import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"

import styled from "styled-components"

import { AppPath } from "components/appRouter/constants"
import Footer from "components/common/Footer"
import Navigation, { NavColor } from "components/navbar/Navigation"

import styles from "styles/styles"

const BookingResult = () => {
    
    // Redux state
    const flightBook = useSelector((state: any) => state.flightBook)
    const outboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.outboundItinerary)

    const [isImageLoaded, setIsImageLoaded] = useState(false)

    const imageRef = useRef(null)

    /*
     *  Load images via Javascript to see if an error occurs
     *  • If it does, set the placeholder image and boolean to true to render Taekus logo
     *  • If it doesn't fail, set the unique destination image we loaded
     * 
     *  note: When the image is used for multiple trips, it is only loaded via network once,
     *    but there will be a network failure for each placeholder
     */
    const loadDestinationImage = () => {
        const image = new Image();
        const airportCode = outboundItinerary?.slices[0].segments.at(-1).destinationCity

        image.onload = () => {
            setIsImageLoaded(true);
            (imageRef.current as any).style.backgroundImage = 'linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.85) ), url(' + image.src + ')';
        }
        image.onerror = () => {
            setIsImageLoaded(true);
        }
        image.src = `https://taekus-image-assets.s3.us-east-2.amazonaws.com/destinations/${airportCode}1.jpg`;
    }

    useEffect(() => {
        if (imageRef.current) {
            loadDestinationImage()
        }
    }, [imageRef.current]) // eslint-disable-line
    
    const title = flightBook.error ? 'Your trip was not booked.' : `Your trip to ${outboundItinerary?.slices[0].segments.at(-1).arrivalPointName} has been reserved!`
    const description = flightBook.error ? 
        'Unfortunately, our system ran into an error and we were not able to process your booking. We apologize for the inconvenience, but you’ll have to try again. For help, please reach out to support@taekus.com.'
        : (flightBook.priceDetail?.isAwardTicket ? 
            'Because this is a partner ticket, various processes with our partner airline Vietnam Airlines may take up to 48 hours for a final confirmation of your ticket booking. We also may need more information from you.  Thanks for choosing Taekus and have a great trip!' 
            : `${flightBook.confirmedBooking.taekusId ? `(Booking code: ${flightBook.confirmedBooking.taekusId})` : ''}
        `)

    return <div key='result'>
        <BookingContainer ref={imageRef} isImageLoaded={isImageLoaded}>
            <Navigation color={NavColor.White} />
            <BookingContent>
                <ConfirmationTitle>{title}</ConfirmationTitle>
                <ConfirmationDescription>{description}</ConfirmationDescription>
                <ButtonsContainer>
                    {!flightBook.error && <TripButton exact to={`${AppPath.MyTrips}/${flightBook.confirmedBooking?.uuid}`} as={NavLink}>View Booking</TripButton>}
                    <FlightSearchButton exact to={AppPath.Rewards} as={NavLink}>Return to Flight Search</FlightSearchButton>
                </ButtonsContainer>
            </BookingContent>
        </BookingContainer>
        <Footer/>
    </div>
}

type BookingContainerProps = {
    isImageLoaded: boolean,
}

const BookingContainer = styled.div<BookingContainerProps>`
    display: flex;
    flex-direction: column;
    backdrop-filter: brightness(30%);
    width: 100%;
    min-height: 100vh;
    max-height: -webkit-fill-available;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7) ), url("https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/tripPlaceholder.jpg");
    ${styles.Animation.transitionStyles}
    opacity: ${props => props.isImageLoaded ? 1 : 0};
`

const ButtonsContainer = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const BookingContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    color: white;
    ${styles.MediaQueries.Desktop} {
        padding-top: 45vh;
        padding-left: 60px;        
        max-width: 60%;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 15px;
    }
`

const TripButton = styled.button`
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    background-color: ${styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    margin-right: 32px;
    border-radius: 2px;
    border: 1px solid ${styles.Color.TaekusPurple};
    ${styles.Animation.transitionStyles}
    &:hover {
        color: ${styles.Color.White};
        text-decoration: none;
        background-color: transparent;
    }
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin-bottom: 10px;
    }
`

const FlightSearchButton = styled.button`
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    border: 1px solid white;
    background-color: transparent;
    color: ${styles.Color.White};
    border-radius: 2px;
    ${styles.Animation.transitionStyles}
    &:hover {
        color: ${styles.Color.White};
        text-decoration: none;
        border: 1px solid ${styles.Color.TaekusPurple};
    }
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const ConfirmationDescription = styled.div`
    color: #FFF;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 20px;
    ${styles.MediaQueries.Mobile} {
        flex: 1;
    }
`

const ConfirmationTitle = styled.div`
    color: #FFF;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    border-bottom: 1px solid #FFF;
    padding-bottom: 10px;
    margin-bottom: 10px;
    ${styles.MediaQueries.Desktop} {
        font-size: 32px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default BookingResult