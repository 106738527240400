import React from 'react'
import { useHistory } from 'react-router';

import styled from 'styled-components';

import { AppPath } from 'components/appRouter/constants';
import Button, { ButtonSize, ButtonType } from 'components/common/Button'
import Navigation, { NavColor } from 'components/navbar/Navigation';

import { ReactComponent as TaekusLogo } from "assets/svg/TaekusLogo.svg";

import styles from 'styles/styles'

const Error500 = () => {
  const history = useHistory()

  const goToHome = () => {
    history.push(AppPath.Home)
  }

  return <Container>
    <Navigation color={NavColor.Black}/>
      <Content>
        <CustomLogo/>
        <Text>Something went wrong.</Text>
        <Button
          size={ButtonSize.Wide}
          buttonType={ButtonType.Purple}
          label='Home'
          onClick={goToHome}
        />
      </Content>
  </Container>
}

const CustomLogo = styled(TaekusLogo)`
  width: auto;
  height: ${styles.Spacing.M};
`

const Text = styled.div`
  margin: ${styles.Spacing.M};
  text-align: center;
`

const Container = styled.div`
  background-image: url("https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/errorBoundary.jpg");
  font-family: ${styles.Font.Family.MonumentGrotesk};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: ${styles.Spacing.S};
`

export default Error500