import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import moment, { Moment } from 'moment';

import Select from "react-select"

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { Actions as RewardsActions } from 'redux/features/banking/rewardsTransactions'

import { Locale, USDCurrencyOptions } from 'utils/constants';

import DatePicker from 'components/common/DatePicker/DatePicker';
import MobileDatePickerInput from 'components/common/DatePicker/MobileDatePickerInput';
import Footer from 'components/common/Footer';
import Spinner from 'components/common/Spinner';
import Navigation, { NavColor } from 'components/navbar/Navigation';

import { ReactComponent as CalendarSVG } from "assets/svg/Calendar.svg";

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles';

const RewardTransactionTypes = {
    ALL: 'All Activity',
    REDEMPTION: 'Redemption',
    REFUND: 'Refund',
    EARN: 'Earned',
    CLAWBACK: 'Adjustment',
    MANUAL_ADJUSTMENT: 'Manual Adjustment',
}

const RewardsActivity = () => {
    // Redux state
    const dispatch = useDispatch()
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const transactions = useSelector((state: any) => state.rewardsTransactions.rewardsTransactions)
    const isLoading = useSelector((state: any) => state.rewardsTransactions.isLoading)

    // Component state
    const [rewardsActivityDateRange, setRewardsActivityDateRange] = useState<{ startDate: Moment | undefined, endDate: Moment | undefined}>({
        startDate: moment().subtract(30, 'days').startOf('day'),
        endDate: moment().endOf('day'),
    })

    type TransactionType = {
      value: string;
      label: string;
    }

    const [rewardsActivityTransactionType, setRewardsActivityTransactionType] = useState<TransactionType>({ value: 'ALL', label: 'All Activity' })

    const transactionTypeOptions: TransactionType[] =  [
            { value: 'ALL', label: 'All Activity' },
            { value: 'REDEMPTION', label: 'Redemption' },
            { value: 'REFUND', label: 'Refund' },
            { value: 'EARN', label: 'Earned' },
            { value: 'CLAWBACK', label: 'Adjustment' },
            { value: 'MANUAL_ADJUSTMENT', label: 'Manual Adjustment' },
        ]

    const isEmpty = !transactions?.length 

    const setRewardsActivityStartDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setRewardsActivityDateRange({ startDate: moment(ev.target.value), endDate: rewardsActivityDateRange.endDate })
    }

    const setRewardsActivityEndDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setRewardsActivityDateRange({ startDate: rewardsActivityDateRange.startDate, endDate: moment(ev.target.value) })
    }

    const handleRewardsActivityDateRangeChange = (dates: [Date | undefined, Date | undefined]) => {
        const [start, end] = dates;
        setRewardsActivityDateRange({
            startDate: start ? moment(start) : undefined,
            endDate: end ? moment(end) : undefined
        })
    }

    useEffect(() => {
        rewardsActivityTransactionType && rewardsActivityDateRange.startDate && rewardsActivityDateRange.endDate &&
        dispatch(RewardsActions.fetchRewardsTransactions({
            startDate: rewardsActivityDateRange.startDate.toDate(),
            endDate: rewardsActivityDateRange.endDate.toDate(),
            transactionType: rewardsActivityTransactionType.value,
        }))
    }, [rewardsActivityDateRange.endDate, rewardsActivityTransactionType, dispatch]) // eslint-disable-line

    const mapTransactionToRow = (transaction: any, index: number) => {
        const pointsAmount = !isNaN((transaction.contentObject?.amount * Number(transaction.contentObject?.fixedBasis))) ? (transaction.contentObject?.amount * Number(transaction.contentObject?.fixedBasis)).toLocaleString(Locale.English, USDCurrencyOptions) : ''

        const transactionDate = (transaction.contentObject && transaction.contentObject.spendThreshold === undefined) ? (transaction.contentObject.transactionDatetime || transaction.contentObject.created) : transaction.created;

        return <tr key={`transaction-item:${index}`}>
            {!isMobile ? <>
                <Item>
                    <ItemContent>{moment(transactionDate).format('MMM D, YYYY')}</ItemContent>
                </Item>
                <Item>
                    <ItemContent>{RewardTransactionTypes[transaction.transactionType as keyof typeof RewardTransactionTypes]}</ItemContent>
                </Item>
                <Item>
                    <ItemContent>{transaction.description}</ItemContent>
                </Item>
                <Item>
                    <ItemContent>{transaction.status}</ItemContent>
                </Item>
                <Item>
                    <ItemContent>{pointsAmount}</ItemContent>
                </Item>
                <Item>
                    <ItemContent>{transaction.value.toLocaleString()} PTS</ItemContent>
                </Item>
            </> : <Item>
                <div className='w-100 d-flex justify-content-between'>
                    <MobileItemCol>
                        <MobileValue>{moment(transactionDate).format('MMM D, YYYY')}</MobileValue>
                        <MobileValue>{RewardTransactionTypes[transaction.transactionType as keyof typeof RewardTransactionTypes]}</MobileValue>
                        <MobileValue>{transaction.description}</MobileValue>
                    </MobileItemCol>
                    <MobileItemCol>
                        <MobileValue>{transaction.value.toLocaleString()} PTS</MobileValue>
                        <MobileValue>{pointsAmount}</MobileValue>
                        <MobileValue>{transaction.status}</MobileValue>
                    </MobileItemCol>
                </div>
            </Item>}
        </tr>
    }

    return <Container>
        <div>
            <Navigation color={NavColor.Black}/>
            <Content>
                <Title>Rewards Activity</Title>
                <CashRedemptionDetails>
                    <CashRedemptionDetail>
                        <BodySmall>Total Points Balance</BodySmall>
                        <Balance>{(currentUser.numPoints || 0).toLocaleString()}</Balance>
                    </CashRedemptionDetail>
                    <CashRedemptionDetail>
                        <BodySmall>Points Available</BodySmall>
                        <Balance>{(currentUser.numPointsPending || 0).toLocaleString()}</Balance>
                    </CashRedemptionDetail>
                    <CashRedemptionDetail>
                        <BodySmall>Recently Earned</BodySmall>
                        <Balance>{(currentUser.pointsEarnedLast30Days || 0).toLocaleString()}</Balance>
                    </CashRedemptionDetail>
                    <CashRedemptionDetail>
                        <BodySmall>Total Points Earned</BodySmall>
                        <Balance>{(Number(currentUser.totalEarnedPoints) || 0).toLocaleString()}</Balance>
                    </CashRedemptionDetail>
                    <CashRedemptionDetail>
                        <BodySmall>Total Points Used</BodySmall>
                        <Balance>{(Number(currentUser.totalUsedPoints) || 0).toLocaleString()}</Balance>
                    </CashRedemptionDetail>
                </CashRedemptionDetails>
                {isMobile ? <div className='d-flex w-100 justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <MobileDatePickerTitle>From</MobileDatePickerTitle>
                        <CustomMobileDatePickerInput onChange={setRewardsActivityStartDate} date={rewardsActivityDateRange.startDate!}/>
                    </div>
                    <div className='d-flex align-items-center'>
                        <MobileDatePickerTitle>To</MobileDatePickerTitle>
                        <CustomMobileDatePickerInput onChange={setRewardsActivityEndDate} date={rewardsActivityDateRange.endDate!}/>
                    </div>
                </div> : <div className='w-100 d-flex justify-content-end'>
                    <div id='dateInput' style={{width: 'min-content'}}>
                        <DatePicker 
                            selected={rewardsActivityDateRange.startDate}
                            startDate={rewardsActivityDateRange.startDate}
                            endDate={rewardsActivityDateRange.endDate}
                            onChange={handleRewardsActivityDateRangeChange}
                            customInput={<RewardsActivityDatePicker>
                                <DatePickerInput id='calendarInput'>
                                    <CalendarIconWrapper>
                                        <CalendarSVG/>
                                    </CalendarIconWrapper>
                                    {`${rewardsActivityDateRange.startDate?.format("MMM D")} - ${rewardsActivityDateRange.endDate ? rewardsActivityDateRange.endDate?.format("MMM D") : ''}`}
                                </DatePickerInput>
                            </RewardsActivityDatePicker>}
                            maxDate={moment().endOf('day')}
                            selectsRange
                        />
                    </div>
                    <div id='typeInput' className="ml-3">
                        <Select
                        classNamePrefix="select"
                        components={{ IndicatorSeparator: () => null }}
                        styles={selectStyles}
                        value={rewardsActivityTransactionType}
                        options={transactionTypeOptions}
                        onChange={(option: any) => {
                            setRewardsActivityTransactionType(option)
                        }}
                        isClearable={false}
                        />
                    </div>
                </div>}
                <Table>
                    <TableContent {...fadeInOutMotionProps}>
                        {!isMobile && <HeaderRow>
                            <HeaderItem width='15%'>Date</HeaderItem>
                            <HeaderItem width='15%'>Type</HeaderItem>
                            <HeaderItem width='35%'>Description</HeaderItem>
                            <HeaderItem width='15%'>Status</HeaderItem>
                            <HeaderItem width='10%'>Amount</HeaderItem>
                            <HeaderItem width='10%'>Points</HeaderItem>
                        </HeaderRow>}
                        {!isLoading && <>
                            {!!transactions?.length && transactions.map(mapTransactionToRow)}
                        </>}
                    </TableContent>
                </Table>
                <AnimatePresence mode='wait'>
                    {isLoading ? <SpinnerArea {...fadeInOutMotionProps}>
                        <SpinnerContainer>
                            <Spinner/>
                        </SpinnerContainer>
                    </SpinnerArea> : (isEmpty && <EmptyMessage {...fadeInOutMotionProps} animate={{opacity: 0.5}}>
                        There are no transactions to display.
                    </EmptyMessage>)}
                </AnimatePresence>
            </Content>
            <Footer/>
        </div>
    </Container>
}


    const selectStyles = {
      option: (provided:any, state:any) => ({
        ...provided,
        fontFamily: 'Monument Grotesk',
        fontSize: '14px',
        letterSpacing: '0.02em',
        color: 'rgb(14, 14, 14)',
        backgroundColor:
          state.isDisabled || state.isFocused || state.isSelected ? 'rgba(90, 115, 138, 0.1)' : '#FFFFFF',
        '&:active': {
          backgroundColor: 'rgba(90, 115, 138, 0.1)',
        },
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        marginTop: '6px',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: '30px',
        background: 'transparent',
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        height: '30px',
        padding: '0',
      }),
      singleValue: (provided: any) => ({
        ...provided,
        fontFamily: 'Monument Grotesk',
        fontSize: '14px',
        letterSpacing: '0.02em',
      }),
      menuList: (provided: any) => ({
        ...provided,
        borderRadius: 0,
        padding: 0,
      }),
      menu: (provided: any) => ({
         ...provided,
         borderRadius: '0',
         padding: '0',
         border: '1px solid rgb(217, 217, 217)',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        marginTop: '-5px',
        height: '30px',
        backgroundColor: 'transparent',
        outline: 'none',
        color: 'white',
        width: '160px',
        boxShadow: state.isSelected || state.isFocused ? `none` : 'none',
        border: `none`,
        borderRadius: '0',
        borderBottom: '1px solid rgb(191, 191, 191)',
        '&:hover': {
          borderBottom: '1px solid rgb(191, 191, 191)',
        },
        '&:active': {
          borderBottom: '1px solid rgb(191, 191, 191)',
        },
      }),
    }


const Balance = styled.div`
    ${styles.Text.DisplayMedium}
    width: 187px;
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
        margin-bottom: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.L};
        display: flex;
        align-items: center;
        &:not(&:last-child) {
            margin-bottom: 53px;
        }    
    }
`

type BodySmallProps = {
    marginBottom?: string
}

const BodySmall = styled.div<BodySmallProps>`
    ${styles.Text.BodySmall}
    margin-bottom: ${props => props.marginBottom || 0};
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const CashRedemptionDetail = styled.div`
    ${styles.MediaQueries.Desktop} {
        width: ${100/5}%;
        height: 75px;
    }
`

const CashRedemptionDetails = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        margin: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Mobile} {
        margin: ${styles.Spacing.S} 0;
        flex-direction: column;
    }
`

const CalendarIconWrapper = styled.div`
    margin-left: ${styles.Spacing.XXXS};
    margin-right 12px;
`

const DatePickerInput = styled.div`
    ${styles.Text.BodySmall}
    padding-right: ${styles.Spacing.S};
    border-bottom: 1px solid ${styles.Color.GreyText};
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
    padding-bottom: 7px;
    white-space: nowrap;
`

const MobileDatePickerTitle = styled.div`
    ${styles.Text.BodySmall}
    color: ${styles.Color.TaekusPurple};
`

const CustomMobileDatePickerInput = styled(MobileDatePickerInput)`
    width: 30%;
`

const MobileItemCol = styled.div`
    max-width: 75%;
    white-space: nowrap;
    &:last-child {
        max-width: 25%;
        text-align: right;
    }
`

const MobileValue = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`

const ItemContent = styled.div`
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Item = styled.td`
    font-style: normal;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
    color: black;
    height: ${styles.Spacing.L};
    border-bottom: 1px solid rgba(0,0,0,0.5);
    vertical-align: top;
    padding-top: ${styles.Spacing.XS};
    padding-bottom: ${styles.Spacing.XS};
    margin-right: ${styles.Spacing.XS};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${styles.MediaQueries.Desktop} {
        &:nth-child(1) {
            padding-left: ${styles.Spacing.XS};
        }
    }
`

const RewardsActivityDatePicker = styled.div`
    display: flex;
    justify-content: end;
    margin:  0 0 ${styles.Spacing.S};
`

const SpinnerArea = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`

const SpinnerContainer = styled.div`
    height: ${styles.Spacing.S};
    width: ${styles.Spacing.S};
`

const TableContent = styled.tbody`
    ${styles.Animation.transitionStyles}
`

const EmptyMessage = styled(motion.div)`
    ${styles.Text.HeadingMedium}    
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    ${styles.MediaQueries.Mobile} {
        text-align: center;
    }
`

type HeaderItemProps = {
    width: string,
}

const HeaderItem = styled.th<HeaderItemProps>`
    width: ${props => props.width};
    font-weight: ${styles.Font.Weight[500]};
    color: ${styles.Color.NearBlack};
    border-bottom: 2px solid ${styles.Color.Black};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    &:nth-child(1) {
        padding-left: ${styles.Spacing.XS};
    }
`

const HeaderRow = styled.tr``

const Table = styled.table`
    gap: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.M};
    width: 100%;
    table-layout: fixed;
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    height: 50px;
    border-bottom: 1px solid grey;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 48px 40px;
    min-height: 100vh;
`

const Container = styled.div`
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: ${styles.Color.Black};
    overflow-y: auto;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default RewardsActivity;