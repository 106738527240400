import { Popover } from "react-bootstrap";

import styled from "styled-components";

import styles from "styles/styles";

const Tooltip = styled(Popover)`
    padding: ${styles.Spacing.XS};
    background-color: ${styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    max-width: 100%;
    position: absolute;
    white-space: nowrap;
    .arrow { display: none; }
`

export default Tooltip