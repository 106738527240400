import React, { useRef, useState } from "react"
import { useSelector } from "react-redux";

import { Modal, ModalDialog } from "react-bootstrap";

import styled from "styled-components"

import Button, { ButtonSize, ButtonType } from "components/common/Button";
import Select from "components/common/Select/Select";
import selectStyles from "components/common/Select/selectStyles";

import { ReactComponent as Close } from "assets/svg/Close.svg";

import styles from "styles/styles"

const NewMccLabel = "+ Create MCC"

type MccProps = {
    card: any
    label?: string,
    ref?: any,
    selectKey?: string,
}

const MerchantCategoryCode = (props: MccProps) => {
    // Redux state
    const banking = useSelector((state: any) => state.banking)

    // Component state
    const [searchInput, setSearchInput] = useState('')
    const [newMccInput, setNewMccInput] = useState('')
    const [tempKey, setTempKey] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const containerRef = useRef(null);

    const preventDefault = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();
    }

    // TODO: Adding and removing MCCs from existing spend-controlled cards not available yet.
    // const addMccToCard = (mcc: string) => {
    //     mcc = mcc.toLowerCase()
    //     const updatedMccs = [...card.mccList, mcc]
    //     const updatedPaymentCard = {
    //         ...card,
    //         cardAccountUuid: banking.account.uuid,
    //         mccList: updatedMccs
    //     }
    //     dispatch(PaymentCardActions.updatePaymentCard(updatedPaymentCard))
    //     dispatch(BankingActions.updateMccs(updatedMccs))
    //     setTempKey('')
    //     setIsModalOpen(false)
    // }

    const handleOnChange = (option: any) => {
        setTempKey('temp')
        if (option.value === NewMccLabel) {
            setIsModalOpen(true)
        }
        // TODO: Uncomment when adding new MCCs to existing cards is supported.
        // else {
        //     addMccToCard(option.value)
        // }
        setSearchInput('')
    }

    const handleNewMccInput = (ev: any) => {
        setNewMccInput(ev.target.value)
    }

    // TODO: Uncomment when adding new MCCs to existing cards is supported.
    // const deleteMcc = () => {
    //     const updatedPaymentCard = {
    //         ...card,
    //         cardAccountUuid: banking.account.uuid,
    //         mccs: card.mccList?.filter((mcc: string) => mcc !== props.label),
    //     }
    //     dispatch(PaymentCardActions.updatePaymentCard(updatedPaymentCard))
    // }

    const closeModal = () => {
        setIsModalOpen(!isModalOpen);
        setNewMccInput('')
        setTempKey('');
    }

    // TODO: Uncomment when adding new MCCs to existing cards is supported.
    // const addNewMcc = () => {
    //     addMccToCard(newMccInput);
    //     setNewMccInput('');
    // }

    let options = [{ value: NewMccLabel, label: NewMccLabel }]
    if (banking.account.tags?.length) {
        options = options.concat(banking?.account.tags
            .filter((tag: any) => !props.card.tags?.includes(tag))
            .map((tag: any) => ({ value: tag, label: tag }))
        )
    }

    return <Container title={props.label} ref={containerRef} newTag={!props.label} onClick={preventDefault} onDoubleClick={preventDefault}>
        {props.label ? <Label>
            <Text>{props.label}</Text>
        </Label> : <Select
            key={(tempKey !== '' ? tempKey : props.selectKey) || ''}
            dropdownIndicator={<></>}
            styleType={selectStyles.tags}
            inputValue={searchInput}
            options={options}
            onChange={handleOnChange}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
        />}
        <Modal dialogAs={Dialog} centered show={isModalOpen}>
            <ModalBody>
                <div className='w-100 d-flex justify-content-between align-items-center'>
                    <ModalTitle>Add New MCC</ModalTitle>
                    <CloseButton onClick={closeModal}>
                        <StyledClose/>
                    </CloseButton>
                </div>
                <MccInput autoFocus value={newMccInput} onChange={handleNewMccInput} placeholder='Add an MCC'/>
                <ButtonContainer>
                    <Button disabled={newMccInput === ''} size={ButtonSize.Wide} label="Add MCC" buttonType={ButtonType.Purple}/>
                </ButtonContainer>
            </ModalBody>
        </Modal>
    </Container>
}

const Dialog = styled(ModalDialog)`
    max-width: 400px;
`

const StyledClose = styled(Close)`
    height: 24px;
    width: 24px;
`

const Text = styled.div`
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${styles.MediaQueries.Mobile} {
        padding: 10px;
    }
`

const MccInput = styled.input`
    margin: ${styles.Spacing.XS} ${styles.Spacing.M};
    outline: 0;
    border: 0;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
`

const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
    cursor: pointer;
    margin: ${styles.Spacing.XS};
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    margin-bottom: ${styles.Spacing.S};
`

const ModalTitle = styled.div`
    padding: 0 ${styles.Spacing.S};
    font-size: 20px;
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Label = styled.div`
    display: flex;
    max-width: 100%;
    padding: 4px 6px;
    user-select: none;
`

type ContainerProps = {
    newTag: boolean
}

const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    border: 1px solid ${props => props.newTag ? styles.Color.TaekusPurple : styles.Color.Grey};
    border-radius: ${styles.BorderRadius.S};
    height: 25px;
    font-style: normal;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.5);
    margin-right: ${styles.Spacing.XS};
    margin-bottom: ${styles.Spacing.XS};
    ${styles.Animation.transitionStyles}
`

export default MerchantCategoryCode