import React from 'react'
import { Provider } from 'react-redux'
import configureStore from 'redux/store'
import { createRoot } from 'react-dom/client';

import { IntlProvider } from 'react-intl'
import translations from './translations/translations'

import App from 'components/App'
import API from 'services/api'
import envVariables from 'services/runtimeEnv'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/global/css/index.scss'

import './index.css'

API.init()

const locale = 'en'

window.env = envVariables

const container = document.getElementById('taekus-app-container')
const root = createRoot(container)
const store = configureStore()
const messages = translations[`${locale}`]

console.log(
  '\n\n  ______              __               \n' +
    ' /_  __/____ _ ___   / /__ __  __ _____\n' +
    '  / /  / __ `// _ \\ / //_// / / // ___/\n' +
    ' / /  / /_/ //  __// ,<  / /_/ /(__  ) \n' +
    '/_/   \\__,_/ \\___//_/|_| \\__,_//____/  \n' +
    '                                       \n\n' +
    'Hi there!\n' +
    '\n' +
    "We're always looking for talented individuals who want to build the \n" +
    "world's greatest financial products. Interested? We're hiring!\nReach out to jobs@taekus.com !\n\n",
)

root.render(
  <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
