import { WsMessage } from 'components/common/Websocket/WsMessageConstants';

export const responseExtractor = (jobType: string, fullResponse: any) => {
	switch (jobType) {
		case WsMessage.Booking.FinalAvailability:
		  	return finalAvailabilityResponseExtractor(fullResponse)
		case WsMessage.Booking.FlightSearch:
			return flightSearchResponseExtractor(fullResponse)
		default:
		  	return ''  //default case
	}
}

export const finalAvailabilityResponseExtractor = (responseMessage: any) => {
	return responseMessage?.message.result
}

export const flightSearchResponseExtractor = (responseMessage: any) => {
	const responseBody = responseMessage?.message
	if ("calendarOptions" in responseBody) {
		return responseBody.calendarOptions
	} else if ("itineraries" in responseBody) {
		return responseBody.itineraries
	} else if ("awardItineraries" in responseBody) {
		return responseBody.awardItineraries
	} else if ("ndcItineraries" in responseBody) {
		return responseBody.ndcItineraries
	} else {
		return {}
	}
}
