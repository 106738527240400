import React from "react";
import styled from "styled-components";
import styles from "styles/styles";

type TextInputProps = {
    onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void,
    icon?: React.ReactElement,
    placeholder?: string,
}

const TextInput = (props: TextInputProps) => {
    return <Container>
        {props.icon && <IconContainer>{props.icon}</IconContainer>}
        <Input onChange={props.onChange} hasIcon={!!props.icon} placeholder={props.placeholder}/>
    </Container>
}

const IconContainer = styled.div`
    position: absolute;
    top: 50%;
    height: 50%;
    left: ${styles.Spacing.S};
    width: 30px;
    transform: translateY(-50%);
`

type InputProps = {
    hasIcon: boolean
}

const Input = styled.input<InputProps>`
    ${styles.Text.BodySmall}
    border: 1px solid ${styles.Color.Grey};
    color: ${styles.Color.NearBlack};
    padding-left: ${props => props.hasIcon ? '50px' : styles.Spacing.XS};
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: relative;
    width: 100%;
    height: ${styles.Spacing.L};
`

export default TextInput;