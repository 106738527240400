import React from "react";

import styled from "styled-components";

import styles from "styles/styles";

export enum ButtonType {
    Default,
    Purple,
    SecondaryPurple,
    Tab,
}

export enum ButtonSize {
    Auto,
    Standard,
    Wide,
    Fit,
}

type ButtonProps = {
    buttonType?: ButtonType;
    "data-testid"?: string;
    size?: ButtonSize;
    onClick?: () => void;
    label?: string;
    disabled?: boolean;
    style?: any
}

const Button = (props: ButtonProps) => {
    return <ButtonContainer 
        $buttonSize={props.size}
        $buttonType={props.buttonType}
        disabled={props.disabled}
        onClick={props.disabled ? undefined : props.onClick}
        style={props.style}
        data-testid={props["data-testid"]}
    >
        {props.label}
    </ButtonContainer>
}

type ButtonContainerProps = {
    $buttonType?: ButtonType,
    $buttonSize?: ButtonSize,
    disabled?: boolean,
}

const getButtonTypeStyles = (props: ButtonContainerProps) => {
    switch (props.$buttonType) {
        case ButtonType.Tab:
            return `
                border-radius: 0;
                border-top: transparent;
                border-bottom: 1px solid ${styles.Color.Black};
                border-left: transparent;
                border-right: transparent;
                background-color: transparent;
                color: ${styles.Color.Black};
                display: flex;
                padding: 8px ${styles.Spacing.XS};
                justify-content: center;
                align-items: center;
                gap:  ${styles.Spacing.XS};
                font-family: ${styles.Font.Family.MonumentGrotesk};
                font-size: 18px;
                font-style: normal;
                font-weight: ${styles.Font.Weight[400]};
                line-height: 138%; /* 24.84px */
                letter-spacing: 0.36px;
            `
        case ButtonType.Purple:
            return `
                border: 1px solid transparent;
                border-radius: 0;
                background-color: ${styles.Color.TaekusPurple};
                color: ${styles.Color.White};
                ${!props.disabled ? `&:hover {
                    border: 1px solid ${styles.Color.TaekusPurple};
                    background-color: transparent;
                    color: ${styles.Color.TaekusPurple};
                }` : ''}
            `
        case ButtonType.SecondaryPurple:
            return `
                border: 1px solid ${styles.Color.TaekusPurple};
                border-radius: 0;
                background-color: transparent;
                color: ${styles.Color.TaekusPurple};
                ${!props.disabled ? `&:hover {
                    border: 1px solid transparent;
                    background-color: ${styles.Color.TaekusPurple};
                    color: ${styles.Color.White};
                }` : `
                    background-color: transparent !important;
                `}
            `
        default:
            return `
                border-radius: 0;
                background-color: ${styles.Color.Transparent};
                color: ${styles.Color.White};
                border: 1px solid ${styles.Color.White};
                padding: ${styles.Spacing.XS};
                ${props.disabled ? `background-color: ${styles.Color.GreyText};` : `
                    &:hover {
                        color: ${styles.Color.Black};
                        background-color: ${styles.Color.White};
                    }
                `}
            `
    }
}

const getButtonSizeStyles = (size?: ButtonSize) => {
    switch (size) {
        case ButtonSize.Fit:
            return `
                border-radius: 2px;
                height: 100%;
                width: 100%;
                padding: ${styles.Spacing.XS} 25px;
            `
        case ButtonSize.Auto:
            return `
                width: 100%;
                height: 100%;
                padding: ${styles.Spacing.XS} 25px;
            `
        case ButtonSize.Wide:
            return `
                padding: ${styles.Spacing.XS};
                border-radius: 2px;
                ${styles.MediaQueries.Desktop} {
                    min-width: 310px;
                    width: 310px;
                    height: 42px;
                }
                ${styles.MediaQueries.Mobile} {
                    width: 100%;
                    height: 42px;
                }
            `
        default:
            return `
                padding: ${styles.Spacing.XS};
                ${styles.MediaQueries.Desktop} {
                    min-width: 160px;
                    height: ${styles.Spacing.L};
                }
                ${styles.MediaQueries.Mobile} {
                    width: 100%;
                    height: ${styles.Spacing.M};
                }
            `
    }
}

const ButtonContainer = styled.button<ButtonContainerProps>`
    ${styles.Text.BodyLarge}
    ${styles.Animation.transitionStyles}
    ${props => getButtonTypeStyles(props)}
    ${props => getButtonSizeStyles(props.$buttonSize)}
    ${props => props.disabled && 'background-color: grey;'}
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
`

export default Button