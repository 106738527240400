import { startCase, toLower } from "lodash";

import { FlightLeg } from "components/pages/Rewards/Flights/types";

import { TicketStatus } from "components/pages/Trips/constants";
import { Trip } from "components/pages/Trips/types";

export const isAmenitiesPopulated = (leg: FlightLeg) => {
    const isSeatPopulated = leg.amenities.seatType !== null
    const isWifiPopulated = leg.amenities.wifi !== 'NO'
    const isPowerPopulated = leg.amenities.power !== null
    const isEntertainmentPopulated = leg.amenities.entertainment !== null
    const isSkypubPopulated = !!leg.amenities.skypub

    return isSeatPopulated || isWifiPopulated || isPowerPopulated || isEntertainmentPopulated || isSkypubPopulated
}

export const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  }

export const getTripPointTotal = (trip: Trip) => {
    const pointsBase = !!trip?.pricePointsBase ? parseInt(trip.pricePointsBase) : 0
    const pointsTaxes = !!trip?.pricePointsTaxes ? parseInt(trip.pricePointsTaxes) : 0
    return pointsBase + pointsTaxes
}

export const getTripCashTotal = (trip: Trip) => {
    const cashBase = !!trip?.priceCashBase ? parseFloat(trip.priceCashBase) : 0
    const cashTaxes = !!trip?.priceCashTaxes ? parseFloat(trip.priceCashTaxes) : 0
    return cashBase + cashTaxes
}

export const getPartnerLocator = (trip: any) => {
    let partnerLocators = [] as any[]
    trip?.reservations.forEach((reservation: any) => {
        reservation.itineraries.forEach((itinerary: any) => {
            itinerary.segments.forEach((segment: any) => {
                segment.partnerPnr && partnerLocators.push(segment.partnerPnr)
            })
        })
    })

    // Return a partner locator if there's exactly one in the whole trip
    return partnerLocators.length === 1 && partnerLocators[0]
}

export const isCancelledTrip = (trip: any) => {
    switch (trip.status) {
        case TicketStatus.CANCELLED:
        case TicketStatus.TICKETING_ERROR:
            return true;
        default:
            return false;
    }
}

export const isTicketStatusError = (status: TicketStatus) => {
    switch (status) {
        case TicketStatus.IN_PROGRESS:
        case TicketStatus.PROCESSING:
        case TicketStatus.CREATED:
        case TicketStatus.TICKETING_ERROR:
            return true;
        default:
            return false;
    }
}

export const getTicketStatusLabel = (status: TicketStatus) => {
    switch (status) {
        case TicketStatus.TICKETING_ERROR:
            return 'Unsuccessful'
        case TicketStatus.IN_PROGRESS:
        case TicketStatus.PROCESSING:
        case TicketStatus.CREATED:
            return 'Pending'
        default:
            return startCase(toLower(status))
    }
}