import React from "react"

import moment from "moment";

import Select, { CSSObjectWithLabel } from "react-select";

import { AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { FlightLocationDropdownStyleTypes } from "components/common/FlightLocationDropdown/constants";
import FlightLocationDropdown from "components/common/FlightLocationDropdown/FlightLocationDropdown";

import styles from "styles/styles";

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";

export enum InputType {
    Date='date',
    Airport='airport',
    Gender='gender'
}

type DetailInputProps = {
    isEditing: boolean;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
    id?: string;
    type?: InputType;
    selectField?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const DetailInput = (props: DetailInputProps) => {
    const genderOptions = [{
        value: 'M',
        label: 'Male'
    }, {
        value: 'F',
        label: 'Female'
    }]

    const selectChangeHandler = (option: any) => {
        props.onChange({
            target: {
                id: props.id!,
                value: option.value,
            }
        })
    }
    
    const airportChangeHandler = (airportOption: any) => {
        props.onChange({
            target: {
                id: props.id!,
                value: airportOption.value,
            }
        })
    }

    const getInput = () => {
        switch(props.type) {
            case InputType.Date:
                return <CustomDateInput min={moment().format('YYYY-MM-DD')} max={moment().add(10, 'years').format('YYYY-MM-DD')} id={props.id} value={props.value} onChange={props.onChange} type='date'/>
            case InputType.Gender:
                return <Select
                    isSearchable={false}
                    id={props.id}
                    onChange={selectChangeHandler}
                    value={genderOptions.find(option => option.value === props.value)}
                    placeholder='Select Gender'
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => <CaretWrapper><StyledCaretDown/></CaretWrapper>,
                    }}
                    styles={{
                        container: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            border: 0,
                            outline: 0,
                            boxShadow: 'none',
                            whiteSpace: 'nowrap',
                            borderBottom: `1px solid ${styles.Color.TaekusPurple}`,
                            height: '27px',
                        }),
                        singleValue: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            fontSize: styles.Font.Size.Small,
                            outline: 0,
                            boxShadow: 'none',
                            maxHeight: '30px',
                            padding: '0 4px',
                            height: '30px',
                            color: styles.Color.Black,
                            border: `1px solid transparent`,
                            width: '100%',
                        }),
                        control: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            backgroundColor: 'transparent',
                            outline: 0,
                            boxShadow: 'none',
                            borderRadius: 0,
                            height: '27px',
                            minHeight: '27px',
                            border: 0,
                            cursor: 'pointer',
                            textAlign: 'right',
                            ':focus-visible': {},
                        }),
                        input: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            border: 0,
                            outline: 0,
                            boxShadow: 'none',
                            margin: 0
                        }),
                        menu: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            minWidth: '120px',
                            borderRadius: 0,
                        }),
                        menuList: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            minWidth: '120px',
                            padding: 0,
                            fontSize: styles.Font.Size.Small,
                            borderRadius: 0,
                        }),
                        valueContainer: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            padding: '0 4px',
                            height: '100%',
                        }),
                        placeholder: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 4px',
                            width: '100%',
                        }),
                        indicatorsContainer: (defaultStyles: CSSObjectWithLabel) => ({
                            ...defaultStyles,
                            height: '27px',
                            fill: styles.Color.TaekusPurple
                        }),
                    }} 
                    options={genderOptions}
                />
            case InputType.Airport:
                return <FlightLocationDropdown
                    id={props.id}
                    placeholder="Search for Airport"
                    styleType={FlightLocationDropdownStyleTypes.Settings}
                    changeHandler={airportChangeHandler}
                />
            default:
                return <CustomInput id={props.id} value={props.value} onChange={props.onChange}/>
        }
    }

    return <AnimatePresence mode='wait'>
        {props.isEditing ? getInput() : (
            !props.value || props.value === '' ? <AddButton id={props.id} onClick={props.selectField}>Add</AddButton> : <ClickableValue 
                id={props.id}
                onClick={props.selectField}
            >
                {props.type === InputType.Date ? moment(props.value).format('MMM D, YYYY') : props.value}
            </ClickableValue>
        )}
    </AnimatePresence>
}

const CaretWrapper = styled.div`
    height: 100%;
    margin-right: ${styles.Spacing.XS};
`

const StyledCaretDown = styled(CaretDown)`
    width: 10px;
    height: 5px;
`

const CustomDateInput = styled.input`
    height: 30px;
    text-align: end;
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
`

const CustomInput = styled.input`
    height: 30px;
    text-align: end;
`

const AddButton = styled.div`
    color: ${styles.Color.TaekusPurple};
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`

const ClickableValue = styled.div`
    cursor: pointer;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
    height: min-content;
    ${styles.Animation.transitionStyles}
    padding: ${styles.Spacing.XXXS};
    :hover {
        border: 1px solid ${styles.Color.TaekusPurple};
    }
`

export default DetailInput