import React, { Component } from 'react'

import Async from 'react-select/async'

import styled from 'styled-components'

import { airportCache } from 'utils/airportCache'

import API from 'services/api'

import { FlightLocationDropdownStyleTypes, flightLocationDropdownStyles } from 'components/common/FlightLocationDropdown/constants'
import {
  backgroundBlue,
  backgroundBlue15PctOpacity,
  backgroundBlue8PctOpacity,
} from 'components/common/styleConstants'
import styles from 'styles/styles'


class FlightLocationDropdown extends Component<any> {
  filterBlacklistedOptions = (option: any) => {
    return this.props.blacklist === undefined || !this.props.blacklist.includes(option.value)
  }

  reloadOptions = (inputValue: string, callback: (option: any) => void) => {
    if (inputValue.length < 3) {
      return callback((airportCache as any)[inputValue]?.filter(this.filterBlacklistedOptions))
    } else {
      return API.flights.airportSearch(inputValue).then((response) => {
        callback((response.data as any).filter(this.filterBlacklistedOptions))
      })
    }
  }

  render() {
    const {
      value,
      name,
      id,
      placeholder,
      changeHandler,
      className,
      styleType = 'white',
      styleObject = null,
    } = this.props

    const redStyles = {
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor:
          state.isDisabled || state.isFocused || state.isSelected
            ? backgroundBlue8PctOpacity()
            : '#ffffff',
        color: backgroundBlue(),
        '&:active': {
          backgroundColor: backgroundBlue15PctOpacity(),
        },
      }),
      menuList: (provided: any) => ({
        ...provided,
        borderRadius: 0,
        padding: 0,
      }),
      placeholder: (provided: any) => ({
        color: '#ffffff',
        gridArea: '1/1/2/3',
        fontSize: '1.5em',
        opacity: 0.5,
        whiteSpace: 'nowrap',
      }),
      singleValue: (provided: any) => ({
        ...provided,
        color: '#ffffff',
        fontSize: '1.5em',
        padding: 0,
        fontFamily: styles.Font.Family.MonumentGrotesk,
      }),
      menu: (provided: any) => ({
        ...provided,
        boxShadow: 'none',
        border: `1px solid ${styles.Color.GreyText}`,
        borderRadius: 0,
        fontFamily: styles.Font.Family.MonumentGrotesk,
        width: '100%',
        '@media only screen and (max-width: 576px)': {
          width: '100%',
        },
      }),
      input: (provided: any) => ({
        ...provided,
        color: '#ffffff',
        padding: 0,
        height: styles.Spacing.S,
        fontFamily: styles.Font.Family.MonumentGrotesk,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        caretColor: 'transparent',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        outline: 'none',
        padding: 0,
        boxShadow: 'none',
        minWidth: '300px',
        maxWidth: '300px',
        backgroundColor: styles.Color.Transparent,
        border: 'none',
        fontFamily: styles.Font.Family.MonumentGrotesk,
      }),
    }

    const rewardsStyles = {
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor:
          state.isDisabled || state.isFocused || state.isSelected
            ? backgroundBlue8PctOpacity()
            : '#ffffff',
        color: backgroundBlue(),
        '&:active': {
          backgroundColor: backgroundBlue15PctOpacity(),
        },
      }),
      menuList: (provided: any) => ({
        ...provided,
        borderRadius: 0,
        padding: 0,
      }),
      placeholder: (provided: any) => ({
        color: '#ffffff',
        gridArea: '1/1/2/3',
        fontSize: '32px',
        whiteSpace: 'nowrap',
      }),
      singleValue: (provided: any) => ({
        ...provided,
        color: '#ffffff',
        fontSize: '32px',
        padding: 0,
        fontFamily: 'Monument Grotesk',
      }),
      menu: (provided: any) => ({
        ...provided,
        boxShadow: 'none',
        border: `1px solid ${styles.Color.GreyText}`,
        borderRadius: 0,
        fontFamily: styles.Font.Family.MonumentGrotesk,
        width: '100%',
        '@media only screen and (max-width: 576px)': {
          width: '100%',
        },
      }),
      input: (provided: any) => ({
        ...provided,
        color: '#ffffff',
        fontSize: '32px',
        padding: 0,
        fontFamily: 'Monument Grotesk',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        border: 0,
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        outline: 'none',
        padding: 0,
        boxShadow: 'none',
        width: '100%',
        height: '62px',
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: 0,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: '1px solid white',
        fontFamily: 'Monument Grotesk',
        margin: 0,
        '&:hover': {
          borderColor: 'white',
          opacity: 1,
        },
      }),
      container: (provided: any) => ({
        ...provided,
        flex: 1,
        minWidth: '20%',
      }),
    }

    const mobileOptionLabel = ({ value, label }: {value: string, label: string}) => (
      <div className="d-flex justify-content-between">
        <div>
          {label}{' '}
          <span style={{ color: '#ccc', marginLeft: '5px', fontSize: '0.7em' }}>{value}</span>
        </div>
      </div>
    )

    const mobileFlightsLabel = ({ value, label }: {value: string, label: string}) => (
      <div className="d-flex justify-content-between">
        <div>
          {label}{' '}
          {this.props.value?.value !== value && <span style={{ color: '#ccc', marginLeft: '5px', fontSize: '0.7em' }}>{value}</span>}
        </div>
      </div>
    )

    const rewardsOptionLabel = ({ value, label, location }: {value: string, label: string, location: string}) => (
      <div className="d-flex flex-column justify-content-between">
        <RewardsAirportTitle>
          {label}
        </RewardsAirportTitle>
        <RewardsLocation>
          {value}{location && ', ' + location}
        </RewardsLocation>
      </div>
    )

    const redOptionLabel = ({ value, label, location }: {value: string, label: string, location: string}) => (
      <div>
        <div>{label}</div>
        <div className="text-size-05">
          {value}
          {location && ', ' + location}
        </div>
      </div>
    )

    const whiteOptionLabel = ({ value, label, location }: {value: string, label: string, location: string}) => (
      <div className="d-flex flex-column justify-content-between">
        <div>{label}</div>
        <div className="text-size-05">
          {value}
          {location && ', ' + location}
        </div>
      </div>
    )

    const settingsOptionLabel = ({ value, label, location }: {value: string, label: string, location: string}) => (
      <div className="d-flex flex-column justify-content-between">
        <div>{label}{value && ', ' + value}</div>
      </div>
    )

    let locationStyles = null
    let optionLabel = null

    if (styleType === 'red') {
      optionLabel = redOptionLabel
    } else if (styleType === 'white') {
      optionLabel = whiteOptionLabel
    } else if (styleType === FlightLocationDropdownStyleTypes.Settings) {
      optionLabel = settingsOptionLabel
    } else if (styleType === 'mobile') {
      optionLabel = mobileOptionLabel
    } else if (styleType === 'mobileFlights') {
      optionLabel = mobileFlightsLabel
    } else if (styleType === FlightLocationDropdownStyleTypes.Rewards) {
      optionLabel = rewardsOptionLabel
    } else {
      optionLabel = whiteOptionLabel
    }

    if ((flightLocationDropdownStyles as any)[styleType]) {
      locationStyles = (flightLocationDropdownStyles as any)[styleType]
    }

    if (styleObject !== null) {
      locationStyles = styleObject
    } else if (styleType === 'red') {
      locationStyles = redStyles
    } else if (styleType === FlightLocationDropdownStyleTypes.Rewards) {
      locationStyles = rewardsStyles
    }

    const locationPickerProps = {
      styles: locationStyles,
      formatOptionLabel: optionLabel,
      components: { DropdownIndicator: () => null, IndicatorSeparator: () => null },
      noOptionsMessage: () => 'No airports found...',
      dropdownIndicator: null,
      isClearable: true,
    }

    const airportOptions = (inputValue: string, callback: (options: any) => void) => {
      this.reloadOptions(inputValue, callback)
    }

    return (
      <Async
        {...locationPickerProps}
        className={className}
        loadOptions={airportOptions}
        value={value}
        maxMenuHeight={200}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={(value) => changeHandler(value, id)}
      />
    )
  }
}

const RewardsAirportTitle = styled.div`
  font-size: min(1.4vw, 20px);
`

const RewardsLocation = styled.div`
  font-size: min(1vw, 18px);
`

export default FlightLocationDropdown
