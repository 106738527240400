import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"

import moment from "moment"

import { Img } from "react-image"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { Locale, USDCurrencyOptions } from "utils/constants"
import { getDurationAsString } from "utils/utils"

import { Actions as TripActions } from 'redux/features/trips'

import Footer from "components/common/Footer"
import Spinner from "components/common/Spinner"
import Tab from "components/common/Tab"
import Navigation, { NavColor } from "components/navbar/Navigation"

import { AppPath } from "components/appRouter/constants"
import { SIDEBAR_WIDTH } from "components/pages/Cards/constants"
import { FareFeeInformation, TravelClass, messages } from "components/pages/Rewards/constants"
import { getTicketStatusLabel, isTicketStatusError } from "components/pages/Trips/utils"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as ArrowLeftIcon } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRightSVG } from "assets/svg/ArrowRight.svg";
import { ReactComponent as InfoIcon } from "assets/svg/Info.svg";
import { ReactComponent as Reverse } from "assets/svg/Reverse.svg";

enum TripInspectorTab {
    FlightInformation,
    TravelInformation
}

const TripInspector = () => {
    const dispatch = useDispatch()
    const { tripID } = useParams<{ tripID: string }>();

    // Redux state
    const trip = useSelector((state: any) => state.trips.trips).find((trip: any) => trip.uuid === tripID)
    const isLoadingTrips = useSelector((state: any) => state.trips.isLoading)

    // Copmonent state
    const [selectedTab, setSelectedTab] = useState(TripInspectorTab.FlightInformation)

    const isChangeable = trip?.reservations[0]?.changeable === FareFeeInformation.Included
    const isRefundable = trip?.reservations[0]?.refundable === FareFeeInformation.Included

    useEffect(() => {
        // If trip is not found in the list of trips on page mount, fetch the individual trip
        if (!trip) {
            dispatch(TripActions.fetchTrip(tripID))
        }
    }, [dispatch]) // eslint-disable-line

    const getLegDuration = (leg: any) => {
        // duration is stored as a hh:mm:ss string
        const durations = leg.duration.split(':')
        // hours + minutes + seconds
        const sumInMinutes = (Number(durations[0]) * 60) + (Number(durations[1])) + Number(durations[2] / 60)
        return getDurationAsString(sumInMinutes)
    }

    const getPointTotal = () => {
        const pointsBase = !!trip?.pricePointsBase ? parseInt(trip.pricePointsBase) : 0
        const pointsTaxes = !!trip?.pricePointsTaxes ? parseInt(trip.pricePointsTaxes) : 0
        return pointsBase + pointsTaxes
    }

    const getCashTotal = () => {
        const cashBase = !!trip?.priceCashBase ? parseFloat(trip.priceCashBase) : 0
        const cashTaxes = !!trip?.priceCashTaxes ? parseFloat(trip.priceCashTaxes) : 0
        return cashBase + cashTaxes
    }

    const getRefundText = () => {
        switch (trip?.reservations[0]?.refundable) {
            case FareFeeInformation.Included:
                return messages.FareFeeInformation.Refundable.Included
            case FareFeeInformation.AtCharge:
                return messages.FareFeeInformation.Refundable.AtCharge
            default:
                return messages.FareFeeInformation.Refundable.NotOffered
        }
    }

    const getChangeText = () => {
        switch (trip?.reservations[0]?.changeable) {
            case FareFeeInformation.Included:
                return messages.FareFeeInformation.Changeable.Included
            case FareFeeInformation.AtCharge:
                return messages.FareFeeInformation.Changeable.AtCharge
            default:
                return messages.FareFeeInformation.Changeable.NotOffered
        }
    }

    const getTabContent = () => {
        switch (selectedTab) {
            case TripInspectorTab.TravelInformation:
                return <TravelInformationContainer key={TripInspectorTab.TravelInformation} {...fadeInOutMotionProps}>
                    <div>
                        <DetailLabel>Provided Contact Info</DetailLabel>
                        <SmallText>{trip?.primaryPhoneNumber || trip?.reservations[0].travellers[0]?.phone}</SmallText>
                        <SmallText>{trip?.primaryEmail || trip?.reservations[0].travellers[0]?.email}</SmallText>
                    </div>
                    <div>
                        {trip?.reservations[0].travellers.map((passenger: any, passengerIndex: number) => <TravelerContainer key={`passenger-${passengerIndex}`}>
                            <TravelerTitle>Traveler {passengerIndex + 1}: {passenger.firstName} {passenger.lastName}</TravelerTitle>
                            <TravelerDetails>
                                <TravelerDetail>
                                    <DetailLabel>Ticket Number</DetailLabel>
                                    <SmallText>{passenger.ticketNumber || 'Pending'}</SmallText>
                                </TravelerDetail>
                                <TravelerDetail>
                                    <DetailLabel>Seat</DetailLabel>
                                    <SmallText>-</SmallText>
                                </TravelerDetail>
                                <TravelerDetail>
                                    <DetailLabel>Known Traveler Number</DetailLabel>
                                    <SmallText>{passenger.knownTravellerNumber || '-'}</SmallText>
                                </TravelerDetail>
                                <TravelerDetail>
                                    <DetailLabel>Redress Number</DetailLabel>
                                    <SmallText>{passenger.redressNumber || '-'}</SmallText>
                                </TravelerDetail>
                            </TravelerDetails>
                        </TravelerContainer>)}
                    </div>
                </TravelInformationContainer>
            case TripInspectorTab.FlightInformation:
            default:
                return <motion.div key={TripInspectorTab.FlightInformation} {...fadeInOutMotionProps}>
                    {trip?.reservations[0].itineraries.map((itinerary: any) => <ItineraryContainer key={itinerary.uuid}>
                        <ItineraryTitle>Departing {moment(itinerary.segments[0]?.localDepartureTimeDate).format('ddd, MMM D')}</ItineraryTitle>
                        {itinerary.segments.map((segment: any) => <>
                            {segment.partnerPnr && <FlightText>Partner Locator: <strong>{segment.partnerPnr}</strong></FlightText>}
                            {segment.legs.map((leg: any) => <div>
                                <SegmentContainer>
                                    <VerticalLegBar>
                                        <LargeDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <LargeDot/>
                                    </VerticalLegBar>
                                    <Segment>
                                        <SegmentSection>
                                            <DetailLabel>Departs</DetailLabel>
                                            <FlightText>{moment(leg.localDepartureTimeDate).format('h:mm A')} • {leg.originCityName} ({leg.origin})</FlightText>
                                            <FlightSubtext>{moment(leg.localDepartureTimeDate).format('dddd, MMMM D')}</FlightSubtext>
                                        </SegmentSection>
                                        <TravelTimeContainer>
                                            <Logo src={[
                                                `/static/img/airlineLogos/mini/${segment.carrierCode.toUpperCase()}.png`,
                                                '/static/img/airlineLogos/mini/default.png'
                                            ]} />
                                            <TravelTime>{getLegDuration(leg)}</TravelTime>
                                        </TravelTimeContainer>
                                        <SegmentSection>
                                            <DetailLabel>Arrives</DetailLabel>
                                            <FlightText>{moment(leg.localArrivalTimeDate).format('h:mm A')} • {leg.destinationCityName} ({leg.destination}) </FlightText>
                                            <FlightSubtext>{moment(leg.localArrivalTimeDate).format('dddd, MMMM D')}</FlightSubtext>
                                        </SegmentSection>
                                    </Segment>
                                </SegmentContainer>
                            </div>)}
                            <FlightSubtext>{segment.operatingCarrierName} • {messages.SearchOptions.TravelClass[segment.cabin as TravelClass]} • {segment.operatingCarrierCode} {segment.fltNumber}</FlightSubtext>
                        </>)}
                    </ItineraryContainer>)}
                </motion.div>
        }
    }

    return <Container>
        <div>
            <Navigation color={NavColor.Black} />
            <TripsContainer>
                {isLoadingTrips ? <LoadingContainer>
                    <Spinner size={40}/>
                    <LoadingText>Fetching your trip details...</LoadingText>
                </LoadingContainer> : <Main>
                    <Sidebar>
                        <BackButtonContainer as={Link} to={AppPath.MyTrips}>
                            <StyledArrowLeft fill={styles.Color.TaekusPurple}/>
                            <BackButtonLabel>Back to all trips</BackButtonLabel>
                        </BackButtonContainer>
                    </Sidebar>
                    <Content {...fadeInOutMotionProps}>
                        <TitleContainer>
                            <LargeText>Trip Details</LargeText>
                        </TitleContainer>
                        <Header>
                            <TripDetails>
                                <ItineraryHeader>
                                    <TripTitleContainer>
                                        <LargeText>{trip?.tripName || `${trip?.reservations[0].itineraries[0]?.segments[0]?.originCityName} to ${trip?.reservations[0].itineraries[0]?.segments.at(-1)?.destinationCityName}`}</LargeText>
                                    </TripTitleContainer>
                                    <Itinerary>
                                        <div>
                                            <DetailLabel>{trip?.reservations[0].itineraries[0]?.segments[0]?.originCityName}</DetailLabel>
                                            <LargeText>{trip?.reservations[0].itineraries[0]?.segments[0]?.origin}</LargeText>
                                            <DetailLabel>{moment(trip?.reservations[0].itineraries[0]?.segments[0]?.localDepartureTimeDate).format('ddd, MMM D')}</DetailLabel>
                                        </div>
                                        <TripTypeIconContainer>
                                            {trip?.reservations[0].itineraries.length > 1 ? <Reverse/> : <ArrowRightSVG/>}
                                        </TripTypeIconContainer>
                                        <div>
                                            <DetailLabel>{trip?.reservations[0].itineraries[0]?.segments.at(-1)?.destinationCityName}</DetailLabel>
                                            <LargeText>{trip?.reservations[0].itineraries[0]?.segments.at(-1)?.destination}</LargeText>
                                            <DetailLabel>{trip?.reservations[0].itineraries.length > 1 ? moment(trip?.reservations.at(-1).itineraries.at(-1)?.segments[0]?.localDepartureTimeDate).format('ddd, MMM D') : moment(trip?.reservations[0].itineraries[0]?.segments.at(-1)?.localArrivalTimeDate).format('ddd, MMM D')}</DetailLabel>
                                        </div>
                                    </Itinerary>
                                    <div>{trip?.reservations[0].itineraries.length > 1 ? 'Round Trip' : 'One-way'} &bull; {trip?.reservations[0].travellers.length} Passenger{trip?.reservations[0].travellers.length > 1 ? 's' : ''}</div>
                                </ItineraryHeader>
                                <DetailsHeader>
                                    <div className="d-flex">
                                        <Detail>
                                            <DetailLabel>Locator</DetailLabel>
                                            {trip?.taekusId ? <SmallText>{trip?.taekusId}</SmallText> : <SmallText error>Pending</SmallText>}
                                        </Detail>
                                    </div>
                                    <Detail>
                                        <DetailLabel>Ticket Status</DetailLabel>
                                        <SmallText error={isTicketStatusError(trip)}>{getTicketStatusLabel(trip?.ticketStatus)}</SmallText>
                                    </Detail>
                                    <Detail>
                                        <DetailLabel>Total Cost</DetailLabel>
                                        <SmallText>
                                            {(getPointTotal()).toLocaleString()} Points
                                            {!!getCashTotal() && " + " + getCashTotal().toLocaleString(Locale.English, USDCurrencyOptions)}
                                        </SmallText>
                                    </Detail>
                                </DetailsHeader>
                            </TripDetails>
                            <ImportantInformation>
                                <InformationTitleContainer>
                                    <CustomInfoIcon/>
                                    <InformationTitle>Important Information</InformationTitle>
                                </InformationTitleContainer>
                                <FlightSubtext>
                                    This ticket is <strong>{isRefundable ? 'refundable' : 'non-refundable'}</strong>
                                </FlightSubtext>
                                <FlightSubtext style={{marginBottom: '16px'}}>Name changes are not allowed.</FlightSubtext>
                                <FareRulesTitle>Fare rules & restrictions</FareRulesTitle>
                                <div className="d-flex">
                                    <InfoTextContainer>
                                        <InfoText>{getRefundText()}</InfoText>
                                        <InfoText>{getChangeText()}</InfoText>
                                    </InfoTextContainer>
                                </div>
                                <BaggageRulesTitle>Baggage rules</BaggageRulesTitle>
                                <div className="d-flex">
                                    {/* <InfoTextContainer>
                                        <InfoText>Airline assigns seats</InfoText>
                                        <InfoText>Upgrades not allowed</InfoText>
                                        <InfoText>Board last in group</InfoText>
                                    </InfoTextContainer> */}
                                    <InfoTextContainer>
                                        <InfoText>{trip?.carryonBaggageAllowance ? trip?.carryonBaggageAllowance + ' carry-on bag(s) allowed' : 'No Carry-on bags allowed'}</InfoText>
                                        <InfoText>{trip?.checkedBaggageAllowance ? trip?.checkedBaggageAllowance + ' free checked bag(s) included' : 'No free checked bags allowed'}</InfoText>
                                    </InfoTextContainer>
                                </div>
                            </ImportantInformation>
                        </Header>
                        {/* <div className="d-flex" style={{marginBottom: '80px'}}>
                            <Button size={ButtonSize.Auto} buttonType={ButtonType.SecondaryPurple} label="Cancel Flight" style={{marginRight: '26px'}} />
                            <Button size={ButtonSize.Auto} buttonType={ButtonType.Purple} label="Change Reservation" style={{marginRight: '26px'}} />
                            <Button size={ButtonSize.Auto} buttonType={ButtonType.Purple} label="Select Seat" style={{marginRight: '26px'}} />
                        </div> */}
                        <Tabs>
                            <Tab selected={selectedTab === TripInspectorTab.FlightInformation} label="Flight Information" onClick={() => { setSelectedTab(TripInspectorTab.FlightInformation) }} />
                            <Tab selected={selectedTab === TripInspectorTab.TravelInformation} label="Travel Information" onClick={() => { setSelectedTab(TripInspectorTab.TravelInformation) }} />
                        </Tabs>
                        <AnimatePresence mode='wait'>
                            {getTabContent()}
                        </AnimatePresence>
                    </Content>
                </Main>}
            </TripsContainer>
            <Footer/>
        </div>
    </Container>
}

const Sidebar = styled.div`
    min-width: ${SIDEBAR_WIDTH}px;
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        padding-left: ${styles.Spacing.M};
        padding-right: ${styles.Spacing.M};    
        padding-top: 48px;
    }
`

const TravelerDetail = styled.div`
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
`

const TravelerDetails = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const StyledArrowLeft = styled(ArrowLeftIcon)`
    width: ${styles.Spacing.S};
`

const TripsContainer = styled.div`
    min-height: 100vh;
`

const Main = styled.div`
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

const BackButtonLabel = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    margin-left: ${styles.Spacing.XS};
    color: ${styles.Color.NearBlack};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
`

const BackButtonContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const SegmentSection = styled.div`
    margin-bottom: 24px;
`

const Segment = styled.div`
    margin: 32px;
    flex: 1;
`

const TravelTimeContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`

const Tabs = styled.div`
    display: flex;
    margin-bottom: 8px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        justify-content: space-between;
        overflow-x: scroll;
        ${styles.Scrollbar.transparent}
    }
`

const TripTypeIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styles.Spacing.M};
    height: auto;
    margin: 0 24px;
    padding: 6px;
`

const Detail = styled.div`
    &:not(&:last-child) {
        margin-bottom: 8px;
    }
`

const SegmentContainer = styled.div`
    width: 50%;
    display: flex;
    &:not(&:last-child) {
        border-bottom: 1px dotted ${styles.Color.Grey};
    }
`

const ItineraryContainer = styled.div`
    padding: 48px 0;
    &:not(&:first-child) {
        border-top: 1px solid ${styles.Color.Grey};
    }
`

const TravelerContainer = styled.div`
    padding: 32px 0;
    &:not(&:first-child) {
        border-top: 1px solid ${styles.Color.Grey};
    }
`

const Logo = styled(Img)`
    width: auto;
    height: 24px;
    margin: 0 ${styles.Spacing.XS};
`

const TravelInformationContainer = styled(motion.div)`
    ${styles.MediaQueries.Desktop} {
        padding: 48px 0;
    }
    ${styles.MediaQueries.Mobile} {
        padding-top: ${styles.Spacing.S};
    }
`

const TravelTime = styled.div`
    color: rgba(0, 0, 0, 0.65);
    /* Subhead */
    font-family: ${styles.Font.Family};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 137%; /* 16.44px */
    letter-spacing: 0.12px;
    text-transform: uppercase;
`

const TravelerTitle = styled.div`
    color: ${styles.Color.Black};
    /* HeadingMedium */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 27.6px */
    letter-spacing: 0.4px;
    margin-bottom: 24px;
`

const LargeDot = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid ${styles.Color.Grey};
    margin: 3.5px 0;
`

const SmallDot = styled.div`
    background-color: ${styles.Color.Grey};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 3px 0;
`

const VerticalLegBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
`

const InfoTextContainer = styled.div`
    &:first-child {
        margin-right: 22px;
    }
`

const InfoText = styled.div`
    color: #BFBFBF;
    /* BodySmall */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    margin-bottom: 4px;
    overflow-wrap: break-word;
`

const CustomInfoIcon = styled(InfoIcon)`
    margin-right: ${styles.Spacing.XS};
`

const InformationTitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`

const InformationTitle = styled.div`
    color: ${styles.Color.Black}
    /* HeadingSmallButton */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 24.84px */
    letter-spacing: 0.36px;
`

const ItineraryTitle = styled.div`
    color: ${styles.Color.Black};
    /* HeadingLarge */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 132%; /* 31.68px */
    letter-spacing: 0.24px;
`

const FlightText = styled.div`
    color: ${styles.Color.Black};
    /* BodyLarge */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        max-width: 100%;
    }
`

const TripDetails = styled.div`
    ${styles.MediaQueries.Desktop} {
        display: flex;
        width: 60%;
        border-right: 1px solid ${styles.Color.Grey};
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const FlightSubtext =  styled.div`
    color: ${styles.Color.Black};
    /* BodySmall */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    white-space: normal;
    max-width: 100%;
`

type SmallTextProps = {
    error?: boolean
}

const SmallText = styled.div<SmallTextProps>`
    color: ${props => props.error ? styles.Color.GreyText : styles.Color.Black};
    /* BodyLarge */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

const FareRulesTitle = styled.div`
    color: ${styles.Color.Black};
    /* Subhead */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    line-height: 137%; /* 16.44px */
    letter-spacing: 0.12px;
    text-transform: uppercase;
    margin-bottom: 4px;
`

const BaggageRulesTitle = styled.div`
    color: ${styles.Color.Black};
    /* Subhead */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    line-height: 137%; /* 16.44px */
    letter-spacing: 0.12px;
    text-transform: uppercase;
    margin-bottom: 4px;
`

const DetailLabel = styled.div`
    color: ${styles.Color.Black};
    /* Subhead */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 137%; /* 16.44px */
    letter-spacing: 0.12px;
    text-transform: uppercase;
`

const LargeText = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Medium};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
`

const ImportantInformation = styled.div`
    ${styles.MediaQueries.Desktop} {
        padding: 0 16px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-top: ${styles.Spacing.XS};
    }
    overflow: hidden;
`

const Content = styled(motion.div)`
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        margin: ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        margin: ${styles.Spacing.S} ${styles.Spacing.S};
    }
`

const ItineraryHeader = styled.div`
    flex: 1;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const DetailsHeader = styled.div`
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        margin-top: 56px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: ${styles.Spacing.S} 0;
    }
`

const LoadingText = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    margin: ${styles.Spacing.XS};
`

const TitleContainer = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 42px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
`

const TripTitleContainer = styled.div`
    margin-bottom: 16px;
`

const Itinerary = styled.div`
    display: flex;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        justify-content: space-between;
    }
`

const Header = styled.div`
    margin-bottom: ${styles.Spacing.S};
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    white-space: nowrap;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default TripInspector