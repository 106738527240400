import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

import { motion } from "framer-motion"
import styled from "styled-components"

import { AppPath } from "components/appRouter/constants"

import ItineraryReview from "components/pages/Rewards/Flights/FlightBooking/ItineraryReview"
import Upsell from "components/pages/Rewards/Flights/FlightBooking/Upsell"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

type TripSummaryProps = {
    selectedUpsellUuid?: string,
    updateSelectedUpsellUuid: (uuid: string) => void,
    isShowMobileUpsell: boolean,
    updateIsShowMobileUpsell: (isShowMobileUpsell: boolean) => void,
}

const TripSummary = (props: TripSummaryProps) => {
    const history = useHistory()

    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const outboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.outboundItinerary)
    const inboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.inboundItinerary)
    const flightBook = useSelector((state: any) => state.flightBook)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    
    const upsellAvailable = !flightBook.pricingIsLoading && priceDetail.fares.length > 0

    const handleMobileUpsellButtonClick = () => {
        updateIsShowMobileUpsell(true)
    }

    const goToFlightSearch = () => {
        history.push(AppPath.FlightSearch)
    }

    const { selectedUpsellUuid, updateSelectedUpsellUuid, isShowMobileUpsell, updateIsShowMobileUpsell } = props;

    return isMobile ? <>
        {isShowMobileUpsell && upsellAvailable ? <>
            <UpsellContainer {...fadeInOutMotionProps}>
                <UpsellHeader>Upgrade your ticket</UpsellHeader>
                {priceDetail.fares.map((fare: any) => <Upsell 
                    selected={fare.uuid === selectedUpsellUuid}
                    onClick={updateSelectedUpsellUuid}
                    fare={fare}
                />)}
            </UpsellContainer>
        </> : <MobileItineraryViewContainer>
            <Title>Review your trip from {outboundItinerary.slices[0].segments[0].originCityName} to {outboundItinerary.slices[0].segments.at(-1)?.destinationCityName}</Title>
            <CalendarBackButton onClick={goToFlightSearch}>{`< Change Flight`}</CalendarBackButton>
            <MobileItinerariesContainer>
                {outboundItinerary && <ItineraryReview isOutbound={true} option={outboundItinerary} />}
                {inboundItinerary && <ItineraryReview isOutbound={false} option={inboundItinerary} />}
            </MobileItinerariesContainer>
            {upsellAvailable && <MobileUpsellButtonContainer>
                <MobileUpsellButton onClick={handleMobileUpsellButtonClick}>Continue to Upgrade Options</MobileUpsellButton>
            </MobileUpsellButtonContainer>}
        </MobileItineraryViewContainer>}
    </> : <>
        <Title>Review your trip from {outboundItinerary.slices[0].segments[0].originCityName} to {outboundItinerary.slices[0].segments.at(-1)?.destinationCityName}</Title>
        <CalendarBackButton onClick={goToFlightSearch}>{`< Change Flight`}</CalendarBackButton>
        {outboundItinerary && <ItineraryReview isOutbound={true} option={outboundItinerary} />}
        {inboundItinerary && <ItineraryReview isOutbound={false} option={inboundItinerary} />}
        {priceDetail.fares.length > 0 && <UpsellContainer {...fadeInOutMotionProps}>
            <UpsellHeader>Upgrade your ticket</UpsellHeader>
            {priceDetail.fares.map((fare: any, index: number) => <Upsell key={index} selected={fare.uuid === selectedUpsellUuid} onClick={updateSelectedUpsellUuid} fare={fare}/>)}
        </UpsellContainer>}
    </>
}

const CalendarBackButton = styled.div`
    color: ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 146%; /* 14.6px */
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    width: min-content;
    white-space: nowrap;
    font-size: 12px;
    ${styles.MediaQueries.Desktop} {
        padding-bottom: 10px;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 20px 10px;
    }
`

const Title = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${styles.MediaQueries.Desktop} {
        font-size: 32px;
        margin-bottom: 20px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
        padding: 0 20px;
        margin-bottom: 8px;
    }
`

const MobileUpsellButtonContainer = styled.div`
    padding: 15px;
`

const MobileItinerariesContainer = styled.div`
    flex: 1;
`

const MobileItineraryViewContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
`

const MobileUpsellButton = styled.button`
    background-color: ${props => props.disabled ? styles.Color.Grey : styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    height: 42px;
    border: 1px solid ${props => props.disabled ? styles.Color.Grey : styles.Color.TaekusPurple};
    width: 100%;
    ${styles.Animation.transitionStyles}
    ${props => !props.disabled && `&:hover {
        background-color: ${styles.Color.White};
        color: ${styles.Color.TaekusPurple};
    }`}
`

const UpsellHeader = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.24px;
    padding-bottom: 2px;
    
    ${styles.MediaQueries.Desktop} {
        border-bottom: 2px solid #000000;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 15px;
    }
`

const UpsellContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 160px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        max-width: 100%;
    }
`

export default TripSummary