import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga4'

import API from 'services/api'

class App extends Component {
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const refreshToken = localStorage.getItem('refreshToken')
      API.auth.refreshAccessToken(refreshToken).then((response) => {
        const { access } = response.data
        localStorage.setItem('accessToken', access)
        // config.headers.authorization = `Bearer ${access}`
        // resolve(config)
      })

      if (window.env.REACT_APP_DEBUG !== 'true') {
        ReactGA.initialize(`${window.env.REACT_APP_GA_ID}`)
        ReactGA.send({
          hitType: 'pageview',
          page: `${window.location.pathname + window.location.search}`,
        })
      }
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    return <div>{this.props.children}</div>
  }
}
export default withRouter(App)
