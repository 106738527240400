import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import styled from "styled-components";

import styles from "styles/styles";
import Spinner from "components/common/Spinner";

import { Actions as StatementActions } from "redux/features/banking/statements"

const Statements = () => {
    // Redux state
    const dispatch = useDispatch();
    const banking = useSelector((state: any) => state.banking)
    const statementsState = useSelector((state: any) => state.statements)

    // Component state
    const [selectedCSVStatementID, setSelectedCSVStatementID] = useState<string | undefined>(undefined)    
    const [selectedPDFStatementID, setSelectedPDFStatementID] = useState<string | undefined>(undefined)
    const [toggledYears, setToggledYears] = useState<any>({})

    const toggleYear = (year: string) => {
        const copyOfToggledYears = { ...toggledYears }

        if (copyOfToggledYears[year] === false) {
            copyOfToggledYears[year] = true
        } else {
            copyOfToggledYears[year] = false
        }

        setToggledYears(copyOfToggledYears)
    }

    const getStatementsContent = () => {
        if (!statementsState.statements.length) {
            return <CenteredFlexContent>
                <EmptyMessage>There are no statements for your account yet.</EmptyMessage>
            </CenteredFlexContent>
        }

        let years = {} as any
        statementsState.statements.forEach((statement: any) => years[moment(statement.date).year().toString()] = 1)

        return Object.keys(years).sort((a: string, b: string) => Number(b) - Number(a)).map(year => {
            const handleToggleClick = () => { toggleYear(year) }

            return <YearContainer>
                <YearHeader>{year}
                    <CollapseButton onClick={handleToggleClick}>
                        {toggledYears[year] === false ? '+' : '-'}
                    </CollapseButton>
                </YearHeader>
                {!(toggledYears[year] === false) && statementsState.statements.filter((statement: any) => moment(statement.date).year().toString() === year).map((statement: any, index: number) => {
                    const downloadCSVStatement = () => {
                        const { id, startDate, endDate } = statement;

                        setSelectedCSVStatementID(id);
                        dispatch(StatementActions.downloadStatement({
                            startDate,
                            endDate,
                            id,
                            filetype: 'CSV',
                            cardAccountUuid: banking.account.uuid,
                        }))
                    }

                    const downloadPDFStatement = () => {
                        const { id } = statement;

                        setSelectedPDFStatementID(id);
                        dispatch(StatementActions.getStatementLink({
                            cardAccountUuid: banking.account.uuid,
                            uuid: statement.id,
                        }))
                    }

                    return <Statement key={`statement:${index}`}>
                        <div>{moment(statement.date).format('MMMM YYYY')} Statement</div>
                        <DownloadLinksContainer>
                            {selectedPDFStatementID === statement.id ? <DocumentLoadingContainer>
                                <Spinner/>
                            </DocumentLoadingContainer> : <DownloadLink onClick={downloadPDFStatement}>PDF</DownloadLink>}
                            {selectedCSVStatementID === statement.id ? <DocumentLoadingContainer>
                                <Spinner/>
                            </DocumentLoadingContainer> : <DownloadLink onClick={downloadCSVStatement}>CSV</DownloadLink>}
                        </DownloadLinksContainer>
                    </Statement>
                })}
            </YearContainer>
        })
    }

    const downloadCSV = () => {
        const fixedData = statementsState.statementGeneration.data.join('')
        const url = window.URL.createObjectURL(new Blob([fixedData], { type: 'text/csv' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', statementsState.statementGeneration.filename)
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        setSelectedCSVStatementID(undefined)
    }

    // On component load or banking account change, fetch card account statements
    useEffect(() => {
        setToggledYears({})
        dispatch(StatementActions.fetchStatements({ cardAccountUuid: banking.account?.uuid }))
    }, [banking, dispatch])

    // When the loading state of statementGeneration changes, download
    useEffect(() => {
        if (statementsState.statementGeneration.loading === false && selectedCSVStatementID) {
            downloadCSV()
        }
    }, [statementsState.statementGeneration.loading]) // eslint-disable-line

    // When PDF statementLink is state changes, open it
    useEffect(() => {
        if (statementsState.statementLink !== '' && selectedPDFStatementID) {
            window.open(statementsState.statementLink, '_blank')
            setSelectedPDFStatementID(undefined)
        }
    }, [statementsState.statementLink]) // eslint-disable-line

    return <Container>
        {(banking.isLoading && statementsState.isLoading) ? <CenteredFlexContent>
            <SpinnerContainer>
                <Spinner/>
            </SpinnerContainer>
        </CenteredFlexContent> : getStatementsContent()}
    </Container>
}

const EmptyMessage = styled.div`
    ${styles.Text.HeadingMedium}    
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
`

const CollapseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${styles.BorderRadius.Half};
    width: 24px;
    height: 24px;
    user-select: none;
    cursor: pointer;
    &:hover {
        background-color: lightgrey;
    }
`

const YearHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid black;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
`

const YearContainer = styled.div`
    width: 100%;
    ${styles.MediaQueries.Desktop} {
        margin-top: ${styles.Spacing.S};
    }
`

const Statement = styled.div`
    flex: 1;
    height: ${styles.Spacing.L};
    padding: ${styles.Spacing.S} 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
`

const CenteredFlexContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const DocumentLoadingContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
`

const DownloadLinksContainer = styled.div`
    display: flex;
    width: 100px;
    justify-content: space-between;
    align-items: center;
`

const DownloadLink = styled.div`
    cursor: pointer;
    color: #5555ff;
`

const SpinnerContainer = styled.div`
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    ${styles.MediaQueries.Mobile} {
        height: 100%;
    }
`

export default Statements