import { UserAccessLevel, UserAccessStatus } from "components/pages/Settings/types";

const messages = {
    Developers: {
        Labels: {
            ApiKeyPrefix: 'Prefix',
            ApiKeyCreatedDate: 'Created Date',
            ApiKeyExpiryDate: 'Expiry Date',
            ApiKeyActions: 'Actions',
        },
        Description: {
            ApiKeyNoAccess: 'This page is for developers who want to interact with the Taekus API. For more information, please reach out to ',
            ApiKeyApiDocPretext: 'For more details, refer to the',
            ApiKeyMainDescription: "Use the Taekus API to interact with your Taekus account via computer.\n\nNote that the Taekus API is an advanced feature intended for software developers. If you are not sure what an API is or why you might want it, you should not use the features below. An API key is like a password to your account and will allow anyone with a copy of the key access to your money.\n\nThe Taekus API is organized around REST. Our API has predictable resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs.",
            ApiKeyMainWarningTitle: 'Managing your keys',
            ApiKeyMainWarningTitleList: [
                "Anyone with access to your API key can view and make changes to your account.",
                "Don't upload your API keys into a version control system.",
                "Don't embed your API keys where the keys are publicly accessible.",
                "Delete the API key if you believe they may have been exposed."
            ],
            ApiKeyExpiryDate: 'Please select an expiry time for your API Key.',
            ApiKeyModal: {
                DeleteConfirmation: 'Are you sure you want to delete your API Key?',
                RegenerateConfirmation: 'Are you sure you want to delete your API Key and generate a new one?',
                CreateConfirmation:  'Warning! Generating an API key will grant anyone with the key, access to your account. Do NOT share this with any unauthorized user.',
                CreationSuccess: 'This is the only time that the API Key can be viewed or downloaded. You cannot recover them later. If you lose this key, please regenerate a new API key.',
            }
        }
    },
    UserAccess: {
        Level: {
            [UserAccessLevel.FullAccess]: {
                label: 'Full Access',
                description: 'Can act as the main user and do everything the main user can do (including getting physical card)',
            },
            [UserAccessLevel.AuthorizedUser]: {
                label: 'Authorized User',
                description: "Can create their own cards, get their own physical card, and manage purchases they make, but not see the parent user's purchases or manage other authorized users.",
            },
            [UserAccessLevel.AccountManager]: {
                label: 'Account Manager',
                description: 'Can make payments on the account, view charges and account statements, and redeem rewards. Cannot view payment cards or order a physical card.',
            },
            [UserAccessLevel.Accountant]: {
                label: 'Accountant',
                description: 'Read only access',
            },
        },
        Status: {
            [UserAccessStatus.Active]: 'Active',
            [UserAccessStatus.Pending]: 'Pending',
            [UserAccessStatus.Removed]: 'Removed',
            [UserAccessStatus.InviteExpired]: 'Invite Expired',
        }
    }
}

export default messages;


