import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FormattedMessage } from 'react-intl'

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import Spinner from 'components/common/Spinner'

import { fetchCardDetails } from 'components/pages/Cards/cardsSlice'
import { CardAccountType } from 'components/pages/Cards/types'

import { getCardBackgroundURL } from 'components/pages/Cards/utils'

import { ReactComponent as MastercardLogo } from 'assets/old/img/banking/desaturated_mastercard_logo.svg'
import { ReactComponent as VisaLogo } from 'assets/old/img/banking/desaturated_visa_logo.svg'

import { fadeInOutMotionProps } from 'styles/motionConstants'
import styles from 'styles/styles'

type CardDetailsProps = {
  userName?: string,
  error: any,
  isPhysicalCard?: boolean,
  cardType?: any,
  cardAccountUuid: string,
  cardUuid: string,
}

const CardDetails = (props: CardDetailsProps) => {
  const dispatch = useDispatch()

  // Redux state
  const cardNetwork = useSelector((state: any) => state.banking.account.network)
  const cardDetails = useSelector((state: any) => state.cards.previewCardDetails)
  const isLoadingCardDetails = useSelector((state: any) => state.cards.isLoadingCardDetails)
  const isMobile = useSelector((state: any) => state.global.isMobile)
  
  // Component state
  const [copyMessageText, setCopyMessageText] = useState(`${isMobile ? 'Tap' : 'Click'} on card details above to copy.`)

  const physicalCardCopyText = 'Card details are not available for physical cards.'
  const isLoading = isLoadingCardDetails || !cardNetwork || !props.userName

  const createCopyHandler = (value: string, label: string) => {
    return () => {
      navigator.clipboard.writeText(value);
      setCopyMessageText(`${label} copied!`)
    }
  }

  useEffect(() => {
    if (!props.isPhysicalCard) {
      dispatch(fetchCardDetails(props.cardAccountUuid, props.cardUuid))
    }
  }, [dispatch]) // eslint-disable-line

  const { cardType, isPhysicalCard, userName } = props;
  return (
    <Container>
      <CardBackground
        cardType={cardType} 
        isPhysicalCard={isPhysicalCard}
        className="CardMockContainer"
      >
        {isLoading ? <SpinnerContainer>
          <Spinner size={40}/>
        </SpinnerContainer> : <CardDetailsContainer {...fadeInOutMotionProps}>
          <CardDetailsHeader>
            <div className='w-100 d-flex'>
              <ClickableCardDetails visible={!props.isPhysicalCard}>
                <CardNoContainer>
                  <CopyContainer onClick={createCopyHandler(cardDetails?.pciPan, 'Card number')}>
                    {cardDetails?.pciPan?.match(/.{1,4}/g).join(' ')}
                  </CopyContainer>
                </CardNoContainer>
                <div className='d-flex'>
                  <SmallDetailContainer>
                    <DetailLabel>CVV</DetailLabel>
                    <CopyContainer onClick={createCopyHandler(cardDetails?.pciCvv, 'CVV')}>
                      {cardDetails?.pciCvv}
                    </CopyContainer>
                  </SmallDetailContainer>
                  <SmallDetailContainer>
                    <DetailLabel>EXP</DetailLabel>
                    <CopyContainer onClick={createCopyHandler(cardDetails?.pciExpirationDate, 'Expiration date')}>
                      {cardDetails?.pciExpirationDate?.match(/.{1,2}/g).join('/')}
                    </CopyContainer>
                  </SmallDetailContainer>
                </div>
              </ClickableCardDetails>
              {cardNetwork && <LogoContainer {...fadeInOutMotionProps}>
                {cardNetwork === 'MASTERCARD' ? <StyledMastercardLogo/> : <StyledVisaLogo/>}
              </LogoContainer>}
            </div>
          </CardDetailsHeader>
          {!props.isPhysicalCard && <Name>{userName}</Name>}
        </CardDetailsContainer>}
        <Bar />
      </CardBackground>
      <div id="copy-details-message" className="text-size-07 text-center mx-4 pt-2 w-100">
        <AnimatePresence mode='wait'>
          <CopyMessage key={copyMessageText} {...fadeInOutMotionProps}>
            <FormattedMessage
              id="card-mock.copy-message"
              defaultMessage={isPhysicalCard ? physicalCardCopyText : copyMessageText}
            />
          </CopyMessage>
        </AnimatePresence>
      </div>
    </Container>
  )
}

const LogoContainer = styled(motion.div)`
  height: 40px;
  display: flex;
  align-items: center;
`

const SmallDetailContainer = styled.div`
  display: flex;
  align-items: end;
  &:first-child {
    margin-right: ${styles.Spacing.XS};
  }
`

const CardNoContainer = styled.div`
  margin-bottom: ${styles.Spacing.XS};
`

const CopyMessage = styled(motion.div)`
  font-size: 1.2em;
`

const DetailLabel = styled.div`
  font-size: 0.8em;
  margin-right: ${styles.Spacing.XS};
`

type ClickableCardDetailsProps = {
  visible: boolean;
}

const ClickableCardDetails = styled.div<ClickableCardDetailsProps>`
  flex: 1;
  visibility: ${props => props.visible ?  'visible' : 'hidden'};
`

const CardDetailsHeader = styled.div`
  padding: 1.5em;
`

const Name = styled.div`
  font-size: 1.2em;
  line-height: 1.2em;
  margin: 0 1.5rem;
  flex: 1;
  display: flex;
  align-items: center;
  font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Bar = styled.div`
  width: 100%;
  height: 22.47%;
  opacity: 0.1;
  background: white;
`

const CardDetailsContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const CopyContainer = styled.div`
  width: fit-content;
  padding: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  font-family: ${styles.Font.Family.MonumentGroteskMono};
  font-size: 1.2em;
  line-height: 1.2em;
  ${styles.Animation.transitionStyles}
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
`

type CardBackgroundProps = {
  isPhysicalCard?: boolean,
  cardType: CardAccountType
}

const CardBackground = styled.div<CardBackgroundProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.5rem;
  padding: 0;
  ${props => props.isPhysicalCard && `
    background-image: url(${getCardBackgroundURL(props.cardType)});
    background-size: cover;
    border: 1px solid rgba(100,100,100,0.2);
    color: black;
  `}
`

const StyledVisaLogo = styled(VisaLogo)`
  width: 60px;
  height: auto;
`

const StyledMastercardLogo = styled(MastercardLogo)`
  width: 60px;
  height: auto;
`

export default CardDetails
