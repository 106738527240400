import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styled from "styled-components"

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import CardStatusDropdown from "components/pages/Cards/CardStatusDropdown";
import { setPreviewCard } from "components/pages/Cards/cardsSlice";

import { ReactComponent as ShowCardIcon } from "assets/svg/RemoveRedEye.svg";

import styles from "styles/styles";
import MiniCard from "../MiniCard";

type CardMobileItemProps = {
    card: any,
    setIsMockCardModalOpen: (isOpen: boolean) => void,
    style: any,
}

const CardMobileItem = (props: CardMobileItemProps) => {
    // Redux state
    const dispatch = useDispatch();
    const cardAccountUuid = useSelector((state: any) => state.banking.account.uuid)
    
    const handleShowMockCard = (ev: React.MouseEvent<HTMLElement>) => { 
        props.setIsMockCardModalOpen(true)
        dispatch(PaymentCardActions.fetchPaymentCardToken({ 
            cardAccountUuid,
            uuid: props.card.uuid
        }));
        dispatch(setPreviewCard(props.card))
        ev.preventDefault();
    }

    const stopPropagation = (ev: React.MouseEvent) => {
        ev.stopPropagation()
    }

    const updateCardStatus = (selectedOption: any) => {
        dispatch(PaymentCardActions.updatePaymentCard({
            ...props.card,
            cardAccountUuid,
            status: selectedOption.value,
            oldCardStatus: props.card.status,
            tags: Array.from(props.card.tags)
        }))
    }
    
    const { card } = props;

    const isPhysicalCard = card.formFactor === 'PHYSICAL'

    return <Container style={props.style}>
        <ClickableArea as={Link} to={`cards/${cardAccountUuid}/${card.uuid}`}>
            <StyledMiniCard card={card}/>
            <CardInfo>
                <CardName>{isPhysicalCard ? 'Physical Card' : card.nickname}</CardName>
                <CardSubRow>
                    <CardStatusContainer onClick={stopPropagation}>
                        <CardStatusDropdown onChange={updateCardStatus} status={card.status}/>
                    </CardStatusContainer>
                    {!isPhysicalCard && <CardLastFour>...{card.lastFour}</CardLastFour>}
                </CardSubRow>
            </CardInfo>
            <ShowCardIconContainer onClick={handleShowMockCard}>
                {!isPhysicalCard && <ShowCardIcon className="w-100 h-100"/>}
            </ShowCardIconContainer>
        </ClickableArea>
    </Container>
}

const CardName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const CardInfo = styled.div`
    overflow: hidden;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: space-between;
`

const CardSubRow = styled.div`
    font-size: ${styles.Font.Size.Small};
    display: flex;
    align-items: center;
`

const CardStatusContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${styles.Spacing.XS};
`

const ShowCardIconContainer = styled.div`
    height: auto;
    width: ${styles.Spacing.S};
    display: flex;
    align-items: center;
    margin: 0 ${styles.Spacing.XXS};
`

const CardLastFour = styled.div`
    padding-top: 3px;
`

const ClickableArea = styled.div`
    color: inherit;
    text-decoration: inherit;
    border: 1px solid ${styles.Color.Grey};
    height: 74px;
    padding: ${styles.Spacing.XS};
    display: flex;
    alignItems: center;
    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
`

const Container = styled.div`
    padding-top: ${styles.Spacing.S};
`

const StyledMiniCard = styled(MiniCard)`
    width: ${styles.Spacing.L};
    height: 38px;
    margin-right: ${styles.Spacing.XS};
`

export default CardMobileItem