export enum TicketStatus {
    TICKETED = "TICKETED",
    IN_PROGRESS = "IN_PROGRESS",
    PROCESSING = "PROCESSING",
    CREATED = "CREATED",
    TICKETING_ERROR = "TICKETING_ERROR",
    ERROR = "ERROR",
    UNATTEMPTED_ERROR = "UNATTEMPTED_ERROR",
    CANCELLED = "CANCELLED",
    CANCEL_STARTED = "CANCEL_STARTED",
    CANCEL_ERROR = "CANCEL_ERROR",
    UNAVAILABLE = "UNAVAILABLE"
}