import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { Actions } from 'redux/features/banking/transfers';

import Spinner from 'components/common/Spinner';

import TransferActivityItem from 'components/pages/Funding/TransferActivityItem';

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles';

const TransferActivity = () => {
    // Redux state
    const dispatch = useDispatch()
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const banking = useSelector((state: any) => state.banking)
    const transfers = useSelector((state: any) => state.transfers.transfers)
    const isLoadingTransfers = useSelector((state: any) => state.transfers.isLoading)
    const cancelTransferStatus = useSelector((state: any) => state.transfers.cancelTransfer.submitting)
    
    const isEmpty = !transfers.length
    const isLoading = isLoadingTransfers || banking.isLoading


    const mapTransferToRow = (transfer: any, index: number) => {
        return <TransferActivityItem transfer={transfer} index={index} key={`transfer-${index}`} />
    }

    useEffect(() => {
        dispatch(Actions.fetchTransfers({ cardAccountUuid: banking.account.uuid }))
    }, [ banking.account.uuid, dispatch])

    useEffect(() => {
        if (cancelTransferStatus === true) {
            setTimeout(() => { dispatch(Actions.fetchTransfers({ cardAccountUuid: banking.account.uuid })) }, 2000) 
        }
    }, [cancelTransferStatus, dispatch]) // eslint-disable-line

    return <Container>
        <Table>
            <TableContent {...fadeInOutMotionProps}>
                {!isMobile && <HeaderRow>
                    <HeaderItem width='15%'>Date</HeaderItem>
                    <HeaderItem width='27.5%'>From</HeaderItem>
                    <HeaderItem width='27.5%'>To</HeaderItem>
                    <HeaderItem width='15%'>Amount</HeaderItem>
                    <HeaderItem width='15%'>Type</HeaderItem>
                    <HeaderItem width='10%'>Status</HeaderItem>
                </HeaderRow>}
                {(!isLoading && !isEmpty) && transfers?.map(mapTransferToRow)}
            </TableContent>
        </Table>
        <AnimatePresence mode='wait'>
            {isLoading ? <SpinnerArea {...fadeInOutMotionProps}>
                <SpinnerContainer>
                    <Spinner/>
                </SpinnerContainer>
            </SpinnerArea> : (isEmpty && <EmptyMessage {...fadeInOutMotionProps} animate={{opacity: 0.5}}>
                There are no transfers to display.
            </EmptyMessage>)}
        </AnimatePresence>
    </Container>
}

const SpinnerArea = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`

const TableContent = styled.tbody`
    ${styles.Animation.transitionStyles}
`

const EmptyMessage = styled(motion.div)`
    ${styles.Text.HeadingMedium}    
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
`

type HeaderItemProps = {
    width: string,
}

const HeaderItem = styled.th<HeaderItemProps>`
    width: ${props => props.width};
    font-weight: ${styles.Font.Weight[500]};
    color: ${styles.Color.NearBlack};
    border-bottom: 2px solid ${styles.Color.Black};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    &:first-child {
        padding-left: ${styles.Spacing.XS};
    }
`

const SpinnerContainer = styled.div`
    height: ${styles.Spacing.S};
    width: ${styles.Spacing.S};
`

const HeaderRow = styled.tr``

const Table = styled.table`
    gap: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.M};
    width: 100%;
    table-layout: fixed;
`

const Container = styled.div`
    min-height: 300px;
    margin-top: ${styles.Spacing.S};
    display: flex;
    flex-direction: column;
    padding: ${styles.Spacing.S} 0;
    width: 100%;
`

export default TransferActivity;