import Axios from 'axios'
import URI from 'urijs'
import Session from 'services/session'

import camelCaseKeys from '../utils/camelCaseKeys'
import snakeCaseKeysObjectsOnly from '../utils/snakeCaseKeysObjectsOnly'

import { AppPath } from 'components/appRouter/constants'

const searchURI = (route, opts) => new URI(route).search(snakeCaseKeysObjectsOnly(opts))

const API = {
  init() {
    // snakeCaseKeys only Objects, and not things like FormData
    Axios.defaults.transformRequest = [snakeCaseKeysObjectsOnly, ...Axios.defaults.transformRequest]

    // Transform json response keys from snake_case to camelCase
    Axios.defaults.transformResponse = [...Axios.defaults.transformResponse, camelCaseKeys]

    // set access tokens to API headers
    Axios.interceptors.request.use(
      (config) => {
        let configPromise = new Promise((resolve, reject) => {
          const accessToken = localStorage.getItem('accessToken')
          if (accessToken && accessToken !== 'undefined') {
            const tokenExpTime = JSON.parse(window.atob(accessToken.split('.')[1]))['exp'] * 1000
            const refreshToken = localStorage.getItem('refreshToken')
            if (tokenExpTime < Date.now() && refreshToken && '/api/user/refresh/' !== config.url) {
              API.auth.refreshAccessToken(refreshToken).then((response) => {
                const { access } = response.data
                localStorage.setItem('accessToken', access)
                config.headers.authorization = `Bearer ${access}`
                resolve(config)
              })
            } else {
              config.headers.authorization = `Bearer ${accessToken}`
              resolve(config)
            }
          } else {
            resolve(config)
          }
        })

        return configPromise
      },
      (error) => Promise.reject(error)
    )

    // Handles token refresh
    Axios.interceptors.response.use(
      (response) => {
        // If all is well with the response pass it forward
        return response
      },
      (error) => {
        // If the response has a non-unauthorized error pass it back to the caller for handling
        if (error.response?.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error)
          })
        }

        // If the unauthorized response was from attempting to refresh a
        // token, logout the user and direct them to the login page
        // TODO: there is a known bug here -- when a url not in this list (and which doesn't hit an endpoint
        // TODO: in this list) returns 401 we get an infinite loop of retries to keep hitting the endpoint.
        // TODO: very rare in normal usage (since most endpoints hit refresh) but should be fixed eventually.
        const { url } = error.config
        if (
          url === '/api/user/refresh/' ||
          url === '/api/user/signup/' ||
          url === '2fa/verify/token/'
        ) {
          Session.logout()
          if (Object.values(AppPath).includes(window.location.pathname)) {
            localStorage.setItem('redirect', window.location.pathname)
          }
          window.location.replace(`/login`)
          return new Promise((resolve, reject) => {
            reject(error)
          })
        }

        // Else refresh the access token and try again
        const refreshToken = localStorage.getItem('refreshToken')
        if (error.response.status === 401 && refreshToken) {
          return API.auth.refreshAccessToken(refreshToken).then((response) => {
            const { access } = response.data
            localStorage.setItem('accessToken', access)

            const originalRequest = error.config
            originalRequest.headers.authorization = `Bearer ${access}`
            return Axios(originalRequest)
          })
        }
        return Promise.reject(error)
      }
    )
  },

  auth: {
    login(username, password, rememberMe) {
      return Axios.post(`/api/user/token/`, { username, password, rememberMe })
    },

    verifyToken(username, password, rememberMe, token) {
      return Axios.post(`/api/user/2fa/verify/token/`, { username, password, rememberMe, token })
    },

    signup(userParams) {
      return Axios.post(`/api/user/signup/`, userParams)
    },

    signupAuthorizedUser(userParams) {
      return Axios.post(`/api/user/signup-authorized-user/`, userParams)
    },

    getApplication(applicationUuid) {
      return Axios.get(`/api/user/application/${applicationUuid}`)
    },

    updateApplicationWithDocumentResults(applicationUuid, documentResults) {
      return Axios.post(
        `/api/user/document-verification-results/${applicationUuid}`,
        documentResults
      )
    },

    signupBusiness(businessParams) {
      return Axios.post(`/api/user/signup/business`, businessParams)
    },

    verifyPhone(phone) {
      return Axios.post(`/api/user/2fa/verify/phone/`, { phone })
    },

    registerPhone(phone, token) {
      return Axios.post(`/api/user/2fa/register/phone/`, { phone, token })
    },

    refreshAccessToken(refresh) {
      return Axios.post(`/api/user/refresh/`, { refresh })
    },

    fetchCurrentUser() {
      return Axios.get(`/api/user/`)
    },

    addAddress(params) {
      return Axios.post(`/api/user/address/`, params)
    },

    updateCurrentUser(userParams) {
      return Axios.patch(`/api/user/`, userParams)
    },

    addAuthorizedUser(params) {
      return Axios.post(`/api/user/authorized-user/`, params)
    },

    updateAuthorizedUser(params) {
      return Axios.patch(`/api/user/authorized-user/`, params)
    },

    getBusinessDetails(params) {
      return Axios.get(`/api/user/business/`, { params })
    },

    getZendeskJWT() {
      return Axios.get(`/api/user/get-jwt/`)
    },

    fetchApiKeys() {
      return Axios.get(`/api/user/api-key/`)
    },
    createApiKey(params) {
      return Axios.post(`/api/user/api-key/`, { ...params })
    },
    revokeApiKey(params) {
      return Axios.delete(`/api/user/api-key/`, { params })
    },

    uploadPassport(formData) {
      return Axios.post(`/api/user/passport/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    },
    deletePassport() {
      return Axios.delete(`/api/user/passport/`)
    },
  },

  flights: {
    airportSearch(query) {
      return Axios.get(searchURI('/api/booking/flights/airports', { query: query }))
    },
    itinerarySearch(searchParams, resetSearch, controller) {
      return Axios.post(
        `/api/booking/flights/itinerary/`,
        {
          ...searchParams,
          resetSearch: resetSearch,
        },
        {
          signal: controller.signal,
        }
      )
    },
    calendarSearch(searchParams, controller) {
      return Axios.post(`/api/booking/flights/calendar/`, searchParams, {
        signal: controller.controller.signal,
      })
    },
    calendarGridSearch(searchParams, controller) {
      return Axios.post(`/api/booking/flights/grid/`, searchParams, {
        signal: controller.controller.signal,
      })
    },
    awardSearch(searchParams, controller) {
      return Axios.post(`/api/booking/flights/award/`, searchParams, {
        signal: controller.signal,
      })
    },
    supplementaryAwardSearch(searchParams, controller) {
      return Axios.post(`/api/booking/flights/award/more`, searchParams, {
        signal: controller.signal,
      })
    },
    liveAwardSearch(searchParams, controller) {
      return Axios.post(`/api/booking/flights/award/live`, searchParams)
    },
    price(searchParams) {
      return Axios.post(`/api/booking/flights/price/`, searchParams)
    },

    book(bookingParams) {
      return Axios.post(`/api/booking/flights/book/`, bookingParams)
    },

    bookingStepPassportUpload(params) {
      return Axios.post(`/api/booking/flights/book/passport/`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    },

    bookingStepPassportDelete(params) {
      return Axios.delete(`/api/booking/flights/book/passport/`, { params })
    },

    createTraveler(params) {
      return Axios.post(`/api/booking/flights/traveller/`, params)
    },
    getTraveler(params) {
      return Axios.get(`/api/booking/flights/traveller/`)
    },
    deleteTraveler(params) {
      return Axios.delete(`/api/booking/flights/traveller/`, { params })
    },
    fetchSeatmap(params, controller) {
      return Axios.get(`/api/booking/flights/seatmap/`, { params }, { signal: controller.signal })
    },
  },

  banking: {
    fetchTransactions(params, controller) {
      return Axios.get('/api/banking/transactions/', { params, signal: controller.signal })
    },
    updateTransaction(params) {
      return Axios.patch('/api/banking/transactions/', { ...params })
    },
    fetchCardAccount(params, controller) {
      return Axios.get('/api/banking/account/', { params, signal: controller.signal })
    },
    updateCardAccount(params) {
      return Axios.patch('/api/banking/account/', params)
    },
    fetchOneTimeAccountToken(params) {
      return Axios.get('/api/banking/account/token/', { params })
    },
    updateNotificationSettings(params) {
      return Axios.post('/api/banking/account/notifications/', params)
    },
    lookupAccount(params) {
      return Axios.get('/api/banking/account/lookup/', { params })
    },
    transfers: {
      fetch(params) {
        return Axios.get('/api/banking/transfers/', { params })
      },
      create(params) {
        return Axios.post('/api/banking/transfers/', params)
      },
      delete(params) {
        return Axios.delete('/api/banking/transfers/', { params })
      },
      createPeerTransfer(params) {
        return Axios.post('/api/banking/transfers/peer/', params)
      },
    },
    statements: {
      fetch(params) {
        return Axios.get('/api/banking/statements/', { params })
      },
      download(params) {
        return Axios.get('/api/banking/statements/download/', { params })
      },
      getPdfUrl(params) {
        return Axios.get('/api/banking/statements/url/', { params })
      },
    },
    financialInstitutions: {
      getName(params) {
        return Axios.get('/api/banking/financial-institutions/', { params })
      },
    },
    linkedAccounts: {
      getPlaidLinkToken(params) {
        return Axios.get('/api/banking/linked-accounts/plaid/', { params })
      },

      get() {
        return Axios.get('/api/banking/linked-accounts/')
      },
      getInternal() {
        return Axios.get('/api/banking/internal-accounts/')
      },
      create(params) {
        return Axios.post('/api/banking/linked-accounts/', params)
      },
      update(params) {
        return Axios.patch('/api/banking/linked-accounts/', params)
      },
      delete(params) {
        return Axios.delete(searchURI('/api/banking/linked-accounts/', params))
      },
      verifyMDV(params) {
        return Axios.post('/api/banking/linked-accounts/verify/', params)
      },
    },
    payees: {
      get() {
        return Axios.get('/api/banking/payees/')
      },
      create(params) {
        return Axios.post('/api/banking/payees/', params)
      },
      update(params) {
        return Axios.patch('/api/banking/payees/', params)
      },
      delete(params) {
        return Axios.delete(searchURI('/api/banking/payees/', params))
      },
    },
    disputes: {
      create(params) {
        return Axios.post('/api/banking/disputes/', params)
      },
    },
    autopay: {
      get(params) {
        return Axios.get('/api/banking/autopay/', { params })
      },
      update(params) {
        return Axios.patch('/api/banking/autopay/', params)
      },
    },
    rewardsTransactions: {
      fetch(params) {
        return Axios.get('/api/banking/rewards/transactions/', { params })
      },
      createStatementCreditRequest(params) {
        return Axios.post('/api/banking/rewards/statement-credit-request/', params)
      },
    },
    paymentCards: {
      get(params, controller) {
        return Axios.get('/api/banking/payment-cards/virtual/', {
          params,
          signal: controller.signal,
        })
      },
      create(params) {
        return Axios.post('/api/banking/payment-cards/virtual/', params)
      },
      batchUpdate(params) {
        return Axios.patch('/api/banking/payment-cards/batch/', params)
      },
      update(params) {
        return Axios.patch('/api/banking/payment-cards/virtual/', params)
      },
      createPhysical(params) {
        return Axios.post('/api/banking/payment-cards/physical/', params)
      },
      activate(params) {
        return Axios.post('/api/banking/payment-cards/activate/', params)
      },
      setPin(params) {
        return Axios.post('/api/banking/payment-cards/setpin/', params)
      },
      getCardDetails(params) {
        return Axios.get('/api/banking/payment-cards/showpan/', { params })
      },
      getTransactions(params) {
        return Axios.get('/api/banking/payment-cards/transactions/', { params })
      },
      getPaymentCardToken(params) {
        return Axios.get('/api/banking/payment-cards/token/', { params })
      },
      downloadPaymentCardDetailsReport(params) {
        return Axios.get('/api/banking/payment-cards/report', { params })
      },
      uploadPaymentCardDetailsReport(params) {
        return Axios.post('/api/banking/payment-cards/report', params)
      },
    },
    credit: {
      fetchPretermDocuments(params) {
        return Axios.get('/api/banking/credit/preterm-documents/', { params })
      },
    },
  },

  trips: {
    fetchAll() {
      return Axios.get('/api/booking/flights/')
    },
    fetch(uuid) {
      return Axios.get(`/api/booking/flight/${uuid}`)
    },
    fetchRefundCost(params) {
      return Axios.get(`/api/booking/flights/cancel/`, { params })
    },
    cancel(params) {
      return Axios.delete(`/api/booking/flights/cancel/`, { params })
    },
    update(tripParams) {
      return Axios.patch(`/api/booking/flight/${tripParams.uuid}`, tripParams)
    },
  },
}

export default API
