import React from "react"

import moment from "moment";

import styled from "styled-components"

import styles from "styles/styles"


type MobileDatePickerInputProps = {
    date: moment.Moment,
    onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void,
    max?: moment.Moment,
    id?: string
}

const MobileDatePickerInput = (props: MobileDatePickerInputProps) => {
    return <Input id={props.id} type='date' onChange={props.onChange} value={props.date.format('YYYY-MM-DD')}/>
}

const Input = styled.input`
    ${styles.Text.BodySmall}
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${styles.Color.GreyText};
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
    height: 30px;
    text-align: center;
    outline: 0;
    &::-webkit-calendar-picker-indicator {
        display: none;
    }
`

export default MobileDatePickerInput