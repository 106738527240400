import { SearchOptions, TripType } from "components/pages/Rewards/constants"

export const isAmenityPopulated = (amenity: any) => {
    return !!amenity && amenity !== '' && amenity !== 'none' && amenity !== 'NO'
}

export const buildItineraryPayload = (searchOptions: SearchOptions, selectedItinerary: any, weeks: number[] = []) => {
    const firstWeekNum = searchOptions.departureDate?.clone().startOf('week').isoWeek() || 0

    return {
        activeFlight: searchOptions.tripType === TripType.RoundTrip && selectedItinerary?.length === 1 ? 'inbound' : 'outbound',
        airlines: searchOptions.airlineOption ? {
            value: searchOptions.airlineOption
        } : [],
        connectingAirport: searchOptions.connectingAirport ? {
            value: searchOptions.connectingAirport
        } : null,
        currentTripType: {
            value: searchOptions.tripType
        },
        currentWeekNum: firstWeekNum,
        departureDate: searchOptions.departureDate?.format('YYYY-MM-DD'),
        destination: {
            value: searchOptions.arrivalAirport?.value,
        },
        excludeNoCarryOn: {
            value: searchOptions.excludeNoCarryOn === 'true'
        },
        inboundItinerary: null,
        isRefundable: {
            value: searchOptions.isRefundable === 'true'
        },
        maxStops: {
            value: searchOptions.maxNumberOfStops,
        },
        numPax: {
            value: searchOptions.numberOfPassengers,
        },
        origin: {
            value: searchOptions.departureAirport?.value,
        },
        originIsCity: {
            value: searchOptions.departureAirport?.isCity === true
        },
        destinationIsCity: {
            value: searchOptions.arrivalAirport?.isCity === true
        },
        outboundItinerary: selectedItinerary?.length === 1 ? (selectedItinerary || [])[0] : null,
        resetSearch: false,
        returnDate: searchOptions.tripType === TripType.RoundTrip ? searchOptions.returnDate?.format('YYYY-MM-DD') : null,
        ticketType: {
            value: searchOptions.ticketType
        },
        travelClass: {
            value: searchOptions.travelClass
        },
        weeksToFetch: weeks || []
    }
}