import React, { Fragment } from 'react'

import { Row, Col, Form } from 'react-bootstrap'
import { GoArrowRight } from 'react-icons/go'

import { USDCurrencyOptions, Locale } from 'utils/constants'

import Logo from 'assets/global/img/taekusLogos/mini-logo-black.png'

import AirlineFullLogo from 'components/common/airlineFullLogo'

import 'assets/components/flights/flightBook/css/paymentDetailsCard.scss'

const PaymentDetailsCard = ({ transferPartner, pricePoints, priceCash, bookTicket }) => {
  const partnerCode = transferPartner

  return (
    <Form>
      <Col className="flightBook-Card detailCard">
        {partnerCode === 'XX' ? (
          <CashBooking pricePoints={pricePoints} priceCash={priceCash} />
        ) : (
          <TransferPartner
            transferPartner={transferPartner}
            pricePoints={pricePoints}
            priceCash={priceCash}
          />
        )}
        {/* <div className="text-gray text-center d-flex justify-content-center mt-5 pt-5">
          <input
            type="checkbox"
            className="mr-2 checkbox"
            onClick={() => setPaymentAgree(!paymentAgree)}
            value={paymentAgree}
          />
          {partnerCode === 'XX' ? (
            <small>I understand that points will be deducted from my Taekus card balance.</small>
          ) : (
            <small>
              I understand that the taxes and fees will be charged to my Taekus credit card.
            </small>
          )}
        </div>
        <Row className="d-flex mt-5 mx-2 bookButtonContainer">
          <Button className="bookButton" disabled={!paymentAgree} onClick={bookTicket}>
            {partnerCode === 'XX' ? (
              <span>Book ticket now</span>
            ) : (
              <span>Book ticket with {partnerName}</span>
            )}
          </Button>
        </Row> */}
      </Col>
    </Form>
  )
}

const CashBooking = ({ pricePoints, priceCash }) => {
  const fullPointsPrice = parseInt(parseFloat(pricePoints)).toLocaleString('en-US')

  return (
    <Fragment>
      {/* <div className="transferNotice text-size-15 mobileTopSpacer text-center mb-4 mt-sm-2">
        Your points will be used to book your ticket...
      </div> */}
      <Row className="d-flex justify-content-center">
        <div className="taekus-paymentBox paymentBox-cash position-relative d-flex justify-content-center align-items-center px-5 py-4">
          <div className="text mr-4">{fullPointsPrice} Points</div>
          <img src={Logo} alt={'taekus logo'} className="taekusLogo-cash align-self-center" />
        </div>
      </Row>
    </Fragment>
  )
}

const TransferPartner = ({ transferPartner, pricePoints, priceCash }) => {
  const { partnerName, partnerCode, partnerColorPrimary, conversionRate } = transferPartner
  const partnerMiles = parseInt(parseFloat(pricePoints) / conversionRate).toLocaleString('en-US')

  return (
    <Fragment>
      <div className="transferNotice text-size-15 mobileTopSpacer text-center mb-4 mt-sm-2">
        Your miles will be transferred to <br /> {partnerName} and used to book this ticket.
      </div>
      <Row className="d-flex justify-content-center">
        <div className="taekus-paymentBox position-relative d-flex flex-column justify-content-center pt-3 pb-2 px-4">
          <div className="d-flex flex-column align-items-end">
            <div className="text">{parseInt(pricePoints).toLocaleString('en-US')} Points</div>
            <div className="text-size-05">
              + {priceCash.toLocaleString(Locale.English, USDCurrencyOptions)}
            </div>
          </div>
          <GoArrowRight className="d-none d-md-block transferArrow" />
        </div>
        <div
          className="bookingPartner-paymentBox d-flex align-items-center px-4 py-3"
          style={{ borderColor: partnerColorPrimary }}
        >
          <div className="d-flex flex-column align-items-end">
            <div className="text-size-15 text mb-n2">{partnerMiles}</div>
            <div className="text-size-15 text">miles</div>
          </div>
          <div className="smallText mx-3">With</div>
          <AirlineFullLogo className="fullLogo" carrierCode={partnerCode} />
        </div>
      </Row>
    </Fragment>
  )
}

export default PaymentDetailsCard
