import React from 'react'

import styled from 'styled-components';

import Modal from 'components/common/Modal';

import { Locale, USDCurrencyOptions } from 'utils/constants';

import styles from 'styles/styles';

type Tax = {
	taxAmount: number;
	taxCode: string;
	taxName: string;
}

type FlightTaxesModalProps = {
	taxes:  Tax[];
	toggleIsOpen: () => void;
	isOpen?: boolean;
}

const FlightTaxesModal = (props: FlightTaxesModalProps) => {
	const taxComponent = (taxObject: any, index: number) => {
		return <Row key={index}>
			<RowItem>{taxObject.taxName || 'Misc. Tax / Fee'}</RowItem>
			<RowItem style={{opacity: 0.5}}>{taxObject.taxCode}</RowItem>
			<RowItem>{taxObject.taxAmount?.toLocaleString(Locale.English, USDCurrencyOptions)}</RowItem>
		</Row>
	}


	const { toggleIsOpen } = props;

    return <Modal title='Tax Details' onClose={toggleIsOpen}>
		<Container>
			<Table>
				{props.taxes?.map(taxComponent) ?? <>There are no tax details for this itinerary</>}
			</Table>
		</Container>
	</Modal>
}

const Container = styled.div`
	flex: 1;
    ${styles.Animation.transitionStyles}
`

const Table = styled.table`
	border-collapse: collapse;
    table-layout: dynamic;
	width: 100%;
`

const Row = styled.tr`
	height: 30px;
	&:not(&:last-child) {
		border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const RowItem = styled.th`
	font-weight: normal;
`

export default FlightTaxesModal