import React from 'react';

import { Dictionary } from 'components/pages/Home/constants';
import styled from 'styled-components';
import styles from 'styles/styles';

type TransactionMetadataDetailsProps = {
    transactionMetadata: Dictionary<string>[],
}

const TransactionMetadataDetails = (props: TransactionMetadataDetailsProps) => {
	const formatText = (text: string) => {
		// Convert from "camelCase" to "Title Case"
		if (!!text) {
			// Cast to string in case the text is an integer
			const result = String(text).replace(/([A-Z])/g, " $1");
			return result.charAt(0).toUpperCase() + result.slice(1);
		} else {
			return text
		}
	}

	const getValueCell = (value: any) => {
		if (value.constructor === Array) {
			return value.map(dictionaryToTable)
		}
		else if (value.constructor === Object)  {
			return dictionaryToTable(value)
		} else {
			return value
		}
	}

	const keyValueToRow = (name: string, value: any) => {
		const valueCell = getValueCell(value)
		return <Table>
			<tbody>
			<Row>
				<Cell>{formatText(name)}</Cell>
				<Cell>{valueCell}</Cell>
			</Row>
			</tbody>
		</Table>
	}

	const dictionaryToTable = (metadata: Dictionary<string>) => {
		return <div>{
			Object.keys(metadata).map((key) => {
				return keyValueToRow(key, metadata[key])
		})}
		</div>
	}

    return <Container>
		{props.transactionMetadata.map(dictionaryToTable)}
	</Container>
}


const Row = styled.tr`
`

const Cell = styled.th`
	font-style: normal;
	font-weight: 300;
	font-size: ${styles.Font.Size.Small};
	line-height: 140%;
	letter-spacing: 0.02em;
	vertical-align: top;
	white-space: nowrap;
    overflow: clip;
	padding-right: 15px;
`

const Table = styled.table`
	border-collapse: collapse;
    table-layout: fixed;
	width: 100%;
	&:not(&:last-child) {
		border-bottom: 1px solid;
	}
	&:last-child {
		margin-bottom: 10px;
	}
`


const Container = styled.div`
	margin: 10px;
	padding: 10px;
	border: 0.5px solid;
`


export default TransactionMetadataDetails;