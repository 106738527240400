import React from "react"
import Modal from "components/common/Modal"
import { useDispatch, useSelector } from "react-redux"
import { toggleSeatMapIsOpen } from "../flightsSlice"
import Spinner from "components/common/Spinner"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

enum SeatStatus {
    Available='AVAILABLE',
    Occupied='OCCUPIED',
    Blocked='BLOCKED'
}

const SeatMapModal = () => {
    const dispatch = useDispatch()
    
    // Redux state
    const title = useSelector((state: any) => state.flights.seatMap.title)
    const isLoading = useSelector((state: any) => state.flights.seatMap.isLoading)
    const seatMapData = useSelector((state: any) => state.flights.seatMap.data)
    const seatMapError = useSelector((state: any) => state.flights.seatMap.error)

    const closeSeatMapModal = () => {
        dispatch(toggleSeatMapIsOpen({}))
    }

    return <Modal title={title} onClose={closeSeatMapModal}>
        <Content>
            <Scrollable isError={seatMapError}>
                <AnimatePresence>
                    {isLoading ? <SpinnerContainer {...fadeInOutMotionProps}>
                        <Spinner size={40}/>
                    </SpinnerContainer> : (seatMapError ? <EmptyText {...fadeInOutMotionProps}>
                        <div>Seat map is unavailable for this flight.</div>
                    </EmptyText> : <SeatmapTableContainer {...fadeInOutMotionProps}>
                        <SeatmapTable>
                            {seatMapData[0]?.seatData?.map((seatRow: any) => {
                                const cabin = seatMapData[0].cabins.find((cabin: any) => 
                                    cabin.range[0] <= seatRow.rowNum && cabin.range[1] >= seatRow.rowNum
                                )

                                return <SeatMapRow isOverWing={seatRow.isOverwing}>
                                    {cabin?.structure?.map((subArray: any, index: number) => {
                                        let mappedSeats = subArray.map((seatKey: string) => <SeatMapCell>
                                            {seatRow.seats[seatKey.toLowerCase()] && <Seat status={seatRow.seats[seatKey.toLowerCase()]?.status}>
                                                {seatRow.seats[seatKey.toLowerCase()]?.status === SeatStatus.Occupied && <OccupiedIndicator/>}
                                            </Seat>}
                                        </SeatMapCell>)

                                        if (index !== cabin.structure.length - 1) {
                                            mappedSeats.push(<SeatMapCell/>)
                                        }

                                        return mappedSeats
                                    }).flat()}
                                </SeatMapRow>
                            })}
                        </SeatmapTable>
                    </SeatmapTableContainer>)}
                </AnimatePresence>
            </Scrollable>
            {!isLoading && !seatMapError && <Legend {...fadeInOutMotionProps}>
                <LegendItem>
                    Available
                    <Seat status={SeatStatus.Available}/>
                </LegendItem>
                <LegendItem>
                    Occupied
                    <Seat status={SeatStatus.Occupied}>
                        <OccupiedIndicator/>
                    </Seat>
                </LegendItem>
                <LegendItem>
                    Blocked
                    <Seat status={SeatStatus.Blocked}/>
                </LegendItem>
                <LegendItem>
                    Plane Wing
                    <PlaneWingItemContainer>
                        <PlaneWingItem/>
                    </PlaneWingItemContainer>
                </LegendItem>
            </Legend>}
        </Content>
    </Modal>
}

const SeatmapTableContainer = styled(motion.div)`
    flex: 1;
    display: flex;
    align-items: center;
`

const SeatmapTable = styled.table`
    width: 100%;
`

const EmptyText = styled(motion.div)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${styles.Color.GreyText};
`

const PlaneWingItemContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 20px;
`

const PlaneWingItem = styled.div`
    height: 20px;
    width: 8px;
    background-color: ${styles.Color.GreyText};
`

const LegendItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
`

const Content = styled.div`
    position: relative;
    flex: 1;
    display: flex;
`

const OccupiedIndicator = styled.div`
    min-width: 30px;
    height: 1px;
    rotate: 45deg;
    background-color: ${styles.Color.TaekusBlue};
`

const Legend = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    border-radius: 2px;
    border: 1px solid ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-weight: 500;
`

type ScrollableProps = {
    isError?: boolean,
}

const Scrollable = styled.div<ScrollableProps>`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 80dvh;
    width: 100%;
    ${props => !props.isError && `
        padding: 20px 140px;
        margin: 0 17px;
    `}
    overflow-y: auto;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

type SeatProps = {
    status: SeatStatus
}

const Seat = styled.div<SeatProps>`
    position: relative;
    overflow: hidden;
    width: 20px;
    height: 20px;
    border: 1px solid ${props => props.status === SeatStatus.Blocked ? styles.Color.GreyText : styles.Color.TaekusBlue};
    background-color: ${props => props.status === SeatStatus.Blocked ? styles.Color.GreyText : (props.status === SeatStatus.Available ? styles.Color.TaekusBlue : 'transparent')};
    // ${props => props.status === SeatStatus.Occupied && `background: linear-gradient(to top right,
    //     rgba(0,0,0,0) 0%,
    //     rgba(0,0,0,0) calc(50% - 1px),
    //     ${styles.Color.TaekusBlue} 50%,
    //     rgba(0,0,0,0) calc(50% + 1px),
    //     rgba(0,0,0,0) 100%);
    // `}
    display: flex;
    justify-content: center;
    align-items: center;
`

const SeatMapCell = styled.td`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 22px;
    min-height: 22px;
`

type SeatMapRowProps = {
    isOverWing: boolean,
}

const SeatMapRow = styled.tr<SeatMapRowProps>`
    display: flex;
    justify-content: center;
    flex: 0;
    padding: 0 6px;
    border-left: 8px solid ${props => props.isOverWing ? styles.Color.GreyText : 'transparent'};
    border-right: 8px solid ${props => props.isOverWing ? styles.Color.GreyText : 'transparent'};
`

const SpinnerContainer = styled(motion.div)`
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export default SeatMapModal