export const SIDEBAR_WIDTH = 240

export const messages = {
    CardStatusLabel: {
        ACTIVE: 'Active',
        SUSPENDED: 'Suspended',
        TERMINATED: 'Terminated',
        UNACTIVATED: 'Unactivated'
    },
    CardTypeLabel: {
        FREE_USE: 'Free Use',
        SINGLE_USE_LOCKED: 'Single Use',
        ONCE_MONTH_LOCKED: 'Once Per Month',
        MERCHANT_LOCKED: 'Merchant Locked',
        MCC_LOCKED: 'MCC Locked',
        MAX_LIMIT_LOCKED: 'Maximum Limit'
    }
}

export enum CardStatus {
    ACTIVE='ACTIVE',
    SUSPENDED='SUSPENDED',
    TERMINATED='TERMINATED',
    UNACTIVATED='UNACTIVATED'
}

export enum CardType {
    FREE_USE='FREE_USE',
    SINGLE_USE_LOCKED='SINGLE_USE_LOCKED',
    ONCE_MONTH_LOCKED='ONCE_MONTH_LOCKED',
    MERCHANT_LOCKED='MERCHANT_LOCKED',
    MCC_LOCKED='MCC_LOCKED',
    MAX_LIMIT_LOCKED='MAX_LIMIT_LOCKED'
}
