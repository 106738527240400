export enum UserAccessLevel {
    Accountant='ACCOUNTANT',
    AccountManager='ACCOUNT_MANAGER',
    AuthorizedUser='AUTHORIZED_USER',
    FullAccess='FULL_ACCESS',
}

export enum UserAccessStatus {
    Active,
    InviteExpired,
    Pending,
    Removed,
}

export type User = {
    accessLevel: UserAccessLevel,
    active: boolean,
    auFirstName: string,
    auLastName: string,
    updated: string,
    uuid: string,
}