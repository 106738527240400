import React from 'react'

import { NavLink } from 'react-router-dom'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'

import { FormattedMessage } from 'react-intl'

import { Row, Col } from 'react-bootstrap'

import Logo from 'assets/global/img/taekusLogos/mini-logo-white.png'

import Session from 'services/session'

import { Actions as UserActions } from 'redux/currentUser'

import 'assets/components/login/css/login.scss'

class SignupAlreadyLoggedIn extends React.Component {
  logout = () => {
    Session.logout()
    this.props.dispatch(UserActions.clearCurrentUser())
  }

  render() {
    return (
      <Row className="Login-container m-0">
        <Col md="6" lg="5">
          <Col
            xs="12"
            md={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 2 }}
            className="Login-formBlock"
          >
            <h3 className="Login-subtitle my-5 pt-5 text-center">
              <FormattedMessage
                id="signup-unsuccessful.title"
                defaultMessage={`Already Logged In`}
              />
            </h3>
            <div className="StandardBodyDMSans mb-5 text-center text-red">
              <FormattedMessage
                id="signup-unsuccessful.description"
                defaultMessage={`You are currently already signed into a Taekus account. Please first sign out before attempting to create this authorized user.`}
              />
            </div>
            <div className="d-flex justify-content-center">
              <NavLink to="/login/" className="text-decoration-none">
                <Button className="StandardButtonPurple" onClick={this.logout}>
                  <FormattedMessage id="acount-recovery.back" defaultMessage={`logout`} />
                </Button>
              </NavLink>
            </div>
          </Col>
        </Col>
        <Col
          md="6"
          lg="7"
          className="Login-decorativeBackground d-none d-md-flex justify-content-center"
        >
          <img src={Logo} alt={'taekus logo'} className="Login-logo" />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.currentUser,
})

export default connect(mapStateToProps, null)(SignupAlreadyLoggedIn)
