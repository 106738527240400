import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Close } from "assets/svg/Close.svg";
import CardDetails from 'components/pages/Cards/CardDetails';

import 'assets/components/common/css/simpleTable.scss'
import 'assets/components/card/css/virtualCards.scss'
import styles from 'styles/styles'

type CardMockProps = {
  cardName: string,
  userName: string,
  error: any,
  onClose: () => void,
  cardAccountUuid: string,
  cardUuid: string,
}

const CardMock = (props: CardMockProps) => {

  let cutCardName = props.cardName
  if (cutCardName.length > 24) {
    //this cuts the name to always fit in the name slot, abbreviating it first
    let firstLetter = cutCardName.split(' ')[0].substr(0, 1)
    cutCardName = firstLetter + '. ' + cutCardName.split(' ').slice(1).join(' ')

    if (cutCardName.length > 24) {
      cutCardName = cutCardName.substr(0, 23)
    }
  }

  const { cardName, onClose, userName, error, cardAccountUuid, cardUuid } = props;
  return (
    <Container>
      <div className='mb-2'>
        <CardName>{cardName}</CardName>
        <CloseButton onClick={onClose}>
          <CustomClose/>
        </CloseButton>
      </div>
      <CardDetails
        cardAccountUuid={cardAccountUuid}
        cardUuid={cardUuid}
        userName={userName}
        error={error}
      />
    </Container>
  )
}

const CustomClose = styled(Close)`
  width: 24px;
  height: 24px;
`

const CardName = styled.div`
  font-family: ${styles.Font.Family.MonumentGrotesk};
  font-size: ${styles.Spacing.S};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${styles.MediaQueries.Desktop} {
    padding: ${styles.Spacing.S};
  }
  ${styles.MediaQueries.Mobile} {
  padding: ${styles.Spacing.S} 0;
  }
`

const CloseButton = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${styles.BorderRadius.Half};
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
    top: ${styles.Spacing.XS};
    right: ${styles.Spacing.XS};
    cursor: pointer;
    &:hover {
        background-color: lightgrey;
    }
`

export default CardMock
