import React from 'react'
import alloy from '@alloyidentity/web-sdk'

import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Row, Col } from 'react-bootstrap'

import { Button } from 'reactstrap'

import API from 'services/api'

import Logo from 'assets/global/img/taekusLogos/mini-logo-white.png'

import 'assets/components/login/css/login.scss'

class VerifyId extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newUser: null,
      errorLoading: false,
      loading: true,
      documentsUploaded: false,
      applicationStatus: null,
      applicationStage: null,
      validation: {
        occured: false,
        succeeded: false,
      },
    }
  }

  componentDidMount() {
    const { match } = this.props
    const { applicationUuid } = match.params
    const { state } = this.props.location

    if (state !== undefined) {
      let { newUser } = state
      this.setState({ newUser: newUser })
    }

    API.auth
      .getApplication(applicationUuid)
      .then((response) => {
        const { application } = response.data
        const { alloyJourney } = application

        const production = window.env.REACT_APP_DEBUG === 'false'

        const alloyInitParams = {
          key: window.env.REACT_APP_ALLOY_FRONTEND_SDK_KEY,
          journeyApplicationToken: alloyJourney.journeyApplicationToken,
          journeyToken: alloyJourney.journeyToken,
          isNext: true,
          showHeader: true,
          production: production,
          color: {
            primary: '#1A2278',
            secondary: '#f7927c',
          },
        }

        alloy.init(alloyInitParams)

        this.setState({
          loading: false,
          documentsUploaded: application.documentsUploaded,
          applicationStatus: alloyJourney.outcome,
          applicationStage: application.currentStage,
        })
      })
      .catch((e) => {
        console.error(e)
        this.setState({
          loading: false,
          errorLoading: true,
        })
      })
  }

  callback = (data) => {
    if (
      data.status === 'completed' ||
      data.status === 'waiting_review' ||
      data.status === 'pending_journey_application_review'
    ) {
      const { match } = this.props
      const { applicationUuid } = match.params
      const { newUser } = this.state
      const params = {
        journeyApplicationToken: data.journey_application_token,
      }

      this.setState({
        loading: true,
      })

      API.auth
        .updateApplicationWithDocumentResults(applicationUuid, params)
        .then((response) => {
          if (response.status === 201) {
            if (newUser !== null) {
              API.auth.login(newUser.username, newUser.password).then((response) => {
                const { access, refresh } = response.data
                localStorage.setItem('accessToken', access)
                localStorage.setItem('refreshToken', refresh)
                this.setState({
                  validation: {
                    occured: true,
                    succeeded: true,
                    loading: false,
                  },
                })
              })
            } else {
              this.setState({
                validation: {
                  occured: true,
                  succeeded: true,
                  loading: false,
                },
              })
            }
          } else {
            this.setState({
              validation: {
                occured: true,
                succeeded: false,
                loading: false,
              },
            })
          }
        })
        .catch((e) => {
          console.error(e)
          this.setState({
            validation: {
              occured: true,
              succeeded: false,
              loading: false,
            },
          })
        })
    }
  }

  onOpen = () => {
    alloy.open(this.callback)
  }

  onClose = () => {
    alloy.close()
  }

  render() {
    const {
      errorLoading,
      loading,
      validation,
      documentsUploaded,
      applicationStatus,
      applicationStage,
      newUser,
    } = this.state
    const bypass2FA = window.env.REACT_APP_BYPASS_2FA === 'true'

    if (validation.occured) {
      // On debug instances we bypass 2fa so people can use fake phone numbers when creating multiple accounts.
      if (validation.succeeded) {
        if (bypass2FA || newUser === null) {
          return <Redirect to={{ pathname: '/signup/success/' }} />
        } else if (newUser !== null) {
          return <Redirect to={{ pathname: '/signup/verify/', state: { phone: newUser.phone } }} />
        }
      }
    } else if (applicationStatus === 'Approved' && applicationStage !== 'PENDING_USER_INPUT') {
      return <Redirect to={{ pathname: '/signup/success/' }} />
    }

    let message = (
      <div className="StandardTitleDMSans mt-3">
        There was a problem loading your application. Please try again later or contact support at{' '}
        <a href="mailto:support@taekus.com">support@taekus.com</a>
      </div>
    )

    if (validation.occured && !validation.succeeded) {
      message = (
        <div className="StandardTitleDMSans mt-3">
          We are sorry but we need a little more information before we can create your account. A
          member of our support staff will reach out to you shortly, but you can contact us at{' '}
          <a href="mailto:support@taekus.com">support@taekus.com</a>
        </div>
      )
    } else if (documentsUploaded) {
      message = (
        <div className="StandardTitleDMSans mt-3">
          Documents have already been uploaded for this application. If you think this is an error
          or you would like to submit additional documents, please reach out to{' '}
          <a href="mailto:support@taekus.com">support@taekus.com</a>
        </div>
      )
    } else if (loading) {
      message = (
        <div className="StandardTitleDMSans mt-3">
          We are processing your application, please wait...
        </div>
      )
    }

    return (
      <Row className="Login-container m-0">
        <Col md="6" lg="5">
          <Col
            xs="12"
            md={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 2 }}
            className="Login-formBlock"
          >
            <h3 className="Login-subtitle my-5 pt-5 text-center">
              <FormattedMessage
                id="signup-unsuccessful.title"
                defaultMessage={`Verify your Identity`}
              />
            </h3>
            <div className="StandardBodyDMSans mb-5 text-center">
              {errorLoading ||
              documentsUploaded ||
              loading ||
              (validation.occured && !validation.succeeded) ? (
                <>{message}</>
              ) : (
                <>
                  <div className="StandardTitleDMSans mt-3">
                    We'll need a bit more information from you to verify your identity. Please use
                    the button below to upload pictures of your state ID. Our systems will verify
                    your information and let you know if any more information is needed.
                  </div>

                  <Button
                    className="font-weight-bold mt-3 mb-4 StandardButtonWhiteGold"
                    disabled={loading}
                    onClick={this.onOpen}
                  >
                    <FormattedMessage id="signup.submit" defaultMessage={`Get Started`} />
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Col>
        <Col
          md="6"
          lg="7"
          className="Login-decorativeBackground d-none d-md-flex justify-content-center"
        >
          <img src={Logo} alt={'taekus logo'} className="Login-logo" />
        </Col>
      </Row>
    )
  }
}

export default VerifyId
