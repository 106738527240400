import React from 'react'
import { FormattedMessage } from 'react-intl'

import get from 'lodash/get'

import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Button, Form, FormGroup } from 'reactstrap'

import API from 'services/api'
import Logo from 'assets/global/img/taekusLogos/mini-logo-white.png'

import 'assets/components/login/css/login.scss'

class VerifyPhone extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      phone: null,
      token: '',
      messageSent: false,
      isSubmitting: false,
      error: false,
      errors: '',
    }
  }

  componentDidMount() {
    let { phone } = this.props.location.state
    this.setState({ phone: phone })
    this.sendVerificationCode(phone)
  }

  generateChangeHandler = (value, fieldName) => {
    this.setState({
      ...this.state,
      [fieldName]: value,
    })
  }

  sendVerificationCode = (phone) => {
    API.auth
      .verifyPhone(phone)
      .then((response) => {
        this.setState({ messageSent: true })
      })
      .catch((e) => {
        this.setState({ error: true, errors: 'Message Sending Failed. Please Try again.' })
      })
  }

  verifyPhone = (e) => {
    e.preventDefault()

    const { phone, token } = this.state
    this.setState({ isSubmitting: true })

    API.auth
      .registerPhone(phone, token)
      .then((response) => {
        window.location.replace('/signup/success/')
      })
      .catch((e) => {
        this.setState({ errors: e.response.data, error: true, isSubmitting: false })
      })
  }

  render() {
    const { token, isSubmitting, error, errors, messageSent, phone } = this.state

    return (
      <Row className="Login-container m-0">
        <Col md="6" lg="5">
          <Col
            xs="12"
            md={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 2 }}
            className="Login-formBlock"
          >
            <h3 className="Login-subtitle mb-0">
              <FormattedMessage
                id="signup.verify-phone.title"
                defaultMessage={`Verify your Phone`}
              />
            </h3>
            <div className="StandardBodyDMSans mb-1">
              {messageSent ? (
                <FormattedMessage
                  id="signup.verify-phone.description"
                  defaultMessage={`Please enter the four-digit code we just sent to your phone. Code is good for 10 minutes.`}
                />
              ) : (
                <FormattedMessage
                  id="signup.verify-phone.description"
                  defaultMessage={`Please wait, sending verification code...`}
                />
              )}
            </div>
            <div className="StandardBodyDMSans mb-5">
              <button
                value={phone}
                className="StandardButtonLink px-0"
                onClick={(e) => this.sendVerificationCode(e.target.value)}
              >
                <FormattedMessage
                  id="signup.verify-phone.resend-code"
                  defaultMessage={`Didn't receive a code? Click here to resend.`}
                />
              </button>
            </div>
            <Form className="Login-form" onSubmit={this.verifyPhone}>
              <FormGroup className="login-formGroup">
                <input
                  type="text"
                  name="token"
                  className="Login-Input w-100 mt-4"
                  placeholder="Verification Code"
                  value={token}
                  maxLength="4"
                  onChange={(e) => this.generateChangeHandler(e.target.value, 'token')}
                />
              </FormGroup>
              <div className="d-flex flex-column align-items-center">
                <Button
                  disabled={isSubmitting}
                  className="StandardButtonWhiteGold font-weight-bold mt-3"
                >
                  <FormattedMessage
                    id="signup.verify-phone.submit"
                    defaultMessage={`Submit Code`}
                  />
                </Button>
              </div>
            </Form>
            {error && (
              <div className="StandardBodyDMSans text-red text-center mt-3">
                {get(errors, 'message', '')}
              </div>
            )}
          </Col>
        </Col>
        <Col
          md="6"
          lg="7"
          className="Login-decorativeBackground d-none d-md-flex justify-content-center"
        >
          <img src={Logo} alt={'taekus logo'} className="Login-logo" />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.currentUser,
})

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(VerifyPhone)
