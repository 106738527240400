import { CSSObjectWithLabel } from "react-select";

import { backgroundBlue, transparentGray, white } from 'components/common/styleConstants'

import styles from "styles/styles"

const baseStyle = {
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'DM Sans',
    fontSize: '0.95em',
    color: backgroundBlue(),
    backgroundColor:
      state.isDisabled || state.isFocused || state.isSelected ? transparentGray() : white(),
    '&:active': {
      backgroundColor: transparentGray(),
    },
  }),
  menuList: (defaultStyles: any) => ({
    ...defaultStyles,
    padding: 0,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0 8px 4px',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: '24px',
    padding: '0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'DM Sans',
    fontSize: '0.95em',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    height: '24px',
    backgroundColor: 'transparent',
    minHeight: '33px',
    outline: 'none',
    color: 'white',
    boxShadow: state.isSelected || state.isFocused ? `none` : 'none',
    border: `none`,
    borderRadius: '0',
    borderBottom: state.isFocused ? '1px solid #7C3D76' : '1px solid #D9D9D9',
    '&:hover': {
      borderBottom: '1px solid #7C3D76',
    },
    '&:active': {
      borderBottom: '1px solid #7C3D76',
    },
  }),
};

const selectStyles = {
    Settings: {
        container: (defaultStyles: CSSObjectWithLabel, state: any) => ({
            ...defaultStyles,
            border: 0,
            outline: 0,
            boxShadow: 'none',
            whiteSpace: 'nowrap',
            transition: 'all 0.2s ease-in',
            borderBottom: `1px solid ${state.isFocused ? styles.Color.TaekusPurple : styles.Color.Grey}`,
            height: '27px',
            '&:hover': {
                borderBottom: `1px solid ${styles.Color.TaekusPurple} !important`,
            }
        }),
        singleValue: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            fontSize: styles.Font.Size.Small,
            fontFamily: styles.Font.Family.MonumentGrotesk,
            outline: 0,
            padding: 0,
            margin: 0,
            boxShadow: 'none',
            maxHeight: '30px',
            height: '100%',
            color: styles.Color.Black,
            border: `1px solid transparent`,
            width: '100%',
        }),
        control: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            backgroundColor: 'transparent',
            outline: 0,
            boxShadow: 'none',
            borderRadius: 0,
            height: '27px',
            minHeight: '27px',
            border: 0,
            cursor: 'pointer',
            textAlign: 'right',
            ':focus-visible': {},
        }),
        input: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            border: 0,
            outline: 0,
            boxShadow: 'none',
            margin: 0
        }),
        menu: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            minWidth: '120px',
            borderRadius: 0,
        }),
        menuList: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            minWidth: '120px',
            padding: 0,
            fontSize: styles.Font.Size.Small,
            borderRadius: 0,
        }),
        valueContainer: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            padding: '0 4px',
            height: '100%',
        }),
        placeholder: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 4px',
            width: '100%',
        }),
        indicatorsContainer: (defaultStyles: CSSObjectWithLabel) => ({
            ...defaultStyles,
            height: '100%',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            fontFamily: 'Monument Grotesk',
            cursor: 'pointer',
            backgroundColor:
                state.isActive || state.isDisabled || state.isSelected ? `${styles.Color.TaekusPurple} !important` :  state.isFocused ? `${styles.Color.Grey} !important` : styles.Color.White,
            color:
              state.isActive || state.isDisabled || state.isSelected ? styles.Color.White : styles.Color.Black,
        }),
    },
    TransactionFilter: {
        option: (provided: any, state: any) => ({
            ...provided,
            fontFamily: 'DM Sans',
            fontSize: '0.95em',
            cursor: 'pointer',
            backgroundColor:
                state.isActive || state.isDisabled || state.isSelected ? `${styles.Color.TaekusPurple} !important` :  state.isFocused ? `${styles.Color.Grey} !important` : styles.Color.White,
            color:
              state.isActive || state.isDisabled || state.isSelected ? styles.Color.White : styles.Color.Black,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            padding: '4px',
            border: 0,
            backgroundColor: styles.Color.Transparent,
            outline: 'none',
            width: '100%',
            fontFamily: styles.Font.Family.MonumentGrotesk,
            fontStyle: 'normal',
            fontWeight: styles.Font.Weight[400],
            fontSize: styles.Font.Size.Small,
            lineHeight: '140%',
            /* identical to box height, or 20px */
            letterSpacing: '0.02em',
        }),
        menu: (defaultStyles: any) => ({
            ...defaultStyles,
            borderRadius: 0,
        }),
        menuList: (defaultStyles: any) => ({
            ...defaultStyles,
            padding: 0,
            borderRadius: 0,
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: '0 8px 4px',
        }),
        input: (provided: any) => ({
            ...provided,
            cursor: 'text',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            background: 'transparent',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            border: 0,
            padding: 0,
        }),
        singleValue: (provided: any) => ({
            ...provided,
            fontFamily: 'DM Sans',
            fontSize: '0.95em',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            height: '24px',
            backgroundColor: 'transparent',
            minHeight: '33px',
            outline: 'none',
            color: 'white',
            boxShadow: state.isSelected || state.isFocused ? `none` : 'none',
            border: `none`,
            borderRadius: '0',
            transition: 'all 0.2s ease-in',
            borderBottom: state.isFocused ? `1px solid ${styles.Color.TaekusPurple}` : `1px solid ${styles.Color.Grey}`,
            '&:hover': {
              borderBottom: `1px solid ${styles.Color.TaekusPurple}`,
            },
            '&:active': {
              borderBottom: `1px solid ${styles.Color.TaekusPurple}`,
            },
        }),
    },
    flightSearch: {
      ...baseStyle,
      option: (provided: any, state: any) => ({
        ...provided,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        fontSize: styles.Font.Size.Small,
        color: state.isDisabled || state.isSelected ? styles.Color.White : styles.Color.Black,
        backgroundColor:
            state.isDisabled  || state.isSelected ? styles.Color.TaekusPurple : (state.isFocused ? styles.Color.Grey : 'transparent'),
        transition: 'all 0.2s ease-in',
      }),
      menu: (defaultStyles: any) => ({
          ...defaultStyles,
          borderRadius: 0,
          margin: 0,
          minWidth: '100%',
          width: 'fit-content',
          zIndex: 9999,
      }),
      menuList: (defaultStyles: any) => ({
          ...defaultStyles,
          padding: 0,
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
              width: '0.4em',
              height: '0.4em',
              backgroundColor: '#transparent',
          },
          '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#7C3D76',
              border: '4px solid transparent',
              borderRadius: '16px',
          }
      }),
      dropdownIndicator: (provided: any, state: any) => ({
          ...provided,
          padding: '0 8px 4px',
      }),
      placeholder: (provided: any) => ({
          ...provided,
          margin: '0 8px',
      }),
      valueContainer: (provided: any) => ({
          ...provided,
          height: '24px',
          fontSize: styles.Font.Size.Small,
          padding: '0',
      }),
      singleValue: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: 'transparent',
          width: 'min-content',
          outline: 0,
          color: state.isDisabled ? 'grey' : 'white',
          padding: `0 ${styles.Spacing.XS}`,
          marginRight: '10px',
          borderRadius: 0,
          borderRight: '8px solid transparent',
          borderTop: 0,
          borderBottom: 0,
          borderLeft: 0,
      }),
      container: (provided: any, state: any) => ({
        position: 'relative',
        // not using style constant since Select component prefers camelCase
        '@media screen and (maxWidth: 768px)': `
            :not(:last-child) { 
                margin-right: styles.Spacing.XS; 
            }
        `,
      }),
      control: (provided: any, state: any) => ({
          ...provided,
          cursor: 'pointer',
          height: styles.Spacing.M,
          marginTop: styles.Spacing.XS,
          border: '1px solid #BCBCBC !important',
          backgroundColor: 'transparent',
          marginRight: styles.Spacing.S,
          display: 'flex',
          alignItems: 'center',
          borderRadius: 0,
          outline: 0,
          boxShadow: 'none !important',
          [styles.MediaQueries.Mobile]: {
              minWidth: '120px',
              marginBottom: styles.Spacing.XS,
              justifyContent: 'space-between',
          }
      })
    },
    virtualCards: {
      ...baseStyle,
      option: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled || state.isFocused || state.isSelected ? styles.Color.White : styles.Color.Black,
        backgroundColor:
            state.isDisabled || state.isFocused || state.isSelected ? styles.Color.TaekusPurple : 'transparent',
      }),
      menu: (defaultStyles: any) => ({
          ...defaultStyles,
          borderRadius: 0,
      }),
      menuList: (defaultStyles: any) => ({
          ...defaultStyles,
          padding: 0,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
              width: '0.4em',
              height: '0.4em',
              backgroundColor: '#transparent',
          },
          '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#7C3D76',
              border: '4px solid transparent',
              borderRadius: '16px',
          }
      }),
      dropdownIndicator: (provided: any) => ({
          ...provided,
          padding: '0 8px 4px',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: '100%'
      }),
      placeholder: (provided: any) => ({
          ...provided,
          margin: '0 8px',
      }),
      valueContainer: (provided: any) => ({
          ...provided,
          maxHeight: "200px",
          overflowY: 'auto',
          '::-webkit-scrollbar': { 
              display: "none"
          },
          '&::-webkit-scrollbar': {
              height: "0",
              width: "0",
          },
          '&::-webkit-scrollbar-thumb': {
              backgroundColor: "transparent",
          },
          padding: '0',
      }),
      singleValue: (provided: any) => ({
          ...provided,
          backgroundColor: 'transparent',
          width: '100%',
          outline: 0,
          color: 'black',
          padding: `1px 2px`,
          margin: 0,
          borderRadius: 0,
          borderRight: '8px solid transparent',
          borderTop: 0,
          borderBottom: 0,
          borderLeft: 0,
          fontSize: '20px',
          
      }),
      multiValue: (provided: any) => ({
        ...provided,
      }),
      multiValueLabel: (provided: any) => ({
          ...provided,
      }),
      control: (provided: any, state: any) => ({
          ...provided,
          width: '100%',
          minHeight: '33px',
          marginBottom: '20px',
          borderTop: `1px solid transparent !important`,
          borderLeft: `1px solid transparent !important`,
          borderRight: `1px solid transparent !important`,
          borderBottom: `1px solid ${state.isFocused ? styles.Color.TaekusPurple : 'grey'}`,
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 0,
          outline: 0,
          boxShadow: 'none !important',
          transition: 'all .2s ease-in',
          '&:hover': {
            borderBottom: `1px solid ${styles.Color.TaekusPurple} !important`,
          },
          [styles.MediaQueries.Mobile]: {
              width: '100%',
              marginBottom: styles.Spacing.XS,
              justifyContent: 'space-between',
          }
      })
    },
    tags: {
        option: (provided: any, state: any) => ({
            ...provided,
            whiteSpace: 'nowrap',
            color: state.isDisabled || state.isFocused || state.isSelected ? styles.Color.White : styles.Color.Black,
            cursor: 'pointer',
            backgroundColor:
              state.isDisabled || state.isFocused || state.isSelected ? `${styles.Color.TaekusPurple} !important` : 'transparent',
        }),
        menu: (defaultStyles: any) => ({
            ...defaultStyles,
            borderRadius: 0,
            width: 'fit-content'
        }),
        menuList: (defaultStyles: any) => ({
            ...defaultStyles,
            padding: 0,
            overflowY: 'auto',
            fontSize: '12px',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
                backgroundColor: '#transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#7C3D76',
                border: '4px solid transparent',
                borderRadius: '16px',
            }
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            visibility: "hidden"
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            visibility: "hidden"
        }),
        input: (provided: any) => ({
            ...provided,
            margin: '0',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            margin: '0 2px',
            color: styles.Color.TaekusPurple,
            padding: '4px 6px',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: '100%',
            padding: '0',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            backgroundColor: 'transparent',
            width: '100%',
            outline: 0,
            color: 'white',
            padding: `0 ${styles.Spacing.XS}`,
            borderRadius: 0,
            borderRight: '8px solid transparent',
            borderTop: 0,
            borderBottom: 0,
            borderLeft: 0,
            
        }),
        container: (provided: any, state: any) => ({
            ...provided,
            maxHeight: '100%',
            height: '100%',
            position: 'relative',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            border: '0',
            cursor: 'pointer',
            minHeight: 'none',
            height: '100%',
            maxHeight: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 0,
            outline: 0,
            boxShadow: 'none !important',
            [styles.MediaQueries.Mobile]: {
                width: '100%',
                marginBottom: styles.Spacing.XS,
                justifyContent: 'space-between',
            }
        }),
    },
}

export default selectStyles