import React from 'react'
import { useSelector } from 'react-redux';

import moment from 'moment';

import styled from 'styled-components'

import { ReactComponent as TaekusLogo } from "assets/svg/TaekusLogo.svg";

import styles from 'styles/styles';

const messages = {
    Copyright: `© Taekus Incorporated, ${moment().year()}`,
    BankCredit: 'Banking Services provided by Stearns Bank NA, Member FDIC.'
}

const careersLink = 'https://www.notion.so/Jobs-at-Taekus-626d67504b154eadb1bb933a98dfb077'
const termsLink = 'https://taekus.com/legal/terms'
const policyLink = 'https://taekus.com/legal/privacy-policy'

const Footer = () => {
    const isMobile = useSelector((state: any) => state.global.isMobile)

    return <FooterWrapper>
        {!isMobile && <CustomTaekusLogo fill='white'/>}
        <Row>
            <Copyright>
                <div>{messages.Copyright}</div>
                <div>{messages.BankCredit}</div>
            </Copyright>
            <div className='d-flex'>
                <Link rel="noopener noreferrer" target='_blank' href={careersLink}>Jobs</Link>
                <Link rel="noopener noreferrer" target='_blank' href={termsLink}>Terms</Link>
                <Link rel="noopener noreferrer" target='_blank' href={policyLink}>Policy</Link>
            </div>
        </Row>
    </FooterWrapper>
}

const Row = styled.div`
    overflow: hidden;
    ${styles.MediaQueries.Desktop} {
        display: flex;
        justify-content: space-between;
    }
`

const Link = styled.a`
    padding-right: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    color: ${styles.Color.White};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    text-align: right;
    letter-spacing: 0.02em;
    &:hover {
        color: ${styles.Color.TaekusPurple};
    }
`

const Copyright = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    color: ${styles.Color.White};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    height: fit-content;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.M};
        white-space: normal;
    }
`

const CustomTaekusLogo = styled(TaekusLogo)`
    width: 175px;
    min-height: 32.5px;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
`

const FooterWrapper = styled.div`
    padding: ${styles.Spacing.M};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 100%;
    min-height: 180px;
    background-color: ${styles.Color.NearBlack};
    color: ${styles.Color.White};
    ${styles.MediaQueries.Mobile} {
        min-height: 200px;
    }
    ${styles.MediaQueries.Print} {
        display: none;
    }
`

export default Footer