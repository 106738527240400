export const mccCodes = {
  1520: {
    readable_description: 'General Contractors',
    comp_description: 'gen_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  1711: {
    readable_description: 'Heating & Plumbing Contractors',
    comp_description: 'plumbing_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  1731: {
    readable_description: 'Electrical Contractors',
    comp_description: 'electrical_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  1740: {
    readable_description: 'Insulation, Stonework, & Tile Contractors',
    comp_description: 'insulation_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  1750: {
    readable_description: 'Carpentry Contractors',
    comp_description: 'carpentry_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  1761: {
    readable_description: 'Roofing, Siding, & Sheet Metal Contractors ',
    comp_description: 'roofing_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  1771: {
    readable_description: 'Concrete Contractors',
    comp_description: 'concrete_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  1799: {
    readable_description: 'Special Trade Contractors',
    comp_description: 'special_contract',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  2741: {
    readable_description: 'Publishing & Printing',
    comp_description: 'publishing',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  2791: {
    readable_description: 'Typesetting, Plate-Making, & Related',
    comp_description: 'typesetting',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  2842: {
    readable_description: 'Sanitation & Cleaning',
    comp_description: 'cleaning',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  3000: {
    readable_description: 'United Airlines',
    comp_description: 'united',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3001: {
    readable_description: 'American Airlines',
    comp_description: 'american',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3002: {
    readable_description: 'Pan American',
    comp_description: 'panam',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3003: {
    readable_description: 'Eurofly',
    comp_description: 'eurofly',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3004: {
    readable_description: 'Dragon Airlines',
    comp_description: 'dragonair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3005: {
    readable_description: 'British Airways',
    comp_description: 'britishawys',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3006: {
    readable_description: 'Japan Air Lines',
    comp_description: 'jalair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3007: {
    readable_description: 'Air France',
    comp_description: 'airfrance',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3008: {
    readable_description: 'Lufthansa German Airlines',
    comp_description: 'lufthansa',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3009: {
    readable_description: 'Air Canada',
    comp_description: 'aircanada',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3010: {
    readable_description: 'Royal Dutch Airlines (KLM Airlines)',
    comp_description: 'klmair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3011: {
    readable_description: 'Aeroflot',
    comp_description: 'aeroflot',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3012: {
    readable_description: 'Qantas',
    comp_description: 'qantas',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3013: {
    readable_description: 'Alitalia',
    comp_description: 'alitalia',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3014: {
    readable_description: 'Saudi Arabian Airlines',
    comp_description: 'saudiair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3015: {
    readable_description: 'Swiss International Air Lines',
    comp_description: 'swissintair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3016: {
    readable_description: 'Scandinavian Airline System (SAS)',
    comp_description: 'sas',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3017: {
    readable_description: 'South African Airways',
    comp_description: 'saaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3018: {
    readable_description: 'Varig',
    comp_description: 'varig',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3020: {
    readable_description: 'Air India',
    comp_description: 'airindia',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3021: {
    readable_description: 'Air Algerie',
    comp_description: 'airalgerie',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3022: {
    readable_description: 'Philippine Airlines',
    comp_description: 'phillipineair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3023: {
    readable_description: 'Mexicana',
    comp_description: 'mexicana',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3024: {
    readable_description: 'Pakistan International',
    comp_description: 'pakistanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3025: {
    readable_description: 'Air New Zealand Ltd. International',
    comp_description: 'airnz',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3026: {
    readable_description: 'Emirates Airlines',
    comp_description: 'emirates',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3027: {
    readable_description: 'Union De Transports Aeriens (UTA/interair)',
    comp_description: 'utaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3028: {
    readable_description: 'Air Malta',
    comp_description: 'airmalta',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3029: {
    readable_description: 'SN Brussels Airlines',
    comp_description: 'snbrusselsair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3030: {
    readable_description: 'Aerolineas Argentinas',
    comp_description: 'aerolineas',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3031: {
    readable_description: 'Olympic Airways',
    comp_description: 'olympicair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3032: {
    readable_description: 'El Al',
    comp_description: 'elal',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3033: {
    readable_description: 'Ansett Airlines',
    comp_description: 'ansettair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3034: {
    readable_description: 'Etihadair',
    comp_description: 'ethiadair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3035: {
    readable_description: 'TAP Air Portugal (TAP)',
    comp_description: 'tapair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3036: {
    readable_description: 'Viacao Aerea Sao Pãulo (VASP)',
    comp_description: 'vaspair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3037: {
    readable_description: 'Egyptair',
    comp_description: 'egyptair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3038: {
    readable_description: 'Kuwait Airways',
    comp_description: 'kuwaitair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3039: {
    readable_description: 'Avianca',
    comp_description: 'avianca',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3040: {
    readable_description: 'Gulf Air (Bahrain)',
    comp_description: 'gulfair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3041: {
    readable_description: 'Balkan-Bulgarian Airlines',
    comp_description: 'balkanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3042: {
    readable_description: 'Finnair',
    comp_description: 'finnair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3043: {
    readable_description: 'Aer Lingus',
    comp_description: 'aerlingus',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3044: {
    readable_description: 'Air Lanka',
    comp_description: 'airlanka',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3045: {
    readable_description: 'Nigeria Airways',
    comp_description: 'nigeriaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3046: {
    readable_description: 'Cruzeiro Do Sul',
    comp_description: 'cruzeriodosul',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3047: {
    readable_description: 'Turk Hava Yollari',
    comp_description: 'turkhavayollari',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3048: {
    readable_description: 'Royal Air Maroc',
    comp_description: 'ram',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3049: {
    readable_description: 'Tunis Air',
    comp_description: 'tunisair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3050: {
    readable_description: 'Icelandair',
    comp_description: 'icelandair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3051: {
    readable_description: 'Austrian Airlines',
    comp_description: 'austrianair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3052: {
    readable_description: 'LAN Airlines',
    comp_description: 'lanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3053: {
    readable_description: 'Aviacion Y Comercio (AVIACO)',
    comp_description: 'aviacoair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3054: {
    readable_description: 'Linea Aerea Del Cobre (LADECO)',
    comp_description: 'ladecoair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3055: {
    readable_description: 'Lloyd Aero Boliviano (LAB)',
    comp_description: 'labair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3056: {
    readable_description: 'Jet Airways',
    comp_description: 'jetair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3057: {
    readable_description: 'Virgin America',
    comp_description: 'viramer',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3058: {
    readable_description: 'Delta',
    comp_description: 'deltaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3059: {
    readable_description: 'DBA Airline',
    comp_description: 'dbaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3060: {
    readable_description: 'Northwest Airlines',
    comp_description: 'nwaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3061: {
    readable_description: 'Continental',
    comp_description: 'continental',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3062: {
    readable_description: 'Hapag-Lloyd Express',
    comp_description: 'hlxair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3063: {
    readable_description: 'US Airways',
    comp_description: 'usair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3064: {
    readable_description: 'Adria Airways',
    comp_description: 'adriaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3065: {
    readable_description: 'Airinter',
    comp_description: 'airinter',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3066: {
    readable_description: 'Southwest Airlines',
    comp_description: 'swair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3067: {
    readable_description: 'Vanguard Airlines',
    comp_description: 'vanguardair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3068: {
    readable_description: 'Air Astana',
    comp_description: 'airastana',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3069: {
    readable_description: 'Sun Country Airlines',
    comp_description: 'sunctryair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3071: {
    readable_description: 'Air British Columbia',
    comp_description: 'airbc',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3072: {
    readable_description: 'Cebu Pacific Airlines',
    comp_description: 'cebupacificair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3075: {
    readable_description: 'Singapore Airlines',
    comp_description: 'singaporeair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3076: {
    readable_description: 'Aeromexico',
    comp_description: 'aeromexico',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3077: {
    readable_description: 'Thai Airways',
    comp_description: 'thaiair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3078: {
    readable_description: 'China Airlines',
    comp_description: 'chinaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3079: {
    readable_description: 'Jetstar Airways',
    comp_description: 'jetstarair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3082: {
    readable_description: 'Korean Airlines',
    comp_description: 'koreanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3083: {
    readable_description: 'Air Afrique',
    comp_description: 'airafrique',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3084: {
    readable_description: 'Eva Airways',
    comp_description: 'evaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3085: {
    readable_description: 'Midwest Express Airlines',
    comp_description: 'midwestair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3086: {
    readable_description: 'Carnival Airlines',
    comp_description: 'carnivalair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3087: {
    readable_description: 'Metro Airlines',
    comp_description: 'metroair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3088: {
    readable_description: 'Croatia Air',
    comp_description: 'croatiaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3089: {
    readable_description: 'Transaero',
    comp_description: 'transaero',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3090: {
    readable_description: 'Uni Airways Corporation',
    comp_description: 'uniair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3094: {
    readable_description: 'Zambia Airways',
    comp_description: 'zambiaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3096: {
    readable_description: 'Air Zimbabwe Corp',
    comp_description: 'airzim',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3097: {
    readable_description: 'Spanair',
    comp_description: 'spanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3098: {
    readable_description: 'Asiana Airlines',
    comp_description: 'asanaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3099: {
    readable_description: 'Cathay Pacific',
    comp_description: 'cathaypacific',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3100: {
    readable_description: 'Malaysian Airline System',
    comp_description: 'malayair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3102: {
    readable_description: 'Iberia',
    comp_description: 'iberiaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3103: {
    readable_description: 'Garuda',
    comp_description: 'garudaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3106: {
    readable_description: 'Braathens S.A.F.E.',
    comp_description: 'braathens',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3110: {
    readable_description: 'Wings Airways',
    comp_description: 'wingsair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3111: {
    readable_description: 'British Midland',
    comp_description: 'britishmidair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3112: {
    readable_description: 'Windward Island',
    comp_description: 'windwardisland',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3115: {
    readable_description: 'Tower Air',
    comp_description: 'towerair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3117: {
    readable_description: 'Venezolana International De Aviacion',
    comp_description: 'venezolanaint',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3118: {
    readable_description: 'Valley Airlines',
    comp_description: 'valleyair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3125: {
    readable_description: 'Tan Sahsa',
    comp_description: 'tanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3126: {
    readable_description: 'Talair PTY Ltd.',
    comp_description: 'talair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3127: {
    readable_description: 'Taca International',
    comp_description: 'tacaintair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3129: {
    readable_description: 'Surinam Airways',
    comp_description: 'surinamair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3130: {
    readable_description: 'Sunworld International',
    comp_description: 'sunworldair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3131: {
    readable_description: 'VLM Airlines',
    comp_description: 'vlmair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3132: {
    readable_description: 'Frontier Airlines',
    comp_description: 'frontierair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3133: {
    readable_description: 'Sunbelt Airlines',
    comp_description: 'sunbeltair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3135: {
    readable_description: 'Sudan Airways',
    comp_description: 'sudanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3136: {
    readable_description: 'Qatar Airways',
    comp_description: 'qatarair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3137: {
    readable_description: 'Singleton',
    comp_description: 'singletonair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3138: {
    readable_description: 'Simmons Airlines',
    comp_description: 'simmonsair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3143: {
    readable_description: 'Scenic Airlines',
    comp_description: 'scenicair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3144: {
    readable_description: 'Virgin Atlantic',
    comp_description: 'virginatlantic',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3145: {
    readable_description: 'San Juan',
    comp_description: 'sanjuanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3146: {
    readable_description: 'Luxair',
    comp_description: 'luxair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3148: {
    readable_description: 'Air Littoral S.A.',
    comp_description: 'airlittoral',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3151: {
    readable_description: 'Air Zaire',
    comp_description: 'airzaire',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3154: {
    readable_description: 'Princeville',
    comp_description: 'princevilleair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3156: {
    readable_description: 'Go Fly Ltd.',
    comp_description: 'gofly',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3159: {
    readable_description: 'Provincetown-Boston Airways',
    comp_description: 'pbaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3161: {
    readable_description: 'All Nipon Airways',
    comp_description: 'anaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3164: {
    readable_description: 'Norontair',
    comp_description: 'nortonair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3165: {
    readable_description: 'New York Helicopter',
    comp_description: 'nyheli',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3167: {
    readable_description: 'Aero Continente',
    comp_description: 'aerocontinente',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3170: {
    readable_description: 'Mount Cook',
    comp_description: 'mtcookair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3171: {
    readable_description: 'Canadian Airlines International',
    comp_description: 'canadianair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3172: {
    readable_description: 'Nationair',
    comp_description: 'nationair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3174: {
    readable_description: 'Jetblue Airways',
    comp_description: 'jetblue',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3175: {
    readable_description: 'Middle East Air',
    comp_description: 'mideastair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3176: {
    readable_description: 'Metroflight Airlines',
    comp_description: 'metrofltair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3177: {
    readable_description: 'AirTran Airways',
    comp_description: 'airtranair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3178: {
    readable_description: 'Mesa Air',
    comp_description: 'mesaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3180: {
    readable_description: 'Westjet Airlines',
    comp_description: 'westjetair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3181: {
    readable_description: 'Malev',
    comp_description: 'malevair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3182: {
    readable_description: 'LOT (Polish Airlines)',
    comp_description: 'lotair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3183: {
    readable_description: 'Oman Air',
    comp_description: 'omanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3184: {
    readable_description: 'LIAT',
    comp_description: 'liatair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3185: {
    readable_description: 'Linea Aeropostal Venezolana (LAV)',
    comp_description: 'lavair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3186: {
    readable_description: 'Lineas Aereas Paraguayas (LAP)',
    comp_description: 'lapair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3187: {
    readable_description: 'LACSA (Costa Rica)',
    comp_description: 'lacsaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3188: {
    readable_description: 'Virgin Express',
    comp_description: 'virginexair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3190: {
    readable_description: 'Jugoslav Air',
    comp_description: 'jugoslavair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3191: {
    readable_description: 'Island Airlines',
    comp_description: 'islandair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3193: {
    readable_description: 'Indian Airlines',
    comp_description: 'indianair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3196: {
    readable_description: 'Hawaiian Air',
    comp_description: 'hawaiianair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3197: {
    readable_description: 'Havasu Airlines',
    comp_description: 'havasuair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3200: {
    readable_description: 'Guyana Airways',
    comp_description: 'guyanaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3203: {
    readable_description: 'Golden Pacific Air',
    comp_description: 'goldpacair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3204: {
    readable_description: 'Freedom Airlines',
    comp_description: 'freedomair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3206: {
    readable_description: 'China Eastern Airlines',
    comp_description: 'chinaeastair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3211: {
    readable_description: 'Norwegian Air Shuttle',
    comp_description: 'norwegianair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3212: {
    readable_description: 'Dominicana De Aviacion',
    comp_description: 'dominicaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3213: {
    readable_description: 'Malmo Aviation',
    comp_description: 'malmoair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3215: {
    readable_description: 'Dan Air Services',
    comp_description: 'danair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3216: {
    readable_description: 'Cumberland Airlines',
    comp_description: 'cumberlandair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3217: {
    readable_description: 'Ceskoslovenske Aerolinie (CSA)',
    comp_description: 'csaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3218: {
    readable_description: 'Crown Air',
    comp_description: 'crownair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3219: {
    readable_description: 'Compania Panamena De Aviacion (COPA)',
    comp_description: 'copaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3220: {
    readable_description: 'Compania Faucett',
    comp_description: 'compfauair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3221: {
    readable_description: 'Transportes Aeros Militares',
    comp_description: 'tameair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3222: {
    readable_description: 'Command Airways',
    comp_description: 'commandair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3223: {
    readable_description: 'Comair',
    comp_description: 'comair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3226: {
    readable_description: 'Skyways',
    comp_description: 'skywaysair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3228: {
    readable_description: 'Cayman Airways',
    comp_description: 'caymanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3229: {
    readable_description: 'Sociedad Ecuatorianos De Transportes Aereos',
    comp_description: 'saetaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3231: {
    readable_description: 'SAHSA (Servicio Aero De Honduras)',
    comp_description: 'sashaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3233: {
    readable_description: 'Capitol Air',
    comp_description: 'capitolair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3234: {
    readable_description: 'Caribbean Airlines',
    comp_description: 'caribbeanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3235: {
    readable_description: 'Brockway Air',
    comp_description: 'brockwayair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3236: {
    readable_description: 'Air Arabia Airline',
    comp_description: 'airarabia',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3238: {
    readable_description: 'Bemidji Aviation',
    comp_description: 'bemidjiair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3239: {
    readable_description: 'Bar Harbor Airlines',
    comp_description: 'barharborair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3240: {
    readable_description: 'Bahamasair',
    comp_description: 'bahamasair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3241: {
    readable_description: 'Aviateca',
    comp_description: 'aviatecaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3242: {
    readable_description: 'Avensa',
    comp_description: 'avensaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3243: {
    readable_description: 'Austrian Air Service',
    comp_description: 'austrianairservice',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3245: {
    readable_description: 'Easyjet Air',
    comp_description: 'easyjet',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3246: {
    readable_description: 'Ryanair',
    comp_description: 'ryanair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3247: {
    readable_description: 'Gol Airlines',
    comp_description: 'golair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3248: {
    readable_description: 'Tam Airlines',
    comp_description: 'tamair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3251: {
    readable_description: 'Aloha Airlines',
    comp_description: 'alohaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3252: {
    readable_description: 'Antilean Airlines (ALM)',
    comp_description: 'almair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3254: {
    readable_description: 'U.S. Air Shuttle',
    comp_description: 'usaairshuttle',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3256: {
    readable_description: 'Alaska Airlines Inc.',
    comp_description: 'alaskaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3259: {
    readable_description: 'American Trans Air',
    comp_description: 'ataair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3260: {
    readable_description: 'Spirit Airlines',
    comp_description: 'spiritair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3261: {
    readable_description: 'Air China',
    comp_description: 'airchina',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3262: {
    readable_description: 'Reno Air',
    comp_description: 'renoair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3263: {
    readable_description: 'Aero Servicio Carabobo',
    comp_description: 'ascair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3266: {
    readable_description: 'Air Seychelles',
    comp_description: 'airseych',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3267: {
    readable_description: 'Air Panama International',
    comp_description: 'airpanama',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3280: {
    readable_description: 'Air Jamaica',
    comp_description: 'airjamaca',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3282: {
    readable_description: 'Air Djibouti',
    comp_description: 'airdjbouti',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3284: {
    readable_description: 'Aero Virgin Islands',
    comp_description: 'aervirgin',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3285: {
    readable_description: 'Aero Peru',
    comp_description: 'aeroperu',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3286: {
    readable_description: 'Aero Nicaraguensis',
    comp_description: 'aeronicaraguensis',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3287: {
    readable_description: 'Aero Coach Aviation',
    comp_description: 'aerocoach',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3292: {
    readable_description: 'Cyprus Airways',
    comp_description: 'cyprusair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3293: {
    readable_description: 'Ecuatoriana',
    comp_description: 'ecuatoriana',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3294: {
    readable_description: 'Ethiopian Airlines',
    comp_description: 'ethiopianair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3295: {
    readable_description: 'Kenya Airways',
    comp_description: 'kenyaair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3296: {
    readable_description: 'Air Berlin',
    comp_description: 'airberlin',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3297: {
    readable_description: 'Tarom Romanian Air Transport',
    comp_description: 'taromair',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3298: {
    readable_description: 'Air Mauritius',
    comp_description: 'airmauritius',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3299: {
    readable_description: 'Wideroes Flyveselskap',
    comp_description: 'wideroes',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  3351: {
    readable_description: 'Affiliated Auto Rental',
    comp_description: 'affiliated_auto',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3352: {
    readable_description: 'American International',
    comp_description: 'american_int',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3353: {
    readable_description: 'Brooks Rent-A-Car',
    comp_description: 'brooks_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3354: {
    readable_description: 'Action Auto Rental',
    comp_description: 'action_auto',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3355: {
    readable_description: 'Sixt Car Rental',
    comp_description: 'sixt_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3357: {
    readable_description: 'Hertz',
    comp_description: 'hertz',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3359: {
    readable_description: 'Payless Car Rental',
    comp_description: 'payless_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3360: {
    readable_description: 'Snappy Car Rental',
    comp_description: 'snappy_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3361: {
    readable_description: 'Airways',
    comp_description: 'airways',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3362: {
    readable_description: 'Altra Auto Rental',
    comp_description: 'altra_auto',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3364: {
    readable_description: 'Agency Rent-A-Car',
    comp_description: 'agency_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3366: {
    readable_description: 'Budget Rent-A-Car',
    comp_description: 'budget_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3368: {
    readable_description: 'Holiday Rent-A-Car',
    comp_description: 'holiday_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3370: {
    readable_description: 'Rent-A-Wreck',
    comp_description: 'rent_a_wreck',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3374: {
    readable_description: 'Accent Rent-A-Car',
    comp_description: 'accent_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3376: {
    readable_description: 'Ajax Rent-A-Car',
    comp_description: 'ajax_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3380: {
    readable_description: 'Triangle Rent-A-Car',
    comp_description: 'triangle_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3381: {
    readable_description: 'Europcar',
    comp_description: 'europcar',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3385: {
    readable_description: 'Tropical Rent-A-Car',
    comp_description: 'tropical_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3386: {
    readable_description: 'Showcase Rental Cars',
    comp_description: 'showcase_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3387: {
    readable_description: 'Alamo Rent-A-Car',
    comp_description: 'almo_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3388: {
    readable_description: 'Merchants Rent-A-Car',
    comp_description: 'merchants_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3389: {
    readable_description: 'Avis Rent-A-Car',
    comp_description: 'avis_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3390: {
    readable_description: 'Dollar Rent-A-Car',
    comp_description: 'dollar_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3391: {
    readable_description: 'Europe By Car',
    comp_description: 'europe_by_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3393: {
    readable_description: 'National Car Rental',
    comp_description: 'national_car_rent',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3394: {
    readable_description: 'Kemwell Group',
    comp_description: 'kemwell',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3395: {
    readable_description: 'Thrifty Car Rental',
    comp_description: 'thrifty_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3396: {
    readable_description: 'Tilden Rent-A-Car',
    comp_description: 'tilden_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3398: {
    readable_description: 'Econo-Car Rent-A-Car',
    comp_description: 'econo_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3400: {
    readable_description: 'Auto Host Rental Cars',
    comp_description: 'auto_host',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3405: {
    readable_description: 'Enterprise Rent-A-Car',
    comp_description: 'enterprise_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3409: {
    readable_description: 'General Rent-A-Car',
    comp_description: 'general_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3412: {
    readable_description: 'A1 Rent-A-Car',
    comp_description: 'a1_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3414: {
    readable_description: 'Godfrey National',
    comp_description: 'godfrey_national',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3420: {
    readable_description: 'Ansa International',
    comp_description: 'ansa_int',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3421: {
    readable_description: 'Allstate',
    comp_description: 'allstate',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3423: {
    readable_description: 'AVCAR',
    comp_description: 'avcar',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3425: {
    readable_description: 'Automate',
    comp_description: 'automate',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3427: {
    readable_description: 'Avon Rent-A-Car',
    comp_description: 'avon_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3428: {
    readable_description: 'Carey',
    comp_description: 'carey',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3429: {
    readable_description: 'Insurance Rent-A-Car',
    comp_description: 'insurance_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3430: {
    readable_description: 'Major Rent-A-Car',
    comp_description: 'major_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3431: {
    readable_description: 'Replacement Rent-A-Car',
    comp_description: 'replace_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3432: {
    readable_description: 'Reserve Rent-A-Car',
    comp_description: 'reserve_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3433: {
    readable_description: 'Ugly Duckling Rent-A-Car',
    comp_description: 'ugly_duckling',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3434: {
    readable_description: 'USA',
    comp_description: 'usa_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3435: {
    readable_description: 'Value Rent-A-Car',
    comp_description: 'value_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3436: {
    readable_description: 'Autohansa',
    comp_description: 'autohansa',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3437: {
    readable_description: 'Cite',
    comp_description: 'cite',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3438: {
    readable_description: 'Interent',
    comp_description: 'interent',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3439: {
    readable_description: 'Milleville',
    comp_description: 'milleville',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3441: {
    readable_description: 'Advantage Rent A Car',
    comp_description: 'advantage_rent_a_car',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars ',
  },
  3501: {
    readable_description: 'Holiday Inns',
    comp_description: 'holiday_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3502: {
    readable_description: 'Best Western',
    comp_description: 'best_western',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3503: {
    readable_description: 'Sheraton',
    comp_description: 'sheraton',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3504: {
    readable_description: 'Hilton',
    comp_description: 'hilton',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3505: {
    readable_description: 'Trusthouse Forte',
    comp_description: 'trusthouse_forte',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3506: {
    readable_description: 'Golden Tulip',
    comp_description: 'golden_tulip',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3507: {
    readable_description: 'Friendship Inns International',
    comp_description: 'frendship_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3508: {
    readable_description: 'Quality International',
    comp_description: 'quality_international',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3509: {
    readable_description: 'Marriott',
    comp_description: 'marriott',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3510: {
    readable_description: 'Days Inns Of America',
    comp_description: 'days_inns_america',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3511: {
    readable_description: 'Arabella Hotels',
    comp_description: 'arabella_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3512: {
    readable_description: 'Intercontinental',
    comp_description: 'intercontinental',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3513: {
    readable_description: 'Westin Hotels',
    comp_description: 'westin_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3514: {
    readable_description: 'Amerisuites',
    comp_description: 'amerisuites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3515: {
    readable_description: 'Rodeway Inns International',
    comp_description: 'rodeway_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3516: {
    readable_description: 'La Quinta Motor Inns',
    comp_description: 'la_quinta',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3517: {
    readable_description: 'Americana Hotels Corporation',
    comp_description: 'americana_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3518: {
    readable_description: 'Sol Hotels',
    comp_description: 'sol_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3519: {
    readable_description: 'PLM/ETAP International',
    comp_description: 'plm_etap_international',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3520: {
    readable_description: 'Meridien',
    comp_description: 'meridien',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3521: {
    readable_description: 'Crest Hotels',
    comp_description: 'crest_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3522: {
    readable_description: 'Tokyo Group',
    comp_description: 'tokyo_group',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3523: {
    readable_description: 'Peninsula Hotels',
    comp_description: 'peninsula_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3524: {
    readable_description: 'Welcomgroup',
    comp_description: 'welcomgroup',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3525: {
    readable_description: 'Dunfey Hotels',
    comp_description: 'dunfey_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3526: {
    readable_description: 'Prince Hotels',
    comp_description: 'prince_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3527: {
    readable_description: 'Downtowner Passport',
    comp_description: 'downtowner_passport',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3528: {
    readable_description: 'Thunderbird/Red Lion',
    comp_description: 'thunderbird_red_lion',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3529: {
    readable_description: 'CP (Canadian Pacific)',
    comp_description: 'cp',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3530: {
    readable_description: 'Renaissance Hotels',
    comp_description: 'renaissance_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3531: {
    readable_description: 'Kauai Coconut Beach Resort',
    comp_description: 'kauai_coconut_beach',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3532: {
    readable_description: 'Royal Kona Resort',
    comp_description: 'royal_kona',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3533: {
    readable_description: 'Park Inn by Radisson',
    comp_description: 'park_inn_radisson',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3534: {
    readable_description: 'Southern Pacific Hotel',
    comp_description: 'southern_pacific',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3535: {
    readable_description: 'Hilton International',
    comp_description: 'hilton_int',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3536: {
    readable_description: 'AMFAC Hotels',
    comp_description: 'amfac_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3537: {
    readable_description: 'Ana Hotels',
    comp_description: 'ana_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3538: {
    readable_description: 'Concorde Hotels',
    comp_description: 'concorde_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3539: {
    readable_description: 'Summerfield Suites Hotel',
    comp_description: 'summerfield_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3540: {
    readable_description: 'Iberotel',
    comp_description: 'iberotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3541: {
    readable_description: 'Hotel Okura',
    comp_description: 'hotel_okura',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3542: {
    readable_description: 'Royal Hotels',
    comp_description: 'royal_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3543: {
    readable_description: 'Four Seasons',
    comp_description: 'four_seasons',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3544: {
    readable_description: 'Cigahotels',
    comp_description: 'cigahotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3545: {
    readable_description: 'Shangri-La International',
    comp_description: 'shangri_la_int',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3546: {
    readable_description: 'Hotel Sierra',
    comp_description: 'hotel_sierra',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3548: {
    readable_description: 'Hotels Melia',
    comp_description: 'hotels_melia',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3549: {
    readable_description: 'Auberge Des Governeurs',
    comp_description: 'auberge_des_gov',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3550: {
    readable_description: 'Regal 8 Inns',
    comp_description: 'regal8_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3551: {
    readable_description: 'Mirage Hotel & Casino',
    comp_description: 'mirage_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3552: {
    readable_description: 'Coast Hotels',
    comp_description: 'coast_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3553: {
    readable_description: 'Parks Inns International',
    comp_description: 'parks_inns_int',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3554: {
    readable_description: 'Pinehurst Resort',
    comp_description: 'pinehurst_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3555: {
    readable_description: 'Treasure Island Hotel & Casino',
    comp_description: 'treasure_island_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3556: {
    readable_description: 'Barton Creek Resort',
    comp_description: 'barton_creek',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3557: {
    readable_description: 'Manhattan East Suite Hotels',
    comp_description: 'manhattan_east_suite',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3558: {
    readable_description: 'Jolly Hotels',
    comp_description: 'jolly_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3559: {
    readable_description: 'Candlewood Suites',
    comp_description: 'candlewood_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3560: {
    readable_description: 'Aladdin Resort & Casino',
    comp_description: 'aladdin_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3561: {
    readable_description: 'Golden Nugget',
    comp_description: 'golden_nugget',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3562: {
    readable_description: 'Comfort Hotel International',
    comp_description: 'comfort_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3563: {
    readable_description: 'Journeys End Motels',
    comp_description: 'journeys_end',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3564: {
    readable_description: 'Sams Town Hotel & Casino',
    comp_description: 'sams_town_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3565: {
    readable_description: 'Relax Inns',
    comp_description: 'relax_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3566: {
    readable_description: 'Garden Place Hotel',
    comp_description: 'garden_place',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3567: {
    readable_description: 'Soho Grand Hotel',
    comp_description: 'soho_grand_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3568: {
    readable_description: 'Ladbroke Hotels',
    comp_description: 'ladbroke_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3569: {
    readable_description: 'Tribeca Grand Hotel',
    comp_description: 'tribeca_grand_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3570: {
    readable_description: 'Grand Met Forum Hotels',
    comp_description: 'grand_met_forum_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3571: {
    readable_description: 'Grand Wailea Resort',
    comp_description: 'grand_wailea_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3572: {
    readable_description: 'Miyako Hotel/Kintetsu',
    comp_description: 'miyako_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3573: {
    readable_description: 'Sandman Hotels',
    comp_description: 'sandman_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3574: {
    readable_description: 'Venture Inn',
    comp_description: 'venture_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3575: {
    readable_description: 'Vagabond Hotels',
    comp_description: 'vagabond_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3576: {
    readable_description: 'La Quinta Resort',
    comp_description: 'la_quinta_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3577: {
    readable_description: 'Mandarin International',
    comp_description: 'mandarin_int',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3578: {
    readable_description: 'Frankenmuth Bavarian',
    comp_description: 'frankenmuth_bavarian',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3579: {
    readable_description: 'Hotel Mercure',
    comp_description: 'hotel_mercure',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3580: {
    readable_description: 'Hotel Del Coronado',
    comp_description: 'hotel_del_coronado',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3581: {
    readable_description: 'Delta Hotels',
    comp_description: 'delta_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3582: {
    readable_description: 'California Hotel & Casino',
    comp_description: 'california_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3583: {
    readable_description: 'Radisson BLU',
    comp_description: 'radisson_blu',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3584: {
    readable_description: 'Princess Hotels International',
    comp_description: 'princess_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3585: {
    readable_description: 'Hungar Hotels',
    comp_description: 'hungar_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3586: {
    readable_description: 'Sokos Hotel',
    comp_description: 'sokos_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3587: {
    readable_description: 'Doral Hotels',
    comp_description: 'doral_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3588: {
    readable_description: 'Helmsley Hotels',
    comp_description: 'helmsley_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3589: {
    readable_description: 'Doral Golf Resort',
    comp_description: 'doral_golf_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3590: {
    readable_description: 'Fairmont Hotels Corporation',
    comp_description: 'fairmont_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3591: {
    readable_description: 'Sonesta International Hotels',
    comp_description: 'sonesta_int_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3592: {
    readable_description: 'Omni International',
    comp_description: 'omni_int',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3593: {
    readable_description: 'Cunard Hotels',
    comp_description: 'cunard_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3594: {
    readable_description: 'Arizona Biltmore',
    comp_description: 'arizona_biltmore',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3595: {
    readable_description: 'Hospitality Inns',
    comp_description: 'hospitality_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3596: {
    readable_description: 'Wynn Las Vegas',
    comp_description: 'wynn_las_vegas',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3597: {
    readable_description: 'Riverside Resort & Casino',
    comp_description: 'riverside_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3598: {
    readable_description: 'Regent Hotels',
    comp_description: 'regent_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3599: {
    readable_description: 'Pannonia Hotels',
    comp_description: 'pannonia_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3600: {
    readable_description: 'Saddlebrook Resort–Tampa',
    comp_description: 'saddlebrook_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3601: {
    readable_description: 'Tradewinds Resort',
    comp_description: 'tradewinds_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3602: {
    readable_description: 'Hudson Hotel',
    comp_description: 'hudson_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3603: {
    readable_description: 'Noahs Hotel (Melbourne)',
    comp_description: 'noahs_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3604: {
    readable_description: 'Hilton Garden Inn',
    comp_description: 'hilton_garden_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3607: {
    readable_description: 'Fontainebleau Resort',
    comp_description: 'fontainebleau_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3608: {
    readable_description: 'Gaylord Opryland',
    comp_description: 'gaylord_opryland',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3609: {
    readable_description: 'Gaylord Palms',
    comp_description: 'gaylord_palms',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3612: {
    readable_description: 'Moevenpick',
    comp_description: 'moevenpick',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3613: {
    readable_description: 'Microtel Inn & Suites',
    comp_description: 'microtel_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3614: {
    readable_description: 'AmericInn',
    comp_description: 'americinn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3615: {
    readable_description: 'Travelodge Motels',
    comp_description: 'travelodge',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3617: {
    readable_description: 'America’s Best Value Inn',
    comp_description: 'americas_best_value',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3618: {
    readable_description: 'Great Wolf',
    comp_description: 'great_wolf',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3619: {
    readable_description: 'Aloft',
    comp_description: 'aloft',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3620: {
    readable_description: 'Binions Horseshoe Club',
    comp_description: 'binions_horseshoe_club',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3621: {
    readable_description: 'Extended Stay',
    comp_description: 'extended_stay',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3622: {
    readable_description: 'Merlin Hotel (Perth)',
    comp_description: 'merlin_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3623: {
    readable_description: 'Dorint Hotels',
    comp_description: 'dorint_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3624: {
    readable_description: 'Lady Luck Hotel & Casino',
    comp_description: 'lady_luck_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3625: {
    readable_description: 'Hotel Universal',
    comp_description: 'hotel_universal',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3626: {
    readable_description: 'Studio Plus',
    comp_description: 'studio_plus',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3627: {
    readable_description: 'Extended Stay America',
    comp_description: 'extended_stay_america',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3628: {
    readable_description: 'Excalibur Hotel & Casino',
    comp_description: 'excalibur_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3629: {
    readable_description: 'Dan Hotels',
    comp_description: 'dan_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3630: {
    readable_description: 'Extended Stay Deluxe',
    comp_description: 'extended_stay_deluxe',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3631: {
    readable_description: 'Sleep Inn',
    comp_description: 'sleep_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3632: {
    readable_description: 'The Phoenician',
    comp_description: 'the_phoenician',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3633: {
    readable_description: 'Rank Hotels',
    comp_description: 'rank_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3634: {
    readable_description: 'Swissotel',
    comp_description: 'swissotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3635: {
    readable_description: 'Reso Hotels',
    comp_description: 'reso_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3636: {
    readable_description: 'Sarova Hotels',
    comp_description: 'sarova_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3637: {
    readable_description: 'Ramada Inns',
    comp_description: 'ramada_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3638: {
    readable_description: 'Howard Johnson',
    comp_description: 'howard_johnson',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3639: {
    readable_description: 'Mount Charlotte Hotels',
    comp_description: 'mount_charlotte_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3640: {
    readable_description: 'Hyatt Hotels/International',
    comp_description: 'hyatt_hotels_int',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3641: {
    readable_description: 'Sofitel Hotels',
    comp_description: 'sofitel_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3642: {
    readable_description: 'Novotel Sieh (Accor)',
    comp_description: 'novotel_sieh_accor',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3643: {
    readable_description: 'Steigenberger',
    comp_description: 'steigenberger',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3644: {
    readable_description: 'Econo-Travel Motor Hotel',
    comp_description: 'econo_travel_motor',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3645: {
    readable_description: 'Queens Moat Houses',
    comp_description: 'queens_moat_houses',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3646: {
    readable_description: 'Swallow Hotels',
    comp_description: 'swallow_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3647: {
    readable_description: 'Grupo Hotels HUSA SA',
    comp_description: 'grupo_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3648: {
    readable_description: 'De Vere Hotels',
    comp_description: 'de_vere_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3649: {
    readable_description: 'Radisson',
    comp_description: 'radisson',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3650: {
    readable_description: 'Red Roof Inns',
    comp_description: 'red_roof_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3651: {
    readable_description: 'Imperial London Hotels',
    comp_description: 'imperial_london_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3652: {
    readable_description: 'Embassy Hotels',
    comp_description: 'embassy_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3653: {
    readable_description: 'Penta Hotels',
    comp_description: 'penta_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3654: {
    readable_description: 'Loews Hotels',
    comp_description: 'loews_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3655: {
    readable_description: 'Scandic Hotels',
    comp_description: 'scandic_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3656: {
    readable_description: 'Sara Hotels',
    comp_description: 'sara_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3657: {
    readable_description: 'Oberoi Hotels',
    comp_description: 'oberoi_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3658: {
    readable_description: 'New Otani Hotels',
    comp_description: 'new_otani_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3659: {
    readable_description: 'Taj Hotels Intl',
    comp_description: 'taj_hotels_int',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3660: {
    readable_description: 'Knights Inn',
    comp_description: 'knights_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3661: {
    readable_description: 'Metropole Hotels',
    comp_description: 'metropole_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3662: {
    readable_description: 'Circus Circus Hotel & Casino',
    comp_description: 'circus_circus_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3663: {
    readable_description: 'Hoteles El Presidente',
    comp_description: 'hoteles_el_presidente',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3664: {
    readable_description: 'Flag Inns',
    comp_description: 'flag_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3665: {
    readable_description: 'Hampton Inns',
    comp_description: 'hampton_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3666: {
    readable_description: 'Stakis Hotels',
    comp_description: 'stakis_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3667: {
    readable_description: 'Luxor Hotel & Casino',
    comp_description: 'luxor_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3668: {
    readable_description: 'Maritim',
    comp_description: 'maritim',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3669: {
    readable_description: 'Eldorado Hotel & Casino',
    comp_description: 'eldorado_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3670: {
    readable_description: 'Arcade',
    comp_description: 'arcade',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3671: {
    readable_description: 'Arctia',
    comp_description: 'arctica',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3672: {
    readable_description: 'Campanile',
    comp_description: 'campanile',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3673: {
    readable_description: 'Ibusz Hotels',
    comp_description: 'ibusz_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3674: {
    readable_description: 'Rantasipi Hotels',
    comp_description: 'rantasipi',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3675: {
    readable_description: 'Interhotel Cedok',
    comp_description: 'interhotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3676: {
    readable_description: 'Monte Carlo Hotel & Casino',
    comp_description: 'monte_carlo_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3677: {
    readable_description: 'Climat De France',
    comp_description: 'climat_de_france',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3678: {
    readable_description: 'Cumulus Hotels',
    comp_description: 'cumulus_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3679: {
    readable_description: 'Silver Legacy Hotel & Casino',
    comp_description: 'silver_legacy_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3680: {
    readable_description: 'Hoteis Othan',
    comp_description: 'hoteis_othan',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3681: {
    readable_description: 'Adams Mark',
    comp_description: 'adams_mark',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3682: {
    readable_description: 'Sahara Hotel & Casino',
    comp_description: 'sahara_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3683: {
    readable_description: 'Bradbury Suites',
    comp_description: 'bradbury_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3684: {
    readable_description: 'Budget Host Inns',
    comp_description: 'budget_host_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3685: {
    readable_description: 'Budgetel',
    comp_description: 'budgetel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3686: {
    readable_description: 'Suisse Chalet',
    comp_description: 'suisse_chalet',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3687: {
    readable_description: 'Clarion Hotel',
    comp_description: 'clarion_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3688: {
    readable_description: 'Compri Hotel',
    comp_description: 'compri_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3689: {
    readable_description: 'Consort',
    comp_description: 'consort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3690: {
    readable_description: 'Courtyard by Marriott',
    comp_description: 'courtyard_marriott',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3691: {
    readable_description: 'Dillon Inn',
    comp_description: 'dillon_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3692: {
    readable_description: 'Doubletree',
    comp_description: 'doubletree',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3693: {
    readable_description: 'Drury Inn',
    comp_description: 'drury_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3694: {
    readable_description: 'Economy Inns Of America',
    comp_description: 'economy_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3695: {
    readable_description: 'Embassy Suites',
    comp_description: 'embassy_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3696: {
    readable_description: 'Excel Inn',
    comp_description: 'excel_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3697: {
    readable_description: 'Fairfield Hotel',
    comp_description: 'fairfield_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3698: {
    readable_description: 'Harley Hotel',
    comp_description: 'harley_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3699: {
    readable_description: 'Midway Motor Lodge',
    comp_description: 'midway_motor_lodge',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3700: {
    readable_description: 'Motel 6',
    comp_description: 'motel6',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3701: {
    readable_description: 'La Mansion Del Rio',
    comp_description: 'la_mansion_del_rio',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3702: {
    readable_description: 'Registry Hotels',
    comp_description: 'registry_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3703: {
    readable_description: 'Residence Inn',
    comp_description: 'residence_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3704: {
    readable_description: 'Royce Hotel',
    comp_description: 'royce_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3705: {
    readable_description: 'Sandman Inn',
    comp_description: 'sandman_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3706: {
    readable_description: 'Shilo Inn',
    comp_description: 'shilo_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3707: {
    readable_description: 'Shoneys Inn',
    comp_description: 'shoneys_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3708: {
    readable_description: 'Virgin River Hotel & Casino',
    comp_description: 'virgin_river_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3709: {
    readable_description: 'Super 8 Motel',
    comp_description: 'super8_motel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3710: {
    readable_description: 'Ritz-Carlton',
    comp_description: 'ritz_carlton',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3711: {
    readable_description: 'Flag Inns (Australia)',
    comp_description: 'flag_inns_australia',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3712: {
    readable_description: 'Buffalo Bills Hotel & Casino',
    comp_description: 'buffalo_bills_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3713: {
    readable_description: 'Quality Pacific Hotel',
    comp_description: 'quality_pacific_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3714: {
    readable_description: 'Four Seasons (Australia)',
    comp_description: 'four_seasons_australia',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3715: {
    readable_description: 'Fairfield Inn',
    comp_description: 'fairfield_inn',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3716: {
    readable_description: 'Carlton Hotels',
    comp_description: 'carlton_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3717: {
    readable_description: 'City Lodge Hotels',
    comp_description: 'city_lodge_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3718: {
    readable_description: 'Karos Hotels',
    comp_description: 'karos_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3719: {
    readable_description: 'Protea Hotels',
    comp_description: 'protea_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3720: {
    readable_description: 'Southern Sun Hotels',
    comp_description: 'southern_sun_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3721: {
    readable_description: 'Conrad Hotels',
    comp_description: 'conrad_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3722: {
    readable_description: 'Wyndham',
    comp_description: 'wyndham',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3723: {
    readable_description: 'Rica Hotels',
    comp_description: 'rica_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3724: {
    readable_description: 'Inter Nor Hotels',
    comp_description: 'inter_nor_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3725: {
    readable_description: 'Sea Pines Resort',
    comp_description: 'sea_pines_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3726: {
    readable_description: 'Rio Suites',
    comp_description: 'rio_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3727: {
    readable_description: 'Broadmoor Hotel',
    comp_description: 'broadmoor_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3728: {
    readable_description: 'Ballys Hotel & Casino',
    comp_description: 'ballys_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3729: {
    readable_description: 'John Ascuagas Nugget',
    comp_description: 'john_ascuagas_nugget',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3730: {
    readable_description: 'MGM Grand Hotel',
    comp_description: 'mgm_grand_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3731: {
    readable_description: 'Harrah’s Hotels & Casinos',
    comp_description: 'harrahs_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3732: {
    readable_description: 'Opryland Hotel',
    comp_description: 'opryland_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3734: {
    readable_description: 'Harvey Bristol Hotels',
    comp_description: 'harvey_bristol_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3735: {
    readable_description: 'Masters Economy Inns',
    comp_description: 'masters_economy_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3736: {
    readable_description: 'Colorado Belle Edgewater Resort',
    comp_description: 'colorado_belle_edgewtr',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3737: {
    readable_description: 'Riviera Hotel & Casino',
    comp_description: 'riviera_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3738: {
    readable_description: 'Tropicana Resort & Casino',
    comp_description: 'tropicana_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3739: {
    readable_description: 'Woodside Hotels & Resorts',
    comp_description: 'woodside_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3740: {
    readable_description: 'Towneplace Suites',
    comp_description: 'towneplace_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3741: {
    readable_description: 'Millennium Broadway Hotel',
    comp_description: 'millenium_broadway',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3742: {
    readable_description: 'Club Med',
    comp_description: 'club_med',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3743: {
    readable_description: 'Biltmore Hotel & Suites',
    comp_description: 'biltmore_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3744: {
    readable_description: 'Carefree Resorts',
    comp_description: 'carefree_resorts',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3745: {
    readable_description: 'St. Regis Hotel',
    comp_description: 'st_regis_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3746: {
    readable_description: 'The Eliot Hotel',
    comp_description: 'the_eliot_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3747: {
    readable_description: 'Club Corporation/Club Resorts',
    comp_description: 'club_corp_club_resorts',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3748: {
    readable_description: 'Wellesley Inns',
    comp_description: 'wellesley_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3749: {
    readable_description: 'Beverly Hills Hotel',
    comp_description: 'beverly_hills_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3750: {
    readable_description: 'Crowne Plaza Hotels',
    comp_description: 'crowne_plaza_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3751: {
    readable_description: 'Homewood Suites',
    comp_description: 'homewood_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3752: {
    readable_description: 'Peabody Hotels',
    comp_description: 'peabody_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3753: {
    readable_description: 'Greenbriar Resorts',
    comp_description: 'greenbriar_resorts',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3754: {
    readable_description: 'Amelia Island Plantation',
    comp_description: 'amelia_islnd_plantation',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3755: {
    readable_description: 'Homestead',
    comp_description: 'homestead',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3758: {
    readable_description: 'Kahala Mandarin Oriental Hotel',
    comp_description: 'kahala_mandarin_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3759: {
    readable_description: 'Orchid At Mauna Lai',
    comp_description: 'orchid_at_mauna_lai',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3760: {
    readable_description: 'Halekulani Hotel/Waikiki Parc',
    comp_description: 'halekulani_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3761: {
    readable_description: 'Primadonna Hotel & Casino',
    comp_description: 'primadonna_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3762: {
    readable_description: 'Whiskey Pete’s Hotel & Casino',
    comp_description: 'whiskey_petes_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3763: {
    readable_description: 'Chateau Elan Winery & Resort',
    comp_description: 'chateau_elan_winery',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3764: {
    readable_description: 'Beau Rivage Hotel & Casino',
    comp_description: 'beau_rivage_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3765: {
    readable_description: 'Bellagio',
    comp_description: 'bellagio',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3766: {
    readable_description: 'Fremont Hotel & Casino',
    comp_description: 'fremont_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3767: {
    readable_description: 'Main Street Station Hotel & Casino',
    comp_description: 'main_st_station_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3768: {
    readable_description: 'Silver Star Hotel & Casino',
    comp_description: 'silver_star_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3769: {
    readable_description: 'Stratosphere Hotel & Casino',
    comp_description: 'stratosphere_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3770: {
    readable_description: 'Springhill Suites',
    comp_description: 'springhill_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3771: {
    readable_description: 'Caesars Hotel & Casino',
    comp_description: 'caesars_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3772: {
    readable_description: 'Nemacolin Woodlands',
    comp_description: 'nemacolin_woodlands',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3773: {
    readable_description: 'Venetian Resort Hotel & Casino',
    comp_description: 'venetian_resort_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3774: {
    readable_description: 'New York-New York Hotel & Casino',
    comp_description: 'ny_ny_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3775: {
    readable_description: 'Sands Resort',
    comp_description: 'sands_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3776: {
    readable_description: 'Nevele Grande Resort & Country Club',
    comp_description: 'nevele_grande_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3777: {
    readable_description: 'Mandalay Bay Resort',
    comp_description: 'mandalay_bay_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3778: {
    readable_description: 'Four Points Hotels',
    comp_description: 'four_points_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3779: {
    readable_description: 'W Hotels',
    comp_description: 'w_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3780: {
    readable_description: 'Disneyland Resorts',
    comp_description: 'disneyland_resorts',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3781: {
    readable_description: 'Patricia Grand Resort Hotels',
    comp_description: 'patricia_grand_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3782: {
    readable_description: 'Rosen Hotel & Resorts',
    comp_description: 'rosen_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3783: {
    readable_description: 'Town & Country Resort & Convention Center',
    comp_description: 'town_and_country',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3784: {
    readable_description: 'First Hospitality Hotels',
    comp_description: 'first_hospitality',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3785: {
    readable_description: 'Outrigger Hotels & Resorts',
    comp_description: 'outrigger_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3786: {
    readable_description: 'Ohana Hotels of Hawaii',
    comp_description: 'ohana_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3787: {
    readable_description: 'Caribe Royal Resort Suite & Villas',
    comp_description: 'caribe_royal_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3788: {
    readable_description: 'Ala Moana Hotel',
    comp_description: 'ala_moana_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3789: {
    readable_description: 'Smugglers Notch Resort',
    comp_description: 'smugglers_notch_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3790: {
    readable_description: 'Raffles Hotels',
    comp_description: 'raffles_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3791: {
    readable_description: 'Staybridge Suites',
    comp_description: 'staybridge_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3792: {
    readable_description: 'Claridge Casino Hotel',
    comp_description: 'claridge_casino_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3793: {
    readable_description: 'The Flamingo Hotels',
    comp_description: 'the_flamingo_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3794: {
    readable_description: 'Grand Casino Hotels',
    comp_description: 'grand_casino_hotels',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3795: {
    readable_description: 'Paris Las Vegas Hotel',
    comp_description: 'paris_las_vegas_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3796: {
    readable_description: 'Peppermill Hotel Casino',
    comp_description: 'peppermill_hotel_casino',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3797: {
    readable_description: 'Atlantic City Hilton',
    comp_description: 'atlantic_city_hilton',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3798: {
    readable_description: 'Embassy Vacation Resort',
    comp_description: 'embassy_vacation_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3799: {
    readable_description: 'Hale Koa Hotel',
    comp_description: 'hale_koa_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3800: {
    readable_description: 'Homestead Suites',
    comp_description: 'homestead_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3801: {
    readable_description: 'Wilderness Hotel & Resort',
    comp_description: 'wilderness_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3802: {
    readable_description: 'The Palace Hotel',
    comp_description: 'the_palace_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3807: {
    readable_description: 'Element',
    comp_description: 'element',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3808: {
    readable_description: 'LXR (Luxury Resorts)',
    comp_description: 'lxr',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3811: {
    readable_description: 'Premier Travel Inns',
    comp_description: 'premier_travel_inns',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3812: {
    readable_description: 'Hyatt Place',
    comp_description: 'hyatt_place',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3813: {
    readable_description: 'Hotel Indigo',
    comp_description: 'hotel_indego',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3814: {
    readable_description: 'The Roosevelt Hotel NY',
    comp_description: 'the_roosevelt_hotel_ny',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3815: {
    readable_description: 'Nickelodeon Family Suites by Holiday Inn',
    comp_description: 'nickelodeon_by_holiday',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3816: {
    readable_description: 'Home2Suites',
    comp_description: 'home2suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3818: {
    readable_description: 'Mainstay Suites',
    comp_description: 'mainstay_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3819: {
    readable_description: 'Oxford Suites',
    comp_description: 'oxford_suites',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3820: {
    readable_description: 'Jumeirah Essex House',
    comp_description: 'jumeirah_essex_house',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3821: {
    readable_description: 'Caribe Royal',
    comp_description: 'caribe_royal',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3822: {
    readable_description: 'Crossland',
    comp_description: 'crossland',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3823: {
    readable_description: 'Grand Sierra Resort',
    comp_description: 'grand_sierra_resort',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3824: {
    readable_description: 'Aria',
    comp_description: 'aria',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3825: {
    readable_description: 'Vdara',
    comp_description: 'vdara',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3826: {
    readable_description: 'Autograph',
    comp_description: 'autograph',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3827: {
    readable_description: 'Galt House',
    comp_description: 'galt_house',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3828: {
    readable_description: 'Cosmopolitan of Las Vegas',
    comp_description: 'cosmopolitan_lv',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3829: {
    readable_description: 'Country Inn By Carlson',
    comp_description: 'country_inn_carlson',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3830: {
    readable_description: 'Park Plaza Hotel',
    comp_description: 'park_plaza_hotel',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  3831: {
    readable_description: 'Waldorf',
    comp_description: 'waldorf',
    comp_category: 'hotels_lodging',
    readable_category: 'Hotels and Lodging',
  },
  4011: {
    readable_description: 'Freight Railroads',
    comp_description: 'freight',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  4111: {
    readable_description: 'Commuter Transportation',
    comp_description: 'commuter',
    comp_category: 'transportation',
    readable_category: 'Transportation',
  },
  4112: {
    readable_description: 'Passenger Railways',
    comp_description: 'passenger',
    comp_category: 'transportation',
    readable_category: 'Transportation',
  },
  4119: {
    readable_description: 'Ambulance',
    comp_description: 'ambulance',
    comp_category: 'transportation',
    readable_category: 'Transportation',
  },
  4121: {
    readable_description: 'Taxicabs & Limos ',
    comp_description: 'taxi',
    comp_category: 'transportation',
    readable_category: 'Transportation',
  },
  4131: {
    readable_description: 'Bus ',
    comp_description: 'bus',
    comp_category: 'transportation',
    readable_category: 'Transportation',
  },
  4214: {
    readable_description: 'Delivery, Moving, & Storage',
    comp_description: 'delivery',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  4215: {
    readable_description: 'Courier Services',
    comp_description: 'courier',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  4225: {
    readable_description: 'Public Warehousing',
    comp_description: 'warehouse',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  4411: {
    readable_description: 'Cruise Lines',
    comp_description: 'cruise',
    comp_category: 'travel',
    readable_category: 'Travel',
  },
  4457: {
    readable_description: 'Boat Leases & Rentals',
    comp_description: 'boat',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  4468: {
    readable_description: 'Marinas, Marine Service/Supplies',
    comp_description: 'marinas',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  4511: {
    readable_description: 'Other Air Carriers',
    comp_description: 'other_air',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  4582: {
    readable_description: 'Airports & Air Fields ',
    comp_description: 'airports',
    comp_category: 'airline_travel',
    readable_category: 'Airline Travel',
  },
  4722: {
    readable_description: 'Travel Agencies & Tour Operators',
    comp_description: 'travel_agencies',
    comp_category: 'travel',
    readable_category: 'Travel',
  },
  4723: {
    readable_description: 'Germany Package Tour Operators',
    comp_description: 'germany_tour',
    comp_category: 'travel',
    readable_category: 'Travel',
  },
  4784: {
    readable_description: 'Tolls',
    comp_description: 'toll',
    comp_category: 'transportation',
    readable_category: 'Transportation',
  },
  4789: {
    readable_description: 'Other Transportation Services',
    comp_description: 'other_transport',
    comp_category: 'transportation',
    readable_category: 'Transportation',
  },
  4812: {
    readable_description: 'Telecommunication Equipment',
    comp_description: 'telecom_equip',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  4814: {
    readable_description: 'Telecommunication',
    comp_description: 'telecom',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  4816: {
    readable_description: 'Computer Network/Information',
    comp_description: 'comp_network',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  4821: {
    readable_description: 'Telegraph',
    comp_description: 'telegraph',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  4829: {
    readable_description: 'Wire Transfer, Money Orders, & Money Transfers',
    comp_description: 'wire_money_trans',
    comp_category: 'fees',
    readable_category: 'Fees',
  },
  4899: {
    readable_description: 'Cable, Satellite, Pay Television, & Radio',
    comp_description: 'cable',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  4900: {
    readable_description: 'Utilities',
    comp_description: 'utilities',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  5013: {
    readable_description: 'Motor Vehicle Supplies',
    comp_description: 'vehicle_supplies',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5021: {
    readable_description: 'Commercial Furniture',
    comp_description: 'com_furniture',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5039: {
    readable_description: 'Other Construction Materials ',
    comp_description: 'other_construction',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5044: {
    readable_description: 'Office & Photographic Equipment',
    comp_description: 'office_equip',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5045: {
    readable_description: 'Computer Equipment & Software',
    comp_description: 'comp_equip',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5046: {
    readable_description: 'Other Commercial Equipment',
    comp_description: 'other_com_equip',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5047: {
    readable_description: 'Hospital Supplies',
    comp_description: 'hospital_supplies',
    comp_category: 'health',
    readable_category: 'Health',
  },
  5051: {
    readable_description: 'Metal Service Centers & Offices',
    comp_description: 'metal',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5065: {
    readable_description: 'Electrical Parts & Equipment',
    comp_description: 'electrical_equip',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5072: {
    readable_description: 'Hardware Equipment & Supplies',
    comp_description: 'hardware_equip',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5074: {
    readable_description: 'Plumbing & Heating Equipment',
    comp_description: 'plumbing_equip',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5085: {
    readable_description: 'Other Industrial Supplies',
    comp_description: 'other_supplies',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5094: {
    readable_description: 'Precious Stones & Metals, Watches & Jewelry',
    comp_description: 'precious',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5099: {
    readable_description: 'Other Durable Goods',
    comp_description: 'other_durable',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5111: {
    readable_description: 'Stationery & Office Supplies',
    comp_description: 'office_supplies',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5122: {
    readable_description: 'Drugs, Drug Proprietor, & Druggists Sundries',
    comp_description: 'drugs',
    comp_category: 'health',
    readable_category: 'Health',
  },
  5131: {
    readable_description: 'Piece Goods, Notions, & Other Dry Goods',
    comp_description: 'other_dry_goods',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5137: {
    readable_description: 'Uniforms & Commercial Clothing',
    comp_description: 'uniforms',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5139: {
    readable_description: 'Commercial Footware',
    comp_description: 'com_footware',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5169: {
    readable_description: 'Other Chemicals & Allied Products',
    comp_description: 'other_chemicals',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5172: {
    readable_description: 'Petroleum Products',
    comp_description: 'petroleum',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5192: {
    readable_description: 'Books, Periodicals, & Newspapers',
    comp_description: 'writing',
    comp_category: 'recreation',
    readable_category: 'Recreation',
  },
  5193: {
    readable_description: 'Florists Supplies, Nursery Stock, & Flowers',
    comp_description: 'florists',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5198: {
    readable_description: 'Paints, Varnishes, & Supplies',
    comp_description: 'paint_supplies',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5199: {
    readable_description: 'Other Non-Durable Goods',
    comp_description: 'other_nondurable',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5200: {
    readable_description: 'Home Supply Warehouse',
    comp_description: 'home_warehouse',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5211: {
    readable_description: 'Building Materials, Lumber',
    comp_description: 'building_supplies',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5231: {
    readable_description: 'Glass, Paint, Wallpaper',
    comp_description: 'glass_paint_wallpaper',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5251: {
    readable_description: 'Hardware',
    comp_description: 'hardware_store',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5261: {
    readable_description: 'Lawn & Garden Supply',
    comp_description: 'garden_supplies',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5271: {
    readable_description: 'Mobile Home Dealers',
    comp_description: 'mobile_home',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5300: {
    readable_description: 'Wholesale Clubs',
    comp_description: 'wholesale',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5309: {
    readable_description: 'Duty Free',
    comp_description: 'duty_free',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5310: {
    readable_description: 'Discount Stores',
    comp_description: 'discount',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5311: {
    readable_description: 'Department Stores',
    comp_description: 'department',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5331: {
    readable_description: 'Variety Stores',
    comp_description: 'variety',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5399: {
    readable_description: 'Miscellaneous General Merchandise Stores',
    comp_description: 'misc_merchandise',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5411: {
    readable_description: 'Grocery Stores, Supermarkets',
    comp_description: 'grocery_stores',
    comp_category: 'groceries',
    readable_category: 'Groceries',
  },
  5422: {
    readable_description: 'Freezer & Locker Meat Provisioners',
    comp_description: 'freezer_meat',
    comp_category: 'groceries',
    readable_category: 'Groceries',
  },
  5441: {
    readable_description: 'Candy, Nuts, & Confectionery',
    comp_description: 'candy',
    comp_category: 'groceries',
    readable_category: 'Groceries',
  },
  5451: {
    readable_description: 'Dairy Products',
    comp_description: 'dairy',
    comp_category: 'groceries',
    readable_category: 'Groceries',
  },
  5462: {
    readable_description: 'Bakeries',
    comp_description: 'bakeries',
    comp_category: 'groceries',
    readable_category: 'Groceries',
  },
  5499: {
    readable_description: 'Miscellaneous Food',
    comp_description: 'misc_food',
    comp_category: 'groceries',
    readable_category: 'Groceries',
  },
  5511: {
    readable_description: 'Automobiles & Trucks',
    comp_description: 'automobiles',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5521: {
    readable_description: 'Used Automobiles & Trucks',
    comp_description: 'used_automobiles',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5532: {
    readable_description: 'Automotive Tires',
    comp_description: 'auto_tires',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5533: {
    readable_description: 'Automotive Parts & Accessories',
    comp_description: 'auto_parts',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5541: {
    readable_description: 'Service Stations',
    comp_description: 'service_stations',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5542: {
    readable_description: 'Automated Fuel Dispensers',
    comp_description: 'automated_fuel',
    comp_category: 'gas',
    readable_category: 'Gas',
  },
  5551: {
    readable_description: 'Boats',
    comp_description: 'boats',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5561: {
    readable_description: 'Campers, Recreational, & Utility Trailers',
    comp_description: 'campers_trailers',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5571: {
    readable_description: 'Motorcycles',
    comp_description: 'motorcycles',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5592: {
    readable_description: 'Motor Homes',
    comp_description: 'motor_home',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5598: {
    readable_description: 'Snowmobiles',
    comp_description: 'snowmobile',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5599: {
    readable_description: 'Other Automotive, Aircraft, & Farm Equipment',
    comp_description: 'other_auto',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  5611: {
    readable_description: "Men's Apparel & Accessories ",
    comp_description: 'men_apparel',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5621: {
    readable_description: 'Women’s Ready to Wear Stores',
    comp_description: 'women_wear_store',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5631: {
    readable_description: 'Women’s Accessories',
    comp_description: 'women_accessories',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5641: {
    readable_description: "Children's Apparel",
    comp_description: 'children_apparel',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5651: {
    readable_description: 'Family Apparel ',
    comp_description: 'family_apparel',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5655: {
    readable_description: 'Sports Apparel',
    comp_description: 'sports_apparel',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5661: {
    readable_description: 'Shoes',
    comp_description: 'shoes',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5681: {
    readable_description: 'Furriers & Fur Shops',
    comp_description: 'fur',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5691: {
    readable_description: 'Clothing Stores',
    comp_description: 'clothing',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5697: {
    readable_description: 'Alterations, Mending, Seamstresses, Tailors',
    comp_description: 'tailors',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  5698: {
    readable_description: 'Wig & Toupee Shops',
    comp_description: 'wigs',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5699: {
    readable_description: 'Other Acessories & Apparel',
    comp_description: 'other_apparel',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5712: {
    readable_description: 'Equipment, Furniture & Home Furnishings',
    comp_description: 'home_furnishing',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5713: {
    readable_description: 'Floor Covering',
    comp_description: 'floor_covering',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5714: {
    readable_description: 'Drapery, Upholstery, & Window Coverings',
    comp_description: 'drapery',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5718: {
    readable_description: 'Fireplace & Fireplace Accessories',
    comp_description: 'fireplace',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5719: {
    readable_description: 'Other House Furnishing',
    comp_description: 'other_house_furnishing',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5722: {
    readable_description: 'Household Appliances',
    comp_description: 'household_apps',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5732: {
    readable_description: 'Electronics',
    comp_description: 'electronics',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5733: {
    readable_description: 'Music ',
    comp_description: 'music',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5734: {
    readable_description: 'Computer Software',
    comp_description: 'software',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5735: {
    readable_description: 'Records ',
    comp_description: 'records',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5811: {
    readable_description: 'Caterers',
    comp_description: 'caterers',
    comp_category: 'food_and_drink',
    readable_category: 'Food and Drink',
  },
  5812: {
    readable_description: 'Restaurants',
    comp_description: 'restaurants',
    comp_category: 'food_and_drink',
    readable_category: 'Food and Drink',
  },
  5813: {
    readable_description: 'Bars & Night Clubs',
    comp_description: 'alcoholic_places',
    comp_category: 'food_and_drink',
    readable_category: 'Food and Drink',
  },
  5814: {
    readable_description: 'Fast Food Restaurants',
    comp_description: 'fast_food',
    comp_category: 'food_and_drink',
    readable_category: 'Food and Drink',
  },
  5815: {
    readable_description: 'Digital Books, Movies, & Music',
    comp_description: 'digital_books_movies',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  5816: {
    readable_description: 'Digital Games',
    comp_description: 'digital_games',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  5817: {
    readable_description: 'Digital Applications',
    comp_description: 'digital_apps',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  5818: {
    readable_description: 'Multi-Category Digital Goods',
    comp_description: 'digital_multi',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  5912: {
    readable_description: 'Drug Stores & Pharmacies',
    comp_description: 'pharmacies',
    comp_category: 'health',
    readable_category: 'Health',
  },
  5921: {
    readable_description: 'Beer, Wine, & Liquor',
    comp_description: 'alcohol',
    comp_category: 'food_and_drink',
    readable_category: 'Food and Drink',
  },
  5931: {
    readable_description: 'Used Merchandise',
    comp_description: 'used_goods',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5932: {
    readable_description: 'Antique Shops',
    comp_description: 'antique',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5933: {
    readable_description: 'Pawn Shops',
    comp_description: 'pawn',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5935: {
    readable_description: 'Wrecking & Salvage',
    comp_description: 'wrecking',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5937: {
    readable_description: 'Antique Reproduction',
    comp_description: 'antique_reproduction',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5940: {
    readable_description: 'Bicycles',
    comp_description: 'bicycles',
    comp_category: 'travel',
    readable_category: 'Travel',
  },
  5941: {
    readable_description: 'Sporting Goods',
    comp_description: 'sporting_goods',
    comp_category: 'recreation',
    readable_category: 'Recreation',
  },
  5942: {
    readable_description: 'Book Stores',
    comp_description: 'books',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5943: {
    readable_description: 'Office & School Supplies',
    comp_description: 'office_school_supplies',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5944: {
    readable_description: 'Clock, Jewelry, Watches, & Silverware',
    comp_description: 'watches_jewelry',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5945: {
    readable_description: 'Game, Toy, & Hobby Shops',
    comp_description: 'hobby_shops',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5946: {
    readable_description: 'Camera & Photographic Supply',
    comp_description: 'camera_equip',
    comp_category: 'recreation',
    readable_category: 'Recreation',
  },
  5947: {
    readable_description: 'Card, Gift, Novelty, & Souvenirs',
    comp_description: 'cards_souvenirs',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5948: {
    readable_description: 'Leather Goods & Luggage',
    comp_description: 'leather_goods',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5949: {
    readable_description: 'Fabric, Needlework, & Sewing',
    comp_description: 'sewing',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5950: {
    readable_description: 'Crystal & Glassware',
    comp_description: 'glassware',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5960: {
    readable_description: 'Insurance Direct Marketing',
    comp_description: 'insurance_directmrkting',
    comp_category: 'business_categories',
    readable_category: 'Business Categories',
  },
  5962: {
    readable_description: 'Travel Direct Marketing',
    comp_description: 'travel_directmrkting',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5963: {
    readable_description: 'Door-to-Door Sales',
    comp_description: 'door_to_door',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5964: {
    readable_description: 'Catalog Merchants Direct Marketing',
    comp_description: 'catalog__directmrkting',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5965: {
    readable_description: 'Combination Catalog & Retail Merchant',
    comp_description: 'combo__directmrkting',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5966: {
    readable_description: 'Outbound Telemarketing',
    comp_description: 'out_telemarketing',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5967: {
    readable_description: 'Inbound Telemarketing',
    comp_description: 'inbound_telemarketing',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5968: {
    readable_description: 'Subscription Direct Marketing ',
    comp_description: 'subscrip__directmrkting',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5969: {
    readable_description: 'Other Direct Marketing',
    comp_description: 'other_directmrkting',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5970: {
    readable_description: 'Artist Supply Stores, Craft Shops',
    comp_description: 'artist_supply',
    comp_category: 'recreation',
    readable_category: 'Recreation',
  },
  5971: {
    readable_description: 'Art Dealers & Galleries',
    comp_description: 'art_galleries',
    comp_category: 'recreation',
    readable_category: 'Recreation',
  },
  5972: {
    readable_description: 'Stamps & Coins',
    comp_description: 'stamps_coins',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5973: {
    readable_description: 'Religious Goods',
    comp_description: 'religious_goods',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5975: {
    readable_description: 'Hearing Aids',
    comp_description: 'hearing_aids',
    comp_category: 'health',
    readable_category: 'Health',
  },
  5976: {
    readable_description: 'Orthopedic Goods',
    comp_description: 'orthopedic_goods',
    comp_category: 'health',
    readable_category: 'Health',
  },
  5977: {
    readable_description: 'Cosmetics',
    comp_description: 'cosmetics',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5978: {
    readable_description: 'Typewriters',
    comp_description: 'typewriters',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  5983: {
    readable_description: 'Fuel',
    comp_description: 'fuel',
    comp_category: 'gas',
    readable_category: 'Gas',
  },
  5992: {
    readable_description: 'Florists',
    comp_description: 'florists',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  5993: {
    readable_description: 'Cigars',
    comp_description: 'cigars',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  5994: {
    readable_description: 'News Dealers & Newsstands',
    comp_description: 'news',
    comp_category: 'recreation',
    readable_category: 'Recreation',
  },
  5995: {
    readable_description: 'Pet Food & Supplies',
    comp_description: 'pet_supplies',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  5996: {
    readable_description: 'Swimming Pools',
    comp_description: 'swimming_pools',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5997: {
    readable_description: 'Electric Razor',
    comp_description: 'electric_razor',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  5998: {
    readable_description: 'Tent & Awning ',
    comp_description: 'tents',
    comp_category: 'home',
    readable_category: 'Home',
  },
  5999: {
    readable_description: 'Miscellaneous & Specialty Retail',
    comp_description: 'misc_retail',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  6010: {
    readable_description: 'Manual Cash Disbursements',
    comp_description: 'manual_cash_disburse',
    comp_category: 'fees',
    readable_category: 'Fees',
  },
  6011: {
    readable_description: 'Automated Cash Disbursements',
    comp_description: 'auto_cash_disburse',
    comp_category: 'fees',
    readable_category: 'Fees',
  },
  6012: {
    readable_description: 'Financial Institution Merchandise & Services',
    comp_description: 'fin_inst_merch',
    comp_category: 'fees',
    readable_category: 'Fees',
  },
  6050: {
    readable_description: 'Quasi Cash–Member Financial Institution',
    comp_description: 'quasi_cash_member_financial_institution',
    comp_category: 'fees',
    readable_category: 'Fees',
  },
  6051: {
    readable_description: 'Quasi Cash–Merchant',
    comp_description: 'quasi_cash_merchant',
    comp_category: 'fees',
    readable_category: 'Fees',
  },
  6211: {
    readable_description: 'Securities Brokers & Dealers',
    comp_description: 'securities',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  6300: {
    readable_description: 'Insurance Sales, Underwriting, & Premiums',
    comp_description: 'insurance ',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  6411: {
    readable_description: 'Testing MCC for internal use',
    comp_description: 'testing_mcc',
    comp_category: 'testing',
    readable_category: 'Internal Testing',
  },
  6513: {
    readable_description: 'Real Estate Agents & Rental Managers',
    comp_description: 'real_estate',
    comp_category: 'bills_utilities',
    readable_category: 'Bills and Utilities',
  },
  7011: {
    readable_description: 'Other Lodging',
    comp_description: 'other_lodging',
    comp_category: 'lodging_hotels',
    readable_category: 'Hotel and Lodging',
  },
  7012: {
    readable_description: 'Timeshares',
    comp_description: 'timeshares',
    comp_category: 'lodging_hotels',
    readable_category: 'Hotel and Lodging',
  },
  7032: {
    readable_description: 'Sporting & Recreational Camps',
    comp_description: 'sporting_camps',
    comp_category: 'food_and_drink',
    readable_category: 'Food and Drink',
  },
  7033: {
    readable_description: 'Campgrounds & Trailer Parks',
    comp_description: 'campgrounds',
    comp_category: 'lodging_hotels',
    readable_category: 'Hotel and Lodging',
  },
  7210: {
    readable_description: 'Cleaning, Garment & Laundry Services',
    comp_description: 'laundry',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7211: {
    readable_description: 'Laundry Services–Family & Commercial',
    comp_description: 'fam_com_laundry',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7216: {
    readable_description: 'Dry Cleaners',
    comp_description: 'dry_cleaners',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7217: {
    readable_description: 'Carpet & Upholstery Cleaning',
    comp_description: 'carpet_cleaning',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7221: {
    readable_description: 'Photographic Studios',
    comp_description: 'photo_studio',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7230: {
    readable_description: 'Barber & Beauty Shops',
    comp_description: 'barber_beauty_shops',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  7251: {
    readable_description: 'Hat Cleaning, Shoe Repair &, Shoe Shine Parlors',
    comp_description: 'hat_shoe_repair_shine',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7261: {
    readable_description: 'Funeral Service & Crematories',
    comp_description: 'funeral',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  7273: {
    readable_description: 'Dating Services',
    comp_description: 'dating',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  7276: {
    readable_description: 'Tax Preparation Service',
    comp_description: 'tax_prep',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7277: {
    readable_description: 'Debt, Marriage, Personal–Counseling Services',
    comp_description: 'personal_counseling',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  7278: {
    readable_description: 'Buying/Shopping Clubs',
    comp_description: 'shopping_clubs',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  7296: {
    readable_description: 'Clothing Rental',
    comp_description: 'clothing_rental',
    comp_category: 'shopping',
    readable_category: 'Shopping',
  },
  7297: {
    readable_description: 'Massage Parlors',
    comp_description: 'massage_parlors',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  7298: {
    readable_description: 'Health & Beauty Spas',
    comp_description: 'health_spas',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  7299: {
    readable_description: 'Other Services',
    comp_description: 'other_services',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7311: {
    readable_description: 'Advertising',
    comp_description: 'advertising',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7321: {
    readable_description: 'Consumer Credit Reporting Agencies',
    comp_description: 'consumer_cred_report',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7333: {
    readable_description: 'Commercial Art, Graphics, Photography',
    comp_description: 'commercial_art',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7338: {
    readable_description: 'Reproduction & Blueprinting',
    comp_description: 'reproduction_blueprint',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7339: {
    readable_description: 'Stenographic & Secretarial Support',
    comp_description: 'stenographic_support',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7342: {
    readable_description: 'Exterminating & Disinfecting',
    comp_description: 'exterminating',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7349: {
    readable_description: 'Cleaning & Maintenance',
    comp_description: 'janitorial',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7361: {
    readable_description: 'Employment Agencies & Temporary Help',
    comp_description: 'employment_agencies',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7372: {
    readable_description: 'Computer Programming & System Design ',
    comp_description: 'comp_programming',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7375: {
    readable_description: 'Information Retrieval',
    comp_description: 'info_retrieval',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7379: {
    readable_description: 'Other Computer Repair & Maintenance',
    comp_description: 'other_comp_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7392: {
    readable_description: 'Consulting, Management & Public Relations',
    comp_description: 'consulting',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7393: {
    readable_description: 'Detective & Security Services',
    comp_description: 'detective_security',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7394: {
    readable_description: 'Equipment, Furniture, & Tool Rental',
    comp_description: 'equip_rental',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7395: {
    readable_description: 'Photo Developing',
    comp_description: 'photo_developing',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7399: {
    readable_description: 'Other Business Services',
    comp_description: 'other_business',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  7512: {
    readable_description: 'Other Automobile Rental Agency',
    comp_description: 'other_auto_rental',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars',
  },
  7513: {
    readable_description: 'Truck Rental',
    comp_description: 'truck_rental',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars',
  },
  7519: {
    readable_description: 'Motor Home & Recreational Vehicle Rental',
    comp_description: 'motor_home_rental',
    comp_category: 'car_rental',
    readable_category: 'Rental Cars',
  },
  7523: {
    readable_description: 'Automobile Parking Lots & Garages',
    comp_description: 'auto_parking',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  7531: {
    readable_description: 'Automotive Body Repair Shops',
    comp_description: 'auto_repair',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  7534: {
    readable_description: 'Tire Retreading & Repair Shops',
    comp_description: 'tire_repair',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  7535: {
    readable_description: 'Automotive Paint Shops',
    comp_description: 'auto_paint',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  7538: {
    readable_description: 'Automotive Service Shops',
    comp_description: 'auto_service',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  7542: {
    readable_description: 'Car Washes',
    comp_description: 'car_washes',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  7549: {
    readable_description: 'Towing',
    comp_description: 'towing',
    comp_category: 'automotive',
    readable_category: 'Automotive',
  },
  7622: {
    readable_description: 'Electronic Repair Shops',
    comp_description: 'electronic_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7623: {
    readable_description: 'Air Conditioning & Refrigeration Repair Shops',
    comp_description: 'refrigeration_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7629: {
    readable_description: 'Small Electrical Appliance Repair Shops',
    comp_description: 'small_electronic_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7631: {
    readable_description: 'Clock, Jewelry, & Watch Repair Shops',
    comp_description: 'jewelry_watch_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7641: {
    readable_description: 'Furniture Reupholstery, Repair & Refinishing',
    comp_description: 'furniture_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7692: {
    readable_description: 'Welding Repair',
    comp_description: 'welding_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7699: {
    readable_description: 'Miscellaneous Repair Shops ',
    comp_description: 'other_repair',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  7800: {
    readable_description: 'Government Owned Lottery',
    comp_description: 'gov_lottery',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  7801: {
    readable_description: 'Government-Licensed Casinos',
    comp_description: 'gov_casino',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  7802: {
    readable_description: 'Government-Licensed Horse/Dog Racing',
    comp_description: 'gov_horse_dog_racing',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  7829: {
    readable_description: 'Motion Picture Production & Distribution',
    comp_description: 'motion_pic_distribution',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7832: {
    readable_description: 'Motion Picture Theaters',
    comp_description: 'motion_pic',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7841: {
    readable_description: 'DVD/Video Tape Rental Stores',
    comp_description: 'dvd_rental',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7911: {
    readable_description: 'Dance Halls, Schools, & Studios',
    comp_description: 'dance',
    comp_category: 'recreation',
    readable_category: 'Recreation',
  },
  7922: {
    readable_description: 'Ticket Agencies & Theatrical Producers',
    comp_description: 'ticket_agencies',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7929: {
    readable_description: 'Bands, Orchestras, & Miscellaneous Entertainers',
    comp_description: 'bands_misc_entertainer',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7932: {
    readable_description: 'Pool & Billiard Establishments',
    comp_description: 'pool_billiard',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7933: {
    readable_description: 'Bowling Alleys',
    comp_description: 'bowling',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7941: {
    readable_description: 'Commercial Sports & Sports Clubs',
    comp_description: 'com_sports',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7991: {
    readable_description: 'Tourist Attractions ',
    comp_description: 'tourist_attractions',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7992: {
    readable_description: 'Public Golf Courses',
    comp_description: 'public_golf',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7993: {
    readable_description: 'Video Amusement Game Supplies',
    comp_description: 'video_game_supplies',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7994: {
    readable_description: 'Video Game Arcades ',
    comp_description: 'arcades',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7995: {
    readable_description: 'Gambling',
    comp_description: 'gambling',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7996: {
    readable_description: 'Amusement Parks, Carnivals, & Circuses',
    comp_description: 'amusement_parks',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7997: {
    readable_description: 'Sports Memberships',
    comp_description: 'sports_memberships',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7998: {
    readable_description: 'Aquariums, Dolphinariums, Zoos, & Seaquariums',
    comp_description: 'aquariums_zoos',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  7999: {
    readable_description: 'Other Recreation',
    comp_description: 'other_recreation',
    comp_category: 'entertainment',
    readable_category: 'Entertainment',
  },
  8011: {
    readable_description: 'Other Doctors ',
    comp_description: 'other_doctors',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8021: {
    readable_description: 'Dentists & Orthodontists',
    comp_description: 'dentists_orthodontists',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8031: {
    readable_description: 'Osteopathic Physicians',
    comp_description: 'osteopathic',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8041: {
    readable_description: 'Chiropractors',
    comp_description: 'chiropractors',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8042: {
    readable_description: 'Optometrists & Ophthalmologists',
    comp_description: 'optometrists',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8043: {
    readable_description: 'Opticians, Optical Goods, & Eyeglasses',
    comp_description: 'optical_goods',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8049: {
    readable_description: 'Chiropodists, Podiatrists',
    comp_description: 'podiatrists',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8050: {
    readable_description: 'Nursing & Personal Care Facilities',
    comp_description: 'nursing_personal_care',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8062: {
    readable_description: 'Hospitals',
    comp_description: 'hospitals',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8071: {
    readable_description: 'Dental & Medical Laboratories',
    comp_description: 'dental_medical_labs',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8099: {
    readable_description: 'Other Medical or Health Services',
    comp_description: 'other_medical',
    comp_category: 'health_wellness',
    readable_category: 'Health and Wellness',
  },
  8111: {
    readable_description: 'Legal Services',
    comp_description: 'legal',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  8211: {
    readable_description: 'Elementary & Secondary Schools',
    comp_description: 'k_12_school',
    comp_category: 'education',
    readable_category: 'Education',
  },
  8220: {
    readable_description: 'Colleges, Universities, & Professional Schools ',
    comp_description: 'college',
    comp_category: 'education',
    readable_category: 'Education',
  },
  8241: {
    readable_description: 'Correspondence Schools ',
    comp_description: 'correspondence_school',
    comp_category: 'education',
    readable_category: 'Education',
  },
  8244: {
    readable_description: 'Business & Secretarial Schools',
    comp_description: 'business_school',
    comp_category: 'education',
    readable_category: 'Education',
  },
  8249: {
    readable_description: 'Trade & Vocational Schools',
    comp_description: 'vocational_school',
    comp_category: 'education',
    readable_category: 'Education',
  },
  8299: {
    readable_description: 'Other Educational Services',
    comp_description: 'other_edu',
    comp_category: 'education',
    readable_category: 'Education',
  },
  8351: {
    readable_description: 'Child Care',
    comp_description: 'child_care',
    comp_category: 'personal',
    readable_category: 'Personal',
  },
  8398: {
    readable_description: 'Charity & Social Service Organizations',
    comp_description: 'charity',
    comp_category: 'gifts_donations',
    readable_category: 'Gifts and Donations',
  },
  8641: {
    readable_description: 'Civic, Social, & Fraternal Associations',
    comp_description: 'civic_social_org',
    comp_category: 'gifts_donations',
    readable_category: 'Gifts and Donations',
  },
  8651: {
    readable_description: 'Political Organizations',
    comp_description: 'political_org',
    comp_category: 'gifts_donations',
    readable_category: 'Gifts and Donations',
  },
  8661: {
    readable_description: 'Religious Organizations',
    comp_description: 'religious_org',
    comp_category: 'gifts_donations',
    readable_category: 'Gifts and Donations',
  },
  8675: {
    readable_description: 'Automobile Associations',
    comp_description: 'auto_org',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  8699: {
    readable_description: 'Other Membership Organizations',
    comp_description: 'other_org',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  8734: {
    readable_description: 'Non-Medical Testing Laboratories',
    comp_description: 'non_med_labs',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  8911: {
    readable_description: 'Architectural, Engineering, & Surveying',
    comp_description: 'architect_engineering',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  8931: {
    readable_description: 'Accounting, Auditing, & Bookkeeping Services',
    comp_description: 'accounting',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  8999: {
    readable_description: 'Other Professional Services',
    comp_description: 'other_professional',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  9211: {
    readable_description: 'Court Costs',
    comp_description: 'court',
    comp_category: 'government',
    readable_category: 'Government',
  },
  9222: {
    readable_description: 'Fines',
    comp_description: 'fines',
    comp_category: 'government',
    readable_category: 'Government',
  },
  9223: {
    readable_description: 'Bail & Bond Payments',
    comp_description: 'bail',
    comp_category: 'government',
    readable_category: 'Government',
  },
  9311: {
    readable_description: 'Tax Payments',
    comp_description: 'tax_payments',
    comp_category: 'government',
    readable_category: 'Government',
  },
  9399: {
    readable_description: 'Other Government Services',
    comp_description: 'other_gov',
    comp_category: 'government',
    readable_category: 'Government',
  },
  9402: {
    readable_description: 'Government Postal Services',
    comp_description: 'gov_postal',
    comp_category: 'government',
    readable_category: 'Government',
  },
  9405: {
    readable_description: 'Intra-Government Purchases',
    comp_description: 'intra_gov',
    comp_category: 'government',
    readable_category: 'Government',
  },
  9700: {
    readable_description: 'Automated Referral Service',
    comp_description: 'auto_referral',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  9701: {
    readable_description: 'Visa Credential Server',
    comp_description: 'visa_credential_server',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  9702: {
    readable_description: 'GCAS Emergency Services',
    comp_description: 'gcas_emergency',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  9950: {
    readable_description: 'Intra-Company Purchases',
    comp_description: 'intra_company',
    comp_category: 'miscellaneous',
    readable_category: 'Miscellaneous',
  },
  '0742': {
    readable_description: 'Veterinary Services',
    comp_description: 'vet_services',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  '0763': {
    readable_description: 'Agricultural Cooperatives',
    comp_description: 'agri_coops',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
  '0780': {
    readable_description: 'Horticultural & Landscaping ',
    comp_description: 'landscaping',
    comp_category: 'professional_services',
    readable_category: 'Professional Services',
  },
}
