import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap"

import styled from "styled-components"

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import { ReactComponent as Close } from "assets/svg/Close.svg";

import styles from "styles/styles"

type TagsCountProps = {
    card: any,
}

const TagsCount = (props: TagsCountProps) => {
    const dispatch = useDispatch()

    // Redux state
    const banking = useSelector((state: any) => state.banking)

    // Component state
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
        ev.preventDefault();
        ev.stopPropagation();
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const deleteTag = (tagToDelete: string) => {
        const updatedPaymentCard = {
            ...card,
            cardAccountUuid: banking.account.uuid,
            tags: card.tags.filter((tag: string) => tag !== tagToDelete),
        }
        dispatch(PaymentCardActions.updatePaymentCard(updatedPaymentCard))
    }

    const { card } = props;

    return <>
        {card.tags.length > 4 && <Container onClick={handleClick}>
            +{card.tags.length - 4} more tags
        </Container>}
        <Modal centered show={isModalOpen} onClick={(ev: any) => { ev.stopPropagation();}}>
            <Content>
                <div className='w-100 d-flex justify-content-between align-items-center'>
                    <ModalTitle>Tags: {card?.nickname} (...{card?.lastFour})</ModalTitle>
                    <CloseButton onClick={closeModal}>
                        <StyledClose/>
                    </CloseButton>
                </div>
                <ModalBody>
                    <div className='w-100'>Applied tags:</div>
                    <ScrollableCardList>
                        {card?.tags.map((tag: string) => <TagContainer key={tag}>
                            {tag}
                            <TagRowClose onClick={(ev: any) => { ev.stopPropagation(); deleteTag(tag)}} />
                        </TagContainer>)}
                    </ScrollableCardList>
                </ModalBody>
            </Content>
        </Modal>
    </>
}

const TagRowClose = styled(Close)`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    cursor: pointer;
`

const StyledClose = styled(Close)`
    height: 24px;
    width: 24px;
`

const TagContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${styles.Font.Size.Small};
    padding: ${styles.Spacing.XS};
    height: ${styles.Spacing.M};
    &:not(&:last-child) {
        border-bottom: 1px solid black;
    }
`

const ScrollableCardList = styled.div`
    margin: ${styles.Spacing.XS} 0;
    max-height: 180px;
    width: 100%;
    overflow-y: auto;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Content = styled.div`
    width: 100%;
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
`

const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
    cursor: pointer;
    margin: ${styles.Spacing.XS};
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    margin-bottom: ${styles.Spacing.S};
`

const ModalTitle = styled.div`
    font-size: 20px;
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Container = styled.div`
    font-size: 12px;
    height: 25px;
    margin-right: ${styles.Spacing.XS};
    display: flex;
    align-items: center;
    color: grey;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

export default TagsCount