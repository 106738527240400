import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { noop } from 'lodash';
import moment from 'moment';

import styled from 'styled-components'

import { debounce } from 'utils/debounce'
import { stopPropagation } from 'utils/utils'

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import Checkbox from 'components/common/Checkbox';

import CardStatusDropdown from 'components/pages/Cards/CardStatusDropdown'
import { messages as cardTypeMessages } from 'components/pages/Cards/constants'
import Tag from 'components/pages/Cards/Tag';
import TagsCount from 'components/pages/Cards/TagsCount';
import { setPreviewCard } from 'components/pages/Cards/cardsSlice'

import { ReactComponent as ArrowRightSVG } from 'assets/svg/ArrowRight.svg';
import { ReactComponent as ShowCardIcon } from 'assets/svg/RemoveRedEye.svg';

import { Locale, USDCurrencyOptions } from 'utils/constants'

import styles from 'styles/styles'
import MiniCard from '../MiniCard';

type CardListItemProps = {
    card: any,
    selectCard: (uuid: string) => void,
    setIsMockCardModalOpen: (arg0: boolean) => void,
    selected: boolean,
    // required for react-virtualized
    style: any
}

const CardListItem = (props: CardListItemProps) => {
    const history = useHistory()
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const mainUser = currentUser.parentUser ? currentUser.parentUser : currentUser
    const userHasMultipleAddresses = mainUser.otherAddresses.length > 0

    // Redux state
    const dispatch = useDispatch();
    const banking = useSelector((state: any) => state.banking)
    const cardAccountUuid = useSelector((state: any) => state.banking.account.uuid)

    const [cardName, setCardName] = useState(props.card.nickname)

    const { card } = props;

    const isPhysicalCard = card.formFactor === 'PHYSICAL'

    const handleShowMockCard = (ev: React.MouseEvent<HTMLElement>) => {
        props.setIsMockCardModalOpen(true)
        dispatch(PaymentCardActions.fetchPaymentCardToken({ 
            cardAccountUuid,
            uuid: card.uuid
        }));
        dispatch(setPreviewCard(props.card))
        ev.preventDefault();
        ev.stopPropagation();
    }

    const inspectCard = () => {
        history.push(`cards/${cardAccountUuid}/${card.uuid}`)
    }

    const renameCard = (newName: string) => {
        const updatedPaymentCard = {
            ...card,
            nickname: newName,
            cardAccountUuid: cardAccountUuid,
            tags: Array.from(card.tags)
        }

        dispatch(PaymentCardActions.updatePaymentCard(updatedPaymentCard))
    }

    const updateCardStatus = (selectedOption: any) => {
        dispatch(PaymentCardActions.updatePaymentCard({
            ...props.card,
            status: selectedOption.value,
            oldCardStatus: props.card.status,
            cardAccountUuid: banking.account.uuid,
            tags: Array.from(props.card.tags)
        }))
    }

    const debouncedRenameCard = useCallback(debounce(renameCard, 2000), []) // eslint-disable-line
    
    const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setCardName(ev.target.value)
        debouncedRenameCard(ev.target.value)
    }

    const handleSelectCard = () => {
        props.selectCard(card.uuid)
    }

    // Tags are empty when only a single item "[]" appears
    const isTagsEmpty = card.tags.length === 0

    return <Container style={props.style}>
        <ClickableArea isSelected={props.selected} id={props.card.uuid} onClick={handleSelectCard} onDoubleClick={inspectCard}>
            <div className='d-flex'>
                <CardNameContainer width={userHasMultipleAddresses ? `${100 * 2 / 7}%` : `${100 / 3}%`}>
                    <NameRow>
                        <CheckboxContainer>
                            <Checkbox onChange={noop} checked={props.selected}/>
                        </CheckboxContainer>
                        <StyledMiniCard card={card}/>
                        <CenteredColumn>
                            <CardTitle
                                style={{width: `${cardName.length * 0.9}ch`}}
                                onDoubleClick={stopPropagation}
                                onClick={stopPropagation}
                                onChange={handleNameChange}
                                value={isPhysicalCard ? 'Physical Card' : cardName}
                                disabled={isPhysicalCard}
                            />
                            {<CenteredRow className={(isPhysicalCard || undefined) && 'invisible'}>
                                <CardNumber>{`No. ${card.lastFour}`}</CardNumber>
                                <ShowCardIconContainer onClick={card.formFactor !== 'PHYSICAL' ? handleShowMockCard : undefined}>
                                    <ShowCardIcon>Show</ShowCardIcon>
                                </ShowCardIconContainer>
                            </CenteredRow>}
                        </CenteredColumn>
                    </NameRow>
                </CardNameContainer>
                {userHasMultipleAddresses && <CardDetailContainer width={`${100 / 7}%`}>
                    <CardDetail>{card.postalCode}</CardDetail>
                </CardDetailContainer>}
                <CardDetailContainer width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>
                    <CardDetail>{(cardTypeMessages.CardTypeLabel as any)[card.cardType]}</CardDetail>
                </CardDetailContainer>
                <CardDetailContainer width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>
                    <StatusWrapper onClick={stopPropagation} onDoubleClick={stopPropagation}>
                        <CustomStatusIndicator status={card.status} onChange={updateCardStatus} />
                    </StatusWrapper>
                </CardDetailContainer>
                <CardDetailContainer width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>
                    <CardDetail>{card.sevenDaySpend?.toLocaleString(Locale.English, USDCurrencyOptions)}</CardDetail>
                </CardDetailContainer>
                <DateCreatedContainer width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>
                    <CardDetail>{moment(card.created).format('MM/DD/YY')}</CardDetail>
                    <ArrowRightIconContainer onClick={inspectCard}>
                        <StyledArrowRight fill={styles.Color.TaekusPurple} />
                    </ArrowRightIconContainer>
                </DateCreatedContainer>
            </div>
            <TagsContainer>
                <Tag selectKey={card.tags.length.toString()} card={card} key={card.tags.length} />
                {!isTagsEmpty && card.tags.slice(0,4).map((tag: any, index: number) => <Tag key={`tag:${index}`} card={card} label={tag} />)}
                <TagsCount card={card}/>
            </TagsContainer>
        </ClickableArea>
    </Container>
}

const StyledArrowRight = styled(ArrowRightSVG)`
    position: absolute;
    top: 4px;
`

const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    height: max-content;
`
const CustomStatusIndicator = styled(CardStatusDropdown)`
    width: 11px;
    height: 11px;
    margin-right: 8px;
`

const ArrowRightIconContainer = styled.div`
    position: relative;
    margin-top: 6px;
    margin-right: 15px;
    width: 20px;
    cursor: pointer;
`

const StyledMiniCard = styled(MiniCard)`
    min-width: ${styles.Spacing.L};
    height: 38px;
    margin-right: 14px;
`

const ShowCardIconContainer = styled.div`
    min-height: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    min-width: ${styles.Spacing.S};
    width: ${styles.Spacing.S};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${styles.Spacing.XXXS};
    border-radius: ${styles.BorderRadius.Half};
    // border: 1px solid ${styles.Color.Black};
    fill: black;
    ${styles.Animation.transitionStyles}
    &:hover {
        fill: white;
        background-color: ${styles.Color.TaekusPurple};
    }
`

type WidthProps = {
    width: string
}

const CardDetailContainer = styled.div<WidthProps>`
    margin: ${styles.Spacing.S} 0;
    width: ${props => props.width};
`

const CardNameContainer = styled.div<WidthProps>`
    display: flex;
    padding-top: ${styles.Spacing.S};
    flex-direction: column;
    width: ${props => props.width};
`

const DateCreatedContainer = styled.div<WidthProps>`
    width: ${props => props.width};
    display: flex;
    justify-content: space-between;
    margin: ${styles.Spacing.S} 0;
`

const NameRow = styled.div`
    display: flex;
    align-items: center; 
    margin-bottom: ${styles.Spacing.S};
    overflow: hidden;
`

const CenteredRow = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2px;
`

const CenteredColumn = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-right: ${styles.Spacing.XS};
    flex-direction: column;
    margin-right: ${styles.Spacing.S};
`

const TagsContainer = styled.div`
    display: flex;
    margin-left: 58px;
`

const CardTitle = styled.input`
    outline: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 138%;
    /* identical to box height, or 25px */
    letter-spacing: 0.02em;
    color: ${styles.Color.Black};
    text-overflow: ellipsis;
    min-width: 100px;
    max-width: 100%;
    overflow: hidden; 
    white-space: nowrap;
    background: transparent;
    :disabled {
        border-bottom: 0;
        cursor: pointer;
    }
`

const CardNumber = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */
    letter-spacing: 0.02em;
    color: ${styles.Color.Black};
    white-space: nowrap;
`

const CardDetail = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
    color: ${styles.Color.Black};
    max-height: 100%;
    padding-top: 6px;
    padding-right: ${styles.Spacing.XS};
    overflow: hidden;
    text-overflow: ellipsis;
`

type ClickableAreaProps = {
    isSelected: boolean
}

const ClickableArea = styled.div<ClickableAreaProps>`
    width: 100%;
    height: 130px;
    border: 1px solid ${props => props.isSelected ? styles.Color.TaekusPurple : styles.Color.Grey};
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
    ${styles.Animation.transitionStyles}
    &:not(&:last-child) { 
        margin-bottom: ${styles.Spacing.XS};
    }
    &:hover {
        color: inherit;
        text-decoration: inherit;
        border: 1px solid ${styles.Color.TaekusPurple};
    }
`

const Container = styled.div`
    width: 100%;
    height: 140px;
    ${styles.Animation.transitionStyles}
    &:not(&:last-child) { 
        padding-bottom: ${styles.Spacing.XS};
    }
`

const CheckboxContainer = styled.div`
    margin: 0 ${styles.Spacing.S};
`

export default CardListItem