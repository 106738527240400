import React, { useEffect, useRef, useState } from "react"
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {parseResponse, refreshTokenGetWebsocketEndpoint, wsRequest} from "components/common/Websocket/WsUtilities";
import { WsJobStatus, WsMessage } from 'components/common/Websocket/WsMessageConstants'
import {v4 as uuidv4} from "uuid";

type FinalAvailabilityWsProps = {
	setIsLoading: (arg: boolean) => void;
	setResponse: (arg0: any) => void;
	websocketRequestViaCounter: number,
	params: any
	requestIdToParams: React.MutableRefObject<any>;
}

const FinalAvailabilityWs = (props: FinalAvailabilityWsProps) => {
	const heartbeatIntervalRef = useRef<number | null>(null);
  	const previousHeartbeats: Record<string, number> = {};

	const socketUrl = refreshTokenGetWebsocketEndpoint("flights/award/live/");
	const [resendMessage, setResendMessage] = useState<boolean>(false);
  	const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    	shouldReconnect: (closeEvent) => {
			setResendMessage(true)
			return true
		},
		onReconnectStop: (numAttempts: number) => {
			props.setIsLoading(false)
		},
      	reconnectAttempts: 3,
      	reconnectInterval: 3000,
    });

	useEffect(()=> {
		if (props.websocketRequestViaCounter > 0) {
			const requestId = uuidv4()
			wsRequest(sendMessage, WsMessage.Booking.FinalAvailability, props.params, requestId)
			props.setIsLoading(true)
			props.requestIdToParams.current = {}
			props.requestIdToParams.current[requestId] = props.params
		}
	}, [props.websocketRequestViaCounter]) // eslint-disable-line

    useEffect(() => {
		// Callback useEffect that is called whenever the server responds with a message over WS
        if (lastMessage !== null) {
			const extractedJob = parseResponse(lastMessage)
			if (extractedJob.jobType === WsMessage.Booking.FinalAvailability) {
				if (extractedJob.requestId in props.requestIdToParams.current) {
					delete props.requestIdToParams.current[extractedJob.requestId]
					props.setIsLoading(false)
					if (extractedJob.status === WsJobStatus.Complete) {
						props.setResponse({"exists": extractedJob.data})
					} else {
						props.setResponse({} as any)
					}
				}
			}
        }
    }, [lastMessage]); // eslint-disable-line

	useEffect(() => {
		if (resendMessage) {
			// Resend with the same request_id. First one that returns is the one we want.
			Object.entries(props.requestIdToParams.current).forEach(([requestId, params]) => {
				wsRequest(sendMessage, WsMessage.Booking.FinalAvailability, params, requestId)
			})
			setResendMessage(false)
		}

	}, [resendMessage]) // eslint-disable-line

	useEffect(() => {
		// from https://github.com/robtaussig/react-use-websocket/issues/133 as we are not on react18 + react-use-websocke 4.0
		if (readyState === ReadyState.OPEN) {
			heartbeatIntervalRef.current = window.setInterval(() => {
				if (socketUrl) {
					const lastHeartbeat = previousHeartbeats[socketUrl];
					const deltaFromNow = (Date.now() - lastHeartbeat) / 1000;
					// Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
					if (!lastHeartbeat || deltaFromNow > 10) {
						// Send the heartbeat message and update the heartbeat history.
						wsRequest(sendMessage, WsMessage.Common.Heartbeat, {});
						previousHeartbeats[socketUrl] = Date.now();
					}
				}
			}, 5000); // check every 5 seconds
			return () => {
				if (heartbeatIntervalRef.current) {
					clearInterval(heartbeatIntervalRef.current);
				}
			};
		}
	}, [socketUrl, readyState, sendMessage]) // eslint-disable-line

    return <></>
}

export default FinalAvailabilityWs
