import { useRef, useEffect } from 'react';

export const useIsFirstRender = () => {

  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);
  
  return isFirstRenderRef.current;
};