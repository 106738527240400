import React from "react"
import { CardAccountType } from "./types";
import styled from "styled-components";
import { getCardBackgroundURL } from "components/pages/Cards/utils";
import styles from "styles/styles";

type MiniCardProps = {
    card: any;
    className?: string;
}

const MiniCard = (props: MiniCardProps) => {
    const isPhysicalCard = props.card.formFactor === 'PHYSICAL'

    const getCardColor = () => {
        switch (props.card.binType) {
            case CardAccountType.PREPAID_BUSINESS:
            case CardAccountType.PREPAID_CONSUMER:
            default:
                return styles.Color.TaekusBlue;
        }
    }

    return <CardBackground
      cardType={props.card.binType}
      isPhysical={isPhysicalCard}
      className={props.className}
      color={getCardColor()}
    >
        <Bar isPhysical={isPhysicalCard}/>
    </CardBackground>
}

type CardProps = {
    color: string;
    cardType: CardAccountType;
    isPhysical?: boolean;
}

const CardBackground = styled.div<CardProps>`
    aspect-ratio: 60 / 38;
    background-color: ${props => props.color};
    ${props => props.isPhysical && `
        background-image: url(${getCardBackgroundURL(props.cardType)});
        background-size: cover;
    `}
    border-radius: ${styles.BorderRadius.M};
    display: flex;
    align-items: end;
    justify-content: center;
    border: 1px solid ${props => props.isPhysical ? 'grey' : 'transparent'}
`

type BarProps = {
    isPhysical: boolean;
}

const Bar = styled.div<BarProps>`
    border-radius: ${styles.BorderRadius.S};
    width: 90%;
    height: 8px;
    background: ${props => props.isPhysical ? 'transparent' : 'white'};
    opacity: 0.1;
    margin-bottom: 3px;
`

export default MiniCard