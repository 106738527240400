import React from "react"
import styled, { keyframes } from "styled-components"
import styles from "styles/styles"

type SkeletonProps = {
    width: string,
    height?: string,
}

const Skeleton = (props: SkeletonProps) => {
    const { width, height } = props;

    const animation = `
        0% { background-position: 0 0; }
        100% { background-position: ${width} 0; }
    `;

    return <Container animation={animation} width={width} height={height}/>
}

type ContainerProps = SkeletonProps & {
    animation: any,
}

const Container = styled.div<ContainerProps>`
    background-color: #999999;
    animation : ${props => keyframes`${props.animation}`} 2s infinite;
    background: linear-gradient(to right, #d0d0d0 2%, ${styles.Color.Grey} 98%, #d0d0d0 100%);
    background-size: 100% 100%;
    width: ${props => props.width};
    height: ${props => props.height ? `${props.height}` : `100%`};
`

export default Skeleton