import API from 'services/api'
import get from 'lodash/get'

const featureName = 'Statements'

const ACTION_FETCH_STATEMENTS_PARTIAL_START = `${featureName}/FETCH_STATEMENTS_PARTIAL_START`
const ACTION_FETCH_STATEMENTS_FULL_START = `${featureName}/FETCH_STATEMENTS_FULL_START`
const ACTION_FETCH_STATEMENTS_COMPLETE = `${featureName}/FETCH_STATEMENTS_COMPLETE`
const ACTION_FETCH_STATEMENTS_ERROR = `${featureName}/FETCH_STATEMENTS_ERROR`

const ACTION_DOWNLOAD_STATEMENT_START = `${featureName}/DOWNLOAD_START`
const ACTION_DOWNLOAD_STATEMENT_COMPLETE = `${featureName}/DOWNLOAD_COMPLETE`
const ACTION_DOWNLOAD_STATEMENT_ERROR = `${featureName}/DOWNLOAD_ERROR`
const ACTION_CLEAR_DOWNLOAD_STATEMENT = `${featureName}/CLEAR_DOWNLOAD`

const ACTION_GET_STATEMENT_LINK_START = `${featureName}/GET_STATEMENT_LINK_START`
const ACTION_GET_STATEMENT_LINK_COMPLETE = `${featureName}/GET_STATEMENT_LINK_COMPLETE`
const ACTION_GET_STATEMENT_LINK_ERROR = `${featureName}/GET_STATEMENT_LINK_ERROR`
const ACTION_CLEAR_GET_STATEMENT_LINK_STATEMENT = `${featureName}/CLEAR_GET_STATEMENT_LINK`

export const Actions = {
  fetchStatements:
    (statementParams = {}, partial = true) =>
    (dispatch) => {
      if (partial) {
        dispatch({ type: ACTION_FETCH_STATEMENTS_PARTIAL_START })
      } else {
        dispatch({ type: ACTION_FETCH_STATEMENTS_FULL_START })
      }

      return API.banking.statements
        .fetch(statementParams)
        .then((response) => {
          dispatch({
            type: ACTION_FETCH_STATEMENTS_COMPLETE,
            payload: response.data,
          })
        })
        .catch((e) => {
          dispatch({
            type: ACTION_FETCH_STATEMENTS_ERROR,
            payload: e,
          })
        })
    },
  getStatementLink: (statementParams) => (dispatch) => {
    dispatch({
      type: ACTION_GET_STATEMENT_LINK_START,
      payload: statementParams,
    })
    return API.banking.statements
      .getPdfUrl(statementParams)
      .then((response) => {
        dispatch({
          type: ACTION_GET_STATEMENT_LINK_COMPLETE,
          payload: response, // we pass in response because we want access the headers in the dispatch
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_STATEMENT_LINK_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  clearStatementUrl:
    (statementParams = {}) =>
    (dispatch) => {
      dispatch({ type: ACTION_CLEAR_GET_STATEMENT_LINK_STATEMENT })
    },
  downloadStatement: (statementParams) => (dispatch) => {
    dispatch({
      type: ACTION_DOWNLOAD_STATEMENT_START,
      payload: statementParams,
    })
    return API.banking.statements
      .download(statementParams)
      .then((response) => {
        dispatch({
          type: ACTION_DOWNLOAD_STATEMENT_COMPLETE,
          payload: response, // we pass in response because we want access the headers in the dispatch
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_DOWNLOAD_STATEMENT_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  clearDownloadStatement:
    (statementParams = {}) =>
    (dispatch) => {
      dispatch({ type: ACTION_CLEAR_DOWNLOAD_STATEMENT })
    },
}

const defaultState = {
  statements: [],
  statementLink: '',
  statementGeneration: {
    data: [],
    filename: '',
    error: false,
    loading: false,
    success: false,
  },
  downloadLoading: false,
  isLoading: false,
  error: false,
  errors: [],
}

export const StatementsReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_FETCH_STATEMENTS_PARTIAL_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_FETCH_STATEMENTS_FULL_START:
      return {
        ...state,
        statements: [],
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_FETCH_STATEMENTS_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }

    case ACTION_CLEAR_DOWNLOAD_STATEMENT:
      return {
        ...state,
        statementGeneration: {
          data: [],
          filename: '',
          error: false,
          loading: false,
          success: false,
        },
        downloadLoading: false,
        error: false,
      }

    case ACTION_GET_STATEMENT_LINK_START:
    case ACTION_GET_STATEMENT_LINK_ERROR:
    case ACTION_CLEAR_GET_STATEMENT_LINK_STATEMENT:
      return {
        ...state,
        statementLink: '',
      }

    case ACTION_GET_STATEMENT_LINK_COMPLETE:
      return {
        ...state,
        statementLink: action.payload.data.url,
      }

    case ACTION_DOWNLOAD_STATEMENT_START:
      return {
        ...state,
        statementGeneration: {
          data: [],
          filename: '',
          error: false,
          loading: true,
          success: false,
        },
        downloadLoading: true,
        error: false,
      }

    case ACTION_DOWNLOAD_STATEMENT_COMPLETE:
      // naive approach for grabbing filename buts thats ok for our application
      let filename = get(action, 'payload.headers.content-disposition', '')
      filename = filename.split('filename=')[1].split(';')[0]

      return {
        ...state,
        statementGeneration: {
          data: action.payload.data,
          filename: filename,
          error: false,
          loading: false,
          success: true,
        },
        downloadLoading: false,
        error: false,
      }

    case ACTION_FETCH_STATEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        downloadLoading: false,
        error: true,
        errors: {
          ...state,
          ...action.payload,
        },
      }
    case ACTION_DOWNLOAD_STATEMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        downloadLoading: false,
        error: false,
        statementGeneration: {
          data: [],
          filename: '',
          error: true,
          loading: false,
          success: false,
        },
        errors: {
          ...state,
          ...action.payload,
        },
      }

    default:
      return state
  }
}
