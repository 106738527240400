import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api';

import { categoryMccCodes } from 'utils/categoryMccCodes';

import { TransactionFilterOptions } from 'components/common/Transactions/constants';

import { Transaction } from 'components/pages/Home/constants';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    transactions: [],
    isLoadingTransactions: true,
    defaultLoadedTransactionsAccountUuid: undefined,
    transactionFetchController: null,
    isMoreTransactions: undefined,
  },
  reducers: {
    transactionsLoading: (state, action) => {
      if (state.transactionFetchController) {
        (state.transactionFetchController as any).abort();
      }
      state.isLoadingTransactions = true;
      state.transactionFetchController = action.payload
    },
    transactionsRecieved: (state, action) => {
      state.transactions = action.payload.transactions;
      state.isMoreTransactions = action.payload.isMore;
      state.isLoadingTransactions = false;
      state.defaultLoadedTransactionsAccountUuid = action.payload.defaultLoadedTransactionsAccountUuid;
      state.transactionFetchController = null;
    }    
  }
})
  
  export const { transactionsLoading, transactionsRecieved } = homeSlice.actions
  
  export const fetchTransactions = (
    uuid: string,
    startIndex: number = 0,
    filterOptions: TransactionFilterOptions,
    isDefaultFetch: boolean = false
  ) => (dispatch: any) => {
    const controller = new AbortController();
    dispatch(transactionsLoading(controller));

    const transactionParams = {
      cardAccountUuid: uuid,
      startIndex,
      startDate: filterOptions.startDate?.toDate(),
      endDate: filterOptions.endDate?.toDate(),
      filterType: filterOptions.type,
      searchString: filterOptions.searchString,
      minimumAmount: filterOptions.minimumAmount,
      maximumAmount: filterOptions.maximumAmount,
      mccs: filterOptions.categories.map((category: string) => (categoryMccCodes as any)[category]).flat(),
      cardUuids: filterOptions.cardUuids,
    }
    
    return API.banking.fetchTransactions(transactionParams, controller)
      .then((response) => {
        dispatch(transactionsRecieved({
          transactions: (response.data as any).transactions,
          isMore: (response.data as any).isMore,
          didTransactionFailParsing: (response.data as any).didTransactionFailParsing,
          defaultLoadedTransactionsAccountUuid: isDefaultFetch ? transactionParams.cardAccountUuid : undefined,
        }));
      })
      .catch((e) => {
        // handle error
      })
  }

  export const updateTransactionMemo = (uuid: string, transaction: Transaction) => async (dispatch: any) => {
    API.banking.updateTransaction({cardAccountUuid: uuid, transaction})
    .then((response) => {
      // todo: add visual feedback to memo saving
    })
    .catch((e) => {
      // todo: error handling
    })
  }

  export default homeSlice.reducer