import API from 'services/api'
import _ from 'lodash'
import values from 'lodash/values'
import merge from 'lodash/merge'
import keyBy from 'lodash/keyBy'
import find from 'lodash/find'
import get from 'lodash/get'
import { updateToast } from '../global/globalSlice'
import { Actions as BankingActions } from './banking'

const featureName = 'PaymentCards'

const ACTION_GET_PAYMENT_CARDS_PARTIAL_START = `${featureName}/GET_PAYMENT_CARDS_PARTIAL_START`
const ACTION_GET_PAYMENT_CARDS_FULL_START = `${featureName}/GET_PAYMENT_CARDS_FULL_START`
const ACTION_CLEAR_PAYMENT_CARDS = `${featureName}/CLEAR_PAYMENT_CARDS`
const ACTION_GET_PAYMENT_CARDS_COMPLETE = `${featureName}/GET_PAYMENT_CARDS_COMPLETE`
const ACTION_GET_PAYMENT_CARDS_ERROR = `${featureName}/GET_PAYMENT_CARDS_ERROR`

const ACTION_GET_PAYMENT_CARD_TOKEN_START = `${featureName}/GET_PAYMENT_CARD_TOKEN_START`
const ACTION_CLEAR_PAYMENT_CARD_TOKEN = `${featureName}/CLEAR_PAYMENT_CARD_TOKEN`
const ACTION_GET_PAYMENT_CARD_TOKEN_ERROR = `${featureName}/GET_PAYMENT_CARD_TOKEN_ERROR`
const ACTION_GET_PAYMENT_CARD_TOKEN_COMPLETE = `${featureName}/GET_PAYMENT_CARD_TOKEN_COMPLETE`

const ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_START = `${featureName}/ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_START`
const ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE = `${featureName}/ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE`
const ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR = `${featureName}/ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR`
const ACTION_CLEAR_PAYMENT_CARD_DETAILS_REPORT = `${featureName}/ACTION_CLEAR_PAYMENT_CARD_DETAILS_REPORT`

const ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_START = `${featureName}/ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_START`
const ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE = `${featureName}/ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE`
const ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR = `${featureName}/ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR`

const ACTION_GET_PAYMENT_CARD_FULL_START = `${featureName}/GET_PAYMENT_CARD_FULL_START`
const ACTION_GET_PAYMENT_CARD_PARTIAL_START = `${featureName}/GET_PAYMENT_CARD_PARTIAL_START`
const ACTION_GET_PAYMENT_CARD_COMPLETE = `${featureName}/GET_PAYMENT_CARD_COMPLETE`
const ACTION_GET_PAYMENT_CARD_ERROR = `${featureName}/GET_PAYMENT_CARD_ERROR`

const ACTION_GET_PAYMENT_CARD_TRANSACTIONS_START = `${featureName}/GET_PAYMENT_CARD_TRANSACTIONS_START`
const ACTION_GET_PAYMENT_CARD_TRANSACTIONS_COMPLETE = `${featureName}/GET_PAYMENT_CARD_TRANSACTIONS_COMPLETE`
const ACTION_GET_PAYMENT_CARD_TRANSACTIONS_ERROR = `${featureName}/GET_PAYMENT_CARD_TRANSACTIONS_ERROR`

const ACTION_UPDATE_ACTIVATE_PAYMENT_CARD = `${featureName}/UPDATE_ACTIVATE_PAYMENT_CARD`
const ACTION_CLEAR_ACTIVATE_PAYMENT_CARD = `${featureName}/CLEAR_ACTIVATE_PAYMENT_CARD`

const ACTION_CREATE_PAYMENT_CARD_START = `${featureName}/CREATE_CARD_START`
const ACTION_CLEAR_CREATE_PAYMENT_CARD = `${featureName}/CLEAR_CREATE_CARD`
const ACTION_CREATE_PAYMENT_CARD_COMPLETE = `${featureName}/CREATE_CARD_COMPLETE`
const ACTION_CREATE_PAYMENT_CARD_ERROR = `${featureName}/CREATE_CARD_ERROR`

const ACTION_ORDER_PHYSICAL_PAYMENT_CARD_START = `${featureName}/ORDER_PHYSICAL_CARD_START`
// const ACTION_CLEAR_ORDER_PHYSICAL_PAYMENT_CARD = `${featureName}/CLEAR_CREATE_PHYSICAL_CARD`
const ACTION_ORDER_PHYSICAL_PAYMENT_CARD_COMPLETE = `${featureName}/CREATE_PHYSICAL_CARD_COMPLETE`
const ACTION_ORDER_PHYSICAL_PAYMENT_CARD_ERROR = `${featureName}/CREATE_PHYSICAL_CARD_ERROR`

const ACTION_BATCH_UPDATE_PAYMENT_CARDS_START = `${featureName}/BATCH_UPDATE_CARDS_START`
const ACTION_BATCH_UPDATE_PAYMENT_CARDS_COMPLETE = `${featureName}/BATCH_UPDATE_CARDS_COMPLETE`
const ACTION_BATCH_UPDATE_PAYMENT_CARDS_ERROR = `${featureName}/BATCH_UPDATE_CARDS_ERROR`

const ACTION_UPDATE_PAYMENT_CARD_START = `${featureName}/UPDATE_CARD_START`
const ACTION_UPDATE_PAYMENT_CARD_COMPLETE = `${featureName}/UPDATE_CARD_COMPLETE`
const ACTION_UPDATE_PAYMENT_CARD_ERROR = `${featureName}/UPDATE_CARD_ERROR`

const ACTION_ACTIVATE_PAYMENT_CARD_START = `${featureName}/ACTIVATE_CARD_START`
const ACTION_ACTIVATE_PAYMENT_CARD_COMPLETE = `${featureName}/ACTIVATE_CARD_COMPLETE`
const ACTION_ACTIVATE_PAYMENT_CARD_ERROR = `${featureName}/ACTIVATE_CARD_ERROR`

const ACTION_SET_PAYMENT_CARD_PIN_START = `${featureName}/SET_CARD_PIN_START`
const ACTION_SET_PAYMENT_CARD_PIN_COMPLETE = `${featureName}/SET_CARD_PIN_COMPLETE`
const ACTION_SET_PAYMENT_CARD_PIN_ERROR = `${featureName}/SET_CARD_PIN_ERROR`
const ACTION_CLEAR_SET_CARD_PIN = `${featureName}/CLEAR_SET_CARD_PIN`

export const Actions = {
  fetchPaymentCards:
    (params, partial = true) =>
    (dispatch) => {
      const controller = new AbortController()

      if (partial) {
        dispatch({ 
          type: ACTION_GET_PAYMENT_CARDS_PARTIAL_START,
          payload:  {
            controller,
          }
        })
      } else {
        dispatch({
          type: ACTION_GET_PAYMENT_CARDS_FULL_START,
          payload:  {
            controller,
            cardAccountUuid: params.cardAccountUuid,
          }
        })
      }

      return API.banking.paymentCards
        .get(params, controller)
        .then((response) => {
          dispatch({
            type: ACTION_GET_PAYMENT_CARDS_COMPLETE,
            payload: response.data,
          })
        })
        .catch((e) => {
          dispatch({
            type: ACTION_GET_PAYMENT_CARDS_ERROR,
            payload: e,
          })
        })
    },
  clearPaymentCards: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_PAYMENT_CARDS })
  },
  fetchPaymentCardToken: (params) => (dispatch) => {
    // we always clear the existing payment card details, if they are set, so we are storing at most one card at a time
    dispatch({ type: ACTION_GET_PAYMENT_CARD_TOKEN_START })

    return API.banking.paymentCards
      .getPaymentCardToken(params)
      .then((response) => {
        dispatch({
          type: ACTION_GET_PAYMENT_CARD_TOKEN_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_PAYMENT_CARD_TOKEN_ERROR,
          payload: e,
        })
      })
  },
  clearPaymentCardToken: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_PAYMENT_CARD_TOKEN })
  },
  downloadCardDetailsReport: (reportParams) => (dispatch) => {
    //clear first
    dispatch({
      type: ACTION_CLEAR_PAYMENT_CARD_DETAILS_REPORT,
    })
    dispatch({
      type: ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_START,
    })
    return API.banking.paymentCards
      .downloadPaymentCardDetailsReport(reportParams)
      .then((response) => {
        dispatch({
          type: ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE,
          payload: response, // we pass in response because we want access the headers in the dispatch
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  uploadCardDetailsReport: (reportParams) => (dispatch) => {
    //clear first
    dispatch({
      type: ACTION_CLEAR_PAYMENT_CARD_DETAILS_REPORT,
    })
    dispatch({
      type: ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_START,
    })
    return API.banking.paymentCards
      .uploadPaymentCardDetailsReport(reportParams)
      .then((response) => {
        dispatch({
          type: ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  clearCardDetailsReport: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_PAYMENT_CARD_DETAILS_REPORT })
  },
  fetchPaymentCardTransactions: (params) => (dispatch) => {
    dispatch({ type: ACTION_GET_PAYMENT_CARD_TRANSACTIONS_START })

    return API.banking.paymentCards
      .getTransactions(params)
      .then((response) => {
        dispatch({
          type: ACTION_GET_PAYMENT_CARD_TRANSACTIONS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_PAYMENT_CARD_TRANSACTIONS_ERROR,
          payload: e,
        })
      })
  },
  createVirtualCard: (params) => (dispatch) => {
    dispatch({ type: ACTION_CREATE_PAYMENT_CARD_START })

    return API.banking.paymentCards
      .create(params)
      .then((response) => {
        dispatch({
          type: ACTION_CREATE_PAYMENT_CARD_COMPLETE,
          payload: response.data,
        })
        dispatch(
          updateToast({
            message: 'Created card successfully.',
            isError: false,
            isOpen: true,
          })
        )
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_PAYMENT_CARD_ERROR,
          payload: e,
        })
      })
  },
  clearCreateVirtualCard: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_CREATE_PAYMENT_CARD })
  },
  orderPhysicalCard: (params) => (dispatch) => {
    dispatch({ type: ACTION_ORDER_PHYSICAL_PAYMENT_CARD_START })

    return API.banking.paymentCards
      .createPhysical(params)
      .then((response) => {
        dispatch({
          type: ACTION_ORDER_PHYSICAL_PAYMENT_CARD_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_ORDER_PHYSICAL_PAYMENT_CARD_ERROR,
          payload: e,
        })
      })
  },
  batchUpdatePaymentCards: (params) => (dispatch) => {
    dispatch({
      type: ACTION_BATCH_UPDATE_PAYMENT_CARDS_START,
      payload: params,
    })

    return API.banking.paymentCards
      .batchUpdate(params)
      .then((response) => {
        dispatch({
          type: ACTION_BATCH_UPDATE_PAYMENT_CARDS_COMPLETE,
          payload: params,
        })
      })
      .catch((e) => {
        // sleep here to make it seem like something happened, otherwise its nearly instant and looks weird
        setTimeout(() => {
          dispatch({
            type: ACTION_BATCH_UPDATE_PAYMENT_CARDS_ERROR,
            payload: e,
          })
        }, 1000)
      })
  },
  updatePaymentCard: (params) => (dispatch) => {
    // Here we preemptively update the frontend object on the assumption
    // that the backend server call will succeed, in order to provide the illusion
    // of faster/better performance.

    /*
     *  Cast tags to string before using API call
     */
    const tags = params.tags
    params.tags = params.tags.length > 0 ? params.tags.map((tag) => `"${tag}"`).toString() : null

    dispatch({
      type: ACTION_UPDATE_PAYMENT_CARD_START,
      payload: params,
      tags: tags,
    })

    return API.banking.paymentCards
      .update(params)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_PAYMENT_CARD_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        // sleep here to make it seem like something happened, otherwise its nearly instant and looks weird
        setTimeout(() => {
          dispatch({
            type: ACTION_UPDATE_PAYMENT_CARD_ERROR,
            payload: e,
          })
        }, 1000)
      })
  },
  updateActivateCard: (newValue) => ({
    type: ACTION_UPDATE_ACTIVATE_PAYMENT_CARD,
    payload: newValue,
  }),
  clearActivateCard: (newValue) => ({
    type: ACTION_CLEAR_ACTIVATE_PAYMENT_CARD,
    payload: newValue,
  }),
  activateCard: (params) => (dispatch) => {
    dispatch({ type: ACTION_ACTIVATE_PAYMENT_CARD_START })

    return API.banking.paymentCards
      .activate(params)
      .then((response) => {
        dispatch({
          type: ACTION_ACTIVATE_PAYMENT_CARD_COMPLETE,
          payload: response.data,
        })
        dispatch(BankingActions.updateCardActivation(false))
      })
      .catch((e) => {
        dispatch({
          type: ACTION_ACTIVATE_PAYMENT_CARD_ERROR,
          payload: e,
        })
      })
  },
  clearSetPinState: (params) => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_SET_CARD_PIN })
  },
  setPin: (params) => (dispatch) => {
    dispatch({ type: ACTION_SET_PAYMENT_CARD_PIN_START })

    return API.banking.paymentCards
      .setPin(params)
      .then((response) => {
        dispatch({
          type: ACTION_SET_PAYMENT_CARD_PIN_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_SET_PAYMENT_CARD_PIN_ERROR,
          payload: e.response,
        })
      })
  },
}

// REDUCER

const defaultState = {
  paymentCards: {
    paymentCards: [
      {
        nickname: '',
        accountLast4: '',
        binType: '',
        cardStatus: '',
        formFactor: '',
        pinIsSet: null,
        zip: '',
        fullAddress: '',
        id: '',
      },
    ],
    success: null,
    isLoading: false,
    isUpdating: false,
    cardFetchAbortController: null,
    lastFetchedAccountUuid: null,
    error: null,
    errors: [],
  },
  paymentCardTransactions: {
    didTransactionFailParsing: false,
    transactions: [],
    isLoading: true,
    error: false,
  },
  paymentCardToken: {
    token: '',
    isLoading: false,
    error: null,
    errors: [],
  },
  createPaymentCard: {
    paymentCard: {
      paymentCardId: null,
      paymentCardLast4: '',
    },
    success: null,
    isLoading: false,
    error: null,
    errors: [],
  },
  orderPhysicalPaymentCard: {
    paymentCard: {
      paymentCardId: null,
      paymentCardLast4: '',
    },
    success: null,
    isLoading: false,
    error: null,
    errors: [],
  },
  paymentCardDetailsReport: {
    data: [],
    filename: '',
    errors: [],
    uploadError: false,
    uploadLoading: false,
    uploadSuccess: false,
    downLoadError: false,
    downloadLoading: false,
    downloadSuccess: false,
  },
  setCardPin: {
    isLoading: false,
    success: null,
    error: null,
  },
  activatePaymentCard: {
    paymentCard: {
      paymentCardId: null,
      paymentCardLast4: '',
      paymentCardDesiredPin: '',
      paymentCardDesiredPinConfirmation: '',
    },
    success: null,
    isLoading: false,
    error: null,
  },
}

export const PaymentCardsReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_CREATE_PAYMENT_CARD_START:
      return {
        ...state,
        createPaymentCard: {
          ...state.createPaymentCard,
          success: null,
          isLoading: true,
          error: null,
          errors: [],
        },
      }

    case ACTION_CLEAR_CREATE_PAYMENT_CARD:
      return {
        ...state,
        createPaymentCard: {
          ...defaultState.createPaymentCard,
        },
      }

    case ACTION_CREATE_PAYMENT_CARD_COMPLETE:
      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          paymentCards: values(
            merge(keyBy(state.paymentCards.paymentCards, 'uuid'), keyBy([action.payload], 'uuid'))
          ),
        },
        createPaymentCard: {
          paymentCard: {
            paymentCardId: action.payload.uuid,
            paymentCardLast4: action.payload.lastFour,
          },
          isLoading: false,
          success: true,
        },
      }

    case ACTION_CREATE_PAYMENT_CARD_ERROR:
      return {
        ...state,
        createPaymentCard: {
          ...state.createPaymentCard,
          errors: action.payload.response?.data,
          success: false,
          isLoading: false,
          error: true,
        },
      }

    case ACTION_ORDER_PHYSICAL_PAYMENT_CARD_START:
      return {
        ...state,
        orderPhysicalPaymentCard: {
          ...state.orderPhysicalPaymentCard,
          success: null,
          isLoading: true,
          error: null,
          errors: [],
        },
      }

    case ACTION_ORDER_PHYSICAL_PAYMENT_CARD_COMPLETE:
      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          paymentCards: values(
            merge(keyBy(state.paymentCards.paymentCards, 'uuid'), keyBy([action.payload], 'uuid'))
          ),
        },
        orderPhysicalPaymentCard: {
          paymentCard: {
            paymentCardId: action.payload.uuid,
            paymentCardLast4: action.payload.lastFour,
          },
          isLoading: false,
          success: true,
        },
      }

    case ACTION_ORDER_PHYSICAL_PAYMENT_CARD_ERROR:
      return {
        ...state,
        orderPhysicalPaymentCard: {
          ...state.orderPhysicalPaymentCard,
          errors: action.payload.response.data,
          success: false,
          isLoading: false,
          error: true,
        },
      }

      case ACTION_BATCH_UPDATE_PAYMENT_CARDS_START:
        let batchUpdatedCards = _(state.paymentCards.paymentCards)
          .concat(action.payload.cards)
          .groupBy('uuid')
          .map(_.spread(_.merge))
          .value();

        return {
          ...state,
          paymentCards: {
            ...state.paymentCards,
            paymentCards: batchUpdatedCards,
            success: null,
            isLoading: true,
            error: false,
          },
        }
      case ACTION_BATCH_UPDATE_PAYMENT_CARDS_ERROR:
        return {
          ...state,
          paymentCards: {
            ...state.paymentCards,
            success: false,
            isLoading: false,
            error: true,
          },
        }
      case ACTION_BATCH_UPDATE_PAYMENT_CARDS_COMPLETE:
        return {
          ...state,
          paymentCards: {
            ...state.paymentCards,
            success: true,
            isLoading: false,
          },
        }
    
      case ACTION_UPDATE_PAYMENT_CARD_START:
      // Here we optimistically update the frontend on start (rather than on
      // return from the server) in order to prevent 'flashing' which is unsightly.

      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          success: null,
          error: false,
        },
      }

    case ACTION_UPDATE_PAYMENT_CARD_ERROR:
      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          paymentCards: state.paymentCards.paymentCards.map((card) => {
            if ('oldCardStatus' in card) {
              card['cardStatus'] = card['oldCardStatus']
            }
            return card
          }),
          errors: action.payload.response.data,
          success: false,
          isLoading: false,
          error: true,
        },
      }

    case ACTION_UPDATE_PAYMENT_CARD_COMPLETE:
      let returnedCards = _(state.paymentCards.paymentCards)
        .concat(action.payload)
        .groupBy('uuid')
        .map(_.spread(_.merge))
        .value();

      let returnedCard = returnedCards.find((card) => card.uuid === action.payload.uuid)
      returnedCard.tags = action.payload.tags

      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          paymentCards: returnedCards,
          old_card_state: null,
          isLoading: false,
          success: true,
        },
      }

    case ACTION_UPDATE_ACTIVATE_PAYMENT_CARD:
      return {
        ...state,
        activatePaymentCard: {
          ...state.activatePaymentCard,
          paymentCard: {
            ...state.activatePaymentCard.paymentCard,
            ...action.payload,
          },
        },
      }

    case ACTION_ACTIVATE_PAYMENT_CARD_START:
      return {
        ...state,
        activatePaymentCard: {
          ...state.activatePaymentCard,
          isLoading: true,
          error: null,
          errors: [],
        },
      }

    case ACTION_ACTIVATE_PAYMENT_CARD_COMPLETE:
      return {
        ...state,
        activatePaymentCard: {
          ...state.activatePaymentCard,
          ...action.payload,
          isLoading: false,
          success: true,
        },
      }

    case ACTION_CLEAR_ACTIVATE_PAYMENT_CARD:
      return {
        ...state,
        activatePaymentCard: {
          ...defaultState.activatePaymentCard,
          paymentCard: {
            ...state.activatePaymentCard.paymentCard,
            paymentCardLast4: '',
            paymentCardDesiredPin: '',
          },
        },
      }

    case ACTION_ACTIVATE_PAYMENT_CARD_ERROR:
      return {
        ...state,
        activatePaymentCard: {
          ...state.activatePaymentCard,
          errors: action.payload.response.data,
          success: false,
          isLoading: false,
          error: true,
        },
      }

    case ACTION_CLEAR_SET_CARD_PIN:
      return {
        ...state,
        setCardPin: {
          ...state.setCardPin,
          isLoading: null,
          success: null,
          error: null
        },
      }
    
    case ACTION_SET_PAYMENT_CARD_PIN_START:
      return {
        ...state,
        setCardPin: {
          ...state.setCardPin,
          isLoading: true,
          success: null,
          error: null
        },
      }

    case ACTION_SET_PAYMENT_CARD_PIN_COMPLETE:
      return {
        ...state,
        setCardPin: {
          ...state.setCardPin,
          isLoading: false,
          success: true,
          error: null
        },
      }

    case ACTION_SET_PAYMENT_CARD_PIN_ERROR:
      return {
        ...state,
        setCardPin: {
          ...state.setCardPin,
          isLoading: false,
          success: false,
          error: action.payload?.data,
        },
      }

    case ACTION_GET_PAYMENT_CARD_TRANSACTIONS_START:
      return {
        ...state,
        paymentCardTransactions: {
          transactions: [],
          didTransactionFailParsing: false,
          isLoading: true,
          error: false,
        },
      }

    case ACTION_GET_PAYMENT_CARDS_PARTIAL_START:
      if (state.paymentCards.cardFetchAbortController) {
        state.paymentCards.cardFetchAbortController.abort()
      }

      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          cardFetchAbortController: action.payload.controller,
          isLoading: true,
          error: false,
        },
      }

    case ACTION_GET_PAYMENT_CARDS_FULL_START:
      if (state.paymentCards.cardFetchAbortController) {
        state.paymentCards.cardFetchAbortController.abort()
      }
    
      return {
        ...state,
        paymentCards: {
          ...defaultState.paymentCards,
          cardFetchAbortController: action.payload.controller,
          lastFetchedAccountUuid: action.payload.cardAccountUuid,
          isLoading: true,
          error: false,
        },
      }

    case ACTION_CLEAR_PAYMENT_CARDS:
      return {
        ...state,
        paymentCards: {
          ...defaultState.paymentCards,
          cardFetchAbortController: state.paymentCards.cardFetchAbortController,
        },
      }

    case ACTION_GET_PAYMENT_CARDS_COMPLETE:
      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          paymentCards: values(
            merge(keyBy(state.paymentCards.paymentCards, 'uuid'), keyBy(action.payload, 'uuid'))
          ),
          cardFetchAbortController: null,
          isLoading: false,
          error: false,
        },
      }

    case ACTION_GET_PAYMENT_CARDS_ERROR:
      return {
        ...state,
        paymentCards: {
          ...defaultState.paymentCards,
          isLoading: false,
          error: action.payload.message !== 'canceled',
          cardFetchAbortController: state.paymentCards.cardFetchAbortController,
        },
      }

    case ACTION_GET_PAYMENT_CARD_PARTIAL_START:
      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          isUpdating: true,
          error: null,
          errors: [],
        },
      }

    case ACTION_GET_PAYMENT_CARD_FULL_START:
      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          isLoading: true,
          error: null,
          errors: [],
        },
      }

    case ACTION_GET_PAYMENT_CARD_TRANSACTIONS_COMPLETE:
      return {
        ...state,
        paymentCardTransactions: {
          transactions: action.payload.transactions,
          didTransactionFailParsing: action.payload.didTransactionFailParsing,
          isLoading: false,
          error: false,
          startIndex: action.payload.startIndex,
          endIndex: action.payload.endIndex,
          isMore: action.payload.isMore,
        },
      }

    case ACTION_GET_PAYMENT_CARD_TRANSACTIONS_ERROR:
      return {
        ...state,
        paymentCardTransactions: {
          transactions: [],
          didTransactionFailParsing: false,
          isLoading: false,
          error: true,
        },
      }

    case ACTION_GET_PAYMENT_CARD_COMPLETE:
      // this mess here replaces just the transactions
      const activePaymentCard = {
        ...find(state.paymentCards.paymentCards, { uuid: action.payload.uuid }),
        transactions: action.payload.transactions,
        didTransactionFailParsing: action.payload.didTransactionFailParsing,
      }
      let activePaymentCardIndex = state.paymentCards.paymentCards.findIndex((obj) => {
        return obj.uuid === action.payload.uuid
      })
      let activePaymentCardRefreshed = state.paymentCards.paymentCards.filter((obj) => {
        return obj.uuid !== action.payload.uuid
      })
      activePaymentCardRefreshed.splice(activePaymentCardIndex, 0, activePaymentCard)

      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          paymentCards: values(activePaymentCardRefreshed),
          old_card_state: null,
          isLoading: false,
          success: true,
        },
      }

    case ACTION_GET_PAYMENT_CARD_ERROR:
      return {
        ...state,
        paymentCards: {
          ...state.paymentCards,
          errors: action.payload.response.data,
          success: false,
          isLoading: false,
          error: true,
        },
      }

    case ACTION_GET_PAYMENT_CARD_TOKEN_START:
      return {
        ...state,
        paymentCardToken: {
          ...state.paymentCardToken,
          isLoading: true,
        },
      }

    case ACTION_CLEAR_PAYMENT_CARD_TOKEN:
      return {
        ...state,
        paymentCardToken: {
          ...state.paymentCardToken,
          token: '',
          isLoading: false,
        },
      }

    case ACTION_GET_PAYMENT_CARD_TOKEN_COMPLETE:
      return {
        ...state,
        paymentCardToken: {
          ...state.paymentCardToken,
          token: action.payload,
          isLoading: false,
        },
      }

    case ACTION_GET_PAYMENT_CARD_TOKEN_ERROR:
      return {
        ...state,
        paymentCardToken: {
          ...state.paymentCardToken,
          isLoading: false,
          error: true,
          errors: action.payload.response.data,
        },
      }

    case ACTION_CLEAR_PAYMENT_CARD_DETAILS_REPORT:
      return {
        ...state,
        paymentCardDetailsReport: defaultState.paymentCardDetailsReport,
      }

    case ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_START:
      return {
        ...state,
        paymentCardDetailsReport: {
          ...state.paymentCardDetailsReport,
          data: [],
          filename: '',
          downloadError: false,
          downloadLoading: true,
          downloadSuccess: false,
        },
      }

    case ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE:
      // naive approach for grabbing filename buts thats ok for our application
      let filename = get(action, 'payload.headers.content-disposition', '')
      filename = filename.split('filename=')[1].split(';')[0]

      return {
        ...state,
        paymentCardDetailsReport: {
          ...state.paymentCardDetailsReport,
          data: action.payload.data,
          filename: filename,
          downloadError: false,
          downloadLoading: false,
          downloadSuccess: true,
        },
      }

    case ACTION_DOWNLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR:
      return {
        ...state,
        paymentCardDetailsReport: {
          ...state.paymentCardDetailsReport,
          data: [],
          filename: '',
          downloadError: true,
          downloadLoading: false,
          downloadSuccess: false,
        },
      }

    case ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_START:
      return {
        ...state,
        paymentCardDetailsReport: {
          ...state.paymentCardDetailsReport,
          uploadError: false,
          uploadLoading: true,
          uploadSuccess: false,
        },
      }

    case ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_COMPLETE:
      return {
        ...state,
        paymentCardDetailsReport: {
          ...state.paymentCardDetailsReport,
          uploadError: false,
          uploadLoading: false,
          uploadSuccess: true,
        },
      }

    case ACTION_UPLOAD_PAYMENT_CARD_DETAILS_REPORT_ERROR:
      return {
        ...state,
        paymentCardDetailsReport: {
          ...state.paymentCardDetailsReport,
          errors: action.payload.error,
          uploadError: true,
          uploadLoading: false,
          uploadSuccess: false,
        },
      }

    default:
      return state
  }
}
