import React from "react";

type BaggageProps = {
    carryonBaggageAllowance?: number;
    checkedBaggageAllowance?: number;
    carryonBaggageAllowanceKilos?: number;
    checkedBaggageAllowanceKilos?: number;
    carryonBaggageText?: string;
    checkedBaggageText?: string;
}

export const baggageFromReservation = (reservation: any): BaggageProps => {
    return {
        "carryonBaggageAllowance": reservation?.carryonBaggageAllowance,
        "checkedBaggageAllowance": reservation?.checkedBaggageAllowance,
        "carryonBaggageAllowanceKilos": reservation?.carryonBaggageAllowanceKilos,
        "checkedBaggageAllowanceKilos": reservation?.checkedBaggageAllowanceKilos,
        "carryonBaggageText": reservation?.carryonBaggageText,
        "checkedBaggageText": reservation?.checkedBaggageText,
    }
}

export const baggageDetails = (baggage: BaggageProps) => {
    return (
      <div>
          <div>
              {itemDetail("Carry on bags", baggage.carryonBaggageAllowance, baggage.carryonBaggageAllowanceKilos, baggage.carryonBaggageText)}
              {itemDetail("Checked bags", baggage.checkedBaggageAllowance, baggage.checkedBaggageAllowanceKilos, baggage.checkedBaggageText)}
          </div>
      </div>
    )
}

export const itemDetail = (key: string, allowance?: number, kilos?: number, text?: string) => {
    const kilo_string = typeof kilos === 'number' ? `${kilos} KG` : kilos
    if (typeof allowance === 'number') {
        return (
          <div>{key} {allowance} {kilo_string}</div>
        )
    } else if (typeof text === 'string'){
        return (
          <div>{text}</div>
        )
    }
}