import React from 'react'

import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Row, Col } from 'react-bootstrap'

import { Actions as UserActions } from 'redux/currentUser'

import LoginForm from 'components/authentication/loginForm'

import { ReactComponent as TaekusLogo } from 'assets/svg/TaekusLogo.svg'
import { ReactComponent as TaekusIcon } from 'assets/svg/TaekusIcon.svg'

import 'assets/components/login/css/login.scss'

class Login extends React.Component {
  state = {
    didAttemptUserFetch: false
  }

  componentDidMount() {
    this.props.dispatch(UserActions.fetchCurrentUser())
  }

  componentDidUpdate(prevProps) {
    if (this.state.didAttemptUserFetch === false && prevProps.isFetching === true && this.props.isFetching === false) {
      this.setState({ didAttemptUserFetch: true })
    }
  }

  render() {
    if (this.state.didAttemptUserFetch && this.props.isFetching === false && this.props.error === false) {
      return <Redirect to={`/`} />
    }
    
    return (
      <Row className="Login-container m-0">
        <Col md="6" lg="5">
          <Col
            xs="12"
            md={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 2 }}
            className="Login-formBlock"
          >
            <div className="d-flex justify-content-center my-4">
              <TaekusLogo style={{ height: '40px', width: 'auto' }} />
            </div>
            <div className="StandardBodyDMSans mb-5">
              <FormattedMessage
                id="login.description"
                defaultMessage={`Log into your Taekus account to analyze your spending, redeem rewards, and make payments.`}
              />
            </div>

            <LoginForm {...this.props} />

            <div className="StandardSubtitleDMSans d-flex flex-column text-center mt-4">
              <NavLink to="/account/recovery/">
                <FormattedMessage
                  id="login.forgot-password"
                  defaultMessage={'Forgot your username or password?'}
                />
              </NavLink>
              <NavLink to="/signup/" className="mt-1">
                <FormattedMessage
                  id="login.register"
                  defaultMessage={'Not registered? Sign up here.'}
                />
              </NavLink>
            </div>
            <div className="StandardSubtitleDMSans d-flex flex-column text-center mt-5 px-5">
              <FormattedMessage
                id="login.banking-disclosure"
                defaultMessage={'Banking services provided by Stearns Bank N.A., Member FDIC.'}
              />
            </div>
          </Col>
        </Col>
        <Col
          md="6"
          lg="7"
          className="Login-decorativeBackground d-none d-md-flex justify-content-center"
        >
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="Taekus-Icon-Large">
              <TaekusIcon fill="white" style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.currentUser,
})

export default connect(mapStateToProps, null)(Login)