
import styled from "styled-components";

import { TransactionType } from "components/pages/Home/constants";

import styles from "styles/styles";

export const filterButtonMessages = {
    [TransactionType.All]: 'All',
    [TransactionType.Purchase]: 'Purchases',
    [TransactionType.Refund]: 'Refunds',
    [TransactionType.Payment]: 'Payments',
    [TransactionType.Decline]: 'Declines',
}

type FilterButtonProps = {
    selected?: boolean;
}

const FilterButton = styled.button<FilterButtonProps>`
    ${styles.Text.BodySmall}
    padding: ${styles.Spacing.XS} 25px;
    background-color: ${props => props.selected ? styles.Color.Black : styles.Color.Transparent};
    color: ${props => props.selected ? styles.Color.TaekusCream : styles.Color.GreyText};
    border: 1px solid ${props => props.selected ? `transparent` : styles.Color.Grey};
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border-radius: 30px;
    user-select: none;
    &:not(&:last-child) {
        margin-right: ${styles.Spacing.XS};
    }
    ${styles.Animation.transitionStyles}
    ${props => !props.selected && `&:hover {
        color ${styles.Color.Black};
        border: 1px solid ${styles.Color.Black} !important;
    }`}
`

export default FilterButton;