export const messages = {
    DebitPayment: {
        Options: {
            Errors: {
                InvalidAmount: 'Amount must be a valid number.',
                EmptyAmount: 'Amount cannot be blank.',
                EmptySourceAccount: 'Source Account cannot be blank.',
                EmptyDestinationAccount: 'Destination Account cannot be blank.',
                InsufficientFunds: `Amount cannot be greater than the source account's balance.`,
            }
        }
    },
}