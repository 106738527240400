import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { noop } from "lodash";

import Select from 'components/common/Select/Select'

import styled from "styled-components";

import { Actions as BankingActions } from 'redux/features/banking/banking'

import Checkbox from "components/common/Checkbox";

import { SaveButton } from "components/pages/Settings/constants";

import styles from "styles/styles";

type NotificationOptions = {
    standardTransferStatus: boolean,
    internalTransferStatus: boolean,
    depositCompleted: boolean,
    transactionDeclined: boolean,
    transactionProcessed: boolean,
    transactionRefunded: boolean,
}

const Notifications = () => {
    // Redux state
    const dispatch = useDispatch()
    const banking = useSelector((state: any) => state.banking)
    const notificationSettings = useSelector((state: any) => state.banking.notificationSettings.settingsCopy)
    const isNotificationsLoading = useSelector((state: any) => state.banking.notificationSettings.isLoading)
    const cardAccountUuid = useSelector((state: any) => state.banking.account.uuid)
    const cardAccounts = useSelector((state: any) => state.currentUser.currentUser.cardAccounts)
    const parentUser = useSelector((state: any) => state.currentUser.currentUser.parentUser)

    // Component state
    const [stateOptions, setStateOptions] = useState({
        standardTransferStatus: false,
        internalTransferStatus: false,
        depositCompleted: false,
        transactionDeclined: false,
        transactionRefunded: false,
        transactionProcessed: false,
    } as NotificationOptions)

    const cardAccountOptions = cardAccounts.map((account: any) => ({
        value: account.uuid,
        label: `${account.name} ...${account.accountLast4}`
    }))

    const changeCardAccounts = (cardAccountOption: any) => {
        dispatch(BankingActions.fetchCardAccount({ cardAccountUuid: cardAccountOption.value }))
    }

    const toggleStandardTransferStatus = () => {
        setStateOptions({ ...stateOptions, standardTransferStatus: !stateOptions.standardTransferStatus })
    }
    
    const toggleInternalTransferStatus = () => {
        setStateOptions({ ...stateOptions, internalTransferStatus: !stateOptions.internalTransferStatus })
    }

    const toggleDepositCompleted = () => {
        setStateOptions({ ...stateOptions, depositCompleted: !stateOptions.depositCompleted })
    }

    const toggleTransactionDeclined = () => {
        setStateOptions({ ...stateOptions, transactionDeclined: !stateOptions.transactionDeclined })
    }

    const toggleTransactionProcessed = () => {
        setStateOptions({ ...stateOptions, transactionProcessed: !stateOptions.transactionProcessed })
    }

    const toggleTransactionRefunded = () => {
        setStateOptions({ ...stateOptions, transactionRefunded: !stateOptions.transactionRefunded })
    }

    const saveNotifications = () => {
        dispatch(BankingActions.updateNotificationSettings({
            settings: {
                transferLifecycleEmails: stateOptions.standardTransferStatus,
                internalTransferEmails: stateOptions.internalTransferStatus,
                depositCompletedEmails: stateOptions.depositCompleted,
                cardDeclineEmails: stateOptions.transactionDeclined,
                cardUsageEmails: stateOptions.transactionProcessed,
                cardRefundEmails: stateOptions.transactionRefunded,
            }, 
            cardAccountUuid: cardAccountUuid
        }))
    }

    // When the notification settings are updated from an API refresh, update the local state
    useEffect(() => {
        setStateOptions({
            standardTransferStatus: notificationSettings.transferLifecycleEmails,
            internalTransferStatus: notificationSettings.internalTransferEmails,
            depositCompleted: notificationSettings.depositCompletedEmails,
            transactionDeclined: notificationSettings.cardDeclineEmails,
            transactionProcessed: notificationSettings.cardUsageEmails,
            transactionRefunded: notificationSettings.cardRefundEmails,
        })
    }, [notificationSettings])

    // When isNotificationsLoading goes from true => false (done updating notifications), re-fetch the card account
    useEffect(() => {
        if (isNotificationsLoading === false) {
            dispatch(BankingActions.fetchCardAccount({ cardAccountUuid }))
        }
    }, [isNotificationsLoading, dispatch]) // eslint-disable-line

    return <div>
        <Title>Email Notification Settings</Title>
        {parentUser ? <HelpText><b>Acting as an authorized user for {parentUser.firstName} {parentUser.lastName}</b></HelpText>:<></>}
        <div>
            <CheckboxContainer>
                <CheckboxLabel>Personal Information Changed:</CheckboxLabel>
                <Checkbox disabled checked={true} onChange={noop} />
            </CheckboxContainer>
            <CheckboxContainer>
                <CheckboxLabel>Rewards Redemption:</CheckboxLabel>
                <Checkbox disabled checked={true} onChange={noop} />
            </CheckboxContainer>
            <CheckboxContainer>
                <CheckboxLabel>Banking Actions:</CheckboxLabel>
                <Checkbox disabled checked={true} onChange={noop} />
            </CheckboxContainer>
            <LineBreak/>
            <CardAccountContainer>
                <CardAccountLabel>Current Card Account:</CardAccountLabel>
                <Select
                    value={cardAccountOptions.find((option: any) => option.value === banking.account.uuid)}
                    options={cardAccountOptions}
                    onChange={changeCardAccounts}
                />
            </CardAccountContainer>
            <CheckboxContainer>
                <CheckboxLabel>Standard Transfer Status:</CheckboxLabel>
                <Checkbox 
                    checked={stateOptions.standardTransferStatus}
                    onChange={toggleStandardTransferStatus} 
                />
            </CheckboxContainer>
            <CheckboxContainer>
                <CheckboxLabel>Internal Transfer Status:</CheckboxLabel>
                <Checkbox 
                    checked={stateOptions.internalTransferStatus}
                    onChange={toggleInternalTransferStatus}
                />
            </CheckboxContainer>
            <CheckboxContainer>
                <CheckboxLabel>Deposit Completed:</CheckboxLabel>
                <Checkbox
                    checked={stateOptions.depositCompleted}
                    onChange={toggleDepositCompleted}
                />
            </CheckboxContainer>
            <CheckboxContainer>
                <CheckboxLabel>Transaction Refunded:</CheckboxLabel>
                <Checkbox
                    checked={stateOptions.transactionRefunded}
                    onChange={toggleTransactionRefunded}
                />
            </CheckboxContainer>
            <CheckboxContainer>
                <CheckboxLabel>Transaction Declined:</CheckboxLabel>
                <Checkbox
                    checked={stateOptions.transactionDeclined}
                    onChange={toggleTransactionDeclined}
                />
            </CheckboxContainer>
            <CheckboxContainer>
                <CheckboxLabel>Transaction Processed:</CheckboxLabel>
                <Checkbox
                    checked={stateOptions.transactionProcessed}
                    onChange={toggleTransactionProcessed}
                />
            </CheckboxContainer>
        </div>
        <ContentFooter>
            <SaveButton onClick={saveNotifications}>Save</SaveButton>
        </ContentFooter>
    </div>
}

const HelpText = styled.div`
     ${styles.Text.BodySmall}
    font-size: ${styles.Font.Size.Small};
    padding-top: ${styles.Spacing.S};
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
`

const LineBreak = styled.div`
    border-bottom: 1px solid grey;
    opacity: 0.5;
`

const CardAccountLabel = styled.div`
    margin-right: ${styles.Spacing.S};
`

const CardAccountContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: ${styles.Spacing.S} ${styles.Spacing.XS};
`

const ContentFooter = styled.div`
    display: flex;
    justify-content: end;
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    min-width: 500px;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const CheckboxContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: ${styles.Spacing.S} ${styles.Spacing.XS};
    align-items: center;
`

const CheckboxLabel = styled.div`
`

export default Notifications;