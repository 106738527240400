import API from 'services/api'

import { Actions as UserActions } from 'redux/currentUser'

const featureName = 'FlightBook'

const ACTION_FETCH_PRICE_START = `${featureName}/FETCH_PRICE_START`
const ACTION_FETCH_PRICE_COMPLETE = `${featureName}/FETCH_PRICE_COMPLETE`
const ACTION_FETCH_PRICE_ERROR = `${featureName}/FETCH_PRICE_ERROR`
const ACTION_CLEAR_PRICE_DETAILS = `${featureName}/CLEAR_PRICE_DETAILS`
// const ACTION_CLEAR_PRICE_ERROR = `${featureName}/CLEAR_PRICE_ERROR`

const ACTION_SELECT_UPSELL_PRICE = `${featureName}/SELECT_UPSELL_PRICE`
const ACTION_CLEAR_UPSELL_PRICE = `${featureName}/CLEAR_UPSELL_PRICE`

const ACTION_UPDATE_FLIGHT_BOOK_VALUE = `${featureName}/UPDATE_VALUE`
const ACTION_UPDATE_FLIGHT_BOOK_PAX_INFO = `${featureName}/UPDATE_PAX_VALUE`
const ACTION_CLEAR_FLIGHT_BOOK_PAX_INFO = `${featureName}/CLEAR_PAX_VALUE`
const ACTION_CLEAR_FLIGHT_BOOK = `${featureName}/CLEAR_FLIGHT_BOOK`
const ACTION_CLEAR_FLIGHT_ERROR = `${featureName}/CLEAR_FLIGHT_ERROR`

const ACTION_BOOK_START = `${featureName}/BOOK_START`
const ACTION_BOOK_COMPLETE = `${featureName}/BOOK_COMPLETE`
const ACTION_BOOK_ERROR = `${featureName}/BOOK_ERROR`

const ACTION_CLEAR_PASSPORT_BOOKING = `${featureName}/CLEAR_PASSPORT_BOOKING`
const ACTION_CLEARN_PASSENGER_PASSPORT_BOOKING = `${featureName}/CLEARN_PASSENGER_PASSPORT_BOOKING`
const ACTION_SET_PASSPORT_BOOKING = `${featureName}/SET_PASSPORT_BOOKING`

const ACTION_CLEAR_PASSPORT_IMAGE = `${featureName}/CLEAR_PASSPORT_IMAGE`
const ACTION_UPLOAD_PASSPORT_IMAGE_START = `${featureName}/CREATE_PASSPORT_IMAGE_START`
const ACTION_UPLOAD_PASSPORT_IMAGE_COMPLETE = `${featureName}/CREATE_PASSPORT_IMAGE_COMPLETE`
const ACTION_UPLOAD_PASSPORT_IMAGE_ERROR = `${featureName}/CREATE_PASSPORT_IMAGE_ERROR`

const ACTION_CREATE_PASSPORT_BOOKING_START = `${featureName}/CREATE_PASSPORT_BOOKING_START`
const ACTION_CREATE_PASSPORT_BOOKING_COMPLETE = `${featureName}/CREATE_PASSPORT_BOOKING_COMPLETE`
const ACTION_CREATE_PASSPORT_BOOKING_ERROR = `${featureName}/CREATE_PASSPORT_BOOKING_ERROR`

const ACTION_DELETE_PASSPORT_BOOKING_START = `${featureName}/DELETE_PASSPORT_BOOKING_START`
const ACTION_DELETE_PASSPORT_BOOKING_COMPLETE = `${featureName}/DELETE_PASSPORT_BOOKING_COMPLETE`
const ACTION_DELETE_PASSPORT_BOOKING_ERROR = `${featureName}/DELETE_PASSPORT_BOOKING_ERROR`

const ACTION_GET_TRAVELER_START = `${featureName}/GET_TRAVELER_START`
const ACTION_GET_TRAVELER_COMPLETE = `${featureName}/GET_TRAVELER_COMPLETE`
const ACTION_GET_TRAVELER_ERROR = `${featureName}/GET_TRAVELER_ERROR`

const ACTION_CREATE_TRAVELER_START = `${featureName}/CREATE_TRAVELER_START`
const ACTION_CREATE_TRAVELER_COMPLETE = `${featureName}/CREATE_TRAVELER_COMPLETE`
const ACTION_CREATE_TRAVELER_ERROR = `${featureName}/CREATE_TRAVELER_ERROR`

const ACTION_DELETE_TRAVELER_START = `${featureName}/DELETE_TRAVELER_START`
const ACTION_DELETE_TRAVELER_COMPLETE = `${featureName}/DELETE_TRAVELER_COMPLETE`
const ACTION_DELETE_TRAVELER_ERROR = `${featureName}/DELETE_TRAVELER_ERROR`

const ACTION_UPDATE_TRAVELER_START = `${featureName}/UPDATE_TRAVELER_START`
const ACTION_UPDATE_TRAVELER_COMPLETE = `${featureName}/UPDATE_TRAVELER_COMPLETE`
const ACTION_UPDATE_TRAVELER_ERROR = `${featureName}/UPDATE_TRAVELER_ERROR`

export const Actions = {
  clearFlightSearch: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_FLIGHT_BOOK })
  },

  clearPriceDetails: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_PRICE_DETAILS })
  },

  clearFlightError: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_FLIGHT_ERROR })
  },

  updateValue: (newValue) => ({
    type: ACTION_UPDATE_FLIGHT_BOOK_VALUE,
    payload: newValue,
  }),

  updatePassengerInfo: (newValue, paxNum) => ({
    type: ACTION_UPDATE_FLIGHT_BOOK_PAX_INFO,
    payload: newValue,
    paxNum: paxNum,
  }),

  clearPassengerInfo: (paxNum) => ({
    type: ACTION_CLEAR_FLIGHT_BOOK_PAX_INFO,
    paxNum: paxNum,
  }),

  fetchItineraryPrice: (searchParams) => (dispatch) => {
    dispatch({ type: ACTION_FETCH_PRICE_START })

    return API.flights
      .price(searchParams)
      .then((response) => {
        dispatch({
          type: ACTION_FETCH_PRICE_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_PRICE_ERROR,
          payload: e,
        })
      })
  },

  clearPassportImage: () => (dispatch) => {
    dispatch({
      type: ACTION_CLEAR_PASSPORT_IMAGE,
    })
  },

  uploadPassportImage: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPLOAD_PASSPORT_IMAGE_START,
    })

    return API.flights
      .bookingStepPassportUpload(params)
      .then((response) => {
        dispatch({
          type: ACTION_UPLOAD_PASSPORT_IMAGE_COMPLETE,
          payload: response.data.referenceUuid,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPLOAD_PASSPORT_IMAGE_ERROR,
        })
      })
  },

  uploadPassport: (travellerNum, params) => (dispatch) => {
    const passportImageState = {}
    passportImageState[travellerNum] = {
      uuid: '',
      isLoading: true,
      error: false,
    }
    dispatch({
      type: ACTION_CREATE_PASSPORT_BOOKING_START,
      payload: { ...passportImageState },
    })

    return API.flights
      .bookingStepPassportUpload(params)
      .then((response) => {
        const passportImageState = {}
        passportImageState[travellerNum] = {
          uuid: response.data.referenceUuid,
          isLoading: false,
          error: false,
        }
        dispatch({
          type: ACTION_CREATE_PASSPORT_BOOKING_COMPLETE,
          payload: { ...passportImageState },
        })
      })
      .catch((e) => {
        passportImageState[travellerNum] = {
          uuid: '',
          isLoading: false,
          error: true,
        }

        dispatch({
          type: ACTION_CREATE_PASSPORT_BOOKING_ERROR,
          payload: { ...passportImageState },
        })
      })
  },

  clearPassportImages: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_PASSPORT_BOOKING })
  },

  clearPassengerPassportImage: (travellerNum) => (dispatch) => {
    const passportImageState = {}
    passportImageState[travellerNum] = {}

    dispatch({
      type: ACTION_CLEARN_PASSENGER_PASSPORT_BOOKING,
      payload: { ...passportImageState },
    })
  },

  setPassportImage: (travellerNum, passportImageUuid) => (dispatch) => {
    const passportImageState = {}
    passportImageState[travellerNum] = {
      uuid: passportImageUuid,
      isLoading: false,
      error: false,
    }
    dispatch({ type: ACTION_SET_PASSPORT_BOOKING, payload: { ...passportImageState } })
  },

  deletePassport: (travellerNum, params) => (dispatch) => {
    const passportImageState = {}
    passportImageState[travellerNum] = {
      uuid: '',
      isLoading: true,
      error: false,
    }
    dispatch({ type: ACTION_DELETE_PASSPORT_BOOKING_START, payload: { ...passportImageState } })

    return API.flights
      .bookingStepPassportDelete(params)
      .then((response) => {
        const passportImageState = {}
        passportImageState[travellerNum] = {
          uuid: '',
          isLoading: false,
          error: false,
        }

        dispatch({
          type: ACTION_DELETE_PASSPORT_BOOKING_COMPLETE,
          payload: { ...passportImageState },
        })
      })
      .catch((e) => {
        const passportImageState = {}
        passportImageState[travellerNum] = {
          uuid: '',
          isLoading: false,
          error: true,
        }

        dispatch({
          type: ACTION_DELETE_PASSPORT_BOOKING_ERROR,
          payload: { ...passportImageState },
        })
      })
  },

  bookItinerary: (bookingParams) => (dispatch) => {
    dispatch({ type: ACTION_BOOK_START })
    return API.flights
      .book(bookingParams)
      .then((response) => {
        dispatch({
          type: ACTION_BOOK_COMPLETE,
          payload: { ...response.data },
        })
        const totalPointsUsed =
          bookingParams.priceDetail.pricePointsBase + bookingParams.priceDetail.pricePointsTaxes
        dispatch(
          UserActions.addToPointBalances({
            numPoints: -totalPointsUsed,
            numPointsPending: -totalPointsUsed,
            totalUsedPoints: totalPointsUsed,
          }),
        )
      })
      .catch((e) => {
        dispatch({
          type: ACTION_BOOK_ERROR,
          payload: { ...e.response?.data },
        })
      })
  },
  selectUpsellPrice: (params) => ({
    type: ACTION_SELECT_UPSELL_PRICE,
    payload: params,
  }),

  clearUpsellPrice: () => ({
    type: ACTION_CLEAR_UPSELL_PRICE,
  }),

  getTravelers: () => (dispatch) => {
    dispatch({ type: ACTION_GET_TRAVELER_START })
    return API.flights
      .getTraveler()
      .then((response) => {
        dispatch({
          type: ACTION_GET_TRAVELER_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_TRAVELER_ERROR,
        })
      })
  },

  createTraveler: (params) => (dispatch) => {
    dispatch({ type: ACTION_CREATE_TRAVELER_START })
    return API.flights
      .createTraveler(params)
      .then((response) => {
        dispatch({
          type: ACTION_CREATE_TRAVELER_COMPLETE,
          payload: { ...response.data.traveller },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_TRAVELER_ERROR,
        })
      })
  },

  deleteTraveler: (params, travelerIndex) => (dispatch) => {
    dispatch({ type: ACTION_DELETE_TRAVELER_START })

    return API.flights
      .deleteTraveler(params)
      .then((response) => {
        dispatch({
          type: ACTION_DELETE_TRAVELER_COMPLETE,
          payload: {
            deleteParams: params,
            travelerIndex: travelerIndex,
          },
        })
      })
      .catch((e) => {
        console.error('e: ', e)
        dispatch({
          type: ACTION_DELETE_TRAVELER_ERROR,
        })
      })
  },

  updateTraveler: (deleteParams, createParams, travelerIndex) => (dispatch) => {
    dispatch({ type: ACTION_UPDATE_TRAVELER_START })
    return API.flights
      .deleteTraveler(deleteParams)
      .then((response) => {
        dispatch({
          type: ACTION_DELETE_TRAVELER_COMPLETE,
          payload: {
            deleteParams: deleteParams,
            travelerIndex: travelerIndex,
          },
        })
        return API.flights
          .createTraveler(createParams)
          .then((response) => {
            dispatch({
              type: ACTION_UPDATE_TRAVELER_COMPLETE,
              payload: {
                traveler: { ...response.data.traveller },
                travelerIndex: travelerIndex,
              },
            })
          })
          .catch((e) => {
            console.error('e: ', e)
            dispatch({
              type: ACTION_UPDATE_TRAVELER_ERROR,
            })
          })
      })
      .catch((e) => {
        console.log('e: ', e)
        dispatch({
          type: ACTION_UPDATE_TRAVELER_ERROR,
        })
      })
  },
}

// REDUCER

const defaultState = {
  passengers: [...Array(9).keys()].map((i) => ({
    id: i,
    firstName: '',
    middleName: '',
    lastName: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    dateOfBirth: '',
    gender: { value: '', label: '' },
    phone: '',
    email: '',
    passportNumber: '',
    passportExpDate: '',
    passportCountry: '',
    knownTravellerNumber: '',
    redressNumber: '',
  })),
  priceDetail: {
    pricePoints: '',
    priceTaxes: '',
    priceTotal: '',
    upsellUuid: '',
    pricePointsTaxes: '',
    pricePointsBase: '',
    priceCashBase: '',
    priceCashTaxes: '',
    checkedBaggageAllowance: '',
    carryonBaggageAllowance: '',
    notes: [],
    taxes: [],
    priceUuid: '',
    fares: [],
    fareDetail: [
      {
        origin: '',
        destination: '',
        fareCode: '',
        nonrefundable: true,
        nonchangeable: true,
        farePriceBySlice: [],
      },
    ],
    ruleSets: [{ rules: [] }],
  },
  passportImage: {
    uuid: undefined,
    isLoading: false,
    error: false,
  },
  travellers: {},
  isDomesticItinerary: false,
  isAwardTicket: false,
  confirmedBooking: null,
  errors: [],
  pricingError: false,
  pricingIsLoading: false,
  error: false,
  isLoading: false,
  successful: false,
  bookingTravelers: [],
  isTravelerLoading: false,
  travelerErrors: false,
  createTravelerErrors: false,
  createTravelerIsLoading: false,
  updateTravelerErrors: false,
  updateTravelerIsLoading: false,
}

export const FlightBookReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_FETCH_PRICE_START:
      return {
        ...state,
        pricingIsLoading: true,
        priceDetail: defaultState.priceDetail,
        pricingError: false,
        error: false,
        errors: [],
        successful: false,
      }

    case ACTION_FETCH_PRICE_COMPLETE:
      return {
        ...state,
        pricingError: false,
        priceDetail: {
          ...state.priceDetail,
          ...action.payload,
        },
        pricingIsLoading: false,
        successful: true,
      }

    case ACTION_FETCH_PRICE_ERROR:
      return {
        ...state,
        priceDetail: defaultState.priceDetail,
        pricingIsLoading: false,
        pricingError: true,
        successful: false,
      }

    case ACTION_CLEAR_FLIGHT_ERROR:
      return {
        ...state,
        error: false,
      }

    case ACTION_BOOK_START:
      return {
        ...state,
        isLoading: true,
        error: false,
        errors: [],
      }

    case ACTION_CLEAR_PASSPORT_BOOKING:
      return {
        ...state,
        passportImage: defaultState.passportImage,
      }

    case ACTION_CLEARN_PASSENGER_PASSPORT_BOOKING:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_SET_PASSPORT_BOOKING:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_CLEAR_PASSPORT_IMAGE:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          uuid: undefined,
          isLoading: false,
          error: false,
        },
      }

    case ACTION_UPLOAD_PASSPORT_IMAGE_START:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          uuid: undefined,
          isLoading: true,
          error: false,
        },
      }

    case ACTION_UPLOAD_PASSPORT_IMAGE_COMPLETE:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          uuid: action.payload,
          isLoading: false,
          error: false,
        },
      }

    case ACTION_UPLOAD_PASSPORT_IMAGE_ERROR:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          isLoading: false,
          error: true,
        },
      }

    case ACTION_CREATE_PASSPORT_BOOKING_START:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_CREATE_PASSPORT_BOOKING_COMPLETE:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_CREATE_PASSPORT_BOOKING_ERROR:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_DELETE_PASSPORT_BOOKING_START:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_DELETE_PASSPORT_BOOKING_COMPLETE:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_DELETE_PASSPORT_BOOKING_ERROR:
      return {
        ...state,
        passportImage: {
          ...state.passportImage,
          ...action.payload,
        },
      }

    case ACTION_BOOK_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }

    case ACTION_BOOK_ERROR:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      }

    case ACTION_UPDATE_FLIGHT_BOOK_VALUE:
      return {
        ...state,
        ...action.payload,
      }

    case ACTION_CLEAR_FLIGHT_BOOK_PAX_INFO:
      const clearPassengers = [...state.passengers]
      clearPassengers[action.paxNum] = defaultState.passengers[action.paxNum]
      return {
        ...state,
        passengers: clearPassengers,
      }

    case ACTION_SELECT_UPSELL_PRICE:
      return {
        ...state,
        priceDetail: {
          ...state.priceDetail,
          ...action.payload,
        },
      }

    case ACTION_CLEAR_UPSELL_PRICE:
      return {
        ...state,
        priceDetail: {
          ...state.priceDetail,
          pricePointsBase: '',
          pricePointsTaxes: '',
          upsellUuid: '',
        },
      }

    case ACTION_UPDATE_FLIGHT_BOOK_PAX_INFO:
      const passengers = [...state.passengers]
      passengers[action.paxNum] = { ...passengers[action.paxNum], ...action.payload }
      return {
        ...state,
        passengers: passengers,
      }

    case ACTION_CLEAR_PRICE_DETAILS:
      return {
        ...state,
        priceDetail: defaultState.priceDetail,
        successful: defaultState.successful,
      }

    case ACTION_GET_TRAVELER_START:
      return {
        ...state,
        travelerErrors: false,
        bookingTravelers: [],
        isTravelerLoading: true,
      }
    case ACTION_GET_TRAVELER_COMPLETE:
      return {
        ...state,
        travelerErrors: false,
        isTravelerLoading: false,
        bookingTravelers: [...action.payload.data],
      }
    case ACTION_GET_TRAVELER_ERROR:
      return {
        ...state,
        travelerErrors: true,
        isTravelerLoading: false,
        bookingTravelers: [],
      }

    case ACTION_CREATE_TRAVELER_START:
      return {
        ...state,
        createTravelerErrors: false,
        createTravelerIsLoading: true,
      }
    case ACTION_CREATE_TRAVELER_COMPLETE:
      return {
        ...state,
        createTravelerErrors: false,
        createTravelerIsLoading: false,
        bookingTravelers: [...state.bookingTravelers, action.payload],
      }
    case ACTION_CREATE_TRAVELER_ERROR:
      return {
        ...state,
        createTravelerErrors: true,
        createTravelerIsLoading: false,
      }
    case ACTION_DELETE_TRAVELER_START:
      return {
        ...state,
        updateTravelerIsLoading: true,
      }
    case ACTION_DELETE_TRAVELER_ERROR:
      return {
        ...state,
        updateTravelerIsLoading: false,
      }
    case ACTION_DELETE_TRAVELER_COMPLETE:
      const copyOfTravelersForDelete = JSON.parse(JSON.stringify(state.bookingTravelers))
      copyOfTravelersForDelete.splice(action.payload.travelerIndex, 1)
      return {
        ...state,
        bookingTravelers: copyOfTravelersForDelete,
        updateTravelerIsLoading: false
      }

    case ACTION_UPDATE_TRAVELER_START:
      return {
        ...state,
        updateTravelerIsLoading: true,
        updateTravelerErrors: false,
      }
    case ACTION_UPDATE_TRAVELER_COMPLETE:
      const copyOfTravelersForUpdate = JSON.parse(JSON.stringify(state.bookingTravelers))
      copyOfTravelersForUpdate.splice(action.payload.travelerIndex, 0, action.payload.traveler)

      return {
        ...state,
        updateTravelerIsLoading: false,
        updateTravelerErrors: false,
        bookingTravelers: copyOfTravelersForUpdate,
      }
    case ACTION_UPDATE_TRAVELER_ERROR:
      return {
        ...state,
        updateTravelerIsLoading: false,
        updateTravelerErrors: true,
      }

    default:
      return state
  }
}
