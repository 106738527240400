import React from "react"
import { useSelector } from "react-redux"

import { AnimatePresence, motion } from "framer-motion"
import styled from 'styled-components'

import { FaTimes, FaCheck, FaMoneyBill, FaQuestion  } from 'react-icons/fa'

import styles from 'styles/styles';

import { ReactComponent as Checkmark } from "assets/svg/Checkmark.svg";
import { ReactComponent as SquareArrow } from "assets/svg/SquareArrow.svg";

interface UpsellProps {
	fare: any,
	onClick: (uuid: string) => void,
	selected: boolean,
}

const Upsell = (props: UpsellProps) => {
	// Redux state
	const isMobile = useSelector((state: any) => state.global.isMobile)

	const totalPoints = props.fare.pricePointsBase + props.fare.pricePointsTaxes
	
	const resToIcon = (res: any) => {
		const resToInt = parseInt(res)
		if (!isNaN(resToInt) && isFinite(res) && resToInt > 0) {
			// First check if the string result, converted to an int is greater than 0
			// https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
			return <StyledCheckIcon/>
		}

		switch (res) {
			case "0":
			case "not_offered":
				return <StyledCrossIcon/>
			case "included":
				return <StyledCheckIcon/>
			case "at_charge":
				return <StyledMoneyIcon/>
			default:
				return <StyledQuestionIcon/>
			}
	}

	const handleUpsellClick = () => {
		onClick(props.fare.uuid)
	}

	const descriptionsExist = (segment: any) => {
		const keys = ["refundable", "changeable", "priorityBoarding", "reserveSeatAssignment"]
		return keys.some((key: string) => segment[key] !== "unknown")
	}

	const { fare, onClick, selected } = props;
	const segment = fare.segments[0]

    return  <MainContainer onClick={handleUpsellClick} selected={selected}>
		{isMobile ? <MobileContainer>
			<MobileHeader>
				<Title selected={selected} isIsolated={false}>{segment.name.charAt(0).toUpperCase() + segment.name.slice(1).toLowerCase()}</Title>
				<div className="d-flex align-items-center">
					<Price selected={selected}>{(totalPoints).toLocaleString()} pts</Price>
					<motion.div key={selected.toString()} initial={{opacity: 0}} animate={{opacity: 1}}>
						{selected ? <StyledSelectedIcon/> : <StyledUnselectedIcon/>}
					</motion.div>
				</div>
			</MobileHeader>
			<AnimatePresence>
				{selected && <motion.div initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0}} animate={{opacity: 1, height: 'auto'}}>
					{descriptionsExist(segment) &&
						<MobileDetailsContainer>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.refundable)}</IconContainer> Refundable
								</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.changeable)}</IconContainer> Ticket changes
								</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.priorityBoarding)}</IconContainer> Priority boarding
								</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.reserveSeatAssignment)}</IconContainer> Seat selection
								</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.carryonBaggageAllowance)}</IconContainer> Carry on bag
								</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.checkedBaggageAllowance)}</IconContainer> Checked bag
							</Detail>
						</MobileDetailsContainer>
					}
				</motion.div>}
			</AnimatePresence>
		</MobileContainer> : <div className="d-flex justify-content-between flex-wrap">
			<DetailsContainer selected={selected}>
				<Title selected={selected} isIsolated={!descriptionsExist(segment)}>{segment.name.charAt(0).toUpperCase() + segment.name.slice(1).toLowerCase()}</Title>
				{descriptionsExist(segment) &&
					<div className="d-flex" >
						<DetailColumn>
							<SubTitle selected={selected}>Ticket</SubTitle>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.refundable)}</IconContainer> Refundable
							</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.changeable)}</IconContainer> Ticket changes
							</Detail>
						</DetailColumn>
						<DetailColumn>
							<SubTitle selected={selected}>Boarding/Seats</SubTitle>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.priorityBoarding)}</IconContainer> Priority boarding
							</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.reserveSeatAssignment)}</IconContainer> Seat selection
							</Detail>
						</DetailColumn>
						<DetailColumn>
							<SubTitle selected={selected}>Baggage</SubTitle>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.carryonBaggageAllowance)}</IconContainer> Carry on bag
							</Detail>
							<Detail selected={selected}>
								<IconContainer>{resToIcon(segment.checkedBaggageAllowance)}</IconContainer> Checked bag
							</Detail>
						</DetailColumn>
					</div>
				}
			</DetailsContainer>
			<PriceContainer isIsolated={!descriptionsExist(segment)}>
				<Price selected={selected}>{(totalPoints).toLocaleString()} pts</Price>
				<motion.div key={selected.toString()} initial={{opacity: 0}} animate={{opacity: 1}}>
					{selected ? <StyledSelectedIcon/> : <StyledUnselectedIcon/>}
				</motion.div>
			</PriceContainer>
		</div>}
	</MainContainer>
}


type SelectedProps = {
    selected: boolean
}

type UpsellTitleProps = {
	selected: boolean
	isIsolated: boolean
}

type PriceProps = {
	isIsolated: boolean
}

const PriceContainer = styled.div<PriceProps>`
	display: flex;
	align-items: center;
	justify-content: end;
	margin-right: 20px;
	margin-bottom: 10px;
	padding-top: 20px;
	margin-top: ${props => props.isIsolated ? "20px" : "0px"};
	flex: 1;
`

const DetailColumn = styled.div`
	&:not(&:last-child) {
		margin-right: 60px;
	}
`

const MobileDetailsContainer = styled.div`
	padding-bottom: 10px;
`

const StyledSelectedIcon = styled(Checkmark)`
	width: 18px;
	height: 18px;
	stroke: black;
`
const StyledUnselectedIcon = styled(SquareArrow)`
	width: 18px;
	height: 18px;
	stroke: black;
	opacity: 0.2;
`

const MobileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 90px;
`

const MobileContainer = styled.div`
	padding: 0 15px;
`

const StyledCrossIcon = styled(FaTimes)`
	width: 13px;
	height: auto;
	fill: ${styles.Color.Black};
`

const StyledCheckIcon = styled(FaCheck)`
	width: 13px;
	height: auto;
	fill: ${styles.Color.Black};
`

const StyledMoneyIcon = styled(FaMoneyBill)`
	width: 13px;
	height: auto;
	fill: ${styles.Color.Black};
`
const StyledQuestionIcon = styled(FaQuestion)`
	width: 13px;
	height: auto;
	fill: ${styles.Color.Black};
`

const DetailsContainer = styled.div<SelectedProps>`
${styles.Animation.transitionStyles}
	padding-left: ${props => props.selected ? '60px' : '0'};
	padding-right: ${props => props.selected ? '0' : '60px'};
`

const Detail = styled.div<SelectedProps>`
	display: flex;
	align-items: center;
	color: #000;
	height: 16px;
	font-family: ${styles.Font.Family.MonumentGrotesk};
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 15.24px */
	letter-spacing: 0.12px;
	margin-bottom: 4px;
	white-space: nowrap;
	${styles.MediaQueries.Desktop} {
		opacity: ${props => props.selected ? 1 : 0.4};
		font-size: 12px;
	}
	${styles.MediaQueries.Mobile} {
		font-size: 14px;
	}
`

const IconContainer = styled.div`
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
	opacity: 0.4;
	${styles.MediaQueries.Mobile} {
		width: 25px;
	}
`

const Price = styled.div<SelectedProps>`
	color: #000;
	font-family: ${styles.Font.Family.MonumentGrotesk};
	font-style: normal;
	line-height: 124%; /* 29.76px */
	letter-spacing: 0.24px;
	white-space: nowrap;
	${styles.MediaQueries.Desktop} {
		margin-right: 60px;
		font-size: 24px;
		font-weight: ${props => props.selected ? 600 : 400};
	}
	${styles.MediaQueries.Mobile} {
		margin-right: 25px;
		font-size: 16px;
	}
`

const Title = styled.div<UpsellTitleProps>`
	font-family: ${styles.Font.Family.MonumentGrotesk};
	font-style: normal;
	line-height: 127%; /* 20.32px */
	letter-spacing: 0.16px;
	font-size: ${props => props.isIsolated ? "24px" : "16px"};
	${styles.Animation.transitionStyles}
	${styles.MediaQueries.Desktop} {
		margin-top: ${props => props.isIsolated ? "20px" : "0px"};
		margin-bottom: 6px;
		padding-top: 15px;
		opacity: ${props => props.selected ? 1 : 0.5};
		font-weight: ${props => props.selected ? 600 : 400};
	}
	${styles.MediaQueries.Mobile} {
		margin-right: 20px;
	}
`

const SubTitle = styled.div<SelectedProps>`
	opacity: ${props => props.selected ? 1 : 0.5};
	color: #000;
	font-family: ${styles.Font.Family.MonumentGrotesk};
	font-size: 16px;
	font-style: normal;
	line-height: 127%; /* 20.32px */
	letter-spacing: 0.16px;
	margin-bottom: 4px;
	${styles.MediaQueries.Desktop} {
		font-weight: ${props => props.selected ? 600 : 400};
	}
`

const MainContainer = styled.div<SelectedProps>`
	cursor: pointer;
	background-color: ${props => props.selected ? 'rgba(139, 139, 139, 0.06)' : '#f8f8f8'};
	width: 100%;
	border-bottom: 1px solid #E7E7E7;
	${styles.Animation.transitionStyles}
	overflow: hidden;
	&:hover {
		background-color: rgba(139, 139, 139, 0.06);
	}
	${styles.MediaQueries.Desktop} {
		min-height: 120px;
		font-weight: ${props => props.selected ? 600 : 400};
	}
	${styles.MediaQueries.Mobile} {
		min-height: 90px;
	}

`


export default Upsell