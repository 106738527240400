import React, { useState } from "react"
import { useSelector } from "react-redux";

import moment from "moment";

import { motion } from "framer-motion";
import { Img } from "react-image";
import styled from "styled-components"

import { IETCodes, Locale, USDCurrencyOptions } from "utils/constants";

import Skeleton from "components/common/Skeleton";
import Spinner from "components/common/Spinner";

import { SearchOptions, TravelClass, TripType, messages } from "components/pages/Rewards/constants"
import FlightTaxesModal from "components/pages/Rewards/Flights/FlightBooking/FlightTaxesModal";
import { ConfirmationStep } from "components/pages/Rewards/Flights/FlightBooking/FlightBooking";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles"

const SIDEBAR_WIDTH = 365

type FlightConfirmationSidebarProps = {
    searchOptions: SearchOptions,
    currentFlowStep: ConfirmationStep,
    incrementFlowStep: () => void,
    selectedUpsellUuid?: string,
    selectedPaymentAccountUuid?: string,
    isAddingPassenger: boolean,
    isShowMobileUpsell: boolean,
    isLoadingFinalAvailability: boolean,
    priceDiff?: number,
}

const FlightConfirmationSidebar = (props: FlightConfirmationSidebarProps) => {
    // Redux state
    const flightBook = useSelector((state: any) => state.flightBook)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const outboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.outboundItinerary)
    const inboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.inboundItinerary)
    const numberOfPassengers = useSelector((state: any) => state.flightSearch.searchParams.numPax.value)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    const [isTaxDetailOpen, setIsTaxDetailOpen] = useState(false)
    const [isNextButtonDebounced, setIsNextButtonDebounced] = useState(false)

    const shouldShowCancellation = !flightBook.pricingIsLoading && priceDetail.transferPartnerData?.partnerCode !== 'VN' && moment(outboundItinerary.slices[0].segments[0].localDepartureTimeDate).diff(moment(), 'days') >= 7
    const selectedUpsellFare = priceDetail.fares.find((fare: any) => fare.uuid === props.selectedUpsellUuid) || priceDetail
    const containsAwardTicket = priceDetail?.reservations?.some((reservation: any) => { return reservation.isAwardTicket})

    const cashTicketPrice = selectedUpsellFare?.pricePointsBase
    const cashTicketTaxes = selectedUpsellFare?.pricePointsTaxes
    const awardTicketPrice = selectedUpsellFare?.pricePointsBase + selectedUpsellFare?.pricePointsTaxes
    const awardTicketTaxes = selectedUpsellFare?.priceCashBase + selectedUpsellFare?.priceCashTaxes

    // Always in points
    const totalPricePerPassenger = ((containsAwardTicket ? awardTicketPrice : cashTicketPrice) / numberOfPassengers).toLocaleString()
    // Taxes are points for cash ticket, cash for award ticket
    const totalTaxesPerPassenger = containsAwardTicket ? `${(awardTicketTaxes / numberOfPassengers).toLocaleString(Locale.English, USDCurrencyOptions)}` : `${(cashTicketTaxes / numberOfPassengers).toLocaleString()} points`
    const totalCash = selectedUpsellFare?.priceCashBase + selectedUpsellFare?.priceCashTaxes
    const totalPoints = selectedUpsellFare?.pricePointsBase + selectedUpsellFare?.pricePointsTaxes
    const userHasInsufficientPoints = currentUser.numPointsPending < totalPoints
    const userHasInsufficientFunds = props.selectedPaymentAccountUuid === undefined || props.selectedPaymentAccountUuid < totalCash
    const userLacksFunds = userHasInsufficientPoints || (totalCash !== 0 && userHasInsufficientFunds)
    const isButtonLoading = flightBook.pricingIsLoading || flightBook.isLoading || props.isLoadingFinalAvailability || isNextButtonDebounced
    const isNextButtonDisabled = (props.currentFlowStep === ConfirmationStep.PayAndConfirm && userLacksFunds) || props.isAddingPassenger
    const upsellAvailable = !flightBook.pricingIsLoading && priceDetail.fares.length > 0
    const isTaxDisplayAvailable =  priceDetail?.reservations?.some((reservation: any) => { return reservation.taxes.length !== 0})
    const taxes =  priceDetail?.reservations?.reduce((acc: any, reservation: any) => {
        return acc.concat(reservation?.taxes)
    }, [])

    const outboundCarrierCode = IETCodes.includes(outboundItinerary.carrierCode) ? outboundItinerary.slices[0].segments[0].operatingCarrierCode.toUpperCase() : outboundItinerary.carrierCode.toUpperCase();

    const getTravelerLabel = () => {
        return `${numberOfPassengers} Traveler${numberOfPassengers > 1 ? 's' : ''}`
    }

    const getLoadingText = () => {
        if (flightBook.pricingIsLoading) {
            return 'Fetching price details'
        }
        if (props.isLoadingFinalAvailability) {
            return 'Confirming availability'
        }
    }

    const getPriceDiffLabel = (priceDiff: number) => {
        return `This price is ${Math.abs(priceDiff) > 10 ? 'significantly' : 'slightly'} ${priceDiff > 0 ? 'higher' : 'lower'} than our prediction.`
    }

    const handleNextButtonClick = () => {
        props.incrementFlowStep();
        setIsNextButtonDebounced(true);
        setTimeout(() => setIsNextButtonDebounced(false), 2000);
    }

    const getSegmentFlexRatio = (segment: any, itinerary: any) => {
        const segmentLength = Number(segment.duration)
        const totalSumOfSegmentLengths = itinerary.slices[0].segments.map((segment: any) => Number(segment.duration)).reduce((counter: number, duration: number) => counter + duration, 0)

        return segmentLength / totalSumOfSegmentLengths
    }

    const toggleTaxDetail = () => {
        if (isTaxDisplayAvailable) {
            setIsTaxDetailOpen(!isTaxDetailOpen)
        }
    }

    const outboundArrivalDateDiffInDays = Number(moment(outboundItinerary.slices.at(-1).segments.at(-1).localArrivalTimeDate).format('DD')) - Number(moment(outboundItinerary.slices[0].segments[0].localDepartureTimeDate).format('DD'))
    const inboundArrivalDateDiffInDays = Number(moment(inboundItinerary?.slices.at(-1).segments.at(-1).localArrivalTimeDate).format('DD')) - Number(moment(inboundItinerary?.slices[0].segments[0].localDepartureTimeDate).format('DD'))

    const { searchOptions, currentFlowStep, isShowMobileUpsell, priceDiff } = props;

    return isMobile ? ((!props.isAddingPassenger && (!upsellAvailable || isShowMobileUpsell)) ? <MobileContainer>
        <Total>
            <div>Total</div>
            {flightBook.pricingIsLoading ? <Skeleton width='160px'/> : <strong>{(totalPoints).toLocaleString()} pts. {!!totalCash && " + " + (totalCash).toLocaleString(Locale.English, USDCurrencyOptions)}</strong>}
        </Total>
        {isButtonLoading ? <ButtonSpinnerContainer>
            <Spinner size={20}/>
            {getLoadingText() && <LoadingText>{getLoadingText()}</LoadingText>}
        </ButtonSpinnerContainer> :  <Button 
                {...fadeInOutMotionProps}
                disabled={isNextButtonDisabled}
                onClick={handleNextButtonClick}
            >
                {currentFlowStep === ConfirmationStep.PayAndConfirm ? 'Book Flight' : `Continue to ${messages.ConfirmationFlowSteps[(currentFlowStep + 1) as ConfirmationStep]}`}
        </Button>}
    </MobileContainer> : <></>) : <Sidebar>
        <Header>
            <SidebarTitle>Trip Details</SidebarTitle>
            <SidebarItem>{getTravelerLabel()} ∙ {messages.SearchOptions.TripType[searchOptions.tripType as TripType]}</SidebarItem>
        </Header>
        {currentFlowStep > ConfirmationStep.ReviewItinerary && <ItinerariesContainer
            initial={{ height: 0, opacity: 0 }}
            exit={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
        >
            {outboundItinerary && <ItineraryContainer>
                <ItineraryTitle>Departure</ItineraryTitle>
                <ItineraryDateContainer>
                    <ItineraryDate>{moment(outboundItinerary.slices[0].segments[0].localDepartureTimeDate).format('ddd, MMM D')}</ItineraryDate>
                    <Logo src={[
                        `/static/img/airlineLogos/mini/${outboundCarrierCode.toUpperCase()}.png`,
                        '/static/img/airlineLogos/mini/default.png'
                    ]} />
                </ItineraryDateContainer>
                <div className="d-flex justify-content-between">
                    <ItineraryLocation>{outboundItinerary.slices[0].segments[0].departurePointName}</ItineraryLocation>
                    <ItineraryLocation>{outboundItinerary.slices.at(-1).segments.at(-1).arrivalPointName}</ItineraryLocation>
                </div>
                <div className="d-flex align-items-center">
                    <div>{outboundItinerary.slices[0].segments[0].departurePoint}</div>
                    {<div style={{ display: 'flex', alignItems: 'center', flex: 1, margin: '6px'}}>
                        {outboundItinerary.slices[0].segments.map((segment: any, index: number) => <>
                            {index !== 0 && <ItineraryPoint key={`point:${index}`}/>}
                            <ItineraryLine segmentRatio={getSegmentFlexRatio(segment, outboundItinerary)} key={`line:${index}`}/>
                        </>)}
                    </div>}
                    <div>{outboundItinerary.slices[0].segments.at(-1).arrivalPoint}</div>
                </div>
                <div className="d-flex justify-content-between">
                    <ItineraryTime>{moment(outboundItinerary.slices[0].segments[0].localDepartureTimeDate).format('h:mm A')}</ItineraryTime>
                    <ItineraryTime>{moment(outboundItinerary.slices.at(-1).segments.at(-1).localArrivalTimeDate).format('h:mm A')}{outboundArrivalDateDiffInDays > 0 && <Superscript>+{outboundArrivalDateDiffInDays}</Superscript>}</ItineraryTime>
                </div>
                <div className="d-flex">
                    <ItineraryDetail>{outboundItinerary.slices[0].segments[0].aircraft}</ItineraryDetail>
                    <ItineraryDetail>{outboundItinerary.slices[0].numStops !== "0" ? `${outboundItinerary.slices[0].numStops} stop${outboundItinerary.slices[0].numStops > 1 ? 's' : ''}` : 'Nonstop'}</ItineraryDetail>
                    <ItineraryDetail>{Math.floor(outboundItinerary.slices[0].duration / 60)} hr {!!(outboundItinerary.slices[0].duration % 60) ? `${(outboundItinerary.slices[0].duration % 60)} m` : ''}</ItineraryDetail>
                    <ItineraryDetail>{messages.SearchOptions.TravelClass[outboundItinerary.slices[0].segments[0].cabin as TravelClass]}</ItineraryDetail>
                </div>
            </ItineraryContainer>}
            {inboundItinerary && <ItineraryContainer>
                <ItineraryTitle>Return</ItineraryTitle>
                <ItineraryDateContainer>
                    <ItineraryDate>{moment(inboundItinerary.slices[0].segments[0].localDepartureTimeDate).format('ddd, MMM D')}</ItineraryDate>
                    <Logo src={[
                        `/static/img/airlineLogos/mini/${outboundCarrierCode.toUpperCase()}.png`,
                        '/static/img/airlineLogos/mini/default.png'
                    ]} />
                </ItineraryDateContainer>
                <div className="d-flex justify-content-between">
                    <ItineraryLocation>{inboundItinerary.slices[0].segments[0].departurePointName}</ItineraryLocation>
                    <ItineraryLocation>{inboundItinerary.slices[0].segments.at(-1).arrivalPointName}</ItineraryLocation>
                </div>
                <div className="d-flex align-items-center">
                    <div>{inboundItinerary.slices[0].segments[0].departurePoint}</div>
                    {<div style={{ display: 'flex', alignItems: 'center', flex: 1, margin: '6px'}}>
                        {inboundItinerary.slices[0].segments.map((segment: any, index: number) => <>
                            {index !== 0 && <ItineraryPoint key={`point:${index}`}/>}
                            <ItineraryLine segmentRatio={getSegmentFlexRatio(segment, inboundItinerary)} key={`line:${index}`}/>
                        </>)}
                    </div>}
                    <div>{inboundItinerary.slices[0].segments.at(-1).arrivalPoint}</div>
                </div>
                <div className="d-flex justify-content-between">
                    <ItineraryTime>{moment(inboundItinerary.slices[0].segments[0].localDepartureTimeDate).format('h:mm A')}</ItineraryTime>
                    <ItineraryTime>{moment(inboundItinerary.slices[0].segments.at(-1).localArrivalTimeDate).format('h:mm A')}{inboundArrivalDateDiffInDays > 0 && <Superscript>+{inboundArrivalDateDiffInDays}</Superscript>}</ItineraryTime>
                </div>
                <div className="d-flex">
                <ItineraryDetail>{inboundItinerary.slices[0].segments[0].aircraft}</ItineraryDetail>
                    <ItineraryDetail>{inboundItinerary.slices[0].numStops !== "0" ? `${inboundItinerary.slices[0].numStops} stop${inboundItinerary.slices[0].numStops > 1 ? 's' : ''}` : 'Nonstop'}</ItineraryDetail>
                    <ItineraryDetail>{Math.floor(inboundItinerary.slices[0].duration / 60)} hr {!!(inboundItinerary.slices[0].duration % 60) ? `${(inboundItinerary.slices[0].duration % 60)} m` : ''}</ItineraryDetail>
                    <ItineraryDetail>{messages.SearchOptions.TravelClass[inboundItinerary.slices[0].segments[0].cabin as TravelClass]}</ItineraryDetail>
                </div>
            </ItineraryContainer>}
        </ItinerariesContainer>}
        <PriceContainer>
            <SidebarItem style={{paddingBottom: '10px'}}><strong>Cost per Traveler*</strong></SidebarItem>
            <SidebarItem style={{paddingBottom: '5px'}}>
                <div>Ticket</div>
                {flightBook.pricingIsLoading ? <Skeleton width='80px'/> : <div>{totalPricePerPassenger} points</div>}
            </SidebarItem>
            {<SidebarItem style={{paddingBottom: '5px'}}>
                <TaxesDropdownText isTaxDisplayAvailable={isTaxDisplayAvailable} onClick={toggleTaxDetail}>Taxes & Fees</TaxesDropdownText>
                {flightBook.pricingIsLoading ? <Skeleton width='80px'/> : <div>{totalTaxesPerPassenger}</div>}
            </SidebarItem>}
            <SidebarItem style={{opacity: 0.5}}>*Optional charges and bag fees may apply.</SidebarItem>
        </PriceContainer>
        <Total>
            <div>Total</div>
            {flightBook.pricingIsLoading ? <Skeleton width='160px'/> : <div>{(totalPoints || 0).toLocaleString()} pts. {!!totalCash && " + " + (totalCash).toLocaleString(Locale.English, USDCurrencyOptions)}</div>}
        </Total>
        {priceDiff !== undefined && Math.abs(priceDiff) > 1 && <PriceDiff 
            isPositive={priceDiff > 0} 
            isLargeDiff={Math.abs(priceDiff) >= 10}
        >
            {getPriceDiffLabel(priceDiff)}
        </PriceDiff>}
        {isButtonLoading ? <ButtonSpinnerContainer>
            <Spinner size={20}/>
            {getLoadingText() && <div style={{marginLeft: '10px', fontFamily: styles.Font.Family.MonumentGrotesk, fontSize: '14px'}}>{getLoadingText()}</div>}
        </ButtonSpinnerContainer> : <Button 
            {...fadeInOutMotionProps}
            disabled={isNextButtonDisabled}
            onClick={handleNextButtonClick}
        >
            {currentFlowStep === ConfirmationStep.PayAndConfirm ? 'Book Flight' : `Continue to ${messages.ConfirmationFlowSteps[(currentFlowStep + 1) as ConfirmationStep]}`}
        </Button>}
        {shouldShowCancellation && <Cancellation {...fadeInOutMotionProps}>Free cancellation for 24 hours</Cancellation>}
        {isTaxDetailOpen && <FlightTaxesModal
            taxes={taxes}
            toggleIsOpen={toggleTaxDetail}
        />}
    </Sidebar>
}

const Superscript = styled.span`
    vertical-align: super;
    font-size: 10px;
`

const LoadingText = styled.div`
    margin-left: 10px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
`

type PriceDiffProps = {
    isLargeDiff: boolean,
    isPositive: boolean,
}

const PriceDiff = styled.div<PriceDiffProps>`
    font-size: 14px;
    margin-bottom: 10px;
    ${props => props.isLargeDiff && `color: ${props.isPositive ? 'red' : styles.Color.TaekusPurple};`}
`

const ItineraryDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`

const ItineraryPoint = styled.div`
    border-radius: 50%;
    background-color: ${styles.Color.TaekusBlue};
    width: 4px;
    height: 4px;
    margin: 3px;
`

type ItineraryLineProps = {
    segmentRatio: number,
}

const ItineraryLine = styled.div<ItineraryLineProps>`
    flex: ${props => props.segmentRatio};
    height: 2px;
    background-color: ${styles.Color.TaekusBlue};
    margin: 6px 0;
`

const ItinerariesContainer = styled(motion.div)`
    border-bottom: 1px solid ${styles.Color.GreyText};
    overflow: hidden;
`

const ItineraryContainer = styled.div`
    &:first-child {
        margin-top: 15px;
    }
    margin-bottom: 15px;
`

const ButtonSpinnerContainer = styled.div`
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MobileContainer = styled.div`
    padding: 15px;
`

type TaxesDropdownTextProps = {
    isTaxDisplayAvailable: boolean,
}

const TaxesDropdownText = styled.div<TaxesDropdownTextProps>`
    border-bottom: 1px solid transparent;
    ${styles.Animation.transitionStyles}
    ${props => props.isTaxDisplayAvailable && `
        cursor: pointer;
        border-bottom: 1px solid ${styles.Color.Grey};
        &:hover {
            border-bottom: 1px solid ${styles.Color.TaekusPurple};
        }
    `}
`

const Logo = styled(Img)`
    width: auto;
    height: 26px;
`

const Cancellation = styled(motion.div)`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.32px;
    margin-top: 10px;
`

const Total = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: 600;
    color: #0E0E0E;
    font-size: 16px;
    font-style: normal;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }
`

const PriceContainer = styled.div`
    padding: 15px 0;
    border-bottom: 1px solid ${styles.Color.GreyText};
`

const Header = styled.div`
    border-top: 1px solid ${styles.Color.GreyText};
    border-bottom: 1px solid ${styles.Color.GreyText};
    height: 84px;
    padding: 17px 0;
`

type ButtonProps = {
    disabled?: boolean,
}

const Button = styled(motion.button)<ButtonProps>`
    background-color: ${props => props.disabled ? styles.Color.Grey : styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    height: 42px;
    border: 1px solid ${props => props.disabled ? styles.Color.Grey : styles.Color.TaekusPurple};
    ${styles.Animation.transitionStyles}
    ${props => !props.disabled && `&:hover {
        background-color: ${styles.Color.White};
        color: ${styles.Color.TaekusPurple};
    }`}
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const ItineraryTitle = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 17.36px */
    letter-spacing: 0.14px;
    opacity: 0.4;
    margin-bottom: 3px;
`

const ItineraryTime = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.16px;
`

const ItineraryLocation = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.1px;
`

const ItineraryDate = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.24px;
`

const ItineraryDetail = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 146%; /* 14.6px */
    letter-spacing: 0.1px;
    &:not(&:last-child) {
        padding-right: 6px;
        border-right: 1px solid rgba(0,0,0,0.1);
    }
    &:not(&:first-child) {
        padding-left: 6px;
    }
`

const SidebarTitle = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
`

const SidebarItem = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    height: 25px;
    display: flex;
    justify-content: space-between;
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${styles.Spacing.XS};
    min-width: ${SIDEBAR_WIDTH}px;
    max-width: ${SIDEBAR_WIDTH}px;
    margin-left: ${styles.Spacing.M};
    margin-right: ${styles.Spacing.M};
    margin-top: 70px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

export default FlightConfirmationSidebar