import styled from "styled-components";

import { UserAccessStatus } from "components/pages/Settings/types";

import styles from "styles/styles";

export const getUserAccessStatusColor = (status: UserAccessStatus) => {
    switch (status) {
        case UserAccessStatus.Active:
            return '#00F680'
        case UserAccessStatus.Pending:
            return '#FFC702'
        case UserAccessStatus.Removed:
        case UserAccessStatus.InviteExpired:
        default:
            return '#888'
    }
}

type SaveButtonProps = {
    disabled?: boolean,
}

export const SaveButton = styled.button<SaveButtonProps>`
    background-color: ${styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    border: 0;
    padding: 8px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        width: 240px;
    }
    ${props => props.disabled && 'background-color: grey;'}
`

export const Bold = styled.div`
    font-weight: ${styles.Font.Weight[500]};
`

export const LinkText = styled.button`
    background-color: ${styles.Color.Transparent};
    border: 0;
    color: ${styles.Color.TaekusPurple};
    &:hover {
        text-decoration: underline;
    }
`