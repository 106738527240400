const airlines = {
    "1T": { "code": "1T", "name": "1Time Airline" },
    "Q5": { "code": "Q5", "name": "40-Mile Air" },
    "M3": { "code": "M3", "name": "ABSA - Aerolinhas Brasileiras" },
    "GB": { "code": "GB", "name": "ABX Air" },
    "VX": { "code": "VX", "name": "ACES Colombia" },
    "Z7": { "code": "Z7", "name": "ADC Airlines" },
    "IW": { "code": "IW", "name": "AOM French Airlines" },
    "TZ": { "code": "TZ", "name": "ATA Airlines" },
    "V8": { "code": "V8", "name": "ATRAN Cargo Airlines" },
    "J6": { "code": "J6", "name": "AVCOM" },
    "1B": { "code": "1B", "name": "Abacus International" },
    "4Z": { "code": "4Z", "name": "Airlink" },
    "K5": { "code": "K5", "name": "Aban Air" },
    "W9": { "code": "W9", "name": "Abelag Aviation" },
    "MO": { "code": "MO", "name": "Abu Dhabi Amiri Flight" },
    "ZA": { "code": "ZA", "name": "Access Air" },
    "ZY": { "code": "ZY", "name": "Ada Air" },
    "KI": { "code": "KI", "name": "Adam Air" },
    "JP": { "code": "JP", "name": "Adria Airways" },
    "4G": { "code": "4G", "name": "Advance Leasing Company" },
    "A3": { "code": "A3", "name": "Aegean Airlines" },
    "RE": { "code": "RE", "name": "Aer Arann" },
    "EI": { "code": "EI", "name": "Aer Lingus" },
    "EE": { "code": "EE", "name": "Aero Airlines" },
    "E4": { "code": "E4", "name": "Aero Asia International" },
    "EM": { "code": "EM", "name": "Aero Benin" },
    "JR": { "code": "JR", "name": "Aero California" },
    "Q6": { "code": "Q6", "name": "Aero Condor Peru" },
    "AJ": { "code": "AJ", "name": "Aero Contractors" },
    "GV": { "code": "GV", "name": "Aero Flight" },
    "QL": { "code": "QL", "name": "Aero Lanka" },
    "W4": { "code": "W4", "name": "Aero Services Executive" },
    "2D": { "code": "2D", "name": "Aero VIP" },
    "DW": { "code": "DW", "name": "Aero-Charter Ukraine" },
    "P5": { "code": "P5", "name": "AeroRepublica" },
    "2B": { "code": "2B", "name": "Aerocondor" },
    "SU": { "code": "SU", "name": "Aeroflot Russian Airlines" },
    "D9": { "code": "D9", "name": "Aeroflot-Don" },
    "5N": { "code": "5N", "name": "Aeroflot-Nord" },
    "3S": { "code": "3S", "name": "Aeroland Airways" },
    "XL": { "code": "XL", "name": "Aerolane" },
    "7E": { "code": "7E", "name": "Aeroline GmbH" },
    "AR": { "code": "AR", "name": "Aerolineas Argentinas" },
    "2K": { "code": "2K", "name": "Aerolineas Galapagos (Aerogal)" },
    "5D": { "code": "5D", "name": "Aerolitoral" },
    "BQ": { "code": "BQ", "name": "Aeromar" },
    "VW": { "code": "VW", "name": "Aeromar" },
    "QO": { "code": "QO", "name": "Aeromexpress" },
    "HT": { "code": "HT", "name": "Aeromist-Kharkiv" },
    "AM": { "code": "AM", "name": "Aeromexico" },
    "OT": { "code": "OT", "name": "Aeropelican Air Services" },
    "PL": { "code": "PL", "name": "Aeroperu" },
    "5L": { "code": "5L", "name": "Aerosur" },
    "VV": { "code": "VV", "name": "Aerosvit Airlines" },
    "FK": { "code": "FK", "name": "Africa West" },
    "ML": { "code": "ML", "name": "African Transport Trading and Investment Company" },
    "J7": { "code": "J7", "name": "Afrijet" },
    "Q9": { "code": "Q9", "name": "Afrinat International Airlines" },
    "8U": { "code": "8U", "name": "Afriqiyah Airways" },
    "ZI": { "code": "ZI", "name": "Aigle Azur" },
    "RK": { "code": "RK", "name": "Air Afrique" },
    "AH": { "code": "AH", "name": "Air Algerie" },
    "GD": { "code": "GD", "name": "Air Alpha Greenland" },
    "A6": { "code": "A6", "name": "Air Alps Aviation" },
    "G9": { "code": "G9", "name": "Air Arabia" },
    "QN": { "code": "QN", "name": "Air Armenia" },
    "KC": { "code": "KC", "name": "Air Astana" },
    "2A": { "code": "2A", "name": "Air Astra" },
    "UU": { "code": "UU", "name": "Air Austral" },
    "BT": { "code": "BT", "name": "Air Baltic" },
    "B9": { "code": "B9", "name": "Air Bangladesh" },
    "KF": { "code": "KF", "name": "Air Belgium" },
    "AB": { "code": "AB", "name": "Air Berlin" },
    "JA": { "code": "JA", "name": "Air Bosna" },
    "BP": { "code": "BP", "name": "Air Botswana" },
    "ZB": { "code": "ZB", "name": "Air Bourbon" },
    "2J": { "code": "2J", "name": "Air Burkina" },
    "8Y": { "code": "8Y", "name": "Air Burundi" },
    "SB": { "code": "SB", "name": "Air Caledonie International" },
    "TY": { "code": "TY", "name": "Air Calédonie" },
    "QK": { "code": "QK", "name": "Air Canada Jazz" },
    "AC": { "code": "AC", "name": "Air Canada" },
    "TX": { "code": "TX", "name": "Air Caraïbes" },
    "2Q": { "code": "2Q", "name": "Air Cargo Carriers" },
    "NV": { "code": "NV", "name": "Air Central" },
    "3C": { "code": "3C", "name": "Air Chathams" },
    "CA": { "code": "CA", "name": "Air China" },
    "4F": { "code": "4F", "name": "Air City" },
    "QD": { "code": "QD", "name": "Air Class Lineas Aereas" },
    "GG": { "code": "GG", "name": "Air Comores International" },
    "AG": { "code": "AG", "name": "Air Contractors" },
    "QC": { "code": "QC", "name": "Air Corridor" },
    "HF": { "code": "HF", "name": "Air Côte d'Ivoire" },
    "H9": { "code": "H9", "name": "Air D'Ayiti" },
    "EN": { "code": "EN", "name": "Air Dolomiti" },
    "UX": { "code": "UX", "name": "Air Europa" },
    "PE": { "code": "PE", "name": "Air Europe" },
    "XT": { "code": "XT", "name": "Air Exel" },
    "OF": { "code": "OF", "name": "Air Finland" },
    "GS": { "code": "GS", "name": "Air Foyle" },
    "AF": { "code": "AF", "name": "Air France" },
    "GN": { "code": "GN", "name": "Air Gabon" },
    "DA": { "code": "DA", "name": "Air Georgia" },
    "ZX": { "code": "ZX", "name": "Air Georgian" },
    "7T": { "code": "7T", "name": "Air Glaciers" },
    "GL": { "code": "GL", "name": "Air Greenland" },
    "2U": { "code": "2U", "name": "Air Guinee Express" },
    "LD": { "code": "LD", "name": "Air Hong Kong" },
    "8C": { "code": "8C", "name": "Air Horizon" },
    "NY": { "code": "NY", "name": "Air Iceland" },
    "IX": { "code": "IX", "name": "Air India Express" },
    "AI": { "code": "AI", "name": "Air India" },
    "I9": { "code": "I9", "name": "Air Italy" },
    "VU": { "code": "VU", "name": "Air Ivoire" },
    "JM": { "code": "JM", "name": "Air Jamaica" },
    "NQ": { "code": "NQ", "name": "Air Japan" },
    "9Y": { "code": "9Y", "name": "Air Kazakhstan" },
    "4A": { "code": "4A", "name": "Air Kiribati" },
    "JS": { "code": "JS", "name": "Air Koryo" },
    "VD": { "code": "VD", "name": "Air Liberte" },
    "TT": { "code": "TT", "name": "Air Lithuania" },
    "L8": { "code": "L8", "name": "Air Luxor GB" },
    "LK": { "code": "LK", "name": "Air Luxor" },
    "NX": { "code": "NX", "name": "Air Macau" },
    "MD": { "code": "MD", "name": "Air Madagascar" },
    "NM": { "code": "NM", "name": "Air Madrid" },
    "QM": { "code": "QM", "name": "Air Malawi" },
    "KM": { "code": "KM", "name": "Air Malta" },
    "6T": { "code": "6T", "name": "Air Mandalay" },
    "CW": { "code": "CW", "name": "Air Marshall Islands" },
    "MR": { "code": "MR", "name": "Air Mauritanie" },
    "MK": { "code": "MK", "name": "Air Mauritius" },
    "DR": { "code": "DR", "name": "Air Mediterranee" },
    "ZV": { "code": "ZV", "name": "Air Midwest" },
    "MC": { "code": "MC", "name": "Air Mobility Command" },
    "9U": { "code": "9U", "name": "Air Moldova" },
    "SW": { "code": "SW", "name": "Air Namibia" },
    "NZ": { "code": "NZ", "name": "Air New Zealand" },
    "EH": { "code": "EH", "name": "Air Nippon Network Co. Ltd." },
    "EL": { "code": "EL", "name": "Air Nippon" },
    "PX": { "code": "PX", "name": "Air Niugini" },
    "4N": { "code": "4N", "name": "Air North" },
    "YW": { "code": "YW", "name": "Air Nostrum" },
    "AP": { "code": "AP", "name": "Air One" },
    "2P": { "code": "2P", "name": "Air Philippines" },
    "A7": { "code": "A7", "name": "Air Plus Comet" },
    "S2": { "code": "S2", "name": "Air Sahara" },
    "PJ": { "code": "PJ", "name": "Air Saint Pierre" },
    "20": { "code": "20", "name": "Air Salone" },
    "2O": { "code": "2O", "name": "Air Salone" },
    "EX": { "code": "EX", "name": "Air Santo Domingo" },
    "HC": { "code": "HC", "name": "Air Senegal" },
    "JU": { "code": "JU", "name": "Air Serbia" },
    "G8": { "code": "G8", "name": "Air Service Gabon" },
    "HM": { "code": "HM", "name": "Air Seychelles" },
    "BM": { "code": "BM", "name": "Air Sicilia" },
    "4D": { "code": "4D", "name": "Air Sinai" },
    "RB": { "code": "RB", "name": "Air Srpska" },
    "KY": { "code": "KY", "name": "Air Sao Tome and Principe" },
    "TN": { "code": "TN", "name": "Air Tahiti Nui" },
    "VT": { "code": "VT", "name": "Air Tahiti" },
    "TC": { "code": "TC", "name": "Air Tanzania" },
    "8T": { "code": "8T", "name": "Air Tindi" },
    "YT": { "code": "YT", "name": "Air Togo" },
    "TS": { "code": "TS", "name": "Air Transat" },
    "6U": { "code": "6U", "name": "Air Ukraine" },
    "3N": { "code": "3N", "name": "Air Urga" },
    "VL": { "code": "VL", "name": "Air VIA" },
    "NF": { "code": "NF", "name": "Air Vanuatu" },
    "6V": { "code": "6V", "name": "Air Vegas" },
    "6G": { "code": "6G", "name": "Air Wales" },
    "ZW": { "code": "ZW", "name": "Air Wisconsin" },
    "UM": { "code": "UM", "name": "Air Zimbabwe" },
    "AK": { "code": "AK", "name": "AirAsia" },
    "RU": { "code": "RU", "name": "AirBridge Cargo" },
    "ED": { "code": "ED", "name": "Airblue" },
    "R3": { "code": "R3", "name": "Aircompany Yakutia" },
    "A5": { "code": "A5", "name": "Airlinair" },
    "FO": { "code": "FO", "name": "Airlines Of Tasmania" },
    "CG": { "code": "CG", "name": "Airlines PNG" },
    "K8": { "code": "K8", "name": "Airlink Zambia" },
    "TL": { "code": "TL", "name": "Airnorth" },
    "FF": { "code": "FF", "name": "Airshop" },
    "AS": { "code": "AS", "name": "Alaska Airlines" },
    "KO": { "code": "KO", "name": "Alaska Central Express" },
    "LV": { "code": "LV", "name": "Albanian Airlines" },
    "F4": { "code": "F4", "name": "Albarka Air" },
    "D4": { "code": "D4", "name": "Alidaunia" },
    "NH": { "code": "NH", "name": "All Nippon Airways" },
    "G4": { "code": "G4", "name": "Allegiant Air" },
    "LL": { "code": "LL", "name": "Allegro" },
    "CD": { "code": "CD", "name": "Alliance Air" },
    "QQ": { "code": "QQ", "name": "Alliance Airlines" },
    "AQ": { "code": "AQ", "name": "Aloha Airlines" },
    "E8": { "code": "E8", "name": "Alpi Eagles" },
    "5A": { "code": "5A", "name": "Alpine Air Express" },
    "6R": { "code": "6R", "name": "Alrosa Mirny Air Enterprise" },
    "1A": { "code": "1A", "name": "Amadeus Global Travel Distribution" },
    "Z8": { "code": "Z8", "name": "Amaszonas" },
    "0A": { "code": "0A", "name": "Amber Air" },
    "HP": { "code": "HP", "name": "America West Airlines" },
    "AA": { "code": "AA", "name": "American Airlines" },
    "MQ": { "code": "MQ", "name": "American Eagle Airlines" },
    "M6": { "code": "M6", "name": "Amerijet International" },
    "2V": { "code": "2V", "name": "Amtrak" },
    "AN": { "code": "AN", "name": "Ansett Australia" },
    "4": { "code": "4", "name": "Antrak Air" },
    "5F": { "code": "5F", "name": "Arctic Circle Air Service" },
    "ZE": { "code": "ZE", "name": "Arcus-Air Logistic" },
    "FG": { "code": "FG", "name": "Ariana Afghan Airlines" },
    "OR": { "code": "OR", "name": "Arkefly" },
    "IZ": { "code": "IZ", "name": "Arkia Israel Airlines" },
    "U8": { "code": "U8", "name": "Armavia" },
    "MV": { "code": "MV", "name": "Armenian International Airways" },
    "JW": { "code": "JW", "name": "Arrow Air" },
    "R7": { "code": "R7", "name": "Aserca Airlines" },
    "HJ": { "code": "HJ", "name": "Asian Express Airlines" },
    "6K": { "code": "6K", "name": "Asian Spirit" },
    "OZ": { "code": "OZ", "name": "Asiana Airlines" },
    "4K": { "code": "4K", "name": "Askari Aviation" },
    "KP": { "code": "KP", "name": "ASKY" },
    "8D": { "code": "8D", "name": "Astair" },
    "ER": { "code": "ER", "name": "Astar Air Cargo" },
    "5W": { "code": "5W", "name": "Astraeus" },
    "8V": { "code": "8V", "name": "Astral Aviation" },
    "3G": { "code": "3G", "name": "Atlant-Soyuz Airlines" },
    "RC": { "code": "RC", "name": "Atlantic Airways" },
    "EV": { "code": "EV", "name": "Atlantic Southeast Airlines" },
    "TD": { "code": "TD", "name": "Atlantis European Airways" },
    "5Y": { "code": "5Y", "name": "Atlas Air" },
    "8A": { "code": "8A", "name": "Atlas Blue" },
    "KK": { "code": "KK", "name": "Atlasjet" },
    "IP": { "code": "IP", "name": "Atyrau Air Ways" },
    "IQ": { "code": "IQ", "name": "Augsburg Airways" },
    "GR": { "code": "GR", "name": "Aurigny Air Services" },
    "AU": { "code": "AU", "name": "Austral Lineas Aereas" },
    "AO": { "code": "AO", "name": "Australian Airlines" },
    "OS": { "code": "OS", "name": "Austrian Airlines" },
    "VO": { "code": "VO", "name": "Austrian Arrows" },
    "VE": { "code": "VE", "name": "Avensa" },
    "RX": { "code": "RX", "name": "Aviaexpress" },
    "EC": { "code": "EC", "name": "Avialeasing Aviation Company" },
    "AV": { "code": "AV", "name": "Avianca" },
    "Z3": { "code": "Z3", "name": "Avient Aviation" },
    "U3": { "code": "U3", "name": "Avies" },
    "G2": { "code": "G2", "name": "Avirex" },
    "V5": { "code": "V5", "name": "Avolar Aerolineas" },
    "J2": { "code": "J2", "name": "Azerbaijan Airlines" },
    "7A": { "code": "7A", "name": "Aztec Worldwide Airlines" },
    "AD": { "code": "AD", "name": "Azul" },
    "ZS": { "code": "ZS", "name": "Azzurra Air" },
    "TH": { "code": "TH", "name": "BA Connect" },
    "B4": { "code": "B4", "name": "BACH Flugbetriebsges" },
    "V9": { "code": "V9", "name": "BAL Bashkirian Airlines" },
    "8W": { "code": "8W", "name": "BAX Global" },
    "7R": { "code": "7R", "name": "BRA-Transportes Aereos" },
    "UP": { "code": "UP", "name": "Bahamasair" },
    "8Q": { "code": "8Q", "name": "Baker Aviation" },
    "LZ": { "code": "LZ", "name": "Balkan Bulgarian Airlines" },
    "QH": { "code": "QH", "name": "Bamboo Airways" },
    "PG": { "code": "PG", "name": "Bangkok Airways" },
    "8N": { "code": "8N", "name": "Barents AirLink" },
    "ID": { "code": "ID", "name": "Batik Air" },
    "OD": { "code": "OD", "name": "Batik Air Malaysia" },
    "JV": { "code": "JV", "name": "Bearskin Lake Air Service" },
    "JD": { "code": "JD", "name": "Beijing Capital Airlines" },
    "4T": { "code": "4T", "name": "Belair Airlines" },
    "B2": { "code": "B2", "name": "Belavia Belarusian Airlines" },
    "B3": { "code": "B3", "name": "Bellview Airlines" },
    "CH": { "code": "CH", "name": "Bemidji Airlines" },
    "A8": { "code": "A8", "name": "Benin Golf Air" },
    "8E": { "code": "8E", "name": "Bering Air" },
    "J8": { "code": "J8", "name": "Berjaya Air" },
    "GQ": { "code": "GQ", "name": "Big Sky Airlines" },
    "BG": { "code": "BG", "name": "Biman Bangladesh Airlines" },
    "NT": { "code": "NT", "name": "Binter Canarias" },
    "0B": { "code": "0B", "name": "Blue Air" },
    "BZ": { "code": "BZ", "name": "Blue Dart Aviation" },
    "BV": { "code": "BV", "name": "Blue Panorama Airlines" },
    "QW": { "code": "QW", "name": "Blue Wings" },
    "OB": { "code": "OB", "name": "Boliviana de Aviacion" },
    "E9": { "code": "E9", "name": "Boston-Maine Airways" },
    "BO": { "code": "BO", "name": "Bouraq Indonesia Airlines" },
    "BU": { "code": "BU", "name": "Braathens" },
    "BN": { "code": "BN", "name": "Braniff International Airways" },
    "K6": { "code": "K6", "name": "Bravo Air Congo" },
    "E6": { "code": "E6", "name": "Bringer Air Cargo Taxi Aereo" },
    "DB": { "code": "DB", "name": "Brit Air" },
    "BA": { "code": "BA", "name": "British Airways" },
    "BS": { "code": "BS", "name": "British International Helicopters" },
    "KJ": { "code": "KJ", "name": "British Mediterranean Airways" },
    "SN": { "code": "SN", "name": "Brussels Airlines" },
    "J4": { "code": "J4", "name": "Buffalo Airways" },
    "FB": { "code": "FB", "name": "Bulgaria Air" },
    "5C": { "code": "5C", "name": "CAL Cargo Air Lines" },
    "XG": { "code": "XG", "name": "Calima Aviacion" },
    "R9": { "code": "R9", "name": "Camai Air" },
    "UY": { "code": "UY", "name": "Cameroon Airlines" },
    "C6": { "code": "C6", "name": "CanJet" },
    "CP": { "code": "CP", "name": "Canadian Airlines" },
    "5T": { "code": "5T", "name": "Canadian North" },
    "9K": { "code": "9K", "name": "Cape Air" },
    "PT": { "code": "PT", "name": "Capital Cargo International Airlines" },
    "8L": { "code": "8L", "name": "Cargo Plus Aviation" },
    "2G": { "code": "2G", "name": "Cargoitalia" },
    "W8": { "code": "W8", "name": "Cargojet Airways" },
    "BW": { "code": "BW", "name": "Caribbean Airlines" },
    "V3": { "code": "V3", "name": "Carpatair" },
    "RV": { "code": "RV", "name": "Caspian Airlines" },
    "CX": { "code": "CX", "name": "Cathay Pacific" },
    "KX": { "code": "KX", "name": "Cayman Airways" },
    "5J": { "code": "5J", "name": "Cebu Pacific" },
    "5Z": { "code": "5Z", "name": "CemAir" },
    "7N": { "code": "7N", "name": "Centavia" },
    "C0": { "code": "C0", "name": "Centralwings" },
    "GM": { "code": "GM", "name": "Chair Airlines" },
    "CE": { "code": "CE", "name": "Chalair Aviation" },
    "OP": { "code": "OP", "name": "Chalk's Ocean Airways" },
    "MG": { "code": "MG", "name": "Champion Air" },
    "2Z": { "code": "2Z", "name": "Changan Airlines" },
    "S8": { "code": "S8", "name": "Chari Aviation Services" },
    "RP": { "code": "RP", "name": "Chautauqua Airlines" },
    "AW": { "code": "AW", "name": "CHC Airways" },
    "C8": { "code": "C8", "name": "Chicago Express" },
    "CI": { "code": "CI", "name": "China Airlines" },
    "CK": { "code": "CK", "name": "China Cargo Airlines" },
    "MU": { "code": "MU", "name": "China Eastern Airlines" },
    "CJ": { "code": "CJ", "name": "China Northern Airlines" },
    "WH": { "code": "WH", "name": "China Northwest Airlines" },
    "CZ": { "code": "CZ", "name": "China Southern Airlines" },
    "XO": { "code": "XO", "name": "China Xinhua Airlines" },
    "X7": { "code": "X7", "name": "Chitaavia" },
    "A2": { "code": "A2", "name": "Cielos Airlines" },
    "QI": { "code": "QI", "name": "Cimber Air" },
    "C9": { "code": "C9", "name": "Cirrus Airlines" },
    "CF": { "code": "CF", "name": "City Airline" },
    "WX": { "code": "WX", "name": "CityJet" },
    "CT": { "code": "CT", "name": "Civil Air Transport" },
    "6P": { "code": "6P", "name": "Club Air" },
    "CC": { "code": "CC", "name": "CM Airlines" },
    "BX": { "code": "BX", "name": "Coast Air" },
    "DQ": { "code": "DQ", "name": "Coastal Air" },
    "9L": { "code": "9L", "name": "Colgan Air" },
    "MN": { "code": "MN", "name": "Comair" },
    "OH": { "code": "OH", "name": "PSA Airlines" },
    "C5": { "code": "C5", "name": "CommutAir" },
    "KR": { "code": "KR", "name": "Comores Airlines" },
    "DE": { "code": "DE", "name": "Condor" },
    "6A": { "code": "6A", "name": "Consorcio Aviaxsa" },
    "V0": { "code": "V0", "name": "Conviasa" },
    "CM": { "code": "CM", "name": "Copa Airlines" },
    "SS": { "code": "SS", "name": "Corsairfly" },
    "XK": { "code": "XK", "name": "Corse-Mediterranee" },
    "F5": { "code": "F5", "name": "Cosmic Air" },
    "OU": { "code": "OU", "name": "Croatia Airlines" },
    "QE": { "code": "QE", "name": "Crossair Europe" },
    "CU": { "code": "CU", "name": "Cubana de Aviacion" },
    "CY": { "code": "CY", "name": "Cyprus Airways" },
    "YK": { "code": "YK", "name": "Cyprus Turkish Airlines" },
    "OK": { "code": "OK", "name": "Czech Airlines" },
    "WD": { "code": "WD", "name": "DAS Air Cargo" },
    "DX": { "code": "DX", "name": "DAT Danish Air Transport" },
    "ES": { "code": "ES", "name": "DHL International" },
    "L3": { "code": "L3", "name": "DHL de Guatemala" },
    "D3": { "code": "D3", "name": "Daallo Airlines" },
    "N2": { "code": "N2", "name": "Dagestan Airlines" },
    "H8": { "code": "H8", "name": "Dalavia" },
    "0D": { "code": "0D", "name": "Darwin Airline" },
    "D5": { "code": "D5", "name": "Dauair" },
    "DL": { "code": "DL", "name": "Delta Air Lines" },
    "D7": { "code": "D7", "name": "Dinar" },
    "DO": { "code": "DO", "name": "Dominicana de Aviacion" },
    "E3": { "code": "E3", "name": "Domodedovo Airlines" },
    "KA": { "code": "KA", "name": "Dragonair" },
    "KB": { "code": "KB", "name": "Druk Air" },
    "BR": { "code": "BR", "name": "EVA Air" },
    "H7": { "code": "H7", "name": "Eagle Air" },
    "B5": { "code": "B5", "name": "East African Safari Air" },
    "QU": { "code": "QU", "name": "Skyline Express Airlines" },
    "T3": { "code": "T3", "name": "Eastern Airways" },
    "DK": { "code": "DK", "name": "Eastland Air" },
    "U2": { "code": "U2", "name": "easyJet" },
    "WK": { "code": "WK", "name": "Edelweiss" },
    "MS": { "code": "MS", "name": "Egyptair" },
    "LY": { "code": "LY", "name": "El Al Israel Airlines" },
    "UZ": { "code": "UZ", "name": "El-Buraq Air Transport" },
    "1C": { "code": "1C", "name": "Electronic Data Systems" },
    "EK": { "code": "EK", "name": "Emirates" },
    "EU": { "code": "EU", "name": "Empresa Ecuatoriana De Aviacion" },
    "E0": { "code": "E0", "name": "Eos Airlines" },
    "B8": { "code": "B8", "name": "Eritrean Airlines" },
    "E7": { "code": "E7", "name": "Estafeta Carga Aerea" },
    "OV": { "code": "OV", "name": "Estonian Air" },
    "ET": { "code": "ET", "name": "Ethiopian Airlines" },
    "EY": { "code": "EY", "name": "Etihad Airways" },
    "RZ": { "code": "RZ", "name": "Euro Exec Express" },
    "UI": { "code": "UI", "name": "Eurocypria Airlines" },
    "K2": { "code": "K2", "name": "Eurolot" },
    "3W": { "code": "3W", "name": "Euromanx Airways" },
    "EA": { "code": "EA", "name": "European Air Express" },
    "QY": { "code": "QY", "name": "European Air Transport" },
    "EW": { "code": "EW", "name": "Eurowings" },
    "4Y": { "code": "4Y", "name": "Eurowings Discover" },
    "EZ": { "code": "EZ", "name": "Evergreen International Airlines" },
    "JN": { "code": "JN", "name": "Excel Airways" },
    "MB": { "code": "MB", "name": "Execair Aviation" },
    "OW": { "code": "OW", "name": "Executive Airlines" },
    "EO": { "code": "EO", "name": "Express One International" },
    "XE": { "code": "XE", "name": "ExpressJet" },
    "IH": { "code": "IH", "name": "Falcon Aviation" },
    "EF": { "code": "EF", "name": "Far Eastern Air Transport" },
    "F6": { "code": "F6", "name": "Faroejet" },
    "F3": { "code": "F3", "name": "Faso Airways" },
    "FX": { "code": "FX", "name": "Federal Express" },
    "FJ": { "code": "FJ", "name": "Fiji Airways" },
    "N8": { "code": "N8", "name": "Fika Salaama Airlines" },
    "4S": { "code": "4S", "name": "Finalair Congo" },
    "AY": { "code": "AY", "name": "Finnair" },
    "FC": { "code": "FC", "name": "Finncomm Airlines" },
    "FY": { "code": "FY", "name": "Firefly" },
    "7F": { "code": "7F", "name": "First Air" },
    "DP": { "code": "DP", "name": "First Choice Airways" },
    "8F": { "code": "8F", "name": "Fischer Air" },
    "F8": { "code": "F8", "name": "Flair Airlines" },
    "YS": { "code": "YS", "name": "Flightlink" },
    "PA": { "code": "PA", "name": "Florida Coastal Airlines" },
    "RF": { "code": "RF", "name": "Florida West International Airways" },
    "F2": { "code": "F2", "name": "Fly Air" },
    "S9": { "code": "S9", "name": "Fly Big" },
    "SH": { "code": "SH", "name": "Fly Me Sweden" },
    "FZ": { "code": "FZ", "name": "Flydubai" },
    "TE": { "code": "TE", "name": "FlyLal" },
    "LF": { "code": "LF", "name": "FlyNordic" },
    "F7": { "code": "F7", "name": "Flybaboo" },
    "BE": { "code": "BE", "name": "Flybe" },
    "W3": { "code": "W3", "name": "Flyhy Cargo Airlines" },
    "XY": { "code": "XY", "name": "Flynas" },
    "HK": { "code": "HK", "name": "Four Star Aviation / Four Star Cargo" },
    "FP": { "code": "FP", "name": "Freedom Air" },
    "SJ": { "code": "SJ", "name": "Freedom Air" },
    "BF": { "code": "BF", "name": "French Bee" },
    "F9": { "code": "F9", "name": "Frontier Airlines" },
    "FH": { "code": "FH", "name": "Futura International Airways" },
    "GT": { "code": "GT", "name": "GB Airways" },
    "7O": { "code": "7O", "name": "Galaxy Air" },
    "1G": { "code": "1G", "name": "Galileo International" },
    "GC": { "code": "GC", "name": "Gambia International Airlines" },
    "G7": { "code": "G7", "name": "Gandalf Airlines" },
    "GA": { "code": "GA", "name": "Garuda Indonesia" },
    "A9": { "code": "A9", "name": "Georgian Airways" },
    "QB": { "code": "QB", "name": "Georgian National Airlines" },
    "ST": { "code": "ST", "name": "Germania" },
    "GH": { "code": "GH", "name": "Ghana Airways" },
    "G0": { "code": "G0", "name": "Ghana International Airlines" },
    "GK": { "code": "GK", "name": "Jetstar Japan" },
    "G3": { "code": "G3", "name": "GOL" },
    "DC": { "code": "DC", "name": "Golden Air" },
    "G1": { "code": "G1", "name": "Gorkha Airlines" },
    "ZK": { "code": "ZK", "name": "Great Lakes Airlines" },
    "IJ": { "code": "IJ", "name": "Great Wall Airlines" },
    "TA": { "code": "TA", "name": "Grupo TACA" },
    "G6": { "code": "G6", "name": "Guine Bissaur Airlines" },
    "J9": { "code": "J9", "name": "Guinee Airlines" },
    "GF": { "code": "GF", "name": "Gulf Air Bahrain" },
    "GY": { "code": "GY", "name": "Guyana Airways 2000" },
    "H6": { "code": "H6", "name": "Hageland Aviation Services" },
    "H1": { "code": "H1", "name": "Hahn Air" },
    "HR": { "code": "HR", "name": "Hahn Air" },
    "X1": { "code": "X1", "name": "Hahn Air" },
    "W2": { "code": "W2", "name": "FlexFlight" },
    "GP": { "code": "GP", "name": "APG" },
    "A1": { "code": "A1", "name": "APG" },
    "HU": { "code": "HU", "name": "Hainan Airlines" },
    "1R": { "code": "1R", "name": "Hainan Phoenix Information Systems" },
    "2T": { "code": "2T", "name": "Haiti Ambassador Airlines" },
    "4R": { "code": "4R", "name": "Hamburg International" },
    "X3": { "code": "X3", "name": "Hapag-Lloyd Express (TUIfly)" },
    "HB": { "code": "HB", "name": "Harbor Airlines" },
    "HQ": { "code": "HQ", "name": "Harmony Airways" },
    "HA": { "code": "HA", "name": "Hawaiian Airlines" },
    "BH": { "code": "BH", "name": "Hawkair" },
    "HN": { "code": "HN", "name": "Heavylift Cargo Airlines" },
    "8H": { "code": "8H", "name": "Heli France" },
    "JB": { "code": "JB", "name": "Helijet" },
    "ZU": { "code": "ZU", "name": "Helios Airways" },
    "T4": { "code": "T4", "name": "Hellas Jet" },
    "HW": { "code": "HW", "name": "Hello" },
    "2L": { "code": "2L", "name": "Helvetic Airways" },
    "DU": { "code": "DU", "name": "Hemus Air" },
    "UD": { "code": "UD", "name": "Hex'Air" },
    "5K": { "code": "5K", "name": "Hi Fly" },
    "HD": { "code": "HD", "name": "Hokkaido International Airlines" },
    "H5": { "code": "H5", "name": "Hola Airlines" },
    "HX": { "code": "HX", "name": "Hong Kong Airlines" },
    "UO": { "code": "UO", "name": "Hong Kong Express" },
    "HH": { "code": "HH", "name": "Hope Air" },
    "QX": { "code": "QX", "name": "Horizon Air" },
    "H4": { "code": "H4", "name": "Heli Securitie Helicopter Airlines" },
    "II": { "code": "II", "name": "IBC Airways" },
    "C3": { "code": "C3", "name": "ICAR Airlines" },
    "1F": { "code": "1F", "name": "INFINI Travel Information" },
    "1U": { "code": "1U", "name": "ITA Software" },
    "IB": { "code": "IB", "name": "Iberia" },
    "FW": { "code": "FW", "name": "Ibex Airlines" },
    "FI": { "code": "FI", "name": "Icelandair" },
    "IK": { "code": "IK", "name": "Imair Airlines" },
    "6E": { "code": "6E", "name": "IndiGo Airlines" },
    "IC": { "code": "IC", "name": "Indian Airlines" },
    "QZ": { "code": "QZ", "name": "Indonesia AirAsia" },
    "IO": { "code": "IO", "name": "Indonesian Airlines" },
    "D6": { "code": "D6", "name": "Interair South Africa" },
    "JY": { "code": "JY", "name": "InterCaribbean Airways" },
    "RS": { "code": "RS", "name": "Intercontinental de Aviacion" },
    "6I": { "code": "6I", "name": "International Business Air" },
    "3L": { "code": "3L", "name": "Intersky" },
    "I4": { "code": "I4", "name": "Interstate Airline" },
    "IR": { "code": "IR", "name": "Iran Air" },
    "EP": { "code": "EP", "name": "Iran Aseman Airlines" },
    "IA": { "code": "IA", "name": "Iraqi Airways" },
    "IS": { "code": "IS", "name": "Island Airlines" },
    "2S": { "code": "2S", "name": "Island Express" },
    "CN": { "code": "CN", "name": "Islands Nationair" },
    "IF": { "code": "IF", "name": "Islas Airways" },
    "WC": { "code": "WC", "name": "Islena De Inversiones" },
    "6H": { "code": "6H", "name": "Israir" },
    "AZ": { "code": "AZ", "name": "ITA Airways" },
    "GI": { "code": "GI", "name": "Itek Air" },
    "XM": { "code": "XM", "name": "J-Air" },
    "JC": { "code": "JC", "name": "JAL Express" },
    "JO": { "code": "JO", "name": "JALways" },
    "1M": { "code": "1M", "name": "JSC Transport Automated Information Systems" },
    "JL": { "code": "JL", "name": "Japan Airlines" },
    "EG": { "code": "EG", "name": "Japan Asia Airways" },
    "NU": { "code": "NU", "name": "Japan Transocean Air" },
    "7C": { "code": "7C", "name": "Jeju Air" },
    "9W": { "code": "9W", "name": "Jet Airways" },
    "QJ": { "code": "QJ", "name": "Jet Airways" },
    "LS": { "code": "LS", "name": "Jet2.com" },
    "B6": { "code": "B6", "name": "JetBlue Airways" },
    "JF": { "code": "JF", "name": "Jetairfly" },
    "0J": { "code": "0J", "name": "Jetclub" },
    "JQ": { "code": "JQ", "name": "Jetstar" },
    "3K": { "code": "3K", "name": "Jetstar Asia" },
    "JX": { "code": "JX", "name": "Starlux Airlines" },
    "GX": { "code": "GX", "name": "Jetx Airlines" },
    "R5": { "code": "R5", "name": "Jordan Aviation" },
    "3J": { "code": "3J", "name": "Jubba Airways" },
    "HO": { "code": "HO", "name": "Juneyao Airlines" },
    "KD": { "code": "KD", "name": "KD Avia" },
    "WA": { "code": "WA", "name": "KLM Cityhopper" },
    "KL": { "code": "KL", "name": "KLM" },
    "K4": { "code": "K4", "name": "Kalitta Air" },
    "RQ": { "code": "RQ", "name": "Kam Air" },
    "E2": { "code": "E2", "name": "Kampuchea Airlines" },
    "V2": { "code": "V2", "name": "Karat" },
    "KV": { "code": "KV", "name": "Kavminvodyavia" },
    "M5": { "code": "M5", "name": "Kenmore Air" },
    "KQ": { "code": "KQ", "name": "Kenya Airways" },
    "IT": { "code": "IT", "name": "Kingfisher Airlines" },
    "Y9": { "code": "Y9", "name": "Kish Air" },
    "7K": { "code": "7K", "name": "Kogalymavia Air Company" },
    "8J": { "code": "8J", "name": "Komiinteravia" },
    "KE": { "code": "KE", "name": "Korean Air" },
    "7B": { "code": "7B", "name": "Krasnojarsky Airlines" },
    "K9": { "code": "K9", "name": "Krylo Airlines" },
    "GW": { "code": "GW", "name": "Kuban Airlines" },
    "KU": { "code": "KU", "name": "Kuwait Airways" },
    "GO": { "code": "GO", "name": "Kuzu Airlines Cargo" },
    "N5": { "code": "N5", "name": "Kyrgyz Airlines" },
    "R8": { "code": "R8", "name": "Kyrgyzstan Airlines" },
    "B0": { "code": "B0", "name": "La Compagnie" },
    "LR": { "code": "LR", "name": "LACSA" },
    "KG": { "code": "KG", "name": "Denver Air Connection" },
    "LA": { "code": "LA", "name": "LATAM" },
    "4M": { "code": "4M", "name": "LATAM Argentina" },
    "JJ": { "code": "JJ", "name": "LATAM Brasil" },
    "4C": { "code": "4C", "name": "LATAM Colombia" },
    "LU": { "code": "LU", "name": "LATAM Express" },
    "PZ": { "code": "PZ", "name": "LATAM Paraguay" },
    "LP": { "code": "LP", "name": "LATAM Peru" },
    "LO": { "code": "LO", "name": "LOT Polish Airlines" },
    "LT": { "code": "LT", "name": "LTU International" },
    "N6": { "code": "N6", "name": "Lagun Air" },
    "QV": { "code": "QV", "name": "Lao Airlines" },
    "L7": { "code": "L7", "name": "Laoag International Airlines" },
    "LW": { "code": "LW", "name": "Lauda Europe" },
    "OE": { "code": "OE", "name": "Laudamotion" },
    "LQ": { "code": "LQ", "name": "Lebanese Air Transport" },
    "LI": { "code": "LI", "name": "Leeward Islands Air Transport" },
    "LN": { "code": "LN", "name": "Libyan Arab Airlines" },
    "JT": { "code": "JT", "name": "Lion Air" },
    "SL": { "code": "SL", "name": "Thai Lion Air" },
    "LB": { "code": "LB", "name": "Lloyd Aereo Boliviano" },
    "LM": { "code": "LM", "name": "Loganair" },
    "GJ": { "code": "GJ", "name": "Loong Air" },
    "HE": { "code": "HE", "name": "Luftfahrtgesellschaft Walter" },
    "LH": { "code": "LH", "name": "Lufthansa" },
    "CL": { "code": "CL", "name": "Lufthansa CityLine" },
    "L1": { "code": "L1", "name": "Lufthansa Systems" },
    "DV": { "code": "DV", "name": "Lufttaxi Fluggesellschaft" },
    "L5": { "code": "L5", "name": "Lufttransport" },
    "LG": { "code": "LG", "name": "Luxair" },
    "5V": { "code": "5V", "name": "Lviv Airlines" },
    "L2": { "code": "L2", "name": "Lynden Air Cargo" },
    "IN": { "code": "IN", "name": "MAT Macedonian Airlines" },
    "OM": { "code": "OM", "name": "MIAT Mongolian Airlines" },
    "DM": { "code": "DM", "name": "Maersk" },
    "W5": { "code": "W5", "name": "Mahan Air" },
    "M2": { "code": "M2", "name": "Mahfooz Aviation" },
    "MH": { "code": "MH", "name": "Malaysia Airlines" },
    "TF": { "code": "TF", "name": "Malmo Aviation" },
    "MA": { "code": "MA", "name": "Malov Hungarian Airlines" },
    "RI": { "code": "RI", "name": "Mandala Airlines" },
    "AE": { "code": "AE", "name": "Mandarin Airlines" },
    "JE": { "code": "JE", "name": "Mango" },
    "M7": { "code": "M7", "name": "Marsland Aviation" },
    "MP": { "code": "MP", "name": "Martinair" },
    "Q4": { "code": "Q4", "name": "Mastertop Linhas Aereas" },
    "L6": { "code": "L6", "name": "Mauritania Airlines" },
    "8M": { "code": "8M", "name": "Maxair" },
    "MY": { "code": "MY", "name": "Maxjet Airways" },
    "MW": { "code": "MW", "name": "Maya Island Air" },
    "IM": { "code": "IM", "name": "Menajet" },
    "IG": { "code": "IG", "name": "Meridiana" },
    "MZ": { "code": "MZ", "name": "Merpati Nusantara Airlines" },
    "YV": { "code": "YV", "name": "Mesa Airlines" },
    "XJ": { "code": "XJ", "name": "Mesaba Airlines" },
    "7P": { "code": "7P", "name": "Metro Batavia" },
    "MX": { "code": "MX", "name": "Mexicana de Aviacion" },
    "ME": { "code": "ME", "name": "Middle East Airlines" },
    "JI": { "code": "JI", "name": "Midway Airlines" },
    "YX": { "code": "YX", "name": "Midwest Airlines" },
    "2M": { "code": "2M", "name": "Moldavian Airlines" },
    "YM": { "code": "YM", "name": "Montenegro Airlines" },
    "3R": { "code": "3R", "name": "Moskovia Airlines" },
    "M9": { "code": "M9", "name": "Motor Sich" },
    "N4": { "code": "N4", "name": "Mountain Air Company" },
    "VZ": { "code": "VZ", "name": "MyTravel Airways" },
    "UB": { "code": "UB", "name": "Myanmar National Airlines" },
    "MJ": { "code": "MJ", "name": "MyWay Airlines" },
    "9O": { "code": "9O", "name": "National Airways Cameroon" },
    "NC": { "code": "NC", "name": "National Jet Systems" },
    "ON": { "code": "ON", "name": "Nauru Air Corporation" },
    "1N": { "code": "1N", "name": "Navitaire" },
    "NO": { "code": "NO", "name": "Neos" },
    "RA": { "code": "RA", "name": "Nepal Airlines" },
    "NA": { "code": "NA", "name": "Nesma Airlines" },
    "EJ": { "code": "EJ", "name": "New England Airlines" },
    "HG": { "code": "HG", "name": "Niki" },
    "NP": { "code": "NP", "name": "Nile Air" },
    "KZ": { "code": "KZ", "name": "Nippon Cargo Airlines" },
    "DD": { "code": "DD", "name": "Nok Air" },
    "NG": { "code": "NG", "name": "Novair" },
    "JH": { "code": "JH", "name": "Nordeste Linhas Aereas Regionais" },
    "6N": { "code": "6N", "name": "Nordic Regional" },
    "N9": { "code": "N9", "name": "North Coast Aviation" },
    "U7": { "code": "U7", "name": "Northern Dene Airways" },
    "J3": { "code": "J3", "name": "Northwestern Air" },
    "DY": { "code": "DY", "name": "Norwegian" },
    "DH": { "code": "DH", "name": "Norwegian" },
    "D8": { "code": "D8", "name": "Norwegian" },
    "BJ": { "code": "BJ", "name": "Nouvel Air Tunisie" },
    "M4": { "code": "M4", "name": "Nova Airline" },
    "UQ": { "code": "UQ", "name": "O'Connor Airlines" },
    "CR": { "code": "CR", "name": "OAG" },
    "O8": { "code": "O8", "name": "Oasis Hong Kong Airlines" },
    "VC": { "code": "VC", "name": "Ocean Airlines" },
    "O6": { "code": "O6", "name": "Oceanair" },
    "O2": { "code": "O2", "name": "Oceanic Airlines" },
    "OA": { "code": "OA", "name": "Olympic Airlines" },
    "WY": { "code": "WY", "name": "Oman Air" },
    "OY": { "code": "OY", "name": "Omni Air International" },
    "N3": { "code": "N3", "name": "Omskavia Airline" },
    "1L": { "code": "1L", "name": "Open Skies Consultative Commission" },
    "R2": { "code": "R2", "name": "Orenburg Airlines" },
    "OX": { "code": "OX", "name": "Orient Thai Airlines" },
    "OL": { "code": "OL", "name": "Ostfriesische Lufttransport" },
    "OJ": { "code": "OJ", "name": "Overland Airways" },
    "O7": { "code": "O7", "name": "Ozjet Airlines" },
    "PV": { "code": "PV", "name": "PAN Air" },
    "9Q": { "code": "9Q", "name": "PB Air" },
    "PU": { "code": "PU", "name": "PLUNA" },
    "U4": { "code": "U4", "name": "PMTair" },
    "Y5": { "code": "Y5", "name": "Pace Airlines" },
    "BL": { "code": "BL", "name": "Pacific Airlines" },
    "DJ": { "code": "DJ", "name": "Pacific Blue" },
    "8P": { "code": "8P", "name": "Pacific Coastal Airline" },
    "Q8": { "code": "Q8", "name": "Pacific East Asia Cargo Airlines" },
    "PK": { "code": "PK", "name": "Pakistan International Airlines" },
    "PF": { "code": "PF", "name": "Palestinian Airlines" },
    "NR": { "code": "NR", "name": "Pamir Airways" },
    "PN": { "code": "PN", "name": "Pan American Airways" },
    "PQ": { "code": "PQ", "name": "Panafrican Airways" },
    "P8": { "code": "P8", "name": "Pantanal Linhas Areas" },
    "ZP": { "code": "ZP", "name": "Paranair" },
    "I7": { "code": "I7", "name": "Paramount Airways" },
    "MM": { "code": "MM", "name": "Peach Aviation" },
    "PC": { "code": "PC", "name": "Pegasus" },
    "KS": { "code": "KS", "name": "Peninsula Airways" },
    "PR": { "code": "PR", "name": "Philippine Airlines" },
    "9R": { "code": "9R", "name": "Phuket Air" },
    "PI": { "code": "PI", "name": "Piedmont Airlines (1948-1989)" },
    "9E": { "code": "9E", "name": "Pinnacle Airlines" },
    "PO": { "code": "PO", "name": "Polar Air Cargo" },
    "PH": { "code": "PH", "name": "Polynesian Airlines" },
    "PD": { "code": "PD", "name": "Porter Airlines" },
    "NI": { "code": "NI", "name": "Portugalia" },
    "BK": { "code": "BK", "name": "Potomac Air" },
    "PW": { "code": "PW", "name": "Precision Air" },
    "TO": { "code": "TO", "name": "President Airlines" },
    "FE": { "code": "FE", "name": "Primaris Airlines" },
    "-": { "code": "-", "name": "Private flight" },
    "P0": { "code": "P0", "name": "Proflight Commuter Services" },
    "FV": { "code": "FV", "name": "Pulkovo Aviation Enterprise" },
    "QF": { "code": "QF", "name": "Qantas" },
    "QR": { "code": "QR", "name": "Qatar Airways" },
    "R6": { "code": "R6", "name": "RACSA" },
    "1D": { "code": "1D", "name": "Radixx Solutions International" },
    "V4": { "code": "V4", "name": "Reem Air" },
    "FN": { "code": "FN", "name": "Fastjet Zimbabwe" },
    "ZL": { "code": "ZL", "name": "Regional Express" },
    "RW": { "code": "RW", "name": "Republic Airlines" },
    "RH": { "code": "RH", "name": "Republic Express Airlines" },
    "C7": { "code": "C7", "name": "Rico Linhas Aéreas" },
    "R4": { "code": "R4", "name": "Rossiya" },
    "GZ": { "code": "GZ", "name": "Rotation International Air Services" },
    "RR": { "code": "RR", "name": "Royal Air Force" },
    "AT": { "code": "AT", "name": "Royal Air Maroc" },
    "R0": { "code": "R0", "name": "Royal Airlines" },
    "BI": { "code": "BI", "name": "Royal Brunei Airlines" },
    "RJ": { "code": "RJ", "name": "Royal Jordanian" },
    "RL": { "code": "RL", "name": "Royal Phnom Penh Airways" },
    "WR": { "code": "WR", "name": "Royal Tongan Airlines" },
    "P7": { "code": "P7", "name": "Russian Sky Airlines" },
    "WB": { "code": "WB", "name": "RwandAir" },
    "RD": { "code": "RD", "name": "Ryan International Airlines" },
    "FR": { "code": "FR", "name": "Ryanair" },
    "S7": { "code": "S7", "name": "S7 Airlines" },
    "SP": { "code": "SP", "name": "SATA Air Acores" },
    "S4": { "code": "S4", "name": "SATA International" },
    "XS": { "code": "XS", "name": "SITA" },
    "2C": { "code": "2C", "name": "SNCF" },
    "1Z": { "code": "1Z", "name": "Sabre Pacific" },
    "1S": { "code": "1S", "name": "Sabre" },
    "FA": { "code": "FA", "name": "Safair" },
    "E5": { "code": "E5", "name": "Samara Airlines" },
    "S3": { "code": "S3", "name": "Santa Barbara Airlines" },
    "6W": { "code": "6W", "name": "Saratov Aviation Division" },
    "HZ": { "code": "HZ", "name": "Sat Airlines" },
    "SV": { "code": "SV", "name": "Saudia" },
    "SK": { "code": "SK", "name": "Scandinavian Airlines" },
    "TR": { "code": "TR", "name": "Scoot" },
    "8S": { "code": "8S", "name": "Scorpio Aviation" },
    "BB": { "code": "BB", "name": "Seaborne Airlines" },
    "FS": { "code": "FS", "name": "Servicios de Transportes Aéreos Fueguinos" },
    "NL": { "code": "NL", "name": "Shaheen Air International" },
    "SC": { "code": "SC", "name": "Shandong Airlines" },
    "FM": { "code": "FM", "name": "Shanghai Airlines" },
    "ZH": { "code": "ZH", "name": "Shenzhen Airlines" },
    "S5": { "code": "S5", "name": "Shuttle America" },
    "5M": { "code": "5M", "name": "Sibaviatrans" },
    "3U": { "code": "3U", "name": "Sichuan Airlines" },
    "FT": { "code": "FT", "name": "Siem Reap Airways" },
    "LJ": { "code": "LJ", "name": "Sierra National Airlines" },
    "MI": { "code": "MI", "name": "SilkAir" },
    "SQ": { "code": "SQ", "name": "Singapore Airlines" },
    "1H": { "code": "1H", "name": "Siren-Travel" },
    "1Q": { "code": "1Q", "name": "Sirena" },
    "R1": { "code": "R1", "name": "Sirin" },
    "H2": { "code": "H2", "name": "Sky Airline" },
    "XW": { "code": "XW", "name": "Sky Express" },
    "I6": { "code": "I6", "name": "Sky Eyes" },
    "NE": { "code": "NE", "name": "SkyEurope" },
    "OO": { "code": "OO", "name": "SkyWest" },
    "SX": { "code": "SX", "name": "Skybus Airlines" },
    "BC": { "code": "BC", "name": "Skymark Airlines" },
    "SI": { "code": "SI", "name": "Skynet Airlines" },
    "6J": { "code": "6J", "name": "Skynet Asia Airways" },
    "5G": { "code": "5G", "name": "Skyservice Airlines" },
    "AL": { "code": "AL", "name": "Skywalk Airlines" },
    "JZ": { "code": "JZ", "name": "Skyways Express" },
    "S0": { "code": "S0", "name": "Slok Air Gambia" },
    "6Q": { "code": "6Q", "name": "Slovak Airlines" },
    "QS": { "code": "QS", "name": "Smartwings" },
    "IE": { "code": "IE", "name": "Solomon Airlines" },
    "SO": { "code": "SO", "name": "Sosoliso Airlines" },
    "SA": { "code": "SA", "name": "South African Airways" },
    "DG": { "code": "DG", "name": "South East Asian Airlines" },
    "9X": { "code": "9X", "name": "Southern Airways Express" },
    "1K": { "code": "1K", "name": "Southern Cross Distribution" },
    "A4": { "code": "A4", "name": "Southern Winds Airlines" },
    "WN": { "code": "WN", "name": "Southwest Airlines" },
    "JK": { "code": "JK", "name": "Spanair" },
    "SG": { "code": "SG", "name": "SpiceJet" },
    "NK": { "code": "NK", "name": "Spirit Airlines" },
    "9S": { "code": "9S", "name": "Spring Airlines" },
    "UL": { "code": "UL", "name": "SriLankan Airlines" },
    "S6": { "code": "S6", "name": "Star Air" },
    "7G": { "code": "7G", "name": "Star Flyer" },
    "NB": { "code": "NB", "name": "Sterling Airlines" },
    "SD": { "code": "SD", "name": "Sudan Airways" },
    "SY": { "code": "SY", "name": "Sun Country Airlines" },
    "7L": { "code": "7L", "name": "Sun D'Or" },
    "XQ": { "code": "XQ", "name": "SunExpress" },
    "CQ": { "code": "CQ", "name": "Sunshine Express Airlines" },
    "WG": { "code": "WG", "name": "Sunwing Airlines" },
    "PY": { "code": "PY", "name": "Surinam Airways" },
    "WV": { "code": "WV", "name": "Swe Fly" },
    "SM": { "code": "SM", "name": "Swedline Express" },
    "LX": { "code": "LX", "name": "SWISS" },
    "SR": { "code": "SR", "name": "Swissair" },
    "DT": { "code": "DT", "name": "TAAG Angola Airlines" },
    "VR": { "code": "VR", "name": "TACV" },
    "5U": { "code": "5U", "name": "TAG" },
    "EQ": { "code": "EQ", "name": "TAME" },
    "QT": { "code": "QT", "name": "TAMPA" },
    "TP": { "code": "TP", "name": "TAP Portugal" },
    "3V": { "code": "3V", "name": "TNT Airways" },
    "8R": { "code": "8R", "name": "TRIP Linhas Aéreas" },
    "TB": { "code": "TB", "name": "TUI Airlines Belgium" },
    "6B": { "code": "6B", "name": "TUIfly Nordic" },
    "TQ": { "code": "TQ", "name": "Tandem Aero" },
    "RO": { "code": "RO", "name": "TAROM" },
    "SF": { "code": "SF", "name": "Tassili Airlines" },
    "U9": { "code": "U9", "name": "Tatarstan Airlines" },
    "T6": { "code": "T6", "name": "Tavrey Airlines" },
    "L9": { "code": "L9", "name": "Teamline Air" },
    "T2": { "code": "T2", "name": "Thai Air Cargo" },
    "FD": { "code": "FD", "name": "Thai AirAsia" },
    "TG": { "code": "TG", "name": "Thai Airways" },
    "WE": { "code": "WE", "name": "Thai Smile" },
    "9I": { "code": "9I", "name": "Thai Sky Airlines" },
    "T9": { "code": "T9", "name": "Thai Star Airlines" },
    "2H": { "code": "2H", "name": "Thalys" },
    "FQ": { "code": "FQ", "name": "Thomas Cook Airlines" },
    "MT": { "code": "MT", "name": "Thomas Cook Airlines" },
    "BY": { "code": "BY", "name": "Thomsonfly" },
    "3P": { "code": "3P", "name": "Tiara Air" },
    "ZT": { "code": "ZT", "name": "Titan Airways" },
    "TI": { "code": "TI", "name": "Tol-Air Services" },
    "AX": { "code": "AX", "name": "Trans States Airlines" },
    "TW": { "code": "TW", "name": "T'way Air" },
    "GE": { "code": "GE", "name": "TransAsia Airways" },
    "UN": { "code": "UN", "name": "Transaero Airlines" },
    "HV": { "code": "HV", "name": "Transavia Holland" },
    "8B": { "code": "8B", "name": "TransNusa" },
    "9T": { "code": "9T", "name": "Transwest Air" },
    "1E": { "code": "1E", "name": "Travelsky Technology" },
    "PM": { "code": "PM", "name": "Tropic Air" },
    "UG": { "code": "UG", "name": "Tuninter" },
    "TU": { "code": "TU", "name": "Tunisair" },
    "3T": { "code": "3T", "name": "Turan Air" },
    "TK": { "code": "TK", "name": "Turkish Airlines" },
    "T5": { "code": "T5", "name": "Turkmenistan Airlines" },
    "T7": { "code": "T7", "name": "Twin Jet" },
    "UR": { "code": "UR", "name": "Uganda Airlines" },
    "UF": { "code": "UF", "name": "UM Airlines" },
    "UH": { "code": "UH", "name": "US Helicopter Corporation" },
    "U5": { "code": "U5", "name": "USA3000 Airlines" },
    "UT": { "code": "UT", "name": "UTair Aviation" },
    "PS": { "code": "PS", "name": "Ukranian International Airlines" },
    "6Z": { "code": "6Z", "name": "Ukrainian Cargo Airways" },
    "UE": { "code": "UE", "name": "Ultimate Air Shuttle" },
    "UA": { "code": "UA", "name": "United Airlines" },
    "5X": { "code": "5X", "name": "United Parcel Service" },
    "U6": { "code": "U6", "name": "Ural Airlines" },
    "HY": { "code": "HY", "name": "Uzbekistan Airways" },
    "VA": { "code": "VA", "name": "Virgin Australia" },
    "VP": { "code": "VP", "name": "VASP" },
    "2R": { "code": "2R", "name": "VIA Rail Canada" },
    "NN": { "code": "NN", "name": "VIM Airlines" },
    "VG": { "code": "VG", "name": "VLM Airlines" },
    "RG": { "code": "RG", "name": "VRG Linhas Aereas" },
    "VF": { "code": "VF", "name": "Valuair" },
    "LC": { "code": "LC", "name": "Varig Logistica" },
    "VM": { "code": "VM", "name": "Viaggio Air" },
    "VJ": { "code": "VJ", "name": "VietJet Air" },
    "VN": { "code": "VN", "name": "Vietnam Airlines" },
    "VS": { "code": "VS", "name": "Virgin Atlantic" },
    "TV": { "code": "TV", "name": "Virgin Express" },
    "VK": { "code": "VK", "name": "Virgin Nigeria Airways" },
    "UK": { "code": "UK", "name": "Vistara" },
    "VB": { "code": "VB", "name": "VivaAerobus" },
    "XF": { "code": "XF", "name": "Vladivostok Air" },
    "Y4": { "code": "Y4", "name": "Volaris" },
    "VI": { "code": "VI", "name": "Volga-Dnepr Airlines" },
    "V7": { "code": "V7", "name": "Volotea" },
    "VY": { "code": "VY", "name": "Vueling" },
    "7W": { "code": "7W", "name": "Wayraperú" },
    "WJ": { "code": "WJ", "name": "WebJet Linhas Aereas" },
    "2W": { "code": "2W", "name": "Welcome Air" },
    "8O": { "code": "8O", "name": "West Coast Air" },
    "WS": { "code": "WS", "name": "WestJet" },
    "WF": { "code": "WF", "name": "Wideroe" },
    "IV": { "code": "IV", "name": "Wind Jet" },
    "1W": { "code": "1W", "name": "Wings Air" },
    "W6": { "code": "W6", "name": "Wizz Air" },
    "WO": { "code": "WO", "name": "World Airways" },
    "1P": { "code": "1P", "name": "Worldspan" },
    "SE": { "code": "SE", "name": "XL Airways France" },
    "MF": { "code": "MF", "name": "Xiamen Airlines" },
    "XP": { "code": "XP", "name": "Xtra Airways" },
    "YL": { "code": "YL", "name": "Yamal Airlines" },
    "Y8": { "code": "Y8", "name": "Yangtze River Express" },
    "Y0": { "code": "Y0", "name": "Yellow Air Taxi" },
    "IY": { "code": "IY", "name": "Yemenia" },
    "3Q": { "code": "3Q", "name": "Yunnan Airlines" },
    "2N": { "code": "2N", "name": "Yuzhmashavia" },
    "Q3": { "code": "Q3", "name": "Zambian Airways" },
    "C4": { "code": "C4", "name": "Zimex Aviation" },
    "ZG": { "code": "ZG", "name": "ZIPAIR Tokyo" },
    "9B": { "code": "9B", "name": "Train Segment" },
    "Z4": { "code": "Z4", "name": "Zoom Airlines" },
    "BD": { "code": "BD", "name": "bmi" },
    "WW": { "code": "WW", "name": "bmibaby" },
    "DI": { "code": "DI", "name": "dba" }
}

export default airlines