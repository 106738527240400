import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import moment from "moment"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { Locale, USDCurrencyOptions } from "utils/constants"

import { Actions as TransferActions } from 'redux/features/banking/transfers'

import Spinner from "components/common/Spinner"

import styles from "styles/styles"

const detailVariants = {
    open: { height: 'auto', opacity: 1 },
    closed: { height: 0, opacity: 0 },
}

type TransferActivityItemProps = {
    transfer: any,
    index: number
}

const TransferActivityItem = (props: TransferActivityItemProps) => {
    const dispatch = useDispatch();
    const banking = useSelector((state: any) => state.banking)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const cancelTransferIsLoading = useSelector((state: any) => state.transfers.cancelTransfer.submitting)

    const [isOpen, setIsOpen] = useState(false)

    const toggleIsOpen = () => {
        transfer.canBeCancelled && setIsOpen(!isOpen)
    }

    const cancelTransfer = (ev: React.MouseEvent<HTMLDivElement>) => {
        ev.stopPropagation();
        dispatch(TransferActions.cancelTransfer({
            transferId: transfer.uuid,
            cardAccountUuid: banking.account.uuid,
        }))
    }    

    const { transfer, index } = props;

    return !isMobile ? <Row onClick={toggleIsOpen} $expanded={isOpen} key={`transfer-item:${index}`}>
        <Cell>
            <Detail>{moment(transfer.requestedExecutionDate).format('MMM D, YYYY')}</Detail>
        </Cell>
        <Cell>
            <Detail><AccountName>{transfer.source.name}</AccountName> {!!transfer.source.lastFour && <PurpleText>...{transfer.source.lastFour}</PurpleText>}</Detail>
        </Cell>
        <Cell>
            <Detail><AccountName>{transfer.destination.name}</AccountName> {!!transfer.destination.lastFour && <PurpleText>...{transfer.destination.lastFour}</PurpleText>}</Detail>
        </Cell>
        <AmountCell status={transfer.status} transferDirection={transfer.direction}>
            <Detail>{transfer.direction === 'inbound' ? '+' : '-'}{transfer.amount.toLocaleString(Locale.English, USDCurrencyOptions)}</Detail>
        </AmountCell>
        <Cell>{transfer.type}</Cell>
        <Cell>
            <StatusMessage transferStatus={transfer.status}>{transfer.status}</StatusMessage>
            <AnimatePresence mode='wait'>
                {isOpen && <ExpandedDetailContainer variants={detailVariants} initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0, marginTop: 0}} animate={{opacity: 1, height: '70px'}}>
                    {cancelTransferIsLoading ? <SpinnerContainer><Spinner/></SpinnerContainer> : <CancelButton onClick={cancelTransfer}>Cancel</CancelButton>}
                </ExpandedDetailContainer>}
            </AnimatePresence>
        </Cell>
    </Row> : <MobileRow>
        <MobileDetail>
            <div>{moment(transfer.requestedExecutionDate).format('MMM D, YYYY')}</div>
            <MobileAccountNameContainer>
                <strong>From:</strong> <MobileAccountName>{transfer.source.name}</MobileAccountName> {!!transfer.source.lastFour && <PurpleText>...{transfer.source.lastFour}</PurpleText>}
            </MobileAccountNameContainer>
            <MobileAccountNameContainer>
                <strong>To:</strong> <MobileAccountName>{transfer.destination.name}</MobileAccountName> {!!transfer.destination.lastFour && <PurpleText>...{transfer.destination.lastFour}</PurpleText>}
            </MobileAccountNameContainer>
        </MobileDetail>
        <MobileDetail>
            <AmountCell status={transfer.status} transferDirection={transfer.direction}>{transfer.direction === 'inbound' ? '+' : '-'}{transfer.amount.toLocaleString(Locale.English, USDCurrencyOptions)}</AmountCell>
            <div>{transfer.type}</div>
            <StatusMessage transferStatus={transfer.status}>{transfer.status}</StatusMessage>
        </MobileDetail>
    </MobileRow>
}

const MobileAccountNameContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const MobileAccountName = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
`

const SpinnerContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
`

const ExpandedDetailContainer = styled(motion.div)`
    height: 70px;
    display: flex;
    align-items: center;
`

const CancelButton = styled.div`
    color: red;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const Cell = styled.td`
    padding-right: ${styles.Spacing.XS};
    overflow: hidden;
    vertical-align: unset;
    &:first-child {
        padding-left: ${styles.Spacing.XS};
    }
`

type RowProps = {
    $expanded: boolean
}

const Row = styled.tr<RowProps>`
    height: ${styles.Spacing.L};
    border-bottom: 1px solid #D7D7D7;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    align-items: center;
    cursor: pointer;
    ${props => props.$expanded && `background-color: rgba(124, 61, 118, 0.1);`}
`

const AccountName = styled.div`
    width: min-content;
    overflow: hidden;
    text-overflow: ellipsis;
`

const PurpleText = styled.span`
    color: ${styles.Color.TaekusPurple};
    width: min-content;
`

type StatusMessageProps = {
    transferStatus: string;
}

const StatusMessage = styled.div<StatusMessageProps>`
    width: min-content;
    height: ${styles.Spacing.L};
    display: flex;
    align-items: center;
`

type AmountCellProps = {
    transferDirection: string;
    status: string;
}

const AmountCell = styled.td<AmountCellProps>`
    color: ${props => props.status === 'Cancelled' ? 'grey' : (props.transferDirection === 'inbound' ? 'green' : 'red')};
    height: 100%;
    vertical-align: unset;
`

const Detail = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`

const MobileDetail = styled.div`
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
        max-width: 30%;
        display: flex;
        flex-direction: column;
        align-items: end;
    }
`

const MobileRow = styled.tr`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D7D7D7;
    min-height: ${styles.Spacing.L};
    padding: ${styles.Spacing.S} 0;
`

export default TransferActivityItem