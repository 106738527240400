import moment from 'moment'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { motion, AnimatePresence } from "framer-motion"
import styled from 'styled-components'

import { useIsFirstRender } from 'hooks/useIsFirstRender'

import { CardAccount, CardAccountCategory } from 'types/CardAccount'

import { AppPath } from 'components/appRouter/constants'
import Button from 'components/common/Button'
import Footer from 'components/common/Footer'
import { defaultTransactionFilterOptions, TransactionFilterOptions } from 'components/common/Transactions/constants'
import Navigation, { NavColor } from 'components/navbar/Navigation'

import { fetchTransactions } from 'components/pages/Home/homeSlice'
import TransactionTable from 'components/pages/Home/TransactionTable'

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles'

import { ReactComponent as ArrowLeftIcon } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/ArrowRight.svg";
import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";

const messages = {
    AddFunds: 'Add Funds',
    PayBill: 'Pay Bill',
    TransactionsTitle: 'Transactions',
}

const getAnimatedCaretMotionProps = (isCaretHovered: boolean) => ({
    initial: { y: 9 },
    animate: { y: [9, -9, 9] },
    transition: { 
        ease: 'easeInOut',
        repeat: isCaretHovered ? 0 : Infinity,
        duration: isCaretHovered ? 1 : 4
    },
})

const Home = () => {
    // Redux
    const dispatch = useDispatch();
    // REPLACE ANY TYPING WITH TYPED REDUX STATE
    const transactions = useSelector((state: any) => state.home.transactions)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const isMoreTransactions = useSelector((state: any) => state.home.isMoreTransactions)
    const didTransactionFailParsing = useSelector((state: any) => state.home.didTransactionFailParsing)
    const currentUser = useSelector((state: any) => state.currentUser)
    const banking = useSelector((state: any) => state.banking)
    const isLoadingTransactions = useSelector((state: any) => state.home.isLoadingTransactions)
    const defaultLoadedTransactionsAccountUuid = useSelector((state: any) => state.home.defaultLoadedTransactionsAccountUuid)

    // Local state
    const [transactionFilterOptions, setTransactionFilterOptions] = useState<TransactionFilterOptions>(defaultTransactionFilterOptions)
    const [transactionsPage, setTransactionsPage] = useState(0)
    const [isCaretHovered, setIsCaretHovered] = useState(false)
    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false)
    const [isForegroundLoaded, setIsForegroundLoaded] = useState(false)

    const isFirstRender = useIsFirstRender()

    const containerRef = useRef(null);
    const parallaxBackgroundRef = useRef(null)
    const parallaxForegroundRef = useRef(null)
    const transactionsRef = useRef(null);
    const isLoading = isLoadingTransactions || banking.isLoading
    const lastAccessedCategory = useMemo(() => {
        return currentUser.currentUser.cardAccounts.find(
          (account: CardAccount) => account.uuid === banking.account.uuid
        )?.categoryType || localStorage.getItem('recentAccountCategory');
      }, [currentUser.currentUser.cardAccounts, banking.account.uuid, banking.account.isLoading]);
    const areImagesLoaded = isBackgroundLoaded && isForegroundLoaded
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const goToTransactions = () => {
        (containerRef.current as any).scrollTo!({ top: window.innerHeight * 0.95, behavior: 'smooth' })
    }

    const nextTransactionsPage = () => {
        setTransactionsPage(transactionsPage + 1)
    }

    const prevTransactionsPage = () => {
        setTransactionsPage(transactionsPage - 1)
    }

    // Fetch transactions whenever the user changes transaction pages
    useEffect(() => {
        const isParamsValid = banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate

        if (!isFirstRender && isParamsValid) { 
            // page number * 100 transactions per page
            dispatch(fetchTransactions(banking.account.uuid, transactionsPage * 100, transactionFilterOptions))
        }
    }, [transactionsPage, dispatch]) // eslint-disable-line

    // Fetch transactions when transaction filters change, and reset transaction page to zero
    useEffect(() => {
        const isParamsValid = banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate

        if (!isFirstRender && isParamsValid) { 
            setTransactionsPage(0);
            dispatch(fetchTransactions(banking.account.uuid, 0, transactionFilterOptions))
        }
    }, [transactionFilterOptions, dispatch]) // eslint-disable-line

    // Fetch transactions when banking account/page loads
    useEffect(() => {
        if (banking.account.uuid && defaultLoadedTransactionsAccountUuid !== banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate) {
            dispatch(fetchTransactions(banking.account.uuid, 0, transactionFilterOptions, true))
        }
    }, [banking.account.uuid, dispatch]) // eslint-disable-line

    const updateScrolledValue = () => {
        if (parallaxBackgroundRef.current) {
            (parallaxBackgroundRef.current as any).style.backgroundPositionY = `${Math.max((containerRef.current as any)?.scrollTop, 0) / 2}px`;
        }
        if (parallaxForegroundRef.current) {
            (parallaxForegroundRef.current as any).style.backgroundPositionY = `${Math.max((containerRef.current as any)?.scrollTop, 0) / 4}px`;
        }
    }

    useEffect(() => {
        (containerRef.current as any)?.addEventListener("scroll", updateScrolledValue);
    }, [])

    const getImageSources = () => {
        switch (lastAccessedCategory) {
            case CardAccountCategory.Business:
                return ["https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/businessHero.jpeg", ""]
            case CardAccountCategory.Corporate:
                return ["https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/corporateHero.jpeg", ""]
            case CardAccountCategory.Consumer:
            default:
                return [
                    "https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/home.jpg",
                    "https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/homeForeground.png"
                ]
        }
    }

    const loadBackgroundImage = () => {
        const bgImage = new Image();
        const fgImage = new Image();

        
        bgImage.onload = () => {
            setTimeout(() => {
                if (parallaxBackgroundRef?.current) {
                    (parallaxBackgroundRef?.current as any).style.backgroundImage = `url(${bgImage.src})`;
                }
            }, 400)
            setTimeout(() => { 
                setIsBackgroundLoaded(true)
            }, 800);
        }
        bgImage.onerror = () => {
            (parallaxBackgroundRef?.current as any).style.backgroundImage = `url()`;
            setIsBackgroundLoaded(true)
        }

        fgImage.onload = () => {
            setTimeout(() => {
                if (parallaxForegroundRef?.current) {
                    (parallaxForegroundRef?.current as any).style.backgroundImage = `url(${fgImage.src})`;
                }
            }, 400)
            setTimeout(() => { 
                setIsForegroundLoaded(true)
            }, 800);
        }
        fgImage.onerror = () => {
            (parallaxForegroundRef?.current as any).style.backgroundImage = `none`;
            setIsForegroundLoaded(true)
        }

        const [ bgSrc, fgSrc ] = getImageSources()

        bgImage.src = bgSrc;
        fgImage.src = fgSrc;
    }

    useEffect(() => {
        if ((isFirstRender ? !!banking.account.uuid : currentUser.isFetching === false)) {
            setIsBackgroundLoaded(false)
            setIsForegroundLoaded(false)
            loadBackgroundImage()
        }
    }, [currentUser.isFetching, banking.account.uuid]) // eslint-disable-line

    let greetingBalance = ""
    let remainingStatementBalance = ""
    let availableCredit = ""
    let dueDate = ""

    const accountType = banking.account.accountType

    if (accountType === "PREPAID") {
        greetingBalance = banking.account.balance.availableBalance ? formatter.format(banking.account.balance.availableBalance) : ''
    }
    else if (accountType === "CREDIT") {
        greetingBalance = banking.account.balance.currentBalance ? formatter.format(banking.account.balance.currentBalance) : ''
        remainingStatementBalance = banking.account.balance.remainingStatementBalance ? formatter.format(banking.account.balance.remainingStatementBalance) : ''
        availableCredit = banking.account.balance.availableCredit ? formatter.format(banking.account.balance.availableCredit) : ''

        const paymentDueDay = banking.account.paymentDueDate
        const currentDay = moment().date()
        const momentDueDate = moment()

        if (currentDay < paymentDueDay){
            dueDate = momentDueDate.date(paymentDueDay).format("MMM Do")
        }
        else if (currentDay > paymentDueDay) {
            dueDate = momentDueDate.date(paymentDueDay).add(1, 'months').format("MMM Do")
        }
        else {
            dueDate = "Today"
        }
    }

    return <Container ref={containerRef}>
        <Main lastCategory={lastAccessedCategory}>
            <AnimatePresence>
                <ParallaxImage 
                    key='background'
                    isImageLoaded={areImagesLoaded}
                    ref={parallaxBackgroundRef}
                />
                <ParallaxImage 
                    key='foreground'
                    isImageLoaded={areImagesLoaded}
                    ref={parallaxForegroundRef}
                />
            </AnimatePresence>
            <MainOverlay>
                <Navigation color={NavColor.White}/>
                <Content>
                    <GreetingContainer>
                        <AnimatePresence>
                            {(!banking.isLoading && !currentUser.isFetching) && <motion.div
                                initial={{opacity: 0, x: isMobile ? 0 : 100}}
                                exit={{opacity: 0, x: isMobile ? 0 : 100}}
                                animate={{opacity: 1, x: 0}}
                                transition={{duration: 0.7}}
                            >
                                <Greeting>
                                    {`Hi ${currentUser.currentUser.firstName},
                                    your current balance is
                                    ${greetingBalance} + ${currentUser.currentUser.numPoints ? currentUser.currentUser.numPoints?.toLocaleString() : ''} Points`}
                                </Greeting>

                                { (accountType === "CREDIT") && <CreditInfo>Your statement balance of {remainingStatementBalance} is due on {dueDate}. Your available credit is {availableCredit}</CreditInfo>}
                            </motion.div>}
                        </AnimatePresence>
                    </GreetingContainer>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5, delay: 0.6}}
                        key='fundingButton'
                    >
                        <FundingLink to={AppPath.Funding}>
                            {(accountType === "CREDIT") ? <Button label={messages.PayBill} /> : <Button label={messages.AddFunds} />
                            }
                        </FundingLink>
                    </motion.div>
                    <TransactionsLink>
                        <TransactionsLinkLabel
                            style={{ opacity: isCaretHovered ? 1 : 0 }}
                            {...fadeInOutMotionProps}
                        >
                            Transactions
                        </TransactionsLinkLabel>
                        <CaretContainer
                            {...getAnimatedCaretMotionProps(isCaretHovered)}
                            onMouseEnter={() => { setIsCaretHovered(true) }}
                            onMouseLeave={() => { setIsCaretHovered(false) }}
                            onClick={goToTransactions}
                        >
                            <StyledCaretDown/>
                        </CaretContainer>
                    </TransactionsLink>
                </Content>
            </MainOverlay>
        </Main>
        <Transactions ref={transactionsRef}>
            <Title>{messages.TransactionsTitle}</Title>
            <AnimatePresence mode='wait'>
                <TableContainer key='transactionsTable' {...fadeInOutMotionProps}>
                    <TransactionTable
                        didTransactionFailParsing={didTransactionFailParsing}
                        updateFilterOptions={setTransactionFilterOptions}
                        filterOptions={transactionFilterOptions}
                        isLoading={isLoading}
                        transactions={transactions}
                    />
                    <CenteredFlex>
                        <PaginationButton isVisible={!isLoading && transactionsPage > 0} onClick={prevTransactionsPage}>
                            <ArrowLeftIcon />
                        </PaginationButton>
                        <PaginationButton isVisible={!isLoading && isMoreTransactions} onClick={nextTransactionsPage}>
                            <ArrowRightIcon />    
                        </PaginationButton>
                    </CenteredFlex>
                </TableContainer>
            </AnimatePresence>
        </Transactions>
        <Footer/>
    </Container>
}

const MainOverlay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 3;
`

type ParallaxImageProps = {
    isImageLoaded: boolean,
}

const ParallaxImage = styled(motion.div)<ParallaxImageProps>`
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    z-index: 1;
    -moz-transition: opacity .2s ease-in;
    -o-transition: opacity .2s ease-in;
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
    opacity: ${props => props.isImageLoaded ? 1 : 0};
`

const TransactionsLinkLabel = styled.div`
    text-align: center;
    white-space: nowrap;
    user-select: none;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const TransactionsLink = styled.div`
    color: rgba(255,255,255,0.8);
    position: absolute;
    left: calc(50vw - 20px);
    bottom: 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
`

const StyledCaretDown = styled(CaretDown)`
    width: 45%;
    height: auto;
    fill: inherit;
    padding-top: 4px;
`

const CaretContainer = styled(motion.div)`
    cursor: pointer;
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.S};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${styles.Animation.transitionStyles}
    border: 1px solid transparent;
    fill: rgba(255,255,255,0.6);
    &:hover {
        fill: rgb(255,255,255);
    }
`

type PaginationButtonProps = {
    isVisible: boolean
}

const PaginationButton = styled.div<PaginationButtonProps>`
    display: ${props => props.isVisible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    font-size: ${styles.Spacing.S};
    line-height: ${styles.Spacing.XS};
    margin: 0 ${styles.Spacing.XS};
    margin-bottom: ${styles.Spacing.S};
    width: ${styles.Spacing.M};
    padding: 0 5px;
    height: ${styles.Spacing.M};
    border: 1px solid ${styles.Color.Black};
    cursor: pointer;
`

const TableContainer = styled(motion.div)`
    max-width: 100%;
    ${styles.MediaQueries.Desktop} {
        margin: 0 ${styles.Spacing.M};
    }
`

const CenteredFlex = styled.div`
    display: flex;
    padding: 0 ${styles.Spacing.M};
    ${styles.MediaQueries.Desktop} {
        justify-content: center;
        align-items: center;
    }
    ${styles.MediaQueries.Mobile} {
        justify-content: center;
        margin-top: ${styles.Spacing.S};
    }
`

const FundingLink = styled(Link)`
    width: fit-content;
    display: block;
    &:hover {
        text-decoration: none;
    }
`

const Title = styled.div`
    ${styles.Text.DisplayLarge}
    color: ${styles.Color.Black};
    margin-top: 50px;
    margin-bottom: ${styles.Spacing.M};
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.S};
    }
`

const Transactions = styled.div`
    width: 100%;
    min-height: 100vh;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 200px;
    }
`

const GreetingContainer = styled.div`
    ${styles.MediaQueries.Desktop} {
        min-height: 220px;
    }
    ${styles.MediaQueries.Mobile} {
        min-height: 160px;
    }
`

const Greeting = styled.div`
    ${styles.Text.DisplayLarge}
    ${styles.Animation.transitionStyles}
    color: ${styles.Color.White};
    max-width: 600px;
    margin-bottom: ${styles.Spacing.L};
    white-space: pre-line;
    ${styles.MediaQueries.Mobile} {
        font-size: 1.6em;
    }
`

const CreditInfo = styled.div`
    ${styles.Text.DisplayMedium}
    ${styles.Animation.transitionStyles}
    color: ${styles.Color.White};
    max-width: 750px;
    margin-bottom: ${styles.Spacing.L};
    white-space: pre-line;
    ${styles.MediaQueries.Mobile} {
        font-size: 1.6em;
    }
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const getDefaultBackgroundColor = (accountCategory: string | null) => {
    switch (accountCategory) {
        case 'BUSINESS':
            return '#74A9E2'
        case 'CORPORATE':
            return '#0C0D10'
        default:
            return '#B69BAC'
    }
}

type MainProps = {
    lastCategory: string | null,
}

const Main = styled.div<MainProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 100%;
    ${styles.Animation.transitionStyles}
    background-color: ${props => getDefaultBackgroundColor(props.lastCategory)};
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
    overflow: hidden;
`

const Content = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    color: white;
    position: relative;
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.S};
    }
`

export default Home