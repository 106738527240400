import { LinkedAccount } from "types/LinkedAccount"

import { Locale, USDCurrencyOptions } from "utils/constants"

export const getAccountName = (account: LinkedAccount) => {
    if (!account.isInternalAccount) {
        return account.name
    }
    if (account.nickname && account.nickname !== '') {
        return account.nickname
    }
    return account.displayName
}

export const getAccountLabel = (account?: LinkedAccount, includeBalance: boolean = false) => {
    if (!account) {
        return undefined
    }
    return `${getAccountName(account)} (...${account.accountLast4}) ${account.availableBalance !== undefined && includeBalance ? `— ${account.availableBalance.toLocaleString(Locale.English, USDCurrencyOptions)}` : ''}`
}