import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import RootReducer from '../redux/reducer'

const middlewares = [thunk]

const { logger } = require('redux-logger')

// const environment = process.env.NODE_ENV

// enable the redux logger for dev, staging, and in production with a query param if provided
if (
  // environment === 'development' ||
  // environment === 'staging' ||
  window.location.search.indexOf('showReduxLogger') > -1
) {
  middlewares.push(logger)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = (preloadedState) =>
  createStore(RootReducer, preloadedState, composeEnhancers(applyMiddleware(...middlewares)))

export default configureStore
