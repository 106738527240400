import API from 'services/api'

import merge from 'lodash/merge'
import keyBy from 'lodash/keyBy'
import values from 'lodash/values'
import { getResponseOrThrow } from './features/helpers/errorHandling'

const ACTION_RECEIVE_USER = `USER/RECEIVE`
const ACTION_LOGIN_USER_START = `USER/FETCH_START`
const ACTION_LOGIN_USER_ERROR = `USER/FETCH_ERROR`

const ACTION_GET_ZENDESK_JWT_ERROR = 'USER/GET_ERROR'
const ACTION_GET_ZENDESK_JWT_COMPLETE = 'USER/GET_JWT_COMPLETE'

const ACTION_GET_BUSINESS_DETAILS_START = 'USER/GET_BUSINESS_DETAILS_START'
const ACTION_GET_BUSINESS_DETAILS_COMPLETE = 'USER/GET_BUSINESS_DETAILS_COMPLETE'
const ACTION_GET_BUSINESS_DETAILS_ERROR = 'USER/GET_BUSINESS_DETAILS_ERROR'

const ACTION_GET_API_KEYS_START = `USER/API_KEY_FETCH_START`
const ACTION_GET_API_KEYS_COMPLETE = `USER/API_KEY_FETCH_COMPLETE`
const ACTION_GET_API_KEYS_ERROR = `USER/API_KEY_FETCH_ERROR`
const ACTION_CREATE_API_KEYS_START = `USER/API_KEY_CREATE_START`
const ACTION_CREATE_API_KEYS_COMPLETE = `USER/API_KEY_CREATE_COMPLETE`
const ACTION_CREATE_API_KEYS_ERROR = `USER/API_KEY_CREATE_ERROR`
const ACTION_REVOKE_API_KEYS_START = `USER/API_KEY_REVOKE_START`
const ACTION_REVOKE_API_KEYS_COMPLETE = `USER/API_KEY_REVOKE_COMPLETE`
const ACTION_REVOKE_API_KEYS_ERROR = `USER/API_KEY_REVOKE_ERROR`
const ACTION_CLEAR_API_KEYS = `USER/API_CLEAR_API_KEY`

const ACTION_UPDATE_WORKING_COPY = `USER/UPDATE`
const ACTION_SAVE_USER_START = `USER/SAVE_START`
const ACTION_SAVE_USER_COMPLETE = `USER/SAVE_COMPLETE`
const ACTION_SAVE_USER_ERROR = `USER/SAVE_ERROR`

const ACTION_ADD_ADDRESS_START = `USER/ADD_ADDRESS_START`
const ACTION_ADD_ADDRESS_COMPLETE = `USER/ADD_ADDRESS_COMPLETE`
const ACTION_ADD_ADDRESS_ERROR = `USER/ADD_ADDRESS_ERROR`
const ACTION_ADD_ADDRESS_CLEAR = `USER/ADD_ADDRESS_CLEAR`

const ACTION_ADD_TO_PENDING_POINTS = 'USER/ADD_TO_PENDING_POINTS'

const ACTION_UPDATE_ADDRESS_WORKING_COPY = `USER/UPDATE_ADDRESS_WORKING_COPY`

const ACTION_CLEAR_USER = `USER/CLEAR_USER`

const ACTION_UPLOAD_PASSPORT_START = `USER/UPLOAD_PASSPORT_START`
const ACTION_UPLOAD_PASSPORT_COMPLETE = `USER/UPLOAD_PASSPORT_COMPLETE`
const ACTION_UPLOAD_PASSPORT_ERROR = `USER/UPLOAD_PASSPORT_ERROR`

const ACTION_DELETE_PASSPORT_START = `USER/DELETE_PASSPORT_START`
const ACTION_DELETE_PASSPORT_COMPLETE = `USER/DELETE_PASSPORT_COMPLETE`
const ACTION_DELETE_PASSPORT_ERROR = `USER/DELETE_PASSPORT_ERROR`

const ACTION_UPDATE_USER_CARD_ACCOUNT = `USER/UPDATE_USER_CARD_ACCOUNT`

export const Actions = {
  fetchCurrentUser: () => (dispatch) => {
    dispatch({ type: ACTION_LOGIN_USER_START })
    return API.auth
      .fetchCurrentUser()
      .then((response) => {
        dispatch({
          type: ACTION_RECEIVE_USER,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_LOGIN_USER_ERROR,
        })
      })
  },

  updateWorkingCopy: (newValue) => ({
    type: ACTION_UPDATE_WORKING_COPY,
    payload: newValue,
  }),

  saveCurrentUser: (userParams) => (dispatch) => {
    dispatch({ type: ACTION_SAVE_USER_START })

    return API.auth
      .updateCurrentUser(userParams)
      .then((response) => {
        dispatch({
          type: ACTION_SAVE_USER_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_SAVE_USER_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  clearCurrentUser: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_USER })
  },

  getZendeskJWT: () => (dispatch) => {
    // we don't actually need to dispatch a start since we assume it is being fetched by default

    return API.auth
      .getJWT()
      .then((response) => {
        dispatch({
          type: ACTION_GET_ZENDESK_JWT_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_ZENDESK_JWT_ERROR,
        })
      })
  },

  getBusinessDetails: (params) => (dispatch) => {
    dispatch({ type: ACTION_GET_BUSINESS_DETAILS_START })

    return API.auth
      .getBusinessDetails(params)
      .then((response) => {
        dispatch({
          type: ACTION_GET_BUSINESS_DETAILS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_BUSINESS_DETAILS_ERROR,
          payload: e,
        })
      })
  },

  /*
   *  Action to locally update card account info stored on the user account.
   *  When we update the nickname on the banking account it should be updated
   *  in parallel on the user object.
   */
  updateUserCardAccount: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_USER_CARD_ACCOUNT,
      payload: params
    })
  },

  getApiKeys: () => (dispatch) => {
    dispatch({ type: ACTION_GET_API_KEYS_START })
    return API.auth
      .fetchApiKeys()
      .then((response) => {
        dispatch({
          type: ACTION_GET_API_KEYS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_API_KEYS_ERROR,
          payload: getResponseOrThrow(e),
        })
      })
  },

  revokeApiKeys: (params) => (dispatch) => {
    dispatch({ type: ACTION_REVOKE_API_KEYS_START })
    return API.auth
      .revokeApiKey(params)
      .then((response) => {
        dispatch({
          type: ACTION_REVOKE_API_KEYS_COMPLETE,
          payload: response.data,
        })
      })
      .then(() => {
        dispatch(Actions.getApiKeys())
      })
      .catch((e) => {
        dispatch({
          type: ACTION_REVOKE_API_KEYS_ERROR,
          payload: getResponseOrThrow(e),
        })
      })
  },

  regenerateApiKeys: (params) => (dispatch) => {
    dispatch({ type: ACTION_REVOKE_API_KEYS_START })
    return API.auth
      .revokeApiKey(params.delete_params)
      .then((response) => {
        dispatch({
          type: ACTION_REVOKE_API_KEYS_COMPLETE,
          payload: response.data,
        })
      })
      .then(() => {
        dispatch(Actions.createApiKeys(params.create_params))
      })
      .catch((e) => {
        dispatch({
          type: ACTION_REVOKE_API_KEYS_ERROR,
          payload: getResponseOrThrow(e),
        })
      })
  },

  createApiKeys: (params) => (dispatch) => {
    dispatch({ type: ACTION_CREATE_API_KEYS_START })
    return API.auth
      .createApiKey(params)
      .then((response) => {
        dispatch({
          type: ACTION_CREATE_API_KEYS_COMPLETE,
          payload: response.data,
        })
      })
      .then(() => {
        dispatch(Actions.getApiKeys())
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_API_KEYS_ERROR,
          payload: getResponseOrThrow(e),
        })
      })
  },

  clearSecretApiKey: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_API_KEYS })
  },

  clearAddAddress: () => (dispatch) => {
    dispatch({ type: ACTION_ADD_ADDRESS_CLEAR })
  },

  addToPointBalances: (params) => ({
    type: ACTION_ADD_TO_PENDING_POINTS,
    payload: params,
  }),

  updateAddressWorkingCopy: (newValue) => ({
    type: ACTION_UPDATE_ADDRESS_WORKING_COPY,
    payload: newValue,
  }),

  addAddress: (params) => (dispatch) => {
    dispatch({ type: ACTION_ADD_ADDRESS_START })

    return API.auth
      .addAddress(params)
      .then((response) => {
        dispatch({
          type: ACTION_ADD_ADDRESS_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_ADD_ADDRESS_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  reuploadPassportSaveUser: (uploadPassportParams, saveUserParams) => (dispatch) => {
    dispatch({ type: ACTION_UPLOAD_PASSPORT_START })

    return API.auth.deletePassport().then((response) => {
      API.auth
        .uploadPassport(uploadPassportParams)
        .then((response) => {
          dispatch({
            type: ACTION_UPLOAD_PASSPORT_COMPLETE,
            payload: { ...response.data },
          })
        })
        .catch((e) => {
          dispatch({
            type: ACTION_UPLOAD_PASSPORT_ERROR,
            payload: { ...e.response.data },
          })
        })
        .then((response) => {
          dispatch(Actions.saveCurrentUser(saveUserParams))
        })
    })
  },

  deletePassportSaveUser: (saveUserParams) => (dispatch) => {
    dispatch({ type: ACTION_DELETE_PASSPORT_START })

    return API.auth
      .deletePassport()
      .then((response) => {
        dispatch({
          type: ACTION_UPLOAD_PASSPORT_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_DELETE_PASSPORT_ERROR,
          payload: { ...e.response.data },
        })
      })
      .then((response) => {
        dispatch(Actions.saveCurrentUser(saveUserParams))
      })
  },
}

// REDUCER

const defaultState = {
  userLoaded: false,
  isFetching: false,
  error: false,
  updateSuccess: false,
  updateErrors: {},
  zendeskJwt: null,
  isFetchingZendeskJwt: true,
  isFetchingApiKeys: false,
  apiKeys: [],
  secretApiKey: null,
  currentUser: {
    dateOfBirth: '',
    email: '',
    emergencyContactEmail: '',
    emergencyContactFirstName: '',
    emergencyContactLastName: '',
    emergencyContactPhoneNumber: '',
    firstName: '',
    gender: '',
    hasAuthorizedUserAccount: true,
    hasCardAccount: true,
    hasApiKeyAccess: '',
    hasCardSpendControlAccess: '',
    homeAirportCode: '',
    isClosedAccount: null,
    isVipUser: false,
    isMser: false,
    isTestAccount: null,
    knownTravellerNumber: '',
    lastName: '',
    middleName: '',
    numPoints: null,
    passportCountry: '',
    passportExpDate: '',
    passportGivenName: '',
    passportLastName: '',
    passportNumber: '',
    phone: '',
    redressNumber: '',
    vnFfpNumber: '',
    twoFaEnabled: null,
    appBased2fa: null,
    username: '',
    cardAccounts: [],
    business: {},
    passportImageUuid: '',
    primaryAddress: {
      street: '',
      unitNum: '',
      state: '',
      city: '',
      zip: '',
      uuid: '',
    },
    primaryCardAccount: null,
    mailingAddress: {
      street: '',
      unitNum: '',
      state: '',
      city: '',
      zip: '',
      uuid: '',
    },
    otherAddresses: [],
  },
  workingCopy: {},
  addAddress: {
    error: false,
    loading: false,
    success: false,
    errors: [],
    address: {
      object: null,
      googlePlacesId: '',
      street: '',
      unitType: '',
      unitNum: '',
      city: '',
      state: '',
      zip: '',
    },
  },
}

export const CurrentUserReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_SAVE_USER_START:
    case ACTION_LOGIN_USER_START:
      return {
        ...state,
        isFetching: true,
        updateSuccess: false,
        error: false,
        updateErrors: [],
      }

    case ACTION_RECEIVE_USER:
      return {
        ...state,
        isFetching: false,
        userLoaded: true,
        currentUser: { ...action.payload },
        workingCopy: { ...action.payload },
      }

    case ACTION_LOGIN_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      }

    case ACTION_UPDATE_WORKING_COPY:
      return {
        ...state,
        workingCopy: {
          ...state.workingCopy,
          ...action.payload,
        },
        updateSuccess: false,
      }

    case ACTION_SAVE_USER_COMPLETE:
      return {
        ...state,
        isFetching: false,
        userLoaded: true,
        updateSuccess: true,
        updateErrors: {},
        currentUser: {
          ...action.payload,
        },
        workingCopy: {
          ...action.payload,
        },
      }

    case ACTION_SAVE_USER_ERROR:
      return {
        ...state,
        ...action.payload,
        updateSuccess: false,
        isFetching: false,
      }

    case ACTION_CLEAR_USER:
      return defaultState

    case ACTION_UPDATE_USER_CARD_ACCOUNT:
      let copyOfUserCardAccounts = JSON.parse(JSON.stringify(state.currentUser.cardAccounts))
      let selectedCardAccountIndex
      copyOfUserCardAccounts.forEach((account, index) => {
        if (account.uuid === action.payload.cardAccountUuid) {
          selectedCardAccountIndex = index
        }
      })
      if (selectedCardAccountIndex > -1) {
        copyOfUserCardAccounts[selectedCardAccountIndex] = {
          ...copyOfUserCardAccounts[selectedCardAccountIndex],
          ...action.payload,
          // add the new balance instead of overwriting (so the caller of this action doesn't need context)
          availableBalance: copyOfUserCardAccounts[selectedCardAccountIndex].availableBalance + (action.payload?.availableBalance || 0)
        }
      }

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          cardAccounts: copyOfUserCardAccounts,
        }
      }

    case ACTION_GET_BUSINESS_DETAILS_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          business: {},
        },
      }

    case ACTION_GET_BUSINESS_DETAILS_COMPLETE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          business: action.payload,
        },
      }

    case ACTION_GET_BUSINESS_DETAILS_ERROR:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          business: {},
        },
      }

    case ACTION_GET_ZENDESK_JWT_ERROR:
      return {
        ...state,
        isFetchingZendeskJwt: false,
      }

    case ACTION_GET_ZENDESK_JWT_COMPLETE:
      return {
        ...state,
        isFetchingZendeskJwt: false,
        zendeskJwt: action.payload.token,
      }

    case ACTION_GET_API_KEYS_START:
      return {
        ...state,
        isFetchingApiKeys: true,
      }

    case ACTION_GET_API_KEYS_COMPLETE:
      return {
        ...state,
        isFetchingApiKeys: false,
        apiKeys: action.payload,
      }

    case ACTION_GET_API_KEYS_ERROR:
      return {
        ...state,
        isFetchingApiKeys: false,
      }

    case ACTION_CREATE_API_KEYS_COMPLETE:
      return {
        ...state,
        secretApiKey: action.payload.secretApiKey,
      }

    case ACTION_CLEAR_API_KEYS:
      return {
        ...state,
        secretApiKey: null,
      }

    case ACTION_ADD_TO_PENDING_POINTS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          numPoints: state.currentUser.numPoints + (action.payload?.numPoints || 0),
          numPointsPending: state.currentUser.numPointsPending + (action.payload?.numPointsPending || 0),
          totalUsedPoints: state.currentUser.totalUsedPoints + (action.payload?.totalUsedPoints || 0),
        },
      }

    case ACTION_UPDATE_ADDRESS_WORKING_COPY:
      return {
        ...state,
        addAddress: {
          ...state.addAddress,
          address: {
            ...state.addAddress.address,
            ...action.payload,
          },
        },
      }

    case ACTION_ADD_ADDRESS_CLEAR:
      // manually clearing the fields because I was running into one-off issues here
      return {
        ...state,
        addAddress: {
          ...defaultState.addAddress,
          address: {
            ...defaultState.addAddress.address,
            unitNum: '',
            unitType: '',
            googlePlacesId: '',
            object: null,
          },
        },
      }

    case ACTION_ADD_ADDRESS_START:
      return {
        ...state,
        addAddress: {
          ...state.addAddress,
          success: false,
          loading: true,
        },
      }

    case ACTION_ADD_ADDRESS_COMPLETE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          otherAddresses: values(
            merge(keyBy(state.currentUser.otherAddresses, 'uuid'), keyBy([action.payload], 'uuid')),
          ),
        },
        addAddress: {
          ...state.addAddress,
          loading: false,
          success: true,
          address: { ...action.payload },
        },
      }

    case ACTION_ADD_ADDRESS_ERROR:
      return {
        ...state,
        addAddress: {
          ...state.addAddress,
          loading: false,
          error: true,
          errors: { ...action.payload },
        },
      }

    case ACTION_UPLOAD_PASSPORT_START:
      return {
        ...state,
        isFetching: true,
        userLoaded: false,
        updateSuccess: false,
      }

    case ACTION_UPLOAD_PASSPORT_COMPLETE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          passportImageUuid: action.payload.referenceUuid,
        },
      }

    case ACTION_UPLOAD_PASSPORT_ERROR:
      return {
        ...state,
        isFetching: false,
        userLoaded: false,
        updateSuccess: false,
      }

    case ACTION_DELETE_PASSPORT_START:
      return {
        ...state,
        isFetching: true,
        userLoaded: false,
        updateSuccess: false,
      }

    case ACTION_DELETE_PASSPORT_COMPLETE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          passportImageUuid: null,
        },
      }

    case ACTION_DELETE_PASSPORT_ERROR:
      return {
        ...state,
        isFetching: false,
        userLoaded: false,
        updateSuccess: false,
      }

    default:
      return state
  }
}
