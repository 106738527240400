import React, { useState } from 'react'

import moment from 'moment-timezone'

import { Img } from 'react-image';

import styled from 'styled-components';
import { FormattedMessage } from 'react-intl'

import { TravelClass, messages } from 'components/pages/Rewards/constants';
import { isAmenityPopulated } from 'components/pages/Rewards/Flights/utils';

import { IETCodes } from 'utils/constants';

import styles from 'styles/styles';
import { getDurationAsString } from 'utils/utils';

type ItineraryReviewProps = {
    // replace any typing with ItineraryReview
    option: any,
}

const ItineraryReview = (props: ItineraryReviewProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleIsOpen = () => { 
        setIsOpen(!isOpen)
    }

    const { option } = props;

    const carrierCode = IETCodes.includes(option.carrierCode) ? option.slices[0].segments[0].operatingCarrierCode.toUpperCase() : option.carrierCode.toUpperCase();

    return <Container>
         <Header onClick={toggleIsOpen}>
            <LogoContainer>
                <Logo src={[
                    `/static/img/airlineLogos/mini/${carrierCode.toUpperCase()}.png`,
                    '/static/img/airlineLogos/mini/default.png'
                ]} />
            </LogoContainer>
            <HeaderDetails>
                <HeaderDetailRow>
                    <HeaderDetail className='justify-content-start'>
                        <strong>{option.airlineName || "Unknown Airline"}</strong>
                    </HeaderDetail>
                    <HeaderDetail>
                        {`${moment(option.slices[0].segments[0].localDepartureTimeDate).format('h:mm A')} - ${moment(option.slices[0].segments.at(-1).localArrivalTimeDate).format('h:mm A')}`}
                    </HeaderDetail>
                    <HeaderDetail>
                        {`${option.slices[0].segments[0].departurePoint} - ${option.slices[0].segments[option.slices[0].segments.length - 1].arrivalPoint}`}
                    </HeaderDetail>
                    <HeaderDetail>
                        {option.slices[0].numStops !== "0" ? `${option.slices[0].numStops} stop${option.slices[0].numStops > 1 ? 's' : ''}` : 'Nonstop'}
                    </HeaderDetail>
                </HeaderDetailRow>
            </HeaderDetails>
        </Header>
        <div>
            {option.slices[0].segments.map((segment:any) => <div>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div className='d-flex'>
                            <VerticalLegBar>
                                <LargeDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <LargeDot/>
                            </VerticalLegBar>
                            <LegDetailsContainer>
                                <LegAirportLabel>{moment(segment.localDepartureTimeDate).format('h:mm a')} &bull; {`${segment.departurePointName} (${segment.departurePoint})`}</LegAirportLabel>
                                <LegTravelTime>Travel Time: {getDurationAsString(segment.duration)}</LegTravelTime>
                                <LegAirportLabel>{moment(segment.localArrivalTimeDate).format('h:mm a')} &bull; {`${segment.arrivalPointName} (${segment.arrivalPoint})`}</LegAirportLabel>
                            </LegDetailsContainer>
                        </div>
                        <AirplaneDetails>
                            {segment.operatingCarrierName} &bull; {messages.SearchOptions.TravelClass[segment.cabin as TravelClass]} &bull; {segment.aircraft || <span style={{ opacity: 0.5 }}>Aircraft model not available</span>} &bull; {segment.carrierCode} {segment.fltNumber}
                        </AirplaneDetails>
                        {segment.carrierCode !== segment.operatingCarrierCode && <div className='d-flex align-items-center' style={{marginLeft: '52px', marginBottom: "30px"}}>
                            <LogoContainer>
                                <CarrierLogo src={[
                                    `/static/img/airlineLogos/mini/${segment.operatingCarrierCode.toUpperCase()}.png`,
                                    '/static/img/airlineLogos/mini/default.png'
                                ]} />
                            </LogoContainer>
                            <CarrierDetails>Ticket sold by {segment.airlineName} and operated by {segment.operatingCarrierName}</CarrierDetails>
                        </div>}
                    </div>
                    <Amenities>
                        {isAmenityPopulated(segment.legs[0].amenities.seatType) && <div>
                            <AmenityIcon src={require(`assets/old/img/amenities/${segment.legs[0].amenities.seatType.split('_')[0]}.svg`)} alt="" />
                            <FormattedMessage id={`booking.amenities.${segment.legs[0].amenities.seatType.split('_')[0]}`} values={{ legroom: segment.legs[0].amenities.seatType.split('_')[1] || null }} />
                        </div>}
                        {isAmenityPopulated(segment.legs[0].amenities.wifi) && <div>
                            <AmenityIcon src={require(`assets/old/img/amenities/wifi-${segment.legs[0].amenities.wifi.toLowerCase()}.svg`)} alt="" />
                            <FormattedMessage id={`booking.amenities.wifi-${segment.legs[0].amenities.wifi.toLowerCase()}`} />
                        </div>}
                        {isAmenityPopulated(segment.legs[0].amenities.power) && <div>
                            <AmenityIcon src={require(`assets/old/img/amenities/power-${segment.legs[0].amenities.power.toLowerCase()}.svg`)} alt="" />
                            <FormattedMessage id={`booking.amenities.power-${segment.legs[0].amenities.power}`} />
                        </div>}
                        {isAmenityPopulated(segment.legs[0].amenities.entertainment) && <div>
                            <AmenityIcon src={require(`assets/old/img/amenities/entertainment-${segment.legs[0].amenities.entertainment.toLowerCase()}.svg`)} alt="" />
                            <FormattedMessage id={`booking.amenities.entertainment-${segment.legs[0].amenities.entertainment}`} />
                        </div>}
                        {segment.legs[0].amenities.skypub && <div>
                            <AmenityIcon src={require(`assets/old/img/amenities/skypub.svg`)} alt="" />
                            <FormattedMessage id={`booking.amenities.skypub`} />
                        </div>}
                    </Amenities>
                </div>
                {!!segment.connection && <ConnectionDetails>
                    {getDurationAsString(segment.connection)} &bull; {`${segment.arrivalPointName} (${segment.arrivalPoint})`}
                </ConnectionDetails>}
            </div>)}
            <Footer>
                <FooterDetailsContainer>
                    <div>{`Total Travel Time: ${Math.floor(option.slices[0].duration / 60)} hr ${!!(option.slices[0].duration % 60) ? `${(option.slices[0].duration % 60)} min` : ''}`}</div>
                </FooterDetailsContainer>
            </Footer>
        </div>
    </Container>
}

const LogoContainer = styled.div`
    width: 45px;
    display: flex;
    justify-content: center;
`

const Amenities = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    fontSize: ${styles.Font.Size.Small};
`

const AmenityIcon = styled.img`
    margin: 4px ${styles.Spacing.XS};
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
`

const HeaderDetailRow = styled.div`
    display: flex;
    align-items: center;
    width: min-content;
    ${styles.MediaQueries.Mobile} {
        height: 50%;
        width: 100%;
        padding: 0 ${styles.Spacing.XS};
        justify-content: space-between;
        flex: 1;
    }
    ${styles.MediaQueries.Desktop} {
        height: 100%;
        flex: 1;
        justify-content: space-between;
        &:last-child {
            flex: 3;
            justify-content: space-around;
        }
    }
`

const HeaderDetails = styled.div`
    flex: 1;
    height: 100%;
    ${styles.MediaQueries.Mobile} {
        padding-right: 80px;
        padding-top: ${styles.Spacing.XS};
        padding-left: 0;
        padding-bottom: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

const HeaderDetail = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    display: flex;
    width: min-content;
    align-items: center;
    justify-content: center;
    width: 25%;
`

const VerticalLegBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-right: 8px;
`

const AirplaneDetails = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 52px;
    font-size: ${styles.Font.Size.Small};
`

const CarrierDetails = styled.div`
    font-size: ${styles.Font.Size.Small};
`

const ConnectionDetails = styled.div`
    border-top: 1px solid ${styles.Color.Grey};
    border-bottom: 1px solid ${styles.Color.Grey};
    margin: 0 46px 30px 46px;
    flex: 1;
    padding: ${styles.Spacing.XS} 0;
    font-size: ${styles.Font.Size.Small};
`

const LegAirportLabel = styled.div`
    font-size: ${styles.Font.Size.Small};
    font-weight: bold;
`

const LegTravelTime = styled.div`
    font-size: ${styles.Font.Size.Small};
    flex: 1;
    display: flex;
    align-items: center;
    opacity: 0.5;
`

const LegDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Footer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${styles.Color.Grey};
    height: 80px;
`

const FooterDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    font-size: 16px;
`

const CarrierLogo = styled(Img)`
    width: auto;
    height: 20px;
`

const Logo = styled(Img)`
    width: auto;
    height: 25px;
    ${styles.MediaQueries.Mobile} {
        margin-top: 15px;
    }
`

const Header = styled.div`
    display: flex;
    white-space: nowrap;
    font-size: ${styles.Font.Size.Small};
    ${styles.MediaQueries.Mobile} {
        height: 110px;
    }
    ${styles.MediaQueries.Desktop} {
        height: 80px;
        align-items: center;
    }
`

const LargeDot = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid ${styles.Color.Grey};
    margin: 3.5px 0;
`

const SmallDot = styled.div`
    background-color: ${styles.Color.Grey};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 3px 0;
`

const Container = styled.div`
    ${styles.Animation.transitionStyles}
    height: min-content;
`


export default ItineraryReview