import React from "react"

import styled from "styled-components"

import styles from "styles/styles"

type IconButtonProps = {
    icon: React.ReactElement,
    onClick: () => void,
    disabled?: boolean,
    size?: number
    superscript?: string,
}

const IconButton = (props: IconButtonProps) => {
    return <Container {...props}>
        {props.icon}
        <Superscript>{props.superscript}</Superscript>
    </Container>
}

const Superscript = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
`

const Container = styled.div<IconButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: ${props => props.size || 40}px;
    width: ${props => props.size || 40}px;
    min-height: ${props => props.size || 40}px;
    min-width: ${props => props.size || 40}px;
    border-radius: 4px;
    ${styles.Animation.transitionStyles}
    ${props => !props.disabled ? `
        cursor: pointer;
        ${styles.MediaQueries.Desktop} {
            &:hover {
                background-color: ${styles.Color.TaekusPurple};
                color: ${styles.Color.TaekusPurple};
            }
        }
    ` : 'opacity: 0.5;'}
`

export default IconButton