import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api';

type FlightSliceState = {
  seatMap: {
    isOpen: boolean,
    isLoading: boolean,
    data: any,
    fetchController: any,
    title: string,
    error: null | boolean
  }
}

export const flightSlice = createSlice({
  name: 'flights',
  initialState: {
    seatMap: {
      isOpen: false,
      isLoading: false,
      data: null,
      fetchController: null,
      title: '',
      error: null,
    }
  } as FlightSliceState,
  reducers: {
    toggleSeatMapIsOpen: (state, action) => {
      state.seatMap.isOpen = !state.seatMap.isOpen
      state.seatMap.title = action.payload;
    },
    seatMapLoading: (state, action) => {
      // cancel fetch if controller is present
      if (state.seatMap.fetchController) {
        (state.seatMap.fetchController as any).abort();
      }

      state.seatMap.isLoading = true
      state.seatMap.fetchController = action.payload
      state.seatMap.data = null
      state.seatMap.error = null
    },
    seatMapSuccess: (state, action) => {
      state.seatMap.isLoading = false
      state.seatMap.fetchController = null
      state.seatMap.data = action.payload.data
      state.seatMap.error = false
    },
    seatMapError: (state) => {
      state.seatMap.isLoading = false
      state.seatMap.fetchController = null
      state.seatMap.error = true
    },
  }
})
  
  export const { toggleSeatMapIsOpen, seatMapLoading, seatMapSuccess, seatMapError } = flightSlice.actions
  
  type FetchSeatMapParams = {
    localDepartureTimeDate: string,
    origin: string,
    destination: string,
    carrierCode: string,
    fltNumber: string,
    bookingCode: string,
  }

  export const fetchSeatMap = (params: FetchSeatMapParams) => (dispatch: any) => {
    const controller = new AbortController();
    dispatch(seatMapLoading(controller));

    return API.flights.fetchSeatmap({
      local_departure_time_date: params.localDepartureTimeDate,
      origin: params.origin,
      destination: params.destination,
      carrier_code: params.carrierCode,
      flt_number: params.fltNumber,
      booking_code: params.bookingCode,
    }, controller)
      .then((response) => {
        dispatch(seatMapSuccess(response));
      })
      .catch((e) => {
        dispatch(seatMapError());
      })
  }

  export default flightSlice.reducer