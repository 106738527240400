import React, { ReactNode } from "react"

import { motion } from "framer-motion";
import styled from "styled-components";

import { ReactComponent as Close } from "assets/svg/Close.svg";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

type ModalProps = {
    title: string;
    titleIcon?: ReactNode;
    onClose?: () => void;
    children?: ReactNode;
}

const Modal = (props: ModalProps) => {
    const stopPropagation = (ev: any) => { ev.stopPropagation()}

    const { titleIcon } = props;

    return <Container {...fadeInOutMotionProps} onClick={props.onClose}>
        <ModalContainer onClick={stopPropagation}>
            <div className='w-100 d-flex justify-content-between align-items-center'>
                <ModalTitle>{titleIcon && <IconContainer>{titleIcon}</IconContainer>}{props.title}</ModalTitle>
                {<CloseButton onClick={props.onClose}>
                    <CustomClose/>
                </CloseButton>}
            </div>
            <Children>
                {props.children}
            </Children>
        </ModalContainer>
    </Container>
}

const IconContainer = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`

const Children = styled.div`
    padding: 0 ${styles.Spacing.XS};
    display: flex;
    flex: 1;
`

const CustomClose = styled(Close)`
    width: 24px;
    height: 24px;
    border-radius: 50%;
`

const ModalTitle = styled.div`
    padding: 0 ${styles.Spacing.XS};
    font-size: 20px;
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

type CloseButtonProps = {
    onClick: any,
}

const CloseButton = styled.div<CloseButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
    cursor: pointer;
    margin: 4px ${styles.Spacing.XS};
    visibility: ${props => props.onClick ? 'visible' : 'hidden'};
`

const ModalContainer = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: ${styles.Spacing.S};
    min-height: 250px;
    ${styles.MediaQueries.Desktop} {
        min-width: 300px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin: ${styles.Spacing.XS};
    }
`

const Container = styled(motion.div)`
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`

export default Modal