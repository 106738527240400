import React from "react"

import styled from "styled-components"

import Spinner from "components/common/Spinner"
import Navigation, { NavColor } from "components/navbar/Navigation"

import styles from "styles/styles"

const DefaultLoadingPage = () => {
    return <Container>
        <Navigation color={NavColor.Black}/>
        <Flex>
            <Spinner size={40}/>
            <SpinnerLabel>Loading</SpinnerLabel>
        </Flex>
    </Container>
}

const Flex = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const SpinnerLabel = styled.div`
    margin-top: ${styles.Spacing.XS};
    opacity: 0.5;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    max-height: -webkit-fill-available;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default DefaultLoadingPage