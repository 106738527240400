import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useSelector } from 'react-redux'

import get from 'lodash/get'
import moment from 'moment'
import classNames from 'classnames'

import { Modal } from 'react-bootstrap'
import { Input } from 'reactstrap'
import NumberFormat from 'react-number-format'
import CurrencyInput from 'react-currency-input'

import { Actions } from 'redux/features/banking/disputes'

import Checkmark from 'assets/old/img/banking/success-check.svg'

import 'assets/components/card/css/payments.scss'

const DisputeModal = (props) => {
  // Stage 100: confirm you'd like to submit a dispute
  // Stage 200: Select class of dispute: incorrectly charged OR still being charged
  // Stage 300: Select subtype
  // Stage 400: Charged wrong amount -> submit expected amount
  // Stage 500: Provide any additional details
  // Stage 600: Confirm details
  // Stage 700: Success page
  //
  // Dispute Types:
  // incorrectly-charged
  // still-charged
  // suspected-fraud
  //
  // Subtypes:
  // charged-for-cancelled-subscription
  // charged-for-return-or-cancelled
  // charged-for-late-never-provided
  // charged-for-dissatisfied-product
  // charged-for-higher-amount
  // charged-multiple-times
  // charged-despite-using-cash
  const dispatch = useDispatch()
  const banking = useSelector((state) => state.banking)
  const disputes = useSelector((state) => state.disputes)

  const [modalStage, setModalStage] = useState(100)
  const disputeTypes = {
    'still-charged': 'still-charged',
    'incorrectly-charged': 'incorrectly-charged',
    'suspected-fraud': 'suspected-fraud',
  }
  const disputeSubTypes = {
    'charged-for-cancelled-subscription': 'charged-for-cancelled-subscription',
    'charged-for-return-or-cancelled': 'charged-for-return-or-cancelled',
    'charged-for-late-never-provided': 'charged-for-late-never-provided',
    'charged-for-dissatisfied-product': 'charged-for-dissatisfied-product',
    'charged-for-higher-amount': 'charged-for-higher-amount',
    'charged-multiple-times': 'charged-multiple-times',
    'charged-despite-using-cash': 'charged-despite-using-cash',
  }

  const closeModal = () => {
    props.setDisplayDisputeModal()

    // We delay resetting the modal by a few hundred ms to avoid 'flashing'
    setTimeout(() => {
      setModalStage(100)
      dispatch(Actions.clearDispute())
    }, 300)
  }

  const onChange = (value, fieldName) => {
    dispatch(Actions.updateWorkingCopy({ [fieldName]: value }))
  }

  const handleCurrencyChange = (event, maskedValue, floatValue, fieldName) => {
    onChange(floatValue, fieldName)
  }

  const submit = () => {
    const { transaction } = props
    disputes.workingCopy['transactionUuid'] = transaction['uuid']
    disputes.workingCopy['cardAccountUuid'] = banking.account.uuid
    dispatch(Actions.createDispute(disputes.workingCopy))
    setModalStage(700)
  }

  const renderDisputeContent = () => {
    const { transaction } = props
    const { workingCopy, submittedDispute, isLoading, error } = disputes
    const { activeType, activeSubtype, expectedCharge, additionalInformation } = workingCopy

    if (modalStage === 100) {
      return (
        <StartDisputeStage1
          transaction={transaction}
          setStage={setModalStage}
          closeModal={closeModal}
        />
      )
    } else if (modalStage === 200) {
      return (
        <SelectDisputeTypeStage2
          transaction={transaction}
          disputeTypes={disputeTypes}
          closeModal={closeModal}
          setStage={setModalStage}
          onChange={onChange}
          activeType={activeType}
        />
      )
    } else if (modalStage === 300) {
      return (
        <SelectSubtypeStage3
          transaction={transaction}
          disputSubTypes={disputeSubTypes}
          closeModal={closeModal}
          setStage={setModalStage}
          onChange={onChange}
          activeType={activeType}
          activeSubtype={activeSubtype}
        />
      )
    } else if (modalStage === 400) {
      return (
        <SubmitExtraInfoStage4
          closeModal={closeModal}
          transaction={transaction}
          setStage={setModalStage}
          expectedCharge={expectedCharge}
          handleCurrencyChange={handleCurrencyChange}
        />
      )
    } else if (modalStage === 500) {
      return (
        <AdditionalDetailsStage5
          closeModal={closeModal}
          activeSubtype={activeSubtype}
          transaction={transaction}
          setStage={setModalStage}
          additionalInformation={additionalInformation}
          onChange={onChange}
        />
      )
    } else if (modalStage === 600) {
      return (
        <ConfirmDetailsStage6
          closeModal={closeModal}
          transaction={transaction}
          workingCopy={workingCopy}
          setStage={setModalStage}
          submit={submit}
        />
      )
    } else if (modalStage === 700) {
      return (
        <SummaryStage7
          transaction={transaction}
          setStage={setModalStage}
          closeModal={closeModal}
          isLoading={isLoading}
          error={error}
          submittedDispute={submittedDispute}
        />
      )
    }
  }

  const { displayDisputeModal } = props

  return (
    <>
      <Modal
        dialogClassName="Payments-modal disputeModal p-2"
        show={displayDisputeModal}
        onHide={closeModal}
        keyboard={false}
      >
        <div className="p-4">
          <div className="px-2">
            <h3 className="StandardTitle">
              <FormattedMessage
                id="payments.disputes.dispute-transaction"
                defaultMessage={`DISPUTE TRANSACTION`}
              />
            </h3>
          </div>
          {renderDisputeContent()}
        </div>
      </Modal>
    </>
  )
}

const TransactionDescriptionBlock = ({ transaction = {} }) => {
  const { date, postedDate, merchant, amount } = transaction

  return (
    <div className="px-2">
      <h5 className="StandardTitleDMSans font-weight-500 mb-1">
        <FormattedMessage
          id="payments.transactions.dispute-details"
          defaultMessage={`Here are your payment details:`}
        />
      </h5>

      <div className="d-flex flex-column disputeModal-transactionBox p-3">
        <div className="d-flex flex-row">
          <div className="d-flex flex-column flex-grow-1">
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.transaction-date"
                defaultMessage={`Transaction Date`}
              />
            </div>
            <div className="StandardBodyDMSans">{moment(date).format('MMMM D, YYYY')}</div>
          </div>
          <div className="d-flex flex-column flex-grow-1">
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.posted-on"
                defaultMessage={`Posted On`}
              />
            </div>
            <div className="StandardBodyDMSans">{moment(postedDate).format('MMMM D, YYYY')}</div>
          </div>
        </div>
        <div className="d-flex flex-row pt-2">
          <div className="d-flex flex-column">
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.description"
                defaultMessage={`Description`}
              />
            </div>
            {merchant && <div className="StandardBodyDMSans">{merchant.merchantDescription}</div>}
          </div>
        </div>
        {merchant && merchant.enrichedMerchantName && (
          <div className="d-flex flex-row pt-2">
            <div className="d-flex flex-column">
              <div className="StandardSubtitleDMSans transactionBox-title">
                <FormattedMessage
                  id="payments.transactions.dispute.merchant"
                  defaultMessage={`Merchant`}
                />
              </div>
              <div className="StandardBodyDMSans">{merchant.enrichedMerchantName}</div>
            </div>
          </div>
        )}
        <div className="d-flex flex-row pt-2">
          <div className="d-flex flex-column">
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.transaction-amount"
                defaultMessage={`Transaction Amount`}
              />
            </div>
            <div className="StandardBodyDMSans">
              {amount && (
                <NumberFormat
                  value={amount.amountUsd}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SelectionBox = ({ title, description, type, activeType, item, onChange }) => {
  return (
    <button
      className={classNames(
        'StandardButtonNoStyles disputeModal-SelectBox w-100 d-flex my-2 py-3',
        { SelectBoxActive: activeType === type },
      )}
      onClick={() => onChange(type, item)}
      disabled={activeType === type}
    >
      <div
        className={classNames('disputeModal-SelectCircle align-self-center my-1 mr-2', {
          SelectCircleActive: type === activeType,
        })}
      />
      <div className="d-flex flex-column text-left pr-2">
        <div className="StandardBodyDMSans">{title}</div>
        {description && <div className="StandardSubtitleDMSans">{description}</div>}
      </div>
    </button>
  )
}

const StartDisputeStage1 = ({ transaction, setStage, closeModal }) => {
  const { merchantWebAddress } = get(transaction, 'merchant', '')

  return (
    <>
      <div className="px-2 mb-3">
        <div className="StandardBodyDMSans">
          <FormattedMessage
            id="payments.transactions.dispute-explainer"
            defaultMessage={`Let's figure out what's going on. We'll ask you a series of questions to help file your dispute.`}
          />
        </div>
      </div>

      <TransactionDescriptionBlock transaction={transaction} />

      <div className="d-flex flex-column px-2 mt-2 mb-5">
        <h5 className="StandardTitleDMSans font-weight-500 mt-3 mb-1">
          <FormattedMessage
            id="payments.transactions.dispute-have-you-contacted"
            defaultMessage={`Have you contacted the business to attempt to resolve the issue?`}
          />
        </h5>
        <div className="StandardBodyDMSans">
          <FormattedMessage
            id="payments.transactions.dispute-explainer"
            defaultMessage={`Contacting a businesses' customer service directly is the fastest way of resolving a dispute and getting a refund. Our investigation process may take up to 6-8 weeks.`}
          />
        </div>
      </div>

      <div className="d-flex w-50 mx-auto justify-content-around mt-5 px-2 mb-2">
        {merchantWebAddress && (
          <button
            className={classNames(
              'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1',
            )}
            onClick={() => window.open(`${merchantWebAddress}`)}
          >
            <FormattedMessage
              id="payments.dispute.contact-business"
              defaultMessage={`CONTACT BUSINESS`}
            />
          </button>
        )}
      </div>

      <div className="d-flex justify-content-around mt-5 px-2 mb-2">
        <button
          className={classNames(
            'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1 mr-3',
          )}
          onClick={() => closeModal()}
        >
          <FormattedMessage id="payments.dispute.cancel" defaultMessage={`CANCEL`} />
        </button>
        <button
          className="StandardButtonWhite Payments-modalButton text-size-08 flex-grow-1 py-1 "
          onClick={() => setStage(200)}
        >
          <FormattedMessage
            id="payments.dispute.continue-dispute"
            defaultMessage={`CONTINUE DISPUTE`}
          />
        </button>
      </div>
    </>
  )
}

const SelectDisputeTypeStage2 = ({
  transaction,
  disputeTypes,
  onChange,
  activeType,
  setStage,
  closeModal,
}) => {
  return (
    <>
      <TransactionDescriptionBlock transaction={transaction} />

      <button
        className="StandardButtonNoStyles StandardSubtitleDMSans text-semibold mt-2 text-underline"
        onClick={() => setStage(100)}
      >
        <FormattedMessage
          id="payments.dispute.back-button"
          defaultMessage={`< Back to last step`}
        />
      </button>

      <div className="d-flex flex-column px-2 mb-2">
        <h5 className="StandardTitleDMSans font-weight-500 mt-3 mb-1">
          <FormattedMessage
            id="payments.transactions.dispute.class-question"
            defaultMessage={`What reason best describes why you're disputing this charge?`}
          />
        </h5>
      </div>

      <SelectionBox
        title={
          <FormattedMessage
            id="payments.disputes.still-charged"
            defaultMessage={`I'm <b>still being charged</b> for a product or service.`}
            values={{ b: (chunks) => <b>{chunks}</b> }}
          />
        }
        description={
          <FormattedMessage
            id="payments.disputes.still-being-charged-description"
            defaultMessage={`This includes trial memberships, returns, cancellations, late/undelivered items, or similar situations.`}
          />
        }
        type={disputeTypes['still-charged']}
        item={'activeType'}
        activeType={activeType}
        onChange={onChange}
      />

      <SelectionBox
        title={
          <FormattedMessage
            id="payments.disputes.incorrectly-charged"
            defaultMessage={`I was <b>incorrectly charged</b> for a purchase.`}
            values={{ b: (chunks) => <b>{chunks}</b> }}
          />
        }
        description={
          <FormattedMessage
            id="payments.disputes.incorrectly-charged-description"
            defaultMessage={`This includes overcharges or multiple charges for the same transaction.`}
          />
        }
        type={disputeTypes['incorrectly-charged']}
        item={'activeType'}
        activeType={activeType}
        onChange={onChange}
      />

      <SelectionBox
        title={
          <FormattedMessage
            id="payments.disputes.suspected-fraud"
            defaultMessage={`I <b>did not authorize this transaction</b>`}
            values={{ b: (chunks) => <b>{chunks}</b> }}
          />
        }
        description={
          <FormattedMessage
            id="payments.disputes.suspected-fraud-description"
            defaultMessage={`This includes situations where you don't recognize this charge at all.`}
          />
        }
        type={disputeTypes['suspected-fraud']}
        item={'activeType'}
        activeType={activeType}
        onChange={onChange}
      />

      <div className="d-flex justify-content-around px-2 mt-3 mb-2">
        <button
          className="StandardButtonWhite Payments-modalButton text-size-08 flex-grow-1 py-1 mr-3"
          onClick={() => closeModal()}
        >
          <FormattedMessage id="payments.dispute.nevermind" defaultMessage={`NEVERMIND`} />
        </button>
        <button
          className={classNames(
            'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1',
          )}
          onClick={() => setStage(300)}
        >
          <FormattedMessage id="payments.dispute.continue" defaultMessage={`CONTINUE`} />
        </button>
      </div>
    </>
  )
}

const SelectSubtypeStage3 = ({
  transaction,
  disputSubTypes,
  onChange,
  activeType,
  activeSubtype,
  setStage,
  closeModal,
}) => {
  const advanceStage = (activeSubtype) => {
    if (activeSubtype === 'charged-for-higher-amount') {
      setStage(400)
    } else {
      setStage(500)
    }
  }

  return (
    <>
      <TransactionDescriptionBlock transaction={transaction} />

      <button
        className="StandardButtonNoStyles StandardSubtitleDMSans text-semibold mt-2 text-underline"
        onClick={() => setStage(200)}
      >
        <FormattedMessage
          id="payments.dispute.back-button"
          defaultMessage={`< Back to last step`}
        />
      </button>

      {activeType !== 'suspected-fraud' && (
        <div className="d-flex flex-column px-2 mt-2 mb-2">
          <h5 className="StandardTitleDMSans font-weight-500 mt-3 mb-1">
            <FormattedMessage
              id="payments.transactions.dispute.subtype-question"
              defaultMessage={`Which of these most closely resembles your situation?`}
            />
          </h5>
        </div>
      )}

      {activeType === 'still-charged' && (
        <>
          <SelectionBox
            title={
              <FormattedMessage
                id="payments.disputes.charged-for-cancelled-subscription"
                defaultMessage={`I was <b>charged for a recurring transaction or trial subscription that I cancelled.</b>.`}
                values={{ b: (chunks) => <b>{chunks}</b> }}
              />
            }
            type={disputSubTypes['charged-for-cancelled-subscription']}
            item={'activeSubtype'}
            activeType={activeSubtype}
            onChange={onChange}
          />
          <SelectionBox
            title={
              <FormattedMessage
                id="payments.disputes.charged-for-return-or-cancelled"
                defaultMessage={`I returned or cancelled a one-time purchase and haven't received credit.`}
              />
            }
            type={disputSubTypes['charged-for-return-or-cancelled']}
            item={'activeSubtype'}
            activeType={activeSubtype}
            onChange={onChange}
          />
          <SelectionBox
            title={
              <FormattedMessage
                id="payments.disputes.charged-for-late-never-provided"
                defaultMessage={`The merchant was late or never provided the product or service.`}
              />
            }
            type={disputSubTypes['charged-for-late-never-provided']}
            item={'activeSubtype'}
            activeType={activeSubtype}
            onChange={onChange}
          />
          <SelectionBox
            title={
              <FormattedMessage
                id="payments.disputes.charged-for-dissatisfied-product"
                defaultMessage={`I'm dissatisfied with the quality of the product or service I received.`}
              />
            }
            type={disputSubTypes['charged-for-dissatisfied-product']}
            item={'activeSubtype'}
            activeType={activeSubtype}
            onChange={onChange}
          />
        </>
      )}

      {activeType === 'incorrectly-charged' && (
        <>
          <SelectionBox
            title={
              <FormattedMessage
                id="payments.disputes.charged-for-higher-amount"
                defaultMessage={`I was <b>charged a higher amount</b> than expected.`}
                values={{ b: (chunks) => <b>{chunks}</b> }}
              />
            }
            type={disputSubTypes['charged-for-higher-amount']}
            item={'activeSubtype'}
            activeType={activeSubtype}
            onChange={onChange}
          />
          <SelectionBox
            title={
              <FormattedMessage
                id="payments.disputes.charged-multiple-times"
                defaultMessage={`The <b>same card</b> was <b>charged more than once</b> .`}
                values={{ b: (chunks) => <b>{chunks}</b> }}
              />
            }
            type={disputSubTypes['charged-multiple-times']}
            item={'activeSubtype'}
            activeType={activeSubtype}
            onChange={onChange}
          />
          <SelectionBox
            title={
              <FormattedMessage
                id="payments.disputes.charged-despite-using-cash"
                defaultMessage={`My card was charged even though I used <b>cash or a different card</b>.`}
                values={{ b: (chunks) => <b>{chunks}</b> }}
              />
            }
            type={disputSubTypes['charged-despite-using-cash']}
            item={'activeSubtype'}
            activeType={activeSubtype}
            onChange={onChange}
          />
        </>
      )}

      {activeType === 'suspected-fraud' && (
        <div className="StandardBodyDMSans text-center mx-2 py-5 my-5">
          <FormattedMessage
            id="payments.disputes.fraud-suspected"
            defaultMessage={`If you believe your card was used fraudulently, please call 1-866-2-TAEKUS immediately. You can also lock your card on your home page to temporarily prevent further transactions if your card was lost or stolen. `}
          />
        </div>
      )}

      <div className="d-flex justify-content-around px-2 mt-4 mb-2">
        <button
          className="StandardButtonWhite Payments-modalButton text-size-08 flex-grow-1 py-1 mr-3"
          onClick={() => closeModal()}
        >
          <FormattedMessage id="payments.dispute.nevermind" defaultMessage={`NEVERMIND`} />
        </button>
        <button
          className={classNames(
            'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1',
          )}
          onClick={() => advanceStage(activeSubtype)}
        >
          <FormattedMessage id="payments.dispute.continue" defaultMessage={`CONTINUE`} />
        </button>
      </div>
    </>
  )
}

const SubmitExtraInfoStage4 = ({
  transaction,
  setStage,
  expectedCharge,
  handleCurrencyChange,
  closeModal,
}) => {
  return (
    <>
      <TransactionDescriptionBlock transaction={transaction} />

      <button
        className="StandardButtonNoStyles StandardSubtitleDMSans text-semibold mt-2 text-underline"
        onClick={() => setStage(300)}
      >
        <FormattedMessage
          id="payments.dispute.back-to-specific-reason"
          defaultMessage={`< Back to Specific Dispute Reason`}
        />
      </button>

      <div className="Payments-modalInputContainer d-flex flex-column px-2 mt-2 mb-2">
        <h5 className="StandardTitleDMSans font-weight-500 mt-3 mb-1">
          <FormattedMessage
            id="payments.transactions.dispute.incorrect-charge-question"
            defaultMessage={`I was charged a higher amount than expected. I expected to be charged:`}
          />
        </h5>

        <CurrencyInput
          className="Payments-modalInput flex-grow mt-2 p-2"
          prefix="$"
          onChangeEvent={(event, maskedValue, floatValue) =>
            handleCurrencyChange(event, maskedValue, floatValue, 'expectedCharge')
          }
          value={expectedCharge}
        />
      </div>

      <div className="d-flex justify-content-around px-2 mt-4 mb-2">
        <button
          className="StandardButtonWhite Payments-modalButton text-size-08 flex-grow-1 py-1 mr-3"
          onClick={() => closeModal()}
        >
          <FormattedMessage id="payments.dispute.nevermind" defaultMessage={`NEVERMIND`} />
        </button>
        <button
          className={classNames(
            'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1',
          )}
          onClick={() => setStage(500)}
        >
          <FormattedMessage id="payments.dispute.continue" defaultMessage={`CONTINUE`} />
        </button>
      </div>
    </>
  )
}

const AdditionalDetailsStage5 = ({
  transaction,
  setStage,
  additionalInformation,
  activeSubtype,
  onChange,
  closeModal,
}) => {
  const backOneStage = (activeSubtype) => {
    if (activeSubtype === 'charged-for-higher-amount') {
      setStage(400)
    } else {
      setStage(300)
    }
  }

  return (
    <>
      <TransactionDescriptionBlock transaction={transaction} />

      <button
        className="StandardButtonNoStyles StandardSubtitleDMSans text-semibold mt-2 text-underline"
        onClick={() => backOneStage(activeSubtype)}
      >
        {activeSubtype === 'charged-for-higher-amount' ? (
          <FormattedMessage
            id="payments.dispute.back-to-expected-charge"
            defaultMessage={`< Back to Expected Charge`}
          />
        ) : (
          <FormattedMessage
            id="payments.dispute.back-to-specific-reason"
            defaultMessage={`< Back to Specific Dispute Reason`}
          />
        )}
      </button>

      <div className="Payments-modalInputContainer d-flex flex-column px-2 mt-2 mb-2">
        <h5 className="StandardTitleDMSans font-weight-500 mt-3 mb-1">
          <FormattedMessage
            id="payments.transactions.dispute.additional-details"
            defaultMessage={`Please add any additional details about your dispute that were missed here:`}
          />
        </h5>

        <Input
          className="Payments-modalInput Payments-modalTextArea flex-grow mt-2 p-2"
          type="textarea"
          onChange={(e) => onChange(e.target.value, 'additionalInformation')}
          placeholder={additionalInformation}
          maxLength={400}
        />
      </div>

      <div className="d-flex justify-content-around px-2 mt-4 mb-2">
        <button
          className="StandardButtonWhite Payments-modalButton text-size-08 flex-grow-1 py-1 mr-3"
          onClick={() => closeModal()}
        >
          <FormattedMessage id="payments.dispute.nevermind" defaultMessage={`NEVERMIND`} />
        </button>
        <button
          className={classNames(
            'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1',
          )}
          onClick={() => setStage(600)}
        >
          <FormattedMessage id="payments.dispute.continue" defaultMessage={`CONTINUE`} />
        </button>
      </div>
    </>
  )
}

const ConfirmDetailsStage6 = ({ transaction, workingCopy, setStage, submit, closeModal }) => {
  const { activeType, activeSubtype, expectedCharge, additionalInformation } = workingCopy

  return (
    <>
      <TransactionDescriptionBlock transaction={transaction} />

      <button
        className="StandardButtonNoStyles StandardSubtitleDMSans text-semibold mt-2 text-underline"
        onClick={() => setStage(500)}
      >
        <FormattedMessage
          id="payments.dispute.back-button"
          defaultMessage={`< Back to additional details`}
        />
      </button>

      <div className="d-flex flex-column flex-grow-1 mt-4 mx-3">
        <div className="StandardSubtitleDMSans transactionBox-title">
          <FormattedMessage
            id="payments.transactions.dispute.reason-for-dispute"
            defaultMessage={`Reason For Dispute`}
          />
        </div>
        <div className="StandardBodyDMSans mb-3">
          <FormattedMessage
            id={`payments.disputes.${activeType}`}
            values={{ b: (chunks) => <b>{chunks}</b> }}
          />
        </div>

        {activeSubtype && (
          <>
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.specifically"
                defaultMessage={`Specifically`}
              />
            </div>
            <div className="StandardBodyDMSans mb-3">
              <FormattedMessage
                id={`payments.disputes.${activeSubtype}`}
                values={{ b: (chunks) => <b>{chunks}</b> }}
              />
            </div>
          </>
        )}

        {expectedCharge && (
          <>
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.amount-I-expected"
                defaultMessage={`The amount I expected to be charged was`}
              />
            </div>
            <div className="StandardBodyDMSans mb-3">
              <NumberFormat
                value={expectedCharge}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            </div>
          </>
        )}

        <div className="StandardSubtitleDMSans transactionBox-title">
          <FormattedMessage
            id="payments.transactions.dispute.additional-information-summary"
            defaultMessage={`Additional Information Provided:`}
          />
        </div>
        <div className="StandardBodyDMSans">
          <div className="StandardBodyDMSans mb-3">{additionalInformation}</div>
        </div>
      </div>

      <div className="d-flex justify-content-around px-2 mt-4 mb-2">
        <button
          className="StandardButtonWhite Payments-modalButton text-size-08 flex-grow-1 py-1 mr-3"
          onClick={() => closeModal()}
        >
          <FormattedMessage id="payments.dispute.nevermind" defaultMessage={`NEVERMIND`} />
        </button>
        <button
          className={classNames(
            'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1',
          )}
          onClick={() => submit()}
        >
          <FormattedMessage
            id="payments.dispute.submit-dispute"
            defaultMessage={`SUBMIT DISPUTE`}
          />
        </button>
      </div>
    </>
  )
}

const SummaryStage7 = ({ transaction, submittedDispute, closeModal, isLoading, error }) => {
  const { date, resolutionDate, id } = submittedDispute

  if (error === true) {
    return (
      <div className="d-flex flex-column text-center px-2 py-5 my-5">
        <h5 className="StandardTitleDMSans font-weight-500 my-5 mt-3">
          <FormattedMessage
            id="payments.transactions.dispute.error"
            defaultMessage={`Oops, it appears that there has been an error submitting your dispute. Please try again later or call 1-866-2-TAEKUS.`}
          />
        </h5>
      </div>
    )
  } else if (isLoading === true) {
    return (
      <div className="px-2 py-5 my-5 text-center">
        <h5 className="StandardTitleDMSans font-weight-500 my-5">
          <FormattedMessage
            id="payments.transactions.dispute.loading"
            defaultMessage={`Submitting dispute. Please wait.....`}
          />
        </h5>
      </div>
    )
  }

  return (
    <>
      <div className="d-flex flex-column px-2 my-2">
        <h5 className="StandardTitleDMSans font-weight-500 mt-3">
          <FormattedMessage
            id="payments.transactions.dispute.thanks"
            defaultMessage={`Thanks! You have successfully submitted your dispute.`}
          />
        </h5>

        <div className="StandardBodyDMSans mt-3">
          <FormattedMessage
            id="payments.transactions.dispute.timeline"
            defaultMessage={`It'll appear on your transactions in 24-48 hours.`}
          />
        </div>

        <img
          className="my-3 mx-5 pl-3 pr-4"
          src={Checkmark}
          alt="Success Glyph"
        />

        <div className="d-flex flex-row mr-4">
          <div className="d-flex flex-column flex-grow-1">
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.submitted-date"
                defaultMessage={`Dispute Submitted`}
              />
            </div>
            <div className="StandardBodyDMSans">{moment(date).format('MMMM D, YYYY')}</div>
          </div>
          <div className="d-flex flex-column">
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.projected-date"
                defaultMessage={`Projected Resolution Date`}
              />
            </div>
            <div className="StandardBodyDMSans">
              {moment(resolutionDate).format('MMMM D, YYYY')}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row mt-2">
          <div className="d-flex flex-column flex-grow-1">
            <div className="StandardSubtitleDMSans transactionBox-title">
              <FormattedMessage
                id="payments.transactions.dispute.reference-number"
                defaultMessage={`Reference Number`}
              />
            </div>
            <div className="StandardBodyDMSans">{id}</div>
          </div>
        </div>

        <div className="StandardBodyDMSans mt-3">
          <FormattedMessage
            id="payments.transactions.dispute.dispute-submitted-explainer"
            defaultMessage={`We'll begin our investigation and get back to you with any updates or notes sent to your email address on file. We'll do our best to resolve this by the projected resolution date, but complex cases may require more time.`}
          />
        </div>
        <div className="StandardBodyDMSans my-3">
          <FormattedMessage
            id="payments.transactions.dispute.submitted-timeline"
            defaultMessage={`You can also stay up-to-date with your dispute by viewing details about this specific transaction.`}
          />
        </div>
      </div>

      <div className="d-flex justify-content-around px-2 mt-4 mb-2">
        {/*<button*/}
        {/*className="StandardButtonWhite Payments-modalButton text-size-08 flex-grow-1 py-1 mr-3"*/}
        {/*// onClick={() => closeModal()}*/}
        {/*>*/}
        {/*<FormattedMessage*/}
        {/*id="payments.dispute.print-confirmation"*/}
        {/*defaultMessage={`PRINT CONFIRMATION`}*/}
        {/*/>*/}
        {/*</button>*/}
        <button
          className={classNames(
            'StandardButtonBlue Payments-modalButton text-size-08 flex-grow-1 py-1',
          )}
          onClick={() => closeModal()}
        >
          <FormattedMessage id="payments.dispute.done" defaultMessage={`DONE`} />
        </button>
      </div>
    </>
  )
}

export default DisputeModal
