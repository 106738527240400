export const getPrimaryAddressAptNumPrefixOptions = () => {
  return [
    { value: '', label: 'Prefix' },
    { value: '#', label: '#' },
    { value: 'APT', label: 'APT' },
    { value: 'BLDG', label: 'BLDG' },
    { value: 'DEPT', label: 'DEPT' },
    { value: 'FL', label: 'FL' },
    { value: 'RM', label: 'RM' },
    { value: 'STE', label: 'STE' },
    { value: 'UNIT', label: 'UNIT' },
  ]
}

export const getBusinessStructureOptions = () => {
  return [
    { value: 'LLC', label: 'LLC' },
    { value: 'C_CORP', label: 'C Corp' },
    { value: 'S_CORP', label: 'S Corp' },
    { value: 'SOLE_PROPRIETORSHIP', label: 'Sole Proprietorship' },
    { value: 'PARTNERSHIP', label: 'Partnership' },
  ]
}
