import React, { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { debounce } from 'utils/debounce';

import styled from "styled-components"

import { Actions as UserActions } from "redux/currentUser";
import { Actions as BankingActions } from "redux/features/banking/banking"
import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import Select from 'components/common/Select/Select'
import selectStyles from "components/common/Select/selectStyles";
import Skeleton from "components/common/Skeleton";
import Spinner from "components/common/Spinner"

import ActivateCardModal from "components/pages/Settings/CardSettings/ActivateCardModal";
import { LinkText } from "components/pages/Settings/constants";

import styles from "styles/styles"

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";

const CardSettings = () => {
    // Redux state
    const dispatch = useDispatch()
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const parentUser = currentUser.parentUser
    const banking = useSelector((state: any) => state.banking)
    const lastFetchedAccountUuid = useSelector((state: any) => state.paymentCards.paymentCards.lastFetchedAccountUuid)

    // Component state
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [defaultCardAccountUuid, setDefaultCardAccountUuid] = useState(currentUser.primaryCardAccount)
    const [accountNickname, setAccountNickname] = useState(banking.account.nickname || '')

    const pinIsSet = !banking.account.needsPinSet

    const cardAccountOptions = currentUser?.cardAccounts.map((account: any) => ({ 
        value: account.uuid,
        label: `${account.name} ...${account.accountLast4}`,
    }))

    const toggleIsModalOpen = () => {
        if (!isModalOpen) {
            dispatch(PaymentCardActions.clearSetPinState())
            dispatch(PaymentCardActions.clearActivateCard())
        }
        setIsModalOpen(!isModalOpen)
    }

    const updateDefaultCardAccount = (option: any) => {
        dispatch(UserActions.saveCurrentUser({ 
            primaryCardAccount: option.value,
            username: currentUser.username
        }))
        setDefaultCardAccountUuid(option.value)
    }

    const updateCardNickname = (nickname: string) => {
        dispatch(BankingActions.updateCardAccount({
            cardAccountUuid: banking.account.uuid,
            nickname
        }))
    }

    const debouncedUpdateCardNickname = useCallback(debounce(updateCardNickname, 2000), [banking.account.uuid]) // eslint-disable-line

    const handleAccountNicknameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        // API is capped at 50 characters
        if (ev.target.value.length <= 50) {
            setAccountNickname(ev.target.value)
            debouncedUpdateCardNickname(ev.target.value)   
        }
    }

    useEffect(() => {
        if (currentUser.primaryCardAccount) {
            setDefaultCardAccountUuid(currentUser.primaryCardAccount)
        }
    }, [currentUser.primaryCardAccount])

    useEffect(() => {
        setAccountNickname(banking.account.nickname || '')
    }, [banking.account.nickname])

    // Fetch the list of cards for the current account if they aren't yet populated
    useEffect(() => {
        const isCardsAlreadyLoaded = lastFetchedAccountUuid === banking.account.uuid

        if (!isCardsAlreadyLoaded) {
            dispatch(PaymentCardActions.fetchPaymentCards({ cardAccountUuid: banking?.account.uuid }, false))
        }
    }, [banking.account.uuid, dispatch]) // eslint-disable-line

    return <div>
        <Title>Card Settings</Title>
        {parentUser ? <HelpText><b>Acting as an authorized user for {parentUser.firstName} {parentUser.lastName}</b></HelpText>:<></>}
        {currentUser?.cardAccounts.length > 1 && <SettingItem>
            <div>Default Card Account</div>
            <Select
                value={cardAccountOptions.find((option: any) => option.value === defaultCardAccountUuid)}
                onChange={updateDefaultCardAccount}
                options={cardAccountOptions}
                styleType={selectStyles.Settings}
                dropdownIndicator={<CaretWrapper><StyledCaretDown/></CaretWrapper>}
            />
        </SettingItem>}
        {currentUser?.cardAccounts.length > 1 && <SettingItem>
            <div>Account Nickname</div>
            <CustomInput
                inputLength={accountNickname.length}
                value={accountNickname}
                onChange={handleAccountNicknameChange}
            />
        </SettingItem>}
        <SettingItem>
            {banking.isLoading ? <Skeleton width='160px' height="24px"/> : <div>{banking.account.needsPhysicalCardActivation ? 'Activate Physical Card' : `${pinIsSet ? 'Reset' : 'Set'} Card PIN`}</div>}
            {banking.isLoading ? <Spinner size={20}/> : <LinkText onClick={toggleIsModalOpen}>{banking.account.needsPhysicalCardActivation ? 'Activate' : (pinIsSet ? 'Reset PIN' : 'Set PIN')} </LinkText>}
        </SettingItem>
        {isModalOpen && <ActivateCardModal onClose={toggleIsModalOpen} />}
    </div>
}

const CaretWrapper = styled.div`
    height: 100%;
    padding: 0 5px;
    margin-bottom: 5px;
`

const StyledCaretDown = styled(CaretDown)`
    width: 10px;
    height: 5px;
`

type CustomInputProps = {
    inputLength: number,
}

const CustomInput = styled.input<CustomInputProps>`
    outline: 0;
    border-radius: 0;
    background-color: transparent;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${styles.Color.Grey};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 160px;
    font-size: 14px;
    text-overflow: ellipsis;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const HelpText = styled.div`
     ${styles.Text.BodySmall}
    font-size: ${styles.Font.Size.Small};
    padding-top: ${styles.Spacing.S};
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
`

const SettingItem = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk}
    display: flex;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(&:first-child) {
        border-top: 1px solid #D7D7D7;
    }
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    min-width: 200px;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

export default CardSettings