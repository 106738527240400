import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import moment from "moment";

import styled from "styled-components";

import { AppPath } from "components/appRouter/constants";
import { getPartnerLocator, getTicketStatusLabel, isTicketStatusError } from "components/pages/Trips/utils";

import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";

import styles from "styles/styles";

export enum TripCardType {
    Small,
    Full,
    Canceled,
}

type TripCardProps = {
    trip?: any,
    type: TripCardType,
}

const isTicketAvailable = (trip: any) => {
    return trip.ticketStatus !== "UNAVAILABLE" && trip.ticketStatus !== "CANCELLED";
}

const TripCard = (props: TripCardProps) => {
    const history = useHistory()
    const isMobile = useSelector((state: any) => state.global.isMobile)

    const imageRef = useRef(null)
    const [isUsingPlaceholderImage, setIsUsingPlaceholderImage] = useState(false)

    const { type, trip } = props;

    const inspectTrip = () => {
        history.push(`${AppPath.MyTrips}/${trip.uuid}`)
    }

    /*
     *  Load images via Javascript to see if an error occurs
     *  • If it does, set the placeholder image and boolean to true to render Taekus logo
     *  • If it doesn't fail, set the unique destination image we loaded
     * 
     *  note: When the image is used for multiple trips, it is only loaded via network once,
     *    but there will be a network failure for each placeholder
     */
    useEffect(() => {
        if (imageRef.current) {
            const image = new Image();
            const airportCode = trip.reservations[0]?.itineraries[0]?.segments.at(-1)?.destinationCity

            image.onload = (a: any) => {
                if (imageRef.current) {
                    (imageRef.current as any).style.backgroundImage = 'url(' + image.src + ')';
                    (imageRef.current as any).style.opacity = 1;
                }
            }
            image.onerror = () => {
                setIsUsingPlaceholderImage(true);
                if (imageRef.current) {
                    (imageRef.current as any).style.backgroundImage = `url("https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/tripPlaceholder.jpg")`;
                    (imageRef.current as any).style.opacity = 1;
                }
            }
            image.src = `https://taekus-image-assets.s3.us-east-2.amazonaws.com/destinations/${airportCode}1.jpg`;
        }
    }, []) // eslint-disable-line

    return type === TripCardType.Full || isMobile ? <FullSizeContainer onClick={inspectTrip} key={trip.uuid}>
        <div className='d-flex'>
            <FullSizeTripImageContainer>
                {<FullSizeTripImage ref={imageRef} airportCode={trip.reservations[0]?.itineraries[0]?.segments.at(-1)?.destinationCity}>
                    {isUsingPlaceholderImage && <CustomTaekusLogo fill='white'/>}
                </FullSizeTripImage>}
            </FullSizeTripImageContainer>
        </div>
        <FullSizeCardContent>
            <FullSizeTripHeader>
                <FullSizeTripTitle>{trip.tripName || `${trip?.reservations[0].itineraries[0]?.segments[0]?.originCityName} to ${trip?.reservations[0].itineraries[0]?.segments.at(-1)?.destinationCityName}`}</FullSizeTripTitle>
                <LargeAirports>{trip?.reservations[0].itineraries[0]?.segments[0]?.origin} - {trip?.reservations[0].itineraries[0]?.segments.at(-1)?.destination}</LargeAirports>
            </FullSizeTripHeader>
            <FullSizeTripDetails>
                <TripLocator>
                    <PNRContainer>
                        {trip.taekusId && <Text>
                            <PNR>{trip.taekusId}</PNR>
                        </Text>}
                        <Text error={isTicketStatusError(trip)}>{getTicketStatusLabel(trip?.ticketStatus)}</Text>
                    </PNRContainer>
                    <div>
                        {getPartnerLocator(trip) && <Text>
                            <strong>{getPartnerLocator(trip)}</strong>
                        </Text>}
                    </div>
                </TripLocator>
                <TripItineraryContainer>
                    <TripItinerary>
                        <Text><strong>Departing</strong></Text>
                        <Text>{moment(trip.reservations[0].itineraries[0].segments[0]?.localDepartureTimeDate).format('MMM D, YYYY')}</Text>
                        <Text>{moment(trip.reservations[0].itineraries[0].segments[0]?.localDepartureTimeDate).format('h:mm A')} - {moment(trip.reservations[0].itineraries[0].segments.at(-1)?.localArrivalTimeDate).format('h:mm A')}</Text>
                    </TripItinerary>
                    <TripItinerary style={{visibility: trip?.reservations[0].itineraries.length > 1 ? 'visible' : 'hidden'}}>
                        <Text><strong>Returning</strong></Text>
                        <Text>{moment(trip.reservations.at(-1).itineraries.at(-1)?.segments[0]?.localDepartureTimeDate).format('MMM D, YYYY')}</Text>
                        <Text>{moment(trip.reservations.at(-1).itineraries.at(-1)?.segments[0]?.localDepartureTimeDate).format('h:mm A')} - {moment(trip.reservations[0].itineraries.at(-1)?.segments?.localArrivalTimeDate).format('h:mm A')}</Text>
                    </TripItinerary>
                </TripItineraryContainer>
                <Travelers>
                    <Text>{trip?.reservations[0].travellers[0]?.firstName} {trip?.reservations[0].travellers[0]?.lastName}</Text>
                    <Text>{trip?.reservations[0].travellers.length > 1 && `+${trip?.reservations[0].travellers.length - 1} other${trip?.reservations[0].travellers.length > 2 ? 's' : ''}`}</Text>
                </Travelers>
            </FullSizeTripDetails>
        </FullSizeCardContent>
    </FullSizeContainer> : <SmallSizeContainer type={type} onClick={inspectTrip}>
        <SmallSizeTripImage ref={imageRef} airportCode={trip.reservations[0].itineraries[0].segments.at(-1).destinationCity}>
            {isUsingPlaceholderImage && <CustomTaekusLogo fill='white'/>}
        </SmallSizeTripImage>
        <SmallSizeCardContent>
            <SmallSizeTripTitle>{trip.tripName || trip?.reservations[0].itineraries[0]?.segments[0]?.originCityName} to {trip?.reservations[0].itineraries[0]?.segments?.at(-1)?.destinationCityName}</SmallSizeTripTitle>
            <SmallText>{trip?.reservations[0].itineraries[0]?.segments[0]?.origin} - {trip?.reservations[0].itineraries[0]?.segments?.at(-1).destination}</SmallText>
            {trip.pnr && <SmallText>{trip.pnr}</SmallText>}
            <SmallText>
                <strong>{moment(trip.reservations[0].itineraries[0]?.segments[0]?.localDepartureTimeDate).format('MMM DD, YYYY')} {trip.reservations[0].itineraries.length > 1 && `- ${moment(trip.reservations[0].itineraries.at(-1)?.segments[0]?.localDepartureTimeDate).format('MMM DD, YYYY')}`}</strong>
            </SmallText>
            {isTicketAvailable(trip) && (
                <>
                    <SmallText style={{marginBottom: 0}}>
                        Flown by {trip?.reservations[0].travellers[0]?.firstName} {trip?.reservations[0].travellers[0]?.lastName}
                        {trip?.reservations[0].travellers.length > 1 && `+ ${trip?.reservations[0].travellers.length} other${trip?.reservations[0].travellers.length > 2 ? 's' : ''}`}
                    </SmallText>
                    <SmallText>Booked on {moment(trip?.created).format('M/DD/YYYY')}</SmallText>
                </>
            )}
            {trip.ticketStatus === "UNAVAILABLE" && <SmallText style={{marginBottom: 0}}>Ticket was unavailable</SmallText>}
        </SmallSizeCardContent>
    </SmallSizeContainer>
}

const PNR = styled.div`
    font-weight: bold;
    ${styles.MediaQueries.Mobile} {
        margin-right: ${styles.Spacing.XS};
    }
`

const PNRContainer = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        border-bottom: 1px solid ${styles.Color.Grey};
        padding-bottom: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        flex-direction: column;
    }
`

const TripLocator = styled.div`
    min-width: 20%;
    margin: ${styles.Spacing.XS} 0;
    ${styles.MediaQueries.Desktop} {
        border-right: 1px solid ${styles.Color.Grey};
    }
`

const TripItinerary = styled.div`
    flex: 1;
    &:not(&:first-child) {
        padding-left: ${styles.Spacing.S};
    }
`

const TripItineraryContainer = styled.div`
    display: flex;
    min-width: min-content;
    margin: ${styles.Spacing.XS} 0;
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.S};
        border-right: 1px solid ${styles.Color.Grey};
    }
    ${styles.MediaQueries.Mobile} {
        padding-bottom: ${styles.Spacing.XS};
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const Travelers = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin: ${styles.Spacing.XS} ${styles.Spacing.S};
    } 
    ${styles.MediaQueries.Mobile} {
        margin: ${styles.Spacing.XS} 0;
    }
`

const FullSizeTripDetails = styled.div`
    height: 50%;
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const FullSizeTripHeader = styled.div`
    height: 50%;
    border-bottom: 1px solid ${styles.Color.Grey};
`

const FullSizeCardContent = styled.div`
    flex: 1;
`

const SmallSizeCardContent = styled.div`
    flex: 1;
    margin-left: ${styles.Spacing.S};
    overflow: hidden;
`

const CustomTaekusLogo = styled(TaekusIcon)`
    width: 30%;
    height: auto;
`

const SmallSizeTripImage = styled.div<TripImageProps>`
    min-height: 140px;
    min-width: 160px;
    aspect-ratio: 160/140;
    background-size: cover;
    background-position: center;
    opacity: 0;
    ${styles.Animation.transitionStyles}
    display: flex;
    justify-content: center;
    align-items: center;
`

const FullSizeTripImageContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`

type TripImageProps = {
    airportCode: string
}

const FullSizeTripImage = styled.div<TripImageProps>`
    background-size: cover;
    background-position: center;
    aspect-ratio: 260/180;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    opacity: 0;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Desktop} {
        height: 180px;
        width: 260px;
        margin-right: ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        height: auto;
    }
`

type TextProps = {
    error?: boolean,
}

const Text = styled.div<TextProps>`
    color: ${props => props.error ? styles.Color.GreyText : styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.16px;
`

const LargeAirports = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.16px;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.XS};
    }
`

const SmallText = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.14px;
    margin-bottom: 5px;
`

const FullSizeTripTitle = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: min-content;
    max-width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 44.64px */
    letter-spacing: 0.36px;
    border-bottom: 1px solid ${styles.Color.Black};
    margin-bottom: ${styles.Spacing.XS};
    white-space: norap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${styles.MediaQueries.Desktop} {
        font-size: 36px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 1.8rem;
        margin: ${styles.Spacing.XS} 0;
    }
`

const SmallSizeTripTitle = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 44.64px */
    letter-spacing: 0.36px;
    border-bottom: 1px solid ${styles.Color.Black};
    margin-bottom: ${styles.Spacing.XS};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: min-content;
`

const FullSizeContainer = styled.div`
    display: flex;
    margin: ${styles.Spacing.XS} 0;
    cursor: pointer;
    border: 1px solid ${styles.Color.Transparent};
    max-width: 100%;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

type SmallSizeContainerProps = {
    type: TripCardType
}

const SmallSizeContainer = styled.div<SmallSizeContainerProps>`
    height: 140px;
    display: flex;
    margin: ${styles.Spacing.XS} ${styles.Spacing.S} ${styles.Spacing.XS} 0;
    opacity: ${props => props.type === TripCardType.Small ? 1 : 0.6};
    cursor: pointer;
    border: 1px solid ${styles.Color.Transparent};
    overflow: hidden;
    &:hover {
        opacity: 1;
    }
`

export default TripCard