import React from 'react'

import Session from 'services/session'

import { FormattedMessage } from 'react-intl'

import background from 'assets/global/img/generic-background-clouds.jpeg'

const logout = (e) => {
  e.preventDefault()
  Session.logout()
  window.location.replace('/login/')
}

const NoCardAccount = ({ application, isClosedAccount }) => {
  let currentStage = null
  if (application != null) {
    currentStage = application.currentStage
  }

  return (
    <>
      {currentStage === 'DENIED' || isClosedAccount ? (
        <h1
          style={{ backgroundImage: `url(${background})`, color: 'white', bottom: '0' }}
          className="w-100 h-100 d-flex flex-column justify-content-center align-items-center position-fixed"
        >
          <div className="text-center">
            <FormattedMessage
              id="no-card-account.message"
              defaultMessage={`We are sorry but we are unable to offer you an account at this time.`}
            />
          </div>
          <button
            className="StandardButtonWhiteGold shadow-none StandardBodyDMSans mt-5"
            onClick={(e) => logout(e)}
          >
            <FormattedMessage id="no-card-account.logout" defaultMessage={`Logout`} />
          </button>
        </h1>
      ) : (
        <h1
          style={{ backgroundImage: `url(${background})`, color: 'white', bottom: '0' }}
          className="w-100 h-100 d-flex flex-column justify-content-center align-items-center position-fixed"
        >
          <div className="text-center">
            <FormattedMessage
              id="no-card-account.message"
              defaultMessage={`Your account is not fully setup right now. Please check again later or contact support at support@taekus.com`}
            />
          </div>
          <button
            className="StandardButtonWhiteGold shadow-none StandardBodyDMSans mt-5"
            onClick={(e) => logout(e)}
          >
            <FormattedMessage id="no-card-account.logout" defaultMessage={`Logout`} />
          </button>
        </h1>
      )}
    </>
  )
}

export default NoCardAccount
