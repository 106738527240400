import React from "react"

import { Moment } from "moment"
import { styled } from "styled-components"

import { default as ReactDatePicker } from "react-datepicker"

import 'assets/global/css/reactDatePicker.scss'
import styles from "styles/styles"

type DatePickerProps = {
    selected: Moment | undefined,
    startDate: Moment | undefined,
    onChange: (date: any) => void,
    customInput: React.ReactNode,
    endDate?: Moment | undefined,
    minDate?: Moment,
    maxDate?: Moment,
    selectsRange?: boolean,
    id?: string,
    disabled?: boolean,
}

const DatePicker = (props: DatePickerProps) => {
    const renderDaysContents = (day: number) => <span>{day}</span>

    return <Container disabled={props.disabled} className='datePicker'>
        <ReactDatePicker
            disabled={props.disabled}
            id={props.id}
            selected={props.selected?.toDate()}
            startDate={props.startDate?.toDate()}
            endDate={props.endDate?.toDate()}
            onChange={props.onChange}
            customInput={props.customInput}
            minDate={props.minDate?.toDate()}
            maxDate={props.maxDate?.toDate()}
            selectsRange={props.selectsRange}
            disabledKeyboardNavigation
            renderDayContents={renderDaysContents}
            popperProps={{strategy: 'fixed'}}
        />
    </Container>
}

type ContainerProps = {
    disabled?: boolean,
}
const Container = styled.div<ContainerProps>`
    ${props => props.disabled && `
        /* todo: style disabled datePicker */
        background-color: rgba(0,0,0,0.05);
    `}
    ${styles.Animation.transitionStyles}
`

export default DatePicker