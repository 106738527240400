import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { AnimatePresence, motion } from "framer-motion"
import moment from "moment"

import { styled } from "styled-components"

import { Actions as BookingActions } from 'redux/features/flightBook'
import { ScreenSize } from "redux/features/global/types"

import ManageTraveler from "components/pages/Rewards/Flights/FlightBooking/ManageTraveler"

import DeletePassengerModal from "components/pages/Settings/Passengers/DeletePassengerModal"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as ArrowRight } from "assets/svg/ArrowRight.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";

const Passengers = () => {
    const dispatch = useDispatch()

    const screenSize = useSelector((state: any) => state.global.screenSize)
    const flightBook = useSelector((state: any) => state.flightBook)

    const [isEditing, setIsEditing] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [selectedTraveler, setSelectedTraveler] = useState<any>(null)

    useEffect(() => {
        dispatch(BookingActions.getTravelers())
    }, [])

    const getPassportLabel = (passenger: any) => {
        const isPassportPartiallyEntered = passenger.passportNumber || passenger.passportCountry || passenger.passportExpDate
        const isPassportFullyEntered = passenger.passportNumber && passenger.passportCountry && passenger.passportExpDate

        if (isPassportFullyEntered) {
            return `Added ${passenger.passportImage ? '+ Photo' : ''}`
        } else if (isPassportPartiallyEntered) {
            return 'Incomplete'
        } else {
            return 'Not added'
        }
    }

    const getGenderLabel = (gender: string) => {
        return gender === 'M' ? 'Male' : 'Female';
    }

    const createPassenger = () => {
        setIsEditing(true)
    }

    const editPassenger = (passenger: any) => {
        setSelectedTraveler(JSON.parse(JSON.stringify(passenger)))
        setIsEditing(true)
    }

    const addNewPassenger = (passenger: any) => {
        dispatch(BookingActions.createTraveler(passenger))
    }

    const closeDeleteModal = () => {
        setSelectedTraveler(null);
        setIsDeleteModalOpen(false);
    }

    const updatePassenger = (passenger: any) => {
        const traveler = flightBook.bookingTravelers.find((traveler: any) => traveler.uuid === selectedTraveler?.uuid)
        const travelerIndex = flightBook.bookingTravelers.findIndex((traveler: any) => traveler.uuid === selectedTraveler?.uuid)
        if (traveler && traveler?.uuid) {
            const deleteTravellerParams = { uuid: traveler.uuid }
            dispatch(BookingActions.updateTraveler(deleteTravellerParams, passenger, travelerIndex))
        }
    }

    const closeEditor = () => {
        setSelectedTraveler(null)
        setIsEditing(false)
    }

    const mapPassengerToRow = (passenger: any, index: number) => {
        const handlePassengerClick = () => { editPassenger(passenger) };
        const handleDeleteClick = (ev: React.MouseEvent) => {
            setSelectedTraveler(passenger);
            setIsDeleteModalOpen(true);
            ev.stopPropagation();
        }

        return <Passenger onClick={handlePassengerClick}>
            {screenSize === ScreenSize.Mobile ? <PassengerDetail>
                <div className="d-flex justify-content-between">
                    <MobileCellColumn>
                        <div>{passenger.firstName} {passenger.lastName}</div>
                        <div>{passenger.dateOfBirth}</div>
                        <div>{getGenderLabel(passenger.gender)}</div>
                    </MobileCellColumn>
                    <MobileCellColumn>
                        <StyledClose onClick={handleDeleteClick}/>
                    </MobileCellColumn>
                </div>
            </PassengerDetail> : <>
                <PassengerDetail>{passenger.firstName} {passenger.lastName}</PassengerDetail>
                <PassengerDetail>{moment(passenger.dateOfBirth).format('MMM D, YYYY')}</PassengerDetail>
                <PassengerDetail>{getGenderLabel(passenger.gender)}</PassengerDetail>
                <PassengerDetail>{getPassportLabel(passenger)}</PassengerDetail>
                <PassengerDetail>
                    <ButtonRow>
                        <StyledClose onClick={handleDeleteClick}/>
                        <StyledArrowRight/>
                    </ButtonRow>
                </PassengerDetail>
            </>}
        </Passenger>
    }

    useEffect(() => {
        const travelerSuccessfullyCreated = !flightBook.createTravelerIsLoading && !flightBook.createTravelerErrors
        if (travelerSuccessfullyCreated) {
            setIsEditing(false)
        }
    }, [flightBook.createTravelerIsLoading, flightBook.createTravelerErrors]); // eslint-disable-line

    useEffect(() => {
        const travelerSuccessfullyUpdated = !flightBook.updateTravelerIsLoading && !flightBook.updateTravelerErrors
        if (travelerSuccessfullyUpdated) {
            setSelectedTraveler(null)
            setIsEditing(false)
        }
    }, [flightBook.updateTravelerErrors, flightBook.updateTravelerIsLoading]); // eslint-disable-line

    return <div>
        <Title>Passengers</Title>
        <AnimatePresence mode="wait">
            {isEditing ? <TravelerEditorContainer>
                <ManageTraveler
                    onClose={closeEditor}
                    onSave={selectedTraveler ? updatePassenger : addNewPassenger}
                    inspectedTraveler={selectedTraveler}
                />
            </TravelerEditorContainer> : <div>
                <AddPassengerButtonContainer>
                    <AddPassengerButton onClick={createPassenger}>+ Add Passenger</AddPassengerButton>
                </AddPassengerButtonContainer>
                <PassengerTable key='table' {...fadeInOutMotionProps}>
                    <tbody>
                        {screenSize !== ScreenSize.Mobile && <TableHeader>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell>Date of Birth</HeaderCell>
                            <HeaderCell>Gender</HeaderCell>
                            <HeaderCell>Passport</HeaderCell>
                            <HeaderCell/>
                        </TableHeader>}
                        {flightBook.bookingTravelers.map(mapPassengerToRow)}
                    </tbody>
                </PassengerTable>
            </div>}
        </AnimatePresence>
        {isDeleteModalOpen && <DeletePassengerModal passenger={selectedTraveler} onClose={closeDeleteModal} />}
    </div>
}

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: ${styles.Spacing.XS};
`

const MobileCellColumn = styled.div`
    flex: 1;
    &:last-child {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
        padding: 4px;
    }
`

const HeaderCell = styled.th`
    &:first-child {
        padding-left: 10px;
    }
    width: 22.5%;
    &:last-child {
        width: 10%;
    }
`

const TableHeader = styled.tr`
    border-bottom: 1px solid black;
`

const AddPassengerButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    align-content: center;
    margin: ${styles.Spacing.S} 0;
`

const TravelerEditorContainer = styled.div`
    @media screen and (min-width: 992px) {
        margin: ${styles.Spacing.S} 0;
    }
`

const AddPassengerButton = styled.button`
    background-color: ${styles.Color.Black};
    color: ${styles.Color.White};
    border: 0;
    padding: ${styles.Spacing.XS};
    height: fit-content;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const StyledClose = styled(Close)`
    fill: ${styles.Color.FailureRed};
    min-height: 26px;
    min-width: 26px;
    max-height: 26px;
    max-width: 26px;
    ${styles.Animation.transitionStyles}
    border-radius: 4px;
    padding: 2px;
    ${styles.MediaQueries.Mobile} {
        background-color: rgba(0,0,0,0) !important;
    }
    &:hover {
        background-color: rgba(0,0,0,0.1);
    }
`

const StyledArrowRight = styled(ArrowRight)`
    fill: ${styles.Color.TaekusPurple};
    min-height: 12px;
    min-width: 24px;
    max-height: 12px;
    max-width: 24px;
`

const PassengerTable = styled(motion.table)`
    margin: 20px 0;
    width: 100%;
    table-layout: fixed;
`

const PassengerDetail = styled.td`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    ${styles.MediaQueries.Mobile} {
        padding: 0 !important;
    }
    padding-right: 10px;
    &:first-child {
        padding-left: 10px;
    }
    &:last-child {
        justify-content: end;    
    }
`

const Passenger = styled.tr`
    cursor: pointer;
    width: 100%;
    height: 60px;
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: #E8E8E8;
    }
    &:not(&:last-child) {
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

export default Passengers