import React from 'react'

import classNames from 'classnames'

const LoadingSpinner = ({ className }) => (
  <div className={classNames('min-vh-100 container mt-4', className)}>
    <div className="LoadingSpinner" />
  </div>
)

export default LoadingSpinner
