import API from 'services/api'

const featureName = 'Marqeta'

const ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_START = `${featureName}/FETCH_ONE_TIME_ACCOUNT_TOKEN_START`
const ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_COMPLETE = `${featureName}/FETCH_ONE_TIME_ACCOUNT_TOKEN_COMPLETE`
const ACTION_CLEAR_ONE_TIME_ACCOUNT_TOKEN = `${featureName}/CLEAR_ONE_TIME_ACCOUNT_TOKEN`
const ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_ERROR = `${featureName}/FETCH_ONE_TIME_ACCOUNT_TOKEN_ERROR`

export const Actions = {
  clearOneTimeAccountToken: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_ONE_TIME_ACCOUNT_TOKEN })
  },
  fetchOneTimeAccountToken: (params) => (dispatch) => {
    dispatch({ type: ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_START })

    return API.banking
      .fetchOneTimeAccountToken(params)
      .then((response) => {
        dispatch({
          type: ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_ERROR,
          payload: e,
        })
      })
  },
}
// REDUCER

const defaultState = {
  token: null,
  isLoading: false,
  error: false,
  errors: [],
}

export const MarqetaReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_COMPLETE:
      return {
        ...state,
        token: action.payload,
        isLoading: false,
      }

    case ACTION_FETCH_ONE_TIME_ACCOUNT_TOKEN_ERROR:
      return {
        ...state,
        errors: action.payload.response.data,
        isLoading: false,
        error: true,
      }

    case ACTION_CLEAR_ONE_TIME_ACCOUNT_TOKEN:
      return {
        ...defaultState,
      }

    default:
      return state
  }
}
