import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Row, Col } from 'react-bootstrap'

import Logo from 'assets/global/img/taekusLogos/mini-logo-white.png'

import 'assets/components/login/css/login.scss'

// TODO: This is basically a throwaway module and should be refactored eventually

class SignupNoAUInvite extends React.Component {
  render() {
    return (
      <Row className="Login-container m-0">
        <Col md="6" lg="5">
          <Col
            xs="12"
            md={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 2 }}
            className="Login-formBlock"
          >
            <h3 className="Login-subtitle my-5 pt-5 text-center">
              <FormattedMessage id="signup-unsuccessful.title" defaultMessage={`No Invite Code`} />
            </h3>
            <div className="StandardBodyDMSans mb-5 text-center text-red">
              <FormattedMessage
                id="signup-unsuccessful.description"
                defaultMessage={`It is only possible to signup for an authorized user account using a link sent by your account administrator.
                Please click on the link in your authorized user invitation or reach out to support at support@taekus.com for assistance.`}
              />
            </div>
          </Col>
        </Col>
        <Col
          md="6"
          lg="7"
          className="Login-decorativeBackground d-none d-md-flex justify-content-center"
        >
          <img src={Logo} alt={'taekus logo'} className="Login-logo" />
        </Col>
      </Row>
    )
  }
}

export default SignupNoAUInvite
