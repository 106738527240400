import React from "react"

import moment from "moment";
import { FormattedMessage } from "react-intl";

import { styled } from "styled-components";

import Modal from "components/common/Modal";

import { FlightLeg } from "components/pages/Rewards/Flights/types";

import styles from "styles/styles";

type AmenitiesModalProps = {
    leg: FlightLeg,
    onClose: () => void,
}

const AmenitiesModal = (props: AmenitiesModalProps) => {
    const { leg, onClose } = props;

    return <Modal title={`${leg.originCityName} to ${leg.destinationCityName}, ${moment(leg.localDepartureTimeDate).format('MMM D, YYYY')}`} onClose={onClose}>
        <Amenities>
            {leg.amenities.seatType !== null && leg.amenities.seatType !== 'none' && <AmenityItem>
                <AmenityIcon src={require(`assets/old/img/amenities/${leg.amenities.seatType.split('_')[0]}.svg`)} alt="" />
                <FormattedMessage id={`booking.amenities.${leg.amenities.seatType.split('_')[0]}`} values={{ legroom: leg.amenities.seatType.split('_')[1] || null }} />
            </AmenityItem>}
            {leg.amenities.wifi !== 'NO' && <AmenityItem>
                <AmenityIcon src={require(`assets/old/img/amenities/wifi-${leg.amenities.wifi.toLowerCase()}.svg`)} alt="" />
                <FormattedMessage id={`booking.amenities.wifi-${leg.amenities.wifi.toLowerCase()}`} />
            </AmenityItem>}
            {leg.amenities.power !== null && <AmenityItem>
                <AmenityIcon src={require(`assets/old/img/amenities/power-${leg.amenities.power.toLowerCase()}.svg`)} alt="" />
                <FormattedMessage id={`booking.amenities.power-${leg.amenities.power}`} />
            </AmenityItem>}
            {leg.amenities.entertainment !== null && <AmenityItem>
                <AmenityIcon src={require(`assets/old/img/amenities/entertainment-${leg.amenities.entertainment.toLowerCase()}.svg`)} alt="" />
                <FormattedMessage id={`booking.amenities.entertainment-${leg.amenities.entertainment}`} />
            </AmenityItem>}
            {leg.amenities.skypub && <AmenityItem>
                <AmenityIcon src={require(`assets/old/img/amenities/skypub.svg`)} alt="" />
                <FormattedMessage id={`booking.amenities.skypub`} />
            </AmenityItem>}
        </Amenities>
    </Modal>
}

const AmenityItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${styles.Spacing.XS};
`

const AmenityIcon = styled.img`
    margin: 4px 8px 4px 0;
    width: 18px;
    height: auto;
`

const Amenities = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    margin-right: ${styles.Spacing.S};
    opacity: 0.5;
`

export default AmenitiesModal