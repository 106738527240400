// Constants defined in this file will map with enums defined in the backend.

export const WsMessage = {
    Booking: {
        FinalAvailability: 'final_availability',
        FlightSearch: 'flight_search'
    },
    Common: {
		Disconnect: 'disconnect',
        Heartbeat: 'heartbeat',
		JobStatus: 'job_status',
    },
}

export enum FlightSearchTypes {
    Calendar = "calendar",
    Itinerary = "itinerary",
    Award = "award",
    Ndc = "ndc"
}

export const WsResponseMessage = {
    Booking: {
        FinalAvailability: 'final_availability_response'
    },
    Common: {
        Heartbeat: 'heartbeat_response',
		JobStatus: 'job_status_response'
    },
}

export enum WsJobStatus {
	Started = "started",
	InProgress = "in_progress",
	Complete = "complete",
	Error = "error",
	Unknown = "unknown"
}

export const enum RequestStatus {
    UNKNOWN = "unknown",
	INIT = "init",
    PENDING = "pending",
    COMPLETE = "complete", // successful or not
}