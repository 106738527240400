import API from 'services/api'
import { Actions as UserActions } from '../../currentUser'

const featureName = 'Banking'

const ACTION_FETCH_TRANSACTIONS_START = `${featureName}/FETCH_TRANSACTIONS_START`
const ACTION_FETCH_TRANSACTIONS_COMPLETE = `${featureName}/FETCH_TRANSACTIONS_COMPLETE`
const ACTION_CLEAR_TRANSACTIONS = `${featureName}/CLEAR_TRANSACTIONS`
const ACTION_FETCH_TRANSACTIONS_ERROR = `${featureName}/FETCH_TRANSACTIONS_ERROR`

const ACTION_FETCH_CARD_ACCOUNT_START = `${featureName}/FETCH_CARD_ACCOUNT_START`
const ACTION_FETCH_CARD_ACCOUNT_COMPLETE = `${featureName}/FETCH_CARD_ACCOUNT_COMPLETE`
const ACTION_CLEAR_CARD_ACCOUNT = `${featureName}/CLEAR_CARD_ACCOUNT`
const ACTION_FETCH_CARD_ACCOUNT_ERROR = `${featureName}/FETCH_CARD_ACCOUNT_ERROR`

const ACTION_UPDATE_CARD_ACCOUNT_START = `${featureName}/UPDATE_CARD_ACCOUNT_START`
const ACTION_UPDATE_CARD_ACCOUNT_COMPLETE = `${featureName}/UPDATE_CARD_ACCOUNT_COMPLETE`
const ACTION_UPDATE_CARD_ACCOUNT_ERROR = `${featureName}/UDPATE_CARD_ACCOUNT_ERROR`

const ACTION_UPDATE_ACCOUNT_TAGS = `${featureName}/UPDATE_ACCOUNT_TAGS`

const ACTION_UPDATE_TRANSACTION_START = `${featureName}/UPDATE_START`
const ACTION_UPDATE_TRANSACTION_COMPLETE = `${featureName}/UPDATE_COMPLETE`
const ACTION_UPDATE_TRANSACTION_ERROR = `${featureName}/UPDATE_ERROR`

const ACTION_UPDATE_NOTIFICATIONS_START = `${featureName}/UPDATE_NOTIFICATIONS_START`
const ACTION_UPDATE_NOTIFICATIONS_COMPLETE = `${featureName}/UPDATE_NOTIFICATIONS_COMPLETE`
const ACTION_UPDATE_NOTIFICATIONS_ERROR = `${featureName}/UPDATE_NOTIFICATIONS_ERROR`
const ACTION_CLEAR_NOTIFICATIONS = `${featureName}/CLEAR_NOTIFICATIONS`
const ACTION_UPDATE_NOTIFICATIONS_COPY = `${featureName}/UPDATE_NOTIFICATIONS_COPY`

const ACTION_LOOKUP_CARD_ACCOUNT_START = `${featureName}/LOOKUP_CARD_ACCOUNT_START`
const ACTION_LOOKUP_CARD_ACCOUNT_COMPLETE = `${featureName}/LOOKUP_CARD_ACCOUNT_COMPLETE`
const ACTION_LOOKUP_CARD_ACCOUNT_ERROR = `${featureName}/LOOKUP_CARD_ACCOUNT_ERROR`

const ACTION_ADD_AUTHORIZED_USER_START = `${featureName}/ADD_AUTHORIZED_USER_START`
const ACTION_ADD_AUTHORIZED_USER_COMPLETE = `${featureName}/ADD_AUTHORIZED_USER_COMPLETE`
const ACTION_ADD_AUTHORIZED_USER_ERROR = `${featureName}/ADD_AUTHORIZED_USER_ERROR`

const ACTION_UPDATE_AUTHORIZED_USER_START = `${featureName}/UPDATE_AUTHORIZED_USER_START`
const ACTION_UPDATE_AUTHORIZED_USER_COMPLETE = `${featureName}/UPDATE_AUTHORIZED_USER_COMPLETE`
const ACTION_UPDATE_AUTHORIZED_USER_ERROR = `${featureName}/UPDATE_AUTHORIZED_USER_ERROR`

const ACTION_FETCH_PRETERM_DOCUMENTS_START = `${featureName}/FETCH_PRETERM_DOCUMENTS_START`
const ACTION_FETCH_PRETERM_DOCUMENTS_COMPLETE = `${featureName}/FETCH_PRETERM_DOCUMENTS_COMPLETE`
const ACTION_FETCH_PRETERM_DOCUMENTS_ERROR = `${featureName}/FETCH_PRETERM_DOCUMENTS_ERROR`

const ACTION_UPDATE_CARD_ACCOUNT_BALANCE = `${featureName}/UPDATE_CARD_ACCOUNT_BALANCE`
const ACTION_UPDATE_CARD_ACTIVATION = `${featureName}/UPDATE_CARD_ACTIVATION`

export const Actions = {
  hopefulUpdateCardAccountBalance: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_CARD_ACCOUNT_BALANCE,
      payload: params,
    })
  },
  fetchPretermDocuments: (params) => (dispatch) => {
    dispatch({ type: ACTION_FETCH_PRETERM_DOCUMENTS_START })

    return API.banking.credit
      .fetchPretermDocuments(params)
      .then((response) => {
        dispatch({
          type: ACTION_FETCH_PRETERM_DOCUMENTS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_PRETERM_DOCUMENTS_ERROR,
          payload: e,
        })
      })
  },
  clearTransactions: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_TRANSACTIONS })
  },
  updateAccountTags: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_ACCOUNT_TAGS,
      payload: params,
    })
  },
  fetchCardAccount: (params) => (dispatch) => {
    const controller = new AbortController()
    dispatch({
      type: ACTION_FETCH_CARD_ACCOUNT_START,
      payload: {
        controller,
        cardAccountUuid: params.cardAccountUuid,
      },
    })

    return API.banking
      .fetchCardAccount(params, controller)
      .then((response) => {
        // store most recently fetched account in localstorage
        localStorage.setItem('recentAccountUuid', params.cardAccountUuid)
        localStorage.setItem('recentAccountCategory', response.data.account.categoryType)
        dispatch({
          type: ACTION_FETCH_CARD_ACCOUNT_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_CARD_ACCOUNT_ERROR,
          payload: e,
        })
        // If the specific uuid failed to load, retry the fetch with default settings
        if (params.cardAccountUuid && params.retry !== false) {
          dispatch(Actions.fetchCardAccount({ enforceDefault: true, retry: false }))
        }
      })
  },
  updateCardAccount: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_CARD_ACCOUNT_START,
      payload: {
        cardAccountUuid: params.cardAccountUuid,
        nickname: params.nickname,
      },
    })

    return API.banking
      .updateCardAccount(params)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_CARD_ACCOUNT_COMPLETE,
          payload: response.data,
        })
        dispatch(UserActions.updateUserCardAccount(params))
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_CARD_ACCOUNT_ERROR,
          payload: e,
        })
      })
  },
  fetchTransactions: (params) => (dispatch) => {
    dispatch({ type: ACTION_FETCH_TRANSACTIONS_START })

    return API.banking
      .fetchTransactions(params)
      .then((response) => {
        dispatch({
          type: ACTION_FETCH_TRANSACTIONS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_TRANSACTIONS_ERROR,
          payload: e,
        })
      })
  },
  updateTransaction: (transactionParams) => (dispatch) => {
    // Here we preemptively update the frontend object on the assumption
    // that the backend server call will succeed, in order to provide the illusion
    // of faster/better performance.
    dispatch({
      type: ACTION_UPDATE_TRANSACTION_START,
      payload: transactionParams,
    })

    return API.banking
      .updateTransaction(transactionParams)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_TRANSACTION_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_TRANSACTION_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  addAuthorizedUser: (params) => (dispatch) => {
    dispatch({
      type: ACTION_ADD_AUTHORIZED_USER_START,
      payload: params,
    })

    return API.auth
      .addAuthorizedUser(params)
      .then((response) => {
        dispatch({
          type: ACTION_ADD_AUTHORIZED_USER_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_ADD_AUTHORIZED_USER_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  updateAuthorizedUser: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_AUTHORIZED_USER_START,
      payload: params,
    })

    return API.auth
      .updateAuthorizedUser(params)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_AUTHORIZED_USER_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_AUTHORIZED_USER_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  updateCardActivation: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_CARD_ACTIVATION,
      payload: params,
    })
  },
  updateNotificationSettings: (settingsParams) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_NOTIFICATIONS_START,
      payload: settingsParams,
    })

    return API.banking
      .updateNotificationSettings(settingsParams)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_NOTIFICATIONS_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_NOTIFICATIONS_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  updateNotificationsCopy: (newValue) => ({
    type: ACTION_UPDATE_NOTIFICATIONS_COPY,
    payload: newValue,
  }),
  clearNotifications: () => ({
    type: ACTION_CLEAR_NOTIFICATIONS,
  }),
  lookupAccount: (params) => (dispatch) => {
    dispatch({ type: ACTION_LOOKUP_CARD_ACCOUNT_START })

    return API.banking
      .lookupAccount(params)
      .then((response) => {
        dispatch({
          type: ACTION_LOOKUP_CARD_ACCOUNT_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_LOOKUP_CARD_ACCOUNT_ERROR,
          payload: e,
        })
      })
  },
}

// REDUCER

const defaultState = {
  account: {
    accountType: null,
    balance: {
      availableBalance: null,
      pendingAmount: '',
      currency: ''
    },
    previousMonthTransactions: { availableAmount: '', currency: '' },
    freezeInfo: { status: '', startDate: null, endDate: null },
    payToAccount: {
      uuid: '',
      accountLast4: '',
      accountRoutingNumber: '',
    },
    paymentCards: [
      {
        accountLast4: '',
        binType: '',
        cardStatus: '',
        formFactor: '',
        id: '',
      },
    ],
    notificationSettings: {},
    accountLookup: {},
    billCycleDay: '', // also statement cycle day for debit cards
    pendingPoints: null,
    totalEarnedPoints: null,
    totalUsedPoints: null,
    isActive: null, // refers to whether the card is activated
    isLoadingAuthorizedUser: false,
    didAuthorizedUserFail: null,
    authorizedUserError: '',
    isArchived: false,
    uuid: null,
    nickname: null,
    autopay: {
      active: true,
      reloadBalanceTrigger: null,
      reloadAmount: null,
      payFromUuid: '',
    },
    accountNumber: '',
    routingNumber: '',
    startDate: null, // AKA member since
    endDate: null, // If the card is closed this will be non-null
  },
  creditPreTermDocuments: {
    documents: {},
    isLoading: false,
    error: false,
  },
  notificationSettings: {
    settingsCopy: {},
    isLoading: false,
    success: false,
    error: false,
  },
  didTransactionFailParsing: false,
  accountAbortController: null,
  transactions: [],
  isLoading: false,
  isUpdating: false,
  error: null,
  errors: [],
}

export const BankingReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_UPDATE_CARD_ACCOUNT_BALANCE:

      // If the supplied uuid doesn't match the current account uuid, ignore this action entirely
      if (action.payload.cardAccountUuid === state.account.uuid) { return state }

      return {
        ...state,
        account: {
          ...state.account,
          balance: {
            availableBalance: String(Number(state.account.balance.availableBalance) + (action.payload.availableBalance || 0)),
            ledgerBalance: String((state.account.balance.ledgerBalance) + (action.payload.ledgerBalance || 0)),
            pendingCredits: String((state.account.balance.pendingCredits) + (action.payload.pendingCredits || 0)),
          }
        }
      }

    case ACTION_FETCH_CARD_ACCOUNT_START:
      if (state.accountAbortController) {
        state.accountAbortController.abort()
      }

      return {
        ...state,
        account: {
          ...state.account,
          uuid: action.payload.cardAccountUuid,
        },
        didTransactionFailParsing: false,
        accountAbortController: action.payload.controller,
        isLoading: true,
        error: null,
        errors: [],
      }
    case ACTION_FETCH_TRANSACTIONS_START:
      return {
        ...state,
        didTransactionFailParsing: false,
        isLoading: true,
        error: null,
        errors: [],
      }
    case ACTION_UPDATE_CARD_ACCOUNT_START:
      return {
        ...state,
        account: {
          ...state.account,
          nickname: action.payload.nickname,
        },
        isUpdating: true,
      }

    case ACTION_FETCH_CARD_ACCOUNT_COMPLETE:
      // we also want to populate notificationSettings as a "working copy"
      return {
        ...state,
        ...action.payload,
        notificationSettings: {
          ...state.notificationSettings,
          settingsCopy: action.payload.account.notificationSettings,
        },
        isLoading: false,
        accountAbortController: null,
      }

    case ACTION_UPDATE_CARD_ACCOUNT_COMPLETE:
      return {
        ...state,
        isUpdating: false,
      }

    case ACTION_FETCH_TRANSACTIONS_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }

    case ACTION_CLEAR_TRANSACTIONS:
      return {
        ...state,
        didTransactionFailParsing: false,
        transactions: [],
        isLoading: false,
        isUpdating: false,
        error: null,
        errors: [],
      }

    case ACTION_CLEAR_CARD_ACCOUNT:
      return {
        ...state,
        account: {},
        isLoading: false,
        isUpdating: false,
        error: null,
        errors: [],
      }

    case ACTION_FETCH_CARD_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: action.payload.message === 'canceled' ? true : false,
        isUpdating: false,
        error: action.payload.message === 'canceled' ? false : true,
      }
    case ACTION_UPDATE_CARD_ACCOUNT_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: true,
      }
    case ACTION_FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        error: true,
      }

    case ACTION_CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationSettings: {
          ...defaultState.notificationSettings,
          settingsCopy: state.notificationSettings.settingsCopy,
        },
      }

    case ACTION_UPDATE_NOTIFICATIONS_START:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          isLoading: true,
        },
      }

    case ACTION_UPDATE_NOTIFICATIONS_COMPLETE:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          isLoading: false,
          success: true,
        },
      }

    case ACTION_UPDATE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          settingsCopy: {
            ...state.account.notificationSettings,
          },
          isLoading: false,
          error: true,
        },
      }

    case ACTION_UPDATE_ACCOUNT_TAGS:
      const mergedTagsArray = Array.from(new Set(state.account.tags.concat(action.payload)))
      return {
        ...state,
        account: {
          ...state.account,
          tags: mergedTagsArray,
        },
      }

    case ACTION_LOOKUP_CARD_ACCOUNT_START:
      return {
        ...state,
        accountLookup: {
          isLoading: true,
        },
      }

    case ACTION_LOOKUP_CARD_ACCOUNT_COMPLETE:
      return {
        ...state,
        accountLookup: {
          ...state.accountLookup,
          isLoading: false,
          success: true,
        },
      }

    case ACTION_LOOKUP_CARD_ACCOUNT_ERROR:
      return {
        ...state,
        accountLookup: {
          ...state.accountLookup,
          isLoading: false,
          error: true,
        },
      }

    case ACTION_ADD_AUTHORIZED_USER_START:
      return {
        ...state,
        account: {
          ...state.account,
          didAuthorizedUserFail: null,
          isLoadingAuthorizedUser: true,
        },
      }

    case ACTION_ADD_AUTHORIZED_USER_COMPLETE:
      return {
        ...state,
        account: {
          ...state.account,
          isLoadingAuthorizedUser: false,
          didAuthorizedUserFail: false,
          // adding null to user, as this is how we detect if the account is active or pending
          authorizedUsers: [...state.account.authorizedUsers, { ...action.payload, user: null }],
        },
      }

    case ACTION_UPDATE_AUTHORIZED_USER_START:
      let updatedUserIndex = state.account.authorizedUsers.findIndex(
        (user) => user.uuid === action.payload.uuid
      )
      state.account.authorizedUsers[updatedUserIndex] = {
        ...action.payload,
      }

      return {
        ...state,
        account: {
          ...state.account,
          didAuthorizedUserFail: null,
          authorizedUserError: '',
          isLoadingAuthorizedUser: true,
        },
      }

    case ACTION_ADD_AUTHORIZED_USER_ERROR:
      return {
        ...state,
        account: {
          ...state.account,
          didAuthorizedUserFail: true,
          authorizedUserError: action.payload?.errors?.nonFieldErrors,
          isLoadingAuthorizedUser: false,
        },
      }

    case ACTION_UPDATE_AUTHORIZED_USER_COMPLETE:
      return {
        ...state,
        account: {
          ...state.account,
          didAuthorizedUserFail: false,
          isLoadingAuthorizedUser: false,
        },
      }

    case ACTION_UPDATE_AUTHORIZED_USER_ERROR:
      return {
        ...state,
        account: {
          ...state.account,
          didAuthorizedUserFail: true,
          authorizedUserError: action.payload,
          isLoadingAuthorizedUser: false,
        },
      }

    case ACTION_UPDATE_NOTIFICATIONS_COPY:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          settingsCopy: {
            ...state.notificationSettings.settingsCopy,
            ...action.payload,
          },
        },
      }

    case ACTION_FETCH_PRETERM_DOCUMENTS_START:
      return {
        ...state,
        creditPreTermDocuments: {
          documents: {},
          isLoading: true,
          error: false,
        },
      }

    case ACTION_FETCH_PRETERM_DOCUMENTS_ERROR:
      return {
        ...state,
        creditPreTermDocuments: {
          documents: {},
          isLoading: false,
          error: true,
        },
      }

    case ACTION_FETCH_PRETERM_DOCUMENTS_COMPLETE:
      return {
        ...state,
        creditPreTermDocuments: {
          documents: action.payload,
          isLoading: false,
          error: false,
        },
      }

    case ACTION_UPDATE_CARD_ACTIVATION:
        return {
          ...state,
          account: {
            ...state.account,
            needsPhysicalCardActivation: action.payload
          }
        }

    default:
      return state
  }
}
