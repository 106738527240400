import API from 'services/api'

import filter from 'lodash/filter'

const featureName = 'Linked_Account'

const ACTION_UPDATE_LINKED_ACCOUNT = `${featureName}/UPDATE_LINKED_ACCOUNT`
const ACTION_CLEAR_LINKED_ACCOUNT = `${featureName}/CLEAR_LINKED_ACCOUNT`

const ACTION_GET_PLAID_TOKEN_START = `${featureName}/GET_PLAID_TOKEN_START`
const ACTION_GET_PLAID_TOKEN_COMPLETE = `${featureName}/GET_PLAID_TOKEN_COMPLETE`
const ACTION_GET_PLAID_TOKEN_ERROR = `${featureName}/GET_PLAID_TOKEN_ERROR`

const ACTION_GET_LINKED_ACCOUNTS_START = `${featureName}/GET_LINKED_ACCOUNTS_START`
const ACTION_GET_LINKED_ACCOUNTS_COMPLETE = `${featureName}/GET_LINKED_ACCOUNTS_COMPLETE`
const ACTION_GET_LINKED_ACCOUNTS_ERROR = `${featureName}/GET_LINKED_ACCOUNTS_ERROR`

const ACTION_GET_INTERNAL_ACCOUNTS_START = `${featureName}/GET_INTERNAL_ACCOUNTS_START`
const ACTION_GET_INTERNAL_ACCOUNTS_COMPLETE = `${featureName}/GET_INTERNAL_ACCOUNTS_COMPLETE`
const ACTION_GET_INTERNAL_ACCOUNTS_ERROR = `${featureName}/GET_INTERNAL_ACCOUNTS_ERROR`

const ACTION_CREATE_LINKED_ACCOUNT_START = `${featureName}/CREATE_LINKED_ACCOUNT_START`
const ACTION_CREATE_LINKED_ACCOUNT_COMPLETE = `${featureName}/CREATE_LINKED_ACCOUNT_COMPLETE`
const ACTION_CREATE_LINKED_ACCOUNT_ERROR = `${featureName}/CREATE_LINKED_ACCOUNT_ERROR`

const ACTION_VERIFY_MDV_START = `${featureName}/VERIFY_MDV_START`
const ACTION_VERIFY_MDV_COMPLETE = `${featureName}/VERIFY_MDV_COMPLETE`
const ACTION_VERIFY_MDV_ERROR = `${featureName}/VERIFY_MDV_ERROR`

const ACTION_UPDATE_LINKED_ACCOUNT_START = `${featureName}/UPDATE_LINKED_ACCOUNT_START`
const ACTION_UPDATE_LINKED_ACCOUNT_COMPLETE = `${featureName}/UPDATE_LINKED_ACCOUNT_COMPLETE`
const ACTION_UPDATE_LINKED_ACCOUNT_ERROR = `${featureName}/UPDATE_LINKED_ACCOUNT_ERROR`

const ACTION_UPDATE_INTERNAL_ACCOUNT = `${featureName}/UPDATE_INTERNAL_ACCOUNT`

const ACTION_DELETE_LINKED_ACCOUNT_START = `${featureName}/DELETE_LINKED_ACCOUNT_START`
const ACTION_DELETE_LINKED_ACCOUNT_COMPLETE = `${featureName}/DELETE_LINKED_ACCOUNT_COMPLETE`
const ACTION_DELETE_LINKED_ACCOUNT_ERROR = `${featureName}/DELETE_LINKED_ACCOUNT_ERROR`

export const Actions = {
  getPlaidLinkToken: () => (dispatch) => {
    dispatch({ type: ACTION_GET_PLAID_TOKEN_START })

    return API.banking.linkedAccounts
      .getPlaidLinkToken()
      .then((response) => {
        dispatch({
          type: ACTION_GET_PLAID_TOKEN_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_PLAID_TOKEN_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  verifyMDV: (params) => (dispatch) => {
    dispatch({ type: ACTION_VERIFY_MDV_START })

    return API.banking.linkedAccounts
      .verifyMDV(params)
      .then((response) => {
        dispatch({
          type: ACTION_VERIFY_MDV_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_VERIFY_MDV_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  getLinkedAccounts: () => (dispatch) => {
    dispatch({ type: ACTION_GET_LINKED_ACCOUNTS_START })

    return API.banking.linkedAccounts
      .get()
      .then((response) => {
        dispatch({
          type: ACTION_GET_LINKED_ACCOUNTS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_LINKED_ACCOUNTS_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  getInternalAccounts: () => (dispatch) => {
    dispatch({ type: ACTION_GET_INTERNAL_ACCOUNTS_START })

    return API.banking.linkedAccounts
      .getInternal()
      .then((response) => {
        dispatch({
          type: ACTION_GET_INTERNAL_ACCOUNTS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_INTERNAL_ACCOUNTS_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  createLinkedAccount: (params) => (dispatch) => {
    dispatch({ type: ACTION_CREATE_LINKED_ACCOUNT_START })

    return API.banking.linkedAccounts
      .create(params)
      .then((response) => {
        dispatch({
          type: ACTION_CREATE_LINKED_ACCOUNT_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_LINKED_ACCOUNT_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  updateLinkedAccount: (params) => (dispatch) => {
    dispatch({ type: ACTION_UPDATE_LINKED_ACCOUNT_START })
    return API.banking.linkedAccounts
      .update(params)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_LINKED_ACCOUNT_COMPLETE,
          payload: params,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_LINKED_ACCOUNT_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  updateInternalAccount: (params) => (dispatch) => {
    dispatch({
      type: ACTION_UPDATE_INTERNAL_ACCOUNT,
      payload: params,
    })
  },

  deleteLinkedAccount: (params) => (dispatch) => {
    dispatch({ type: ACTION_DELETE_LINKED_ACCOUNT_START })

    return API.banking.linkedAccounts
      .delete(params)
      .then((response) => {
        dispatch({
          type: ACTION_DELETE_LINKED_ACCOUNT_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_DELETE_LINKED_ACCOUNT_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
}

// REDUCER

const defaultState = {
  workingCopy: {
    routingNumber: '',
    accountNumber: '',
    accountNumber2: '',
    accountType: 'checking',
    accountName: '',
    verificationSource: '',
    bankName: '',
    uuid: null,
  },
  linkedAccounts: [],
  internalAccounts: [],
  plaid: {
    token: '',
    expiration: '',
  },
  mdvVerificationError: false,
  isLoading: false,
  isFetchingInternalAccounts: false,
  isFetchingExternalAccounts: false,
  error: null,
  errors: [],
}

export const LinkedAccountsReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_UPDATE_LINKED_ACCOUNT:
      return {
        ...state,
        name: action.payload,
      }

    case ACTION_VERIFY_MDV_START:
    case ACTION_DELETE_LINKED_ACCOUNT_START:
    case ACTION_GET_PLAID_TOKEN_START:
    case ACTION_CREATE_LINKED_ACCOUNT_START:
    case ACTION_UPDATE_LINKED_ACCOUNT_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_GET_INTERNAL_ACCOUNTS_START:
      return {
        ...state,
        isFetchingInternalAccounts: true,
        error: null,
        errors: [],
      }
    
    case ACTION_GET_LINKED_ACCOUNTS_START:
      return {
        ...state,
        isFetchingExternalAccounts: true,
        error: null,
        errors: [],
      }

    case ACTION_GET_PLAID_TOKEN_COMPLETE:
      return {
        ...state,
        plaid: {
          ...state.plaid,
          ...action.payload,
        },
        isLoading: false,
        error: false,
      }

    case ACTION_VERIFY_MDV_COMPLETE:
    case ACTION_CREATE_LINKED_ACCOUNT_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        shouldUpdateLinkedAccounts: true,
      }
    case ACTION_GET_LINKED_ACCOUNTS_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isFetchingExternalAccounts: false,
        shouldUpdateLinkedAccounts: false,
      }
    case ACTION_GET_INTERNAL_ACCOUNTS_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isFetchingInternalAccounts: false,
        shouldUpdateLinkedAccounts: false,
      }

    case ACTION_UPDATE_LINKED_ACCOUNT_COMPLETE:
      let copyOfAccounts = JSON.parse(JSON.stringify(state.linkedAccounts))
      let selectedAccountIndex
      copyOfAccounts.forEach((account, index) => {
        if (account.uuid === action.payload.uuid) {
          selectedAccountIndex = index
        }
      })
      if (selectedAccountIndex > -1) {
        copyOfAccounts[selectedAccountIndex] = {
          ...copyOfAccounts[selectedAccountIndex],
          ...action.payload,
        }
      }

      return {
        ...state,
        linkedAccounts: copyOfAccounts,
        isLoading: false,
        shouldUpdateLinkedAccounts: false,
      }

    case ACTION_UPDATE_INTERNAL_ACCOUNT:
      let copyOfInternalAccounts = JSON.parse(JSON.stringify(state.internalAccounts))
      let selectedInternalAccountIndex
      copyOfInternalAccounts.forEach((account, index) => {
        if (account.uuid === action.payload.uuid) {
          selectedInternalAccountIndex = index
        }
      })
      if (selectedInternalAccountIndex > -1) {
        copyOfInternalAccounts[selectedInternalAccountIndex] = {
          ...copyOfInternalAccounts[selectedInternalAccountIndex],
          ...action.payload,
          availableBalance: copyOfInternalAccounts[selectedInternalAccountIndex].availableBalance + (action.payload.availableBalance || 0)
        }
      }

      return {
        ...state,
        internalAccounts: copyOfInternalAccounts,
      }

    case ACTION_DELETE_LINKED_ACCOUNT_COMPLETE:
      const oldLinkedAccounts = state.linkedAccounts
      const uuid = action.payload.uuid
      const newLinkedAccounts = filter(oldLinkedAccounts, (acct) => {
        return acct.uuid !== uuid
      })

      return {
        ...state,
        linkedAccounts: newLinkedAccounts,
        isLoading: false,
      }

    case ACTION_CLEAR_LINKED_ACCOUNT:
      return {
        ...defaultState,
      }

    case ACTION_VERIFY_MDV_ERROR:
      return {
        ...state,
        mdvVerificationError: true,
      }

    case ACTION_DELETE_LINKED_ACCOUNT_ERROR:
    case ACTION_GET_PLAID_TOKEN_ERROR:
    case ACTION_CREATE_LINKED_ACCOUNT_ERROR:
    case ACTION_UPDATE_LINKED_ACCOUNT_ERROR:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      }

    case ACTION_GET_INTERNAL_ACCOUNTS_ERROR:
      return {
        ...state,
        ...action.payload,
        isFetchingInternalAccounts: false,
        error: true,
      }

    case ACTION_GET_LINKED_ACCOUNTS_ERROR:
      return {
        ...state,
        ...action.payload,
        isFetchingExternalAccounts: false,
        error: true,
      }

    default:
      return state
  }
}
