import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"

import { Modal } from 'react-bootstrap';

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { Locale, USDCurrencyOptions } from "utils/constants"
import { debounce } from "utils/debounce"

import { useIsFirstRender } from "hooks/useIsFirstRender";

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import { AppPath } from "components/appRouter/constants"
import Footer from "components/common/Footer";
import Spinner from "components/common/Spinner";
import { AdvancedTransactionFilter, TransactionFilterOptions, defaultTransactionFilterOptions } from "components/common/Transactions/constants";
import Navigation, { NavColor } from "components/navbar/Navigation"

import TransactionTable from "components/pages/Home/TransactionTable"
import { CardStatus, SIDEBAR_WIDTH } from "components/pages/Cards/constants"
import { messages, CardType as enumCardType } from 'components/pages/Cards/constants'
import { fetchSelectedCardTransactions } from "components/pages/Cards/cardsSlice"
import CardStatusDropdown from "components/pages/Cards/CardStatusDropdown"
import MerchantCategoryCode from "components/pages/Cards/MerchantCategoryCode"
import MerchantCategoryCodesCount from "components/pages/Cards/MerchantCategoryCodesCount"
import Tag from "components/pages/Cards/Tag"
import TagsCount from "components/pages/Cards/TagsCount"
import CardMockInterior from 'components/pages/Cards/CardDetails';

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from "styles/styles"

import { ReactComponent as ArrowLeftIcon } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/ArrowRight.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";

const CardInspector = () => {
    const dispatch = useDispatch();
    const history = useHistory()

    // Redux state
    const { cardID, cardAccountID } = useParams<{ cardID: string, cardAccountID: string }>();
    const currentUser = useSelector((state: any) => state.currentUser)
    const parentUser = currentUser.currentUser.parentUser
    const mainUser = parentUser ? parentUser : currentUser.currentUser
    const banking = useSelector((state: any) => state.banking)
    const cards = useSelector((state: any) => state.paymentCards.paymentCards.paymentCards).slice(1)
    const isLoadingTransactions = useSelector((state: any) => state.cards.isLoadingSelectedCardTransactions)
    const isMoreTransactions = useSelector((state: any) => state.cards.isMoreTransactions)
    const selectedCardTransactions = useSelector((state: any) => state.cards.selectedCardTransactions)
    const didTransactionFailParsing = useSelector((state: any) => state.cards.didTransactionFailParsing)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const card = useSelector((state: any) => state.paymentCards.paymentCards.paymentCards).slice(1).find((card: any) => card.uuid === cardID)
    const defaultLoadedTransactionsCardUuid = useSelector((state: any) => state.cards.defaultLoadedTransactionsCardUuid)
    
    // Local state
    const [cardName, setCardName] = useState('')
    const [transactionFilterOptions, setTransactionFilterOptions] = useState<TransactionFilterOptions>({...defaultTransactionFilterOptions, cardUuids: [ cardID ]})
    const [transactionsPage, setTransactionsPage] = useState(0)
    const [isTerminateCardModalOpen, setIsTerminateCardModalOpen] = useState(false)

    const isFirstRender = useIsFirstRender()

    const isLoading = isLoadingTransactions

    const nextTransactionsPage = () => {
        setTransactionsPage(transactionsPage + 1)
    }

    const prevTransactionsPage = () => {
        setTransactionsPage(transactionsPage - 1)
    }

    const toggleTerminateCardModal = () => {
        setIsTerminateCardModalOpen(!isTerminateCardModalOpen)
    }

    const terminateCard = () => {
        dispatch(PaymentCardActions.updatePaymentCard({
            ...card,
            status: CardStatus.TERMINATED,
            oldCardStatus: card.status,
            cardAccountUuid: cardAccountID,
            tags: Array.from(card.tags)
        }))
        setIsTerminateCardModalOpen(false)
    }

    // Pass card since debounced functions won't have an accurate version of the current state
    const renameCard = (newName: string, card: any) => {
        dispatch(PaymentCardActions.updatePaymentCard({
            ...card,
            nickname: newName,
            cardAccountUuid: cardAccountID,
            tags: Array.from(card.tags)
        }))
    }

    const debouncedRenameCard = useCallback(debounce(renameCard, 2000), []) // eslint-disable-line
    
    const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setCardName(ev.target.value)
        debouncedRenameCard(ev.target.value, card)
    }

    const renderCardDetail = (card: { cardType: string, mccList: any, merchantId: any, merchantName: any, maxAmountLimit: any }) => {
        if (card.cardType === enumCardType.FREE_USE || card.cardType === enumCardType.ONCE_MONTH_LOCKED || card.cardType === enumCardType.MCC_LOCKED) {
            return null;
        }
    
        let detailLabel = "";
        let spendControlValue: React.ReactNode = "";

        if (card.cardType === enumCardType.MERCHANT_LOCKED && card.merchantId) {
            detailLabel = "Merchant";
            spendControlValue = card.merchantName;
        } else if (card.cardType === enumCardType.MAX_LIMIT_LOCKED) {
            detailLabel = "Maximum Limit"
            spendControlValue = card.maxAmountLimit?.toLocaleString(Locale.English, USDCurrencyOptions);
        }
    
        return (
            <DetailContainer flex='1 0'>
                <Detail>
                    <DetailLabel>{detailLabel}</DetailLabel>
                    <SpendControlData>{spendControlValue}</SpendControlData>
                </Detail>
            </DetailContainer>
        );
    }

    const updateCardStatus = (selectedOption: any) => {
        dispatch(PaymentCardActions.updatePaymentCard({
            ...card,
            status: selectedOption.value,
            oldCardStatus: card.status,
            cardAccountUuid: banking.account.uuid,
            tags: Array.from(card.tags)
        }))
    }

    // Fetch transactions whenever the user changes transaction pages
    useEffect(() => {
        const isParamsValid = banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate

        if (!isFirstRender && isParamsValid) {
            // page number * 100 transactions per page
            dispatch(fetchSelectedCardTransactions(cardAccountID, transactionsPage * 100, transactionFilterOptions))
        }
    }, [transactionsPage, dispatch]) // eslint-disable-line

    // Fetch transactions when transaction filters change, and reset transaction page to zero
    useEffect(() => {
        const isParamsValid = banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate

        if (!isFirstRender && isParamsValid) {
            setTransactionsPage(0);
            dispatch(fetchSelectedCardTransactions(cardAccountID, 0, transactionFilterOptions))
        }
    }, [transactionFilterOptions, dispatch]) // eslint-disable-line

    // Fetch transactions when page loads, unless the default param transactions have been loaded already
    useEffect(() => {
        const isParamsValid = cardAccountID && transactionFilterOptions.startDate && transactionFilterOptions.endDate

        if (defaultLoadedTransactionsCardUuid !== cardID && isParamsValid) {
            dispatch(fetchSelectedCardTransactions(cardAccountID, 0, transactionFilterOptions, true))
        }
    }, [cardAccountID, dispatch]) // eslint-disable-line

    // Move to the cards list view if user chooses a Taekus account that doesn't match the inspected card
    useEffect(() => {
        if (banking.account.uuid && banking.account.uuid !== cardAccountID) {
            history.push(AppPath.Cards)
        }
    }, [banking.account.uuid]) // eslint-disable-line

    // If the list of cards is empty (shouldn't be if one is being inspected), fetch the full list of cards
    useEffect(() => {
        if (cards.length === 0 && cardAccountID) {
            dispatch(PaymentCardActions.fetchPaymentCards({ cardAccountUuid: cardAccountID }))
        }
    }, [cardAccountID, dispatch]) // eslint-disable-line

    // Set the displayed card name when the redux state updates
    useEffect(() => {
        card && setCardName(card?.nickname)
    }, [card])

    const isTagsEmpty = card?.tags.length === 0

    return <Container>
        <Navigation color={NavColor.Black}/>
        <Content>
            <Sidebar>
                <BackButtonContainer as={Link} to='/cards'>
                    <StyledArrowLeft fill={styles.Color.TaekusPurple}/>
                    <BackButtonLabel>Back to all cards</BackButtonLabel>
                </BackButtonContainer>
            </Sidebar>
            <Column>
                <CardDetails>
                    {card ? <>
                        <CardMockInterior
                            cardAccountUuid={cardAccountID}
                            cardUuid={cardID}
                            userName={mainUser.firstName ? `${mainUser.firstName} ${mainUser.lastName}` : undefined}
                            error={false}
                            isPhysicalCard={card.formFactor === 'PHYSICAL'}
                            cardType={card.binType}
                        />
                        {isMobile ? <Column className="ml-0 ml-md-5">
                            {(card.formFactor === "PHYSICAL") ? <FixedCardTitle>Physical Card</FixedCardTitle> : <CardTitle onChange={handleNameChange} value={cardName}/>}
                            <DetailsHeader>
                                <Detail>
                                    <DetailLabel>Available</DetailLabel>
                                    <AvailableBalance>{Number(banking.account.balance.availableBalance)?.toLocaleString(Locale.English, USDCurrencyOptions)}</AvailableBalance>
                                </Detail>
                                <Detail>
                                    <DetailLabel>Spent this month</DetailLabel>
                                    <AvailableBalance>{(card.monthlySpend || 0).toLocaleString(Locale.English, USDCurrencyOptions)}</AvailableBalance>
                                </Detail>
                                <Detail>
                                    <DetailLabel>Type</DetailLabel>
                                    <CardType>{(messages.CardTypeLabel as any)[card.cardType]}</CardType>
                                </Detail>
                                <Detail>
                                    <DetailLabel>Status</DetailLabel>
                                    <CardStatusDropdown onChange={updateCardStatus} status={card.status}/>
                                </Detail>
                                <Detail>
                                    <DetailLabel>Address</DetailLabel>
                                    <Address>{card.fullAddress}</Address>
                                </Detail>
                                <DetailContainer flex='1'>
                                    <DetailLabel>Tags</DetailLabel>
                                    {card && <TagsContainer>
                                        <Tag card={card} selectKey={card.tags?.filter((tag: string) => tag !== '[]').length.toString()} key={card.tags?.filter((tag: string) => tag !== '[]').length.toString()} />
                                        {!isTagsEmpty && card?.tags.slice(0,4).map((tag: any, index: number) => <Tag key={`tag:${index}`} card={card} label={tag} />)}
                                        <TagsCount card={card}/>
                                    </TagsContainer>}
                                </DetailContainer>
                                {(card.formFactor !== "PHYSICAL") && (
                                    <DetailContainer flex='1 0'>
                                        {card.status !== CardStatus.TERMINATED && <TerminateCardButton onClick={toggleTerminateCardModal}>Terminate Card</TerminateCardButton>}
                                    </DetailContainer>
                                )}
                            </DetailsHeader>
                        </Column> : <Header>
                            {(card.formFactor === "PHYSICAL") ? <FixedCardTitle>Physical Card</FixedCardTitle> : <CardTitle onChange={handleNameChange} value={cardName}/>}
                            <div className="d-flex">
                                <DetailsCol flex='4'>
                                    <DetailsRow>
                                        <DetailContainer flex='1 0'>
                                            <Detail>
                                                <DetailLabel>Available</DetailLabel>
                                                <AvailableBalance>{Number(banking.account.balance.availableBalance)?.toLocaleString(Locale.English, USDCurrencyOptions)}</AvailableBalance>
                                            </Detail>
                                        </DetailContainer>
                                        <DetailContainer flex='1 0'>
                                            <Detail>
                                                <DetailLabel>Spent this month</DetailLabel>
                                                <AvailableBalance>{(card.monthlySpend || 0).toLocaleString(Locale.English, USDCurrencyOptions)}</AvailableBalance>
                                            </Detail>
                                        </DetailContainer>
                                        <DetailContainer flex='1 0'>
                                            <Detail>
                                                <DetailLabel>Type</DetailLabel>
                                                <CardType>{(messages.CardTypeLabel as any)[card.cardType]}</CardType>
                                            </Detail>
                                        </DetailContainer>
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailContainer flex='2 0'>
                                            <Detail>
                                                <DetailLabel>Address</DetailLabel>
                                                <Address>{card.fullAddress}</Address>
                                            </Detail>
                                        </DetailContainer>
                                        {renderCardDetail(card)}
                                    </DetailsRow>
                                </DetailsCol>
                                <DetailsCol flex='1'>
                                    <DetailsRow>
                                        <DetailContainer flex='1 0'>
                                            <Detail>
                                                <DetailLabel>Status</DetailLabel>
                                                <CardStatusDropdown onChange={updateCardStatus} status={card.status}/>
                                            </Detail>
                                        </DetailContainer>
                                    </DetailsRow>
                                    <DetailsRow>
                                        {(card.formFactor !== "PHYSICAL") && (
                                            <DetailContainer flex='1 0'>
                                                {card.status !== CardStatus.TERMINATED && <TerminateCardButton onClick={toggleTerminateCardModal}>Terminate Card</TerminateCardButton>}
                                            </DetailContainer>
                                        )}
                                    </DetailsRow>
                                </DetailsCol>
                            </div>
                            {card && card.cardType === enumCardType.MCC_LOCKED &&
                                <DetailsRow>
                                    <DetailContainer flex='1'>
                                        <DetailLabel>Merchant Category Codes</DetailLabel>
                                        <MccContainer>
                                            {card?.mccList.slice(0,4).map((mcc: any, index: number) => <MerchantCategoryCode key={`mcc:${index}`} card={card} label={mcc} />)}
                                            <MerchantCategoryCodesCount card={card}/>
                                        </MccContainer>
                                    </DetailContainer>
                                </DetailsRow>
                            }
                            <DetailsRow>
                                <DetailContainer flex='1'>
                                    <DetailLabel>Tags</DetailLabel>
                                    {card && <TagsContainer>
                                        <Tag card={card} selectKey={card.tags?.filter((tag: string) => tag !== '[]').length.toString()} key={card.tags?.filter((tag: string) => tag !== '[]').length.toString()} />
                                        {!isTagsEmpty && card?.tags.slice(0,4).map((tag: any, index: number) => <Tag key={`tag:${index}`} card={card} label={tag} />)}
                                        <TagsCount card={card}/>
                                    </TagsContainer>}
                                </DetailContainer>
                            </DetailsRow>
                        </Header>}
                    </> : <SpinnerContainer>
                        <Spinner size={40}/>
                    </SpinnerContainer>}
                </CardDetails>
                <TransactionsTitle>Transactions</TransactionsTitle>
                <AnimatePresence mode='wait'>
                    <TransactionTableContainer isMobile={isMobile} key='transactionsTable' {...fadeInOutMotionProps}>
                        <TransactionTable
                            filterBlacklist={[AdvancedTransactionFilter.CardUuids]}
                            updateFilterOptions={setTransactionFilterOptions}
                            didTransactionFailParsing={didTransactionFailParsing}
                            filterOptions={transactionFilterOptions}
                            isLoading={isLoading}
                            transactions={selectedCardTransactions}
                        />
                        <div className="d-flex">
                            <PaginationButton isVisible={transactionsPage > 0} onClick={prevTransactionsPage}>
                                <ArrowLeftIcon />
                            </PaginationButton>
                            <PaginationButton isVisible={isMoreTransactions} onClick={nextTransactionsPage}>
                                <ArrowRightIcon />
                            </PaginationButton>
                        </div>
                    </TransactionTableContainer>
                </AnimatePresence>
            </Column>
        </Content>
        <Footer/>
        <Modal centered show={isTerminateCardModalOpen}>
            <div className='w-100 d-flex justify-content-between'>
                <ModalTitle>Close Card</ModalTitle>
                <CloseButton onClick={toggleTerminateCardModal}>
                    <StyledClose/>
                </CloseButton>
            </div>
            <ModalContent>
                Are you sure you want to close this card? This action is permanent and cannot be undone.
            </ModalContent>
            <ModalButtonsContainer>
                <CancelButton onClick={toggleTerminateCardModal}>Cancel</CancelButton>
                <TerminateButton onClick={terminateCard}>Close Card</TerminateButton>
            </ModalButtonsContainer>
        </Modal>
    </Container>
}

const Header = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 20px;
`

const DetailsHeader = styled.div`
    ${styles.MediaQueries.Mobile} {
        padding-top: 20px;
    }
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

type DetailsColProps = {
    flex: string
}

const DetailsCol = styled.div<DetailsColProps>`
    flex: ${props => props.flex};
    display: flex
    flex-direction: column;
`

const StyledClose = styled(Close)`
    width: 24px;
    height: 24px;
`

const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    ${styles.MediaQueries.Mobile} {
        padding: ${styles.Spacing.S} 0;
    }
`

const MccContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    ${styles.MediaQueries.Mobile} {
        padding: ${styles.Spacing.S} 0;
    }
`

const ModalButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    padding: ${styles.Spacing.S};
`

const TerminateButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color ${styles.Color.White};
    border: 0;
    padding: 8px;
    flex: 1;
    margin-left: 4px;
    margin-top: ${styles.Spacing.XS};
    height: ${styles.Spacing.M};
`

const CancelButton = styled.button`
    background-color: ${styles.Color.Grey};
    color ${styles.Color.Black};
    border: 0;
    padding: 8px;
    flex: 1;
    margin-right: 4px;
    margin-top: ${styles.Spacing.XS};
    height: ${styles.Spacing.M};
`

const ModalTitle = styled.div`
    padding: 0 ${styles.Spacing.S};
    font-size: 20px;
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const ModalContent = styled.div`
    font-size: 14px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    padding: 0 ${styles.Spacing.S};
`

const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 10px;
`

const StyledArrowLeft = styled(ArrowLeftIcon)`
    width: 20px;
`

type TransactionTableContainerProps = {
    isMobile: boolean
}

const TransactionTableContainer = styled(motion.div)<TransactionTableContainerProps>`
    min-height: 100vh;
`

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`

type PaginationButtonProps = {
    isVisible: boolean
}

const PaginationButton = styled.div<PaginationButtonProps>`
    display: ${props => props.isVisible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    font-size: ${styles.Spacing.S};
    line-height: ${styles.Spacing.XS};
    margin: 0 ${styles.Spacing.XS};
    margin-bottom: ${styles.Spacing.S};
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
    border: 1px solid ${styles.Color.Black};
    cursor: pointer;
`

const BackButtonContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const BackButtonLabel = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    margin-left: ${styles.Spacing.XS};
    color: ${styles.Color.NearBlack};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
`

const TransactionsTitle = styled.div`
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: ${styles.Font.Size.Medium};
    ${styles.MediaQueries.Desktop} {
        margin-top: 38px;
        margin-bottom: 32px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: ${styles.Spacing.S} ${styles.Spacing.S} ${styles.Spacing.M};
    }
`

const CardDetails = styled.div`
    border-bottom: 1px solid ${styles.Color.Grey};
    display: flex;
    ${styles.MediaQueries.Desktop} {
        padding: 0 0 ${styles.Spacing.M};
        min-height: 300px;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        margin: 0 ${styles.Spacing.S};
    }
`

const Detail = styled.div`
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Mobile} {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
`

type DetailContainerProp = {
    flex: string;
}

const DetailContainer = styled.div<DetailContainerProp>`
    flex: ${props => props.flex};
    ${styles.MediaQueries.Desktop} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

const DetailsRow = styled.div`
    flex: 1 0;
    ${styles.MediaQueries.Desktop} {
        display: flex;
        padding-top: 24px;
        min-height: 80px;
    }
`

const CardTitle = styled.input`
    outline: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    color: ${styles.Color.NearBlack};
    border-bottom: 1px solid ${styles.Color.Grey};
    font-style: normal;
    padding-bottom: 11px;
    line-height: 112%;
    text-overflow: ellipsis;
    /* identical to box height, or 72px */
    letter-spacing: 0.01em;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Mobile} {
        font-size: 1.6em;
        margin-top: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        font-size: 40px;
    }
    &:hover {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
    &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const FixedCardTitle = styled.div`
    outline: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    color: ${styles.Color.NearBlack};
    font-style: normal;
    padding-bottom: 11px;
    line-height: 112%;
    text-overflow: ellipsis;
    /* identical to box height, or 72px */
    letter-spacing: 0.01em;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Mobile} {
        font-size: 1.6em;
        margin-top: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        font-size: 40px;
    }
`

const TerminateCardButton = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    color: red;
    width: fit-content;
    font-style: normal;
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 16px;
        width: 100%;
        margin: 8px 0;
        display: flex;
        justify-content: center;
    }
    ${styles.MediaQueries.Desktop} {
        font-size: ${styles.Font.Size.Small};
    }
`

const DetailLabel = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[500]};
    color: ${styles.Color.Black};
    font-style: normal;
    line-height: 140%;
    white-space: nowrap;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
    }
    ${styles.MediaQueries.Desktop} {
        font-size: ${styles.Font.Size.Small};
    }
`

const Address = styled.div`
    ${styles.Text.DisplaySmall}
    font-size: 20px;
    ${styles.MediaQueries.Mobile} {
        font-size: 15px;
        text-align: right;
        max-width: 50%;
    }
`

const SpendControlData = styled.div`
    ${styles.Text.DisplaySmall}
    font-size: 20px;
    ${styles.MediaQueries.Mobile} {
        font-size: 15px;
        text-align: right;
        max-width: 50%;
    }
`

const AvailableBalance = styled.div`
    ${styles.Text.DisplaySmall}
    font-size: 20px;
`

const CardType = styled.div`
    ${styles.Text.DisplaySmall}
    font-size: 20px;
`

const Column = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

const Sidebar = styled.div`
    min-width: ${SIDEBAR_WIDTH}px;
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Mobile} {
        padding-left: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        padding-left: ${styles.Spacing.M};
        padding-right: ${styles.Spacing.M};    
        padding-top: 48px;
    }
`

const Content = styled.div`
    ${styles.MediaQueries.Mobile} {
        margin-top: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.M};
        margin-top: ${styles.Spacing.M};
        display: flex;
        flex: 1 1 auto;
    }
`

const Container = styled.div`
    background-color: ${styles.Color.White};
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default CardInspector