import React, {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Actions as BookingActions } from 'redux/features/flightBook'
import { FaTimes, FaCheck, FaMoneyBill, FaQuestion  } from 'react-icons/fa'
import styled from 'styled-components'
import styles from 'styles/styles';

type Tax = {
	taxAmount: number;
	taxCode: string;
	taxName: string;
}

interface UpsellProps {
    uuid: any,
    priceDisagreement: any,
	pricePointsBase: any,
    pricePointsTaxes: any,
	priceTotal: any,
	segments: any,
	taxes: Tax[];
	active?: any
}

const Upsell = (props: UpsellProps) => {
	const totalPoints = props.pricePointsBase + props.pricePointsTaxes
	const dispatch = useDispatch()
	const searchParams = useSelector((state: any) => state.flightSearch.searchParams)
	const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)


	const [selected, setSelected] = useState(false)
    const clickButton = () => {
		if (selected) {
			// Deselect
			dispatch(BookingActions.clearUpsellPrice())
		} else {
			dispatch(BookingActions.selectUpsellPrice({
				pricePointsBase: props.pricePointsBase,
				pricePointsTaxes: props.pricePointsTaxes,
				upsellUuid: props.uuid,
				taxes: props.taxes
			}))
		}
    }
	
	const resToIcon = (res: any) => {
		if (res === "not_offered") {
			return <IconContainer><FaTimes fill={styles.Color.FailureRed}/></IconContainer>
		} else if (res === "included") {
			return <IconContainer><FaCheck fill={styles.Color.SuccessGreen}/></IconContainer>
		} else if (res === "at_charge") {
			return <IconContainer><FaMoneyBill /></IconContainer>
		} else {
			return <IconContainer><FaQuestion /></IconContainer>
		}
	}

	useEffect(() => {
		// Set initial upsell to the one reflected in the itinerary search
		const outboundPrice = searchParams?.outboundItinerary?.priceTotal || 0
		const inboundPrice = searchParams?.inboundItinerary?.priceTotal || 0
		const initialPrice = Math.max(inboundPrice, outboundPrice)
		if (initialPrice === props.priceTotal) {
			dispatch(BookingActions.selectUpsellPrice({
				pricePointsBase: props.pricePointsBase,
				pricePointsTaxes: props.pricePointsTaxes,
				upsellUuid: props.uuid,
				taxes: props.taxes
			}))
		}
    }, [dispatch]) // eslint-disable-line

	useEffect(() => {
		if (props.uuid === priceDetail.upsellUuid) {
			setSelected(true)
		} else {
			setSelected(false)
		}
    }, [priceDetail.upsellUuid]) // eslint-disable-line

	const renderSegment = (segment: any) => {		
		return <MainContainer selected={selected}>
			<HeaderContainer>
				<Title>{segment.name.charAt(0).toUpperCase() + segment.name.slice(1).toLowerCase() }</Title>
				<SubTitle>{(totalPoints).toLocaleString()} points</SubTitle>
			</HeaderContainer>
			<ItemContainer>{resToIcon(segment.reserveSeatAssignment)} Seat selection</ItemContainer>
			<ItemContainer>{resToIcon(segment.priorityBoarding)} Priority boarding</ItemContainer>
			<ItemContainer>{resToIcon(segment.changeable)} Ticket changes</ItemContainer>
			<ItemContainer>{resToIcon(segment.refundable)} Refundable</ItemContainer>
			<Divider/>
			<ItemContainer>{resToIcon(segment.carryonBaggageAllowance)} Carry on bag</ItemContainer>
			<ItemContainer>{resToIcon(segment.checkedBaggageAllowance)} Checked bag</ItemContainer>
			<br></br>
			<ButtonWrapper><Button selected={selected} onClick={clickButton}>Select</Button></ButtonWrapper>
		</MainContainer>
	}

    return <div>{renderSegment(props.segments[0])}</div>

}

type StyleProps = {
    selected: boolean
}

const IconContainer = styled.div`
    width: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.XS};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
`

const ItemContainer = styled.div`
    display: flex;
    align-items: left;
	margin-left: 5px;
`

const Divider = styled.div`
	border-top: 1px solid #bbb;
	margin: 5px;
`
const HeaderContainer = styled.div`
    display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 5px
`

const Title = styled.div`
	width: 60%;
	margin-left: 5px;
	font-weight: ${styles.Font.Weight[600]};
	height: 50px;
`

const SubTitle = styled.div`
	display: flex;
	justify-content: right;
	width: 40%;
	margin-right: 5px;
	font-weight: ${styles.Font.Weight[500]};
`
const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
`
const Button = styled.button<StyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
	background-color: ${props => props.selected ? '#000000' : styles.Color.TaekusPurple};
	color: ${styles.Color.White};
    border: 0;
    padding: 10px;
    flex: 1;
    margin: ${styles.Spacing.XS};
    height: 30px;
	width: 75%;
	&:hover {
		border: 1px solid ${styles.Color.TaekusPurple};
		background-color: ${styles.Color.TaekusCream};
		color: ${styles.Color.TaekusPurple};
	}
`

const MainContainer = styled.div<StyleProps>`
	border: solid 1px #bbb;
	background-color: ${props => props.selected ? '#ededed' : '#f8f8f8'};
	width: 300px;
	height: 300px;
	margin: 15px;
	padding: 10px;
`


export default Upsell