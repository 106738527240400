import React, { ReactElement } from "react"
import { default as ReactSelect } from 'react-select';
import baseStyle from "components/common/Select/selectStyles"
import Props from 'react-select';

type SelectProps = {
  onChange: (option: any) => void,
  className?: Props,
  defaultValue?: Props,
  dropdownIndicator?: ReactElement,
  inputValue?: string,
  isSearchable?: boolean,
  menuPortalTarget?: any,
  menuPosition?: any,
  onInputChange?: (option: any) => void,
  onFocus?: () => void,
  options?: any,
  placeholder?: string,
  styleType?: any,
  value?: Props,
  isDisabled?: boolean,
  isMulti?: any,
  getOptionLabel?: any
};

const Select = (props: SelectProps) => {
  const getComponents = () => {
    let components = {
      IndicatorSeparator: () => null,
    } as any

    if (props.dropdownIndicator) {
      components.DropdownIndicator = () => props.dropdownIndicator
    }

    return components
  }

  return <ReactSelect
    isMulti={props.isMulti}
    className={props.className?.toString()}
    components={getComponents()}
    defaultValue={props.defaultValue}
    isSearchable={props.isSearchable || false}
    inputValue={props.inputValue}
    onChange={props.onChange}
    onInputChange={props.onInputChange}
    menuPortalTarget={props.menuPortalTarget}
    menuPosition={props.menuPosition}
    options={(props.options)}
    placeholder={props.placeholder}
    styles={props.styleType || baseStyle}
    value={props.value}
    onFocus={props.onFocus}
    isDisabled={props.isDisabled}
    getOptionLabel={props.getOptionLabel}
  />
}

export default Select;
