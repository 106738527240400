// The purpose of this file is to collect the handful of common style constants we need in JS for
// use in individual components. Since some components only natively support passing styles as props,
// directly adding css classes to them from the stylesheets is annoying. this file fixes that.

export const backgroundBlue = () => {
  return '#01193D'
}

export const backgroundBlueInactive = () => {
  return 'rgba(1, 25, 61, 0.4)'
}
export const backgroundBlue15PctOpacity = () => {
  return 'rgba(1, 25, 61, 0.15)'
}
export const backgroundBlue8PctOpacity = () => {
  return 'rgba(1, 25, 61, 0.08)'
}
export const backgroundBlueRGBAFragment = () => {
  return '1, 25, 61,'
}
export const goldGradientLight = () => {
  return '#FCE9D1'
}
export const goldGradientDark = () => {
  return '#DDB5B2'
}
export const gray = () => {
  return '#C4C4C4'
}
export const white = () => {
  return '#FFFFFF'
}
export const transparentGray = () => {
  return 'rgba(90, 115, 138, 0.1)'
}
