import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import styled from "styled-components";

import { ToggleFeature, isFeatureEnabled } from "utils/toggles";

import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'

import Navigation, { NavColor } from "components/navbar/Navigation";
import FilterButton from "components/common/FilterButton";

import DefaultLoadingPage from "components/pages/DefaultLoadingPage";

import Addresses from "components/pages/Settings/Addresses";
import Business from "components/pages/Settings/Business";
import CardSettings from "components/pages/Settings/CardSettings/CardSettings";
import Developers from "components/pages/Settings/Developers";
import ExternalAccounts from "components/pages/Settings/ExternalAccounts";
import Notifications from "components/pages/Settings/Notifications";
import Passengers from "components/pages/Settings/Passengers/Passengers";
import Personal from "components/pages/Settings/Personal";
import Security from "components/pages/Settings/Security";
import Travel from "components/pages/Settings/Travel";
import UserAccess from "components/pages/Settings/UserAccess";

import styles from "styles/styles";

enum SidebarTab {
    Personal='Personal',
    Business='Business',
    Security='Security',
    CardSettings='CardSettings',
    Notifications='Notifications',
    Addresses='Addresses',
    Travel='Travel',
    Passengers='Passengers',
    ExternalAccounts='ExternalAccounts',
    Developers='Developers',
    UserAccess='UserAccess',
}

type SettingsURLParams = {
    tab: string
}

const messages = {
    sidebarTabTitles: {
        [SidebarTab.Personal]: 'Personal',
        [SidebarTab.Business]: 'Business',
        [SidebarTab.Security]: 'Security',
        [SidebarTab.CardSettings]: 'Card Settings',
        [SidebarTab.UserAccess]: 'User Access',
        [SidebarTab.Notifications]: 'Notifications',
        [SidebarTab.Addresses]: 'Addresses',
        [SidebarTab.Travel]: 'Travel',
        [SidebarTab.Passengers]: 'Passengers',
        [SidebarTab.ExternalAccounts]: 'External Accounts',
        [SidebarTab.Developers]: 'Developers',
    }
}

const Settings = () => {
    // Redux state
    const dispatch = useDispatch()
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const hasApiKeyAccess = useSelector((state: any) => state.currentUser.currentUser.hasApiKeyAccess)
    const isLoadingCurrentUser = useSelector((state: any) => state.currentUser.isFetching)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)

    const { tab } = useParams<SettingsURLParams>();
    const history = useHistory();

    const hasBusiness = (user: any) => {
        return user.cardAccounts.some((cardAccount: any) => cardAccount?.isBusiness)
    }

    const getSelectedTabFromURL = () => {
        switch (tab) {
            case SidebarTab.CardSettings.toLowerCase():
                return SidebarTab.CardSettings
            case SidebarTab.Security.toLowerCase():
                return SidebarTab.Security
            case SidebarTab.Notifications.toLowerCase():
                return SidebarTab.Notifications
            case SidebarTab.Addresses.toLowerCase():
                return SidebarTab.Addresses
            case SidebarTab.Travel.toLowerCase():
                return SidebarTab.Travel
            case SidebarTab.Passengers.toLowerCase():
                return SidebarTab.Passengers
            case SidebarTab.ExternalAccounts.toLowerCase():
                return SidebarTab.ExternalAccounts
            case SidebarTab.Business.toLowerCase():
                return SidebarTab.Business
            case SidebarTab.UserAccess.toLowerCase():
                return SidebarTab.UserAccess
            case SidebarTab.Personal.toLowerCase():
            default:
                return SidebarTab.Personal
        }
    }

    // Component state
    const [selectedSidebarTab, setSelectedSidebarTab] = useState(getSelectedTabFromURL())
    
    const isAuthorizedUsersEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.AuthorizedUsers)
    
    useEffect(() => {
        dispatch(LinkedAccountActions.getLinkedAccounts())
    }, [dispatch])

    const getTabContent = () => {
        switch (selectedSidebarTab) {
            case SidebarTab.CardSettings:
                return <CardSettings/>
            case SidebarTab.ExternalAccounts:
                return <ExternalAccounts/>
            case SidebarTab.Travel:
                return <Travel/>
            case SidebarTab.Passengers:
                return <Passengers/>
            case SidebarTab.Addresses:
                return <Addresses/>
            case SidebarTab.Notifications:
                return <Notifications/>
            case SidebarTab.Security:
                return <Security/>
            case SidebarTab.Developers:
                return <Developers/>
            case SidebarTab.Business:
                return <Business/>
            case SidebarTab.UserAccess:
                return isAuthorizedUsersEnabled ? <UserAccess/> : <Personal/>
            case SidebarTab.Personal:
            default:
                return <Personal/>
        }
    }

    const mapSidebarTabToItem = (tab: SidebarTab) => {
        const onItemClick = () => {
            setSelectedSidebarTab(tab);
            history.push(`/settings/${tab.toLowerCase()}`)
        }

        return <SidebarItem
            selected={selectedSidebarTab === tab}
            onClick={onItemClick}
            key={tab}
        >
            {messages.sidebarTabTitles[tab]}
        </SidebarItem>
    }

    const sidebarTabs = [
        SidebarTab.Personal,
        ...hasBusiness(currentUser) ? [SidebarTab.Business] : [],
        SidebarTab.Security,
        SidebarTab.CardSettings,
        SidebarTab.Notifications,
        SidebarTab.Addresses,
        SidebarTab.Travel,
        SidebarTab.Passengers,
        SidebarTab.ExternalAccounts,
        ...(isAuthorizedUsersEnabled ? [SidebarTab.UserAccess] : []),
        ...hasApiKeyAccess ? [SidebarTab.Developers] : [],
    ]

    // Hide loading state with generic page since some tabs are shown on toggle
    if (isLoadingCurrentUser) {
        return <DefaultLoadingPage/>
    }

    return <Container>
        <Navigation color={NavColor.Black}/>
        <Content>
            {isMobile ? <div>
                <MobileTitle>Settings</MobileTitle>
                <FilterButtonContainer>
                    {sidebarTabs.map(tab => <FilterButton 
                        onClick={() => {
                            setSelectedSidebarTab(tab)
                            history.push(`/settings/${tab.toLowerCase()}`)
                        }}
                        selected={selectedSidebarTab === tab}
                    >
                        {messages.sidebarTabTitles[tab]}
                    </FilterButton>)}
                </FilterButtonContainer>
            </div> : <Sidebar>
                {sidebarTabs.map(mapSidebarTabToItem)}
            </Sidebar>}
            <TabContent>
                {getTabContent()}
            </TabContent>
        </Content>
    </Container>
}

const MobileTitle = styled.div`
    padding: 0 ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Medium};
`

const FilterButtonContainer = styled.div`
    display: flex;
    width: 100%;
    overflow-x: auto;
    height: 50px;
    margin: ${styles.Spacing.S} 0;
    padding: 0 ${styles.Spacing.S} ${styles.Spacing.XS};
    mask-image: linear-gradient(to right, transparent, black ${styles.Spacing.S}, black calc(100% - ${styles.Spacing.S}), transparent 100%);
    ${styles.Scrollbar.transparent}
`

const TabContent = styled.div`
    margin-bottom: ${styles.Spacing.M};
    flex: 1;
    max-height: 100%;
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.S} ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.S};
        padding-bottom: ${styles.Spacing.M};
    }
`

type SidebarItemProps = {
    selected: boolean;
}

const SidebarItem = styled.div<SidebarItemProps>`
    ${props => props.selected && `color: ${styles.Color.TaekusPurple};`}
    ${styles.Animation.transitionStyles}
    cursor: pointer;
    &:hover {
        color: ${styles.Color.TaekusPurple};
    }
`

const Sidebar = styled.div`
    display: flex; 
    flex-direction: column;
    width: 240px;
    padding-left: ${styles.Spacing.M};
    padding-right: ${styles.Spacing.M};
    padding-top: 50px;
`

const Content = styled.div`
    flex: 1;
    padding-top: 48px;
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.M};
    }
`

const Container = styled.div`
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: ${styles.Color.Black};
    overflow-y: scroll;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default Settings;