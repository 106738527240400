import API from 'services/api'
import { Actions as UserActions } from 'redux/currentUser'
import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'
import { Actions as BankingActions } from 'redux/features/banking/banking'

const featureName = 'Transfers'

const ACTION_UPDATE_VALUE = `${featureName}/UPDATE_VALUE`
const ACTION_CLEAR_TRANSFERS = `${featureName}/CLEAR_TRANSFERS`

const ACTION_FETCH_TRANSFERS_PARTIAL_START = `${featureName}/FETCH_TRANSFERS_PARTIAL_START`
const ACTION_FETCH_TRANSFERS_FULL_START = `${featureName}/FETCH_TRANSFERS_FULL_START`
const ACTION_FETCH_TRANSFERS_COMPLETE = `${featureName}/FETCH_TRANSFERS_COMPLETE`
const ACTION_FETCH_TRANSFERS_ERROR = `${featureName}/FETCH_TRANSFERS_ERROR`

const ACTION_CREATE_TRANSFER_START = `${featureName}/CREATE_START`
const ACTION_CREATE_TRANSFER_COMPLETE = `${featureName}/CREATE_COMPLETE`
const ACTION_CREATE_TRANSFER_ERROR = `${featureName}/CREATE_ERROR`

const ACTION_CREATE_PEER_TRANSFER_START = `${featureName}/PEER/CREATE_START`
const ACTION_CREATE_PEER_TRANSFER_COMPLETE = `${featureName}/PEER/CREATE_COMPLETE`
const ACTION_CREATE_PEER_TRANSFER_ERROR = `${featureName}/PEER/CREATE_ERROR`

const ACTION_CANCEL_TRANSFER_START = `${featureName}/CANCEL_START`
const ACTION_CLEAR_CANCEL_TRANSFER = `${featureName}/CLEAR_CANCEL`
const ACTION_CANCEL_TRANSFER_COMPLETE = `${featureName}/CANCEL_COMPLETE`
const ACTION_CANCEL_TRANSFER_ERROR = `${featureName}/CANCEL_ERROR`

export const Actions = {
  clearTransfers: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_TRANSFERS })
  },

  updateValue: (newValue) => ({
    type: ACTION_UPDATE_VALUE,
    payload: newValue,
  }),

  fetchTransfers:
    (params, partial = true) =>
    (dispatch) => {
      if (partial) {
        dispatch({ type: ACTION_FETCH_TRANSFERS_PARTIAL_START })
      } else {
        dispatch({ type: ACTION_FETCH_TRANSFERS_FULL_START })
      }

      return API.banking.transfers
        .fetch(params)
        .then((response) => {
          dispatch({
            type: ACTION_FETCH_TRANSFERS_COMPLETE,
            payload: response.data,
          })
        })
        .catch((e) => {
          dispatch({
            type: ACTION_FETCH_TRANSFERS_ERROR,
            payload: e,
          })
        })
    },
  createTransfer: (transferParams) => (dispatch) => {
    dispatch({
      type: ACTION_CREATE_TRANSFER_START,
      payload: transferParams,
    })

    return API.banking.transfers
      .create(transferParams)
      .then((response) => {
        // if it's an internal transfer, update balance on both accounts in user, banking, and linkedAccounts
        if (transferParams.isInternalAccount) {
          // Update balance for the source account under currentUser cardAccount list
          dispatch(UserActions.updateUserCardAccount({
            cardAccountUuid: transferParams.cardAccountUuid,
            availableBalance: Number(transferParams.transferValue) * (transferParams.transferDirection === 'inbound' ? 1 : -1)
          }))
          // Update balance for the destination account under currentUser cardAccount list
          dispatch(UserActions.updateUserCardAccount({
            cardAccountUuid: transferParams.externalAccountUuid,
            availableBalance: Number(transferParams.transferValue) * (transferParams.transferDirection === 'outbound' ? 1 : -1)
          }))
          // Update balance for the source account under linkedAccount's internal accounts
          dispatch(LinkedAccountActions.updateInternalAccount({
            uuid: transferParams.cardAccountUuid,
            availableBalance: Number(transferParams.transferValue) * (transferParams.transferDirection === 'inbound' ? 1 : -1)
          }))
          // Update balance for the destination account under linkedAccount's internal accounts
          dispatch(LinkedAccountActions.updateInternalAccount({
            uuid: transferParams.externalAccountUuid,
            availableBalance: Number(transferParams.transferValue) * (transferParams.transferDirection === 'outbound' ? 1 : -1)
          }))
          /*
           * Update balance on cardAccount -- firing 2 actions, with unique uuids. These actions will only apply
           * if the supplied cardAccountUuid matches the current account selection. Using this method as this reducer
           * has no access to the banking store to see what the selected account uuid is.
           * 
           * We should wholly replace these 6 actions when we inevitably merge our redux stores to include all card
           * data in a single store.
           */
          dispatch(BankingActions.hopefulUpdateCardAccountBalance({
            availableBalance: Number(transferParams.transferValue) * (transferParams.transferDirection === 'inbound' ? 1 : -1),
            cardAccountUuid: transferParams.externalAccountUuid,
          }))
          dispatch(BankingActions.hopefulUpdateCardAccountBalance({
            availableBalance: Number(transferParams.transferValue) * (transferParams.transferDirection === 'outbound' ? 1 : -1),
            cardAccountUuid: transferParams.cardAccountUuid,
          }))
        }
        dispatch({
          type: ACTION_CREATE_TRANSFER_COMPLETE,
          payload: {
            transferParams,
            transferUuid: response.data.uuid,
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_TRANSFER_ERROR,
          payload: { ...e.response?.data },
        })
      })
  },
  cancelTransfer: (transferParams) => (dispatch) => {
    dispatch({ type: ACTION_CANCEL_TRANSFER_START })

    return API.banking.transfers
      .delete(transferParams)
      .then((response) => {
        dispatch({
          type: ACTION_CANCEL_TRANSFER_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CANCEL_TRANSFER_ERROR,
          payload: e,
        })
      })
  },
  clearCancelTransfer: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_CANCEL_TRANSFER })
  },
  createPeerToPeerTransfer: (transferParams) => (dispatch) => {
    dispatch({
      type: ACTION_CREATE_PEER_TRANSFER_START,
      payload: transferParams,
    })

    return API.banking.transfers
      .createPeerTransfer(transferParams)
      .then((response) => {
        // Update balance under currentUser cardAccount list
        dispatch(UserActions.updateUserCardAccount({
          cardAccountUuid: transferParams.cardAccountUuid,
          availableBalance: -transferParams.amount
        }))
        // Update balance under cardAccount
        dispatch(BankingActions.hopefulUpdateCardAccountBalance({
          availableBalance: -transferParams.amount
        }))
        // Update balance under linkedAccounts internal account
        dispatch(LinkedAccountActions.updateInternalAccount({
          uuid: transferParams.cardAccountUuid,
          availableBalance: -transferParams.amount
        }))
        dispatch({
          type: ACTION_CREATE_PEER_TRANSFER_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_PEER_TRANSFER_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
}

const defaultState = {
  transfers: [],
  newTransferPending: false,
  peerTransfer: {},
  transferCreated: false,
  isLoading: false,
  error: false,
  createError: false,
  errors: [],
  cancelTransfer: {
    success: null,
    error: false,
    submitting: false,
  },
}

export const TransfersReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_UPDATE_VALUE:
      return {
        ...state,
        ...action.payload,
      }

    case ACTION_CLEAR_TRANSFERS:
      return {
        ...defaultState,
      }

    case ACTION_FETCH_TRANSFERS_FULL_START:
      return {
        ...defaultState,
        isLoading: true,
      }

    case ACTION_FETCH_TRANSFERS_PARTIAL_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_FETCH_TRANSFERS_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }

    case ACTION_CREATE_TRANSFER_START:
      return {
        ...state,
        newTransferPending: true,
        createError: false,
      }

    case ACTION_CREATE_TRANSFER_COMPLETE:
      return {
        ...state,
        ...action.payload.transferParams,
        createError: false,
        newTransferPending: false,
        newTransferUuid: action.payload.transferUuid,
      }

    case ACTION_CREATE_TRANSFER_ERROR:
      return {
        ...state,
        newTransferPending: false,
        isLoading: false,
        createError: true,
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      }

    case ACTION_CREATE_PEER_TRANSFER_START:
      return {
        ...state,
        peerTransfer: {
          isLoading: true,
        },
      }

    case ACTION_CREATE_PEER_TRANSFER_COMPLETE:
      return {
        ...state,
        ...action.payload,
        peerTransfer: {
          ...state.peerTransfer,
          isLoading: false,
          success: true,
        },
      }

    case ACTION_CREATE_PEER_TRANSFER_ERROR:
      return {
        ...state,
        peerTransfer: {
          ...state.peerTransfer,
          isLoading: false,
          error: true,
        },
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      }

    case ACTION_FETCH_TRANSFERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      }

    case ACTION_CANCEL_TRANSFER_START:
      return {
        ...state,
        cancelTransfer: {
          ...state.cancelTransfer,
          submitting: true,
        },
      }

    case ACTION_CANCEL_TRANSFER_COMPLETE:
      return {
        ...state,
        cancelTransfer: {
          success: true,
          error: false,
          submitting: false,
        },
      }

    case ACTION_CANCEL_TRANSFER_ERROR:
      return {
        ...state,
        cancelTransfer: {
          success: false,
          error: true,
          submitting: false,
        },
      }

    case ACTION_CLEAR_CANCEL_TRANSFER:
      return {
        ...state,
        cancelTransfer: {
          success: null,
          error: false,
        },
      }

    default:
      return state
  }
}
