import React from "react"
import { useSelector } from "react-redux"

import moment from "moment"

import styled from "styled-components"

import styles from "styles/styles"

type ApiKeyItemProps = {
    apiKey: any,
    index: number,
    regenerateCallback: (apiKey: any) => void,
    deleteCallback: (apiKey: any) => void,
}

const ApiKeyItem = (props: ApiKeyItemProps) => {
    const isMobile = useSelector((state: any) => state.global.isMobile)

    const { apiKey, index, regenerateCallback, deleteCallback } = props;

    const formatDate = (date: any) => {
        if (date !== undefined  && date !== "") {
            return moment(date).format('YYYY-MM-DD')            
        } else {
            return ""
        }
    }

    return !isMobile ? <Row key={`transfer-item:${index}`}>
        <Cell>
            <Detail>{apiKey.prefix}</Detail>
        </Cell>
        <Cell>
            <Detail>{formatDate(apiKey.created)}</Detail>
        </Cell>
        <Cell>
            <Detail>{formatDate(apiKey.expiryDate)}</Detail>
        </Cell>
        <Cell>
            <Detail>
                <ManageButton onClick={() => regenerateCallback(apiKey)}>Regenerate</ManageButton>
                <ManageButton onClick={() => deleteCallback(apiKey)}>Delete</ManageButton>
            </Detail>
        </Cell>
    </Row> : <MobileRow>
        <MobileDetail>{apiKey.prefix}</MobileDetail>
        <MobileDetail>{formatDate(apiKey.created)}</MobileDetail>
        <MobileDetail>{formatDate(apiKey.expiryDate)}</MobileDetail>
        <MobileDetail>
            <ManageButton onClick={() => regenerateCallback(apiKey)}>Regenerate</ManageButton>
            <ManageButton onClick={() => deleteCallback(apiKey)}>Delete</ManageButton>
        </MobileDetail>
    </MobileRow>
}

const Cell = styled.td`
    padding-right: ${styles.Spacing.XS};
    overflow: hidden;
    vertical-align: unset;
    &:first-child {
        padding-left: ${styles.Spacing.XS};
    }
`

const Row = styled.tr`
    height: ${styles.Spacing.L};
    border-bottom: 1px solid #D7D7D7;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    align-items: center;
    cursor: pointer;
`

const Detail = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`

const MobileDetail = styled.div`
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
        max-width: 30%;
        display: flex;
        flex-direction: column;
        align-items: end;
    }
`

const MobileRow = styled.tr`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D7D7D7;
    min-height: ${styles.Spacing.L};
    padding: ${styles.Spacing.S} 0;
`

const ManageButton = styled.button`
    border: 0;
    background-color: ${styles.Color.Transparent};
    width: fit-content;
    height: fit-content;
    color: ${styles.Color.TaekusPurple};
    &:hover {
        text-decoration: underline;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0;
    }
`

export default ApiKeyItem