import React from 'react'
import qs from 'qs'

import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import classNames from 'classnames'
import get from 'lodash/get'

import moment from 'moment'
import { Row, Col } from 'react-bootstrap'

import { Button, Form, FormGroup } from 'reactstrap'
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select'
import DateInput from '../common/dateInput'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import extractFromAddress from '../common/util'
import { getPrimaryAddressAptNumPrefixOptions } from '../common/userConstants'

import LoadingSpinner from '../common/loadingSpinner'

import API from 'services/api'
import { Actions } from 'redux/currentUser'

import { backgroundBlue, transparentGray, white } from '../common/styleConstants'
import 'react-phone-number-input/style.css'
import 'assets/components/signup/css/signup.scss'

import { ReactComponent as TaekusIcon } from 'assets/svg/TaekusIcon.svg'

const IndicatorSeparator = () => {
  return null
}

class Signup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newUser: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        dateOfBirth: '',
        ssn: '',
        addressObject: '',
        address: {
          unitNum: '',
          unitNumPrefix: '',
          googlePlacesId: '',
          street: '',
          city: '',
          state: '',
          zip: '',
        },
        username: '',
        password: '',
        acceptTerms: false,
      },
      noInviteCode: false,
      usingLoggedInData: false,
      signupError: false,
      validationError: false,
      kycValidationFailed: false,
      signupSuccess: false,
      showPassword: false,
      showSSN: false,
      isSubmitting: false,
      auInviteId: null,
      errors: null,
      showDebugModal: false,
    }
  }

  getIovationBlackboxKey = () => {
    // this references a script pulled in App.js via Helmet
    let bb3 = null

    try {
      bb3 = window.ioGetBlackbox().blackbox
    } catch (exceptionVar) {
      console.log('blackbox error')
    }

    return bb3
  }

  componentDidMount() {
    this.props.dispatch(Actions.fetchCurrentUser())

    const auInviteId = get(
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true }),
      'au-invite-id',
      null
    )

    this.setState({
      auInviteId: auInviteId,
    })

    if (auInviteId !== null) {
      this.setState({
        newUser: {
          ...this.state.newUser,
          auInviteId: auInviteId,
        },
      })
    } else {
      this.setState({ noInviteCode: true })
    }
  }

  toggleShowPassowrd = (event) => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  toggleShowSSN = (event) => {
    this.setState({ showSSN: !this.state.showSSN })
  }

  generateChangeHandler = (value, fieldName, address = false) => {
    let updatedNewUser = this.state.newUser
    if (address) {
      updatedNewUser.address[fieldName] = value
    } else {
      updatedNewUser[fieldName] = value
    }

    this.setState({
      ...this.state,
      newUser: updatedNewUser,
    })
  }

  parseGoogleAddressComponents = (gAddress, business) => {
    let updatedObject = this.state.newUser
    let address_components = get(gAddress, '[0].address_components', [])

    // sometimes cities are sublocalities, and not localities.
    // Occasionally, no city exists at all, so we set it to a blank string.
    let city = ''

    if (extractFromAddress(address_components, 'locality') !== null) {
      city = extractFromAddress(address_components, 'locality')
    } else if (extractFromAddress(address_components, 'sublocality') !== null) {
      city = extractFromAddress(address_components, 'sublocality')
    } else {
      city = ''
    }

    updatedObject = {
      ...updatedObject,
      address: {
        ...updatedObject.address,
        street:
          extractFromAddress(address_components, 'street_number') +
          ' ' +
          extractFromAddress(address_components, 'route'),
        city: city,
        state: extractFromAddress(address_components, 'administrative_area_level_1', true),
        zip: extractFromAddress(address_components, 'postal_code'),
      },
    }
    this.setState({
      ...this.state,
      newUser: updatedObject,
    })
  }

  saveAddressDataThenSignUp = (e) => {
    e.preventDefault()
    let consumerPlacesId = this.state.newUser.address['googlePlacesId']

    // first we handle consumer primary address and if that succeeds then we signup
    geocodeByPlaceId(consumerPlacesId)
      .then((gAddressConsumer) => {
        this.parseGoogleAddressComponents(gAddressConsumer, false)
      })
      .then(() => {
        this.signup(e)
      })
      .catch((error) => console.error(error))
  }

  handleAddressChange = (value, business = false) => {
    let updatedNewUser = this.state.newUser

    updatedNewUser['addressObj'] = value
    updatedNewUser.address['googlePlacesId'] = get(value, 'value.place_id', '')

    this.setState({
      ...this.state,
      newUser: updatedNewUser,
    })
  }

  signup = (e) => {
    const { newUser } = this.state
    const iovationBlackboxKey = this.getIovationBlackboxKey()
    this.setState({ isSubmitting: true })

    // stripe all occurances of "-" in ssn if it was the 11 character variety
    newUser['ssn'] = newUser['ssn'].replace(/-/g, '')

    let params = {
      ...newUser,
      iovationBlackboxKey: iovationBlackboxKey,
    }

    API.auth
      .signupAuthorizedUser(params)
      .then((response) => {
        if (response.status === 201) {
          API.auth.login(newUser.username, newUser.password).then((response) => {
            const { access, refresh } = response.data
            localStorage.setItem('accessToken', access)
            localStorage.setItem('refreshToken', refresh)
            this.setState({ signupSuccess: true })
          })
        } else if (response.status === 206) {
          this.setState({
            signupSuccess: false,
            kycValidationFailed: true,
            isSubmitting: false,
          })
        }
      })
      .catch((e) => {
        if (e.response.status === 422) {
          this.setState({
            validationError: true,
            isSubmitting: false,
            errors: e.response.data.errors,
          })
        } else {
          this.setState({ signupError: true, isSubmitting: false, errors: e.response.data.errors })
        }
      })
  }

  renderSignupForm() {
    const { isSubmitting, validationError, showPassword, showSSN, newUser, errors } = this.state

    const { currentUser } = this.props

    let {
      firstName,
      middleName,
      lastName,
      phone,
      email,
      username,
      password,
      dateOfBirth,
      addressObj,
      address,
      ssn,
      acceptTerms,
    } = newUser

    const { googlePlacesId, unitNum, unitNumPrefix } = address
    const isAlreadyLoggedIn = currentUser.username !== ''

    const isComplete =
      !isAlreadyLoggedIn &&
      firstName &&
      lastName &&
      phone &&
      email &&
      username &&
      password &&
      dateOfBirth &&
      acceptTerms &&
      googlePlacesId &&
      ((ssn.length === 9 && /^\d+$/.test(ssn)) ||
        (ssn.length === 11 && /^\d{3}-?\d{2}-?\d{4}$/.test(ssn)))

    const passwordMissingUpper = !/[A-Z]/.test(password)
    const passwordMissingDigit = !/\d/.test(password)
    const passwordTooShort = password.length < 12
    const passwordInvalid = passwordMissingUpper || passwordMissingDigit || passwordTooShort

    // our usernames only supporty certain characters...
    const usernameInvalid = !/^[a-z0-9@.+-_]*$/.test(username)

    // if address line 2 is provided, you must specify a unit prefix (apt/unit/floor etc).
    // this line coerces the inputs to bools and confirms that both fields are either full OR empty
    const addressLine2Invalid = !(!!address.unitNumPrefix === !!address.unitNum)

    const isDisabled = !isComplete || isSubmitting

    const selectStyles = {
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'DM Sans',
        fontSize: '0.80em',
        color: backgroundBlue(),
        backgroundColor:
          state.isDisabled || state.isFocused || state.isSelected ? transparentGray() : white(),
        '&:active': {
          backgroundColor: transparentGray(),
        },
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#2A4966',
        paddingRight: '0',
        marginRight: '-1px',
        transform: 'Scale(0.80)',
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: '24px',
        background: 'transparent',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '24px',
        padding: '0',
      }),
      singleValue: (provided) => ({
        ...provided,
        fontFamily: 'DM Sans',
        fontSize: '0.80em',
      }),
      control: (provided, state) => ({
        ...provided,
        height: '24px',
        backgroundColor: 'transparent',
        minHeight: '33px',
        outline: 'none',
        color: 'white',
        // padding: '0.2em 0.5em 0.3em 0.5em',
        boxShadow: state.isSelected || state.isFocused ? `none` : 'none',
        border: `none`,
        borderRadius: '0',
        borderBottom: '1px solid #D9D9D9',
        '&:hover': {
          borderBottom: '1px solid #7C3D76',
        },
        '&:active': {
          borderBottom: '1px solid #7C3D76',
        },
      }),
    }

    const primaryAddressAptNumPrefixOptions = getPrimaryAddressAptNumPrefixOptions()

    return (
      <div className="pt-2 pt-sm-0 m-2 m-sm-0">
        <h4 className="Signup-subtitle mb-0">
          <FormattedMessage
            id="signup.authorized-user.signup"
            defaultMessage={`Set up your Taekus authorized user account`}
          />
        </h4>
        {validationError && (
          <div className="StandardBodyDMSans text-red text-center mt-3">
            <FormattedMessage
              id="login.error"
              defaultMessage={`Account Creation Unsuccessful. Please Try again.`}
            />
          </div>
        )}
        {get(errors, 'inviteCode', null) && (
          <div className="StandardBodyDMSans text-bold text-red text-center mt-3">
            {errors.inviteCode}
          </div>
        )}
        <div className="StandardSubtitleDMSans mt-3">
          To help the government fight the funding of terrorism and money laundering activities,
          federal law requires all financial institutions to obtain, verify, and record information
          that identifies each person who opens an account.
        </div>

        <div className="StandardSubtitleDMSans my-2">
          Banking services provided by Stearns Bank NA, member FDIC.
        </div>

        <Form className="Signup-form" onSubmit={this.saveAddressDataThenSignUp}>
          <h5 className="StandardTitleDMSans font-weight-normal mt-3 mb-0">Personal Details</h5>
          <div className="StandardSubtitleDMSans mb-1">
            Tell us about yourself -- we need your name and your birthday as listed on your official
            documents.
          </div>
          <FormGroup>
            <input
              type="text"
              name="firstName"
              className="Signup-Input w-100 mt-1"
              placeholder="First Name"
              autoCorrect="off"
              autoComplete="none"
              disabled={isAlreadyLoggedIn}
              value={firstName}
              onChange={(e) => this.generateChangeHandler(e.target.value, 'firstName')}
            />
            {get(errors, 'firstName', null) && (
              <div className="text-bold text-red">{errors.firstName}</div>
            )}
          </FormGroup>
          <FormGroup>
            <input
              type="text"
              name="middleName"
              className="Signup-Input w-100"
              placeholder="Middle Name"
              autoCorrect="off"
              autoComplete="none"
              disabled={isAlreadyLoggedIn}
              value={middleName}
              onChange={(e) => this.generateChangeHandler(e.target.value, 'middleName')}
            />
            {get(errors, 'middleName', null) && (
              <div className="text-bold text-red">{errors.middleName}</div>
            )}
          </FormGroup>
          <FormGroup>
            <input
              type="text"
              name="lastName"
              className="Signup-Input w-100"
              placeholder="Last Name"
              autoCorrect="off"
              autoComplete="none"
              disabled={isAlreadyLoggedIn}
              value={lastName}
              onChange={(e) => this.generateChangeHandler(e.target.value, 'lastName')}
            />
            {get(errors, 'lastName', null) && (
              <div className="text-bold text-red">{errors.lastName}</div>
            )}
          </FormGroup>
          <FormGroup className="login-formGroup mb-2">
            <DateInput
              datePickerClassName="Signup-Input w-100"
              selected={dateOfBirth && moment(dateOfBirth).toDate()}
              dateFormat="MM/dd/yyyy"
              placeholderText="Date of Birth"
              selectedVariableName="dateOfBirth"
              handleDateChange={(date) =>
                moment(date).isValid() &&
                this.generateChangeHandler(moment(date).format('YYYY-MM-DD'), 'dateOfBirth')
              }
              maxDate={moment().subtract(5, 'years').toDate()}
              minDate={moment().subtract(120, 'year').toDate()}
              disabled={isAlreadyLoggedIn}
            />
            {get(errors, 'dateOfBirth', null) && (
              <div className="text-bold text-red">{errors.dateOfBirth}</div>
            )}
          </FormGroup>

          <h5 className="StandardTitleDMSans font-weight-normal mt-4 mb-0">Social Security</h5>
          <div className="StandardSubtitleDMSans mb-1">
            To help verify your identity and deliver services, Taekus shares your SSN with trusted
            third party service providers and Stearns Bank, NA.
          </div>
          <FormGroup>
            <input
              type="text"
              name="ssn"
              className={classNames('Signup-Input w-100 mt-1', {
                HiddenInput: !showSSN && ssn !== '',
              })}
              placeholder="Social Security Number"
              autocomplete="new-password"
              value={ssn}
              onChange={(e) => this.generateChangeHandler(e.target.value, 'ssn')}
            />
            <button
              type="button"
              onClick={this.toggleShowSSN}
              className={classNames(
                'Signup-glyph',
                { 'Signup-revealPassword': !showSSN },
                { 'Signup-hidePassword': showSSN }
              )}
            />
            {get(errors, 'ssn', null) && <div className="text-bold text-red">{errors.ssn}</div>}
          </FormGroup>

          <h5 className="StandardTitleDMSans font-weight-normal mt-4 mb-0">Residential Address</h5>
          <div className="StandardSubtitleDMSans mb-1">
            Where should we mail your card? This needs to be a residential address and cannot be a
            PO box.
          </div>
          <FormGroup className="mt-1">
            <GooglePlacesAutocomplete
              apiKey={window.env.REACT_APP_GOOGLE_PLACES_API_KEY}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['us'],
                },
              }}
              selectProps={{
                value: addressObj,
                onChange: (value) => this.handleAddressChange(value),
                placeholder: 'Home Address',
                components: {
                  IndicatorSeparator: IndicatorSeparator,
                },
                styles: selectStyles,
              }}
            />
          </FormGroup>
          <FormGroup>
            <div className="input-group mb-1">
              <Select
                placeholder="Prefix"
                className="addressLineTwoPrefix"
                onChange={(e) => this.generateChangeHandler(e.value, 'unitNumPrefix', true)}
                defaultValue={primaryAddressAptNumPrefixOptions.filter((obj) => {
                  return obj.value === unitNumPrefix
                })}
                options={primaryAddressAptNumPrefixOptions}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={selectStyles}
              />
              <input
                type="text"
                name="primaryAddressAptNum"
                className="Signup-Input flex-grow-1"
                placeholder="Address Line 2 Number (optional)"
                autoComplete="new-password"
                autoCorrect="off"
                value={unitNum}
                onChange={(e) => this.generateChangeHandler(e.target.value.trim(), 'unitNum', true)}
              />
            </div>
            {get(errors, 'address.unitNum', null) && (
              <div className="text-bold text-red">{errors.address.unitNum}</div>
            )}
            <div
              className={classNames('text-red text-size-08 mb-1', {
                invisible: !addressLine2Invalid,
              })}
            >
              Unit number prefix is required if you have a unit number.
            </div>
          </FormGroup>

          <h5 className="StandardTitleDMSans font-weight-normal mt-4 mb-0">Contact Information</h5>
          <div className="StandardSubtitleDMSans mb-1">
            Your phone number will be used for two-factor authentication for this account once
            you're approved.
          </div>
          <div className="StandardSubtitleDMSans text-bold mb-1">
            Note that the email address below must match the email address on your authorized user
            invitation.
          </div>

          <FormGroup>
            <input
              type="email"
              name="email"
              className="Signup-Input w-100"
              placeholder="Email"
              value={email}
              onChange={(e) => this.generateChangeHandler(e.target.value, 'email')}
              disabled={isAlreadyLoggedIn}
            />
            {get(errors, 'email', null) && <div className="text-bold text-red">{errors.email}</div>}
          </FormGroup>
          <FormGroup>
            <PhoneInput
              defaultCountry="US"
              placeholder="Phone Number"
              className="Signup-Input"
              value={phone}
              onChange={(value) => this.generateChangeHandler(value, 'phone')}
              styles={selectStyles}
              disabled={isAlreadyLoggedIn}
            />
            {get(errors, 'phone', null) && <div className="text-bold text-red">{errors.phone}</div>}
          </FormGroup>

          <h5 className="StandardTitleDMSans font-weight-normal mt-4 mb-0">
            Username and Password
          </h5>
          <div className="StandardSubtitleDMSans mb-1">
            Make yourself a username and password for your Taekus account.
          </div>
          <FormGroup>
            <input
              type="text"
              name="username"
              className={classNames('Signup-Input w-100', { 'text-red invalid': usernameInvalid })}
              placeholder="Username"
              value={username}
              autoCorrect="off"
              autoComplete="none"
              onChange={(e) => this.generateChangeHandler(e.target.value.toLowerCase(), 'username')}
              disabled={isAlreadyLoggedIn}
            />
            {get(errors, 'username', null) && (
              <div className="text-bold text-red">{errors.username}</div>
            )}
            {usernameInvalid && (
              <div className="text-red text-size-08">
                Your username may contain only letters, numbers, and @/./+/-/_ characters.
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              className={classNames('Signup-Input w-100', { 'text-red invalid': passwordInvalid })}
              placeholder="Password"
              value={password}
              autoCorrect="off"
              autoComplete="none"
              onChange={(e) => this.generateChangeHandler(e.target.value, 'password')}
            />
            <button
              type="button"
              onClick={this.toggleShowPassowrd}
              className={classNames(
                'Signup-glyph',
                { 'Signup-revealPassword': !showPassword },
                { 'Signup-hidePassword': showPassword },
                { invalid: passwordInvalid }
              )}
            />
            {
              <div className="text-black text-size-07 mt-3">
                Passwords must:
                <ul className="mt-1">
                  <li className={classNames({ 'text-bold text-red': passwordMissingUpper })}>
                    Contain at least one upper case letter
                  </li>
                  <li className={classNames({ 'text-bold text-red': passwordMissingDigit })}>
                    Contain at least one digit.
                  </li>
                  <li className={classNames({ 'text-bold text-red': passwordTooShort })}>
                    Be at least 12 characters long.
                  </li>
                </ul>
              </div>
            }
            {get(errors, 'password', null) && (
              <div className="text-bold text-red">{errors.password}</div>
            )}
          </FormGroup>

          <h5 className="StandardTitleDMSans font-weight-normal mt-4 mb-0">
            And some important information...
          </h5>
          <div className="StandardSubtitleDMSans mb-1">
            All Taekus debit cards are issued by Stearns Bank, NA.
          </div>
          <div>
            <ul className="pl-3">
              <li className="Signup-TermsLink">
                <a
                  href="https://taekus.com/legal/electronic-communications-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="StandardLinkPurple text-size-07 text-bold"
                >
                  Electronic Communication Policy
                </a>
              </li>
              <li className="Signup-TermsLink">
                <a
                  href="https://taekus.com/legal/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="StandardLinkPurple text-size-07 text-bold"
                >
                  Terms of Service
                </a>
              </li>
              <li className="Signup-TermsLink">
                <a
                  href="https://taekus.com/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="StandardLinkPurple text-size-07 text-bold"
                >
                  Privacy Policy (Taekus and Stearns Bank NA)
                </a>
              </li>
            </ul>
          </div>
          <FormGroup>
            <div className="StandardBodyDMSans custom-control custom-checkbox complianceCheckboxContainer mb-4">
              <input
                type="checkbox"
                className="custom-control-input"
                id="compliance-checkbox"
                checked={acceptTerms}
                onChange={() => this.generateChangeHandler(!acceptTerms, 'acceptTerms')}
              />
              <label
                className="custom-control-label StandardSubtitleDMSans"
                htmlFor="compliance-checkbox"
              >
                I have read and agree to the Electronic Communications Disclosure, Taekus Terms of
                Service, and the Taekus Privacy Policy.
              </label>
              {get(errors, 'acceptTerms', null) && (
                <div className="text-bold text-red">{errors.acceptTerms}</div>
              )}
            </div>
          </FormGroup>
          <div className="d-flex flex-column align-items-center">
            <Button
              disabled={isDisabled}
              className={classNames('font-weight-bold mt-3 mb-4', {
                StandardButtonPurple: !isDisabled,
                'Signup-formButtonDisabled StandardButtonGray': isDisabled,
              })}
            >
              <FormattedMessage id="signup.submit" defaultMessage={`Create Account`} />
            </Button>
          </div>
        </Form>
      </div>
    )
  }

  renderLoadingPage() {
    window.scrollTo(0, 0)

    return (
      <div className="d-flex flex-column">
        <div className="mt-5 mb-3">
          <FormattedMessage
            id="signup.loading"
            defaultMessage={`Processing your application....`}
          />
        </div>
        <LoadingSpinner />
      </div>
    )
  }

  render() {
    const { isSubmitting, newUser, signupSuccess, signupError, kycValidationFailed, noInviteCode } =
      this.state
    const bypass2FA = window.env.REACT_APP_BYPASS_2FA === 'true'
    const { currentUser } = this.props
    const isAlreadyLoggedIn = currentUser.username !== ''

    if (isAlreadyLoggedIn) {
      return <Redirect to={{ pathname: '/signup-authorized-user/already-logged-in/' }} />
    }

    // On debug instances we bypass 2fa so people can use fake phone numbers when creating multiple accounts.
    if (signupSuccess && bypass2FA) {
      return <Redirect to={{ pathname: '/signup/success/' }} />
    } else if (signupSuccess) {
      return <Redirect to={{ pathname: '/signup/verify/', state: { phone: newUser.phone } }} />
    }

    if (signupError) {
      return <Redirect to={{ pathname: '/signup/error/' }} />
    }

    if (kycValidationFailed) {
      return <Redirect to={{ pathname: '/signup/unsuccessful/' }} />
    }

    if (noInviteCode) {
      return <Redirect to={{ pathname: '/signup-authorized-user/no-invite/' }} />
    }

    return (
      <Row className="Signup-container m-0">
        <Col md="6" lg="5" className="px-1 px-md-3 px-xl-5">
          <Col xs="12" className="Signup-formBlock mt-4 px-2 px-md-4 px-xl-5">
            {isSubmitting ? this.renderLoadingPage() : this.renderSignupForm()}
          </Col>
        </Col>
        <Col
          md="6"
          lg="7"
          className="Signup-decorativeBackground d-none d-md-flex flex-column align-items-center justify-content-center"
        >
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="Taekus-Icon-Large">
              <TaekusIcon fill="white" style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>

          {/* {debug && (
            <div className="text-white mt-2">
              <h4>Debug instructions</h4>

              <p>
                <i>last</i> name rules:
              </p>

              <ul>
                <li>"Review" They will end up in Alloy Manual Review state</li>
                <li>"Deny" They will end up in Alloy Denied State</li>
                <li>"DocReview" They will end up in Manual Review after Documnet Verification</li>
                <li>"DocDenied" They will end up Denied after Document Verification</li>
                <li>All other last name values will be in the Alloy Approved State</li>
              </ul>

              <p>* Not implemented right now, currently just fails</p>
            </div>
          )} */}
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.currentUser,
})

export default connect(mapStateToProps, null)(Signup)
