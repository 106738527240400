import { CardAccountType } from 'components/pages/Cards/types'

import BusinessDebitCard from 'assets/global/img/banking/business_debit_card.png'
import ConsumerDebitCard from 'assets/global/img/banking/consumer_debit_card.png'


export const getCardBackgroundURL = (type: CardAccountType) => {
    switch(type) {
        case CardAccountType.PREPAID_BUSINESS:
            return BusinessDebitCard
        case CardAccountType.PREPAID_CONSUMER:
        default:
            return ConsumerDebitCard
    }
}