import React, { Component } from 'react'

class AirlineFullLogo extends Component {
  render() {
    const { carrierCode, className } = this.props

    return (
      <div className={className && className}>
        <img
          src={`/static/img/airlineLogos/full/${carrierCode.toUpperCase()}.png`}
          alt={`airline logo ${carrierCode}`}
        />
      </div>
    )
  }
}

export default AirlineFullLogo
