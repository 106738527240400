// Pipeline that takes a list of itineraries as an argument then filters them with any number of rules
// At the end of the pipeline, we deduplicate overlapping flights (ie from all our sources) then groups them by a common key

import { TicketType } from "components/pages/Rewards/constants";


export const itineraryPipeline = (itineraryOptions: any[], ticketType: string) => {
	// Entry point where we can add additional filtering or ordering of itinerary options
	return groupByKey(itineraryOptions.filter(option => ticketTypeFilter(option, ticketType)))
}

interface GroupedItinerary {
    [Key: string]: any;
}

const groupByKey = (itineraryOptions: any[]) => {
	// groups itineraries by a common key, then returns them ordered from cheapest to most expensive

	const groupedItineraries: GroupedItinerary  = {}
	itineraryOptions.forEach(
		(option: any) => {
			const key = itineraryKey(option)
			if (key in groupedItineraries) {
				groupedItineraries[key].push(option)
			} else {
				groupedItineraries[key] = [option]
			}
		}
	)
	return Object.keys(groupedItineraries).map(
		(key: string) => {
			const itineraryList = groupedItineraries[key].sort((a: any, b: any) => a.priceTotal < b.priceTotal ? -1 : 1)			
			return itineraryList
		}
	)
}

const itineraryKey = (itineraryOption: any) => {
	/*
		The key will be composed of a values extracted from the segment information.
	*/
	const key: string[] = []
	itineraryOption?.slices.forEach(
		(slice: any) => {
			slice.segments?.forEach(
				(segment: any) => {
					// Dedupes award flights but not codeshares
					if (itineraryOption.isAwardTicket) {
						key.push(`AWARD:${segment.fltNumber}:${segment.operatingCarrierCode}:${segment.cabin}`)
					} else {
						key.push(`CASH:${segment.fltNumber}:${segment.carrierCode}:${segment.operatingCarrierCode}:${segment.cabin}`)
					}
				}
			)
		}
	)
	return key.join("")
}


const ticketTypeFilter = (itineraryOption: any, ticketType: string) => {
	const isAwardTicket = itineraryOption?.isAwardTicket;
	const isRegularTicket = !itineraryOption?.isAwardTicket;
	const isAwardType = ticketType === TicketType.Award;
	const isRegularType = ticketType === TicketType.Regular;
	return (isAwardType && isAwardTicket) || (isRegularType && isRegularTicket) || (ticketType === TicketType.All)
}