import { ScreenSize } from "./types"

export const getWindowScreenSize = (window: any) => {
    if (window.matchMedia('(max-width: 768px)').matches) {
        return ScreenSize.Mobile
    } else if (window.matchMedia('(max-width: 992px)').matches) {
        return ScreenSize.Tablet
    } else {
        return ScreenSize.Desktop
    }
}