import API from 'services/api'

const featureName = 'Autopay'

const ACTION_UPDATE_STATUS = `${featureName}/UPDATE_STATUS_AUTOPAY`
const ACTION_UPDATE_WORKING_COPY = `${featureName}/UPDATE_WORKING_COPY_AUTOPAY`
const ACTION_CLEAR_AUTOPAY = `${featureName}/CLEAR_AUTOPAY`

const ACTION_FETCH_AUTOPAY_PARTIAL_START = `${featureName}/FETCH_AUTOPAY_PARTIAL_START`
const ACTION_FETCH_AUTOPAY_FULL_START = `${featureName}/FETCH_AUTOPAY_FULL_START`
const ACTION_FETCH_AUTOPAY_COMPLETE = `${featureName}/FETCH_AUTOPAY_COMPLETE`
const ACTION_FETCH_AUTOPAY_ERROR = `${featureName}/FETCH_AUTOPAY_ERROR`

const ACTION_UPDATE_AUTOPAY_START = `${featureName}/UPDATE_AUTOPAY_START`
const ACTION_UPDATE_AUTOPAY_COMPLETE = `${featureName}/UPDATE_AUTOPAY_COMPLETE`
const ACTION_UPDATE_AUTOPAY_ERROR = `${featureName}/UPDATE_AUTOPAY_ERROR`

export const Actions = {
  clearAutopay: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_AUTOPAY })
  },

  updateStatus: (newValue) => ({
    type: ACTION_UPDATE_STATUS,
    payload: newValue,
  }),

  updateWorkingCopy: (newValue) => ({
    type: ACTION_UPDATE_WORKING_COPY,
    payload: newValue,
  }),

  fetchAutopay:
    (params, partial = false) =>
    (dispatch) => {
      if (partial) {
        dispatch({ type: ACTION_FETCH_AUTOPAY_PARTIAL_START })
      } else {
        dispatch({ type: ACTION_FETCH_AUTOPAY_FULL_START })
      }

      return API.banking.autopay
        .get(params)
        .then((response) => {
          dispatch({
            type: ACTION_FETCH_AUTOPAY_COMPLETE,
            payload: { ...response.data },
          })
        })
        .catch((e) => {
          dispatch({
            type: ACTION_FETCH_AUTOPAY_ERROR,
            payload: { ...e.response.data },
          })
        })
    },

  updateAutopay: (params) => (dispatch) => {
    dispatch({ type: ACTION_UPDATE_AUTOPAY_START })

    return API.banking.autopay
      .update(params)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_AUTOPAY_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_AUTOPAY_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
}

// REDUCER

const defaultState = {
  workingCopy: {
    accountType: null,
    active: false,
    uuid: null,
    card_account: null,
    external_account: null,
    debit_reload_balance_trigger: null,
    credit_category: null,
    description: null,
    amount: null,
  },
  displayDisableConfirmation: false,
  updateSuccess: false,
  isLoading: false,
  error: false,
  errors: [],
}

export const AutopayReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      }

    case ACTION_UPDATE_WORKING_COPY:
      return {
        ...state,
        workingCopy: {
          ...state.workingCopy,
          ...action.payload,
        },
      }

    case ACTION_FETCH_AUTOPAY_PARTIAL_START:
    case ACTION_UPDATE_AUTOPAY_START:
      return {
        ...state,
        isLoading: true,
        error: false,
        errors: [],
      }

    case ACTION_FETCH_AUTOPAY_COMPLETE:
      return {
        ...state,
        workingCopy: {
          ...state.workingCopy,
          ...action.payload.autopay,
        },
        isLoading: false,
      }

    case ACTION_UPDATE_AUTOPAY_COMPLETE:
      return {
        ...state,
        workingCopy: {
          ...state.workingCopy,
          ...action.payload,
        },
        updateSuccess: true,
        isLoading: false,
      }

    case ACTION_FETCH_AUTOPAY_FULL_START:
      return {
        ...defaultState,
        isLoading: true,
      }

    case ACTION_CLEAR_AUTOPAY:
      return {
        ...defaultState,
      }

    case ACTION_FETCH_AUTOPAY_ERROR:
    case ACTION_UPDATE_AUTOPAY_ERROR:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}
