import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { motion } from "framer-motion";
import styled from "styled-components";

import Spinner from "components/common/Spinner";

import { fetchCardDetails } from "components/pages/Cards/cardsSlice";
import { CardAccountType } from "components/pages/Cards/types";
import { getCardBackgroundURL } from "components/pages/Cards/utils";

import LogoSVG from "assets/svg/TaekusCardLogo.svg";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

import { ReactComponent as MastercardLogo } from 'assets/old/img/banking/desaturated_mastercard_logo.svg'
import { ReactComponent as VisaLogo } from 'assets/old/img/banking/desaturated_visa_logo.svg'

enum CardColor {
    Blue='Blue',
    Purple='Purple',
    SeaFoam='SeaFoam',
}

const messages = {
    Debit: 'Debit',
    Logo: 'Taekus Logo',
}

type HoverableCardDetailsProps = {
    card: any;
    updateCopyText: (text: string) => void;
    // Required to pass custom styles via `styled(HoverableCardDetails)`
    className?: string;
    color?: CardColor;
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
    hoveredCardUuid: string | null,
    setHoveredCardUuid: (uuid: string | null) => void,
}

const HoverableCardDetails = (props: HoverableCardDetailsProps) => {
  const dispatch = useDispatch()

  // Redux state
  const cardAccountUuid = useSelector((state: any) => state.banking.account.uuid)
  const currentUser = useSelector((state: any) => state.currentUser)
  const parentUser = currentUser.currentUser.parentUser
  const mainUser = parentUser ? parentUser : currentUser.currentUser
  const cardNetwork = useSelector((state: any) => state.banking.account.network)
  const cardDetails = useSelector((state: any) => state.cards.previewCardDetails)
  const isLoading = useSelector((state: any) => state.cards.isLoadingCardDetails)

  const isHovered = props.card.uuid === props.hoveredCardUuid
  const isPhysicalCard = props.card.formFactor === 'PHYSICAL'

  const getCardColor = () => {
      if (isPhysicalCard) {
          return 'transparent'
      }

      switch (props.color) {
          case CardColor.Purple:
              return styles.Color.TaekusPurple
          case CardColor.SeaFoam:
              return styles.Color.TaekusSeaFoam
          case CardColor.Blue:
          default:
              return styles.Color.TaekusBlue
      }
  }

  const color = getCardColor()
  const createCopyHandler = (value: string, label: string) => {
    return () => {
      navigator.clipboard.writeText(value);
      props.updateCopyText(label + ' copied!')
    }
  }

  const handleMouseOver = () => {
    const { card, setHoveredCardUuid } = props;

    setHoveredCardUuid(isPhysicalCard ? null : card.uuid)
  }

  const handleMouseLeave = () => {
    const { setHoveredCardUuid } = props;

    setHoveredCardUuid(null)
  }

  useEffect(() => {
      if (isHovered) {
          dispatch(fetchCardDetails(cardAccountUuid, props.card.uuid))
      }
  }, [isHovered, dispatch]) // eslint-disable-line

  return <div 
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
  >
      <StandardCard 
          isPhysical={isPhysicalCard}
          cardType={props.card.binType}
          className={props.className} 
          color={color}
      >
          {isHovered ? ((isLoading || !cardNetwork || !mainUser.firstName) ? <div className='w-100 d-flex justify-content-center align-items-center' style={{flex: 1}}>
            <Spinner size={40}/>
          </div> : <>
            <motion.div 
              onClick={(ev: React.MouseEvent) => { ev.preventDefault() }}
              onDoubleClick={props.onClick}
              {...fadeInOutMotionProps}
              style={{display: 'flex', flexDirection: 'column', color: 'white', padding: '17px', flex: 1,}}
            >
              <div style={{marginBottom: '20px'}}>
                <div className='w-100 d-flex'>
                  <div style={{ flex: 1 }}>
                    <CopyContainer
                      id=''
                      onClick={createCopyHandler(cardDetails?.pciPan, 'Card number')} 
                      style={{fontSize: '1.0em', fontFamily: 'Monument Grotesk Mono', marginBottom: '5px'}}
                    >
                      {cardDetails?.pciPan?.match(/.{1,4}/g).join(' ')}
                    </CopyContainer>
                    <div className='d-flex'>
                      <div className='d-flex align-items-center' style={{ marginRight: '10px', fontFamily: 'Monument Grotesk' }}>
                        <div style={{fontSize: '0.8em', marginRight: '10px'}}>CVV</div>
                        <CopyContainer
                          onClick={createCopyHandler(cardDetails?.pciCvv, 'CVV')}
                          style={{fontSize: '1.0em', lineHeight: '1.0em'}}
                        >
                          {cardDetails?.pciCvv}
                        </CopyContainer>
                      </div>
                      <div className='d-flex align-items-center'>
                        <div style={{fontSize: '0.8em', marginRight: '10px'}}>EXP</div>
                        <CopyContainer
                          onClick={createCopyHandler(cardDetails?.pciExpirationDate, 'Expiration date')}
                          style={{fontSize: '1.0em', lineHeight: '1.0em'}}
                        >
                          {cardDetails?.pciExpirationDate?.match(/.{1,2}/g).join('/')}
                        </CopyContainer>
                      </div>
                    </div>
                  </div>
                  {cardNetwork && <motion.div {...fadeInOutMotionProps}>{cardNetwork === 'MASTERCARD' ? <StyledMastercardLogo/> : <StyledVisaLogo/>}</motion.div>}
                </div>
              </div>
            <div style={{fontSize: '1.0em', lineHeight: '1.0em', flex: 1, display: 'flex', alignItems: 'center', fontFamily: 'Monument Grotesk'}}>{`${mainUser.firstName} ${mainUser.lastName}`}</div>
        </motion.div>
      <div style={{width: '100%', height: '22.47%', opacity: 0.1, background: 'white' }} />
      </>) : <div style={{padding: '17px', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
          <Title>{messages.Debit}</Title>
          <Logo>
              <img src={LogoSVG} alt={messages.Logo} />
          </Logo>
          </div>}
      </StandardCard>
  </div>
}

const StyledVisaLogo = styled(VisaLogo)`
  width: 60px;
  height: auto;
`

const StyledMastercardLogo = styled(MastercardLogo)`
  width: 60px;
  height: auto;
`

const CopyContainer = styled.div`
  width: fit-content;
  padding: 5px 4px;
  border-radius: 4px;
  cursor: pointer;
  ${styles.Animation.transitionStyles}
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
  
`

const Logo = styled.div`
    display: flex;
    justify-content: end;
    align-items: end;
    flex: 1;
`

const Title = styled.div`
    color: ${styles.Color.White};
    font-weight: ${styles.Font.Weight[400]};
    margin-left: ${styles.Spacing.XS};
    font-style: normal;
    font-size: 14px;
    line-height: 138%;
    letter-spacing: 0.02em;
    opacity: 0.35;
`

type CardProps = {
    color: string;
    cardType: CardAccountType;
    isPhysical?: boolean;
}

const StandardCard = styled.div<CardProps>`
    aspect-ratio: 294 / 178;
    position: relative;
    background-color: ${props => props.color};
    ${props => props.isPhysical && `
        background-image: url(${getCardBackgroundURL(props.cardType)});
        background-size: cover;
    `}
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.isPhysical ? 'grey' : 'transparent'}
`

export default HoverableCardDetails