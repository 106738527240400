import API from 'services/api'

import { Actions as UserActions } from 'redux/currentUser'

const featureName = 'RewardsTransactions'

const ACTION_FETCH_REWARDS_TRANSACTIONS_START = `${featureName}/FETCH_START`
const ACTION_FETCH_REWARDS_TRANSACTIONS_COMPLETE = `${featureName}/FETCH_COMPLETE`
const ACTION_CLEAR_REWARDS_TRANSACTIONS = `${featureName}/CLEAR`
const ACTION_FETCH_REWARDS_TRANSACTIONS_ERROR = `${featureName}/FETCH_ERROR`

const ACTION_CLEAR_STATEMENT_CREDIT_REQUEST = `${featureName}/CLEAR_STATEMENT_CREDIT_REQUEST`
const ACTION_STATEMENT_CREDIT_REQUEST_START = `${featureName}/STATEMENT_CREDIT_REQUEST_START`
const ACTION_STATEMENT_CREDIT_REQUEST_ERROR = `${featureName}/STATEMENT_CREDIT_REQUEST_ERROR`
const ACTION_STATEMENT_CREDIT_REQUEST_COMPLETE = `${featureName}/STATEMENT_CREDIT_REQUEST_COMPLETE`

const ACTION_UPDATE_STATEMENT_CREDIT_REQUEST_WORKING_COPY = `${featureName}/UPDATE_STATEMENT_CREDIT_REQUEST_WORKING_COPY`

export const Actions = {
  clearRewardsTransactions: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_REWARDS_TRANSACTIONS })
  },

  fetchRewardsTransactions:
    (transactionParams = {}) =>
    (dispatch) => {
      dispatch({ type: ACTION_FETCH_REWARDS_TRANSACTIONS_START })

      return API.banking.rewardsTransactions
        .fetch(transactionParams)
        .then((response) => {
          dispatch({
            type: ACTION_FETCH_REWARDS_TRANSACTIONS_COMPLETE,
            payload: response.data,
          })
        })
        .catch((e) => {
          dispatch({
            type: ACTION_FETCH_REWARDS_TRANSACTIONS_ERROR,
            payload: e,
          })
        })
    },

  clearStatementCreditRequest: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_STATEMENT_CREDIT_REQUEST })
  },

  createStatementCreditRequest:
    (params = {}) =>
    (dispatch) => {
      dispatch({ type: ACTION_STATEMENT_CREDIT_REQUEST_START })

      return API.banking.rewardsTransactions
        .createStatementCreditRequest(params)
        .then((response) => {
          dispatch({
            type: ACTION_STATEMENT_CREDIT_REQUEST_COMPLETE,
            payload: response.data,
          })
          dispatch(UserActions.addToPointBalances({
            numPointsPending: -params.amount
          }))
        })
        .catch((e) => {
          dispatch({
            type: ACTION_STATEMENT_CREDIT_REQUEST_ERROR,
            payload: e,
          })
        })
    },

  updatePointsWorkingCopy: (newValue) => ({
    type: ACTION_UPDATE_STATEMENT_CREDIT_REQUEST_WORKING_COPY,
    payload: newValue,
  }),
}

// REDUCER

const defaultState = {
  rewardsTransactions: [],
  isLoading: false,
  error: null,
  errors: [],
  statementCreditRequest: {
    error: false,
    success: false,
    loading: false,
    pointsAmount: 0,
  },
}

export const RewardsTransactionsReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_FETCH_REWARDS_TRANSACTIONS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_FETCH_REWARDS_TRANSACTIONS_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }

    case ACTION_FETCH_REWARDS_TRANSACTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case ACTION_UPDATE_STATEMENT_CREDIT_REQUEST_WORKING_COPY:
      return {
        ...state,
        statementCreditRequest: {
          ...state.statementCreditRequest,
          ...action.payload,
        },
      }

    case ACTION_STATEMENT_CREDIT_REQUEST_START:
      return {
        ...state,
        statementCreditRequest: {
          ...state.statementCreditRequest,
          error: false,
          success: false,
          loading: true,
        },
      }

    case ACTION_STATEMENT_CREDIT_REQUEST_COMPLETE:
      return {
        ...state,
        statementCreditRequest: {
          ...state.statementCreditRequest,
          error: false,
          success: true,
          loading: false,
        },
      }

    case ACTION_STATEMENT_CREDIT_REQUEST_ERROR:
      return {
        ...state,
        statementCreditRequest: {
          ...state.statementCreditRequest,
          error: true,
          success: false,
          loading: false,
        },
      }

    case ACTION_CLEAR_STATEMENT_CREDIT_REQUEST:
      return {
        ...state,
        statementCreditRequest: defaultState.statementCreditRequest,
      }

    case ACTION_CLEAR_REWARDS_TRANSACTIONS:
      return defaultState

    default:
      return state
  }
}
