export const stopPropagation = (event: any) => {
    event.stopPropagation()
}

// Convert flight duration to a readable string (e.x. 210 => 2 hr 30 min)
export const getDurationAsString = (durationInMinutes: number) => {
    const hasHours = Math.floor(durationInMinutes / 60) > 0;
    const hasMinutesRemainder = durationInMinutes % 60 !== 0;

    return (hasHours ? `${Math.floor(durationInMinutes / 60)} ${hasMinutesRemainder ? 'hr' : 'hours'}` : '') + (hasHours && hasMinutesRemainder ? ' ' : '') + (hasMinutesRemainder ? `${(durationInMinutes % 60)} min` : '')
}

// returns true if the input string is not empty or falsy
export const validateString = (str?: string) => {
    return !(!str || str.trim() === '')
}

// returns true if the input string is valid email format
export const validateEmail = (str?: string) => {
    if (str === undefined) { return false };
    return /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(str)
}

// Helper function for onChange of text inputs to validate only numeric USD values
export const simplifyCurrencyString = (amount: string) => {
    const newValue = amount.replaceAll(',|$', '')

    const valueIsANumber = !isNaN(Number(newValue))
    const valueIsInRange = Number(newValue) >= 0 && Number(newValue) < 10000000
    const valueHasUpTo2DecimalPlaces = (!newValue.includes('.') || newValue.split('.')[1]?.length <= 2)

    if (valueIsANumber && valueIsInRange && valueHasUpTo2DecimalPlaces){
        return newValue
    }
}