import React, { Component } from 'react'

import styled from 'styled-components'

import { AppPath } from 'components/appRouter/constants';
import Button, { ButtonSize, ButtonType } from 'components/common/Button'
import Navigation, { NavColor } from 'components/navbar/Navigation';

import styles from 'styles/styles'

import { ReactComponent as TaekusLogo } from "assets/svg/TaekusLogo.svg";

// ErrorBoundary needs to be a class component, as hooks do not yet support componentDidCatch
class ErrorBoundary extends Component<any> {
  state = {
    error: null,
  }

  componentWillUnmount() {
    const { history } = this.props

    // We need to reset the ErrorBoundary when user switches pages, otherwise the error message will persist.
    history.listen(() => {
      this.setState({ error: null })
    })
  }

  componentDidCatch(error: any) {
    this.setState({ error })
  }

  goToHome = () => {
    const { history } = this.props
    
    this.setState({ error: null })
    history.push(AppPath.Home)
  }

  render() {
    const { error } = this.state

    if (error) {
      return <Container>
        <Navigation color={NavColor.Black}/>
          <Content>
            <CustomLogo/>
            <Text>Something went wrong.</Text>
            <Button
              size={ButtonSize.Wide}
              buttonType={ButtonType.Purple}
              label='Home'
              onClick={this.goToHome}
            />
          </Content>
      </Container>
    }
    return this.props.children
  }
}

const CustomLogo = styled(TaekusLogo)`
  width: auto;
  height: ${styles.Spacing.M};
`

const Text = styled.div`
  margin: ${styles.Spacing.M};
  text-align: center;
`

const Container = styled.div`
  background-image: url("https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/errorBoundary.jpg");
  font-family: ${styles.Font.Family.MonumentGrotesk};
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: ${styles.Spacing.S};
`

export default ErrorBoundary
