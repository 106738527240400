export const airportCache = {
  a: [
    { value: 'ATL', label: 'Atlanta Hartsfield-Jackson', location: 'Atlanta, USA' },
    { value: 'LAX', label: 'Los Angeles', location: 'Los Angeles, USA' },
    { value: 'AMS', label: 'Amsterdam Schiphol', location: 'Amsterdam Netherlands' },
    { value: 'CAN', label: 'Guangzhou Baiyun', location: 'Guangzhou China' },
    { value: 'FRA', label: 'Frankfurt am Main', location: 'Frankfurt am Main Germany' },
    { value: 'MAD', label: 'Adolfo Su\u00e1rez Madrid\u2013Barajas', location: 'Madrid Spain' },
  ],
  b: [
    { value: 'DXB', label: 'Dubai', location: 'Dubai, UAE' },
    { value: 'BKK', label: 'Suvarnabhumi', location: 'Bangkok Thailand' },
    { value: 'BCN', label: 'Barcelona', location: 'Barcelona Spain' },
    { value: 'BOM', label: 'Chhatrapati Shivaji', location: 'Mumbai India' },
    { value: 'BOS', label: 'Boston Logan', location: 'Boston, USA' },
    { value: 'BLR', label: 'Kempegowda', location: 'Bangalore India' },
  ],
  c: [
    { value: 'CDG', label: 'Charles de Gaulle', location: 'Paris France' },
    { value: 'CAN', label: 'Guangzhou Baiyun', location: 'Guangzhou China' },
    { value: 'ICN', label: 'Incheon', location: 'Seoul S. Korea' },
    { value: 'CGK', label: 'Soekarno-Hatta', location: 'Jakarta Indonesia' },
    { value: 'CTU', label: 'Chengdu Shuangliu', location: 'Chengdu China' },
    { value: 'BCN', label: 'Barcelona', location: 'Barcelona Spain' },
  ],
  d: [
    { value: 'DXB', label: 'Dubai', location: 'Dubai, UAE' },
    { value: 'HND', label: 'Tokyo Haneda', location: 'Tokyo Japan' },
    { value: 'ORD', label: "Chicago O'Hare", location: 'Chicago, USA' },
    { value: 'CDG', label: 'Charles de Gaulle', location: 'Paris France' },
    { value: 'DEL', label: 'Indira Gandhi', location: 'New Delhi India' },
    { value: 'DFW', label: 'Dallas Fort Worth', location: 'Dallas-Fort Worth, USA' },
  ],
  e: [
    { value: 'PEK', label: 'Beijing Capital', location: 'Beijing China' },
    { value: 'DEL', label: 'Indira Gandhi', location: 'New Delhi India' },
    { value: 'DEN', label: 'Denver', location: 'Denver, USA' },
    { value: 'SEA', label: 'Seattle Tacoma', location: 'Seattle, USA' },
    { value: 'MEX', label: 'Mexico City-Benito Juarez', location: 'Mexico City Mexico' },
    { value: 'TPE', label: 'Taiwan Taoyuan', location: 'Taipei, Taiwan' },
  ],
  f: [
    { value: 'FRA', label: 'Frankfurt am Main', location: 'Frankfurt am Main Germany' },
    { value: 'DFW', label: 'Dallas Fort Worth', location: 'Dallas-Fort Worth, USA' },
    { value: 'JFK', label: 'John F Kennedy', location: 'New York, USA' },
    { value: 'SFO', label: 'San Francisco', location: 'San Francisco, USA' },
    { value: 'FCO', label: 'Leonardo da Vinci\u2013Fiumicino', location: 'Rome Italy' },
    { value: 'FLL', label: 'Fort Lauderdale Hollywood', location: 'Fort Lauderdale, USA' },
  ],
  g: [
    { value: 'HKG', label: 'Hong Kong', location: 'Hong Kong Hong Kong' },
    { value: 'PVG', label: 'Shanghai Pudong', location: 'Shanghai China' },
    { value: 'CDG', label: 'Charles de Gaulle', location: 'Paris France' },
    { value: 'CGK', label: 'Soekarno-Hatta', location: 'Jakarta Indonesia' },
    { value: 'KMG', label: 'Kunming Changshui', location: 'Kunming China' },
    { value: 'LGW', label: 'London Gatwick', location: 'London United Kingdom' },
  ],
  h: [
    { value: 'HND', label: 'Tokyo Haneda', location: 'Tokyo Japan' },
    { value: 'LHR', label: 'London Heathrow', location: 'London United Kingdom' },
    { value: 'HKG', label: 'Hong Kong', location: 'Hong Kong Hong Kong' },
    { value: 'PHX', label: 'Phoenix Sky Harbor', location: 'Phoenix, USA' },
    { value: 'IAH', label: 'George Bush Houston', location: 'Houston, USA' },
    { value: 'SHA', label: 'Shanghai Hongqiao', location: 'Shanghai China' },
  ],
  i: [
    { value: 'ICN', label: 'Incheon', location: 'Seoul S. Korea' },
    { value: 'IST', label: 'Istanbul', location: 'Istanbul Turkey' },
    { value: 'SIN', label: 'Singapore Changi', location: 'Singapore' },
    { value: 'MIA', label: 'Miami', location: 'Miami, USA' },
    { value: 'XIY', label: "Xi'an Xianyang ", location: "Xi'an China" },
    { value: 'IAH', label: 'George Bush Houston', location: 'Houston, USA' },
  ],
  j: [
    { value: 'JFK', label: 'John F Kennedy', location: 'New York, USA' },
    { value: 'JED', label: 'King Abdulaziz', location: 'Jeddah Saudi Arabia' },
    { value: 'CJU', label: 'Jeju', location: 'Jeju City S. Korea' },
    { value: 'JNB', label: 'O.R. Tambo', location: 'Johannesburg South Africa' },
    { value: 'SJC', label: 'Norman Y. Mineta San Jose', location: 'San Jose, USA' },
    { value: 'SHJ', label: 'Sharjah', location: 'Sharjah, UAE' },
  ],
  k: [
    { value: 'PEK', label: 'Beijing Capital', location: 'Beijing China' },
    { value: 'HKG', label: 'Hong Kong', location: 'Hong Kong Hong Kong' },
    { value: 'CGK', label: 'Soekarno-Hatta', location: 'Jakarta Indonesia' },
    { value: 'BKK', label: 'Suvarnabhumi', location: 'Bangkok Thailand' },
    { value: 'JFK', label: 'John F Kennedy', location: 'New York, USA' },
    { value: 'KUL', label: 'Kuala Lumpur', location: 'Kuala Lumpur Malaysia' },
  ],
  l: [
    { value: 'ATL', label: 'Atlanta Hartsfield-Jackson', location: 'Atlanta, USA' },
    { value: 'LAX', label: 'Los Angeles', location: 'Los Angeles, USA' },
    { value: 'LHR', label: 'London Heathrow', location: 'London United Kingdom' },
    { value: 'DEL', label: 'Indira Gandhi', location: 'New Delhi India' },
    { value: 'KUL', label: 'Kuala Lumpur', location: 'Kuala Lumpur Malaysia' },
    { value: 'LAS', label: 'Las Vegas Harry Reid International', location: 'Las Vegas, USA' },
  ],
  m: [
    { value: 'AMS', label: 'Amsterdam Schiphol', location: 'Amsterdam Netherlands' },
    { value: 'MAD', label: 'Adolfo Su\u00e1rez Madrid\u2013Barajas', location: 'Madrid Spain' },
    { value: 'BOM', label: 'Chhatrapati Shivaji', location: 'Mumbai India' },
    { value: 'MEX', label: 'Mexico City-Benito Juarez', location: 'Mexico City Mexico' },
    { value: 'MCO', label: 'Orlando', location: 'Orlando, USA' },
    { value: 'KMG', label: 'Kunming Changshui', location: 'Kunming China' },
  ],
  n: [
    { value: 'HND', label: 'Tokyo Haneda', location: 'Tokyo Japan' },
    { value: 'CAN', label: 'Guangzhou Baiyun', location: 'Guangzhou China' },
    { value: 'ICN', label: 'Incheon', location: 'Seoul S. Korea' },
    { value: 'SIN', label: 'Singapore Changi', location: 'Singapore Singapore' },
    { value: 'DEN', label: 'Denver', location: 'Denver, USA' },
    { value: 'BCN', label: 'Barcelona', location: 'Barcelona Spain' },
  ],
  o: [
    { value: 'ORD', label: "Chicago O'Hare", location: 'Chicago, USA' },
    { value: 'SFO', label: 'San Francisco', location: 'San Francisco, USA' },
    { value: 'BOM', label: 'Chhatrapati Shivaji', location: 'Mumbai India' },
    { value: 'MCO', label: 'Orlando', location: 'Orlando, USA' },
    { value: 'SVO', label: 'Sheremetyevo', location: 'Moscow, Russia' },
    { value: 'FCO', label: 'Leonardo da Vinci\u2013Fiumicino', location: 'Rome Italy' },
  ],
  p: [
    { value: 'PEK', label: 'Beijing Capital', location: 'Beijing China' },
    { value: 'PVG', label: 'Shanghai Pudong', location: 'Shanghai China' },
    { value: 'TPE', label: 'Taiwan Taoyuan', location: 'Taipei, Taiwan' },
    { value: 'PHX', label: 'Phoenix Sky Harbor', location: 'Phoenix, USA' },
    { value: 'MSP', label: 'Minneapolis-St Paul', location: 'Minneapolis, USA' },
    { value: 'CPH', label: 'Copenhagen Kastrup', location: 'Copenhagen Denmark' },
  ],
  q: [
    { value: 'CGQ', label: 'Longjia', location: 'Changchun China' },
    { value: 'BLQ', label: 'Bologna Guglielmo Marconi', location: 'Bologna Italy' },
    { value: 'PNQ', label: 'Pune', location: 'Pune India' },
    { value: 'SVQ', label: 'Sevilla', location: 'Sevilla Spain' },
    { value: 'ABQ', label: 'Albuquerque  Sunport', location: 'Albuquerque, USA' },
    { value: 'MSQ', label: 'Minsk National', location: 'Minsk Belarus' },
  ],
  r: [
    { value: 'ORD', label: "Chicago O'Hare", location: 'Chicago, USA' },
    { value: 'LHR', label: 'London Heathrow', location: 'London United Kingdom' },
    { value: 'FRA', label: 'Frankfurt am Main', location: 'Frankfurt am Main Germany' },
    { value: 'EWR', label: 'Newark Liberty', location: 'Newark, USA' },
    { value: 'GRU', label: 'S\u00e3o Paulo-Guarulhos', location: 'S\u00e3o Paulo Brazil' },
    { value: 'NRT', label: 'Narita', location: 'Tokyo Japan' },
  ],
  s: [
    { value: 'AMS', label: 'Amsterdam Schiphol', location: 'Amsterdam Netherlands' },
    { value: 'IST', label: 'Istanbul', location: 'Istanbul Turkey' },
    { value: 'SIN', label: 'Singapore Changi', location: 'Singapore Singapore' },
    { value: 'SFO', label: 'San Francisco', location: 'San Francisco, USA' },
    { value: 'SEA', label: 'Seattle Tacoma', location: 'Seattle, USA' },
    { value: 'LAS', label: 'Las Vegas Harry Reid International', location: 'Las Vegas, USA' },
  ],
  t: [
    { value: 'ATL', label: 'Atlanta Hartsfield-Jackson', location: 'Atlanta, USA' },
    { value: 'IST', label: 'Istanbul', location: 'Istanbul Turkey' },
    { value: 'CTU', label: 'Chengdu Shuangliu', location: 'Chengdu China' },
    { value: 'TPE', label: 'Taiwan Taoyuan', location: 'Taipei, Taiwan' },
    { value: 'CLT', label: 'Charlotte Douglas', location: 'Charlotte, USA' },
    { value: 'NRT', label: 'Narita', location: 'Tokyo Japan' },
    { value: 'TFU', label: 'Chengdu Tianfu', location: 'Chengdu China' },
  ],
  u: [
    { value: 'KUL', label: 'Kuala Lumpur', location: 'Kuala Lumpur Malaysia' },
    { value: 'CTU', label: 'Chengdu Shuangliu', location: 'Chengdu China' },
    { value: 'MUC', label: 'Munich', location: 'Munich Germany' },
    { value: 'GRU', label: 'S\u00e3o Paulo-Guarulhos', location: 'S\u00e3o Paulo Brazil' },
    { value: 'DUB', label: 'Dublin', location: 'Dublin Ireland' },
    { value: 'RUH', label: 'King Khaled', location: 'Riyadh Saudi Arabia' },
  ],
  v: [
    { value: 'PVG', label: 'Shanghai Pudong', location: 'Shanghai China' },
    { value: 'SVO', label: 'Sheremetyevo', location: 'Moscow, Russia' },
    { value: 'VIE', label: 'Vienna', location: 'Vienna Austria' },
    { value: 'YVR', label: 'Vancouver', location: 'Vancouver Canada' },
    { value: 'TLV', label: 'Ben Gurion', location: 'Tel Aviv Israel' },
    { value: 'VKO', label: 'Vnukovo', location: 'Moscow, Russia' },
  ],
  w: [
    { value: 'DFW', label: 'Dallas Fort Worth', location: 'Dallas-Fort Worth, USA' },
    { value: 'LGW', label: 'London Gatwick', location: 'London United Kingdom' },
    { value: 'EWR', label: 'Newark Liberty', location: 'Newark, USA' },
    { value: 'DTW', label: 'Detroit', location: 'Detroit, USA' },
    { value: 'SAW', label: 'Sabiha G\u00f6k\u00e7en', location: 'Istanbul Turkey' },
    { value: 'BWI', label: 'Baltimore/Washington  Thurgood Marshall', location: 'Baltimore, USA' },
  ],
  x: [
    { value: 'DXB', label: 'Dubai', location: 'Dubai, UAE' },
    { value: 'LAX', label: 'Los Angeles', location: 'Los Angeles, USA' },
    { value: 'SZX', label: "Shenzhen Bao'an ", location: 'Shenzhen China' },
    { value: 'MEX', label: 'Mexico City-Benito Juarez', location: 'Mexico City Mexico' },
    { value: 'PHX', label: 'Phoenix Sky Harbor', location: 'Phoenix, USA' },
    { value: 'XIY', label: "Xi'an Xianyang ", location: "Xi'an China" },
  ],
  y: [
    { value: 'YYZ', label: 'Lester B. Pearson', location: 'Toronto Canada' },
    { value: 'XIY', label: "Xi'an Xianyang ", location: "Xi'an China" },
    { value: 'SYD', label: 'Sydney Kingsford Smith', location: 'Sydney Australia' },
    { value: 'ORY', label: 'Paris-Orly', location: 'Paris France' },
    { value: 'YVR', label: 'Vancouver', location: 'Vancouver Canada' },
    { value: 'AYT', label: 'Antalya', location: 'Antalya Turkey' },
  ],
  z: [
    { value: 'YYZ', label: 'Lester B. Pearson', location: 'Toronto Canada' },
    { value: 'SZX', label: "Shenzhen Bao'an ", location: 'Shenzhen China' },
    { value: 'ZRH', label: 'Z\u00fcrich', location: 'Zurich Switzerland' },
    { value: 'ZUH', label: 'Zhuhai Jinwan', location: 'Zhuhai China' },
    { value: 'WNZ', label: 'Wenzhou Longwan', location: 'Wenzhou China' },
    { value: 'EZE', label: 'Ministro Pistarini', location: 'Buenos Aires Argentina' },
  ],
  aa: [
    { value: 'MAA', label: 'Chennai', location: 'Chennai India' },
    { value: 'AAL', label: 'Aalborg', location: 'Aalborg Denmark' },
    { value: 'AAQ', label: 'Anapa Vityazevo', location: 'Anapa, Russia' },
    { value: 'AAR', label: 'Aarhus', location: 'Aarhus Denmark' },
    { value: 'VAA', label: 'Vaasa', location: 'Vaasa Finland' },
    { value: 'IAA', label: 'Igarka', location: 'Igarka, Russia' },
  ],
  ab: [
    { value: 'ABQ', label: 'Albuquerque  Sunport', location: 'Albuquerque, USA' },
    { value: 'ABV', label: 'Nnamdi Azikiwe', location: 'Abuja Nigeria' },
    { value: 'ABZ', label: 'Aberdeen Dyce', location: 'Aberdeen United Kingdom' },
    { value: 'ABJ', label: 'Port Bouet', location: "Abidjan Cote d'Ivoire" },
    { value: 'TAB', label: 'Tobago-Crown Point', location: 'Scarborough Trinidad and Tobago' },
    { value: 'DAB', label: 'Daytona Beach', location: 'Daytona Beach, USA' },
  ],
  ac: [
    { value: 'ACE', label: 'Lanzarote', location: 'Lanzarote Island Spain' },
    { value: 'DAC', label: 'Hazrat Shahjalal', location: 'Dhaka Bangladesh' },
    { value: 'ACC', label: 'Kotoka', location: 'Accra Ghana' },
    { value: 'ACY', label: 'Atlantic City', location: 'Atlantic City, USA' },
    { value: 'ACX', label: 'Xingyi', location: 'Xingyi China' },
    { value: 'ACA', label: 'General Juan N Alvarez', location: 'Acapulco Mexico' },
  ],
  ad: [
    { value: 'MAD', label: 'Adolfo Su\u00e1rez Madrid\u2013Barajas', location: 'Madrid Spain' },
    { value: 'IAD', label: 'Washington Dulles', location: 'Washington, USA' },
    { value: 'ADB', label: 'Adnan Menderes', location: '\u0130zmir Turkey' },
    { value: 'DAD', label: 'Da Nang', location: 'Da Nang Viet Nam' },
    { value: 'ADL', label: 'Adelaide', location: 'Adelaide Australia' },
    { value: 'ADA', label: 'Adana', location: 'Adana Turkey' },
  ],
  ae: [
    {
      value: 'AEP',
      label: 'Buenos Aires Aeroparque Jorge Newbery',
      location: 'Buenos Aires Argentina',
    },
    { value: 'AER', label: 'Sochi', location: 'Sochi, Russia' },
    { value: 'TAE', label: 'Daegu', location: 'Daegu S. Korea' },
    { value: 'AES', label: '\u00c5lesund', location: '\u00c5lesund Norway' },
    { value: 'CAE', label: 'Columbia', location: 'Columbia, USA' },
    { value: 'FAE', label: 'Vagar', location: 'Vagar Faroe Islands' },
  ],
  af: [
    { value: 'MAF', label: 'Midland', location: 'Midland, USA' },
    { value: 'SAF', label: 'Santa Fe Municipal', location: 'Santa Fe, USA' },
  ],
  ag: [
    { value: 'AGP', label: 'M\u00e1laga', location: 'Malaga Spain' },
    { value: 'CAG', label: 'Cagliari Elmas', location: 'Cagliari Italy' },
    { value: 'ZAG', label: 'Zagreb', location: 'Zagreb Croatia' },
    { value: 'NAG', label: 'Dr. Babasaheb Ambedkar', location: 'Naqpur India' },
    { value: 'AGA', label: 'Al Massira', location: 'Agadir Morocco' },
    { value: 'TAG', label: 'Tagbilaran', location: 'Tagbilaran City Philippines' },
  ],
  ah: [
    { value: 'IAH', label: 'George Bush Houston', location: 'Houston, USA' },
    { value: 'BAH', label: 'Bahrain', location: 'Manama Bahrain' },
    { value: 'MAH', label: 'Menorca', location: 'Menorca Island Spain' },
    { value: 'AHO', label: 'Alghero-Fertilia', location: 'Alghero Italy' },
    { value: 'ZAH', label: 'Zahedan', location: 'Zahedan, Iran' },
    { value: 'HAH', label: 'Prince Said Ibrahim', location: 'Moroni Comoros' },
  ],
  ai: [
    { value: 'CAI', label: 'Cairo', location: 'Cairo Egypt' },
    { value: 'JAI', label: 'Jaipur', location: 'Jaipur India' },
    { value: 'FAI', label: 'Fairbanks', location: 'Fairbanks, USA' },
    { value: 'RAI', label: 'Praia', location: 'Praia Cape Verde' },
  ],
  aj: [
    { value: 'HAJ', label: 'Hannover', location: 'Hannover Germany' },
    {
      value: 'AJA',
      label: 'Ajaccio-Napol\u00e9on Bonaparte',
      location: 'Ajaccio/Napol\u00e9on Bonaparte France',
    },
    { value: 'AJU', label: 'Santa Maria', location: 'Aracaju Brazil' },
    { value: 'NAJ', label: 'Nakhchivan', location: 'Nakhchivan Azerbaijan' },
    { value: 'RAJ', label: 'Rajkot', location: 'Rajkot India' },
    { value: 'OAJ', label: 'Albert J Ellis', location: 'Jacksonville, USA' },
  ],
  ak: [
    { value: 'HAK', label: 'Haikou Meilan', location: 'Haikou China' },
    { value: 'AKL', label: 'Auckland', location: 'Auckland New Zealand' },
    { value: 'OAK', label: 'Metropolitan Oakland', location: 'Oakland, USA' },
    { value: 'RAK', label: 'Menara', location: 'Marrakech Morocco' },
    { value: 'TAK', label: 'Takamatsu', location: 'Takamatsu Japan' },
    { value: 'AKU', label: 'Aksu', location: 'Aksu China' },
  ],
  al: [
    { value: 'DAL', label: 'Dallas Love Field', location: 'Dallas, USA' },
    { value: 'ALC', label: 'Alicante', location: 'Alicante Spain' },
    { value: 'ALA', label: 'Almaty', location: 'Almaty Kazakhstan' },
    { value: 'ALG', label: 'Houari Boumediene', location: 'Algiers Algeria' },
    {
      value: 'SAL',
      label: '\u00d3scar Arnulfo Romero',
      location: 'San Salvador (San Luis Talpa) El Salvador',
    },
    { value: 'ALB', label: 'Albany', location: 'Albany, USA' },
  ],
  am: [
    { value: 'AMS', label: 'Amsterdam Schiphol', location: 'Amsterdam Netherlands' },
    { value: 'HAM', label: 'Hamburg', location: 'Hamburg Germany' },
    { value: 'AMD', label: 'Sardar Vallabhbhai Patel', location: 'Ahmedabad India' },
    { value: 'AMM', label: 'Queen Alia', location: 'Amman Jordan' },
    { value: 'DAM', label: 'Damascus', location: 'Damascus, Syria' },
    { value: 'AMQ', label: 'Pattimura, Ambon', location: 'Ambon Indonesia' },
  ],
  an: [
    { value: 'CAN', label: 'Guangzhou Baiyun', location: 'Guangzhou China' },
    { value: 'MAN', label: 'Manchester', location: 'Manchester United Kingdom' },
    { value: 'HAN', label: 'Noi Bai', location: 'Hanoi Viet Nam' },
    { value: 'SAN', label: 'San Diego', location: 'San Diego, USA' },
    { value: 'ANC', label: 'Ted Stevens Anchorage', location: 'Anchorage, USA' },
    { value: 'NAN', label: 'Nadi', location: 'Nadi Fiji' },
  ],
  ao: [
    { value: 'TAO', label: 'Liuting', location: 'Qingdao China' },
    { value: 'FAO', label: 'Faro', location: 'Faro Portugal' },
    { value: 'MAO', label: 'Eduardo Gomes', location: 'Manaus Brazil' },
    { value: 'AOJ', label: 'Aomori', location: 'Aomori Japan' },
    { value: 'NAO', label: 'Nanchong', location: 'Nanchong China' },
    { value: 'AOR', label: 'Sultan Abdul Halim', location: 'Alor Satar Malaysia' },
  ],
  ap: [
    { value: 'NAP', label: 'Naples', location: 'N\u00e1poli Italy' },
    { value: 'PAP', label: 'Toussaint Louverture', location: 'Port-au-Prince Haiti' },
    { value: 'LAP', label: 'Manuel M\u00e1rquez de Le\u00f3n', location: 'La Paz Mexico' },
    { value: 'SAP', label: 'Ram\u00f3n Villeda Morales', location: 'San Pedro Sula Honduras' },
    { value: 'RAP', label: 'Rapid City', location: 'Rapid City, USA' },
    { value: 'TAP', label: 'Tapachula', location: 'Tapachula Mexico' },
  ],
  aq: [
    { value: 'BAQ', label: 'Ernesto Cortissoz', location: 'Barranquilla Colombia' },
    { value: 'AQP', label: 'Rodr\u00edguez Ball\u00f3n', location: 'Arequipa Peru' },
    { value: 'AAQ', label: 'Anapa Vityazevo', location: 'Anapa, Russia' },
    { value: 'UAQ', label: 'Domingo Faustino Sarmiento', location: 'San Juan Argentina' },
    { value: 'MAQ', label: 'Mae Sot', location: 'Thailand' },
  ],
  ar: [
    { value: 'ARN', label: 'Stockholm-Arlanda', location: 'Stockholm Sweden' },
    { value: 'VAR', label: 'Varna', location: 'Varna Bulgaria' },
    {
      value: 'DAR',
      label: 'Julius Nyerere',
      location: 'Dar es Salaam Tanzania, United Republic of',
    },
    { value: 'ARH', label: 'Talagi', location: 'Archangelsk, Russia' },
    { value: 'FAR', label: 'Hector', location: 'Fargo, USA' },
    { value: 'ARI', label: 'Chacalluta', location: 'Arica Chile' },
  ],
  as: [
    { value: 'LAS', label: 'Las Vegas Harry Reid International', location: 'Las Vegas, USA' },
    { value: 'NAS', label: 'Lynden Pindling', location: 'Nassau Bahamas' },
    { value: 'TAS', label: 'Tashkent', location: 'Tashkent Uzbekistan' },
    { value: 'ASR', label: 'Kayseri Erkilet', location: 'Kayseri Turkey' },
    { value: 'ASB', label: 'Ashgabat', location: 'Ashgabat Turkmenistan' },
    { value: 'IAS', label: 'Ia\u015fi', location: 'Ia\u015fi Romania' },
  ],
  at: [
    { value: 'ATL', label: 'Atlanta Hartsfield-Jackson', location: 'Atlanta, USA' },
    { value: 'ATH', label: 'Eleftherios Venizelos', location: 'Athens Greece' },
    { value: 'SAT', label: 'San Antonio', location: 'San Antonio, USA' },
    { value: 'PAT', label: 'Lok Nayak Jayaprakash', location: 'Patna India' },
    { value: 'NAT', label: 'Governador Alu\u00edzio Alves', location: 'Natal Brazil' },
    { value: 'ATQ', label: 'Sri Guru Ram Dass Jee', location: 'Amritsar India' },
  ],
  au: [
    { value: 'AUH', label: 'Abu Dhabi', location: 'Abu Dhabi, UAE' },
    { value: 'AUS', label: 'Austin Bergstrom', location: 'Austin, USA' },
    { value: 'GAU', label: 'Lokpriya Gopinath Bordoloi', location: 'Guwahati India' },
    { value: 'AUA', label: 'Queen Beatrix', location: 'Oranjestad Aruba' },
    { value: 'HAU', label: 'Haugesund', location: 'Karm\u00f8y Norway' },
    { value: 'EAU', label: 'Chippewa Valley', location: 'Eau Claire, USA' },
  ],
  av: [
    { value: 'HAV', label: 'Jos\u00e9 Mart\u00ed', location: 'Havana Cuba' },
    { value: 'SAV', label: 'Savannah Hilton Head', location: 'Savannah, USA' },
    { value: 'BAV', label: 'Baotou', location: 'Baotou China' },
    { value: 'AVL', label: 'Asheville Regional', location: 'Asheville, USA' },
    { value: 'AVP', label: 'Wilkes Barre Scranton', location: 'Wilkes-Barre/Scranton, USA' },
    { value: 'DAV', label: 'Enrique Malek', location: 'David Panama' },
  ],
  aw: [
    { value: 'SAW', label: 'Sabiha G\u00f6k\u00e7en', location: 'Istanbul Turkey' },
    { value: 'WAW', label: 'Warsaw Chopin', location: 'Warsaw Poland' },
    { value: 'AWZ', label: 'Ahwaz', location: 'Ahwaz, Iran' },
    { value: 'NAW', label: 'Narathiwat', location: 'Thailand' },
    { value: 'LAW', label: 'Lawton-Fort Sill', location: 'Lawton, USA' },
    { value: 'VAW', label: 'Vard\u00f8, Svartnes', location: 'Vard\u00f8 Norway' },
  ],
  ax: [
    { value: 'LAX', label: 'Los Angeles', location: 'Los Angeles, USA' },
    { value: 'JAX', label: 'Jacksonville', location: 'Jacksonville, USA' },
    { value: 'AXT', label: 'Akita', location: 'Akita Japan' },
    { value: 'OAX', label: 'Xoxocotl\u00e1n', location: 'Oaxaca Mexico' },
    { value: 'BAX', label: 'Barnaul', location: 'Barnaul, Russia' },
    { value: 'AXM', label: 'El Eden', location: 'Armenia Colombia' },
  ],
  ay: [
    { value: 'AYT', label: 'Antalya', location: 'Antalya Turkey' },
    { value: 'NAY', label: 'Beijing Nanyuan', location: 'Beijing China' },
    { value: 'DAY', label: 'James M Cox Dayton', location: 'Dayton, USA' },
    { value: 'FAY', label: 'Fayetteville Grannis Field', location: 'Fayetteville, USA' },
    { value: 'CAY', label: 'Cayenne-Rochambeau', location: 'Cayenne / Rochambeau French Guiana' },
    { value: 'AYQ', label: 'Ayers Rock Connellan', location: 'Ayers Rock Australia' },
  ],
  az: [
    { value: 'AZA', label: 'Phoenix-Mesa-Gateway', location: 'Phoenix, USA' },
    { value: 'AZD', label: 'Shahid Sadooghi', location: 'Yazd, Iran' },
    { value: 'ZAZ', label: 'Zaragoza Air Base', location: 'Zaragoza Spain' },
    { value: 'AZO', label: 'Kalamazoo Battle Creek', location: 'Kalamazoo, USA' },
    { value: 'PAZ', label: 'El Taj\u00edn National', location: 'Poza Rica Mexico' },
    { value: 'AZS', label: 'Saman\u00e1 El Catey', location: 'Samana Dominican Republic' },
  ],
  ba: [
    { value: 'BAH', label: 'Bahrain', location: 'Manama Bahrain' },
    { value: 'LBA', label: 'Leeds Bradford', location: 'Leeds United Kingdom' },
    { value: 'BAQ', label: 'Ernesto Cortissoz', location: 'Barranquilla Colombia' },
    { value: 'HBA', label: 'Hobart', location: 'Hobart Australia' },
    { value: 'BAV', label: 'Baotou', location: 'Baotou China' },
    { value: 'MBA', label: 'Mombasa Moi', location: 'Mombasa Kenya' },
  ],
  bb: [
    { value: 'BBI', label: 'Biju Patnaik', location: 'Bhubaneswar India' },
    { value: 'EBB', label: 'Entebbe', location: 'Kampala Uganda' },
    {
      value: 'CBB',
      label: 'Jorge Wilsterman',
      location: 'Cochabamba, Bolivia',
    },
    { value: 'LBB', label: 'Lubbock Preston Smith', location: 'Lubbock, USA' },
    { value: 'BBA', label: 'Balmaceda', location: 'Balmaceda Chile' },
    { value: 'TBB', label: 'Dong Tac', location: 'Tuy Hoa Viet Nam' },
  ],
  bc: [
    { value: 'BCN', label: 'Barcelona', location: 'Barcelona Spain' },
    { value: 'BCD', label: 'Bacolod-Silay', location: 'Bacolod City Philippines' },
    { value: 'PBC', label: 'Hermanos Serd\u00e1n', location: 'Puebla Mexico' },
    { value: 'NBC', label: 'Begishevo', location: 'Nizhnekamsk, Russia' },
    { value: 'BCM', label: 'Bac\u0103u', location: 'Bac\u0103u Romania' },
  ],
  bd: [
    { value: 'BDL', label: 'Bradley', location: 'Hartford, USA' },
    { value: 'BDO', label: 'Husein Sastranegara', location: 'Bandung-Java Island Indonesia' },
    { value: 'BDJ', label: 'Syamsudin Noor', location: 'Banjarmasin-Borneo Island Indonesia' },
    { value: 'BDS', label: 'Brindisi \u2013 Salento', location: 'Brindisi Italy' },
    { value: 'BDQ', label: 'Vadodara', location: 'Vadodara India' },
    { value: 'BDA', label: 'L.F. Wade', location: 'Hamilton Bermuda' },
  ],
  be: [
    { value: 'BER', label: 'Berlin Brandenburg', location: 'Berlin Germany' },
    { value: 'BEY', label: 'Beirut Rafic Hariri', location: 'Beirut Lebanon' },
    { value: 'BEG', label: 'Belgrade Nikola Tesla', location: 'Belgrade Serbia' },
    { value: 'BEL', label: 'Val de Cans/J\u00falio Cezar Ribeiro', location: 'Bel\u00e9m Brazil' },
    { value: 'HBE', label: 'Borg El Arab', location: 'Alexandria Egypt' },
    { value: 'BES', label: 'Brest Bretagne', location: 'Brest/Guipavas France' },
    { value: 'ABE', label: 'Lehigh Valley', location: 'Allentown, USA' },
  ],
  bf: [
    { value: 'BFS', label: 'Belfast', location: 'Belfast United Kingdom' },
    { value: 'BFJ', label: 'Bijie Feixiong', location: 'Bijie China' },
    { value: 'BFN', label: 'Bram Fischer', location: 'Bloemfontain South Africa' },
    { value: 'BFV', label: 'Buri Ram', location: 'Thailand' },
    { value: 'BFL', label: 'Meadows Field', location: 'Bakersfield, USA' },
    { value: 'BFI', label: 'Boeing Field King County', location: 'Seattle, USA' },
  ],
  bg: [
    { value: 'BGY', label: 'Il Caravaggio', location: 'Bergamo Italy' },
    { value: 'BGW', label: 'Baghdad', location: 'Baghdad Iraq' },
    { value: 'BGO', label: 'Bergen Flesland', location: 'Bergen Norway' },
    { value: 'BGI', label: 'Sir Grantley Adams', location: 'Bridgetown Barbados' },
    { value: 'BGA', label: 'Palonegro', location: 'Bucaramanga Colombia' },
    { value: 'BGR', label: 'Bangor', location: 'Bangor, USA' },
  ],
  bh: [
    { value: 'BHZ', label: 'Belo Horizonte', location: 'Belo Horizonte Brazil', isCity: true },
    { value: 'BHX', label: 'Birmingham', location: 'Birmingham United Kingdom' },
    { value: 'BHM', label: 'Birmingham-Shuttlesworth', location: 'Birmingham, USA' },
    { value: 'BHD', label: 'George Best Belfast City', location: 'Belfast United Kingdom' },
    { value: 'BHY', label: 'Beihai', location: 'Beihai China' },
    { value: 'BHO', label: 'Raja Bhoj', location: 'Bhopal India' },
    { value: 'BHE', label: 'Woodbourne', location: 'Blenheim New Zealand' },
  ],
  bi: [
    { value: 'PBI', label: 'Palm Beach', location: 'West Palm Beach, USA' },
    { value: 'BIO', label: 'Bilbao', location: 'Bilbao Spain' },
    { value: 'BBI', label: 'Biju Patnaik', location: 'Bhubaneswar India' },
    { value: 'BIA', label: 'Bastia-Poretta', location: 'Bastia/Poretta France' },
    { value: 'BIQ', label: 'Biarritz-Anglet-Bayonne', location: 'Biarritz/Anglet/Bayonne France' },
    { value: 'BIL', label: 'Billings Logan', location: 'Billings, USA' },
  ],
  bj: [
    { value: 'BJS', label: 'Beijing', location: 'Beijing China', isCity: true },
    { value: 'MBJ', label: 'Sangster', location: 'Montego Bay Jamaica' },
    { value: 'BJV', label: 'Milas Bodrum', location: 'Bodrum Turkey' },
    { value: 'BJX', label: 'Del Baj\u00edo', location: 'Silao Mexico' },
    { value: 'ABJ', label: 'Port Bouet', location: "Abidjan Cote d'Ivoire" },
    { value: 'UBJ', label: 'Yamaguchi Ube', location: 'Ube Japan' },
    { value: 'LBJ', label: 'Komodo', location: 'Labuan Bajo - Flores Island Indonesia' },
  ],
  bk: [
    { value: 'BKK', label: 'Suvarnabhumi', location: 'Bangkok Thailand' },
    { value: 'BKI', label: 'Kota Kinabalu', location: 'Kota Kinabalu Malaysia' },
    { value: 'BKS', label: 'Fatmawati Soekarno', location: 'Bengkulu-Sumatra Island Indonesia' },
    { value: 'BKO', label: 'Modibo Keita', location: 'Bamako Mali' },
  ],
  bl: [
    { value: 'BLR', label: 'Kempegowda', location: 'Bangalore India' },
    { value: 'BLQ', label: 'Bologna Guglielmo Marconi', location: 'Bologna Italy' },
    { value: 'BLL', label: 'Billund', location: 'Billund Denmark' },
    { value: 'EBL', label: 'Erbil', location: 'Arbil Iraq' },
    { value: 'BLI', label: 'Bellingham', location: 'Bellingham, USA' },
    { value: 'BLD', label: 'Boulder City Municipal', location: 'Boulder City, USA' },
  ],
  bm: [
    { value: 'BMA', label: 'Stockholm-Bromma', location: 'Stockholm Sweden' },
    { value: 'BMV', label: 'Buon Ma Thuot', location: 'Buon Ma Thuot Viet Nam' },
    { value: 'BME', label: 'Broome', location: 'Broome Australia' },
    { value: 'BMU', label: 'Muhammad Salahuddin', location: 'Bima-Sumbawa Island Indonesia' },
    { value: 'BMI', label: 'Bloomington-Normal', location: 'Bloomington/Normal, USA' },
  ],
  bn: [
    { value: 'BNE', label: 'Brisbane', location: 'Brisbane Australia' },
    { value: 'BNA', label: 'Nashville', location: 'Nashville, USA' },
    { value: 'BND', label: 'Bandar Abbas', location: 'Bandar Abbas, Iran' },
    { value: 'SBN', label: 'South Bend', location: 'South Bend, USA' },
    { value: 'BNK', label: 'Ballina Byron Gateway', location: 'Ballina Australia' },
    { value: 'BNI', label: 'Benin', location: 'Benin Nigeria' },
  ],
  bo: [
    { value: 'BOM', label: 'Chhatrapati Shivaji', location: 'Mumbai India' },
    { value: 'BOS', label: 'Boston Logan', location: 'Boston, USA' },
    { value: 'BOG', label: 'El Dorado', location: 'Bogota Colombia' },
    { value: 'NBO', label: 'Jomo Kenyatta', location: 'Nairobi Kenya' },
    { value: 'BOD', label: 'Bordeaux-M\u00e9rignac', location: 'Bordeaux/M\u00e9rignac France' },
    { value: 'BOI', label: 'Boise Gowen Field', location: 'Boise, USA' },
  ],
  bp: [
    { value: 'KBP', label: 'Boryspil', location: 'Kiev Ukraine' },
    {
      value: 'BPN',
      label: 'Sultan Aji Muhamad Sulaiman',
      location: 'Kotamadya Balikpapan Indonesia',
    },
    { value: 'UBP', label: 'Ubon Ratchathani', location: 'Ubon Ratchathani Thailand' },
    { value: 'YBP', label: 'Yibin Caiba', location: 'Yibin China' },
    { value: 'SBP', label: 'San Luis County Regional', location: 'San Luis Obispo, USA' },
    { value: 'TBP', label: 'Pedro Canga Rodriguez', location: 'Tumbes Peru' },
  ],
  bq: [
    { value: 'ABQ', label: 'Albuquerque  Sunport', location: 'Albuquerque, USA' },
    { value: 'BQN', label: 'Rafael Hernandez', location: 'Aguadilla Puerto Rico' },
    { value: 'BQS', label: 'Ignatyevo', location: 'Blagoveschensk, Russia' },
    { value: 'CBQ', label: 'Margaret Ekpo', location: 'Calabar Nigeria' },
    { value: 'DBQ', label: 'Dubuque Regional', location: 'Dubuque, USA' },
    { value: 'BQK', label: 'Brunswick Golden Isles', location: 'Brunswick, USA' },
  ],
  br: [
    { value: 'BRU', label: 'Brussels', location: 'Brussels Belgium' },
    { value: 'BRS', label: 'Bristol', location: 'Bristol United Kingdom' },
    { value: 'BRI', label: 'Bari Karol Wojty\u0142a', location: 'Bari Italy' },
    { value: 'CBR', label: 'Canberra', location: 'Canberra Australia' },
    { value: 'BRE', label: 'Bremen', location: 'Bremen Germany' },
    { value: 'KBR', label: 'Sultan Ismail Petra', location: 'Kota Baharu Malaysia' },
  ],
  bs: [
    {
      value: 'BSB',
      label: 'Bras\u00edlia-Juscelino Kubistschek',
      location: 'Bras\u00edlia Brazil',
    },
    { value: 'BSL', label: 'Basel-Mulhouse-Freiburg', location: 'B\u00e2le/Mulhouse France' },
    { value: 'TBS', label: 'Tbilisi', location: 'Tbilisi Georgia' },
    { value: 'BSD', label: 'Baoshan Yunduan', location: 'China' },
    { value: 'MBS', label: 'MBS', location: 'Saginaw, USA' },
    { value: 'ABS', label: 'Abu Simbel', location: 'Abu Simbel Egypt' },
  ],
  bt: [
    { value: 'BTH', label: 'Hang Nadim', location: 'Batam Island Indonesia' },
    { value: 'BTS', label: 'M. R. \u0160tef\u00e1nik', location: 'Bratislava Slovakia' },
    { value: 'BTV', label: 'Burlington', location: 'Burlington, USA' },
    { value: 'BTJ', label: 'Sultan Iskandar Muda', location: 'Banda Aceh Indonesia' },
    { value: 'BTU', label: 'Bintulu', location: 'Bintulu Malaysia' },
    { value: 'BTR', label: 'Baton Rouge', location: 'Baton Rouge, USA' },
  ],
  bu: [
    { value: 'BUH', label: 'Bucharest', location: 'Bucharest Romania', isCity: true },
    { value: 'BUE', label: 'Buenos Aires', location: 'Buenos Aires Argentina', isCity: true },
    { value: 'BUD', label: 'Budapest Ferenc Liszt', location: 'Budapest Hungary' },
    { value: 'BUR', label: 'Bob Hope', location: 'Burbank, USA' },
    { value: 'BUF', label: 'Buffalo Niagara', location: 'Buffalo, USA' },
    { value: 'BUZ', label: 'Bushehr', location: 'Bushehr, Iran' },
    { value: 'BUS', label: 'Batumi', location: 'Batumi Georgia' },
    { value: 'BUW', label: 'Betoambari', location: 'Bau Bau-Butung Island Indonesia' },
  ],
  bv: [
    { value: 'KBV', label: 'Krabi', location: 'Krabi Thailand' },
    { value: 'BVA', label: 'Paris Beauvais Till\u00e9', location: 'Beauvais/Till\u00e9 France' },
    { value: 'ABV', label: 'Nnamdi Azikiwe', location: 'Abuja Nigeria' },
    { value: 'DBV', label: 'Dubrovnik', location: 'Dubrovnik Croatia' },
    { value: 'LBV', label: "Libreville Leon M'ba ", location: 'Libreville Gabon' },
    { value: 'BVC', label: 'Rabil', location: 'Rabil Cape Verde' },
  ],
  bw: [
    { value: 'BWI', label: 'Baltimore/Washington  Thurgood Marshall', location: 'Baltimore, USA' },
    { value: 'SBW', label: 'Sibu', location: 'Sibu Malaysia' },
    { value: 'BWN', label: 'Brunei', location: 'Bandar Seri Begawan Brunei Darussalam' },
    { value: 'BWK', label: 'Bol', location: 'Bra\u010d Island Croatia' },
  ],
  bx: [
    { value: 'BXU', label: 'Bancasi', location: 'Butuan City Philippines' },
    { value: 'ABX', label: 'Albury', location: 'Albury Australia' },
    { value: 'LBX', label: 'Lubang', location: 'Philippines' },
  ],
  by: [
    { value: 'VBY', label: 'Visby', location: 'Visby Sweden' },
    { value: 'SBY', label: 'Salisbury-Ocean City-Wicomico', location: 'Salisbury, USA' },
    { value: 'ABY', label: 'Southwest Georgia Regional', location: 'Albany, USA' },
  ],
  bz: [
    { value: 'IBZ', label: 'Ibiza', location: 'Ibiza Spain' },
    { value: 'ABZ', label: 'Aberdeen Dyce', location: 'Aberdeen United Kingdom' },
    { value: 'TBZ', label: 'Tabriz', location: 'Tabriz, Iran' },
    { value: 'BZN', label: 'Gallatin Field', location: 'Bozeman, USA' },
    { value: 'BZE', label: 'Philip S. W. Goldson', location: 'Belize City Belize' },
    { value: 'BZV', label: 'Maya-Maya', location: 'Brazzaville Congo' },
  ],
  ca: [
    { value: 'CAN', label: 'Guangzhou Baiyun', location: 'Guangzhou China' },
    { value: 'DCA', label: 'Ronald Reagan National', location: 'Washington, USA' },
    { value: 'CAI', label: 'Cairo', location: 'Cairo Egypt' },
    { value: 'LCA', label: 'Larnaca', location: 'Larnarca Cyprus' },
    { value: 'CAG', label: 'Cagliari Elmas', location: 'Cagliari Italy' },
    { value: 'CAK', label: 'Akron Canton Regional', location: 'Akron, USA' },
  ],
  cb: [
    { value: 'CBR', label: 'Canberra', location: 'Canberra Australia' },
    {
      value: 'CBB',
      label: 'Jorge Wilsterman',
      location: 'Cochabamba, Bolivia',
    },
    { value: 'CBQ', label: 'Margaret Ekpo', location: 'Calabar Nigeria' },
    { value: 'CBH', label: 'Boudghene Ben Ali Lotfi', location: 'B\u00e9char Algeria' },
  ],
  cc: [
    { value: 'CCU', label: 'Netaji Subhash Chandra Bose', location: 'Kolkata India' },
    {
      value: 'CCS',
      label: 'Sim\u00f3n Bol\u00edvar',
      location: 'Caracas Venezuela, Bolivarian Republic of',
    },
    { value: 'CCJ', label: 'Calicut', location: 'Calicut India' },
    { value: 'ACC', label: 'Kotoka', location: 'Accra Ghana' },
    { value: 'CCP', label: 'Carriel Sur', location: 'Concepcion Chile' },
    { value: 'CCF', label: 'Carcassonne', location: 'Carcassonne/Salvaza France' },
  ],
  cd: [
    { value: 'CDG', label: 'Charles de Gaulle', location: 'Paris France' },
    { value: 'BCD', label: 'Bacolod-Silay', location: 'Bacolod City Philippines' },
    { value: 'YCD', label: 'Nanaimo', location: 'Nanaimo Canada' },
    { value: 'CDV', label: 'Merle K (Mudhole) Smith', location: 'Cordova, USA' },
  ],
  ce: [
    { value: 'NCE', label: "Nice-C\u00f4te d'Azur", location: 'Nice France' },
    { value: 'VCE', label: 'Venice Marco Polo', location: 'Venice Italy' },
    { value: 'CEB', label: 'Mactan Cebu', location: 'Lapu-Lapu City Philippines' },
    { value: 'ACE', label: 'Lanzarote', location: 'Lanzarote Island Spain' },
    { value: 'CEI', label: 'Chiang Rai', location: 'Chiang Rai Thailand' },
    { value: 'CEK', label: 'Chelyabinsk Balandino', location: 'Chelyabinsk, Russia' },
  ],
  cf: [
    { value: 'CFU', label: 'Ioannis Kapodistrias', location: 'Kerkyra Island Greece' },
    { value: 'CFS', label: 'Coffs Harbour', location: 'Coffs Harbour Australia' },
    { value: 'CCF', label: 'Carcassonne', location: 'Carcassonne/Salvaza France' },
    {
      value: 'CFE',
      label: 'Clermont-Ferrand Auvergne',
      location: 'Clermont-Ferrand/Auvergne France',
    },
    { value: 'CFR', label: 'Caen-Carpiquet', location: 'Caen/Carpiquet France' },
    { value: 'CFK', label: 'Ech Cheliff', location: 'Algeria' },
  ],
  cg: [
    { value: 'CGK', label: 'Soekarno-Hatta', location: 'Jakarta Indonesia' },
    { value: 'CGO', label: 'Zhengzhou Xinzheng', location: 'Zhengzhou China' },
    { value: 'CGH', label: 'Congonhas', location: 'S\u00e3o Paulo Brazil' },
    { value: 'CGQ', label: 'Longjia', location: 'Changchun China' },
    { value: 'CGN', label: 'Cologne Bonn', location: 'Cologne Germany' },
    { value: 'CGB', label: 'Marechal Rondon', location: 'Cuiab\u00e1 Brazil' },
  ],
  ch: [
    { value: 'CHI', label: 'Chicago', location: 'Chicago, USA', isCity: true },
    { value: 'CHC', label: 'Christchurch', location: 'Christchurch New Zealand' },
    { value: 'KCH', label: 'Kuching', location: 'Kuching Malaysia' },
    { value: 'CHS', label: 'Charleston', location: 'Charleston, USA' },
    { value: 'CHQ', label: 'Chania', location: 'Souda Greece' },
    { value: 'CHA', label: 'Lovell Field', location: 'Chattanooga, USA' },
    { value: 'CHO', label: 'Charlottesville Albemarle', location: 'Charlottesville, USA' },
  ],
  ci: [
    { value: 'MCI', label: 'Kansas City', location: 'Kansas City, USA' },
    { value: 'CIA', label: 'Ciampino\u2013G. B. Pastine', location: 'Rome Italy' },
    { value: 'CIF', label: 'Chifeng', location: 'Chifeng China' },
    { value: 'CID', label: 'The Eastern Iowa Airport', location: 'Cedar Rapids, USA' },
    { value: 'GCI', label: 'Guernsey', location: 'Saint Peter Port Guernsey' },
    { value: 'CIH', label: 'Changzhi', location: 'Changzhi China' },
  ],
  cj: [
    { value: 'CJU', label: 'Jeju', location: 'Jeju City S. Korea' },
    { value: 'CCJ', label: 'Calicut', location: 'Calicut India' },
    { value: 'CJJ', label: 'Cheongju', location: 'Cheongju S. Korea' },
    { value: 'CJB', label: 'Coimbatore', location: 'Coimbatore India' },
    { value: 'CJC', label: 'El Loa', location: 'Calama Chile' },
    { value: 'CJS', label: 'Abraham Gonz\u00e1lez', location: 'Ciudad Ju\u00e1rez Mexico' },
  ],
  ck: [
    { value: 'CKG', label: 'Chongqing Jiangbei', location: 'Chongqing China' },
    { value: 'ACK', label: 'Nantucket Memorial', location: 'Nantucket, USA' },
    { value: 'CKY', label: 'Conakry', location: 'Conakry Guinea' },
    { value: 'CKZ', label: '\u00c7anakkale', location: '\u00c7anakkale Turkey' },
    { value: 'SCK', label: 'Stockton', location: 'Stockton, USA' },
    { value: 'GCK', label: 'Garden City Regional', location: 'Garden City, USA' },
  ],
  cl: [
    { value: 'CLT', label: 'Charlotte Douglas', location: 'Charlotte, USA' },
    { value: 'SCL', label: 'Arturo Merino Ben\u00edtez', location: 'Santiago Chile' },
    { value: 'CLE', label: 'Cleveland Hopkins', location: 'Cleveland, USA' },
    { value: 'NCL', label: 'Newcastle', location: 'Newcastle United Kingdom' },
    { value: 'CLO', label: 'Alfonso Bonilla Aragon', location: 'Cali Colombia' },
    { value: 'CLJ', label: 'Cluj-Napoca', location: 'Cluj-Napoca Romania' },
  ],
  cm: [
    { value: 'CMB', label: 'Bandaranaike Colombo', location: 'Colombo Sri Lanka' },
    { value: 'CMN', label: 'Mohammed V', location: 'Casablanca Morocco' },
    { value: 'CMH', label: 'John Glenn Columbus', location: 'Columbus, USA' },
    { value: 'GCM', label: 'Owen Roberts', location: 'Georgetown Cayman Islands' },
    { value: 'BCM', label: 'Bac\u0103u', location: 'Bac\u0103u Romania' },
    { value: 'CME', label: 'Ciudad del Carmen', location: 'Ciudad del Carmen Mexico' },
  ],
  cn: [
    { value: 'ICN', label: 'Incheon', location: 'Seoul S. Korea' },
    { value: 'BCN', label: 'Barcelona', location: 'Barcelona Spain' },
    { value: 'CNX', label: 'Chiang Mai', location: 'Chiang Mai Thailand' },
    { value: 'CNF', label: 'Tancredo Neves', location: 'Belo Horizonte Brazil' },
    { value: 'CNS', label: 'Cairns', location: 'Cairns Australia' },
    { value: 'GCN', label: 'Grand Canyon National Park', location: 'Grand Canyon, USA' },
  ],
  co: [
    { value: 'MCO', label: 'Orlando', location: 'Orlando, USA' },
    { value: 'FCO', label: 'Leonardo da Vinci\u2013Fiumicino', location: 'Rome Italy' },
    { value: 'COK', label: 'Cochin', location: 'Kochi India' },
    { value: 'COR', label: 'Ingeniero Ambrosio Taravella', location: 'Cordoba Argentina' },
    { value: 'COS', label: 'Colorado Springs', location: 'Colorado Springs, USA' },
    { value: 'SCO', label: 'Aktau', location: 'Aktau Kazakhstan' },
  ],
  cp: [
    { value: 'CPH', label: 'Copenhagen Kastrup', location: 'Copenhagen Denmark' },
    { value: 'CPT', label: 'Cape Town', location: 'Cape Town South Africa' },
    { value: 'VCP', label: 'Viracopos', location: 'Campinas Brazil' },
    { value: 'CCP', label: 'Carriel Sur', location: 'Concepcion Chile' },
    { value: 'ECP', label: 'Northwest Florida Beaches', location: 'Panama City Beach, USA' },
    { value: 'CPO', label: 'Desierto de Atacama', location: 'Copiapo Chile' },
  ],
  cq: [
    { value: 'SCQ', label: 'Santiago de Compostela', location: 'Santiago de Compostela Spain' },
    { value: 'TCQ', label: 'Carlos Ciriani Santa Rosa', location: 'Tacna Peru' },
  ],
  cr: [
    { value: 'CRL', label: 'Brussels South Charleroi', location: 'Brussels Belgium' },
    { value: 'CRK', label: 'Diosdado Macapagal', location: 'Angeles/Mabalacat Philippines' },
    { value: 'CRP', label: 'Corpus Christi', location: 'Corpus Christi, USA' },
    { value: 'CRD', label: 'General E. Mosconi', location: 'Comodoro Rivadavia Argentina' },
    { value: 'CRW', label: 'Yeager', location: 'Charleston, USA' },
    { value: 'CRA', label: 'Craiova', location: 'Craiova Romania' },
  ],
  cs: [
    { value: 'CSX', label: 'Changsha Huanghua', location: 'Changsha China' },
    {
      value: 'CCS',
      label: 'Sim\u00f3n Bol\u00edvar',
      location: 'Caracas Venezuela, Bolivarian Republic of',
    },
    { value: 'CSY', label: 'Cheboksary', location: 'Cheboksary, Russia' },
    { value: 'VCS', label: 'Co Ong', location: 'Con Dao Viet Nam' },
    { value: 'SCS', label: 'Scatsta', location: 'Shetland Islands United Kingdom' },
    { value: 'CSG', label: 'Columbus Metropolitan', location: 'Columbus, USA' },
  ],
  ct: [
    { value: 'CTU', label: 'Chengdu Shuangliu', location: 'Chengdu China' },
    { value: 'CTS', label: 'New Chitose', location: 'Chitose / Tomakomai Japan' },
    { value: 'MCT', label: 'Muscat', location: 'Muscat Oman' },
    { value: 'CTA', label: 'Catania-Fontanarossa', location: 'Catania Italy' },
    { value: 'CTG', label: 'Rafael Nu\u00f1ez', location: 'Cartagena Colombia' },
    { value: 'ICT', label: 'Wichita Eisenhower National', location: 'Wichita, USA' },
  ],
  cu: [
    { value: 'CUN', label: 'Canc\u00fan', location: 'Canc\u00fan Mexico' },
    { value: 'CCU', label: 'Netaji Subhash Chandra Bose', location: 'Kolkata India' },
    { value: 'CUZ', label: 'Alejandro Velasco Astete', location: 'Cusco Peru' },
    { value: 'CUL', label: 'Bachigualato Federal', location: 'Culiac\u00e1n Mexico' },
    { value: 'YCU', label: 'Yuncheng Guangong', location: 'Yuncheng China' },
    { value: 'CUR', label: 'Hato', location: 'Willemstad Cura\u00e7ao' },
  ],
  cv: [
    { value: 'CVG', label: 'Cincinnati Northern Kentucky', location: 'Cincinnati, USA' },
    { value: 'SCV', label: 'Suceava Stefan cel Mare', location: 'Suceava Romania' },
    { value: 'ACV', label: 'Humboldt County', location: 'Arcata/Eureka, USA' },
    { value: 'CVM', label: 'Ciudad Victoria', location: 'Ciudad Victoria Mexico' },
  ],
  cw: [
    { value: 'CWB', label: 'Afonso Pena', location: 'Curitiba Brazil' },
    { value: 'CWL', label: 'Cardiff', location: 'Cardiff United Kingdom' },
    { value: 'SCW', label: 'Syktyvkar', location: 'Syktyvkar, Russia' },
    { value: 'CWA', label: 'Central Wisconsin', location: 'Mosinee, USA' },
    { value: 'CWC', label: 'Chernivtsi', location: 'Chernivtsi Ukraine' },
  ],
  cx: [
    { value: 'CXR', label: 'Cam Ranh', location: 'Nha Trang Viet Nam' },
    { value: 'MCX', label: 'Uytash', location: 'Makhachkala, Russia' },
    { value: 'ACX', label: 'Xingyi', location: 'Xingyi China' },
  ],
  cy: [
    { value: 'LCY', label: 'London City', location: 'London United Kingdom' },
    { value: 'ACY', label: 'Atlantic City', location: 'Atlantic City, USA' },
    { value: 'MCY', label: 'Sunshine Coast', location: 'Maroochydore Australia' },
    { value: 'CYZ', label: 'Cauayan', location: 'Cauayan City Philippines' },
    { value: 'CYP', label: 'Calbayog', location: 'Calbayog City Philippines' },
    { value: 'CYS', label: 'Cheyenne Regional', location: 'Cheyenne, USA' },
  ],
  cz: [
    { value: 'CZX', label: 'Changzhou Benniu', location: 'Changzhou China' },
    { value: 'MCZ', label: 'Zumbi dos Palmares', location: 'Macei\u00f3 Brazil' },
    { value: 'KCZ', label: 'K\u014dchi Ry\u014dma', location: 'Nankoku Japan' },
    { value: 'TCZ', label: 'Tengchong Tuofeng', location: 'Tengchong China' },
    { value: 'CZM', label: 'Cozumel', location: 'Cozumel Mexico' },
    { value: 'CZS', label: 'Cruzeiro do Sul', location: 'Cruzeiro Do Sul Brazil' },
  ],
  da: [
    { value: 'DAL', label: 'Dallas Love Field', location: 'Dallas, USA' },
    { value: 'DAD', label: 'Da Nang', location: 'Da Nang Viet Nam' },
    { value: 'DAC', label: 'Hazrat Shahjalal', location: 'Dhaka Bangladesh' },
    { value: 'ADA', label: 'Adana', location: 'Adana Turkey' },
    { value: 'DAM', label: 'Damascus', location: 'Damascus, Syria' },
    {
      value: 'DAR',
      label: 'Julius Nyerere',
      location: 'Dar es Salaam Tanzania, United Republic of',
    },
  ],
  db: [
    { value: 'ADB', label: 'Adnan Menderes', location: '\u0130zmir Turkey' },
    { value: 'DBV', label: 'Dubrovnik', location: 'Dubrovnik Croatia' },
    { value: 'LDB', label: 'Governador Jos\u00e9 Richa', location: 'Londrina Brazil' },
    { value: 'DBO', label: 'Dubbo City Regional', location: 'Dubbo Australia' },
    { value: 'BDB', label: 'Bundaberg', location: 'Bundaberg Australia' },
    { value: 'DBQ', label: 'Dubuque Regional', location: 'Dubuque, USA' },
  ],
  dc: [
    { value: 'DCA', label: 'Ronald Reagan National', location: 'Washington, USA' },
    { value: 'MDC', label: 'Sam Ratulangi', location: 'Manado-Celebes Island Indonesia' },
    { value: 'DCM', label: 'Castres-Mazamet', location: 'Castres/Mazamet France' },
  ],
  dd: [
    { value: 'ADD', label: 'Addis Ababa Bole', location: 'Addis Ababa Ethiopia' },
    { value: 'RDD', label: 'Redding Municipal', location: 'Redding, USA' },
  ],
  de: [
    { value: 'DEL', label: 'Indira Gandhi', location: 'New Delhi India' },
    { value: 'DEN', label: 'Denver', location: 'Denver, USA' },
    { value: 'MDE', label: 'Jose Maria C\u00f3rdova', location: 'Medell\u00edn Colombia' },
    { value: 'DED', label: 'Dehradun', location: 'Dehradun India' },
    {
      value: 'LDE',
      label: 'Tarbes-Lourdes-Pyr\u00e9n\u00e9es',
      location: 'Tarbes/Lourdes/Pyr\u00e9n\u00e9es France',
    },
    { value: 'DEB', label: 'Debrecen', location: 'Debrecen Hungary' },
  ],
  df: [
    { value: 'DFW', label: 'Dallas Fort Worth', location: 'Dallas-Fort Worth, USA' },
    { value: 'SDF', label: 'Louisville Standiford Field', location: 'Louisville, USA' },
    {
      value: 'FDF',
      label: 'Martinique Aim\u00e9 C\u00e9saire',
      location: 'Fort-de-France Martinique',
    },
    { value: 'YDF', label: 'Deer Lake', location: 'Deer Lake Canada' },
    { value: 'ADF', label: 'Ad\u0131yaman', location: 'Ad\u0131yaman Turkey' },
  ],
  dg: [
    { value: 'CDG', label: 'Charles de Gaulle', location: 'Paris France' },
    { value: 'PDG', label: 'Minangkabau', location: 'Ketaping/Padang - Sumatra Island Indonesia' },
    { value: 'MDG', label: 'Mudanjiang Hailang', location: 'Mudanjiang China' },
    { value: 'HDG', label: 'Handan', location: 'Handan China' },
    { value: 'DGO', label: 'General Guadalupe Victoria', location: 'Durango Mexico' },
    { value: 'TDG', label: 'Tandag', location: 'Philippines' },
  ],
  dh: [
    { value: 'WDH', label: 'Hosea Kutako', location: 'Windhoek Namibia' },
    { value: 'FDH', label: 'Friedrichshafen', location: 'Friedrichshafen Germany' },
    { value: 'JDH', label: 'Jodhpur', location: 'Jodhpur India' },
    { value: 'VDH', label: 'Dong Hoi', location: 'Dong Hoi Viet Nam' },
    { value: 'DHM', label: 'Kangra', location: 'India' },
    { value: 'DHN', label: 'Dothan Regional', location: 'Dothan, USA' },
  ],
  di: [
    { value: 'EDI', label: 'Edinburgh', location: 'Edinburgh United Kingdom' },
    { value: 'DIY', label: 'Diyarbakir', location: 'Diyarbakir Turkey' },
    { value: 'KDI', label: 'Wolter Monginsidi', location: 'Kendari-Celebes Island Indonesia' },
    { value: 'UDI', label: 'Aviador C\u00e9sar Bombonato', location: 'Uberl\u00e2ndia Brazil' },
    { value: 'DIB', label: 'Dibrugarh', location: 'Dibrugarh India' },
    { value: 'DIN', label: 'Dien Bien Phu', location: 'Dien Bien Phu Viet Nam' },
  ],
  dj: [
    { value: 'BDJ', label: 'Syamsudin Noor', location: 'Banjarmasin-Borneo Island Indonesia' },
    { value: 'SDJ', label: 'Sendai', location: 'Sendai Japan' },
    { value: 'DJJ', label: 'Sentani', location: 'Jayapura-Papua Island Indonesia' },
    { value: 'DJB', label: 'Sultan Thaha', location: 'Jambi-Sumatra Island Indonesia' },
    { value: 'DJE', label: 'Djerba Zarzis', location: 'Djerba Tunisia' },
  ],
  dk: [
    { value: 'DKR', label: 'L\u00e9opold S\u00e9dar Senghor', location: 'Dakar Senegal' },
    { value: 'SDK', label: 'Sandakan', location: 'Sandakan Malaysia' },
  ],
  dl: [
    { value: 'DLC', label: 'Zhoushuizi', location: 'Dalian China' },
    { value: 'GDL', label: 'Don Miguel Hidalgo Y Costilla', location: 'Guadalajara Mexico' },
    { value: 'ADL', label: 'Adelaide', location: 'Adelaide Australia' },
    { value: 'BDL', label: 'Bradley', location: 'Hartford, USA' },
    { value: 'DLM', label: 'Dalaman', location: 'Dalaman Turkey' },
    { value: 'PDL', label: 'Jo\u00e3o Paulo II', location: 'Ponta Delgada Portugal' },
  ],
  dm: [
    { value: 'DMK', label: 'Don Mueang', location: 'Bangkok Thailand' },
    { value: 'DME', label: 'Domodedovo', location: 'Moscow, Russia' },
    { value: 'DMM', label: 'King Fahd', location: 'Ad Dammam Saudi Arabia' },
    { value: 'RDM', label: 'Roberts Field', location: 'Redmond, USA' },
    { value: 'DMU', label: 'Dimapur', location: 'Dimapur India' },
  ],
  dn: [
    { value: 'GDN', label: 'Gda\u0144sk Lech Wa\u0142\u0119sa', location: 'Gda\u0144sk Poland' },
    { value: 'DNZ', label: '\u00c7ardak', location: 'Denizli Turkey' },
    { value: 'DNK', label: 'Dnipropetrovsk', location: 'Dnipropetrovsk Ukraine' },
    {
      value: 'DNR',
      label: 'Dinard-Pleurtuit-Saint-Malo',
      location: 'Dinard/Pleurtuit/Saint-Malo France',
    },
    { value: 'HDN', label: 'Yampa Valley', location: 'Hayden, USA' },
    { value: 'SDN', label: 'Sandane (Anda)', location: 'Sandane Norway' },
  ],
  do: [
    { value: 'DOH', label: 'Hamad International Airport', location: 'Doha Qatar' },
    { value: 'BDO', label: 'Husein Sastranegara', location: 'Bandung-Java Island Indonesia' },
    { value: 'JDO', label: 'Orlando Bezerra de Menezes', location: 'Juazeiro Do Norte Brazil' },
    { value: 'EDO', label: 'Bal\u0131kesir K\u00f6rfez', location: 'Edremit Turkey' },
    { value: 'DOL', label: 'Deauville-Saint-Gatien', location: 'Deauville France' },
  ],
  dp: [
    { value: 'DPS', label: 'Ngurah Rai (Bali)', location: 'Denpasar-Bali Island Indonesia' },
    { value: 'DPL', label: 'Dipolog', location: 'Dipolog City Philippines' },
    { value: 'PDP', label: 'Capitan Corbeta CA Curbelo', location: 'Punta del Este Uruguay' },
    { value: 'DPO', label: 'Devonport', location: 'Devonport Australia' },
  ],
  dq: [
    { value: 'SDQ', label: 'Las Am\u00e9ricas', location: 'Santo Domingo Dominican Republic' },
    { value: 'BDQ', label: 'Vadodara', location: 'Vadodara India' },
    { value: 'MDQ', label: '\u00c1stor Piazzola', location: 'Mar del Plata Argentina' },
    { value: 'ADQ', label: 'Kodiak', location: 'Kodiak, USA' },
  ],
  dr: [
    { value: 'IDR', label: 'Devi Ahilyabai Holkar', location: 'Indore India' },
    { value: 'DRW', label: 'Darwin', location: 'Darwin Australia' },
    { value: 'DRS', label: 'Dresden', location: 'Dresden Germany' },
    { value: 'UDR', label: 'Maharana Pratap', location: 'Udaipur India' },
    { value: 'SDR', label: 'Santander', location: 'Santander Spain' },
    { value: 'NDR', label: 'Nador', location: 'Nador Morocco' },
  ],
  ds: [
    { value: 'DSM', label: 'Des Moines', location: 'Des Moines, USA' },
    { value: 'DSN', label: 'Ordos Ejin Horo', location: 'Ordos China' },
    { value: 'BDS', label: 'Brindisi \u2013 Salento', location: 'Brindisi Italy' },
    { value: 'ODS', label: 'Odessa', location: 'Odessa Ukraine' },
    { value: 'DSA', label: 'Robin Hood Doncaster/Sheffield', location: 'Doncaster United Kingdom' },
    { value: 'WDS', label: 'Shiyan Wudangshan', location: 'Shiyan China' },
  ],
  dt: [
    { value: 'DTW', label: 'Detroit', location: 'Detroit, USA' },
    { value: 'DTM', label: 'Dortmund', location: 'Dortmund Germany' },
    { value: 'MDT', label: 'Harrisburg', location: 'Harrisburg, USA' },
  ],
  du: [
    { value: 'DUB', label: 'Dublin', location: 'Dublin Ireland' },
    { value: 'DUS', label: 'D\u00fcsseldorf', location: 'D\u00fcsseldorf Germany' },
    { value: 'RDU', label: 'Raleigh Durham', location: 'Raleigh/Durham, USA' },
    { value: 'SDU', label: 'Santos Dumont', location: 'Rio De Janeiro Brazil' },
    { value: 'DUR', label: 'King Shaka', location: 'Durban South Africa' },
    { value: 'DUD', label: 'Dunedin', location: 'Dunedin New Zealand' },
  ],
  dv: [
    { value: 'DVO', label: 'Francisco Bangoy', location: 'Davao City Philippines' },
    { value: 'PDV', label: 'Plovdiv', location: 'Plovdiv Bulgaria' },
    { value: 'CDV', label: 'Merle K (Mudhole) Smith', location: 'Cordova, USA' },
  ],
  dw: [{ value: 'MDW', label: 'Chicago Midway', location: 'Chicago, USA' }],
  dx: [
    { value: 'DXB', label: 'Dubai', location: 'Dubai, UAE' },
    { value: 'PDX', label: 'Portland', location: 'Portland, USA' },
    { value: 'GDX', label: 'Sokol', location: 'Magadan, Russia' },
    { value: 'TDX', label: 'Trat', location: 'Thailand' },
  ],
  dy: [
    { value: 'HDY', label: 'Hat Yai', location: 'Hat Yai Thailand' },
    { value: 'DYG', label: 'Dayong', location: 'Dayong China' },
    { value: 'DYU', label: 'Dushanbe', location: 'Dushanbe Tajikistan' },
    { value: 'LDY', label: 'City of Derry', location: 'Derry United Kingdom' },
  ],
  dz: [
    { value: 'ADZ', label: 'Gustavo Rojas Pinilla', location: 'San Andr\u00e9s Colombia' },
    { value: 'MDZ', label: 'El Plumerillo', location: 'Mendoza Argentina' },
    { value: 'DZA', label: 'Dzaoudzi Pamandzi', location: 'Dzaoudzi Mayotte' },
    { value: 'GDZ', label: 'Gelendzhik', location: 'Gelendzhik, Russia' },
    { value: 'RDZ', label: 'Rodez-Marcillac', location: 'Rodez/Marcillac France' },
    { value: 'VDZ', label: 'Valdez Pioneer Field', location: 'Valdez, USA' },
  ],
  ea: [
    { value: 'SEA', label: 'Seattle Tacoma', location: 'Seattle, USA' },
    { value: 'GEA', label: 'Noum\u00e9a Magenta', location: 'Noum\u00e9a New Caledonia' },
    { value: 'MEA', label: 'Maca\u00e9', location: 'Maca\u00e9 Brazil' },
    { value: 'EAS', label: 'San Sebastian', location: 'Hondarribia Spain' },
    { value: 'EAT', label: 'Pangborn Memorial', location: 'Wenatchee, USA' },
    { value: 'EAU', label: 'Chippewa Valley', location: 'Eau Claire, USA' },
  ],
  eb: [
    { value: 'CEB', label: 'Mactan Cebu', location: 'Lapu-Lapu City Philippines' },
    { value: 'EBL', label: 'Erbil', location: 'Arbil Iraq' },
    { value: 'EBB', label: 'Entebbe', location: 'Kampala Uganda' },
    { value: 'DEB', label: 'Debrecen', location: 'Debrecen Hungary' },
    {
      value: 'EBU',
      label: 'Saint-\u00c9tienne-Bouth\u00e9on',
      location: 'Saint-\u00c9tienne/Bouth\u00e9on France',
    },
    { value: 'EBJ', label: 'Esbjerg', location: 'Esbjerg Denmark' },
  ],
  ec: [
    { value: 'REC', label: 'Recife/Guararapes-Gilberto Freyre', location: 'Recife Brazil' },
    { value: 'MEC', label: 'Eloy Alfaro', location: 'Manta Ecuador' },
    { value: 'ECP', label: 'Northwest Florida Beaches', location: 'Panama City Beach, USA' },
    { value: 'CEC', label: 'Jack Mc Namara Field', location: 'Crescent City, USA' },
  ],
  ed: [
    { value: 'JED', label: 'Jeddah King Abdulaziz', location: 'Jeddah Saudi Arabia' },
    { value: 'LED', label: 'Pulkovo', location: 'St. Petersburg, Russia' },
    { value: 'EDI', label: 'Edinburgh', location: 'Edinburgh United Kingdom' },
    { value: 'DED', label: 'Dehradun', location: 'Dehradun India' },
    { value: 'EDO', label: 'Bal\u0131kesir K\u00f6rfez', location: 'Edremit Turkey' },
    { value: 'EDL', label: 'Eldoret', location: 'Eldoret Kenya' },
  ],
  ee: [
    { value: 'PEE', label: 'Bolshoye Savino', location: 'Perm, Russia' },
    { value: 'CEE', label: 'Cherepovets', location: 'Cherepovets, Russia' },
    { value: 'MEE', label: 'Mar\u00e9', location: 'Mar\u00e9 New Caledonia' },
  ],
  ef: [
    { value: 'KEF', label: 'Keflavik', location: 'Reykjav\u00edk Iceland' },
    { value: 'EFL', label: 'Kefallinia', location: 'Kefallinia Island Greece' },
  ],
  eg: [
    { value: 'YEG', label: 'Edmonton', location: 'Edmonton Canada' },
    { value: 'BEG', label: 'Belgrade Nikola Tesla', location: 'Belgrade Serbia' },
    { value: 'GEG', label: 'Spokane', location: 'Spokane, USA' },
    { value: 'EGO', label: 'Belgorod', location: 'Belgorod, Russia' },
    { value: 'REG', label: 'Reggio Calabria', location: 'Reggio Calabria Italy' },
    { value: 'EGE', label: 'Eagle County Regional', location: 'Eagle, USA' },
  ],
  eh: [
    { value: 'WEH', label: 'Weihai', location: 'Weihai China' },
    { value: 'MEH', label: 'Mehamn', location: 'Mehamn Norway' },
  ],
  ei: [
    { value: 'EIN', label: 'Eindhoven', location: 'Eindhoven Netherlands' },
    { value: 'CEI', label: 'Chiang Rai', location: 'Chiang Rai Thailand' },
    { value: 'PEI', label: 'Mateca\u00f1a', location: 'Pereira Colombia' },
    { value: 'LEI', label: 'Almer\u00eda', location: 'Almer\u00eda Spain' },
    { value: 'EIS', label: 'Terrance B. Lettsome', location: 'Road Town Virgin Islands, British' },
    { value: 'YEI', label: 'Bursa Yeni\u015fehir', location: 'Bursa Turkey' },
  ],
  ej: [
    { value: 'LEJ', label: 'Leipzig/Halle', location: 'Leipzig Germany' },
    { value: 'KEJ', label: 'Kemerovo', location: 'Kemerovo, Russia' },
    { value: 'EJA', label: 'Yarigu\u00edes', location: 'Barrancabermeja Colombia' },
  ],
  ek: [
    { value: 'PEK', label: 'Beijing Capital', location: 'Beijing China' },
    { value: 'CEK', label: 'Chelyabinsk Balandino', location: 'Chelyabinsk, Russia' },
    { value: 'EKO', label: 'Elko Regional', location: 'Elko, USA' },
  ],
  el: [
    { value: 'DEL', label: 'Indira Gandhi', location: 'New Delhi India' },
    { value: 'MEL', label: 'Melbourne', location: 'Melbourne Australia' },
    { value: 'HEL', label: 'Helsinki Vantaa', location: 'Helsinki Finland' },
    { value: 'BEL', label: 'Val de Cans/J\u00falio Cezar Ribeiro', location: 'Bel\u00e9m Brazil' },
    { value: 'ELP', label: 'El Paso', location: 'El Paso, USA' },
    { value: 'ELS', label: 'Ben Schoeman', location: 'East London South Africa' },
  ],
  em: [
    { value: 'EMA', label: 'East Midlands', location: 'Nottingham United Kingdom' },
    { value: 'MEM', label: 'Memphis', location: 'Memphis, USA' },
    { value: 'PEM', label: 'Padre Aldamiz', location: 'Puerto Maldonado Peru' },
    { value: 'EMD', label: 'Emerald', location: 'Emerald Australia' },
    { value: 'KEM', label: 'Kemi-Tornio', location: 'Kemi / Tornio Finland' },
  ],
  en: [
    { value: 'DEN', label: 'Denver', location: 'Denver, USA' },
    { value: 'PEN', label: 'Penang', location: 'Penang Malaysia' },
    { value: 'SEN', label: 'Southend', location: 'Southend United Kingdom' },
    { value: 'TEN', label: 'Tongren Fenghuang', location: 'China' },
    { value: 'ENH', label: 'Enshi', location: 'Enshi China' },
    { value: 'REN', label: 'Orenburg Central', location: 'Orenburg, Russia' },
  ],
  eo: [
    { value: 'EOH', label: 'Enrique Olaya Herrera', location: 'Medell\u00edn Colombia' },
    { value: 'GEO', label: 'Cheddi Jagan', location: 'Georgetown Guyana' },
    { value: 'UEO', label: 'Kumejima', location: 'Japan' },
  ],
  ep: [
    {
      value: 'AEP',
      label: 'Buenos Aires Aeroparque Jorge Newbery',
      location: 'Buenos Aires Argentina',
    },
    { value: 'REP', label: 'Siem Reap', location: 'Siem Reap Cambodia' },
  ],
  eq: [
    { value: 'TEQ', label: 'Tekirda\u011f \u00c7orlu', location: '\u00c7orlu Turkey' },
    { value: 'LEQ', label: "Land's End", location: "Land's End United Kingdom" },
  ],
  er: [
    { value: 'BER', label: 'Berlin Brandenburg', location: 'Berlin Germany' },
    { value: 'PER', label: 'Perth', location: 'Perth Australia' },
    { value: 'HER', label: 'Heraklion Nikos Kazantzakis', location: 'Heraklion Greece' },
    { value: 'AER', label: 'Sochi', location: 'Sochi, Russia' },
    { value: 'JER', label: 'Jersey', location: 'Saint Helier Jersey' },
    { value: 'VER', label: 'General Heriberto Jara', location: 'Veracruz Mexico' },
    { value: 'ERZ', label: 'Erzurum', location: 'Erzurum Turkey' },
  ],
  es: [
    { value: 'ESB', label: 'Esenbo\u011fa', location: 'Ankara Turkey' },
    { value: 'AES', label: '\u00c5lesund', location: '\u00c5lesund Norway' },
    { value: 'BES', label: 'Brest Bretagne', location: 'Brest/Guipavas France' },
    { value: 'GES', label: 'General Santos', location: 'General Santos Philippines' },
    { value: 'RES', label: 'Resistencia', location: 'Resistencia Argentina' },
    { value: 'ESU', label: 'Mogador', location: 'Essaouira Morocco' },
  ],
  et: [
    { value: 'HET', label: 'Baita', location: 'Hohhot China' },
    { value: 'UET', label: 'Quetta', location: 'Quetta Pakistan' },
    { value: 'BET', label: 'Bethel', location: 'Bethel, USA' },
    { value: 'ETZ', label: 'Metz-Nancy-Lorraine', location: 'Metz / Nancy France' },
    { value: 'LET', label: 'Alfredo V\u00e1squez Cobo', location: 'Leticia Colombia' },
    { value: 'GET', label: 'Geraldton', location: 'Geraldton Australia' },
  ],
  eu: [
    { value: 'REU', label: 'Reus Air Base', location: 'Reus Spain' },
    { value: 'EUG', label: 'Mahlon Sweet Field', location: 'Eugene, USA' },
    { value: 'EUN', label: 'Hassan I', location: 'El Aai\u00fan Morocco' },
  ],
  ev: [
    { value: 'IEV', label: 'Kiev Zhuliany', location: 'Kiev Ukraine' },
    { value: 'EVN', label: 'Zvartnots', location: 'Yerevan Armenia' },
    { value: 'EVE', label: 'Harstad/Narvik, Evenes', location: 'Evenes Norway' },
    { value: 'EVV', label: 'Evansville Regional', location: 'Evansville, USA' },
  ],
  ew: [
    { value: 'EWR', label: 'Newark Liberty', location: 'Newark, USA' },
    { value: 'PEW', label: 'Peshawar', location: 'Peshawar Pakistan' },
    { value: 'EWN', label: 'Coastal Carolina Regional', location: 'New Bern, USA' },
    { value: 'BEW', label: 'Beira', location: 'Beira Mozambique' },
  ],
  ex: [
    { value: 'MEX', label: 'Mexico City-Benito Juarez', location: 'Mexico City Mexico' },
    { value: 'LEX', label: 'Blue Grass', location: 'Lexington, USA' },
    { value: 'EXT', label: 'Exeter', location: 'Exeter United Kingdom' },
    { value: 'REX', label: 'General Lucio Blanco', location: 'Reynosa Mexico' },
    { value: 'AEX', label: 'Alexandria', location: 'Alexandria, USA' },
  ],
  ey: [
    { value: 'BEY', label: 'Beirut Rafic Hariri', location: 'Beirut Lebanon' },
    { value: 'EYW', label: 'Key West', location: 'Key West, USA' },
    { value: 'EYP', label: 'El Yopal', location: 'El Yopal Colombia' },
    { value: 'AEY', label: 'Akureyri', location: 'Akureyri Iceland' },
  ],
  ez: [
    { value: 'EZE', label: 'Ministro Pistarini', location: 'Buenos Aires Argentina' },
    { value: 'EZS', label: 'Elaz\u0131\u011f', location: 'Elaz\u0131\u011f Turkey' },
    { value: 'FEZ', label: 'Sa\u00efss', location: 'Fes Morocco' },
    { value: 'SEZ', label: 'Seychelles', location: 'Mahe Island Seychelles' },
    { value: 'PEZ', label: 'Penza', location: 'Penza, Russia' },
  ],
  fa: [
    { value: 'FAO', label: 'Faro', location: 'Faro Portugal' },
    { value: 'UFA', label: 'Ufa', location: 'Ufa, Russia' },
    { value: 'FAI', label: 'Fairbanks', location: 'Fairbanks, USA' },
    { value: 'FAR', label: 'Hector', location: 'Fargo, USA' },
    { value: 'FAT', label: 'Fresno Yosemite', location: 'Fresno, USA' },
    { value: 'FAY', label: 'Fayetteville Grannis Field', location: 'Fayetteville, USA' },
  ],
  fb: [{ value: 'SFB', label: 'Orlando Sanford', location: 'Orlando, USA' }],
  fc: [
    { value: 'FCO', label: 'Leonardo da Vinci\u2013Fiumicino', location: 'Rome Italy' },
    { value: 'FCA', label: 'Glacier Park', location: 'Kalispell, USA' },
    { value: 'YFC', label: 'Fredericton', location: 'Fredericton Canada' },
  ],
  fd: [
    {
      value: 'FDF',
      label: 'Martinique Aim\u00e9 C\u00e9saire',
      location: 'Fort-de-France Martinique',
    },
    { value: 'FDH', label: 'Friedrichshafen', location: 'Friedrichshafen Germany' },
    { value: 'RFD', label: 'Chicago Rockford', location: 'Chicago/Rockford, USA' },
    { value: 'FDE', label: 'F\u00f8rde', location: 'F\u00f8rde Norway' },
  ],
  fe: [
    { value: 'HFE', label: 'Hefei Luogang', location: 'Hefei China' },
    { value: 'FEZ', label: 'Sa\u00efss', location: 'Fes Morocco' },
    { value: 'MFE', label: 'Mc Allen Miller', location: 'Mc Allen, USA' },
    {
      value: 'CFE',
      label: 'Clermont-Ferrand Auvergne',
      location: 'Clermont-Ferrand/Auvergne France',
    },
  ],
  ff: [],
  fg: [
    { value: 'SFG', label: "L'Esp\u00e9rance", location: 'Grand Case Saint Martin (French part)' },
  ],
  fh: [],
  fi: [
    { value: 'FIH', label: 'Ndjili', location: 'Kinshasa, DR Congo' },
    { value: 'BFI', label: 'Boeing Field King County', location: 'Seattle, USA' },
  ],
  fj: [
    { value: 'BFJ', label: 'Bijie Feixiong', location: 'Bijie China' },
    { value: 'SFJ', label: 'Kangerlussuaq', location: 'Kangerlussuaq Greenland' },
  ],
  fk: [
    { value: 'JFK', label: 'John F Kennedy', location: 'New York, USA' },
    { value: 'FKB', label: 'Karlsruhe Baden-Baden', location: 'Baden-Baden Germany' },
    { value: 'GFK', label: 'Grand Forks', location: 'Grand Forks, USA' },
    { value: 'FKS', label: 'Fukushima', location: 'Sukagawa Japan' },
    { value: 'CFK', label: 'Ech Cheliff', location: 'Algeria' },
  ],
  fl: [
    { value: 'FLL', label: 'Fort Lauderdale Hollywood', location: 'Fort Lauderdale, USA' },
    { value: 'FLN', label: 'Herc\u00edlio Luz', location: 'Florian\u00f3polis Brazil' },
    { value: 'FLR', label: 'Peretola', location: 'Firenze Italy' },
    { value: 'EFL', label: 'Kefallinia', location: 'Kefallinia Island Greece' },
    { value: 'BFL', label: 'Meadows Field', location: 'Bakersfield, USA' },
    { value: 'FLG', label: 'Flagstaff Pulliam', location: 'Flagstaff, USA' },
  ],
  fm: [
    { value: 'MFM', label: 'Macau', location: 'Macau Macao' },
    { value: 'FMM', label: 'Memmingen Allgau', location: 'Memmingen Germany' },
    { value: 'FMO', label: 'M\u00fcnster Osnabr\u00fcck', location: 'M\u00fcnster Germany' },
  ],
  fn: [
    { value: 'TFN', label: 'Tenerife Norte', location: 'Tenerife Island Spain' },
    { value: 'FNC', label: 'Madeira', location: 'Funchal Portugal' },
    {
      value: 'IFN',
      label: 'Esfahan Shahid Beheshti',
      location: 'Isfahan, Iran',
    },
    { value: 'XFN', label: 'Xiangyang Liuji', location: 'Xiangfan China' },
    { value: 'FNT', label: 'Bishop', location: 'Flint, USA' },
    { value: 'BFN', label: 'Bram Fischer', location: 'Bloemfontain South Africa' },
  ],
  fo: [
    { value: 'SFO', label: 'San Francisco', location: 'San Francisco, USA' },
    { value: 'FOC', label: 'Fuzhou Changle', location: 'Fuzhou China' },
    { value: 'FOR', label: 'Pinto Martins', location: 'Fortaleza Brazil' },
    { value: 'PFO', label: 'Paphos', location: 'Paphos Cyprus' },
    { value: 'IFO', label: 'Ivano-Frankivsk', location: 'Ivano-Frankivsk Ukraine' },
  ],
  fp: [
    { value: 'IFP', label: 'Laughlin Bullhead', location: 'Bullhead City, USA' },
    { value: 'RFP', label: 'Raiatea', location: 'Uturoa French Polynesia' },
  ],
  fq: [],
  fr: [
    { value: 'FRA', label: 'Frankfurt am Main', location: 'Frankfurt am Main Germany' },
    { value: 'FRU', label: 'Manas', location: 'Bishkek Kyrgyzstan' },
    { value: 'MFR', label: 'Rogue Valley Medford', location: 'Medford, USA' },
    { value: 'CFR', label: 'Caen-Carpiquet', location: 'Caen/Carpiquet France' },
    { value: 'FRO', label: 'Flor\u00f8', location: 'Flor\u00f8 Norway' },
    { value: 'FRD', label: 'Friday Harbor', location: 'Friday Harbor, USA' },
  ],
  fs: [
    { value: 'TFS', label: 'Tenerife South', location: 'Tenerife Island Spain' },
    { value: 'BFS', label: 'Belfast', location: 'Belfast United Kingdom' },
    { value: 'FSC', label: 'Figari Sud-Corse', location: 'Figari Sud-Corse France' },
    { value: 'FSD', label: 'Joe Foss Field', location: 'Sioux Falls, USA' },
    { value: 'FSZ', label: 'Mt. Fuji Shizuoka', location: 'Makinohara / Shimada Japan' },
    { value: 'CFS', label: 'Coffs Harbour', location: 'Coffs Harbour Australia' },
  ],
  ft: [
    { value: 'FTE', label: 'El Calafate', location: 'El Calafate Argentina' },
    { value: 'LFT', label: 'Lafayette', location: 'Lafayette, USA' },
    { value: 'SFT', label: 'Skellefte\u00e5', location: 'Skellefte\u00e5 Sweden' },
    { value: 'HFT', label: 'Hammerfest', location: 'Hammerfest Norway' },
  ],
  fu: [
    { value: 'FUK', label: 'Fukuoka', location: 'Fukuoka Japan' },
    { value: 'FUE', label: 'Fuerteventura', location: 'Fuerteventura Island Spain' },
    { value: 'CFU', label: 'Ioannis Kapodistrias', location: 'Kerkyra Island Greece' },
    { value: 'TFU', label: 'Chengdu Tianfu', location: 'Chengdu China' },
  ],
  fv: [{ value: 'BFV', label: 'Buri Ram', location: 'Thailand' }],
  fw: [
    { value: 'DFW', label: 'Dallas Fort Worth', location: 'Dallas-Fort Worth, USA' },
    { value: 'FWA', label: 'Fort Wayne', location: 'Fort Wayne, USA' },
    { value: 'LFW', label: 'Lom\u00e9-Tokoin', location: 'Lom\u00e9 Togo' },
  ],
  fx: [],
  fy: [{ value: 'FYG', label: 'Fuyong Ferry Terminal', location: 'Shenzhen China' }],
  fz: [],
  ga: [
    { value: 'LGA', label: 'La Guardia', location: 'New York, USA' },
    { value: 'GAU', label: 'Lokpriya Gopinath Bordoloi', location: 'Guwahati India' },
    { value: 'BGA', label: 'Palonegro', location: 'Bucaramanga Colombia' },
    { value: 'MGA', label: 'Augusto C. Sandino Managua', location: 'Managua Nicaragua' },
    { value: 'AGA', label: 'Al Massira', location: 'Agadir Morocco' },
    { value: 'VGA', label: 'Vijayawada', location: 'India' },
  ],
  gb: [
    { value: 'NGB', label: 'Ningbo Lishe', location: 'Ningbo China' },
    { value: 'LGB', label: 'Long Beach', location: 'Long Beach, USA' },
    { value: 'CGB', label: 'Marechal Rondon', location: 'Cuiab\u00e1 Brazil' },
    { value: 'GBE', label: 'Sir Seretse Khama', location: 'Gaborone Botswana' },
    { value: 'GBB', label: 'Gabala', location: 'Gabala Azerbaijan' },
  ],
  gc: [
    { value: 'SGC', label: 'Surgut', location: 'Surgut, Russia' },
    { value: 'GCM', label: 'Owen Roberts', location: 'Georgetown Cayman Islands' },
    { value: 'GCI', label: 'Guernsey', location: 'Saint Peter Port Guernsey' },
    { value: 'GCN', label: 'Grand Canyon National Park', location: 'Grand Canyon, USA' },
    {
      value: 'EGC',
      label: 'Bergerac-Roumani\u00e8re',
      location: 'Bergerac/Roumani\u00e8re France',
    },
    { value: 'GCC', label: 'Gillette-Campbell County', location: 'Gillette, USA' },
  ],
  gd: [
    { value: 'GDL', label: 'Don Miguel Hidalgo Y Costilla', location: 'Guadalajara Mexico' },
    { value: 'GDN', label: 'Gda\u0144sk Lech Wa\u0142\u0119sa', location: 'Gda\u0144sk Poland' },
    { value: 'KGD', label: 'Khrabrovo', location: 'Kaliningrad, Russia' },
    { value: 'TGD', label: 'Podgorica', location: 'Podgorica Montenegro' },
    { value: 'PGD', label: 'Charlotte County', location: 'Punta Gorda, USA' },
    { value: 'GDX', label: 'Sokol', location: 'Magadan, Russia' },
  ],
  ge: [
    { value: 'GEG', label: 'Spokane', location: 'Spokane, USA' },
    { value: 'GES', label: 'General Santos', location: 'General Santos Philippines' },
    { value: 'GEO', label: 'Cheddi Jagan', location: 'Georgetown Guyana' },
    { value: 'GEA', label: 'Noum\u00e9a Magenta', location: 'Noum\u00e9a New Caledonia' },
    { value: 'EGE', label: 'Eagle County Regional', location: 'Eagle, USA' },
    { value: 'GET', label: 'Geraldton', location: 'Geraldton Australia' },
  ],
  gf: [
    { value: 'SGF', label: 'Springfield Branson National', location: 'Springfield, USA' },
    { value: 'PGF', label: 'Perpignan-Rivesaltes', location: 'Perpignan/Rivesaltes France' },
    { value: 'GFK', label: 'Grand Forks', location: 'Grand Forks, USA' },
    { value: 'BGF', label: "Bangui M'Poko", location: 'Bangui Central African Republic' },
    { value: 'AGF', label: 'Agen-La Garenne', location: 'Agen/La Garenne France' },
  ],
  gg: [
    { value: 'OGG', label: 'Kahului', location: 'Kahului, USA' },
    { value: 'TGG', label: 'Sultan Mahmud', location: 'Kuala Terengganu Malaysia' },
    { value: 'LGG', label: 'Li\u00e8ge', location: 'Li\u00e8ge Belgium' },
    { value: 'BGG', label: 'Bing\u00f6l \u00c7eltiksuyu', location: 'Bing\u00f6l Turkey' },
    { value: 'GGG', label: 'East Texas Regional', location: 'Longview, USA' },
  ],
  gh: [
    { value: 'HGH', label: 'Hangzhou Xiaoshan', location: 'Hangzhou China' },
    { value: 'CGH', label: 'Congonhas', location: 'S\u00e3o Paulo Brazil' },
    { value: 'AGH', label: '\u00c4ngelholm-Helsingborg', location: '\u00c4ngelholm Sweden' },
    { value: 'GHA', label: 'Noum\u00e9rat - Moufdi Zakaria', location: 'Gharda\u00efa Algeria' },
  ],
  gi: [
    { value: 'GIG', label: 'Rio Gale\u00e3o \u2013 Tom Jobim', location: 'Rio De Janeiro Brazil' },
    { value: 'BGI', label: 'Sir Grantley Adams', location: 'Bridgetown Barbados' },
    { value: 'GIB', label: 'Gibraltar', location: 'Gibraltar Gibraltar' },
    { value: 'KGI', label: 'Kalgoorlie Boulder', location: 'Kalgoorlie Australia' },
    { value: 'RGI', label: 'Rangiroa', location: 'French Polynesia' },
  ],
  gj: [
    { value: 'YGJ', label: 'Miho Yonago', location: 'Yonago Japan' },
    { value: 'GJT', label: 'Grand Junction', location: 'Grand Junction, USA' },
  ],
  gk: [
    { value: 'CGK', label: 'Soekarno-Hatta', location: 'Jakarta Indonesia' },
    { value: 'LGK', label: 'Langkawi', location: 'Langkawi Malaysia' },
    {
      value: 'PGK',
      label: 'Pangkal Pinang (Depati Amir)',
      location: 'Pangkal Pinang-Palaubangka Island Indonesia',
    },
  ],
  gl: [
    { value: 'GLA', label: 'Glasgow', location: 'Glasgow United Kingdom' },
    { value: 'GLT', label: 'Gladstone', location: 'Gladstone Australia' },
    { value: 'RGL', label: 'Piloto Civil N. Fern\u00e1ndez', location: 'Rio Gallegos Argentina' },
    { value: 'KGL', label: 'Kigali', location: 'Kigali Rwanda' },
  ],
  gm: [
    { value: 'GMP', label: 'Gimpo', location: 'Seoul S. Korea' },
    { value: 'MGM', label: 'Montgomery Dannelly Field', location: 'Montgomery, USA' },
    { value: 'BGM', label: 'Greater Binghamton', location: 'Binghamton, USA' },
  ],
  gn: [
    { value: 'SGN', label: 'Tan Son Nhat', location: 'Ho Chi Minh City Viet Nam' },
    { value: 'CGN', label: 'Cologne Bonn', location: 'Cologne Germany' },
    { value: 'RGN', label: 'Yangon', location: 'Yangon Myanmar' },
    { value: 'GNY', label: '\u015eanl\u0131urfa GAP', location: '\u015eanl\u0131urfa Turkey' },
    { value: 'GNV', label: 'Gainesville Regional', location: 'Gainesville, USA' },
    { value: 'GNB', label: 'Grenoble-Is\u00e8re', location: 'Grenoble/Saint-Geoirs France' },
  ],
  go: [
    { value: 'CGO', label: 'Zhengzhou Xinzheng', location: 'Zhengzhou China' },
    { value: 'NGO', label: 'Chubu Centrair', location: 'Tokoname Japan' },
    { value: 'GOI', label: 'Dabolim', location: 'Vasco da Gama India' },
    { value: 'GOT', label: 'Gothenburg-Landvetter', location: 'Gothenburg Sweden' },
    { value: 'BGO', label: 'Bergen Flesland', location: 'Bergen Norway' },
    { value: 'GOA', label: 'Genoa Cristoforo Colombo', location: 'Genova Italy' },
  ],
  gp: [
    { value: 'AGP', label: 'M\u00e1laga', location: 'Malaga Spain' },
    { value: 'GPS', label: 'Seymour', location: 'Baltra Ecuador' },
    { value: 'GPT', label: 'Gulfport Biloxi', location: 'Gulfport, USA' },
    { value: 'LGP', label: 'Legazpi City', location: 'Legazpi City Philippines' },
    { value: 'GPA', label: 'Araxos', location: 'Patras Greece' },
    { value: 'KGP', label: 'Kogalym', location: 'Kogalym, Russia' },
  ],
  gq: [{ value: 'CGQ', label: 'Longjia', location: 'Changchun China' }],
  gr: [
    { value: 'GRU', label: 'S\u00e3o Paulo-Guarulhos', location: 'S\u00e3o Paulo Brazil' },
    { value: 'GRR', label: 'Gerald R. Ford', location: 'Grand Rapids, USA' },
    { value: 'GRO', label: 'Girona', location: 'Girona Spain' },
    { value: 'CGR', label: 'Campo Grande', location: 'Campo Grande Brazil' },
    { value: 'GRZ', label: 'Graz', location: 'Graz Austria' },
    { value: 'GRX', label: 'Federico Garcia Lorca', location: 'Granada Spain' },
  ],
  gs: [
    { value: 'NGS', label: 'Nagasaki', location: 'Nagasaki Japan' },
    { value: 'KGS', label: 'Kos', location: 'Kos Island Greece' },
    { value: 'GSP', label: 'Greenville Spartanburg', location: 'Greenville, USA' },
    { value: 'GSO', label: 'Piedmont Triad', location: 'Greensboro, USA' },
    { value: 'AGS', label: 'Augusta Regional At Bush Field', location: 'Augusta, USA' },
    { value: 'GSM', label: 'Qeshm', location: 'Qeshm, Iran' },
  ],
  gt: [
    { value: 'GTO', label: 'Jalaluddin', location: 'Gorontalo-Celebes Island Indonesia' },
    { value: 'GTF', label: 'Great Falls', location: 'Great Falls, USA' },
    { value: 'IGT', label: 'Magas', location: 'Magas, Russia' },
    { value: 'GTR', label: 'Golden Triangle', location: 'Columbus/W Point/Starkville, USA' },
    { value: 'AGT', label: 'Guarani', location: 'Ciudad del Este Paraguay' },
  ],
  gu: [
    {
      value: 'GUM',
      label: 'Antonio B. Won Pat',
      location: 'Hag\u00e5t\u00f1a, Guam International Airport Guam',
    },
    { value: 'GUA', label: 'La Aurora', location: 'Guatemala City Guatemala' },
    { value: 'IGU', label: 'Cataratas', location: 'Foz Do Igua\u00e7u Brazil' },
    { value: 'OGU', label: 'Ordu Giresun', location: 'Ordu Turkey' },
    { value: 'AGU', label: 'Jes\u00fas Ter\u00e1n Paredo', location: 'Aguascalientes Mexico' },
    { value: 'PGU', label: 'Persian Gulf', location: 'Asalouyeh, Iran' },
  ],
  gv: [
    { value: 'GVA', label: 'Geneva Cointrin', location: 'Geneva Switzerland' },
    { value: 'PGV', label: 'Pitt Greenville', location: 'Greenville, USA' },
  ],
  gw: [
    { value: 'LGW', label: 'London Gatwick', location: 'London United Kingdom' },
    { value: 'BGW', label: 'Baghdad', location: 'Baghdad Iraq' },
    { value: 'MGW', label: 'Morgantown', location: 'Morgantown, USA' },
  ],
  gx: [{ value: 'OGX', label: 'Ain el Beida', location: 'Ouargla Algeria' }],
  gy: [
    { value: 'BGY', label: 'Il Caravaggio', location: 'Bergamo Italy' },
    { value: 'GYD', label: 'Heydar Aliyev', location: 'Baku Azerbaijan' },
    { value: 'GYE', label: 'Jos\u00e9 Joaqu\u00edn de Olmedo', location: 'Guayaquil Ecuador' },
    { value: 'GYN', label: 'Santa Genoveva', location: 'Goi\u00e2nia Brazil' },
    { value: 'CGY', label: 'Laguindingan', location: 'Cagayan de Oro City Philippines' },
  ],
  gz: [
    { value: 'GZT', label: 'Gaziantep', location: 'Gaziantep Turkey' },
    { value: 'TGZ', label: 'Angel Albino Corzo', location: 'Tuxtla Guti\u00e9rrez Mexico' },
    { value: 'GZP', label: 'Gazipa\u015fa', location: 'Gazipa\u015fa Turkey' },
    { value: 'OGZ', label: 'Beslan', location: 'Beslan, Russia' },
  ],
  ha: [
    { value: 'SHA', label: 'Shanghai Hongqiao', location: 'Shanghai China' },
    { value: 'HAN', label: 'Noi Bai', location: 'Hanoi Viet Nam' },
    { value: 'HAK', label: 'Haikou Meilan', location: 'Haikou China' },
    { value: 'HAM', label: 'Hamburg', location: 'Hamburg Germany' },
    { value: 'HAJ', label: 'Hannover', location: 'Hannover Germany' },
    { value: 'HAV', label: 'Jos\u00e9 Mart\u00ed', location: 'Havana Cuba' },
  ],
  hb: [
    { value: 'JHB', label: 'Senai', location: 'Johor Bahru Malaysia' },
    { value: 'HBA', label: 'Hobart', location: 'Hobart Australia' },
    { value: 'HBE', label: 'Borg El Arab', location: 'Alexandria Egypt' },
  ],
  hc: [
    { value: 'CHC', label: 'Christchurch', location: 'Christchurch New Zealand' },
    { value: 'PHC', label: 'Port Harcourt', location: 'Port Harcourt Nigeria' },
  ],
  hd: [
    { value: 'MHD', label: 'Mashhad', location: 'Mashhad, Iran' },
    { value: 'HDY', label: 'Hat Yai', location: 'Hat Yai Thailand' },
    { value: 'BHD', label: 'George Best Belfast City', location: 'Belfast United Kingdom' },
    { value: 'HDG', label: 'Handan', location: 'Handan China' },
    { value: 'THD', label: 'Tho Xuan', location: 'Thanh H\u00f3a Viet Nam' },
    {
      value: 'OHD',
      label: 'Ohrid St. Paul the Apostle',
      location: 'Ohrid Macedonia, the former Yugoslav Republic of',
    },
  ],
  he: [
    { value: 'HEL', label: 'Helsinki Vantaa', location: 'Helsinki Finland' },
    { value: 'SHE', label: 'Taoxian', location: 'Shenyang China' },
    { value: 'HET', label: 'Baita', location: 'Hohhot China' },
    { value: 'HER', label: 'Heraklion Nikos Kazantzakis', location: 'Heraklion Greece' },
    { value: 'LHE', label: 'Alama Iqbal', location: 'Lahore Pakistan' },
    { value: 'THE', label: 'Senador Petr\u00f4nio Portela', location: 'Teresina Brazil' },
  ],
  hf: [
    { value: 'HFE', label: 'Hefei Luogang', location: 'Hefei China' },
    { value: 'PHF', label: 'Newport News Williamsburg', location: 'Newport News, USA' },
    { value: 'HFT', label: 'Hammerfest', location: 'Hammerfest Norway' },
  ],
  hg: [
    { value: 'HGH', label: 'Hangzhou Xiaoshan', location: 'Hangzhou China' },
    { value: 'JHG', label: 'Xishuangbanna Gasa', location: 'Jinghong China' },
    { value: 'KHG', label: 'Kashgar', location: 'Kashgar China' },
    { value: 'HGN', label: 'Mae Hong Son', location: 'Thailand' },
    { value: 'HGA', label: 'Egal', location: 'Hargeisa Somalia' },
    { value: 'HGR', label: 'Hagerstown Regional', location: 'Hagerstown, USA' },
  ],
  hh: [
    { value: 'KHH', label: 'Kaohsiung', location: 'Kaohsiung City, Taiwan' },
    { value: 'HHN', label: 'Frankfurt-Hahn', location: 'Frankfurt am Main Germany' },
    { value: 'HHH', label: 'Hilton Head', location: 'Hilton Head Island, USA' },
    { value: 'HHQ', label: 'Hua Hin', location: 'Hua Hin Thailand' },
  ],
  hi: [
    { value: 'KHI', label: 'Jinnah', location: 'Karachi Pakistan' },
    { value: 'HIJ', label: 'Hiroshima', location: 'Hiroshima Japan' },
    { value: 'HIA', label: 'Lianshui', location: "Huai'an China" },
    { value: 'BHI', label: 'Comandante Espora', location: 'Bahia Blanca Argentina' },
    { value: 'HIN', label: 'Sacheon Air Base/Airport', location: 'Sacheon S. Korea' },
    { value: 'RHI', label: 'Rhinelander Oneida County', location: 'Rhinelander, USA' },
  ],
  hj: [
    { value: 'SHJ', label: 'Sharjah', location: 'Sharjah, UAE' },
    { value: 'BHJ', label: 'Bhuj', location: 'Bhuj India' },
  ],
  hk: [
    { value: 'HKG', label: 'Hong Kong', location: 'Hong Kong Hong Kong' },
    { value: 'HKT', label: 'Phuket', location: 'Phuket Thailand' },
    { value: 'HKD', label: 'Hakodate', location: 'Hakodate Japan' },
    { value: 'MHK', label: 'Manhattan Regional', location: 'Manhattan, USA' },
  ],
  hl: [
    { value: 'PHL', label: 'Philadelphia', location: 'Philadelphia, USA' },
    { value: 'HLP', label: 'Halim Perdanakusuma', location: 'Jakarta Indonesia' },
    { value: 'HLD', label: 'Dongshan', location: 'Hailar China' },
    { value: 'HLH', label: 'Ulanhot', location: 'Ulanhot China' },
    { value: 'HLT', label: 'Hamilton', location: 'Australia' },
    { value: 'HLZ', label: 'Hamilton', location: 'Hamilton New Zealand' },
  ],
  hm: [
    { value: 'BHM', label: 'Birmingham-Shuttlesworth', location: 'Birmingham, USA' },
    { value: 'HMO', label: 'General Ignacio P. Garcia', location: 'Hermosillo Mexico' },
    { value: 'YHM', label: 'John C. Munro Hamilton', location: 'Hamilton Canada' },
    { value: 'HMB', label: 'Sohag', location: 'Sohag Egypt' },
    { value: 'HME', label: 'Oued Irara', location: 'Hassi Messaoud Algeria' },
    { value: 'HMA', label: 'Khanty Mansiysk', location: 'Khanty-Mansiysk, Russia' },
  ],
  hn: [
    { value: 'HND', label: 'Tokyo Haneda', location: 'Tokyo Japan' },
    { value: 'HNL', label: 'Daniel K Inouye', location: 'Honolulu, USA' },
    { value: 'KHN', label: 'Nanchang Changbei', location: 'Nanchang China' },
    { value: 'HHN', label: 'Frankfurt-Hahn', location: 'Frankfurt am Main Germany' },
    { value: 'HNA', label: 'Hanamaki', location: 'Japan' },
    { value: 'DHN', label: 'Dothan Regional', location: 'Dothan, USA' },
  ],
  ho: [
    { value: 'HOU', label: 'William P Hobby', location: 'Houston, USA' },
    { value: 'RHO', label: 'Diagoras', location: 'Rodes Island Greece' },
    { value: 'AHO', label: 'Alghero-Fertilia', location: 'Alghero Italy' },
    { value: 'BHO', label: 'Raja Bhoj', location: 'Bhopal India' },
    { value: 'CHO', label: 'Charlottesville Albemarle', location: 'Charlottesville, USA' },
    { value: 'HOV', label: '\u00d8rsta-Volda, Hovden', location: '\u00d8rsta Norway' },
  ],
  hp: [
    { value: 'HPH', label: 'Cat Bi', location: 'Haiphong Viet Nam' },
    { value: 'HPN', label: 'Westchester County', location: 'White Plains, USA' },
  ],
  hq: [
    { value: 'CHQ', label: 'Chania', location: 'Souda Greece' },
    { value: 'MHQ', label: 'Mariehamn', location: 'Aland Islands' },
    { value: 'HHQ', label: 'Hua Hin', location: 'Hua Hin Thailand' },
  ],
  hr: [
    { value: 'LHR', label: 'London Heathrow', location: 'London United Kingdom' },
    { value: 'HRB', label: 'Taiping', location: 'Harbin China' },
    { value: 'THR', label: 'Mehrabad', location: 'Tehran, Iran' },
    { value: 'HRG', label: 'Hurghada', location: 'Hurghada Egypt' },
    { value: 'HRK', label: 'Kharkiv', location: 'Kharkiv Ukraine' },
    { value: 'HRE', label: 'Robert Gabriel Mugabe', location: 'Harare Zimbabwe' },
  ],
  hs: [
    { value: 'CHS', label: 'Charleston', location: 'Charleston, USA' },
    { value: 'HSN', label: 'Zhoushan', location: 'Zhoushan China' },
    { value: 'HSV', label: 'Huntsville Carl T Jones Field', location: 'Huntsville, USA' },
    { value: 'HSG', label: 'Saga', location: 'Saga Japan' },
    { value: 'PHS', label: 'Phitsanulok', location: 'Thailand' },
    { value: 'THS', label: 'Sukhothai', location: 'Thailand' },
  ],
  ht: [
    { value: 'MHT', label: 'Manchester-Boston Regional', location: 'Manchester, USA' },
    { value: 'HTY', label: 'Hatay', location: 'Hatay Turkey' },
    { value: 'HTN', label: 'Hotan', location: 'Hotan China' },
    { value: 'HTI', label: 'Hamilton Island', location: 'Hamilton Island Australia' },
    { value: 'HTA', label: 'Chita-Kadala', location: 'Chita, Russia' },
    { value: 'HTS', label: 'Tri-State/Milton J. Ferguson', location: 'Huntington, USA' },
    { value: 'ZHT', label: 'Geneva Railway Station', location: 'Geneva Switzerland' },
  ],
  hu: [
    { value: 'HUZ', label: 'Huizhou', location: 'Huizhou China' },
    { value: 'HUI', label: 'Phu Bai', location: 'Hue Viet Nam' },
    { value: 'HUX', label: 'Bah\u00edas de Huatulco', location: 'Huatulco Mexico' },
    { value: 'HUN', label: 'Hualien', location: 'Hualien City, Taiwan' },
    { value: 'HUY', label: 'Humberside', location: 'Grimsby United Kingdom' },
    { value: 'HUH', label: 'Huahine-Fare', location: 'Fare French Polynesia' },
  ],
  hv: [
    { value: 'KHV', label: 'Khabarovsk-Novy', location: 'Khabarovsk, Russia' },
    { value: 'SHV', label: 'Shreveport', location: 'Shreveport, USA' },
    { value: 'HVB', label: 'Hervey Bay', location: 'Hervey Bay Australia' },
    { value: 'HVN', label: 'Tweed New Haven', location: 'New Haven, USA' },
    { value: 'NHV', label: 'Nuku Hiva', location: 'French Polynesia' },
    { value: 'HVD', label: 'Khovd', location: 'Khovd Mongolia' },
  ],
  hw: [{ value: 'LHW', label: 'Lanzhou Zhongchuan', location: 'Lanzhou China' }],
  hx: [
    { value: 'PHX', label: 'Phoenix Sky Harbor', location: 'Phoenix, USA' },
    { value: 'BHX', label: 'Birmingham', location: 'Birmingham United Kingdom' },
  ],
  hy: [
    { value: 'HYD', label: 'Rajiv Gandhi', location: 'Hyderabad India' },
    { value: 'BHY', label: 'Beihai', location: 'Beihai China' },
    { value: 'HYN', label: 'Huangyan Luqiao', location: 'Huangyan China' },
    { value: 'HYA', label: 'Barnstable', location: 'Hyannis, USA' },
  ],
  hz: [{ value: 'YHZ', label: 'Halifax / Stanfield', location: 'Halifax Canada' }],
  ia: [
    { value: 'MIA', label: 'Miami', location: 'Miami, USA' },
    { value: 'IAH', label: 'George Bush Houston', location: 'Houston, USA' },
    { value: 'IAD', label: 'Washington Dulles', location: 'Washington, USA' },
    { value: 'CIA', label: 'Ciampino\u2013G. B. Pastine', location: 'Rome Italy' },
    { value: 'TIA', label: 'Tirana Mother Teresa', location: 'Tirana Albania' },
    { value: 'HIA', label: 'Lianshui', location: "Huai'an China" },
  ],
  ib: [
    { value: 'IBZ', label: 'Ibiza', location: 'Ibiza Spain' },
    { value: 'IBR', label: 'Hyakuri', location: 'Omitama Japan' },
    { value: 'GIB', label: 'Gibraltar', location: 'Gibraltar Gibraltar' },
    { value: 'UIB', label: 'El Cara\u00f1o', location: 'Quibd\u00f3 Colombia' },
    { value: 'DIB', label: 'Dibrugarh', location: 'Dibrugarh India' },
    { value: 'JIB', label: 'Djibouti-Ambouli', location: 'Djibouti City Djibouti' },
  ],
  ic: [
    { value: 'ICN', label: 'Incheon', location: 'Seoul S. Korea' },
    { value: 'RIC', label: 'Richmond', location: 'Richmond, USA' },
    { value: 'ICT', label: 'Wichita Eisenhower National', location: 'Wichita, USA' },
  ],
  id: [
    { value: 'MID', label: 'Manuel Crescencio Rejon', location: 'M\u00e9rida Mexico' },
    { value: 'IDR', label: 'Devi Ahilyabai Holkar', location: 'Indore India' },
    { value: 'CID', label: 'The Eastern Iowa Airport', location: 'Cedar Rapids, USA' },
    { value: 'SID', label: 'Am\u00edlcar Cabral', location: 'Espargos Cape Verde' },
    { value: 'IDA', label: 'Idaho Falls', location: 'Idaho Falls, USA' },
    { value: 'BID', label: 'Block Island State', location: 'Block Island, USA' },
  ],
  ie: [
    { value: 'VIE', label: 'Vienna', location: 'Vienna Austria' },
    { value: 'IEV', label: 'Kiev Zhuliany', location: 'Kiev Ukraine' },
    { value: 'PIE', label: 'St Petersburg Clearwater', location: 'St Petersburg-Clearwater, USA' },
    { value: 'IEG', label: 'Zielona G\u00f3ra-Babimost', location: 'Babimost Poland' },
  ],
  if: [
    {
      value: 'IFN',
      label: 'Esfahan Shahid Beheshti',
      location: 'Isfahan, Iran',
    },
    { value: 'CIF', label: 'Chifeng', location: 'Chifeng China' },
    { value: 'IFP', label: 'Laughlin Bullhead', location: 'Bullhead City, USA' },
    { value: 'LIF', label: 'Lifou', location: 'Lifou New Caledonia' },
    { value: 'IFO', label: 'Ivano-Frankivsk', location: 'Ivano-Frankivsk Ukraine' },
  ],
  ig: [
    { value: 'GIG', label: 'Rio Gale\u00e3o \u2013 Tom Jobim', location: 'Rio De Janeiro Brazil' },
    { value: 'MIG', label: 'Mianyang', location: 'Mianyang China' },
    { value: 'IGU', label: 'Cataratas', location: 'Foz Do Igua\u00e7u Brazil' },
    { value: 'IGR', label: 'Cataratas Del Iguaz\u00fa', location: 'Puerto Iguazu Argentina' },
    { value: 'LIG', label: 'Limoges', location: 'Limoges/Bellegarde France' },
    { value: 'IGD', label: 'I\u011fd\u0131r', location: 'I\u011fd\u0131r Turkey' },
  ],
  ih: [
    { value: 'YIH', label: 'Yichang Sanxia', location: 'Yichang China' },
    { value: 'LIH', label: 'Lihue', location: 'Lihue, USA' },
    { value: 'KIH', label: 'Kish', location: 'Kish Island, Iran' },
    { value: 'CIH', label: 'Changzhi', location: 'Changzhi China' },
    { value: 'FIH', label: 'Ndjili', location: 'Kinshasa, DR Congo' },
    { value: 'ZIH', label: 'Ixtapa Zihuatanejo', location: 'Ixtapa Mexico' },
  ],
  ii: [{ value: 'VII', label: 'Vinh', location: 'Vinh Viet Nam' }],
  ij: [
    { value: 'TIJ', label: 'General Abelardo L. Rodr\u00edguez', location: 'Tijuana Mexico' },
    { value: 'HIJ', label: 'Hiroshima', location: 'Hiroshima Japan' },
    { value: 'KIJ', label: 'Niigata', location: 'Niigata Japan' },
    { value: 'IJK', label: 'Izhevsk', location: 'Izhevsk, Russia' },
  ],
  ik: [
    { value: 'IKA', label: 'Imam Khomeini', location: 'Tehran, Iran' },
    { value: 'IKT', label: 'Irkutsk', location: 'Irkutsk, Russia' },
    { value: 'PIK', label: 'Glasgow Prestwick', location: 'Glasgow United Kingdom' },
    { value: 'BIK', label: 'Frans Kaisiepo', location: 'Biak-Supiori Island Indonesia' },
    { value: 'DIK', label: 'Dickinson Theodore Roosevelt', location: 'Dickinson, USA' },
    { value: 'JIK', label: 'Ikaria', location: 'Ikaria Island Greece' },
  ],
  il: [
    { value: 'ILO', label: 'Iloilo', location: 'Iloilo City Philippines' },
    { value: 'LIL', label: 'Lille-Lesquin', location: 'Lille/Lesquin France' },
    { value: 'ILM', label: 'Wilmington', location: 'Wilmington, USA' },
    { value: 'BIL', label: 'Billings Logan', location: 'Billings, USA' },
    { value: 'XIL', label: 'Xilinhot', location: 'Xilinhot China' },
    { value: 'VIL', label: 'Dakhla', location: 'Dakhla Morocco' },
  ],
  im: [
    { value: 'LIM', label: 'Jorge Ch\u00e1vez', location: 'Lima Peru' },
    { value: 'IMF', label: 'Imphal', location: 'Imphal India' },
    { value: 'IMP', label: 'Prefeito Renato Moreira', location: 'Imperatriz Brazil' },
    { value: 'KIM', label: 'Kimberley', location: 'Kimberley South Africa' },
    { value: 'NIM', label: 'Diori Hamani', location: 'Niamey Niger' },
    { value: 'TIM', label: 'Moses Kilangin', location: 'Timika-Papua Island Indonesia' },
  ],
  in: [
    { value: 'SIN', label: 'Singapore Changi', location: 'Singapore Singapore' },
    { value: 'LIN', label: 'Milano Linate', location: 'Milan Italy' },
    { value: 'INC', label: 'Yinchuan Hedong', location: 'Yinchuan China' },
    { value: 'IND', label: 'Indianapolis', location: 'Indianapolis, USA' },
    { value: 'EIN', label: 'Eindhoven', location: 'Eindhoven Netherlands' },
    { value: 'KIN', label: 'Norman Manley', location: 'Kingston Jamaica' },
  ],
  io: [
    { value: 'UIO', label: 'Mariscal Sucre', location: 'Quito Ecuador' },
    { value: 'BIO', label: 'Bilbao', location: 'Bilbao Spain' },
    { value: 'IOM', label: 'Isle of Man', location: 'Castletown Isle of Man' },
    { value: 'IOS', label: 'Bahia - Jorge Amado', location: 'Ilh\u00e9us Brazil' },
    { value: 'IOA', label: 'Ioannina', location: 'Ioannina Greece' },
  ],
  ip: [
    { value: 'SIP', label: 'Simferopol', location: 'Simferopol Ukraine' },
    { value: 'TIP', label: 'Tripoli', location: 'Tripoli Libyan Arab Jamahiriya' },
    { value: 'IPH', label: 'Sultan Azlan Shah', location: 'Ipoh Malaysia' },
    { value: 'IPC', label: 'Mataveri', location: 'Isla De Pascua Chile' },
    { value: 'UIP', label: 'Quimper-Cornouaille', location: 'Quimper/Pluguffan France' },
    { value: 'IPT', label: 'Williamsport', location: 'Williamsport, USA' },
  ],
  iq: [
    { value: 'IQQ', label: 'Diego Aracena', location: 'Iquique Chile' },
    { value: 'BIQ', label: 'Biarritz-Anglet-Bayonne', location: 'Biarritz/Anglet/Bayonne France' },
    { value: 'IQT', label: 'Francisco Secada Vignetta', location: 'Iquitos Peru' },
  ],
  ir: [
    { value: 'MIR', label: 'Monastir Habib Bourguiba', location: 'Monastir Tunisia' },
    { value: 'LIR', label: 'Daniel Oduber Quiros', location: 'Liberia Costa Rica' },
    { value: 'TIR', label: 'Tirupati', location: 'Tirupati India' },
    { value: 'PIR', label: 'Pierre Regional', location: 'Pierre, USA' },
  ],
  is: [
    { value: 'IST', label: 'Istanbul', location: 'Istanbul Turkey' },
    { value: 'LIS', label: 'Humberto Delgado Lisbon', location: 'Lisbon Portugal' },
    { value: 'ISB', label: 'New Islamabad', location: 'Islamabad Pakistan' },
    { value: 'ISG', label: 'New Ishigaki', location: 'Ishigaki Japan' },
    { value: 'ISP', label: 'Long Island Mac Arthur', location: 'Islip, USA' },
    { value: 'BIS', label: 'Bismarck', location: 'Bismarck, USA' },
  ],
  it: [
    { value: 'ITM', label: 'Osaka', location: 'Osaka Japan' },
    { value: 'PIT', label: 'Pittsburgh', location: 'Pittsburgh, USA' },
    { value: 'LIT', label: 'Bill & Hillary Clinton', location: 'Little Rock, USA' },
    { value: 'OIT', label: 'Oita', location: 'Oita Japan' },
    { value: 'ITO', label: 'Hilo', location: 'Hilo, USA' },
    { value: 'ITH', label: 'Ithaca Tompkins', location: 'Ithaca, USA' },
  ],
  iu: [
    { value: 'PIU', label: 'Guillermo Concha Iberico', location: 'Piura Peru' },
    { value: 'CIU', label: 'Chippewa County', location: 'Sault Ste Marie, USA' },
  ],
  iv: [
    {
      value: 'KIV',
      label: 'Chi\u015fin\u0103u',
      location: 'Chi\u015fin\u0103u Moldova, Republic of',
    },
    { value: 'TIV', label: 'Tivat', location: 'Tivat Montenegro' },
    { value: 'IVC', label: 'Invercargill', location: 'Invercargill New Zealand' },
    { value: 'IVL', label: 'Ivalo', location: 'Ivalo Finland' },
  ],
  iw: [
    { value: 'YIW', label: 'Yiwu', location: 'Yiwu China' },
    { value: 'IWK', label: 'MCAS Iwakuni', location: 'Iwakuni Japan' },
    { value: 'RIW', label: 'Riverton', location: 'Riverton, USA' },
  ],
  ix: [
    { value: 'KIX', label: 'Kansai', location: 'Osaka Japan' },
    { value: 'RIX', label: 'Riga', location: 'Riga Latvia' },
    { value: 'VIX', label: 'Eurico de Aguiar Salles', location: 'Vit\u00f3ria Brazil' },
    { value: 'IXC', label: 'Chandigarh', location: 'Chandigarh India' },
    { value: 'IXB', label: 'Bagdogra', location: 'Siliguri India' },
    { value: 'IXE', label: 'Mangalore', location: 'Mangalore India' },
  ],
  iy: [
    { value: 'XIY', label: "Xi'an Xianyang ", location: "Xi'an China" },
    { value: 'DIY', label: 'Diyarbakir', location: 'Diyarbakir Turkey' },
    { value: 'CIY', label: 'Comiso', location: 'Comiso Italy' },
  ],
  iz: [{ value: 'IZO', label: 'Izumo', location: 'Izumo Japan' }],
  ja: [
    { value: 'JAX', label: 'Jacksonville', location: 'Jacksonville, USA' },
    { value: 'JAI', label: 'Jaipur', location: 'Jaipur India' },
    { value: 'KJA', label: 'Yemelyanovo', location: 'Krasnoyarsk, Russia' },
    {
      value: 'AJA',
      label: 'Ajaccio-Napol\u00e9on Bonaparte',
      location: 'Ajaccio/Napol\u00e9on Bonaparte France',
    },
    { value: 'JAN', label: 'Jackson-Medgar Wiley Evers', location: 'Jackson, USA' },
    { value: 'JAC', label: 'Jackson Hole', location: 'Jackson, USA' },
  ],
  jb: [
    { value: 'CJB', label: 'Coimbatore', location: 'Coimbatore India' },
    { value: 'DJB', label: 'Sultan Thaha', location: 'Jambi-Sumatra Island Indonesia' },
  ],
  jc: [
    { value: 'SJC', label: 'Norman Y. Mineta San Jose', location: 'San Jose, USA' },
    { value: 'CJC', label: 'El Loa', location: 'Calama Chile' },
    { value: 'NJC', label: 'Nizhnevartovsk', location: 'Nizhnevartovsk, Russia' },
  ],
  jd: [
    { value: 'SJD', label: 'Los Cabos', location: 'San Jos\u00e9 del Cabo Mexico' },
    { value: 'JDO', label: 'Orlando Bezerra de Menezes', location: 'Juazeiro Do Norte Brazil' },
    { value: 'JDH', label: 'Jodhpur', location: 'Jodhpur India' },
  ],
  je: [
    { value: 'JED', label: 'King Abdulaziz', location: 'Jeddah Saudi Arabia' },
    { value: 'JER', label: 'Jersey', location: 'Saint Helier Jersey' },
    { value: 'DJE', label: 'Djerba Zarzis', location: 'Djerba Tunisia' },
  ],
  jf: [
    { value: 'JFK', label: 'John F Kennedy', location: 'New York, USA' },
    { value: 'MJF', label: 'Mosj\u00f8en (Kj\u00e6rstad)', location: 'Norway' },
    { value: 'BJF', label: 'B\u00e5tsfjord', location: 'B\u00e5tsfjord Norway' },
  ],
  jg: [{ value: 'LJG', label: 'Lijiang', location: 'Lijiang China' }],
  jh: [
    { value: 'JHG', label: 'Xishuangbanna Gasa', location: 'Jinghong China' },
    { value: 'JHB', label: 'Senai', location: 'Johor Bahru Malaysia' },
  ],
  ji: [
    { value: 'AJI', label: 'A\u011fr\u0131', location: 'Turkey' },
    { value: 'JIB', label: 'Djibouti-Ambouli', location: 'Djibouti City Djibouti' },
    { value: 'SJI', label: 'San Jose Mindoro', location: 'San Jose Philippines' },
    { value: 'BJI', label: 'Bemidji', location: 'Bemidji, USA' },
    { value: 'JIK', label: 'Ikaria', location: 'Ikaria Island Greece' },
  ],
  jj: [
    { value: 'JJN', label: 'Quanzhou Jinjiang', location: 'Quanzhou China' },
    { value: 'CJJ', label: 'Cheongju', location: 'Cheongju S. Korea' },
    { value: 'DJJ', label: 'Sentani', location: 'Jayapura-Papua Island Indonesia' },
    { value: 'SJJ', label: 'Sarajevo', location: 'Sarajevo Bosnia' },
  ],
  jk: [
    { value: 'IJK', label: 'Izhevsk', location: 'Izhevsk, Russia' },
    { value: 'JKH', label: 'Chios Island National', location: 'Chios Island Greece' },
    { value: 'RJK', label: 'Rijeka', location: 'Rijeka Croatia' },
    { value: 'JKG', label: 'J\u00f6nk\u00f6ping', location: 'J\u00f6nk\u00f6ping Sweden' },
    {
      value: 'SJK',
      label: 'S\u00e3o Jos\u00e9 Dos Campos',
      location: 'S\u00e3o Jos\u00e9 Dos Campos Brazil',
    },
  ],
  jl: [
    { value: 'BJL', label: 'Banjul', location: 'Banjul Gambia' },
    { value: 'AJL', label: 'Lengpui', location: 'Aizawl India' },
    { value: 'JLR', label: 'Jabalpur', location: 'India' },
    { value: 'JLN', label: 'Joplin', location: 'Joplin, USA' },
  ],
  jm: [
    { value: 'TJM', label: 'Roshchino', location: 'Tyumen, Russia' },
    { value: 'JMK', label: 'Mikonos', location: 'Mykonos Island Greece' },
    { value: 'BJM', label: 'Bujumbura', location: 'Bujumbura Burundi' },
    { value: 'CJM', label: 'Chumphon', location: 'Thailand' },
  ],
  jn: [
    { value: 'JNB', label: 'O.R. Tambo', location: 'Johannesburg South Africa' },
    { value: 'JJN', label: 'Quanzhou Jinjiang', location: 'Quanzhou China' },
    { value: 'JNG', label: 'Jining Qufu', location: 'Jining China' },
    { value: 'JNU', label: 'Juneau', location: 'Juneau, USA' },
    { value: 'JNX', label: 'Naxos', location: 'Naxos Island Greece' },
  ],
  jo: [
    { value: 'JOG', label: 'Adi Sutjipto', location: 'Yogyakarta-Java Island Indonesia' },
    { value: 'SJO', label: 'Juan Santamaria', location: 'San Jose Costa Rica' },
    { value: 'JOI', label: 'Lauro Carneiro de Loyola', location: 'Joinville Brazil' },
    { value: 'JOE', label: 'Joensuu', location: 'Joensuu / Liperi Finland' },
    { value: 'JOL', label: 'Jolo', location: 'Philippines' },
  ],
  jp: [{ value: 'JPA', label: 'Presidente Castro Pinto', location: 'Jo\u00e3o Pessoa Brazil' }],
  jq: [
    {
      value: 'TJQ',
      label: 'H.A.S. Hanandjoeddin',
      location: 'Tanjung Pandan-Belitung Island Indonesia',
    },
  ],
  jr: [
    { value: 'JRO', label: 'Kilimanjaro', location: 'Arusha Tanzania, United Republic of' },
    { value: 'AJR', label: 'Arvidsjaur', location: 'Arvidsjaur Sweden' },
  ],
  js: [
    { value: 'CJS', label: 'Abraham Gonz\u00e1lez', location: 'Ciudad Ju\u00e1rez Mexico' },
    { value: 'JSI', label: 'Skiathos Island National', location: 'Skiathos Greece' },
    { value: 'JSH', label: 'Sitia', location: 'Crete Island Greece' },
    { value: 'JSY', label: 'Syros', location: 'Syros Island Greece' },
  ],
  jt: [
    { value: 'JTR', label: 'Santorini', location: 'Santorini Island Greece' },
    { value: 'MJT', label: 'Mytilene', location: 'Mytilene Greece' },
    { value: 'GJT', label: 'Grand Junction', location: 'Grand Junction, USA' },
    { value: 'SJT', label: 'San Angelo Regional', location: 'San Angelo, USA' },
    { value: 'JTY', label: 'Astypalaia', location: 'Astypalaia Island Greece' },
  ],
  ju: [
    { value: 'CJU', label: 'Jeju', location: 'Jeju City S. Korea' },
    { value: 'SJU', label: 'Luis Munoz Marin', location: 'San Juan Puerto Rico' },
    { value: 'LJU', label: 'Ljubljana Jo\u017ee Pu\u010dnik', location: 'Ljubljana Slovenia' },
    { value: 'AJU', label: 'Santa Maria', location: 'Aracaju Brazil' },
    { value: 'JUL', label: 'Inca Manco Capac', location: 'Juliaca Peru' },
    {
      value: 'JUJ',
      label: 'Gobernador Horacio Guzman',
      location: 'San Salvador de Jujuy Argentina',
    },
  ],
  jv: [
    { value: 'BJV', label: 'Milas Bodrum', location: 'Bodrum Turkey' },
    { value: 'MJV', label: 'San Javier', location: 'San Javier Spain' },
  ],
  jw: [{ value: 'SJW', label: 'Shijiazhuang Daguocun', location: 'Shijiazhuang China' }],
  jx: [{ value: 'BJX', label: 'Del Baj\u00edo', location: 'Silao Mexico' }],
  jy: [{ value: 'JYV', label: 'Jyvaskyla', location: 'Jyv\u00e4skyl\u00e4n Maalaiskunta Finland' }],
  jz: [
    { value: 'JZH', label: 'Jiuzhai Huanglong', location: 'Jiuzhaigou China' },
    { value: 'MJZ', label: 'Mirny', location: 'Mirny, Russia' },
  ],
  ka: [
    { value: 'OKA', label: 'Naha', location: 'Naha Japan' },
    { value: 'IKA', label: 'Imam Khomeini', location: 'Tehran, Iran' },
    { value: 'KAN', label: 'Mallam Aminu', location: 'Kano Nigeria' },
    { value: 'YKA', label: 'Kamloops', location: 'Kamloops Canada' },
    { value: 'KAD', label: 'Kaduna', location: 'Kaduna Nigeria' },
    { value: 'KAO', label: 'Kuusamo', location: 'Kuusamo Finland' },
  ],
  kb: [
    { value: 'KBP', label: 'Boryspil', location: 'Kiev Ukraine' },
    { value: 'KBV', label: 'Krabi', location: 'Krabi Thailand' },
    { value: 'UKB', label: 'Kobe', location: 'Kobe Japan' },
    { value: 'KBR', label: 'Sultan Ismail Petra', location: 'Kota Baharu Malaysia' },
    { value: 'FKB', label: 'Karlsruhe Baden-Baden', location: 'Baden-Baden Germany' },
    { value: 'SKB', label: 'Robert L. Bradshaw', location: 'Basseterre Saint Kitts and Nevis' },
  ],
  kc: [
    { value: 'KCH', label: 'Kuching', location: 'Kuching Malaysia' },
    { value: 'OKC', label: 'Will Rogers', location: 'Oklahoma City, USA' },
    { value: 'KKC', label: 'Khon Kaen', location: 'Khon Kaen Thailand' },
    { value: 'KCZ', label: 'K\u014dchi Ry\u014dma', location: 'Nankoku Japan' },
    { value: 'PKC', label: 'Yelizovo', location: 'Petropavlovsk-Kamchatsky, Russia' },
    { value: 'KCM', label: 'Kahramanmara\u015f', location: 'Kahramanmara\u015f Turkey' },
  ],
  kd: [
    { value: 'HKD', label: 'Hakodate', location: 'Hakodate Japan' },
    { value: 'KDI', label: 'Wolter Monginsidi', location: 'Kendari-Celebes Island Indonesia' },
    { value: 'OKD', label: 'Okadama', location: 'Sapporo Japan' },
    { value: 'RKD', label: 'Knox County Regional', location: 'Rockland, USA' },
  ],
  ke: [
    { value: 'KEF', label: 'Keflavik', location: 'Reykjav\u00edk Iceland' },
    { value: 'MKE', label: 'General Mitchell', location: 'Milwaukee, USA' },
    { value: 'KER', label: 'Kerman', location: 'Kerman, Iran' },
    { value: 'KEJ', label: 'Kemerovo', location: 'Kemerovo, Russia' },
    { value: 'KEM', label: 'Kemi-Tornio', location: 'Kemi / Tornio Finland' },
  ],
  kf: [{ value: 'KFS', label: 'Kastamonu', location: 'Kastamonu Turkey' }],
  kg: [
    { value: 'HKG', label: 'Hong Kong', location: 'Hong Kong Hong Kong' },
    { value: 'CKG', label: 'Chongqing Jiangbei', location: 'Chongqing China' },
    { value: 'NKG', label: 'Nanjing Lukou', location: 'Nanjing China' },
    { value: 'SKG', label: 'Thessaloniki Macedonia', location: 'Thessaloniki Greece' },
    { value: 'KGS', label: 'Kos', location: 'Kos Island Greece' },
    {
      value: 'TKG',
      label: 'Radin Inten II (Branti)',
      location: 'Bandar Lampung-Sumatra Island Indonesia',
    },
  ],
  kh: [
    { value: 'KHN', label: 'Nanchang Changbei', location: 'Nanchang China' },
    { value: 'KHI', label: 'Jinnah', location: 'Karachi Pakistan' },
    { value: 'KHH', label: 'Kaohsiung', location: 'Kaohsiung City, Taiwan' },
    { value: 'KHV', label: 'Khabarovsk-Novy', location: 'Khabarovsk, Russia' },
    { value: 'KHG', label: 'Kashgar', location: 'Kashgar China' },
    { value: 'JKH', label: 'Chios Island National', location: 'Chios Island Greece' },
  ],
  ki: [
    { value: 'KIX', label: 'Kansai', location: 'Osaka Japan' },
    { value: 'BKI', label: 'Kota Kinabalu', location: 'Kota Kinabalu Malaysia' },
    { value: 'KIH', label: 'Kish', location: 'Kish Island, Iran' },
    {
      value: 'KIV',
      label: 'Chi\u015fin\u0103u',
      location: 'Chi\u015fin\u0103u Moldova, Republic of',
    },
    { value: 'KIN', label: 'Norman Manley', location: 'Kingston Jamaica' },
    { value: 'KIJ', label: 'Niigata', location: 'Niigata Japan' },
  ],
  kj: [
    { value: 'KJA', label: 'Yemelyanovo', location: 'Krasnoyarsk, Russia' },
    { value: 'KKJ', label: 'Kitaky\u016bsh\u016b', location: 'Kitaky\u016bsh\u016b Japan' },
    { value: 'OKJ', label: 'Okayama', location: 'Okayama City Japan' },
    { value: 'AKJ', label: 'Asahikawa', location: 'Asahikawa / Hokkaid\u014d Japan' },
  ],
  kk: [
    { value: 'BKK', label: 'Suvarnabhumi', location: 'Bangkok Thailand' },
    { value: 'KKC', label: 'Khon Kaen', location: 'Khon Kaen Thailand' },
    { value: 'KKJ', label: 'Kitaky\u016bsh\u016b', location: 'Kitaky\u016bsh\u016b Japan' },
    { value: 'KKN', label: 'Kirkenes', location: 'Kirkenes Norway' },
    { value: 'MKK', label: 'Molokai', location: 'Kaunakakai, USA' },
  ],
  kl: [
    { value: 'AKL', label: 'Auckland', location: 'Auckland New Zealand' },
    { value: 'KLO', label: 'Kalibo', location: 'Kalibo Philippines' },
    { value: 'KLX', label: 'Kalamata', location: 'Kalamata Greece' },
    { value: 'KLR', label: 'Kalmar', location: 'Sweden' },
    { value: 'KLU', label: 'Klagenfurt', location: 'Klagenfurt am W\u00f6rthersee Austria' },
    { value: 'LKL', label: 'Banak', location: 'Lakselv Norway' },
  ],
  km: [
    { value: 'KMG', label: 'Kunming Changshui', location: 'Kunming China' },
    { value: 'KMJ', label: 'Kumamoto', location: 'Kumamoto Japan' },
    { value: 'KMI', label: 'Miyazaki', location: 'Miyazaki Japan' },
    { value: 'KMQ', label: 'Komatsu', location: 'Kanazawa Japan' },
    { value: 'NKM', label: 'Nagoya', location: 'Nagoya Japan' },
    { value: 'YKM', label: 'Yakima Air Terminal', location: 'Yakima, USA' },
  ],
  kn: [
    { value: 'KNO', label: 'Kualanamu', location: 'Indonesia' },
    { value: 'KNH', label: 'Kinmen', location: 'Shang-I, Taiwan' },
    { value: 'PKN', label: 'Iskandar', location: 'Pangkalanbun-Borneo Island Indonesia' },
    { value: 'KKN', label: 'Kirkenes', location: 'Kirkenes Norway' },
    { value: 'LKN', label: 'Leknes', location: 'Leknes Norway' },
    { value: 'SKN', label: 'Stokmarknes Skagen', location: 'Hadsel Norway' },
  ],
  ko: [
    { value: 'VKO', label: 'Vnukovo', location: 'Moscow, Russia' },
    { value: 'KOJ', label: 'Kagoshima', location: 'Kagoshima Japan' },
    { value: 'LKO', label: 'Chaudhary Charan Singh', location: 'Lucknow India' },
    { value: 'KOA', label: 'Ellison Onizuka Kona', location: 'Kailua/Kona, USA' },
    { value: 'KOE', label: 'El Tari', location: 'Kupang-Timor Island Indonesia' },
    { value: 'KOW', label: 'Ganzhou', location: 'Ganzhou China' },
  ],
  kp: [
    {
      value: 'SKP',
      label: 'Skopje Alexander the Great',
      location: 'Skopje Macedonia, the former Yugoslav Republic of',
    },
  ],
  kq: [{ value: 'MKQ', label: 'Mopah', location: 'Merauke-Papua Island Indonesia' }],
  kr: [
    { value: 'KRK', label: 'Krak\u00f3w John Paul II', location: 'Krak\u00f3w Poland' },
    { value: 'KRR', label: 'Krasnodar Pashkovsky', location: 'Krasnodar, Russia' },
    { value: 'KRT', label: 'Khartoum', location: 'Khartoum Sudan' },
    { value: 'DKR', label: 'L\u00e9opold S\u00e9dar Senghor', location: 'Dakar Senegal' },
    { value: 'KRL', label: 'Korla', location: 'Korla China' },
    { value: 'KRS', label: 'Kristiansand', location: 'Kjevik Norway' },
  ],
  ks: [
    { value: 'TKS', label: 'Tokushima', location: 'Tokushima Japan' },
    { value: 'BKS', label: 'Fatmawati Soekarno', location: 'Bengkulu-Sumatra Island Indonesia' },
    { value: 'YKS', label: 'Yakutsk', location: 'Yakutsk, Russia' },
    {
      value: 'KSH',
      label: 'Shahid Ashrafi Esfahani',
      location: 'Kermanshah, Iran',
    },
    { value: 'KSY', label: 'Kars', location: 'Kars Turkey' },
    { value: 'KSC', label: 'Ko\u0161ice', location: 'Ko\u0161ice Slovakia' },
  ],
  kt: [
    { value: 'HKT', label: 'Phuket', location: 'Phuket Thailand' },
    { value: 'KTW', label: 'Katowice', location: 'Katowice Poland' },
    { value: 'KTM', label: 'Tribhuvan', location: 'Kathmandu Nepal' },
    { value: 'IKT', label: 'Irkutsk', location: 'Irkutsk, Russia' },
    { value: 'SKT', label: 'Sialkot', location: 'Sialkot Pakistan' },
    { value: 'KTA', label: 'Karratha', location: 'Karratha Australia' },
  ],
  ku: [
    { value: 'KUL', label: 'Kuala Lumpur', location: 'Kuala Lumpur Malaysia' },
    {
      value: 'PKU',
      label: 'Sultan Syarif Kasim II',
      location: 'Pekanbaru-Sumatra Island Indonesia',
    },
    { value: 'KUF', label: 'Kurumoch', location: 'Samara, Russia' },
    { value: 'AKU', label: 'Aksu', location: 'Aksu China' },
    { value: 'KUN', label: 'Kaunas', location: 'Kaunas Lithuania' },
    { value: 'KUH', label: 'Kushiro', location: 'Kushiro Japan' },
  ],
  kv: [
    { value: 'KVA', label: 'Alexander the Great', location: 'Kavala Greece' },
    { value: 'RKV', label: 'Reykjavik', location: 'Reykjavik Iceland' },
    { value: 'KVD', label: 'Ganja', location: 'Ganja Azerbaijan' },
    { value: 'KVX', label: 'Pobedilovo', location: 'Kirov, Russia' },
  ],
  kw: [
    { value: 'KWE', label: 'Longdongbao', location: 'Guiyang China' },
    { value: 'KWI', label: 'Kuwait', location: 'Kuwait City Kuwait' },
    { value: 'KWL', label: 'Guilin Liangjiang', location: 'Guilin City China' },
    { value: 'KWJ', label: 'Gwangju', location: 'Gwangju S. Korea' },
    { value: 'MKW', label: 'Rendani', location: 'Manokwari-Papua Island Indonesia' },
    { value: 'KWG', label: 'Kryvyi Rih', location: 'Kryvyi Rih Ukraine' },
  ],
  kx: [],
  ky: [
    { value: 'KYA', label: 'Konya', location: 'Konya Turkey' },
    { value: 'PKY', label: 'Tjilik Riwut', location: 'Palangkaraya-Kalimantan Tengah Indonesia' },
    { value: 'MKY', label: 'Mackay', location: 'Mackay Australia' },
    { value: 'CKY', label: 'Conakry', location: 'Conakry Guinea' },
  ],
  kz: [
    { value: 'KZN', label: 'Kazan', location: 'Kazan, Russia' },
    { value: 'CKZ', label: '\u00c7anakkale', location: '\u00c7anakkale Turkey' },
    { value: 'KZR', label: 'Zafer', location: 'Alt\u0131nta\u015f Turkey' },
    { value: 'MKZ', label: 'Malacca', location: 'Malacca Malaysia' },
  ],
  la: [
    { value: 'LAX', label: 'Los Angeles', location: 'Los Angeles, USA' },
    { value: 'LAS', label: 'Las Vegas Harry Reid International', location: 'Las Vegas, USA' },
    { value: 'GLA', label: 'Glasgow', location: 'Glasgow United Kingdom' },
    { value: 'MLA', label: 'Malta', location: 'Valletta Malta' },
    { value: 'ALA', label: 'Almaty', location: 'Almaty Kazakhstan' },
    { value: 'LAD', label: 'Quatro de Fevereiro', location: 'Luanda Angola' },
  ],
  lb: [
    { value: 'LBA', label: 'Leeds Bradford', location: 'Leeds United Kingdom' },
    { value: 'OLB', label: 'Olbia Costa Smeralda', location: 'Olbia (SS) Italy' },
    { value: 'ALB', label: 'Albany', location: 'Albany, USA' },
    { value: 'LBB', label: 'Lubbock Preston Smith', location: 'Lubbock, USA' },
    { value: 'LBV', label: "Libreville Leon M'ba ", location: 'Libreville Gabon' },
    { value: 'LBJ', label: 'Komodo', location: 'Labuan Bajo - Flores Island Indonesia' },
  ],
  lc: [
    { value: 'SLC', label: 'Salt Lake City', location: 'Salt Lake City, USA' },
    { value: 'DLC', label: 'Zhoushuizi', location: 'Dalian China' },
    { value: 'ALC', label: 'Alicante', location: 'Alicante Spain' },
    { value: 'VLC', label: 'Valencia', location: 'Valencia Spain' },
    { value: 'LCA', label: 'Larnaca', location: 'Larnarca Cyprus' },
    { value: 'LCY', label: 'London City', location: 'London United Kingdom' },
  ],
  ld: [
    { value: 'HLD', label: 'Dongshan', location: 'Hailar China' },
    { value: 'LDB', label: 'Governador Jos\u00e9 Richa', location: 'Londrina Brazil' },
    {
      value: 'LDE',
      label: 'Tarbes-Lourdes-Pyr\u00e9n\u00e9es',
      location: 'Tarbes/Lourdes/Pyr\u00e9n\u00e9es France',
    },
    { value: 'BLD', label: 'Boulder City Municipal', location: 'Boulder City, USA' },
    { value: 'LDY', label: 'City of Derry', location: 'Derry United Kingdom' },
    { value: 'VLD', label: 'Valdosta Regional', location: 'Valdosta, USA' },
  ],
  le: [
    { value: 'LED', label: 'Pulkovo', location: 'St. Petersburg, Russia' },
    { value: 'CLE', label: 'Cleveland Hopkins', location: 'Cleveland, USA' },
    { value: 'LEJ', label: 'Leipzig/Halle', location: 'Leipzig Germany' },
    { value: 'LEX', label: 'Blue Grass', location: 'Lexington, USA' },
    { value: 'LEI', label: 'Almer\u00eda', location: 'Almer\u00eda Spain' },
    { value: 'LET', label: 'Alfredo V\u00e1squez Cobo', location: 'Leticia Colombia' },
  ],
  lf: [
    { value: 'LFT', label: 'Lafayette', location: 'Lafayette, USA' },
    { value: 'ALF', label: 'Alta', location: 'Alta Norway' },
    { value: 'LFW', label: 'Lom\u00e9-Tokoin', location: 'Lom\u00e9 Togo' },
  ],
  lg: [
    { value: 'LGW', label: 'London Gatwick', location: 'London United Kingdom' },
    { value: 'LGA', label: 'La Guardia', location: 'New York, USA' },
    { value: 'WLG', label: 'Wellington', location: 'Wellington New Zealand' },
    { value: 'ALG', label: 'Houari Boumediene', location: 'Algiers Algeria' },
    { value: 'LGB', label: 'Long Beach', location: 'Long Beach, USA' },
    { value: 'LGK', label: 'Langkawi', location: 'Langkawi Malaysia' },
  ],
  lh: [
    { value: 'LHR', label: 'London Heathrow', location: 'London United Kingdom' },
    { value: 'LHW', label: 'Lanzhou Zhongchuan', location: 'Lanzhou China' },
    { value: 'LHE', label: 'Alama Iqbal', location: 'Lahore Pakistan' },
    { value: 'TLH', label: 'Tallahassee Regional', location: 'Tallahassee, USA' },
    { value: 'HLH', label: 'Ulanhot', location: 'Ulanhot China' },
    { value: 'DLH', label: 'Duluth', location: 'Duluth, USA' },
  ],
  li: [
    { value: 'LIS', label: 'Humberto Delgado Lisbon', location: 'Lisbon Portugal' },
    { value: 'LIM', label: 'Jorge Ch\u00e1vez', location: 'Lima Peru' },
    { value: 'LIN', label: 'Milano Linate', location: 'Milan Italy' },
    { value: 'LIH', label: 'Lihue', location: 'Lihue, USA' },
    { value: 'LIT', label: 'Bill & Hillary Clinton', location: 'Little Rock, USA' },
    { value: 'LIL', label: 'Lille-Lesquin', location: 'Lille/Lesquin France' },
  ],
  lj: [
    { value: 'LJG', label: 'Lijiang', location: 'Lijiang China' },
    { value: 'CLJ', label: 'Cluj-Napoca', location: 'Cluj-Napoca Romania' },
    { value: 'LJU', label: 'Ljubljana Jo\u017ee Pu\u010dnik', location: 'Ljubljana Slovenia' },
  ],
  lk: [
    { value: 'LKO', label: 'Chaudhary Charan Singh', location: 'Lucknow India' },
    { value: 'TLK', label: 'Talakan', location: 'Talakan Oil Field, Russia' },
    { value: 'LKN', label: 'Leknes', location: 'Leknes Norway' },
    { value: 'LKL', label: 'Banak', location: 'Lakselv Norway' },
    { value: 'NLK', label: 'Norfolk Island', location: 'Burnt Pine Norfolk Island' },
  ],
  ll: [
    { value: 'FLL', label: 'Fort Lauderdale Hollywood', location: 'Fort Lauderdale, USA' },
    { value: 'BLL', label: 'Billund', location: 'Billund Denmark' },
    { value: 'TLL', label: 'Lennart Meri Tallinn', location: 'Tallinn Estonia' },
    { value: 'LLA', label: 'Lule\u00e5', location: 'Lule\u00e5 Sweden' },
    { value: 'SLL', label: 'Salalah', location: 'Salalah Oman' },
    { value: 'LLW', label: 'Lilongwe', location: 'Lilongwe Malawi' },
  ],
  lm: [
    {
      value: 'PLM',
      label: 'Sultan Mahmud Badaruddin II',
      location: 'Palembang-Sumatra Island Indonesia',
    },
    { value: 'DLM', label: 'Dalaman', location: 'Dalaman Turkey' },
    { value: 'ILM', label: 'Wilmington', location: 'Wilmington, USA' },
    { value: 'MLM', label: 'General Francisco J. Mujica', location: 'Morelia Mexico' },
    { value: 'LMM', label: 'Valle del Fuerte', location: 'Los Mochis Mexico' },
    { value: 'ELM', label: 'Elmira Corning Regional', location: 'Elmira/Corning, USA' },
  ],
  ln: [
    { value: 'FLN', label: 'Herc\u00edlio Luz', location: 'Florian\u00f3polis Brazil' },
    { value: 'ULN', label: 'Chinggis Khaan', location: 'Ulan Bator Mongolia' },
    {
      value: 'TLN',
      label: 'Toulon-Hy\u00e8res',
      location: 'Toulon/Hy\u00e8res/Le Palyvestre France',
    },
    { value: 'LNZ', label: 'Linz H\u00f6rsching', location: 'Linz Austria' },
    { value: 'MLN', label: 'Melilla', location: 'Melilla Spain' },
    { value: 'LNK', label: 'Lincoln', location: 'Lincoln, USA' },
  ],
  lo: [
    { value: 'LON', label: 'London', location: 'London United Kingdom', isCity: true },
    { value: 'LOS', label: 'Murtala Muhammed', location: 'Lagos Nigeria' },
    { value: 'CLO', label: 'Alfonso Bonilla Aragon', location: 'Cali Colombia' },
    { value: 'LOP', label: 'Lombok', location: 'Mataram Indonesia' },
    { value: 'KLO', label: 'Kalibo', location: 'Kalibo Philippines' },
    { value: 'ILO', label: 'Iloilo', location: 'Iloilo City Philippines' },
    { value: 'LOE', label: 'Loei', location: 'Thailand' },
  ],
  lp: [
    { value: 'LPA', label: 'Gran Canaria', location: 'Gran Canaria Island Spain' },
    { value: 'HLP', label: 'Halim Perdanakusuma', location: 'Jakarta Indonesia' },
    { value: 'LPL', label: 'Liverpool John Lennon', location: 'Liverpool United Kingdom' },
    { value: 'ELP', label: 'El Paso', location: 'El Paso, USA' },
    {
      value: 'LPB',
      label: 'El Alto',
      location: 'La Paz / El Alto, Bolivia',
    },
    { value: 'SLP', label: 'Ponciano Arriaga', location: 'San Luis Potos\u00ed Mexico' },
  ],
  lq: [
    { value: 'BLQ', label: 'Bologna Guglielmo Marconi', location: 'Bologna Italy' },
    { value: 'PLQ', label: 'Palanga', location: 'Palanga Lithuania' },
    { value: 'CLQ', label: 'Colima', location: 'Colima Mexico' },
  ],
  lr: [
    { value: 'BLR', label: 'Kempegowda', location: 'Bangalore India' },
    { value: 'FLR', label: 'Peretola', location: 'Firenze Italy' },
    { value: 'KLR', label: 'Kalmar', location: 'Sweden' },
    {
      value: 'LRH',
      label: 'La Rochelle-\u00cele de R\u00e9',
      location: 'La Rochelle/\u00cele de R\u00e9 France',
    },
    { value: 'JLR', label: 'Jabalpur', location: 'India' },
    { value: 'LRD', label: 'Laredo', location: 'Laredo, USA' },
  ],
  ls: [
    { value: 'TLS', label: 'Toulouse-Blagnac', location: 'Toulouse/Blagnac France' },
    { value: 'LST', label: 'Launceston', location: 'Launceston Australia' },
    {
      value: 'PLS',
      label: 'Providenciales',
      location: 'Providenciales Island Turks and Caicos Islands',
    },
    { value: 'ELS', label: 'Ben Schoeman', location: 'East London South Africa' },
    { value: 'LSC', label: 'La Florida', location: 'La Serena-Coquimbo Chile' },
    { value: 'LSI', label: 'Sumburgh', location: 'Lerwick United Kingdom' },
  ],
  lt: [
    { value: 'CLT', label: 'Charlotte Douglas', location: 'Charlotte, USA' },
    { value: 'LTN', label: 'London Luton', location: 'London United Kingdom' },
    { value: 'HLT', label: 'Hamilton', location: 'Australia' },
    { value: 'GLT', label: 'Gladstone', location: 'Gladstone Australia' },
    { value: 'LTO', label: 'Loreto', location: 'Loreto Mexico' },
  ],
  lu: [
    { value: 'LUX', label: 'Luxembourg-Findel', location: 'Luxembourg Luxembourg' },
    { value: 'LUM', label: 'Mangshi', location: 'Luxi China' },
    { value: 'DLU', label: 'Dali', location: 'Xiaguan China' },
    { value: 'LUN', label: 'Kenneth Kaunda Lusaka', location: 'Lusaka Zambia' },
    { value: 'LUZ', label: 'Lublin', location: 'Lublin Poland' },
    { value: 'KLU', label: 'Klagenfurt', location: 'Klagenfurt am W\u00f6rthersee Austria' },
  ],
  lv: [
    { value: 'TLV', label: 'Ben Gurion', location: 'Tel Aviv Israel' },
    { value: 'BLV', label: 'Midamerica St. Louis', location: 'Belleville, USA' },
    { value: 'LVI', label: 'Livingstone', location: 'Livingstone Zambia' },
    { value: 'KLV', label: 'Karlovy Vary', location: 'Karlovy Vary Czech Republic' },
  ],
  lw: [
    { value: 'YLW', label: 'Kelowna', location: 'Kelowna Canada' },
    { value: 'LWO', label: 'Lviv', location: 'Lviv Ukraine' },
    { value: 'PLW', label: 'Mutiara', location: 'Palu-Celebes Island Indonesia' },
    { value: 'LLW', label: 'Lilongwe', location: 'Lilongwe Malawi' },
    { value: 'LWS', label: 'Lewiston-Nez Perce County', location: 'Lewiston, USA' },
    { value: 'ALW', label: 'Walla Walla', location: 'Walla Walla, USA' },
  ],
  lx: [
    { value: 'LXA', label: 'Lhasa Gonggar', location: 'Lhasa China' },
    { value: 'MLX', label: 'Malatya Erha\u00e7', location: 'Malatya Turkey' },
    { value: 'LXR', label: 'Luxor', location: 'Luxor Egypt' },
    { value: 'KLX', label: 'Kalamata', location: 'Kalamata Greece' },
    { value: 'LXS', label: 'Limnos', location: 'Limnos Island Greece' },
  ],
  ly: [
    { value: 'LYS', label: 'Lyon Saint-Exup\u00e9ry', location: 'Lyon France' },
    { value: 'LYI', label: 'Shubuling', location: 'Linyi China' },
    { value: 'LYG', label: 'Lianyungang', location: 'Lianyungang China' },
    { value: 'LYA', label: 'Luoyang', location: 'Luoyang China' },
    { value: 'ALY', label: 'El Nouzha', location: 'Alexandria Egypt' },
    { value: 'LYP', label: 'Faisalabad', location: 'Faisalabad Pakistan' },
  ],
  lz: [
    { value: 'PLZ', label: 'Port Elizabeth', location: 'Port Elizabeth South Africa' },
    { value: 'SLZ', label: 'Marechal Cunha Machado', location: 'S\u00e3o Lu\u00eds Brazil' },
    { value: 'LZH', label: 'Liuzhou Bailian', location: 'Liuzhou China' },
    { value: 'LZO', label: 'Luzhou', location: 'Luzhou China' },
    { value: 'HLZ', label: 'Hamilton', location: 'Hamilton New Zealand' },
    { value: 'LZN', label: 'Matsu Nangan', location: 'Nangang Island, Taiwan' },
  ],
  ma: [
    { value: 'MAD', label: 'Adolfo Su\u00e1rez Madrid\u2013Barajas', location: 'Madrid Spain' },
    { value: 'MAN', label: 'Manchester', location: 'Manchester United Kingdom' },
    { value: 'MAA', label: 'Chennai', location: 'Chennai India' },
    { value: 'EMA', label: 'East Midlands', location: 'Nottingham United Kingdom' },
    { value: 'OMA', label: 'Eppley Airfield', location: 'Omaha, USA' },
    { value: 'MAH', label: 'Menorca', location: 'Menorca Island Spain' },
  ],
  mb: [
    { value: 'CMB', label: 'Bandaranaike Colombo', location: 'Colombo Sri Lanka' },
    { value: 'MBJ', label: 'Sangster', location: 'Montego Bay Jamaica' },
    { value: 'MBA', label: 'Mombasa Moi', location: 'Mombasa Kenya' },
    { value: 'MMB', label: 'Memanbetsu', location: '\u014czora Japan' },
    { value: 'HMB', label: 'Sohag', location: 'Sohag Egypt' },
    { value: 'MBS', label: 'MBS', location: 'Saginaw, USA' },
  ],
  mc: [
    { value: 'MCO', label: 'Orlando', location: 'Orlando, USA' },
    { value: 'MCT', label: 'Muscat', location: 'Muscat Oman' },
    { value: 'MCI', label: 'Kansas City', location: 'Kansas City, USA' },
    { value: 'MCZ', label: 'Zumbi dos Palmares', location: 'Macei\u00f3 Brazil' },
    { value: 'MCX', label: 'Uytash', location: 'Makhachkala, Russia' },
    { value: 'PMC', label: 'El Tepual', location: 'Puerto Montt Chile' },
  ],
  md: [
    { value: 'MDW', label: 'Chicago Midway', location: 'Chicago, USA' },
    { value: 'AMD', label: 'Sardar Vallabhbhai Patel', location: 'Ahmedabad India' },
    { value: 'MDE', label: 'Jose Maria C\u00f3rdova', location: 'Medell\u00edn Colombia' },
    { value: 'MDC', label: 'Sam Ratulangi', location: 'Manado-Celebes Island Indonesia' },
    { value: 'MDT', label: 'Harrisburg', location: 'Harrisburg, USA' },
    { value: 'MDZ', label: 'El Plumerillo', location: 'Mendoza Argentina' },
  ],
  me: [
    { value: 'MEX', label: 'Mexico City-Benito Juarez', location: 'Mexico City Mexico' },
    { value: 'MEL', label: 'Melbourne', location: 'Melbourne Australia' },
    { value: 'DME', label: 'Domodedovo', location: 'Moscow, Russia' },
    { value: 'MEM', label: 'Memphis', location: 'Memphis, USA' },
    { value: 'UME', label: 'Ume\u00e5', location: 'Ume\u00e5 Sweden' },
    { value: 'MEC', label: 'Eloy Alfaro', location: 'Manta Ecuador' },
  ],
  mf: [
    { value: 'SMF', label: 'Sacramento', location: 'Sacramento, USA' },
    { value: 'MFM', label: 'Macau', location: 'Macau Macao' },
    { value: 'RMF', label: 'Marsa Alam', location: 'Marsa Alam Egypt' },
    { value: 'IMF', label: 'Imphal', location: 'Imphal India' },
    { value: 'MFE', label: 'Mc Allen Miller', location: 'Mc Allen, USA' },
    { value: 'MFR', label: 'Rogue Valley Medford', location: 'Medford, USA' },
  ],
  mg: [
    { value: 'KMG', label: 'Kunming Changshui', location: 'Kunming China' },
    { value: 'MGA', label: 'Augusto C. Sandino Managua', location: 'Managua Nicaragua' },
    { value: 'MGM', label: 'Montgomery Dannelly Field', location: 'Montgomery, USA' },
    { value: 'MGW', label: 'Morgantown', location: 'Morgantown, USA' },
  ],
  mh: [
    { value: 'MHD', label: 'Mashhad', location: 'Mashhad, Iran' },
    { value: 'CMH', label: 'John Glenn Columbus', location: 'Columbus, USA' },
    { value: 'MHT', label: 'Manchester-Boston Regional', location: 'Manchester, USA' },
    { value: 'OMH', label: 'Urmia', location: 'Urmia, Iran' },
    { value: 'MHK', label: 'Manhattan Regional', location: 'Manhattan, USA' },
    { value: 'MHQ', label: 'Mariehamn', location: 'Aland Islands' },
  ],
  mi: [
    { value: 'MIL', label: 'Milan', location: 'Milan Italy', isCity: true },
    { value: 'MIA', label: 'Miami', location: 'Miami, USA' },
    { value: 'PMI', label: 'Palma De Mallorca', location: 'Palma De Mallorca Spain' },
    { value: 'MIG', label: 'Mianyang', location: 'Mianyang China' },
    { value: 'MIR', label: 'Monastir Habib Bourguiba', location: 'Monastir Tunisia' },
    { value: 'KMI', label: 'Miyazaki', location: 'Miyazaki Japan' },
    { value: 'WMI', label: 'Modlin', location: 'Warsaw Poland' },
  ],
  mj: [
    { value: 'KMJ', label: 'Kumamoto', location: 'Kumamoto Japan' },
    { value: 'MJV', label: 'San Javier', location: 'San Javier Spain' },
    { value: 'MJT', label: 'Mytilene', location: 'Mytilene Greece' },
    { value: 'MJZ', label: 'Mirny', location: 'Mirny, Russia' },
    { value: 'MJF', label: 'Mosj\u00f8en (Kj\u00e6rstad)', location: 'Norway' },
  ],
  mk: [
    { value: 'DMK', label: 'Don Mueang', location: 'Bangkok Thailand' },
    { value: 'MKE', label: 'General Mitchell', location: 'Milwaukee, USA' },
    { value: 'JMK', label: 'Mikonos', location: 'Mykonos Island Greece' },
    { value: 'MMK', label: 'Murmansk', location: 'Murmansk, Russia' },
    { value: 'MKY', label: 'Mackay', location: 'Mackay Australia' },
    { value: 'MKW', label: 'Rendani', location: 'Manokwari-Papua Island Indonesia' },
  ],
  ml: [
    { value: 'MLA', label: 'Malta', location: 'Valletta Malta' },
    { value: 'MLG', label: 'Abdul Rachman Saleh', location: 'Malang-Java Island Indonesia' },
    { value: 'MLX', label: 'Malatya Erha\u00e7', location: 'Malatya Turkey' },
    { value: 'MLI', label: 'Quad City', location: 'Moline, USA' },
    { value: 'MLM', label: 'General Francisco J. Mujica', location: 'Morelia Mexico' },
    { value: 'MLN', label: 'Melilla', location: 'Melilla Spain' },
  ],
  mm: [
    { value: 'AMM', label: 'Queen Alia', location: 'Amman Jordan' },
    { value: 'DMM', label: 'King Fahd', location: 'Ad Dammam Saudi Arabia' },
    { value: 'MMX', label: 'Malm\u00f6 Sturup', location: 'Malm\u00f6 Sweden' },
    { value: 'MMY', label: 'Miyako', location: 'Miyako City Japan' },
    { value: 'FMM', label: 'Memmingen Allgau', location: 'Memmingen Germany' },
    { value: 'MMK', label: 'Murmansk', location: 'Murmansk, Russia' },
  ],
  mn: [
    { value: 'MNL', label: 'Ninoy Aquino', location: 'Manila Philippines' },
    { value: 'XMN', label: 'Xiamen Gaoqi', location: 'Xiamen China' },
    { value: 'CMN', label: 'Mohammed V', location: 'Casablanca Morocco' },
    { value: 'LMN', label: 'Limbang', location: 'Limbang Malaysia' },
  ],
  mo: [
    { value: 'MOW', label: 'Moscow', location: 'Moscow Russia', isCity: true },
    { value: 'PMO', label: 'Falcone\u2013Borsellino', location: 'Palermo Italy' },
    { value: 'HMO', label: 'General Ignacio P. Garcia', location: 'Hermosillo Mexico' },
    { value: 'FMO', label: 'M\u00fcnster Osnabr\u00fcck', location: 'M\u00fcnster Germany' },
    { value: 'MOB', label: 'Mobile', location: 'Mobile, USA' },
    { value: 'MOT', label: 'Minot', location: 'Minot, USA' },
    { value: 'MOL', label: 'Molde', location: '\u00c5r\u00f8 Norway' },
  ],
  mp: [
    { value: 'GMP', label: 'Gimpo', location: 'Seoul S. Korea' },
    {
      value: 'MPL',
      label: 'Montpellier-M\u00e9diterran\u00e9e',
      location: 'Montpellier/M\u00e9diterran\u00e9e France',
    },
    { value: 'MPH', label: 'Godofredo P. Ramos', location: 'Malay Philippines' },
    { value: 'MPM', label: 'Maputo', location: 'Maputo Mozambique' },
    { value: 'IMP', label: 'Prefeito Renato Moreira', location: 'Imperatriz Brazil' },
    { value: 'TMP', label: 'Tampere-Pirkkala', location: 'Tampere / Pirkkala Finland' },
  ],
  mq: [
    {
      value: 'RMQ',
      label: 'Taichung Ching Chuang Kang',
      location: 'Taichung City, Taiwan',
    },
    { value: 'KMQ', label: 'Komatsu', location: 'Kanazawa Japan' },
    { value: 'AMQ', label: 'Pattimura, Ambon', location: 'Ambon Indonesia' },
    { value: 'MQM', label: 'Mardin', location: 'Mardin Turkey' },
    { value: 'SMQ', label: 'Sampit( Hasan)', location: 'Sampit-Borneo Island Indonesia' },
    { value: 'MQL', label: 'Mildura', location: 'Mildura Australia' },
  ],
  mr: [
    { value: 'MRS', label: 'Marseille Provence', location: 'Marseille France' },
    { value: 'MRU', label: 'Sir Seewoosagur Ramgoolam', location: 'Port Louis Mauritius' },
    { value: 'MRV', label: 'Mineralnyye Vody', location: 'Mineralnyye Vody, Russia' },
    { value: 'SMR', label: 'Sim\u00f3n Bol\u00edvar', location: 'Santa Marta Colombia' },
    { value: 'PMR', label: 'Palmerston North', location: 'New Zealand' },
    { value: 'MRY', label: 'Monterey Peninsula', location: 'Monterey, USA' },
  ],
  ms: [
    { value: 'AMS', label: 'Amsterdam Schiphol', location: 'Amsterdam Netherlands' },
    { value: 'MSP', label: 'Minneapolis-St Paul', location: 'Minneapolis, USA' },
    { value: 'MSY', label: 'Louis Armstrong New Orleans', location: 'New Orleans, USA' },
    { value: 'MSQ', label: 'Minsk National', location: 'Minsk Belarus' },
    { value: 'MSN', label: 'Dane County Truax Field', location: 'Madison, USA' },
    { value: 'OMS', label: 'Omsk Central', location: 'Omsk, Russia' },
  ],
  mt: [
    { value: 'MTY', label: 'General Mariano Escobedo', location: 'Monterrey Mexico' },
    { value: 'MTR', label: 'Los Garzones', location: 'Monter\u00eda Colombia' },
    { value: 'MTJ', label: 'Montrose Regional', location: 'Montrose, USA' },
    { value: 'MTT', label: 'Minatitl\u00e1n/Coatzacoalcos', location: 'Minatitl\u00e1n Mexico' },
    { value: 'IMT', label: 'Ford', location: 'Iron Mountain / Kingsford, USA' },
  ],
  mu: [
    { value: 'MUC', label: 'Munich', location: 'Munich Germany' },
    { value: 'MUX', label: 'Multan', location: 'Multan Pakistan' },
    { value: 'BMU', label: 'Muhammad Salahuddin', location: 'Bima-Sumbawa Island Indonesia' },
    { value: 'MUB', label: 'Maun', location: 'Maun Botswana' },
    { value: 'DMU', label: 'Dimapur', location: 'Dimapur India' },
  ],
  mv: [
    { value: 'MVD', label: 'Carrasco/General C L Berisso', location: 'Montevideo Uruguay' },
    { value: 'BMV', label: 'Buon Ma Thuot', location: 'Buon Ma Thuot Viet Nam' },
    { value: 'MVY', label: "Martha's Vineyard", location: "Martha's Vineyard, USA" },
  ],
  mw: [
    { value: 'PMW', label: 'Brigadeiro Lysias Rodrigues', location: 'Palmas Brazil' },
    { value: 'MWX', label: 'Muan', location: 'Piseo-ri (Muan) S. Korea' },
    { value: 'MWZ', label: 'Mwanza', location: 'Mwanza Tanzania, United Republic of' },
    { value: 'TMW', label: 'Tamworth', location: 'Tamworth Australia' },
    { value: 'MWA', label: 'Veterans of Southern Illinois', location: 'Marion, USA' },
  ],
  mx: [
    { value: 'MXP', label: 'Malpensa', location: 'Milan Italy' },
    { value: 'MMX', label: 'Malm\u00f6 Sturup', location: 'Malm\u00f6 Sweden' },
    { value: 'MXL', label: 'Rodolfo S\u00e1nchez Taboada', location: 'Mexicali Mexico' },
    { value: 'WMX', label: 'Wamena', location: 'Wamena-Papua Island Indonesia' },
    { value: 'SMX', label: 'Santa Maria Public', location: 'Santa Maria, USA' },
    { value: 'CMX', label: 'Houghton County Memorial', location: 'Hancock, USA' },
  ],
  my: [
    { value: 'MYJ', label: 'Matsuyama', location: 'Matsuyama Japan' },
    { value: 'MYR', label: 'Myrtle Beach', location: 'Myrtle Beach, USA' },
    { value: 'MYY', label: 'Miri', location: 'Miri Malaysia' },
    { value: 'MMY', label: 'Miyako', location: 'Miyako City Japan' },
  ],
  mz: [
    { value: 'MZG', label: 'Makung', location: 'Makung City, Taiwan' },
    { value: 'MZT', label: 'General Rafael Buelna', location: 'Mazatl\u00e1n Mexico' },
    { value: 'MZH', label: 'Amasya Merzifon', location: 'Amasya Turkey' },
    { value: 'MZL', label: 'La Nubia', location: 'Manizales Colombia' },
    { value: 'MZV', label: 'Mulu', location: 'Mulu Malaysia' },
  ],
  na: [
    { value: 'TNA', label: 'Yaoqiang', location: 'Jinan China' },
    { value: 'BNA', label: 'Nashville', location: 'Nashville, USA' },
    { value: 'SNA', label: 'John Wayne-Orange County', location: 'Santa Ana, USA' },
    { value: 'NAP', label: 'Naples', location: 'N\u00e1poli Italy' },
    { value: 'NAY', label: 'Beijing Nanyuan', location: 'Beijing China' },
    { value: 'NAS', label: 'Lynden Pindling', location: 'Nassau Bahamas' },
  ],
  nb: [
    { value: 'JNB', label: 'O.R. Tambo', location: 'Johannesburg South Africa' },
    { value: 'NBO', label: 'Jomo Kenyatta', location: 'Nairobi Kenya' },
    { value: 'NBC', label: 'Begishevo', location: 'Nizhnekamsk, Russia' },
    { value: 'GNB', label: 'Grenoble-Is\u00e8re', location: 'Grenoble/Saint-Geoirs France' },
    { value: 'RNB', label: 'Ronneby', location: 'Sweden' },
  ],
  nc: [
    { value: 'NCE', label: "Nice-C\u00f4te d'Azur", location: 'Nice France' },
    { value: 'INC', label: 'Yinchuan Hedong', location: 'Yinchuan China' },
    { value: 'NCL', label: 'Newcastle', location: 'Newcastle United Kingdom' },
    { value: 'ANC', label: 'Ted Stevens Anchorage', location: 'Anchorage, USA' },
    { value: 'FNC', label: 'Madeira', location: 'Funchal Portugal' },
  ],
  nd: [
    { value: 'HND', label: 'Tokyo Haneda', location: 'Tokyo Japan' },
    { value: 'IND', label: 'Indianapolis', location: 'Indianapolis, USA' },
    { value: 'BND', label: 'Bandar Abbas', location: 'Bandar Abbas, Iran' },
    { value: 'NDR', label: 'Nador', location: 'Nador Morocco' },
  ],
  ne: [
    { value: 'BNE', label: 'Brisbane', location: 'Brisbane Australia' },
    { value: 'ZNE', label: 'Newman', location: 'Newman Australia' },
    { value: 'ENE', label: 'Ende (H Hasan Aroeboesman)', location: 'Ende-Flores Island Indonesia' },
    { value: 'NER', label: 'Chulman', location: 'Neryungri, Russia' },
  ],
  nf: [
    { value: 'CNF', label: 'Tancredo Neves', location: 'Belo Horizonte Brazil' },
    { value: 'ANF', label: 'Andr\u00e9s Sabella G\u00e1lvez', location: 'Antofagasta Chile' },
  ],
  ng: [
    { value: 'NNG', label: 'Nanning Wuxu', location: 'Nanning China' },
    { value: 'NGB', label: 'Ningbo Lishe', location: 'Ningbo China' },
    { value: 'NGO', label: 'Chubu Centrair', location: 'Tokoname Japan' },
    { value: 'NGS', label: 'Nagasaki', location: 'Nagasaki Japan' },
    { value: 'JNG', label: 'Jining Qufu', location: 'Jining China' },
    { value: 'TNG', label: 'Ibn Batouta', location: 'Tangier Morocco' },
  ],
  nh: [
    { value: 'PNH', label: 'Phnom Penh', location: 'Phnom Penh Cambodia' },
    { value: 'KNH', label: 'Kinmen', location: 'Shang-I, Taiwan' },
    { value: 'ENH', label: 'Enshi', location: 'Enshi China' },
    { value: 'NHV', label: 'Nuku Hiva', location: 'French Polynesia' },
  ],
  ni: [
    { value: 'INI', label: 'Nis', location: 'Nis Serbia' },
    { value: 'BNI', label: 'Benin', location: 'Benin Nigeria' },
    { value: 'FNI', label: 'N\u00eemes-Arles-Camargue', location: 'N\u00eemes/Garons France' },
    { value: 'NIM', label: 'Diori Hamani', location: 'Niamey Niger' },
    { value: 'ANI', label: 'Aniak', location: 'Aniak, USA' },
  ],
  nj: [
    { value: 'YNJ', label: 'Yanji Chaoyangchuan', location: 'Yanji China' },
    { value: 'NJC', label: 'Nizhnevartovsk', location: 'Nizhnevartovsk, Russia' },
    {
      value: 'TNJ',
      label: 'Raja Haji Fisabilillah',
      location: 'Tanjung Pinang-Bintan Island Indonesia',
    },
  ],
  nk: [
    { value: 'NKG', label: 'Nanjing Lukou', location: 'Nanjing China' },
    { value: 'PNK', label: 'Supadio', location: 'Pontianak-Borneo Island Indonesia' },
    { value: 'NKM', label: 'Nagoya', location: 'Nagoya Japan' },
    { value: 'BNK', label: 'Ballina Byron Gateway', location: 'Ballina Australia' },
    {
      value: 'NKT',
      label: '\u015e\u0131rnak \u015eerafettin El\u00e7i',
      location: '\u015e\u0131rnak Turkey',
    },
    { value: 'LNK', label: 'Lincoln', location: 'Lincoln, USA' },
  ],
  nl: [
    { value: 'MNL', label: 'Ninoy Aquino', location: 'Manila Philippines' },
    { value: 'HNL', label: 'Daniel K Inouye', location: 'Honolulu, USA' },
    { value: 'NLK', label: 'Norfolk Island', location: 'Burnt Pine Norfolk Island' },
    { value: 'INL', label: 'International Falls', location: 'International Falls, USA' },
  ],
  nm: [{ value: 'NNM', label: 'Naryan Mar', location: 'Naryan Mar, Russia' }],
  nn: [
    { value: 'NNG', label: 'Nanning Wuxu', location: 'Nanning China' },
    { value: 'XNN', label: 'Xining Caojiabu', location: 'Xining China' },
    { value: 'SNN', label: 'Shannon', location: 'Shannon Ireland' },
    { value: 'INN', label: 'Innsbruck', location: 'Innsbruck Austria' },
    { value: 'NNY', label: 'Nanyang Jiangying', location: 'Nanyang China' },
    { value: 'TNN', label: 'Tainan', location: 'Tainan City, Taiwan' },
  ],
  no: [
    { value: 'KNO', label: 'Kualanamu', location: 'Indonesia' },
    { value: 'VNO', label: 'Vilnius', location: 'Vilnius Lithuania' },
    { value: 'RNO', label: 'Reno Tahoe', location: 'Reno, USA' },
    { value: 'NOC', label: 'Ireland West Knock', location: 'Charleston Ireland' },
    { value: 'NOU', label: 'La Tontouta', location: 'Noum\u00e9a New Caledonia' },
    { value: 'SNO', label: 'Sakon Nakhon', location: 'Thailand' },
  ],
  np: [
    { value: 'NPE', label: "Hawke's Bay", location: 'Napier New Zealand' },
    { value: 'NPL', label: 'New Plymouth', location: 'New Plymouth New Zealand' },
    { value: 'WNP', label: 'Naga', location: 'Naga Philippines' },
  ],
  nq: [
    { value: 'PNQ', label: 'Pune', location: 'Pune India' },
    { value: 'NQZ', label: 'Nur-Sultan (Astana)', location: 'Nur-Sultan Kazakhstan' },
    { value: 'NQN', label: 'Presidente Peron', location: 'Neuquen Argentina' },
    { value: 'NQY', label: 'Newquay Cornwall', location: 'Newquay United Kingdom' },
    { value: 'ONQ', label: 'Zonguldak', location: 'Zonguldak Turkey' },
  ],
  nr: [
    { value: 'NRT', label: 'Narita', location: 'Tokyo Japan' },
    { value: 'NRN', label: 'Weeze', location: 'Weeze Germany' },
    { value: 'TNR', label: 'Ivato', location: 'Antananarivo Madagascar' },
    { value: 'ANR', label: 'Antwerp (Deurne)', location: 'Antwerp Belgium' },
    {
      value: 'DNR',
      label: 'Dinard-Pleurtuit-Saint-Malo',
      location: 'Dinard/Pleurtuit/Saint-Malo France',
    },
    { value: 'NRK', label: 'Norrk\u00f6ping', location: 'Norrk\u00f6ping Sweden' },
  ],
  ns: [
    { value: 'CNS', label: 'Cairns', location: 'Cairns Australia' },
    { value: 'VNS', label: 'Lal Bahadur Shastri', location: 'Varanasi India' },
    { value: 'PNS', label: 'Pensacola Regional', location: 'Pensacola, USA' },
    { value: 'NST', label: 'Nakhon Si Thammarat', location: 'Nakhon Si Thammarat Thailand' },
    { value: 'NSN', label: 'Nelson', location: 'Nelson New Zealand' },
    { value: 'RNS', label: 'Rennes-Saint-Jacques', location: 'Rennes/Saint-Jacques France' },
  ],
  nt: [
    { value: 'YNT', label: 'Yantai Laishan', location: 'Yantai China' },
    { value: 'NTE', label: 'Nantes Atlantique', location: 'Nantes France' },
    { value: 'ONT', label: 'Ontario', location: 'Ontario, USA' },
    { value: 'NTG', label: 'Nantong', location: 'Nantong China' },
    { value: 'NTL', label: 'Newcastle', location: 'Williamtown Australia' },
    { value: 'FNT', label: 'Bishop', location: 'Flint, USA' },
  ],
  nu: [
    { value: 'NUE', label: 'Nuremberg', location: 'Nuremberg Germany' },
    { value: 'NUX', label: 'Novy Urengoy', location: 'Novy Urengoy, Russia' },
    { value: 'ANU', label: 'V.C. Bird', location: "St. John's Antigua and Barbuda" },
    { value: 'JNU', label: 'Juneau', location: 'Juneau, USA' },
    { value: 'ENU', label: 'Akanu Ibiam', location: 'Enegu Nigeria' },
  ],
  nv: [
    { value: 'NVT', label: 'Ministro Victor Konder', location: 'Navegantes Brazil' },
    { value: 'INV', label: 'Inverness', location: 'Inverness United Kingdom' },
    { value: 'GNV', label: 'Gainesville Regional', location: 'Gainesville, USA' },
    { value: 'NVA', label: 'Benito Salas', location: 'Neiva Colombia' },
  ],
  nw: [{ value: 'NWI', label: 'Norwich', location: 'Norwich United Kingdom' }],
  nx: [
    { value: 'CNX', label: 'Chiang Mai', location: 'Chiang Mai Thailand' },
    { value: 'KNX', label: 'Kununurra', location: 'Kununurra Australia' },
    { value: 'JNX', label: 'Naxos', location: 'Naxos Island Greece' },
    { value: 'ANX', label: 'And\u00f8ya', location: 'Andenes Norway' },
    { value: 'BNX', label: 'Banja Luka', location: 'Banja Luka Bosnia' },
  ],
  ny: [
    { value: 'NYC', label: 'New York City', location: 'New York, USA', isCity: true },
    { value: 'NYO', label: 'Stockholm Skavsta', location: 'Stockholm / Nyk\u00f6ping Sweden' },
    { value: 'NNY', label: 'Nanyang Jiangying', location: 'Nanyang China' },
    { value: 'GNY', label: '\u015eanl\u0131urfa GAP', location: '\u015eanl\u0131urfa Turkey' },
    { value: 'NYM', label: 'Nadym', location: 'Nadym, Russia' },
    { value: 'LNY', label: 'Lanai', location: 'Lanai City, USA' },
    { value: 'YNY', label: 'Yangyang', location: 'Gonghang-ro S. Korea' },
  ],
  nz: [
    { value: 'WNZ', label: 'Wenzhou Longwan', location: 'Wenzhou China' },
    { value: 'YNZ', label: 'Yancheng', location: 'Yancheng China' },
    { value: 'DNZ', label: '\u00c7ardak', location: 'Denizli Turkey' },
    {
      value: 'ZNZ',
      label: 'Abeid Amani Karume',
      location: 'Zanzibar Tanzania, United Republic of',
    },
    { value: 'PNZ', label: 'Senador Nilo Coelho', location: 'Petrolina Brazil' },
    { value: 'LNZ', label: 'Linz H\u00f6rsching', location: 'Linz Austria' },
  ],
  oa: [
    { value: 'OAK', label: 'Metropolitan Oakland', location: 'Oakland, USA' },
    { value: 'POA', label: 'Salgado Filho', location: 'Porto Alegre Brazil' },
    { value: 'KOA', label: 'Ellison Onizuka Kona', location: 'Kailua/Kona, USA' },
    { value: 'GOA', label: 'Genoa Cristoforo Colombo', location: 'Genova Italy' },
    { value: 'OAX', label: 'Xoxocotl\u00e1n', location: 'Oaxaca Mexico' },
    { value: 'OAJ', label: 'Albert J Ellis', location: 'Jacksonville, USA' },
  ],
  ob: [
    { value: 'OBO', label: 'Tokachi-Obihiro', location: 'Obihiro Japan' },
    { value: 'MOB', label: 'Mobile', location: 'Mobile, USA' },
    { value: 'BOB', label: 'Bora Bora', location: 'Motu Mute French Polynesia' },
    { value: 'ROB', label: 'Roberts', location: 'Monrovia Liberia' },
    { value: 'HOB', label: 'Lea County Regional', location: 'Hobbs, USA' },
    { value: 'SOB', label: 'H\u00e9v\u00edz-Balaton', location: 'S\u00e1rmell\u00e9k Hungary' },
  ],
  oc: [
    { value: 'FOC', label: 'Fuzhou Changle', location: 'Fuzhou China' },
    {
      value: 'SOC',
      label: 'Adi Sumarmo Wiryokusumo',
      location: 'Sukarata(Solo)-Java Island Indonesia',
    },
    { value: 'ROC', label: 'Greater Rochester', location: 'Rochester, USA' },
    { value: 'NOC', label: 'Ireland West Knock', location: 'Charleston Ireland' },
    { value: 'MOC', label: 'M\u00e1rio Ribeiro', location: 'Montes Claros Brazil' },
  ],
  od: [
    { value: 'BOD', label: 'Bordeaux-M\u00e9rignac', location: 'Bordeaux/M\u00e9rignac France' },
    { value: 'ODS', label: 'Odessa', location: 'Odessa Ukraine' },
    { value: 'COD', label: 'Yellowstone Regional', location: 'Cody, USA' },
  ],
  oe: [
    { value: 'KOE', label: 'El Tari', location: 'Kupang-Timor Island Indonesia' },
    { value: 'LOE', label: 'Loei', location: 'Thailand' },
    { value: 'JOE', label: 'Joensuu', location: 'Joensuu / Liperi Finland' },
    { value: 'OER', label: '\u00d6rnsk\u00f6ldsvik', location: '\u00d6rnsk\u00f6ldsvik Sweden' },
    { value: 'AOE', label: 'Anadolu', location: 'Eski\u015fehir Turkey' },
  ],
  of: [
    { value: 'SOF', label: 'Sofia', location: 'Sofia Bulgaria' },
    { value: 'TOF', label: 'Bogashevo', location: 'Tomsk, Russia' },
  ],
  og: [
    { value: 'BOG', label: 'El Dorado', location: 'Bogota Colombia' },
    { value: 'JOG', label: 'Adi Sutjipto', location: 'Yogyakarta-Java Island Indonesia' },
    { value: 'OGG', label: 'Kahului', location: 'Kahului, USA' },
    { value: 'OGU', label: 'Ordu Giresun', location: 'Ordu Turkey' },
    { value: 'VOG', label: 'Volgograd', location: 'Volgograd, Russia' },
    { value: 'OGZ', label: 'Beslan', location: 'Beslan, Russia' },
  ],
  oh: [
    { value: 'DOH', label: 'Hamad', location: 'Doha Qatar' },
    { value: 'EOH', label: 'Enrique Olaya Herrera', location: 'Medell\u00edn Colombia' },
    { value: 'BOH', label: 'Bournemouth', location: 'Bournemouth United Kingdom' },
    {
      value: 'OHD',
      label: 'Ohrid St. Paul the Apostle',
      location: 'Ohrid Macedonia, the former Yugoslav Republic of',
    },
  ],
  oi: [
    { value: 'GOI', label: 'Dabolim', location: 'Vasco da Gama India' },
    { value: 'BOI', label: 'Boise Gowen Field', location: 'Boise, USA' },
    { value: 'OIT', label: 'Oita', location: 'Oita Japan' },
    { value: 'JOI', label: 'Lauro Carneiro de Loyola', location: 'Joinville Brazil' },
    { value: 'AOI', label: 'Ancona Falconara', location: 'Ancona Italy' },
    { value: 'ROI', label: 'Roi Et', location: 'Thailand' },
  ],
  oj: [
    { value: 'KOJ', label: 'Kagoshima', location: 'Kagoshima Japan' },
    { value: 'BOJ', label: 'Burgas', location: 'Burgas Bulgaria' },
    { value: 'AOJ', label: 'Aomori', location: 'Aomori Japan' },
    {
      value: 'GOJ',
      label: 'Nizhny Novgorod Strigino',
      location: 'Nizhny Novgorod, Russia',
    },
    { value: 'NOJ', label: 'Noyabrsk', location: 'Noyabrsk, Russia' },
  ],
  ok: [
    { value: 'OKA', label: 'Naha', location: 'Naha Japan' },
    { value: 'COK', label: 'Cochin', location: 'Kochi India' },
    { value: 'OKC', label: 'Will Rogers', location: 'Oklahoma City, USA' },
    { value: 'OKJ', label: 'Okayama', location: 'Okayama City Japan' },
    { value: 'ROK', label: 'Rockhampton', location: 'Rockhampton Australia' },
    { value: 'OKD', label: 'Okadama', location: 'Sapporo Japan' },
  ],
  ol: [
    { value: 'OOL', label: 'Gold Coast', location: 'Gold Coast Australia' },
    { value: 'OLB', label: 'Olbia Costa Smeralda', location: 'Olbia (SS) Italy' },
    { value: 'MOL', label: 'Molde', location: '\u00c5r\u00f8 Norway' },
    { value: 'TOL', label: 'Toledo Express', location: 'Toledo, USA' },
    { value: 'DOL', label: 'Deauville-Saint-Gatien', location: 'Deauville France' },
    { value: 'YOL', label: 'Yola', location: 'Yola Nigeria' },
  ],
  om: [
    { value: 'BOM', label: 'Chhatrapati Shivaji', location: 'Mumbai India' },
    { value: 'OMA', label: 'Eppley Airfield', location: 'Omaha, USA' },
    { value: 'POM', label: 'Port Moresby Jacksons', location: 'Port Moresby Papua New Guinea' },
    { value: 'OMS', label: 'Omsk Central', location: 'Omsk, Russia' },
    { value: 'IOM', label: 'Isle of Man', location: 'Castletown Isle of Man' },
    { value: 'OMH', label: 'Urmia', location: 'Urmia, Iran' },
  ],
  on: [
    { value: 'ONT', label: 'Ontario', location: 'Ontario, USA' },
    { value: 'BON', label: 'Flamingo', location: 'Kralendijk Bonaire, Saint Eustatius and Saba' },
    { value: 'ONQ', label: 'Zonguldak', location: 'Zonguldak Turkey' },
  ],
  oo: [
    { value: 'OOL', label: 'Gold Coast', location: 'Gold Coast Australia' },
    { value: 'BOO', label: 'Bod\u00f8', location: 'Bod\u00f8 Norway' },
    { value: 'COO', label: 'Cadjehoun', location: 'Cotonou Benin' },
  ],
  op: [
    { value: 'OPO', label: 'Francisco de S\u00e1 Carneiro', location: 'Porto Portugal' },
    { value: 'LOP', label: 'Lombok', location: 'Mataram Indonesia' },
    { value: 'KOP', label: 'Nakhon Phanom', location: 'Thailand' },
    { value: 'POP', label: 'Gregorio Luperon', location: 'Puerto Plata Dominican Republic' },
    { value: 'NOP', label: 'Sinop', location: 'Sinop Turkey' },
    { value: 'ROP', label: 'Rota', location: 'Rota Island Northern Mariana Islands' },
  ],
  oq: [{ value: 'SOQ', label: 'Dominique Edward Osok', location: 'Sorong-Papua Island Indonesia' }],
  or: [
    { value: 'ORD', label: "Chicago O'Hare ", location: 'Chicago, USA' },
    { value: 'ORY', label: 'Paris-Orly', location: 'Paris France' },
    { value: 'FOR', label: 'Pinto Martins', location: 'Fortaleza Brazil' },
    { value: 'ORF', label: 'Norfolk', location: 'Norfolk, USA' },
    { value: 'ORK', label: 'Cork', location: 'Cork Ireland' },
    { value: 'COR', label: 'Ingeniero Ambrosio Taravella', location: 'Cordoba Argentina' },
  ],
  os: [
    { value: 'OSA', label: 'Osaka', location: 'Osaka Japan', isCity: true },
    { value: 'BOS', label: 'Boston Logan', location: 'Boston, USA' },
    { value: 'OSL', label: 'Oslo Gardermoen', location: 'Oslo Norway' },
    { value: 'LOS', label: 'Murtala Muhammed', location: 'Lagos Nigeria' },
    { value: 'POS', label: 'Piarco', location: 'Port of Spain Trinidad and Tobago' },
    { value: 'TOS', label: 'Troms\u00f8', location: 'Troms\u00f8 Norway' },
    { value: 'OSS', label: 'Osh', location: 'Osh Kyrgyzstan' },
  ],
  ot: [
    { value: 'OTP', label: 'Henri Coand\u0103', location: 'Bucharest Romania' },
    { value: 'GOT', label: 'Gothenburg-Landvetter', location: 'Gothenburg Sweden' },
    { value: 'MOT', label: 'Minot', location: 'Minot, USA' },
    { value: 'ROT', label: 'Rotorua Regional', location: 'Rotorua New Zealand' },
    { value: 'OTZ', label: 'Ralph Wien Memorial', location: 'Kotzebue, USA' },
    { value: 'OTH', label: 'Southwest Oregon Regional', location: 'North Bend, USA' },
  ],
  ou: [
    { value: 'HOU', label: 'William P Hobby', location: 'Houston, USA' },
    { value: 'SOU', label: 'Southampton', location: 'Southampton United Kingdom' },
    { value: 'OUL', label: 'Oulu', location: 'Oulu / Oulunsalo Finland' },
    { value: 'OUD', label: 'Angads', location: 'Oujda Morocco' },
    { value: 'NOU', label: 'La Tontouta', location: 'Noum\u00e9a New Caledonia' },
    { value: 'OUA', label: 'Ouagadougou', location: 'Ouagadougou Burkina Faso' },
  ],
  ov: [
    { value: 'OVB', label: 'Tolmachevo', location: 'Novosibirsk, Russia' },
    { value: 'ROV', label: 'Platov', location: 'Rostov-on-Don, Russia' },
    { value: 'OVD', label: 'Asturias', location: 'Ran\u00f3n Spain' },
    { value: 'MOV', label: 'Moranbah', location: 'Moranbah Australia' },
    { value: 'HOV', label: '\u00d8rsta-Volda, Hovden', location: '\u00d8rsta Norway' },
  ],
  ow: [
    { value: 'YOW', label: 'Ottawa Macdonald-Cartier', location: 'Ottawa Canada' },
    { value: 'KOW', label: 'Ganzhou', location: 'Ganzhou China' },
    { value: 'QOW', label: 'Sam Mbakwe', location: 'Owerri Nigeria' },
    { value: 'ROW', label: 'Roswell  Air Center', location: 'Roswell, USA' },
    { value: 'OWB', label: 'Owensboro Daviess County', location: 'Owensboro, USA' },
  ],
  ox: [],
  oy: [{ value: 'TOY', label: 'Toyama', location: 'Toyama Japan' }],
  oz: [
    { value: 'POZ', label: 'Pozna\u0144-\u0141awica', location: 'Pozna\u0144 Poland' },
    { value: 'VOZ', label: 'Voronezh', location: 'Voronezh, Russia' },
    { value: 'OZH', label: 'Zaporizhzhia', location: 'Zaporizhia Ukraine' },
    { value: 'OZC', label: 'Labo', location: 'Ozamiz City Philippines' },
    { value: 'NOZ', label: 'Spichenkovo', location: 'Novokuznetsk, Russia' },
    { value: 'MOZ', label: 'Moorea', location: 'French Polynesia' },
  ],
  pa: [
    { value: 'PAR', label: 'Paris', location: 'Paris France', isCity: true },
    { value: 'TPA', label: 'Tampa', location: 'Tampa, USA' },
    { value: 'LPA', label: 'Gran Canaria', location: 'Gran Canaria Island Spain' },
    { value: 'PAT', label: 'Lok Nayak Jayaprakash', location: 'Patna India' },
    { value: 'PAP', label: 'Toussaint Louverture', location: 'Port-au-Prince Haiti' },
    { value: 'JPA', label: 'Presidente Castro Pinto', location: 'Jo\u00e3o Pessoa Brazil' },
    { value: 'PAD', label: 'Paderborn Lippstadt', location: 'Paderborn Germany' },
  ],
  pb: [
    { value: 'PBI', label: 'Palm Beach', location: 'West Palm Beach, USA' },
    {
      value: 'LPB',
      label: 'El Alto',
      location: 'La Paz / El Alto, Bolivia',
    },
    { value: 'PBC', label: 'Hermanos Serd\u00e1n', location: 'Puebla Mexico' },
    { value: 'PBG', label: 'Plattsburgh', location: 'Plattsburgh, USA' },
    { value: 'PBO', label: 'Paraburdoo', location: 'Paraburdoo Australia' },
  ],
  pc: [
    { value: 'SPC', label: 'La Palma', location: 'Sta Cruz de la Palma, La Palma Island Spain' },
    { value: 'PCL', label: 'David Abenzur Rengifo', location: 'Pucallpa Peru' },
    { value: 'IPC', label: 'Mataveri', location: 'Isla De Pascua Chile' },
  ],
  pd: [
    { value: 'PDX', label: 'Portland', location: 'Portland, USA' },
    { value: 'PDG', label: 'Minangkabau', location: 'Ketaping/Padang - Sumatra Island Indonesia' },
    { value: 'PDL', label: 'Jo\u00e3o Paulo II', location: 'Ponta Delgada Portugal' },
    { value: 'PDP', label: 'Capitan Corbeta CA Curbelo', location: 'Punta del Este Uruguay' },
    { value: 'PDV', label: 'Plovdiv', location: 'Plovdiv Bulgaria' },
  ],
  pe: [
    { value: 'PEK', label: 'Beijing Capital', location: 'Beijing China' },
    { value: 'TPE', label: 'Taiwan Taoyuan', location: 'Taipei, Taiwan' },
    { value: 'PER', label: 'Perth', location: 'Perth Australia' },
    { value: 'PEN', label: 'Penang', location: 'Penang Malaysia' },
    { value: 'PEE', label: 'Bolshoye Savino', location: 'Perm, Russia' },
    { value: 'PEI', label: 'Mateca\u00f1a', location: 'Pereira Colombia' },
  ],
  pf: [{ value: 'PFO', label: 'Paphos', location: 'Paphos Cyprus' }],
  pg: [
    { value: 'UPG', label: 'Hasanuddin', location: 'Ujung Pandang-Celebes Island Indonesia' },
    {
      value: 'PGK',
      label: 'Pangkal Pinang (Depati Amir)',
      location: 'Pangkal Pinang-Palaubangka Island Indonesia',
    },
    { value: 'PGD', label: 'Charlotte County', location: 'Punta Gorda, USA' },
    { value: 'PGU', label: 'Persian Gulf', location: 'Asalouyeh, Iran' },
    { value: 'PGF', label: 'Perpignan-Rivesaltes', location: 'Perpignan/Rivesaltes France' },
    { value: 'PPG', label: 'Pago Pago', location: 'Pago Pago American Samoa' },
  ],
  ph: [
    { value: 'PHX', label: 'Phoenix Sky Harbor', location: 'Phoenix, USA' },
    { value: 'CPH', label: 'Copenhagen Kastrup', location: 'Copenhagen Denmark' },
    { value: 'PHL', label: 'Philadelphia', location: 'Philadelphia, USA' },
    { value: 'HPH', label: 'Cat Bi', location: 'Haiphong Viet Nam' },
    { value: 'MPH', label: 'Godofredo P. Ramos', location: 'Malay Philippines' },
    { value: 'PHC', label: 'Port Harcourt', location: 'Port Harcourt Nigeria' },
  ],
  pi: [
    { value: 'PIT', label: 'Pittsburgh', location: 'Pittsburgh, USA' },
    { value: 'PIE', label: 'St Petersburg Clearwater', location: 'St Petersburg-Clearwater, USA' },
    { value: 'PIU', label: 'Guillermo Concha Iberico', location: 'Piura Peru' },
    { value: 'PIK', label: 'Glasgow Prestwick', location: 'Glasgow United Kingdom' },
    { value: 'PIA', label: 'General Wayne A. Downing', location: 'Peoria, USA' },
    { value: 'SPI', label: 'Abraham Lincoln Capital', location: 'Springfield, USA' },
  ],
  pj: [],
  pk: [
    {
      value: 'PKU',
      label: 'Sultan Syarif Kasim II',
      location: 'Pekanbaru-Sumatra Island Indonesia',
    },
    { value: 'PKY', label: 'Tjilik Riwut', location: 'Palangkaraya-Kalimantan Tengah Indonesia' },
    { value: 'PKN', label: 'Iskandar', location: 'Pangkalanbun-Borneo Island Indonesia' },
    { value: 'PKC', label: 'Yelizovo', location: 'Petropavlovsk-Kamchatsky, Russia' },
  ],
  pl: [
    {
      value: 'PLM',
      label: 'Sultan Mahmud Badaruddin II',
      location: 'Palembang-Sumatra Island Indonesia',
    },
    { value: 'LPL', label: 'Liverpool John Lennon', location: 'Liverpool United Kingdom' },
    {
      value: 'MPL',
      label: 'Montpellier-M\u00e9diterran\u00e9e',
      location: 'Montpellier/M\u00e9diterran\u00e9e France',
    },
    { value: 'PLZ', label: 'Port Elizabeth', location: 'Port Elizabeth South Africa' },
    { value: 'PLW', label: 'Mutiara', location: 'Palu-Celebes Island Indonesia' },
    {
      value: 'PLS',
      label: 'Providenciales',
      location: 'Providenciales Island Turks and Caicos Islands',
    },
  ],
  pm: [
    { value: 'PMI', label: 'Palma De Mallorca', location: 'Palma De Mallorca Spain' },
    { value: 'PMO', label: 'Falcone\u2013Borsellino', location: 'Palermo Italy' },
    { value: 'PMC', label: 'El Tepual', location: 'Puerto Montt Chile' },
    { value: 'MPM', label: 'Maputo', location: 'Maputo Mozambique' },
    { value: 'PMR', label: 'Palmerston North', location: 'New Zealand' },
    { value: 'PMW', label: 'Brigadeiro Lysias Rodrigues', location: 'Palmas Brazil' },
  ],
  pn: [
    { value: 'PNQ', label: 'Pune', location: 'Pune India' },
    {
      value: 'BPN',
      label: 'Sultan Aji Muhamad Sulaiman',
      location: 'Kotamadya Balikpapan Indonesia',
    },
    { value: 'PNK', label: 'Supadio', location: 'Pontianak-Borneo Island Indonesia' },
    { value: 'PNH', label: 'Phnom Penh', location: 'Phnom Penh Cambodia' },
    { value: 'PNS', label: 'Pensacola Regional', location: 'Pensacola, USA' },
    { value: 'HPN', label: 'Westchester County', location: 'White Plains, USA' },
  ],
  po: [
    { value: 'OPO', label: 'Francisco de S\u00e1 Carneiro', location: 'Porto Portugal' },
    { value: 'POA', label: 'Salgado Filho', location: 'Porto Alegre Brazil' },
    { value: 'POS', label: 'Piarco', location: 'Port of Spain Trinidad and Tobago' },
    { value: 'POZ', label: 'Pozna\u0144-\u0141awica', location: 'Pozna\u0144 Poland' },
    { value: 'POM', label: 'Port Moresby Jacksons', location: 'Port Moresby Papua New Guinea' },
    { value: 'CPO', label: 'Desierto de Atacama', location: 'Copiapo Chile' },
  ],
  pp: [
    { value: 'PPS', label: 'Puerto Princesa', location: 'Puerto Princesa City Philippines' },
    { value: 'PPT', label: "Faa'a ", location: 'Papeete French Polynesia' },
    { value: 'TPP', label: 'Guillermo Del Castillo Paredes', location: 'Tarapoto Peru' },
    { value: 'PPP', label: 'Proserpine Whitsunday Coast', location: 'Proserpine Australia' },
    { value: 'PPG', label: 'Pago Pago', location: 'Pago Pago American Samoa' },
  ],
  pq: [
    { value: 'PQC', label: 'Phu Quoc', location: 'Phu Quoc Island Viet Nam' },
    { value: 'PQQ', label: 'Port Macquarie', location: 'Port Macquarie Australia' },
    { value: 'TPQ', label: 'Amado Nervo National', location: 'Tepic Mexico' },
    { value: 'PQI', label: 'Presque Isle', location: 'Presque Isle, USA' },
  ],
  pr: [
    { value: 'PRG', label: 'V\u00e1clav Havel Prague', location: 'Prague Czech Republic' },
    { value: 'PRN', label: 'Pri\u0161tina', location: 'Prishtina Kosovo' },
    { value: 'RPR', label: 'Raipur Swami Vivekananda', location: 'Raipur India' },
    { value: 'CPR', label: 'Casper-Natrona County', location: 'Casper, USA' },
    { value: 'PRI', label: 'Praslin', location: 'Praslin Island Seychelles' },
    { value: 'PRH', label: 'Phrae', location: 'Thailand' },
  ],
  ps: [
    { value: 'DPS', label: 'Ngurah Rai (Bali)', location: 'Denpasar-Bali Island Indonesia' },
    { value: 'PSA', label: 'Pisa', location: 'Pisa Italy' },
    { value: 'PSP', label: 'Palm Springs', location: 'Palm Springs, USA' },
    { value: 'PPS', label: 'Puerto Princesa', location: 'Puerto Princesa City Philippines' },
    { value: 'TPS', label: 'Vincenzo Florio Trapani-Birgi', location: 'Trapani (TP) Italy' },
    { value: 'GPS', label: 'Seymour', location: 'Baltra Ecuador' },
  ],
  pt: [
    { value: 'PTY', label: 'Panama City-Tocumen', location: 'Tocumen Panama' },
    { value: 'CPT', label: 'Cape Town', location: 'Cape Town South Africa' },
    {
      value: 'PTP',
      label: 'Pointe-\u00e0-Pitre Le Raizet',
      location: 'Pointe-\u00e0-Pitre Guadeloupe',
    },
    { value: 'PPT', label: "Faa'a", location: 'Papeete French Polynesia' },
    { value: 'GPT', label: 'Gulfport Biloxi', location: 'Gulfport, USA' },
    { value: 'LPT', label: 'Lampang', location: 'Thailand' },
  ],
  pu: [
    { value: 'PUS', label: 'Gimhae', location: 'Busan S. Korea' },
    { value: 'PUJ', label: 'Punta Cana', location: 'Punta Cana Dominican Republic' },
    { value: 'SPU', label: 'Split', location: 'Split Croatia' },
    { value: 'PUQ', label: 'Carlos Iba\u00f1ez del Campo', location: 'Punta Arenas Chile' },
    { value: 'PUY', label: 'Pula', location: 'Pula Croatia' },
    {
      value: 'PUF',
      label: 'Pau Pyr\u00e9n\u00e9es',
      location: 'Pau/Pyr\u00e9n\u00e9es (Uzein) France',
    },
  ],
  pv: [
    { value: 'PVG', label: 'Shanghai Pudong', location: 'Shanghai China' },
    {
      value: 'PVR',
      label: 'Licenciado Gustavo D\u00edaz Ordaz',
      location: 'Puerto Vallarta Mexico',
    },
    { value: 'PVD', label: 'Theodore Francis Green', location: 'Providence, USA' },
    { value: 'PVH', label: 'Jorge Teixeira de Oliveira', location: 'Porto Velho Brazil' },
    { value: 'PVK', label: 'Aktion National', location: 'Preveza/Lefkada Greece' },
    { value: 'CPV', label: 'Presidente Jo\u00e3o Suassuna', location: 'Campina Grande Brazil' },
  ],
  pw: [{ value: 'PWM', label: 'Portland Jetport', location: 'Portland, USA' }],
  px: [
    { value: 'PXM', label: 'Puerto Escondido', location: 'Puerto Escondido Mexico' },
    { value: 'PXU', label: 'Pleiku', location: 'Pleiku Viet Nam' },
  ],
  py: [],
  pz: [],
  qa: [],
  qb: [{ value: 'YQB', label: 'Quebec Jean Lesage', location: 'Quebec Canada' }],
  qc: [{ value: 'PQC', label: 'Phu Quoc', location: 'Phu Quoc Island Viet Nam' }],
  qd: [],
  qe: [],
  qf: [{ value: 'MQF', label: 'Magnitogorsk', location: 'Magnitogorsk, Russia' }],
  qg: [],
  qh: [],
  qi: [{ value: 'PQI', label: 'Presque Isle', location: 'Presque Isle, USA' }],
  qj: [],
  qk: [{ value: 'BQK', label: 'Brunswick Golden Isles', location: 'Brunswick, USA' }],
  ql: [{ value: 'MQL', label: 'Mildura', location: 'Mildura Australia' }],
  qm: [
    { value: 'YQM', label: 'Greater Moncton', location: 'Moncton Canada' },
    { value: 'MQM', label: 'Mardin', location: 'Mardin Turkey' },
  ],
  qn: [
    { value: 'ZQN', label: 'Queenstown', location: 'Queenstown New Zealand' },
    { value: 'NQN', label: 'Presidente Peron', location: 'Neuquen Argentina' },
    { value: 'BQN', label: 'Rafael Hernandez', location: 'Aguadilla Puerto Rico' },
    { value: 'MQN', label: 'Mo i Rana, R\u00f8ssvoll', location: 'Mo i Rana Norway' },
  ],
  qo: [{ value: 'QOW', label: 'Sam Mbakwe', location: 'Owerri Nigeria' }],
  qp: [
    { value: 'AQP', label: 'Rodr\u00edguez Ball\u00f3n', location: 'Arequipa Peru' },
    { value: 'MQP', label: 'Kruger Mpumalanga', location: 'Mpumalanga South Africa' },
  ],
  qq: [
    { value: 'IQQ', label: 'Diego Aracena', location: 'Iquique Chile' },
    { value: 'YQQ', label: 'Comox', location: 'Comox Canada' },
    { value: 'PQQ', label: 'Port Macquarie', location: 'Port Macquarie Australia' },
  ],
  qr: [
    { value: 'YQR', label: 'Regina', location: 'Regina Canada' },
    { value: 'QRO', label: 'Quer\u00e9taro', location: 'Quer\u00e9taro Mexico' },
    { value: 'QRW', label: 'Warri', location: 'Warri Nigeria' },
  ],
  qs: [
    { value: 'BQS', label: 'Ignatyevo', location: 'Blagoveschensk, Russia' },
    { value: 'VQS', label: 'Antonio Rivera Rodriguez', location: 'Vieques Island Puerto Rico' },
  ],
  qt: [
    { value: 'IQT', label: 'Francisco Secada Vignetta', location: 'Iquitos Peru' },
    { value: 'YQT', label: 'Thunder Bay', location: 'Thunder Bay Canada' },
    { value: 'MQT', label: 'Sawyer', location: 'Marquette, USA' },
  ],
  qu: [{ value: 'YQU', label: 'Grande Prairie', location: 'Grande Prairie Canada' }],
  qv: [],
  qw: [],
  qx: [{ value: 'MQX', label: 'Mekele', location: 'Ethiopia' }],
  qy: [{ value: 'NQY', label: 'Newquay Cornwall', location: 'Newquay United Kingdom' }],
  qz: [{ value: 'NQZ', label: 'Nur-Sultan (Astana)', location: 'Nur-Sultan Kazakhstan' }],
  ra: [
    { value: 'FRA', label: 'Frankfurt am Main', location: 'Frankfurt am Main Germany' },
    { value: 'RAK', label: 'Menara', location: 'Marrakech Morocco' },
    { value: 'VRA', label: 'Juan Gualberto Gomez', location: 'Varadero Cuba' },
    { value: 'RAP', label: 'Rapid City', location: 'Rapid City, USA' },
    { value: 'RAI', label: 'Praia', location: 'Praia Cape Verde' },
    { value: 'CRA', label: 'Craiova', location: 'Craiova Romania' },
  ],
  rb: [
    { value: 'HRB', label: 'Taiping', location: 'Harbin China' },
    { value: 'RBA', label: 'Rabat-Sal\u00e9', location: 'Rabat Morocco' },
    { value: 'GRB', label: 'Austin Straubel', location: 'Green Bay, USA' },
    { value: 'RBR', label: 'Pl\u00e1cido de Castro', location: 'Rio Branco Brazil' },
    { value: 'ORB', label: '\u00d6rebro', location: '\u00d6rebro Sweden' },
  ],
  rc: [
    { value: 'URC', label: '\u00dcr\u00fcmqi Diwopu', location: '\u00dcr\u00fcmqi China' },
    {
      value: 'BRC',
      label: 'San Carlos De Bariloche',
      location: 'San Carlos de Bariloche Argentina',
    },
    { value: 'TRC', label: 'Francisco Sarabia', location: 'Torre\u00f3n Mexico' },
    { value: 'ERC', label: 'Erzincan', location: 'Erzincan Turkey' },
    { value: 'VRC', label: 'Virac', location: 'Virac Philippines' },
  ],
  rd: [
    { value: 'ORD', label: "Chicago O'Hare", location: 'Chicago, USA' },
    { value: 'RDU', label: 'Raleigh Durham', location: 'Raleigh/Durham, USA' },
    { value: 'TRD', label: 'Trondheim V\u00e6rnes', location: 'Trondheim Norway' },
    { value: 'CRD', label: 'General E. Mosconi', location: 'Comodoro Rivadavia Argentina' },
    { value: 'RDM', label: 'Roberts Field', location: 'Redmond, USA' },
    { value: 'LRD', label: 'Laredo', location: 'Laredo, USA' },
  ],
  re: [
    { value: 'REC', label: 'Recife/Guararapes-Gilberto Freyre', location: 'Recife Brazil' },
    { value: 'REP', label: 'Siem Reap', location: 'Siem Reap Cambodia' },
    { value: 'BRE', label: 'Bremen', location: 'Bremen Germany' },
    { value: 'REU', label: 'Reus Air Base', location: 'Reus Spain' },
    { value: 'REN', label: 'Orenburg Central', location: 'Orenburg, Russia' },
    { value: 'HRE', label: 'Robert Gabriel Mugabe', location: 'Harare Zimbabwe' },
  ],
  rf: [
    { value: 'ORF', label: 'Norfolk', location: 'Norfolk, USA' },
    { value: 'TRF', label: 'Sandefjord, Torp', location: 'Torp Norway' },
    { value: 'ERF', label: 'Erfurt', location: 'Erfurt Germany' },
    { value: 'RFP', label: 'Raiatea', location: 'Uturoa French Polynesia' },
    { value: 'RFD', label: 'Chicago Rockford', location: 'Chicago/Rockford, USA' },
  ],
  rg: [
    { value: 'PRG', label: 'V\u00e1clav Havel Prague', location: 'Prague Czech Republic' },
    { value: 'RGN', label: 'Yangon', location: 'Yangon Myanmar' },
    { value: 'SRG', label: 'Achmad Yani', location: 'Semarang-Java Island Indonesia' },
    { value: 'HRG', label: 'Hurghada', location: 'Hurghada Egypt' },
    { value: 'TRG', label: 'Tauranga', location: 'Tauranga New Zealand' },
    { value: 'RGL', label: 'Piloto Civil N. Fern\u00e1ndez', location: 'Rio Gallegos Argentina' },
  ],
  rh: [
    { value: 'ZRH', label: 'Z\u00fcrich', location: 'Zurich Switzerland' },
    { value: 'RHO', label: 'Diagoras', location: 'Rhodes Island Greece' },
    { value: 'ARH', label: 'Talagi', location: 'Archangelsk, Russia' },
    {
      value: 'LRH',
      label: 'La Rochelle-\u00cele de R\u00e9',
      location: 'La Rochelle/\u00cele de R\u00e9 France',
    },
    { value: 'ORH', label: 'Worcester Regional', location: 'Worcester, USA' },
    { value: 'PRH', label: 'Phrae', location: 'Thailand' },
  ],
  ri: [
    { value: 'RIO', label: 'Rio de Janeiro', location: 'Rio de Janeiro Brazil', isCity: true },
    { value: 'RIX', label: 'Riga', location: 'Riga Latvia' },
    { value: 'BRI', label: 'Bari Karol Wojty\u0142a', location: 'Bari Italy' },
    { value: 'RIC', label: 'Richmond', location: 'Richmond, USA' },
    { value: 'ARI', label: 'Chacalluta', location: 'Arica Chile' },
    {
      value: 'TRI',
      label: 'Tri-Cities Regional TN/VA',
      location: 'Bristol/Johnson/Kingsport, USA',
    },
    { value: 'ERI', label: 'Erie Tom Ridge Field', location: 'Erie, USA' },
  ],
  rj: [
    { value: 'GRJ', label: 'George', location: 'George South Africa' },
    { value: 'RJA', label: 'Rajahmundry', location: 'Rajahmundry India' },
    { value: 'RJK', label: 'Rijeka', location: 'Rijeka Croatia' },
  ],
  rk: [
    { value: 'KRK', label: 'Krak\u00f3w John Paul II', location: 'Krak\u00f3w Poland' },
    { value: 'ORK', label: 'Cork', location: 'Cork Ireland' },
    { value: 'CRK', label: 'Diosdado Macapagal', location: 'Angeles/Mabalacat Philippines' },
    { value: 'TRK', label: 'Juwata', location: 'Tarakan Island Indonesia' },
    { value: 'HRK', label: 'Kharkiv', location: 'Kharkiv Ukraine' },
    { value: 'RKV', label: 'Reykjavik', location: 'Reykjavik Iceland' },
  ],
  rl: [
    { value: 'CRL', label: 'Brussels South Charleroi', location: 'Brussels Belgium' },
    { value: 'KRL', label: 'Korla', location: 'Korla China' },
    { value: 'HRL', label: 'Valley', location: 'Harlingen, USA' },
    { value: 'RLG', label: 'Rostock-Laage', location: 'Rostock Germany' },
  ],
  rm: [
    {
      value: 'RMQ',
      label: 'Taichung Ching Chuang Kang',
      location: 'Taichung City, Taiwan',
    },
    { value: 'RMF', label: 'Marsa Alam', location: 'Marsa Alam Egypt' },
    { value: 'ARM', label: 'Armidale', location: 'Armidale Australia' },
    { value: 'LRM', label: 'Casa De Campo', location: 'La Romana Dominican Republic' },
    { value: 'CRM', label: 'Catarman', location: 'Catarman Philippines' },
    { value: 'HRM', label: "Hassi R'Mel", location: 'Algeria' },
  ],
  rn: [
    { value: 'ARN', label: 'Stockholm-Arlanda', location: 'Stockholm Sweden' },
    { value: 'TRN', label: 'Turin', location: 'Torino Italy' },
    { value: 'RNO', label: 'Reno Tahoe', location: 'Reno, USA' },
    { value: 'VRN', label: 'Verona Villafranca', location: 'Verona Italy' },
    { value: 'PRN', label: 'Pri\u0161tina', location: 'Prishtina Kosovo' },
    { value: 'ORN', label: 'Es Senia', location: 'Oran Algeria' },
  ],
  ro: [
    { value: 'ROM', label: 'Rome', location: 'Rome Italy', isCity: true },
    { value: 'ROV', label: 'Platov', location: 'Rostov-on-Don, Russia' },
    { value: 'WRO', label: 'Copernicus Wroc\u0142aw', location: 'Wroc\u0142aw Poland' },
    { value: 'ROC', label: 'Greater Rochester', location: 'Rochester, USA' },
    { value: 'GRO', label: 'Girona', location: 'Girona Spain' },
    { value: 'QRO', label: 'Quer\u00e9taro', location: 'Quer\u00e9taro Mexico' },
    { value: 'ROK', label: 'Rockhampton', location: 'Rockhampton Australia' },
  ],
  rp: [
    { value: 'RPR', label: 'Raipur Swami Vivekananda', location: 'Raipur India' },
    { value: 'CRP', label: 'Corpus Christi', location: 'Corpus Christi, USA' },
    { value: 'KRP', label: 'Karup', location: 'Karup Denmark' },
    { value: 'SRP', label: 'Stord', location: 'Leirvik Norway' },
  ],
  rq: [
    { value: 'SRQ', label: 'Sarasota Bradenton', location: 'Sarasota/Bradenton, USA' },
    { value: 'BRQ', label: 'Brno-Tu\u0159any', location: 'Brno Czech Republic' },
    { value: 'GRQ', label: 'Eelde', location: 'Groningen Netherlands' },
  ],
  rr: [
    { value: 'KRR', label: 'Krasnodar Pashkovsky', location: 'Krasnodar, Russia' },
    { value: 'GRR', label: 'Gerald R. Ford', location: 'Grand Rapids, USA' },
    { value: 'NRR', label: 'Jos\u00e9 Aponte de la Torre', location: 'Ceiba Puerto Rico' },
    { value: 'RRS', label: 'R\u00f8ros', location: 'R\u00f8ros Norway' },
  ],
  rs: [
    { value: 'MRS', label: 'Marseille Provence', location: 'Marseille France' },
    { value: 'RSW', label: 'Southwest Florida', location: 'Fort Myers, USA' },
    { value: 'BRS', label: 'Bristol', location: 'Bristol United Kingdom' },
    { value: 'DRS', label: 'Dresden', location: 'Dresden Germany' },
    { value: 'KRS', label: 'Kristiansand', location: 'Kjevik Norway' },
    { value: 'TRS', label: 'Trieste\u2013Friuli Venezia Giulia', location: 'Trieste Italy' },
  ],
  rt: [
    { value: 'NRT', label: 'Narita', location: 'Tokyo Japan' },
    { value: 'KRT', label: 'Khartoum', location: 'Khartoum Sudan' },
    { value: 'URT', label: 'Surat Thani', location: 'Surat Thani Thailand' },
    { value: 'RTM', label: 'Rotterdam/The Hague', location: 'Rotterdam Netherlands' },
    { value: 'RTW', label: 'Saratov Central', location: 'Saratov, Russia' },
    { value: 'RTB', label: 'Juan Manuel Galvez', location: 'Roatan Island Honduras' },
  ],
  ru: [
    { value: 'GRU', label: 'S\u00e3o Paulo-Guarulhos', location: 'S\u00e3o Paulo Brazil' },
    { value: 'RUH', label: 'King Khaled', location: 'Riyadh Saudi Arabia' },
    { value: 'BRU', label: 'Brussels', location: 'Brussels Belgium' },
    { value: 'MRU', label: 'Sir Seewoosagur Ramgoolam', location: 'Port Louis Mauritius' },
    { value: 'RUN', label: 'Roland Garros', location: 'St Denis R\u00e9union' },
    { value: 'FRU', label: 'Manas', location: 'Bishkek Kyrgyzstan' },
  ],
  rv: [
    { value: 'TRV', label: 'Trivandrum', location: 'Thiruvananthapuram India' },
    { value: 'MRV', label: 'Mineralnyye Vody', location: 'Mineralnyye Vody, Russia' },
    { value: 'RVN', label: 'Rovaniemi', location: 'Rovaniemi Finland' },
    { value: 'GRV', label: 'Grozny North', location: 'Grozny, Russia' },
    { value: 'RVK', label: 'R\u00f8rvik, Ryum', location: 'R\u00f8rvik Norway' },
  ],
  rw: [
    { value: 'DRW', label: 'Darwin', location: 'Darwin Australia' },
    { value: 'CRW', label: 'Yeager', location: 'Charleston, USA' },
    { value: 'QRW', label: 'Warri', location: 'Warri Nigeria' },
    { value: 'BRW', label: 'Wiley Post-Will Rogers', location: 'Utqia\u0121vik, USA' },
  ],
  rx: [
    { value: 'GRX', label: 'Federico Garcia Lorca', location: 'Granada Spain' },
    { value: 'RXS', label: 'Roxas', location: 'Roxas City Philippines' },
  ],
  ry: [
    { value: 'ORY', label: 'Paris-Orly', location: 'Paris France' },
    { value: 'RYG', label: 'Moss, Rygge', location: 'Oslo Norway' },
    { value: 'XRY', label: 'Jerez', location: 'Jerez de la Forntera Spain' },
    { value: 'MRY', label: 'Monterey Peninsula', location: 'Monterey, USA' },
    { value: 'SRY', label: 'Dasht-e Naz', location: 'Sari, Iran' },
  ],
  rz: [
    { value: 'TRZ', label: 'Tiruchirapally', location: 'Tiruchirappally India' },
    { value: 'ERZ', label: 'Erzurum', location: 'Erzurum Turkey' },
    { value: 'GRZ', label: 'Graz', location: 'Graz Austria' },
    { value: 'RZE', label: 'Rzesz\u00f3w-Jasionka', location: 'Rzesz\u00f3w Poland' },
  ],
  sa: [
    { value: 'SAO', label: 'Sao Paulo', location: 'Sao Paulo Brazil', isCity: true },
    { value: 'SAW', label: 'Sabiha G\u00f6k\u00e7en', location: 'Istanbul Turkey' },
    { value: 'SAN', label: 'San Diego', location: 'San Diego, USA' },
    { value: 'SAT', label: 'San Antonio', location: 'San Antonio, USA' },
    { value: 'SSA', label: 'Luiz Eduardo Magalh\u00e3es', location: 'Salvador Brazil' },
    { value: 'TSA', label: 'Taipei Songshan', location: 'Taipei City, Taiwan' },
    { value: 'PSA', label: 'Pisa', location: 'Pisa Italy' },
  ],
  sb: [
    {
      value: 'BSB',
      label: 'Bras\u00edlia-Juscelino Kubistschek',
      location: 'Bras\u00edlia Brazil',
    },
    { value: 'ESB', label: 'Esenbo\u011fa', location: 'Ankara Turkey' },
    { value: 'ISB', label: 'New Islamabad', location: 'Islamabad Pakistan' },
    { value: 'ASB', label: 'Ashgabat', location: 'Ashgabat Turkmenistan' },
    { value: 'SBW', label: 'Sibu', location: 'Sibu Malaysia' },
    { value: 'SBN', label: 'South Bend', location: 'South Bend, USA' },
  ],
  sc: [
    { value: 'SCL', label: 'Arturo Merino Ben\u00edtez', location: 'Santiago Chile' },
    { value: 'SCQ', label: 'Santiago de Compostela', location: 'Santiago de Compostela Spain' },
    { value: 'SCO', label: 'Aktau', location: 'Aktau Kazakhstan' },
    { value: 'FSC', label: 'Figari Sud-Corse', location: 'Figari Sud-Corse France' },
    { value: 'LSC', label: 'La Florida', location: 'La Serena-Coquimbo Chile' },
    { value: 'PSC', label: 'Tri Cities', location: 'Pasco, USA' },
  ],
  sd: [
    { value: 'SDU', label: 'Santos Dumont', location: 'Rio De Janeiro Brazil' },
    { value: 'SDF', label: 'Louisville Standiford Field', location: 'Louisville, USA' },
    { value: 'SDJ', label: 'Sendai', location: 'Sendai Japan' },
    { value: 'SDQ', label: 'Las Am\u00e9ricas', location: 'Santo Domingo Dominican Republic' },
    { value: 'BSD', label: 'Baoshan Yunduan', location: 'China' },
    { value: 'SDR', label: 'Santander', location: 'Santander Spain' },
  ],
  se: [
    { value: 'SEA', label: 'Seattle Tacoma', location: 'Seattle, USA' },
    { value: 'SEL', label: 'Seoul', location: 'Seoul S. Korea', isCity: true },
    { value: 'SEN', label: 'Southend', location: 'Southend United Kingdom' },
    { value: 'SEZ', label: 'Seychelles', location: 'Mahe Island Seychelles' },
    { value: 'ASE', label: 'Aspen-Pitkin Co/Sardy Field', location: 'Aspen, USA' },
    { value: 'PSE', label: 'Mercedita', location: 'Ponce Puerto Rico' },
    { value: 'ISE', label: 'S\u00fcleyman Demirel', location: 'Isparta Turkey' },
  ],
  sf: [
    { value: 'SFO', label: 'San Francisco', location: 'San Francisco, USA' },
    { value: 'TSF', label: "Treviso-Sant'Angelo", location: 'Treviso Italy' },
    { value: 'SFB', label: 'Orlando Sanford', location: 'Orlando, USA' },
    { value: 'ASF', label: 'Astrakhan', location: 'Astrakhan, Russia' },
    { value: 'SFT', label: 'Skellefte\u00e5', location: 'Skellefte\u00e5 Sweden' },
    { value: 'SFG', label: "L'Esp\u00e9rance", location: 'Grand Case Saint Martin (French part)' },
  ],
  sg: [
    { value: 'SGN', label: 'Tan Son Nhat', location: 'Ho Chi Minh City Viet Nam' },
    { value: 'ISG', label: 'New Ishigaki', location: 'Ishigaki Japan' },
    { value: 'SGC', label: 'Surgut', location: 'Surgut, Russia' },
    { value: 'SGF', label: 'Springfield Branson National', location: 'Springfield, USA' },
    { value: 'HSG', label: 'Saga', location: 'Saga Japan' },
    { value: 'SSG', label: 'Malabo', location: 'Malabo Equatorial Guinea' },
  ],
  sh: [
    { value: 'SHA', label: 'Shanghai Hongqiao', location: 'Shanghai China' },
    { value: 'SHE', label: 'Taoxian', location: 'Shenyang China' },
    { value: 'SHJ', label: 'Sharjah', location: 'Sharjah, UAE' },
    { value: 'SSH', label: 'Sharm El Sheikh', location: 'Sharm el-Sheikh Egypt' },
    { value: 'USH', label: 'Malvinas Argentinas', location: 'Ushuahia Argentina' },
    {
      value: 'KSH',
      label: 'Shahid Ashrafi Esfahani',
      location: 'Kermanshah, Iran',
    },
  ],
  si: [
    { value: 'SIN', label: 'Singapore Changi', location: 'Singapore Singapore' },
    { value: 'SIP', label: 'Simferopol', location: 'Simferopol Ukraine' },
    { value: 'SID', label: 'Am\u00edlcar Cabral', location: 'Espargos Cape Verde' },
    { value: 'JSI', label: 'Skiathos Island National', location: 'Skiathos Greece' },
    { value: 'LSI', label: 'Sumburgh', location: 'Lerwick United Kingdom' },
    { value: 'NSI', label: 'Yaound\u00e9 Nsimalen', location: 'Yaound\u00e9 Cameroon' },
  ],
  sj: [
    { value: 'SJC', label: 'Norman Y. Mineta San Jose', location: 'San Jose, USA' },
    { value: 'SJW', label: 'Shijiazhuang Daguocun', location: 'Shijiazhuang China' },
    { value: 'SJU', label: 'Luis Munoz Marin', location: 'San Juan Puerto Rico' },
    { value: 'SJO', label: 'Juan Santamaria', location: 'San Jose Costa Rica' },
    { value: 'SJD', label: 'Los Cabos', location: 'San Jos\u00e9 del Cabo Mexico' },
    { value: 'SJJ', label: 'Sarajevo', location: 'Sarajevo Bosnia' },
  ],
  sk: [
    { value: 'SKG', label: 'Thessaloniki Macedonia', location: 'Thessaloniki Greece' },
    {
      value: 'SKP',
      label: 'Skopje Alexander the Great',
      location: 'Skopje Macedonia, the former Yugoslav Republic of',
    },
    { value: 'SKT', label: 'Sialkot', location: 'Sialkot Pakistan' },
    { value: 'NSK', label: 'Norilsk-Alykel', location: 'Norilsk, Russia' },
    { value: 'SKB', label: 'Robert L. Bradshaw', location: 'Basseterre Saint Kitts and Nevis' },
    { value: 'USK', label: 'Usinsk', location: 'Usinsk, Russia' },
  ],
  sl: [
    { value: 'OSL', label: 'Oslo Gardermoen', location: 'Oslo Norway' },
    { value: 'SLC', label: 'Salt Lake City', location: 'Salt Lake City, USA' },
    { value: 'BSL', label: 'Basel-Mulhouse-Freiburg', location: 'B\u00e2le/Mulhouse France' },
    { value: 'SLZ', label: 'Marechal Cunha Machado', location: 'S\u00e3o Lu\u00eds Brazil' },
    { value: 'SLL', label: 'Salalah', location: 'Salalah Oman' },
    { value: 'SLA', label: 'Martin Miguel De Guemes', location: 'Salta Argentina' },
  ],
  sm: [
    { value: 'SMF', label: 'Sacramento', location: 'Sacramento, USA' },
    { value: 'USM', label: 'Samui', location: 'Na Thon (Ko Samui Island) Thailand' },
    { value: 'DSM', label: 'Des Moines', location: 'Des Moines, USA' },
    { value: 'SMR', label: 'Sim\u00f3n Bol\u00edvar', location: 'Santa Marta Colombia' },
    { value: 'GSM', label: 'Qeshm', location: 'Qeshm, Iran' },
    { value: 'SMI', label: 'Samos', location: 'Samos Island Greece' },
  ],
  sn: [
    { value: 'TSN', label: 'Tianjin Binhai', location: 'Tianjin China' },
    { value: 'SNA', label: 'John Wayne-Orange County', location: 'Santa Ana, USA' },
    { value: 'DSN', label: 'Ordos Ejin Horo', location: 'Ordos China' },
    { value: 'MSN', label: 'Dane County Truax Field', location: 'Madison, USA' },
    { value: 'SNN', label: 'Shannon', location: 'Shannon Ireland' },
    { value: 'HSN', label: 'Zhoushan', location: 'Zhoushan China' },
  ],
  so: [
    { value: 'SOF', label: 'Sofia', location: 'Sofia Bulgaria' },
    {
      value: 'SOC',
      label: 'Adi Sumarmo Wiryokusumo',
      location: 'Sukarata(Solo)-Java Island Indonesia',
    },
    { value: 'SOU', label: 'Southampton', location: 'Southampton United Kingdom' },
    { value: 'GSO', label: 'Piedmont Triad', location: 'Greensboro, USA' },
    { value: 'SOQ', label: 'Dominique Edward Osok', location: 'Sorong-Papua Island Indonesia' },
    { value: 'MSO', label: 'Missoula', location: 'Missoula, USA' },
  ],
  sp: [
    { value: 'SPK', label: 'Sapporo', location: 'Sapporo Japan', isCity: true },
    { value: 'MSP', label: 'Minneapolis-St Paul', location: 'Minneapolis, USA' },
    { value: 'SPU', label: 'Split', location: 'Split Croatia' },
    { value: 'GSP', label: 'Greenville Spartanburg', location: 'Greenville, USA' },
    { value: 'PSP', label: 'Palm Springs', location: 'Palm Springs, USA' },
    { value: 'ISP', label: 'Long Island Mac Arthur', location: 'Islip, USA' },
    { value: 'SPC', label: 'La Palma', location: 'Sta Cruz de la Palma, La Palma Island Spain' },
  ],
  sq: [{ value: 'MSQ', label: 'Minsk National', location: 'Minsk Belarus' }],
  sr: [
    { value: 'SRG', label: 'Achmad Yani', location: 'Semarang-Java Island Indonesia' },
    { value: 'ASR', label: 'Kayseri Erkilet', location: 'Kayseri Turkey' },
    { value: 'TSR', label: 'Timi\u015foara Traian Vuia', location: 'Timi\u015foara Romania' },
    { value: 'SRQ', label: 'Sarasota Bradenton', location: 'Sarasota/Bradenton, USA' },
    { value: 'PSR', label: 'Pescara', location: 'Pescara Italy' },
    { value: 'MSR', label: 'Mu\u015f', location: 'Mu\u015f Turkey' },
  ],
  ss: [
    { value: 'SSA', label: 'Luiz Eduardo Magalh\u00e3es', location: 'Salvador Brazil' },
    { value: 'SSH', label: 'Sharm El Sheikh', location: 'Sharm el-Sheikh Egypt' },
    { value: 'OSS', label: 'Osh', location: 'Osh Kyrgyzstan' },
    { value: 'SSG', label: 'Malabo', location: 'Malabo Equatorial Guinea' },
    { value: 'PSS', label: 'General Jose De San Martin', location: 'Posadas Argentina' },
    { value: 'SSJ', label: 'Sandnessj\u00f8en (Stokka)', location: 'Alstahaug Norway' },
  ],
  st: [
    { value: 'STO', label: 'Stockholm', location: 'Stockholm Sweden', isCity: true },
    { value: 'IST', label: 'Istanbul', location: 'Istanbul Turkey' },
    { value: 'STN', label: 'London Stansted', location: 'London United Kingdom' },
    { value: 'STL', label: 'St Louis Lambert', location: 'St Louis, USA' },
    { value: 'STR', label: 'Stuttgart', location: 'Stuttgart Germany' },
    { value: 'NST', label: 'Nakhon Si Thammarat', location: 'Nakhon Si Thammarat Thailand' },
    { value: 'LST', label: 'Launceston', location: 'Launceston Australia' },
  ],
  su: [
    { value: 'SUB', label: 'Juanda', location: 'Surabaya Indonesia' },
    { value: 'SUF', label: 'Lamezia Terme', location: 'Lamezia Terme (CZ) Italy' },
    { value: 'ASU', label: 'Silvio Pettirossi', location: 'Asunci\u00f3n Paraguay' },
    { value: 'RSU', label: 'Yeosu', location: 'Yeosu S. Korea' },
    { value: 'USU', label: 'Francisco B. Reyes', location: 'Coron Philippines' },
    { value: 'KSU', label: 'Kristiansund', location: 'Kvernberget Norway' },
  ],
  sv: [
    { value: 'SVO', label: 'Sheremetyevo', location: 'Moscow, Russia' },
    { value: 'SVQ', label: 'Sevilla', location: 'Sevilla Spain' },
    { value: 'SVX', label: 'Koltsovo', location: 'Yekaterinburg, Russia' },
    { value: 'SVG', label: 'Stavanger Sola', location: 'Stavanger Norway' },
    { value: 'TSV', label: 'Townsville', location: 'Townsville Australia' },
    { value: 'HSV', label: 'Huntsville Carl T Jones Field', location: 'Huntsville, USA' },
  ],
  sw: [
    { value: 'RSW', label: 'Southwest Florida', location: 'Fort Myers, USA' },
    { value: 'SWA', label: 'Jieyang Chaoshan', location: 'Shantou China' },
    { value: 'SWF', label: 'Stewart', location: 'Newburgh, USA' },
    { value: 'ASW', label: 'Aswan', location: 'Aswan Egypt' },
  ],
  sx: [
    { value: 'CSX', label: 'Changsha Huanghua', location: 'Changsha China' },
    { value: 'SXF', label: 'Berlin-Sch\u00f6nefeld', location: 'Berlin Germany' },
    { value: 'SXR', label: 'Sheikh ul Alam', location: 'Srinagar India' },
    {
      value: 'SXM',
      label: 'Princess Juliana',
      location: 'Saint Martin Sint Maarten (Dutch part)',
    },
    { value: 'SXB', label: 'Strasbourg', location: 'Strasbourg France' },
    { value: 'SXZ', label: 'Siirt', location: 'Siirt Turkey' },
  ],
  sy: [
    { value: 'SYD', label: 'Sydney Kingsford Smith', location: 'Sydney Australia' },
    { value: 'SYX', label: 'Sanya Phoenix', location: 'Sanya China' },
    { value: 'MSY', label: 'Louis Armstrong New Orleans', location: 'New Orleans, USA' },
    {
      value: 'SYZ',
      label: 'Shiraz Shahid Dastghaib',
      location: 'Shiraz, Iran',
    },
    { value: 'SYR', label: 'Syracuse Hancock', location: 'Syracuse, USA' },
    { value: 'KSY', label: 'Kars', location: 'Kars Turkey' },
  ],
  sz: [
    { value: 'SZX', label: "Shenzhen Bao'an ", location: 'Shenzhen China' },
    { value: 'SZB', label: 'Sultan Abdul Aziz Shah', location: 'Subang Malaysia' },
    { value: 'SZG', label: 'Salzburg', location: 'Salzburg Austria' },
    { value: 'SZF', label: 'Samsun \u00c7ar\u015famba', location: 'Samsun Turkey' },
    { value: 'FSZ', label: 'Mt. Fuji Shizuoka', location: 'Makinohara / Shimada Japan' },
    {
      value: 'SZZ',
      label: 'Szczecin-Goleni\u00f3w Solidarno\u015b\u0107',
      location: 'Goleniow Poland',
    },
  ],
  ta: [
    { value: 'TAO', label: 'Liuting', location: 'Qingdao China' },
    { value: 'CTA', label: 'Catania-Fontanarossa', location: 'Catania Italy' },
    { value: 'TAE', label: 'Daegu', location: 'Daegu S. Korea' },
    { value: 'TAS', label: 'Tashkent', location: 'Tashkent Uzbekistan' },
    { value: 'TAK', label: 'Takamatsu', location: 'Takamatsu Japan' },
    { value: 'TAG', label: 'Tagbilaran', location: 'Tagbilaran City Philippines' },
  ],
  tb: [
    { value: 'TBS', label: 'Tbilisi', location: 'Tbilisi Georgia' },
    { value: 'TBZ', label: 'Tabriz', location: 'Tabriz, Iran' },
    { value: 'RTB', label: 'Juan Manuel Galvez', location: 'Roatan Island Honduras' },
    { value: 'TBP', label: 'Pedro Canga Rodriguez', location: 'Tumbes Peru' },
    { value: 'WTB', label: 'Toowoomba Wellcamp', location: 'Toowoomba Australia' },
    { value: 'TBB', label: 'Dong Tac', location: 'Tuy Hoa Viet Nam' },
  ],
  tc: [
    { value: 'TCI', label: 'Tenerife', location: 'Tenerife Spain', isCity: true },
    { value: 'TCZ', label: 'Tengchong Tuofeng', location: 'Tengchong China' },
    { value: 'TCQ', label: 'Carlos Ciriani Santa Rosa', location: 'Tacna Peru' },
    { value: 'STC', label: 'St Cloud', location: 'St Cloud, USA' },
  ],
  td: [
    { value: 'TDX', label: 'Trat', location: 'Thailand' },
    { value: 'TDG', label: 'Tandag', location: 'Philippines' },
  ],
  te: [
    { value: 'NTE', label: 'Nantes Atlantique', location: 'Nantes France' },
    { value: 'TEN', label: 'Tongren Fenghuang', location: 'China' },
    { value: 'TTE', label: 'Sultan Khairun Babullah', location: 'Sango-Ternate Island Indonesia' },
    { value: 'FTE', label: 'El Calafate', location: 'El Calafate Argentina' },
    { value: 'TEQ', label: 'Tekirda\u011f \u00c7orlu', location: '\u00c7orlu Turkey' },
  ],
  tf: [
    { value: 'TFS', label: 'Tenerife South', location: 'Tenerife Island Spain' },
    { value: 'TFN', label: 'Tenerife Norte', location: 'Tenerife Island Spain' },
    { value: 'GTF', label: 'Great Falls', location: 'Great Falls, USA' },
    { value: 'TFU', label: 'Chengdu Tianfu', location: 'Chengdu China' },
  ],
  tg: [
    { value: 'CTG', label: 'Rafael Nu\u00f1ez', location: 'Cartagena Colombia' },
    { value: 'NTG', label: 'Nantong', location: 'Nantong China' },
    { value: 'TGZ', label: 'Angel Albino Corzo', location: 'Tuxtla Guti\u00e9rrez Mexico' },
    { value: 'TGD', label: 'Podgorica', location: 'Podgorica Montenegro' },
    { value: 'TGO', label: 'Tongliao', location: 'Tongliao China' },
    { value: 'TGG', label: 'Sultan Mahmud', location: 'Kuala Terengganu Malaysia' },
  ],
  th: [
    { value: 'ATH', label: 'Eleftherios Venizelos', location: 'Athens Greece' },
    { value: 'THR', label: 'Mehrabad', location: 'Tehran, Iran' },
    { value: 'BTH', label: 'Hang Nadim', location: 'Batam Island Indonesia' },
    { value: 'UTH', label: 'Udon Thani', location: 'Udon Thani Thailand' },
    { value: 'ZTH', label: 'Zakynthos Dionysios Solomos', location: 'Zakynthos Island Greece' },
    { value: 'THE', label: 'Senador Petr\u00f4nio Portela', location: 'Teresina Brazil' },
  ],
  ti: [
    { value: 'TIJ', label: 'General Abelardo L. Rodr\u00edguez', location: 'Tijuana Mexico' },
    { value: 'TIP', label: 'Tripoli', location: 'Tripoli Libyan Arab Jamahiriya' },
    { value: 'TIA', label: 'Tirana Mother Teresa', location: 'Tirana Albania' },
    { value: 'TIV', label: 'Tivat', location: 'Tivat Montenegro' },
    { value: 'STI', label: 'Cibao', location: 'Santiago Dominican Republic' },
    { value: 'TIR', label: 'Tirupati', location: 'Tirupati India' },
  ],
  tj: [
    { value: 'TJM', label: 'Roshchino', location: 'Tyumen, Russia' },
    { value: 'BTJ', label: 'Sultan Iskandar Muda', location: 'Banda Aceh Indonesia' },
    {
      value: 'TJQ',
      label: 'H.A.S. Hanandjoeddin',
      location: 'Tanjung Pandan-Belitung Island Indonesia',
    },
    { value: 'TTJ', label: 'Tottori', location: 'Tottori Japan' },
    { value: 'MTJ', label: 'Montrose Regional', location: 'Montrose, USA' },
  ],
  tk: [
    {
      value: 'TKG',
      label: 'Radin Inten II (Branti)',
      location: 'Bandar Lampung-Sumatra Island Indonesia',
    },
    { value: 'TKS', label: 'Tokushima', location: 'Tokushima Japan' },
    { value: 'TKU', label: 'Turku', location: 'Turku Finland' },
    { value: 'BTK', label: 'Bratsk', location: 'Bratsk, Russia' },
  ],
  tl: [
    { value: 'ATL', label: 'Atlanta Hartsfield-Jackson', location: 'Atlanta, USA' },
    { value: 'TLV', label: 'Ben Gurion', location: 'Tel Aviv Israel' },
    { value: 'STL', label: 'St Louis Lambert', location: 'St Louis, USA' },
    { value: 'TLS', label: 'Toulouse-Blagnac', location: 'Toulouse/Blagnac France' },
    { value: 'TLL', label: 'Lennart Meri Tallinn', location: 'Tallinn Estonia' },
    { value: 'NTL', label: 'Newcastle', location: 'Williamtown Australia' },
  ],
  tm: [
    { value: 'ITM', label: 'Osaka', location: 'Osaka Japan' },
    { value: 'KTM', label: 'Tribhuvan', location: 'Kathmandu Nepal' },
    { value: 'DTM', label: 'Dortmund', location: 'Dortmund Germany' },
    { value: 'RTM', label: 'Rotterdam/The Hague', location: 'Rotterdam Netherlands' },
    { value: 'STM', label: 'Maestro Wilson Fonseca', location: 'Santar\u00e9m Brazil' },
    { value: 'CTM', label: 'Chetumal', location: 'Chetumal Mexico' },
  ],
  tn: [
    { value: 'STN', label: 'London Stansted', location: 'London United Kingdom' },
    { value: 'LTN', label: 'London Luton', location: 'London United Kingdom' },
    { value: 'TNA', label: 'Yaoqiang', location: 'Jinan China' },
    { value: 'HTN', label: 'Hotan', location: 'Hotan China' },
    { value: 'TNG', label: 'Ibn Batouta', location: 'Tangier Morocco' },
    { value: 'TNR', label: 'Ivato', location: 'Antananarivo Madagascar' },
  ],
  to: [
    { value: 'TOS', label: 'Troms\u00f8', location: 'Troms\u00f8 Norway' },
    { value: 'ITO', label: 'Hilo', location: 'Hilo, USA' },
    { value: 'GTO', label: 'Jalaluddin', location: 'Gorontalo-Celebes Island Indonesia' },
    { value: 'TOF', label: 'Bogashevo', location: 'Tomsk, Russia' },
    { value: 'TOY', label: 'Toyama', location: 'Toyama Japan' },
    { value: 'TOL', label: 'Toledo Express', location: 'Toledo, USA' },
  ],
  tp: [
    { value: 'TPE', label: 'Taiwan Taoyuan', location: 'Taipei, Taiwan' },
    { value: 'TPA', label: 'Tampa', location: 'Tampa, USA' },
    { value: 'OTP', label: 'Henri Coand\u0103', location: 'Bucharest Romania' },
    {
      value: 'PTP',
      label: 'Pointe-\u00e0-Pitre Le Raizet',
      location: 'Pointe-\u00e0-Pitre Guadeloupe',
    },
    { value: 'UTP', label: 'U-Tapao', location: 'Rayong Thailand' },
    { value: 'TPS', label: 'Vincenzo Florio Trapani-Birgi', location: 'Trapani (TP) Italy' },
  ],
  tq: [{ value: 'ATQ', label: 'Sri Guru Ram Dass Jee', location: 'Amritsar India' }],
  tr: [
    { value: 'STR', label: 'Stuttgart', location: 'Stuttgart Germany' },
    { value: 'TRD', label: 'Trondheim V\u00e6rnes', location: 'Trondheim Norway' },
    { value: 'TRV', label: 'Trivandrum', location: 'Thiruvananthapuram India' },
    { value: 'TRN', label: 'Turin', location: 'Torino Italy' },
    { value: 'JTR', label: 'Santorini', location: 'Santorini Island Greece' },
    { value: 'TRF', label: 'Sandefjord, Torp', location: 'Torp Norway' },
  ],
  ts: [
    { value: 'CTS', label: 'New Chitose', location: 'Chitose / Tomakomai Japan' },
    { value: 'TSN', label: 'Tianjin Binhai', location: 'Tianjin China' },
    { value: 'TSA', label: 'Taipei Songshan', location: 'Taipei City, Taiwan' },
    { value: 'TSF', label: "Treviso-Sant'Angelo", location: 'Treviso Italy' },
    { value: 'BTS', label: 'M. R. \u0160tef\u00e1nik', location: 'Bratislava Slovakia' },
    { value: 'TSR', label: 'Timi\u015foara Traian Vuia', location: 'Timi\u015foara Romania' },
  ],
  tt: [
    {
      value: 'STT',
      label: 'Cyril E. King',
      location: 'Charlotte Amalie, Harry S. Truman Airport Virgin Islands, U.S.',
    },
    { value: 'TTE', label: 'Sultan Khairun Babullah', location: 'Sango-Ternate Island Indonesia' },
    { value: 'TTJ', label: 'Tottori', location: 'Tottori Japan' },
    { value: 'KTT', label: 'Kittil\u00e4', location: 'Kittil\u00e4 Finland' },
    { value: 'TTT', label: 'Taitung', location: 'Taitung City, Taiwan' },
    { value: 'TTN', label: 'Trenton Mercer', location: 'Trenton, USA' },
  ],
  tu: [
    { value: 'CTU', label: 'Chengdu Shuangliu', location: 'Chengdu China' },
    { value: 'TUN', label: 'Tunis Carthage', location: 'Tunis Tunisia' },
    { value: 'TUS', label: 'Tucson', location: 'Tucson, USA' },
    { value: 'TUL', label: 'Tulsa', location: 'Tulsa, USA' },
    { value: 'BTU', label: 'Bintulu', location: 'Bintulu Malaysia' },
    { value: 'TFU', label: 'Chengdu Tianfu', location: 'Chengdu China' },
    {
      value: 'TUC',
      label: 'Teniente Benjamin Matienzo',
      location: 'San Miguel de Tucum\u00e1n Argentina',
    },
  ],
  tv: [
    { value: 'BTV', label: 'Burlington', location: 'Burlington, USA' },
    { value: 'STV', label: 'Surat', location: 'India' },
    { value: 'TVC', label: 'Cherry Capital', location: 'Traverse City, USA' },
  ],
  tw: [
    { value: 'DTW', label: 'Detroit', location: 'Detroit, USA' },
    { value: 'KTW', label: 'Katowice', location: 'Katowice Poland' },
    { value: 'TWU', label: 'Tawau', location: 'Tawau Malaysia' },
    { value: 'ATW', label: 'Appleton', location: 'Appleton, USA' },
    { value: 'RTW', label: 'Saratov Central', location: 'Saratov, Russia' },
    { value: 'STW', label: 'Stavropol Shpakovskoye', location: 'Stavropol, Russia' },
  ],
  tx: [
    { value: 'TXN', label: 'Tunxi', location: 'Huangshan China' },
    { value: 'STX', label: 'Henry E Rohlsen', location: 'Christiansted Virgin Islands, U.S.' },
    { value: 'TXK', label: 'Texarkana Webb Field', location: 'Texarkana, USA' },
  ],
  ty: [
    { value: 'TYO', label: 'Tokyo', location: 'Tokyo Japan', isCity: true },
    { value: 'PTY', label: 'Tocumen', location: 'Tocumen Panama' },
    { value: 'TYN', label: 'Taiyuan Wusu', location: 'Taiyuan China' },
    { value: 'MTY', label: 'General Mariano Escobedo', location: 'Monterrey Mexico' },
    { value: 'YTY', label: 'Yangzhou Taizhou', location: 'Yangzhou and Taizhou China' },
    { value: 'TYS', label: 'McGhee Tyson', location: 'Knoxville, USA' },
    { value: 'HTY', label: 'Hatay', location: 'Hatay Turkey' },
  ],
  tz: [
    { value: 'TZX', label: 'Trabzon', location: 'Trabzon Turkey' },
    { value: 'YTZ', label: 'Billy Bishop Toronto', location: 'Toronto Canada' },
    { value: 'VTZ', label: 'Vishakhapatnam', location: 'Visakhapatnam India' },
    { value: 'TZL', label: 'Tuzla', location: 'Tuzla Bosnia' },
    { value: 'ETZ', label: 'Metz-Nancy-Lorraine', location: 'Metz / Nancy France' },
    { value: 'OTZ', label: 'Ralph Wien Memorial', location: 'Kotzebue, USA' },
  ],
  ua: [
    { value: 'GUA', label: 'La Aurora', location: 'Guatemala City Guatemala' },
    { value: 'AUA', label: 'Queen Beatrix', location: 'Oranjestad Aruba' },
    { value: 'UAQ', label: 'Domingo Faustino Sarmiento', location: 'San Juan Argentina' },
    { value: 'OUA', label: 'Ouagadougou', location: 'Ouagadougou Burkina Faso' },
    { value: 'KUA', label: 'Kuantan', location: 'Kuantan Malaysia' },
    { value: 'UAK', label: 'Narsarsuaq', location: 'Narsarsuaq Greenland' },
  ],
  ub: [
    { value: 'DUB', label: 'Dublin', location: 'Dublin Ireland' },
    { value: 'SUB', label: 'Juanda', location: 'Surabaya Indonesia' },
    { value: 'UBP', label: 'Ubon Ratchathani', location: 'Ubon Ratchathani Thailand' },
    { value: 'UBJ', label: 'Yamaguchi Ube', location: 'Ube Japan' },
    { value: 'MUB', label: 'Maun', location: 'Maun Botswana' },
    { value: 'UBA', label: 'Uberaba', location: 'Uberaba Brazil' },
    { value: 'UBN', label: 'Chinggis Khaan', location: 'Ulaanbaatar Mongolia' },
  ],
  uc: [
    { value: 'MUC', label: 'Munich', location: 'Munich Germany' },
    { value: 'CUC', label: 'Camilo Daza', location: 'C\u00facuta Colombia' },
    {
      value: 'TUC',
      label: 'Teniente Benjamin Matienzo',
      location: 'San Miguel de Tucum\u00e1n Argentina',
    },
    { value: 'GUC', label: 'Gunnison-Crested Butte', location: 'Gunnison, USA' },
  ],
  ud: [
    { value: 'BUD', label: 'Budapest Ferenc Liszt', location: 'Budapest Hungary' },
    { value: 'UDR', label: 'Maharana Pratap', location: 'Udaipur India' },
    { value: 'UDI', label: 'Aviador C\u00e9sar Bombonato', location: 'Uberl\u00e2ndia Brazil' },
    { value: 'DUD', label: 'Dunedin', location: 'Dunedin New Zealand' },
    { value: 'OUD', label: 'Angads', location: 'Oujda Morocco' },
    { value: 'UUD', label: 'Ulan-Ude (Mukhino)', location: 'Ulan Ude, Russia' },
  ],
  ue: [
    { value: 'FUE', label: 'Fuerteventura', location: 'Fuerteventura Island Spain' },
    { value: 'NUE', label: 'Nuremberg', location: 'Nuremberg Germany' },
    { value: 'CUE', label: 'Mariscal Lamar', location: 'Cuenca Ecuador' },
    { value: 'UET', label: 'Quetta', location: 'Quetta Pakistan' },
    { value: 'UEO', label: 'Kumejima', location: 'Japan' },
  ],
  uf: [
    { value: 'BUF', label: 'Buffalo Niagara', location: 'Buffalo, USA' },
    { value: 'UFA', label: 'Ufa', location: 'Ufa, Russia' },
    { value: 'KUF', label: 'Kurumoch', location: 'Samara, Russia' },
    { value: 'SUF', label: 'Lamezia Terme', location: 'Lamezia Terme (CZ) Italy' },
    {
      value: 'PUF',
      label: 'Pau Pyr\u00e9n\u00e9es',
      location: 'Pau/Pyr\u00e9n\u00e9es (Uzein) France',
    },
    {
      value: 'TUF',
      label: 'Tours-Val-de-Loire',
      location: 'Tours/Val de Loire (Loire Valley) France',
    },
  ],
  ug: [
    { value: 'EUG', label: 'Mahlon Sweet Field', location: 'Eugene, USA' },
    { value: 'TUG', label: 'Tuguegarao', location: 'Tuguegarao City Philippines' },
    { value: 'SUG', label: 'Surigao', location: 'Surigao City Philippines' },
  ],
  uh: [
    { value: 'RUH', label: 'King Khaled', location: 'Riyadh Saudi Arabia' },
    { value: 'WUH', label: 'Wuhan Tianhe', location: 'Wuhan China' },
    { value: 'AUH', label: 'Abu Dhabi', location: 'Abu Dhabi, UAE' },
    { value: 'ZUH', label: 'Zhuhai Jinwan', location: 'Zhuhai China' },
    { value: 'KUH', label: 'Kushiro', location: 'Kushiro Japan' },
    { value: 'HUH', label: 'Huahine-Fare', location: 'Fare French Polynesia' },
  ],
  ui: [
    { value: 'UIO', label: 'Mariscal Sucre', location: 'Quito Ecuador' },
    { value: 'HUI', label: 'Phu Bai', location: 'Hue Viet Nam' },
    { value: 'UIH', label: 'Phu Cat', location: 'Quy Nohn Viet Nam' },
    { value: 'UIB', label: 'El Cara\u00f1o', location: 'Quibd\u00f3 Colombia' },
    { value: 'UIP', label: 'Quimper-Cornouaille', location: 'Quimper/Pluguffan France' },
    { value: 'UIN', label: 'Quincy Regional Baldwin Field', location: 'Quincy, USA' },
  ],
  uj: [
    { value: 'PUJ', label: 'Punta Cana', location: 'Punta Cana Dominican Republic' },
    {
      value: 'JUJ',
      label: 'Gobernador Horacio Guzman',
      location: 'San Salvador de Jujuy Argentina',
    },
  ],
  uk: [
    { value: 'FUK', label: 'Fukuoka', location: 'Fukuoka Japan' },
    { value: 'UKB', label: 'Kobe', location: 'Kobe Japan' },
  ],
  ul: [
    { value: 'KUL', label: 'Kuala Lumpur', location: 'Kuala Lumpur Malaysia' },
    { value: 'YUL', label: 'Montreal Pierre Trudeau', location: 'Montr\u00e9al Canada' },
    { value: 'TUL', label: 'Tulsa', location: 'Tulsa, USA' },
    { value: 'CUL', label: 'Bachigualato Federal', location: 'Culiac\u00e1n Mexico' },
    { value: 'OUL', label: 'Oulu', location: 'Oulu / Oulunsalo Finland' },
    { value: 'ULN', label: 'Chinggis Khaan', location: 'Ulan Bator Mongolia' },
  ],
  um: [
    {
      value: 'GUM',
      label: 'Antonio B. Won Pat',
      location: 'Hag\u00e5t\u00f1a, Guam International Airport Guam',
    },
    { value: 'LUM', label: 'Mangshi', location: 'Luxi China' },
    { value: 'UME', label: 'Ume\u00e5', location: 'Ume\u00e5 Sweden' },
    { value: 'YUM', label: 'Yuma', location: 'Yuma, USA' },
  ],
  un: [
    { value: 'CUN', label: 'Canc\u00fan', location: 'Canc\u00fan Mexico' },
    { value: 'TUN', label: 'Tunis Carthage', location: 'Tunis Tunisia' },
    { value: 'RUN', label: 'Roland Garros', location: 'St Denis R\u00e9union' },
    { value: 'KUN', label: 'Kaunas', location: 'Kaunas Lithuania' },
    { value: 'LUN', label: 'Kenneth Kaunda Lusaka', location: 'Lusaka Zambia' },
    { value: 'HUN', label: 'Hualien', location: 'Hualien City, Taiwan' },
  ],
  uo: [{ value: 'KUO', label: 'Kuopio', location: 'Kuopio / Siilinj\u00e4rvi Finland' }],
  up: [
    { value: 'UPG', label: 'Hasanuddin', location: 'Ujung Pandang-Celebes Island Indonesia' },
    { value: 'VUP', label: 'Alfonso L\u00f3pez Pumarejo', location: 'Valledupar Colombia' },
    { value: 'UPN', label: 'Urapan', location: 'Mexico' },
  ],
  uq: [
    { value: 'PUQ', label: 'Carlos Iba\u00f1ez del Campo', location: 'Punta Arenas Chile' },
    { value: 'AUQ', label: 'Hiva Oa-Atuona', location: 'Hiva Oa Island French Polynesia' },
  ],
  ur: [
    { value: 'URC', label: '\u00dcr\u00fcmqi Diwopu', location: '\u00dcr\u00fcmqi China' },
    { value: 'DUR', label: 'King Shaka', location: 'Durban South Africa' },
    { value: 'BUR', label: 'Bob Hope', location: 'Burbank, USA' },
    { value: 'URT', label: 'Surat Thani', location: 'Surat Thani Thailand' },
    { value: 'CUR', label: 'Hato', location: 'Willemstad Cura\u00e7ao' },
    { value: 'AUR', label: 'Aurillac', location: 'Aurillac France' },
  ],
  us: [
    { value: 'DUS', label: 'D\u00fcsseldorf', location: 'D\u00fcsseldorf Germany' },
    { value: 'PUS', label: 'Gimhae', location: 'Busan S. Korea' },
    { value: 'AUS', label: 'Austin Bergstrom', location: 'Austin, USA' },
    { value: 'TUS', label: 'Tucson', location: 'Tucson, USA' },
    { value: 'USM', label: 'Samui', location: 'Na Thon (Ko Samui Island) Thailand' },
    { value: 'UUS', label: 'Yuzhno-Sakhalinsk', location: 'Yuzhno-Sakhalinsk, Russia' },
  ],
  ut: [
    { value: 'UTH', label: 'Udon Thani', location: 'Udon Thani Thailand' },
    { value: 'UTP', label: 'U-Tapao', location: 'Rayong Thailand' },
    { value: 'KUT', label: 'Kopitnari', location: 'Kutaisi Georgia' },
    { value: 'DUT', label: 'Unalaska', location: 'Unalaska, USA' },
  ],
  uu: [
    { value: 'CUU', label: 'Roberto Fierro Villalobos', location: 'Chihuahua Mexico' },
    { value: 'UUS', label: 'Yuzhno-Sakhalinsk', location: 'Yuzhno-Sakhalinsk, Russia' },
    { value: 'UUD', label: 'Ulan-Ude (Mukhino)', location: 'Ulan Ude, Russia' },
  ],
  uv: [
    { value: 'UVF', label: 'Hewanorra', location: 'Vieux Fort Saint Lucia' },
    { value: 'UVE', label: 'Ouv\u00e9a', location: 'Ouv\u00e9a New Caledonia' },
  ],
  uw: [
    { value: 'BUW', label: 'Betoambari', location: 'Bau Bau-Butung Island Indonesia' },
    { value: 'PUW', label: 'Pullman Moscow Regional', location: 'Pullman/Moscow, USA' },
  ],
  ux: [
    { value: 'WUX', label: 'Sunan Shuofang', location: 'Wuxi China' },
    { value: 'LUX', label: 'Luxembourg-Findel', location: 'Luxembourg Luxembourg' },
    { value: 'NUX', label: 'Novy Urengoy', location: 'Novy Urengoy, Russia' },
    { value: 'HUX', label: 'Bah\u00edas de Huatulco', location: 'Huatulco Mexico' },
    { value: 'MUX', label: 'Multan', location: 'Multan Pakistan' },
    { value: 'SUX', label: 'Sioux Gateway', location: 'Sioux City, USA' },
  ],
  uy: [
    { value: 'UYN', label: 'Yulin Yuyang', location: 'Yulin China' },
    { value: 'PUY', label: 'Pula', location: 'Pula Croatia' },
    { value: 'HUY', label: 'Humberside', location: 'Grimsby United Kingdom' },
  ],
  uz: [
    { value: 'CUZ', label: 'Alejandro Velasco Astete', location: 'Cusco Peru' },
    { value: 'XUZ', label: 'Xuzhou Guanyin', location: 'Xuzhou China' },
    { value: 'HUZ', label: 'Huizhou', location: 'Huizhou China' },
    { value: 'BUZ', label: 'Bushehr', location: 'Bushehr, Iran' },
    { value: 'LUZ', label: 'Lublin', location: 'Lublin Poland' },
  ],
  va: [
    { value: 'GVA', label: 'Geneva Cointrin', location: 'Geneva Switzerland' },
    { value: 'BVA', label: 'Paris Beauvais Till\u00e9', location: 'Beauvais/Till\u00e9 France' },
    { value: 'VAR', label: 'Varna', location: 'Varna Bulgaria' },
    { value: 'VAN', label: 'Van Ferit Melen', location: 'Van Turkey' },
    { value: 'VAS', label: 'Sivas Nuri Demira\u011f', location: 'Sivas Turkey' },
    { value: 'KVA', label: 'Alexander the Great', location: 'Kavala Greece' },
  ],
  vb: [
    { value: 'OVB', label: 'Tolmachevo', location: 'Novosibirsk, Russia' },
    { value: 'VBY', label: 'Visby', location: 'Visby Sweden' },
    { value: 'BVB', label: 'Atlas Brasil Cantanhede', location: 'Boa Vista Brazil' },
    { value: 'HVB', label: 'Hervey Bay', location: 'Hervey Bay Australia' },
  ],
  vc: [
    { value: 'VCE', label: 'Venice Marco Polo', location: 'Venice Italy' },
    { value: 'VCP', label: 'Viracopos', location: 'Campinas Brazil' },
    { value: 'VCL', label: 'Chu Lai', location: 'Dung Quat Bay Viet Nam' },
    { value: 'VCA', label: 'Can Tho', location: 'Can Tho Viet Nam' },
    { value: 'BVC', label: 'Rabil', location: 'Rabil Cape Verde' },
    { value: 'TVC', label: 'Cherry Capital', location: 'Traverse City, USA' },
  ],
  vd: [
    { value: 'PVD', label: 'Theodore Francis Green', location: 'Providence, USA' },
    { value: 'MVD', label: 'Carrasco/General C L Berisso', location: 'Montevideo Uruguay' },
    { value: 'OVD', label: 'Asturias', location: 'Ran\u00f3n Spain' },
    { value: 'KVD', label: 'Ganja', location: 'Ganja Azerbaijan' },
    { value: 'VDH', label: 'Dong Hoi', location: 'Dong Hoi Viet Nam' },
    { value: 'VDA', label: 'Ovda', location: 'Eilat Israel' },
  ],
  ve: [
    { value: 'VER', label: 'General Heriberto Jara', location: 'Veracruz Mexico' },
    { value: 'EVE', label: 'Harstad/Narvik, Evenes', location: 'Evenes Norway' },
    { value: 'UVE', label: 'Ouv\u00e9a', location: 'Ouv\u00e9a New Caledonia' },
    { value: 'BVE', label: 'Brive Souillac', location: 'France' },
  ],
  vf: [
    { value: 'UVF', label: 'Hewanorra', location: 'Vieux Fort Saint Lucia' },
    { value: 'VFA', label: 'Victoria Falls', location: 'Victoria Falls Zimbabwe' },
  ],
  vg: [
    { value: 'PVG', label: 'Shanghai Pudong', location: 'Shanghai China' },
    { value: 'CVG', label: 'Cincinnati Northern Kentucky', location: 'Cincinnati, USA' },
    { value: 'SVG', label: 'Stavanger Sola', location: 'Stavanger Norway' },
    { value: 'VGO', label: 'Vigo', location: 'Vigo Spain' },
    { value: 'VGA', label: 'Vijayawada', location: 'India' },
    { value: 'HVG', label: 'Valan', location: 'Honningsv\u00e5g Norway' },
  ],
  vh: [{ value: 'PVH', label: 'Jorge Teixeira de Oliveira', location: 'Porto Velho Brazil' }],
  vi: [
    { value: 'VIE', label: 'Vienna', location: 'Vienna Austria' },
    { value: 'VIX', label: 'Eurico de Aguiar Salles', location: 'Vit\u00f3ria Brazil' },
    { value: 'VVI', label: 'Viru Viru', location: 'Santa Cruz, Bolivia' },
    { value: 'VII', label: 'Vinh', location: 'Vinh Viet Nam' },
    { value: 'VIL', label: 'Dakhla', location: 'Dakhla Morocco' },
    { value: 'LVI', label: 'Livingstone', location: 'Livingstone Zambia' },
  ],
  vj: [{ value: 'SVJ', label: 'Svolv\u00e6r Helle', location: 'Svolv\u00e6r Norway' }],
  vk: [
    { value: 'VKO', label: 'Vnukovo', location: 'Moscow, Russia' },
    { value: 'PVK', label: 'Aktion National', location: 'Preveza/Lefkada Greece' },
    { value: 'RVK', label: 'R\u00f8rvik, Ryum', location: 'R\u00f8rvik Norway' },
    { value: 'VKG', label: 'Rach Gia', location: 'Rach Gia Viet Nam' },
  ],
  vl: [
    { value: 'VLC', label: 'Valencia', location: 'Valencia Spain' },
    { value: 'AVL', label: 'Asheville Regional', location: 'Asheville, USA' },
    { value: 'IVL', label: 'Ivalo', location: 'Ivalo Finland' },
    { value: 'VLL', label: 'Valladolid', location: 'Valladolid Spain' },
    { value: 'VLD', label: 'Valdosta Regional', location: 'Valdosta, USA' },
  ],
  vm: [{ value: 'CVM', label: 'Ciudad Victoria', location: 'Ciudad Victoria Mexico' }],
  vn: [
    { value: 'VNO', label: 'Vilnius', location: 'Vilnius Lithuania' },
    { value: 'EVN', label: 'Zvartnots', location: 'Yerevan Armenia' },
    { value: 'VNS', label: 'Lal Bahadur Shastri', location: 'Varanasi India' },
    { value: 'RVN', label: 'Rovaniemi', location: 'Rovaniemi Finland' },
    { value: 'HVN', label: 'Tweed New Haven', location: 'New Haven, USA' },
  ],
  vo: [
    { value: 'SVO', label: 'Sheremetyevo', location: 'Moscow, Russia' },
    { value: 'DVO', label: 'Francisco Bangoy', location: 'Davao City Philippines' },
    { value: 'VVO', label: 'Vladivostok', location: 'Vladivostok, Russia' },
    { value: 'VOG', label: 'Volgograd', location: 'Volgograd, Russia' },
    { value: 'VOZ', label: 'Voronezh', location: 'Voronezh, Russia' },
    { value: 'VOL', label: 'Nea Anchialos', location: 'Nea Anchialos Greece' },
  ],
  vp: [
    { value: 'VPS', label: 'Destin-Ft Walton Beach', location: 'Valparaiso, USA' },
    { value: 'AVP', label: 'Wilkes Barre Scranton', location: 'Wilkes-Barre/Scranton, USA' },
  ],
  vq: [
    { value: 'SVQ', label: 'Sevilla', location: 'Sevilla Spain' },
    { value: 'VQS', label: 'Antonio Rivera Rodriguez', location: 'Vieques Island Puerto Rico' },
  ],
  vr: [
    { value: 'YVR', label: 'Vancouver', location: 'Vancouver Canada' },
    {
      value: 'PVR',
      label: 'Licenciado Gustavo D\u00edaz Ordaz',
      location: 'Puerto Vallarta Mexico',
    },
    { value: 'VRN', label: 'Verona Villafranca', location: 'Verona Italy' },
    { value: 'VRA', label: 'Juan Gualberto Gomez', location: 'Varadero Cuba' },
    { value: 'VRC', label: 'Virac', location: 'Virac Philippines' },
  ],
  vs: [
    { value: 'VSA', label: 'Carlos Rovirosa P\u00e9rez', location: 'Villahermosa Mexico' },
    {
      value: 'VST',
      label: 'Stockholm V\u00e4ster\u00e5s',
      location: 'Stockholm / V\u00e4ster\u00e5s Sweden',
    },
  ],
  vt: [
    { value: 'VTZ', label: 'Vishakhapatnam', location: 'Visakhapatnam India' },
    { value: 'NVT', label: 'Ministro Victor Konder', location: 'Navegantes Brazil' },
  ],
  vu: [
    { value: 'VUP', label: 'Alfonso L\u00f3pez Pumarejo', location: 'Valledupar Colombia' },
    { value: 'PVU', label: 'Provo Municipal', location: 'Provo, USA' },
  ],
  vv: [
    { value: 'VVO', label: 'Vladivostok', location: 'Vladivostok, Russia' },
    { value: 'VVI', label: 'Viru Viru', location: 'Santa Cruz, Bolivia' },
    { value: 'EVV', label: 'Evansville Regional', location: 'Evansville, USA' },
    { value: 'VVC', label: 'Vanguardia', location: 'Villavicencio Colombia' },
  ],
  vw: [],
  vx: [
    { value: 'SVX', label: 'Koltsovo', location: 'Yekaterinburg, Russia' },
    { value: 'VXO', label: 'V\u00e4xj\u00f6 Kronoberg', location: 'V\u00e4xj\u00f6 Sweden' },
    { value: 'VXE', label: 'S\u00e3o Pedro', location: 'S\u00e3o Pedro Cape Verde' },
    { value: 'KVX', label: 'Pobedilovo', location: 'Kirov, Russia' },
  ],
  vy: [{ value: 'MVY', label: "Martha's Vineyard", location: "Martha's Vineyard, USA" }],
  vz: [],
  wa: [
    { value: 'WAS', label: 'Washington, D.C.', location: 'Washington, D.C USA', isCity: true },
    { value: 'WAW', label: 'Warsaw Chopin', location: 'Warsaw Poland' },
    { value: 'SWA', label: 'Jieyang Chaoshan', location: 'Shantou China' },
    { value: 'FWA', label: 'Fort Wayne', location: 'Fort Wayne, USA' },
    { value: 'CWA', label: 'Central Wisconsin', location: 'Mosinee, USA' },
    { value: 'MWA', label: 'Veterans of Southern Illinois', location: 'Marion, USA' },
  ],
  wb: [
    { value: 'CWB', label: 'Afonso Pena', location: 'Curitiba Brazil' },
    { value: 'OWB', label: 'Owensboro Daviess County', location: 'Owensboro, USA' },
    { value: 'LWB', label: 'Greenbrier Valley', location: 'Lewisburg, USA' },
  ],
  wc: [{ value: 'CWC', label: 'Chernivtsi', location: 'Chernivtsi Ukraine' }],
  wd: [
    { value: 'WDS', label: 'Shiyan Wudangshan', location: 'Shiyan China' },
    { value: 'WDH', label: 'Hosea Kutako', location: 'Windhoek Namibia' },
  ],
  we: [
    { value: 'KWE', label: 'Longdongbao', location: 'Guiyang China' },
    { value: 'WEH', label: 'Weihai', location: 'Weihai China' },
  ],
  wf: [
    { value: 'SWF', label: 'Stewart', location: 'Newburgh, USA' },
    { value: 'TWF', label: 'Magic Valley Regional', location: 'Twin Falls, USA' },
  ],
  wg: [
    { value: 'YWG', label: 'Winnipeg/James A Richardson', location: 'Winnipeg Canada' },
    { value: 'WGA', label: 'Wagga Wagga City', location: 'Wagga Wagga Australia' },
    { value: 'KWG', label: 'Kryvyi Rih', location: 'Kryvyi Rih Ukraine' },
  ],
  wh: [],
  wi: [
    { value: 'BWI', label: 'Baltimore/Washington  Thurgood Marshall', location: 'Baltimore, USA' },
    { value: 'KWI', label: 'Kuwait', location: 'Kuwait City Kuwait' },
    { value: 'NWI', label: 'Norwich', location: 'Norwich United Kingdom' },
  ],
  wj: [{ value: 'KWJ', label: 'Gwangju', location: 'Gwangju S. Korea' }],
  wk: [
    { value: 'IWK', label: 'MCAS Iwakuni', location: 'Iwakuni Japan' },
    { value: 'BWK', label: 'Bol', location: 'Bra\u010d Island Croatia' },
  ],
  wl: [
    { value: 'KWL', label: 'Guilin Liangjiang', location: 'Guilin City China' },
    { value: 'WLG', label: 'Wellington', location: 'Wellington New Zealand' },
    { value: 'CWL', label: 'Cardiff', location: 'Cardiff United Kingdom' },
    { value: 'WLS', label: 'Hihifo', location: 'Wallis Island Wallis and Futuna' },
  ],
  wm: [
    { value: 'WMI', label: 'Modlin', location: 'Warsaw Poland' },
    { value: 'PWM', label: 'Portland Jetport', location: 'Portland, USA' },
    { value: 'WMX', label: 'Wamena', location: 'Wamena-Papua Island Indonesia' },
  ],
  wn: [
    { value: 'WNZ', label: 'Wenzhou Longwan', location: 'Wenzhou China' },
    { value: 'BWN', label: 'Brunei', location: 'Bandar Seri Begawan Brunei Darussalam' },
    { value: 'EWN', label: 'Coastal Carolina Regional', location: 'New Bern, USA' },
    { value: 'WNP', label: 'Naga', location: 'Naga Philippines' },
  ],
  wo: [{ value: 'LWO', label: 'Lviv', location: 'Lviv Ukraine' }],
  wp: [],
  wq: [],
  wr: [
    { value: 'EWR', label: 'Newark Liberty', location: 'Newark, USA' },
    { value: 'WRO', label: 'Copernicus Wroc\u0142aw', location: 'Wroc\u0142aw Poland' },
    { value: 'WRG', label: 'Wrangell', location: 'Wrangell, USA' },
  ],
  ws: [
    { value: 'LWS', label: 'Lewiston-Nez Perce County', location: 'Lewiston, USA' },
    { value: 'WST', label: 'Westerly State', location: 'Westerly, USA' },
  ],
  wt: [
    { value: 'WTB', label: 'Toowoomba Wellcamp', location: 'Toowoomba Australia' },
    { value: 'TWT', label: 'Sanga Sanga', location: 'Philippines' },
  ],
  wu: [
    { value: 'WUH', label: 'Wuhan Tianhe', location: 'Wuhan China' },
    { value: 'WUX', label: 'Sunan Shuofang', location: 'Wuxi China' },
    { value: 'TWU', label: 'Tawau', location: 'Tawau Malaysia' },
  ],
  wv: [],
  ww: [],
  wx: [
    { value: 'WXN', label: 'Wanxian', location: 'Wanxian China' },
    { value: 'MWX', label: 'Muan', location: 'Piseo-ri (Muan) S. Korea' },
  ],
  wy: [],
  wz: [
    { value: 'AWZ', label: 'Ahwaz', location: 'Ahwaz, Iran' },
    { value: 'MWZ', label: 'Mwanza', location: 'Mwanza Tanzania, United Republic of' },
  ],
  xa: [
    { value: 'LXA', label: 'Lhasa Gonggar', location: 'Lhasa China' },
    { value: 'IXA', label: 'Agartala', location: 'Agartala India' },
  ],
  xb: [
    { value: 'DXB', label: 'Dubai', location: 'Dubai, UAE' },
    { value: 'IXB', label: 'Bagdogra', location: 'Siliguri India' },
    { value: 'SXB', label: 'Strasbourg', location: 'Strasbourg France' },
  ],
  xc: [
    { value: 'IXC', label: 'Chandigarh', location: 'Chandigarh India' },
    { value: 'XCR', label: 'Ch\u00e2lons-Vatry', location: 'Vatry France' },
  ],
  xd: [{ value: 'AXD', label: 'Dimokritos', location: 'Alexandroupolis Greece' }],
  xe: [
    { value: 'IXE', label: 'Mangalore', location: 'Mangalore India' },
    { value: 'YXE', label: 'Saskatoon John G. Diefenbaker', location: 'Saskatoon Canada' },
    { value: 'VXE', label: 'S\u00e3o Pedro', location: 'S\u00e3o Pedro Cape Verde' },
    { value: 'XEG', label: 'Kingston Railway Station', location: 'Kingston Canada' },
  ],
  xf: [
    { value: 'SXF', label: 'Berlin-Sch\u00f6nefeld', location: 'Berlin Germany' },
    { value: 'XFN', label: 'Xiangyang Liuji', location: 'Xiangfan China' },
  ],
  xg: [{ value: 'IXG', label: 'Belgaum', location: 'Belgaum India' }],
  xh: [],
  xi: [
    { value: 'XIY', label: "Xi'an Xianyang ", location: "Xi'an China" },
    { value: 'XIL', label: 'Xilinhot', location: 'Xilinhot China' },
  ],
  xj: [{ value: 'IXJ', label: 'Jammu', location: 'Jammu India' }],
  xk: [{ value: 'TXK', label: 'Texarkana Webb Field', location: 'Texarkana, USA' }],
  xl: [
    { value: 'MXL', label: 'Rodolfo S\u00e1nchez Taboada', location: 'Mexicali Mexico' },
    { value: 'IXL', label: 'Leh Kushok Bakula Rimpochee', location: 'Leh India' },
  ],
  xm: [
    { value: 'XMN', label: 'Xiamen Gaoqi', location: 'Xiamen China' },
    {
      value: 'SXM',
      label: 'Princess Juliana',
      location: 'Saint Martin Sint Maarten (Dutch part)',
    },
    { value: 'IXM', label: 'Madurai', location: 'Madurai India' },
    { value: 'AXM', label: 'El Eden', location: 'Armenia Colombia' },
    { value: 'PXM', label: 'Puerto Escondido', location: 'Puerto Escondido Mexico' },
  ],
  xn: [
    { value: 'XNN', label: 'Xining Caojiabu', location: 'Xining China' },
    {
      value: 'XNA',
      label: 'Northwest Arkansas Regional',
      location: 'Fayetteville/Springdale/Rogers, USA',
    },
    { value: 'TXN', label: 'Tunxi', location: 'Huangshan China' },
    { value: 'WXN', label: 'Wanxian', location: 'Wanxian China' },
  ],
  xo: [{ value: 'VXO', label: 'V\u00e4xj\u00f6 Kronoberg', location: 'V\u00e4xj\u00f6 Sweden' }],
  xp: [{ value: 'MXP', label: 'Malpensa', location: 'Milan Italy' }],
  xq: [],
  xr: [
    { value: 'CXR', label: 'Cam Ranh', location: 'Nha Trang Viet Nam' },
    { value: 'SXR', label: 'Sheikh ul Alam', location: 'Srinagar India' },
    { value: 'IXR', label: 'Birsa Munda', location: 'Ranchi India' },
    { value: 'XRY', label: 'Jerez', location: 'Jerez de la Forntera Spain' },
    { value: 'LXR', label: 'Luxor', location: 'Luxor Egypt' },
  ],
  xs: [
    { value: 'YXS', label: 'Prince George', location: 'Prince George Canada' },
    { value: 'IXS', label: 'Silchar', location: 'Silchar India' },
    { value: 'RXS', label: 'Roxas', location: 'Roxas City Philippines' },
    { value: 'LXS', label: 'Limnos', location: 'Limnos Island Greece' },
  ],
  xt: [
    { value: 'AXT', label: 'Akita', location: 'Akita Japan' },
    { value: 'EXT', label: 'Exeter', location: 'Exeter United Kingdom' },
  ],
  xu: [
    { value: 'XUZ', label: 'Xuzhou Guanyin', location: 'Xuzhou China' },
    { value: 'BXU', label: 'Bancasi', location: 'Butuan City Philippines' },
    { value: 'YXU', label: 'London', location: 'London Canada' },
    { value: 'IXU', label: 'Aurangabad', location: 'Aurangabad India' },
    { value: 'PXU', label: 'Pleiku', location: 'Pleiku Viet Nam' },
  ],
  xv: [{ value: 'MXV', label: 'M\u00f6r\u00f6n', location: 'M\u00f6r\u00f6n Mongolia' }],
  xw: [],
  xx: [{ value: 'YXX', label: 'Abbotsford', location: 'Abbotsford Canada' }],
  xy: [{ value: 'YXY', label: 'Whitehorse / Erik Nielsen', location: 'Whitehorse Canada' }],
  xz: [
    { value: 'IXZ', label: 'Vir Savarkar', location: 'Port Blair India' },
    { value: 'SXZ', label: 'Siirt', location: 'Siirt Turkey' },
  ],
  ya: [
    { value: 'LYA', label: 'Luoyang', location: 'Luoyang China' },
    { value: 'KYA', label: 'Konya', location: 'Konya Turkey' },
    { value: 'HYA', label: 'Barnstable', location: 'Hyannis, USA' },
    { value: 'YAK', label: 'Yakutat', location: 'Yakutat, USA' },
  ],
  yb: [{ value: 'YBP', label: 'Yibin Caiba', location: 'Yibin China' }],
  yc: [
    { value: 'YYC', label: 'Calgary', location: 'Calgary Canada' },
    { value: 'YCU', label: 'Yuncheng Guangong', location: 'Yuncheng China' },
    { value: 'YCD', label: 'Nanaimo', location: 'Nanaimo Canada' },
  ],
  yd: [
    { value: 'SYD', label: 'Sydney Kingsford Smith', location: 'Sydney Australia' },
    { value: 'HYD', label: 'Rajiv Gandhi', location: 'Hyderabad India' },
    { value: 'GYD', label: 'Heydar Aliyev', location: 'Baku Azerbaijan' },
    { value: 'YDF', label: 'Deer Lake', location: 'Deer Lake Canada' },
  ],
  ye: [
    { value: 'YEG', label: 'Edmonton', location: 'Edmonton Canada' },
    { value: 'GYE', label: 'Jos\u00e9 Joaqu\u00edn de Olmedo', location: 'Guayaquil Ecuador' },
    { value: 'YEI', label: 'Bursa Yeni\u015fehir', location: 'Bursa Turkey' },
  ],
  yf: [{ value: 'YFC', label: 'Fredericton', location: 'Fredericton Canada' }],
  yg: [
    { value: 'DYG', label: 'Dayong', location: 'Dayong China' },
    { value: 'LYG', label: 'Lianyungang', location: 'Lianyungang China' },
    { value: 'RYG', label: 'Moss, Rygge', location: 'Oslo Norway' },
    { value: 'YGJ', label: 'Miho Yonago', location: 'Yonago Japan' },
    { value: 'YYG', label: 'Charlottetown', location: 'Charlottetown Canada' },
  ],
  yh: [
    { value: 'YHZ', label: 'Halifax / Stanfield', location: 'Halifax Canada' },
    { value: 'YHM', label: 'John C. Munro Hamilton', location: 'Hamilton Canada' },
    { value: 'LYH', label: 'Lynchburg Preston Glenn Field', location: 'Lynchburg, USA' },
  ],
  yi: [
    { value: 'YIH', label: 'Yichang Sanxia', location: 'Yichang China' },
    { value: 'ZYI', label: 'Zunyi Xinzhou', location: 'Zunyi China' },
    { value: 'LYI', label: 'Shubuling', location: 'Linyi China' },
    { value: 'YIW', label: 'Yiwu', location: 'Yiwu China' },
    { value: 'YIN', label: 'Yining', location: 'Yining China' },
    { value: 'YIA', label: 'Yogyakarta', location: 'Yogyakarta Indonesia' },
  ],
  yj: [
    { value: 'MYJ', label: 'Matsuyama', location: 'Matsuyama Japan' },
    { value: 'YYJ', label: 'Victoria', location: 'Victoria Canada' },
    { value: 'YJM', label: 'Malaga Railway Station', location: 'Malaga Spain' },
  ],
  yk: [
    { value: 'YKS', label: 'Yakutsk', location: 'Yakutsk, Russia' },
    { value: 'YKA', label: 'Kamloops', location: 'Kamloops Canada' },
    { value: 'YKO', label: 'Hakkari Y\u00fcksekova', location: 'Hakkari Turkey' },
    { value: 'YKM', label: 'Yakima Air Terminal', location: 'Yakima, USA' },
  ],
  yl: [{ value: 'YLW', label: 'Kelowna', location: 'Kelowna Canada' }],
  ym: [
    { value: 'YMQ', label: 'Montreal', location: 'Montreal Canada', isCity: true },
    { value: 'YMM', label: 'Fort McMurray', location: 'Fort McMurray Canada' },
    { value: 'NYM', label: 'Nadym', location: 'Nadym, Russia' },
  ],
  yn: [
    { value: 'TYN', label: 'Taiyuan Wusu', location: 'Taiyuan China' },
    { value: 'YNT', label: 'Yantai Laishan', location: 'Yantai China' },
    { value: 'GYN', label: 'Santa Genoveva', location: 'Goi\u00e2nia Brazil' },
    { value: 'UYN', label: 'Yulin Yuyang', location: 'Yulin China' },
    { value: 'YNZ', label: 'Yancheng', location: 'Yancheng China' },
    { value: 'YNJ', label: 'Yanji Chaoyangchuan', location: 'Yanji China' },
  ],
  yo: [
    { value: 'YOW', label: 'Ottawa Macdonald-Cartier', location: 'Ottawa Canada' },
    { value: 'NYO', label: 'Stockholm Skavsta', location: 'Stockholm / Nyk\u00f6ping Sweden' },
    { value: 'SYO', label: 'Shonai', location: 'Shonai Japan' },
    { value: 'YOL', label: 'Yola', location: 'Yola Nigeria' },
  ],
  yp: [
    { value: 'LYP', label: 'Faisalabad', location: 'Faisalabad Pakistan' },
    { value: 'EYP', label: 'El Yopal', location: 'El Yopal Colombia' },
    { value: 'AYP', label: 'Alfredo Mendivil Duarte', location: 'Ayacucho Peru' },
    { value: 'CYP', label: 'Calbayog', location: 'Calbayog City Philippines' },
  ],
  yq: [
    { value: 'YQB', label: 'Quebec Jean Lesage', location: 'Quebec Canada' },
    { value: 'YQR', label: 'Regina', location: 'Regina Canada' },
    { value: 'YQT', label: 'Thunder Bay', location: 'Thunder Bay Canada' },
    { value: 'YQM', label: 'Greater Moncton', location: 'Moncton Canada' },
    { value: 'YQU', label: 'Grande Prairie', location: 'Grande Prairie Canada' },
    { value: 'YQQ', label: 'Comox', location: 'Comox Canada' },
  ],
  yr: [
    { value: 'MYR', label: 'Myrtle Beach', location: 'Myrtle Beach, USA' },
    { value: 'SYR', label: 'Syracuse Hancock', location: 'Syracuse, USA' },
    { value: 'LYR', label: 'Svalbard, Longyear', location: 'Longyearbyen Svalbard and Jan Mayen' },
    { value: 'TYR', label: 'Tyler Pounds Regional', location: 'Tyler, USA' },
  ],
  ys: [
    { value: 'LYS', label: 'Lyon Saint-Exup\u00e9ry', location: 'Lyon France' },
    { value: 'TYS', label: 'McGhee Tyson', location: 'Knoxville, USA' },
    { value: 'CYS', label: 'Cheyenne Regional', location: 'Cheyenne, USA' },
  ],
  yt: [
    { value: 'YTO', label: 'Toronto', location: 'Toronto Canada', isCity: true },
    { value: 'AYT', label: 'Antalya', location: 'Antalya Turkey' },
    { value: 'YTZ', label: 'Billy Bishop Toronto', location: 'Toronto Canada' },
    { value: 'YTY', label: 'Yangzhou Taizhou', location: 'Yangzhou and Taizhou China' },
    { value: 'YYT', label: "St. John's ", location: "St. John's Canada" },
  ],
  yu: [
    { value: 'YUL', label: 'Montreal Pierre Trudeau', location: 'Montr\u00e9al Canada' },
    { value: 'DYU', label: 'Dushanbe', location: 'Dushanbe Tajikistan' },
    { value: 'YUM', label: 'Yuma', location: 'Yuma, USA' },
  ],
  yv: [
    { value: 'YVR', label: 'Vancouver', location: 'Vancouver Canada' },
    { value: 'JYV', label: 'Jyvaskyla', location: 'Jyv\u00e4skyl\u00e4n Maalaiskunta Finland' },
  ],
  yw: [
    { value: 'YWG', label: 'Winnipeg/James A Richardson', location: 'Winnipeg Canada' },
    { value: 'EYW', label: 'Key West', location: 'Key West, USA' },
  ],
  yx: [
    { value: 'SYX', label: 'Sanya Phoenix', location: 'Sanya China' },
    { value: 'YXE', label: 'Saskatoon John G. Diefenbaker', location: 'Saskatoon Canada' },
    { value: 'YXX', label: 'Abbotsford', location: 'Abbotsford Canada' },
    { value: 'YXU', label: 'London', location: 'London Canada' },
    { value: 'YXS', label: 'Prince George', location: 'Prince George Canada' },
    { value: 'YXY', label: 'Whitehorse / Erik Nielsen', location: 'Whitehorse Canada' },
  ],
  yy: [
    { value: 'YYZ', label: 'Lester B. Pearson', location: 'Toronto Canada' },
    { value: 'YYC', label: 'Calgary', location: 'Calgary Canada' },
    { value: 'MYY', label: 'Miri', location: 'Miri Malaysia' },
    { value: 'YYJ', label: 'Victoria', location: 'Victoria Canada' },
    { value: 'YYT', label: "St. John's ", location: "St. John's Canada" },
    { value: 'YYG', label: 'Charlottetown', location: 'Charlottetown Canada' },
  ],
  yz: [
    { value: 'YYZ', label: 'Lester B. Pearson', location: 'Toronto Canada' },
    {
      value: 'SYZ',
      label: 'Shiraz Shahid Dastghaib',
      location: 'Shiraz, Iran',
    },
    { value: 'YZF', label: 'Yellowknife', location: 'Yellowknife Canada' },
    { value: 'CYZ', label: 'Cauayan', location: 'Cauayan City Philippines' },
  ],
  za: [
    { value: 'ZAG', label: 'Zagreb', location: 'Zagreb Croatia' },
    { value: 'AZA', label: 'Phoenix-Mesa-Gateway', location: 'Phoenix, USA' },
    { value: 'ZAM', label: 'Zamboanga', location: 'Zamboanga City Philippines' },
    { value: 'ZAD', label: 'Zadar', location: 'Zemunik (Zadar) Croatia' },
    { value: 'ZAH', label: 'Zahedan', location: 'Zahedan, Iran' },
    { value: 'ZAZ', label: 'Zaragoza Air Base', location: 'Zaragoza Spain' },
  ],
  zb: [{ value: 'SZB', label: 'Sultan Abdul Aziz Shah', location: 'Subang Malaysia' }],
  zc: [
    { value: 'ZCO', label: 'La Araucan\u00eda', location: 'Temuco Chile' },
    { value: 'ZCL', label: 'General Leobardo C. Ruiz', location: 'Zacatecas Mexico' },
    { value: 'OZC', label: 'Labo', location: 'Ozamiz City Philippines' },
    { value: 'NZC', label: 'Maria Reiche Neuman', location: 'Nazca, USA' },
  ],
  zd: [{ value: 'AZD', label: 'Shahid Sadooghi', location: 'Yazd, Iran' }],
  ze: [
    { value: 'EZE', label: 'Ministro Pistarini', location: 'Buenos Aires Argentina' },
    { value: 'BZE', label: 'Philip S. W. Goldson', location: 'Belize City Belize' },
    { value: 'RZE', label: 'Rzesz\u00f3w-Jasionka', location: 'Rzesz\u00f3w Poland' },
  ],
  zf: [
    { value: 'SZF', label: 'Samsun \u00c7ar\u015famba', location: 'Samsun Turkey' },
    { value: 'YZF', label: 'Yellowknife', location: 'Yellowknife Canada' },
  ],
  zg: [
    { value: 'MZG', label: 'Makung', location: 'Makung City, Taiwan' },
    { value: 'SZG', label: 'Salzburg', location: 'Salzburg Austria' },
    { value: 'BZG', label: 'Ignacy Jan Paderewski', location: 'Bydgoszcz Poland' },
  ],
  zh: [
    { value: 'ZHA', label: 'Zhanjiang', location: 'Zhanjiang China' },
    { value: 'LZH', label: 'Liuzhou Bailian', location: 'Liuzhou China' },
    { value: 'JZH', label: 'Jiuzhai Huanglong', location: 'Jiuzhaigou China' },
    { value: 'OZH', label: 'Zaporizhzhia', location: 'Zaporizhia Ukraine' },
    { value: 'MZH', label: 'Amasya Merzifon', location: 'Amasya Turkey' },
    { value: 'ZHT', label: 'Geneva Railway Station', location: 'Geneva Switzerland' },
  ],
  zi: [
    { value: 'ZIA', label: 'Zhukovsky', location: 'Moscow, Russia' },
    { value: 'ZIH', label: 'Ixtapa Zihuatanejo', location: 'Ixtapa Mexico' },
  ],
  zj: [],
  zk: [],
  zl: [
    { value: 'TZL', label: 'Tuzla', location: 'Tuzla Bosnia' },
    { value: 'MZL', label: 'La Nubia', location: 'Manizales Colombia' },
    { value: 'ZLO', label: 'Playa De Oro', location: 'Manzanillo Mexico' },
  ],
  zm: [{ value: 'CZM', label: 'Cozumel', location: 'Cozumel Mexico' }],
  zn: [
    { value: 'KZN', label: 'Kazan', location: 'Kazan, Russia' },
    { value: 'BZN', label: 'Gallatin Field', location: 'Bozeman, USA' },
    {
      value: 'ZNZ',
      label: 'Abeid Amani Karume',
      location: 'Zanzibar Tanzania, United Republic of',
    },
    { value: 'LZN', label: 'Matsu Nangan', location: 'Nangang Island, Taiwan' },
    { value: 'ZNE', label: 'Newman', location: 'Newman Australia' },
  ],
  zo: [
    { value: 'LZO', label: 'Luzhou', location: 'Luzhou China' },
    { value: 'IZO', label: 'Izumo', location: 'Izumo Japan' },
    { value: 'AZO', label: 'Kalamazoo Battle Creek', location: 'Kalamazoo, USA' },
    { value: 'ZOS', label: 'Ca\u00f1al Bajo Carlos Hott Siebert', location: 'Osorno Chile' },
  ],
  zp: [{ value: 'GZP', label: 'Gazipa\u015fa', location: 'Gazipa\u015fa Turkey' }],
  zq: [{ value: 'ZQN', label: 'Queenstown', location: 'Queenstown New Zealand' }],
  zr: [
    { value: 'ZRH', label: 'Z\u00fcrich', location: 'Zurich Switzerland' },
    { value: 'BZR', label: 'B\u00e9ziers-Vias', location: 'B\u00e9ziers/Vias France' },
    { value: 'KZR', label: 'Zafer', location: 'Alt\u0131nta\u015f Turkey' },
  ],
  zs: [
    { value: 'EZS', label: 'Elaz\u0131\u011f', location: 'Elaz\u0131\u011f Turkey' },
    { value: 'CZS', label: 'Cruzeiro do Sul', location: 'Cruzeiro Do Sul Brazil' },
    { value: 'ZSE', label: 'Pierrefonds', location: 'St Pierre R\u00e9union' },
    { value: 'AZS', label: 'Saman\u00e1 El Catey', location: 'Samana Dominican Republic' },
  ],
  zt: [
    { value: 'GZT', label: 'Gaziantep', location: 'Gaziantep Turkey' },
    { value: 'ZTH', label: 'Zakynthos Dionysios Solomos', location: 'Zakynthos Island Greece' },
    { value: 'MZT', label: 'General Rafael Buelna', location: 'Mazatl\u00e1n Mexico' },
  ],
  zu: [{ value: 'ZUH', label: 'Zhuhai Jinwan', location: 'Zhuhai China' }],
  zv: [
    { value: 'BZV', label: 'Maya-Maya', location: 'Brazzaville Congo' },
    { value: 'MZV', label: 'Mulu', location: 'Mulu Malaysia' },
  ],
  zw: [],
  zx: [
    { value: 'SZX', label: "Shenzhen Bao'an ", location: 'Shenzhen China' },
    { value: 'TZX', label: 'Trabzon', location: 'Trabzon Turkey' },
    { value: 'CZX', label: 'Changzhou Benniu', location: 'Changzhou China' },
  ],
  zy: [
    { value: 'ZYI', label: 'Zunyi Xinzhou', location: 'Zunyi China' },
    { value: 'ZYK', label: 'Shekou Port station', location: 'Shekou China' },
  ],
  zz: [
    {
      value: 'SZZ',
      label: 'Szczecin-Goleni\u00f3w Solidarno\u015b\u0107',
      location: 'Goleniow Poland',
    },
    { value: 'OZZ', label: 'Ouarzazate', location: 'Ouarzazate Morocco' },
  ],
}
