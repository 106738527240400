import React from "react";
import styled, { keyframes } from "styled-components";
import styles from "styles/styles";

type SpinnerProps = {
    size?: number
}

const Spinner = (props: SpinnerProps) => {
    return <Container size={props.size}/>
}

const spin = keyframes`
    to { -webkit-transform: rotate(360deg); }
`

type ContainerProps = {
    size?: number
}

const Container = styled.div<ContainerProps>`
    display: inline-block;
    width: ${props => props.size ? `${props.size}px` : '100%'};
    height: ${props => props.size ? `${props.size}px` : '100%'};
    border: 3px solid rgba(0,0,0,.3);
    border-radius: ${styles.BorderRadius.Half};
    border-top-color: #000;
    animation: ${spin} 1s ease-in-out infinite;
`

export default Spinner;