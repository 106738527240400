import API from 'services/api'

const featureName = 'Disputes'

const ACTION_UPDATE_DISPUTE = `${featureName}/UPDATE_DISPUTE`
const ACTION_CLEAR_DISPUTE = `${featureName}/CLEAR_DISPUTE`

const ACTION_CREATE_DISPUTE_START = `${featureName}/CREATE_DISPUTE_START`
const ACTION_CREATE_DISPUTE_COMPLETE = `${featureName}/CREATE_DISPUTE_COMPLETE`
const ACTION_CREATE_DISPUTE_ERROR = `${featureName}/CREATE_DISPUTE_ERROR`

export const Actions = {
  clearDispute: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_DISPUTE })
  },
  updateWorkingCopy: (newValue) => ({
    type: ACTION_UPDATE_DISPUTE,
    payload: newValue,
  }),

  createDispute: (params) => (dispatch) => {
    dispatch({ type: ACTION_CREATE_DISPUTE_START })

    return API.banking.disputes
      .create(params)
      .then((response) => {
        dispatch({
          type: ACTION_CREATE_DISPUTE_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_DISPUTE_ERROR,
          payload: e,
        })
      })
  },
}

// REDUCER

const defaultState = {
  workingCopy: {
    activeType: null,
    activeSubtype: null,
    expectedCharge: null,
    additionalInformation: '',
    transactionId: null,
  },
  submittedDispute: {
    success: '',
    id: '',
    date: '',
    resolutionDate: '',
  },
  isLoading: false,
  error: null,
  errors: [],
}

export const DisputesReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_UPDATE_DISPUTE:
      return {
        ...state,
        workingCopy: {
          ...state.workingCopy,
          ...action.payload,
        },
      }

    case ACTION_CREATE_DISPUTE_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_CREATE_DISPUTE_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }

    case ACTION_CLEAR_DISPUTE:
      return {
        ...defaultState,
      }

    case ACTION_CREATE_DISPUTE_ERROR:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}
