import React from "react"

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { FaRegTimesCircle } from 'react-icons/fa'

import Spinner from "components/common/Spinner";

import { ReactComponent as Checkmark } from "assets/svg/Checkmark.svg"

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

type StatusDisplayProps = {
    isLoading: boolean,
    isError: boolean,
    label: string,
    textColor?: string,
    hideIcon?: boolean,
}

const StatusDisplayMotionProps = {
    animate: {
        opacity: 1, 
        height: 'auto' 
    },
    initial: { 
        opacity: 0,
        height: 0 
    },
    exit:{
        opacity: 0,
        height: 0
    },
}

const StatusDisplay = (props: StatusDisplayProps) => {
    return <Container {...StatusDisplayMotionProps}>
        <AnimatePresence mode='wait'>
            {props.isLoading ? <motion.div {...fadeInOutMotionProps} className="d-flex align-items-center">
                <SpinnerContainer>
                    <Spinner/>
                </SpinnerContainer>
                <Label color={props.textColor}>{props.label}</Label>
            </motion.div> : <div>
                <motion.div {...fadeInOutMotionProps} key='error' className="d-flex align-items-center">
                    {!props.hideIcon && (props.isError ? <ErrorIcon fill='red'/> : <SuccessIcon fill='#00bf0c'/>)}
                    <Label color={props.textColor}>{props.label}</Label>
                </motion.div>
            </div>}
        </AnimatePresence>
    </Container>
}

type LabelProps = {
    color?: string;
}

const Label = styled.div<LabelProps>`
    ${props => props.color && `color: ${props.color}`}
`

const SuccessIcon = styled(Checkmark)`
    min-width: 16px;
    max-width: 16px;
    height: auto;
    margin-right: ${styles.Spacing.XS};
`

const ErrorIcon = styled(FaRegTimesCircle)`
    min-width: 16px;
    max-width: 16px;
    height: auto;
    margin-right: ${styles.Spacing.XS};
`

const SpinnerContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.S};
`

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    border-left: 2px solid ${styles.Color.TaekusPurple};
    padding-left: ${styles.Spacing.S};
    height: ${styles.Spacing.M};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

export default StatusDisplay