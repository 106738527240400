import API from 'services/api'

import filter from 'lodash/filter'

const featureName = 'Payee'

const ACTION_UPDATE_PAYEE = `${featureName}/UPDATE_PAYEE`
const ACTION_CLEAR_PAYEE = `${featureName}/CLEAR_PAYEE`

const ACTION_GET_PAYEES_START = `${featureName}/GET_PAYEES_START`
const ACTION_GET_PAYEES_COMPLETE = `${featureName}/GET_PAYEES_COMPLETE`
const ACTION_GET_PAYEES_ERROR = `${featureName}/GET_PAYEES_ERROR`

const ACTION_CREATE_PAYEE_START = `${featureName}/CREATE_PAYEE_START`
const ACTION_CREATE_PAYEE_COMPLETE = `${featureName}/CREATE_PAYEE_COMPLETE`
const ACTION_CREATE_PAYEE_ERROR = `${featureName}/CREATE_PAYEE_ERROR`

const ACTION_UPDATE_PAYEE_START = `${featureName}/UPDATE_PAYEE_START`
const ACTION_UPDATE_PAYEE_COMPLETE = `${featureName}/UPDATE_PAYEE_COMPLETE`
const ACTION_UPDATE_PAYEE_ERROR = `${featureName}/UPDATE_PAYEE_ERROR`

const ACTION_DELETE_PAYEE_START = `${featureName}/DELETE_PAYEE_START`
const ACTION_DELETE_PAYEE_COMPLETE = `${featureName}/DELETE_PAYEE_COMPLETE`
const ACTION_DELETE_PAYEE_ERROR = `${featureName}/DELETE_PAYEE_ERROR`

export const Actions = {
  getPayees: () => (dispatch) => {
    dispatch({ type: ACTION_GET_PAYEES_START })

    return API.banking.payees
      .get()
      .then((response) => {
        dispatch({
          type: ACTION_GET_PAYEES_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_GET_PAYEES_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  createPayee: (params) => (dispatch) => {
    dispatch({ type: ACTION_CREATE_PAYEE_START })

    return API.banking.payees
      .create(params)
      .then((response) => {
        dispatch({
          type: ACTION_CREATE_PAYEE_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_PAYEE_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  updatePayee: (params) => (dispatch) => {
    dispatch({ type: ACTION_UPDATE_PAYEE_START })
    return API.banking.payees
      .update(params)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_PAYEE_COMPLETE,
          payload: params,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_PAYEE_ERROR,
          payload: { ...e.response.data },
        })
      })
  },

  deletePayee: (params) => (dispatch) => {
    dispatch({ type: ACTION_DELETE_PAYEE_START })

    return API.banking.payees
      .delete(params)
      .then((response) => {
        dispatch({
          type: ACTION_DELETE_PAYEE_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_DELETE_PAYEE_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
}

// REDUCER

const defaultState = {
  payees: [],
  isLoading: false,
  error: null,
  errors: [],
}

export const PayeesReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_UPDATE_PAYEE:
      return {
        ...state,
        name: action.payload,
      }

    case ACTION_DELETE_PAYEE_START:
    case ACTION_CREATE_PAYEE_START:
    case ACTION_UPDATE_PAYEE_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      }

    case ACTION_GET_PAYEES_START:
      return {
        ...state,
        isFetchingExternalAccounts: true,
        error: null,
        errors: [],
      }

    case ACTION_CREATE_PAYEE_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        shouldUpdatePayees: true,
      }
    case ACTION_GET_PAYEES_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isFetchingExternalAccounts: false,
        shouldUpdatePayees: false,
      }

    case ACTION_UPDATE_PAYEE_COMPLETE:
      let copyOfPayees = JSON.parse(JSON.stringify(state.payees))
      let selectedPayeeIndex
      copyOfPayees.forEach((account, index) => {
        if (account.uuid === action.payload.uuid) {
          selectedPayeeIndex = index
        }
      })
      if (selectedPayeeIndex > -1) {
        copyOfPayees[selectedPayeeIndex] = {
          ...copyOfPayees[selectedPayeeIndex],
          ...action.payload,
        }
      }

      return {
        ...state,
        Payees: copyOfPayees,
        isLoading: false,
        shouldUpdatePayees: false,
      }

    case ACTION_DELETE_PAYEE_COMPLETE:
      const oldPayees = state.Payees
      const uuid = action.payload.uuid
      const newPayees = filter(oldPayees, (acct) => {
        return acct.uuid !== uuid
      })

      return {
        ...state,
        Payees: newPayees,
        isLoading: false,
      }

    case ACTION_CLEAR_PAYEE:
      return {
        ...defaultState,
      }

    case ACTION_DELETE_PAYEE_ERROR:
    case ACTION_CREATE_PAYEE_ERROR:
    case ACTION_UPDATE_PAYEE_ERROR:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      }

    case ACTION_GET_PAYEES_ERROR:
      return {
        ...state,
        ...action.payload,
        isFetchingExternalAccounts: false,
        error: true,
      }

    default:
      return state
  }
}
