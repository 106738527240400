import moment from "moment";

import { TransactionType } from "components/pages/Home/constants";

export enum AdvancedTransactionFilter {
    CardUuids,
    AmountRange,
    Categories,
}

export type TransactionFilterOptions = {
    cardUuids: string[],
    type: TransactionType,
    startDate?: moment.Moment,
    endDate?: moment.Moment,
    searchString: string,
    minimumAmount?: number,
    maximumAmount?: number,
    categories: string[],
}

export const defaultTransactionFilterOptions = {
    cardUuids: [],
    categories: [],
    endDate: moment().endOf('day'),
    maximumAmount: undefined,
    minimumAmount: undefined,    
    searchString: '',
    startDate: moment().subtract(30, 'days').startOf('day'),
    type: TransactionType.All,
}