export enum CardAccountType {
    Debit='PREPAID',
    Credit='CREDIT'
}

export enum CardAccountCategory {
    Business = 'BUSINESS',
    Consumer = 'CONSUMER',
    Corporate = 'CORPORATE',
}

export type CardAccount = {
    uuid: string,
}