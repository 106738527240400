export const enum AppPath {
    Cards = '/cards',
    CardInspector = '/cards/:cardID',
    CardSettings = '/settings/cardsettings',
    FlightSearch = '/rewards/flights',
    Funding = '/funding',
    Home = '/',
    MyTrips = '/trips',
    Rewards = '/rewards',
    RewardsActivity = '/rewards-activity',
    Settings = '/settings',
    ExternalAccounts = '/settings/externalaccounts',
}