import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import { Actions } from "redux/currentUser";

import { SaveButton } from "components/pages/Settings/constants";
import DetailInput, { InputType } from "components/pages/Settings/DetailInput";

import Spinner from "components/common/Spinner"
import styles from "styles/styles";

const TravelDetailIDs = {
    TravelerNumbers: {
        KnownTravellerNumber: 'knownTravellerNumber',
        RedressNumber: 'redressNumber',
    },
    HomeAirport: {
        HomeAirportCode: 'homeAirportCode'
    },
    FrequentFlyer: {
        VietnamAirlines: 'vnFfpNumber',
    },
    EmergencyContact: {
        FirstName: 'emergencyContactFirstName',
        LastName: 'emergencyContactLastName',
        PhoneNumber: 'emergencyContactPhoneNumber',
        Email: 'emergencyContactEmail',
    }
}

const Travel = () => {
    // Redux state
    const dispatch = useDispatch();
    const isFetchingUser = useSelector((state: any) => state.currentUser.isFetching)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)

    // Component state
    const [travelState, setTravelState] = useState<any>({
        knownTravellerNumber: currentUser.knownTravellerNumber,
        redressNumber: currentUser.redressNumber,
        homeAirportCode: currentUser.homeAirportCode,
        vnFfpNumber: currentUser.vnFfpNumber,
        emergencyContactFirstName: currentUser.emergencyContactFirstName,
        emergencyContactLastName: currentUser.emergencyContactLastName,
        emergencyContactPhoneNumber: currentUser.emergencyContactPhoneNumber,
        emergencyContactEmail: currentUser.emergencyContactEmail,
    })
    const [activeEditingField, setActiveEditingField] = useState(undefined)

    const saveTravelDetails = () => {
        const formattedTravelState = JSON.parse(JSON.stringify(travelState))
        dispatch(Actions.saveCurrentUser(formattedTravelState))
        setActiveEditingField(undefined)
    }

    const setFieldEditable = (ev: React.MouseEvent<HTMLDivElement>) => {
        setActiveEditingField((ev.target as any).id)
    }

    const updateTravelState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTravelState({ ...travelState, [event.target.id]: event.target.value.trim() })
    }

    const getDetailInput = (inputID: string, type?: InputType) => {
        return <DetailInput 
            selectField={setFieldEditable}
            id={inputID}
            isEditing={activeEditingField === inputID}
            onChange={updateTravelState}
            value={travelState[inputID]}
            type={type}
        />
    }

    useEffect(() => {
        setTravelState({
            knownTravellerNumber: currentUser.knownTravellerNumber,
            redressNumber: currentUser.redressNumber,
            homeAirportCode: currentUser.homeAirportCode,
            vnFfpNumber: currentUser.vnFfpNumber,
            emergencyContactFirstName: currentUser.emergencyContactFirstName,
            emergencyContactLastName: currentUser.emergencyContactLastName,
            emergencyContactPhoneNumber: currentUser.emergencyContactPhoneNumber,
            emergencyContactEmail: currentUser.emergencyContactEmail,
        })
    }, [currentUser])

    return <div>
        <Title>Travel</Title>
        <div>
            <SettingItem>
                <Subtitle>Traveler Numbers</Subtitle>
                <SubsectionContent>
                    <InputContainer>
                        <div>Known Traveler Number:</div>
                        {getDetailInput(TravelDetailIDs.TravelerNumbers.KnownTravellerNumber)}
                    </InputContainer>
                    <InputContainer>
                        <div>Redress Number:</div>
                        {getDetailInput(TravelDetailIDs.TravelerNumbers.RedressNumber)}
                    </InputContainer>
                </SubsectionContent>
            </SettingItem>
            <SettingItem>
                <Subtitle>Home Airport</Subtitle>
                <SubsectionContent>
                    <InputContainer>
                        <div>Home Airport:</div>
                        {getDetailInput(TravelDetailIDs.HomeAirport.HomeAirportCode, InputType.Airport)}
                    </InputContainer>
                </SubsectionContent>
            </SettingItem>
            <SettingItem>
                <Subtitle>Frequent Flyer Program Memberships</Subtitle>
                <SubsectionContent>
                    <InputContainer>
                        <div>Vietnam Airlines:</div>
                        {getDetailInput(TravelDetailIDs.FrequentFlyer.VietnamAirlines)}
                    </InputContainer>
                </SubsectionContent>
            </SettingItem>
            <SettingItem>
                <Subtitle>Emergency Contact</Subtitle>
                <SubsectionContent>
                    <InputContainer>
                        <div>First Name:</div>
                        {getDetailInput(TravelDetailIDs.EmergencyContact.FirstName)}
                    </InputContainer>
                    <InputContainer>
                        <div>Last Name:</div>
                        {getDetailInput(TravelDetailIDs.EmergencyContact.LastName)}
                    </InputContainer>
                    <InputContainer>
                        <div>Phone:</div>
                        {getDetailInput(TravelDetailIDs.EmergencyContact.PhoneNumber)}
                    </InputContainer>
                    <InputContainer>
                        <div>Email:</div>
                        {getDetailInput(TravelDetailIDs.EmergencyContact.Email)}
                    </InputContainer>
                </SubsectionContent>
            </SettingItem>
        </div>
        <ContentFooter>
            {
                isFetchingUser ? <SpinnerContainer><Spinner/></SpinnerContainer> : <SaveButton onClick={saveTravelDetails}>Save</SaveButton>
            }            
        </ContentFooter>
    </div>
}

const SubsectionContent = styled.div`
    font-size: ${styles.Font.Size.Small};
    width: 100%;
`

const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin: ${styles.Spacing.XS} 0;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const SettingItem = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk}
    display: flex;
    height: fit-content;
    min-height: 100px;
    padding: ${styles.Spacing.S} 0;
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        justify-content: space-between;
    }
`

const Subtitle = styled.div`
    ${styles.Text.DisplaySmall}
    font-size: ${styles.Spacing.S};
`

const ContentFooter = styled.div`
    display: flex;
    justify-content: end;
`

const SpinnerContainer = styled.div`
    width: 24px;
    height: 24px;
    margin: ${styles.Spacing.S} ${styles.Spacing.M};
    display: flex;
    align-items: center;
    justify-content: center;
`



export default Travel;