import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import styled from "styled-components"

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import Button, { ButtonSize, ButtonType } from "components/common/Button"
import Modal from "components/common/Modal"
import Spinner from "components/common/Spinner"
import StatusDisplay from "components/common/StatusDisplay"

import styles from "styles/styles"

type ActivateCardModalProps = {
    onClose: () => void;
}

const PIN_CLASSNAME = 'pin'
const CONFIRM_PIN_CLASSNAME = 'confirmPin'

const ActivateCardModal = (props: ActivateCardModalProps) => {
    const dispatch = useDispatch()

    const banking = useSelector((state: any) => state.banking)
    const paymentCards = useSelector((state: any) => state.paymentCards)
    const inactivePhysicalCard = useSelector((state: any) => state.paymentCards.paymentCards.paymentCards).slice(1).find((card: any) => card.formFactor === 'PHYSICAL' && card.status === 'UNACTIVATED')

    const [cardNumber, setCardNumber] = useState('')
    const [pin, setPin] = useState(['', '', '', ''])
    const [pin2, setPin2] = useState(['', '', '', ''])
    const [focusedTabIndex, setFocusedTabIndex] = useState<Number | null>(null)

    const handleCardNumberOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const trimmedValue = ev.target.value.replaceAll(' ', '')
        if (trimmedValue.length <= 16 && /^[0-9]*$/.test(trimmedValue)) {
            setCardNumber(trimmedValue)
        }
        return
    }

    const activateCard = () => {
        dispatch(PaymentCardActions.activateCard({
            cardAccountUuid: banking.account.uuid,
            paymentCardUuid: inactivePhysicalCard.uuid,
            cardPan: cardNumber,
            pin: pin.join(''),
        }))
    }

    const dispatchPinSet = () => {
        dispatch(PaymentCardActions.setPin({
            cardAccountUuid: banking.account.uuid,
            pin: pin.join(''),
        }))
    }

    const handlePinInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const className = Array.from((ev.target as any).classList).at(-1)
        const inputs = document.querySelectorAll(`.${className}`);
        
        const setCallback = (className === PIN_CLASSNAME ? setPin : setPin2)
        const enteredChar = ev.target.value.replace('*', '')
        if (enteredChar && enteredChar !== '' && !isNaN(Number(enteredChar))) {
            setCallback((className === PIN_CLASSNAME ? pin : pin2).map((pinDigit, index) => index === ((ev.target.tabIndex - 1) % 4) ? enteredChar : pinDigit))

            if (ev.target.tabIndex !== 4 && ev.target.tabIndex !== 8) {
                (inputs[ev.target.tabIndex % 4] as HTMLElement).focus();
            } else {
                (document.activeElement as HTMLElement)?.blur();
            }
            setFocusedTabIndex(ev.target.tabIndex)
        } else if (enteredChar === undefined || enteredChar === '') {
            setCallback((className === PIN_CLASSNAME ? pin : pin2).map((pinDigit, index) => index === ((ev.target.tabIndex - 1) % 4) ? '' : pinDigit))   
        }
    }

    const isButtonDisabled = () => {
        // card number is not 16 digits (onChange handles )
        if (inactivePhysicalCard?.status === 'UNACTIVATED' && cardNumber.length !== 16) {
            return true
        }
        // pins are equal
        if (Number(pin.join('')) !== Number(pin2.join(''))) {
            return true
        }
        // validate pin length (also validating pin2 since it matches according to the above conditional)
        if (pin.findIndex(pinChar => pinChar === '') !== -1) {
            return true
        }
    }

    const getErrorMessage = () => {
        if ((paymentCards.setCardPin.error || paymentCards.activatePaymentCard.errors || {}).pin) {
            return 'Your PIN cannot include sequential digits.'
        }
        // if ((paymentCards.setCardPin.error || paymentCards.activatePaymentCard.errors?.nonFieldErrors)?.includes("Card PAN is not valid.")) {
        //     return 'Card number does not match.'
        // }
        if (paymentCards.setCardPin.error || paymentCards.activatePaymentCard.errors?.nonFieldErrors?.length) {
            return 'An unexpected error occurred. Please review your info and try again.'
        }
        return undefined
    }

    // if setCardPin changes loading state, and is succesful, close modal
    useEffect(() => {
        if (paymentCards.setCardPin.success) {
            props.onClose()
        }
    }, [paymentCards.setCardPin.isLoading]) // eslint-disable-line

    // if activatePaymentCard changes loading state, and is succesful, close modal
    useEffect(() => {
        if (paymentCards.activatePaymentCard.success) {
            props.onClose()
        }
    }, [paymentCards.activatePaymentCard.isLoading]) // eslint-disable-line

    const formatCardNumber = (cardNumber: string) => cardNumber.match(/.{1,4}/g)?.join(' ') || ''
    const errorMessage = getErrorMessage()
    const { onClose } = props;

    return <Modal
        title={inactivePhysicalCard?.status === 'UNACTIVATED' ? 'Activate Card' : 'Set Card PIN'}
        onClose={onClose}
    >
        <ModalBody>
            {inactivePhysicalCard?.status === 'UNACTIVATED' && <>
                <CustomInput value={formatCardNumber(cardNumber)} onChange={handleCardNumberOnChange} />
                <InputLabel>16 digit Card Number</InputLabel>
            </>}
            <PinRow>
                <PinInput tabIndex={1} className={PIN_CLASSNAME} onChange={handlePinInput} value={!pin[0] || focusedTabIndex === 1 ? pin[0] : '*'}/>
                <PinInput tabIndex={2} className={PIN_CLASSNAME} onChange={handlePinInput} value={!pin[1] || focusedTabIndex === 2 ? pin[1] : '*'}/>
                <PinInput tabIndex={3} className={PIN_CLASSNAME} onChange={handlePinInput} value={!pin[2] || focusedTabIndex === 3 ? pin[2] : '*'}/>
                <PinInput tabIndex={4} className={PIN_CLASSNAME} onChange={handlePinInput} value={!pin[3] || focusedTabIndex === 4 ? pin[3] : '*'}/>
            </PinRow>
            <InputLabel>4 digit PIN</InputLabel>
            <PinRow>
                <PinInput tabIndex={5} className={CONFIRM_PIN_CLASSNAME} onChange={handlePinInput} value={!pin2[0] || focusedTabIndex === 5 ? pin2[0] : '*'}/>
                <PinInput tabIndex={6} className={CONFIRM_PIN_CLASSNAME} onChange={handlePinInput} value={!pin2[1] || focusedTabIndex === 6 ? pin2[1] : '*'}/>
                <PinInput tabIndex={7} className={CONFIRM_PIN_CLASSNAME} onChange={handlePinInput} value={!pin2[2] || focusedTabIndex === 7 ? pin2[2] : '*'}/>
                <PinInput tabIndex={8} className={CONFIRM_PIN_CLASSNAME} onChange={handlePinInput} value={!pin2[3] || focusedTabIndex === 8 ? pin2[3] : '*'}/>
            </PinRow>
            <InputLabel>Confirm 4 digit PIN</InputLabel>
            <ErrorContainer>
                {errorMessage && <StatusDisplay isLoading={false} isError label={errorMessage}/>}
            </ErrorContainer>
            {(paymentCards.setCardPin.isLoading || paymentCards.activatePaymentCard.isLoading) ? <SpinnerContainer>
                <Spinner size={20}/>
            </SpinnerContainer> : <ModalButtonContainer>
                <Button
                    disabled={isButtonDisabled()}
                    size={ButtonSize.Fit}
                    buttonType={ButtonType.Purple}
                    label={inactivePhysicalCard?.status === 'UNACTIVATED' ? 'Activate' : 'Set PIN'}
                    onClick={inactivePhysicalCard?.status === 'UNACTIVATED' ? activateCard : dispatchPinSet}
                />
            </ModalButtonContainer>}
        </ModalBody>
    </Modal>
}

const ErrorContainer = styled.div`
    min-height: 24px;
    max-width: 320px;
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const SpinnerContainer = styled.div`
    height: 40px;
    margin-top: 20px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    ${styles.MediaQueries.Desktop} {
        width: 340px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const PinRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:first-child {
        margin-top: 20px;
    }
`

const PinInput = styled.input`
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${styles.Color.Grey};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40px;
    width: 40px;
    outline: 0;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    ${styles.Animation.transitionStyles};
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const ModalButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: ${styles.Spacing.S};
    height: 40px;
    ${styles.MediaQueries.Desktop} {
        min-width: 340px;
    }
    width: 100%;
`

const InputLabel = styled.div`
    opacity: 0.5;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    margin: 10px 0;
`

const CustomInput = styled.input`
    outline: 0;
    border-radius: 0;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${styles.Color.Grey};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 100%;
    font-size: 20px;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

export default ActivateCardModal