import React from "react";
import { useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { FaRegTimesCircle } from 'react-icons/fa'

import Spinner from "components/common/Spinner";

import { ReactComponent as Checkmark } from "assets/svg/Checkmark.svg"

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

type PriceModalProps = {
    liveScrapeIsLoading: boolean,
    awardTicketAvailable: boolean,
}

const PriceModal = (props: PriceModalProps) => {
    const flightBook = useSelector((state: any) => state.flightBook);

    const isLoading = flightBook.priceDetail?.isAwardTicket
        ? flightBook.pricingIsLoading || props.liveScrapeIsLoading
        : flightBook.pricingIsLoading;

    const showAwardTicketUnavailable = flightBook.priceDetail?.isAwardTicket && !props.awardTicketAvailable;

    let loadingMessage = "";
    if (flightBook.pricingIsLoading && props.liveScrapeIsLoading) {
        loadingMessage = "Loading your price details...";
    } else if (!flightBook.pricingIsLoading && props.liveScrapeIsLoading) {
        loadingMessage = "Confirming ticket availability...";
    } else if (flightBook.pricingIsLoading && !props.liveScrapeIsLoading) {
        loadingMessage = "Loading your price details...";
    }

    return (
        <Container>
            <AnimatePresence mode='wait'>
                {isLoading ? (
                    <motion.div {...fadeInOutMotionProps} className="d-flex align-items-center">
                        <SpinnerContainer>
                            <Spinner/>
                        </SpinnerContainer>
                        <div>{loadingMessage}</div>
                    </motion.div>
                ) : (
                    <div>
                        {flightBook.pricingError || showAwardTicketUnavailable ? (
                            <motion.div {...fadeInOutMotionProps} className="d-flex align-items-center">
                                <ErrorIcon fill='red'/>
                                <div>
                                    {showAwardTicketUnavailable ? "Unable to confirm availability for your ticket." : "There was an error fetching price details for your trip."}
                                </div>
                            </motion.div>
                        ) :
                            <motion.div {...fadeInOutMotionProps} className="d-flex align-items-center">
                                <SuccessIcon fill='#00bf0c'/>
                                <div>We've confirmed the booking details for your trip!</div>
                            </motion.div>
                        }
                    </div>
                )}
            </AnimatePresence>
        </Container>
    );
};

const SuccessIcon = styled(Checkmark)`
    margin-right: ${styles.Spacing.XS};
`

const ErrorIcon = styled(FaRegTimesCircle)`
    margin-right: ${styles.Spacing.XS};
`

const SpinnerContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.S};
`

const Container = styled.div`
    display: flex;
    align-items: center;
    border-left: 2px solid ${styles.Color.TaekusPurple};
    padding-left: ${styles.Spacing.S};
    height: ${styles.Spacing.M};
    margin-bottom: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

export default PriceModal