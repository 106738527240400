export const DropdownKeys = {
    Closed: '',
    DatePicker: 'datePicker',
    Funding: {
        Account: 'funding/account'
    },
    Navigation: {
        CardAccount: 'navigation/cardAccount',
        Settings: 'navigation/settings',
    },
    FlightBooking: {
        PaymentAccount: 'booking/paymentAccount'
    }
}