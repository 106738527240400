import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { debounce } from "utils/debounce";

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import HoverableCardDetails from "components/pages/Cards/MappedCardItems/HoverableCardDetails";
import StatusIndicator from "components/pages/Cards/CardStatusDropdown";
import { CardStatus } from "components/pages/Cards/constants";
import Tag from "components/pages/Cards/Tag";
import TagsCount from "components/pages/Cards/TagsCount";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

type Card = {
    nickname: string,
    uuid: string,
    formFactor: string,
    status: CardStatus,
    tags: string[],
}

type CardGridItemProps = {
    hoveredCardUuid: string | null,
    setHoveredCardUuid: (uuid: string | null) => void,
    card: Card,
    style?: any,
}

const CardGridItem = (props: CardGridItemProps) => {
    const history = useHistory()

    // Redux state
    const dispatch = useDispatch();
    const banking = useSelector((state: any) => state.banking)
    const cardAccountUuid = useSelector((state: any) => state.banking.account.uuid)

    // Component state
    const [cardName, setCardName] = useState(props.card.nickname)
    const [copyText, setCopyText] = useState('')

    const { card, hoveredCardUuid, setHoveredCardUuid } = props;

    const cardInspectorLink = `cards/${cardAccountUuid}/${card.uuid}`

    const isPhysicalCard = card.formFactor === 'PHYSICAL'

    const renameCard = (newName: string) => {
        const updatedPaymentCard = {
            ...card,
            nickname: newName,
            cardAccountUuid: cardAccountUuid,
            tags: Array.from(card.tags)
          }
      
        dispatch(PaymentCardActions.updatePaymentCard(updatedPaymentCard))
    }

    const debouncedRenameCard = useCallback(debounce(renameCard, 2000), []) // eslint-disable-line
    
    const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setCardName(ev.target.value)
        debouncedRenameCard(ev.target.value)
    }

    const navigateToCardInspector = () => {
        history.push(cardInspectorLink)
    }

    const updateCardStatus = (selectedOption: any) => {
        dispatch(PaymentCardActions.updatePaymentCard({
            ...props.card,
            status: selectedOption.value,
            oldCardStatus: props.card.status,
            cardAccountUuid: banking.account.uuid,
            tags: Array.from(props.card.tags)
        }))
    }

    // Tags are empty when only a single item "[]" appears
    const isTagsEmpty = card.tags.length === 1 && card.tags[0] === "[]"

    const clearCopyText = () => {
        setCopyText('')
    }

    const debouncedClearCopyText = useCallback(debounce(clearCopyText, 5000), []) // eslint-disable-line

    useEffect(() => {
        if (copyText !== '') {
            debouncedClearCopyText()
        }
    }, [copyText]) // eslint-disable-line

    return <div style={props.style}>
        <GridItem>
            <CustomLink to={cardInspectorLink}>
                <GridItemBody>
                    <GridHeader>
                        <NameInput
                            disabled={isPhysicalCard}
                            onClick={(ev: any) => { ev.preventDefault() }}
                            style={{width: `${cardName.length * 0.9}ch`}} value={isPhysicalCard ? 'Physical Card' : cardName}
                            onChange={handleNameChange}
                        />
                        <Status onClick={(ev: any) => { ev.preventDefault() }}>
                            <StatusIndicator onChange={updateCardStatus} status={card.status} />
                        </Status>
                    </GridHeader>
                    <AnimatePresence mode="wait">
                        <CopiedText {...fadeInOutMotionProps} key={copyText}>{copyText}</CopiedText>
                    </AnimatePresence>
                    <CustomHoverableCardDetails
                        hoveredCardUuid={hoveredCardUuid}
                        setHoveredCardUuid={setHoveredCardUuid}
                        card={card}
                        onClick={navigateToCardInspector}
                        updateCopyText={setCopyText}
                    />
                    <TagsContainer>
                        <Tag card={card} key={card.tags.filter((tag: string) => tag !== '[]').length.toString()}  selectKey={card.tags.filter((tag: string) => tag !== '[]').length.toString()} />
                        {!isTagsEmpty && card.tags.slice(0,4).map((tag: any, index: number) => <Tag key={`tag:${index}`} card={card} label={tag} />)}
                        <TagsCount card={card}/>
                    </TagsContainer>
                </GridItemBody>
            </CustomLink>
        </GridItem>
    </div>
}

const CopiedText = styled(motion.div)`
    margin-top: 30px;
    height: 20px;
    font-size: 14px;
    color: black;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
`

const GridItemBody = styled.div`
    padding: ${styles.Spacing.S};
`

const CustomLink = styled(Link)`
    min-width: 100%;
    min-height: 100%;
    &:hover {
        text-decoration: none;
    }
`

const NameInput = styled.input`
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
    background-color: transparent;
    outline: 0;
    min-width: 80px;
    max-width: 200px;
    font-weight: ${styles.Font.Weight[400]};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    text-overflow: ellipsis;
    margin-right: ${styles.Spacing.S};
    :disabled {
        border-bottom: 0;
        cursor: pointer;
    }
`

const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
`

const CustomHoverableCardDetails = styled(HoverableCardDetails)`
    margin-bottom: ${styles.Spacing.XS};
`

const GridHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

const GridItem = styled(motion.div)`
    border: 1px solid ${styles.Color.Grey};
    max-width: 360px;
    width: 360px;
    height: 390px;
    max-height: 390px;
    margin-top: ${styles.Spacing.XS};
    margin-bottom: ${styles.Spacing.XS};
    margin-right: ${styles.Spacing.S};
    cursor: pointer;
    color: inherit;
    text-decoration: inherit;
    &:hover {
        color: inherit;
        text-decoration: inherit;
        border: 1px solid ${styles.Color.TaekusPurple};
    }
    ${styles.Animation.transitionStyles}
`

const Status = styled.div`
    font-weight: ${styles.Font.Weight[400]};
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.5);
`

export default CardGridItem;