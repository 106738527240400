import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePlaidLink } from 'react-plaid-link'

import 'assets/components/card/css/payments.scss'

import API from 'services/api'
import { Actions } from 'redux/features/banking/linkedAccounts'

type PlaidModalProps = {
  children: React.ReactNode,
  linkedAccount?: any,
  style?: any,
}

const PlaidModal = (props: PlaidModalProps) => {
  // Redux state
  const dispatch = useDispatch()
  const banking = useSelector((state: any) => state.banking)

  // Component state
  const [showRenderLink, setShowRenderLink] = useState(false)

  const accountUuid = props.linkedAccount?.uuid

  const toggleShowRenderLink = () => {
    setShowRenderLink(!showRenderLink)
  }

  const success = (public_token: string, metadata: any) => {
    const { accounts } = metadata

    for (const account of accounts) {
      account['plaid_account_id'] = account['id']
      delete account['id']
      account['account_holder_type'] = account['class_type']
      delete account['class_type']
      account['account_last_4'] = account['mask']
      delete account['mask']
      // plaid and Taekus use 'type' differently...
      account['account_category'] = account['type']
      delete account['type']
      account['type'] = account['subtype']
      delete account['subtype']
    }

    if (accountUuid !== undefined) {
      dispatch(Actions.verifyMDV({ 
        account_uuid: accountUuid 
      }))
    } else {
      dispatch(Actions.createLinkedAccount({ 
        public_token: public_token,
        accounts: accounts,
        cardAccountUuid: banking.account.uuid
      }))
    }
  }
  
  const handleEvent = () => {}
  
  const handleExit = () => {}

  const handleClick = () => {
    toggleShowRenderLink()
  }

  return (
    <>
      <div style={props.style} onClick={handleClick}>{props.children}</div>
      {showRenderLink && (
        <PlaidLink
          onSuccess={success}
          onEvent={handleEvent}
          onExit={handleExit}
          accountUuid={accountUuid}
        />
      )}
    </>
  )

}

type PlaidLinkProps = {
  onSuccess: (public_token: string, metadata: any) => void,
  onEvent: () => void,
  onExit: () => void,
  accountUuid: string,
}

const PlaidLink = (props: PlaidLinkProps) => {
  const [token, setToken] = useState(null)

  React.useEffect(() => {
    const createLinkToken = async () => {
      const response = await API.banking.linkedAccounts.getPlaidLinkToken({
        accountUuid: props.accountUuid,
      })
      const { token } = await response.data
      setToken(token)
    }
    createLinkToken()
  }, []) // eslint-disable-line

  const { open, ready } = usePlaidLink({ 
    token,
    onSuccess: props.onSuccess,
    onEvent: props.onEvent,
    onExit: props.onExit
  })

  // open Link immediately when ready
  React.useEffect(() => {
    if (ready) {
      open()
    }
  }, [ready, open])

  return <></>
}

export default PlaidModal
