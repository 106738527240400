import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from 'react-router-dom'

import { noop } from "lodash";

import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion";
import { Modal } from "react-bootstrap";

import Session from "services/session";
import { Actions as BankingActions } from 'redux/features/banking/banking'
import { Actions as UserActions } from "redux/currentUser";

import { Locale, USDCurrencyOptions } from "utils/constants";

import { AppPath } from "components/appRouter/constants";
import Spinner from "components/common/Spinner";

import { fadeInOutMotionProps, fadeUpwardMotionProps } from "styles/motionConstants";
import styles from "styles/styles"
import { ReactComponent as TaekusLogoSVG } from "assets/svg/TaekusLogo.svg";
import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as DropdownArrow } from "assets/svg/DropdownArrow.svg";
import { ReactComponent as Menu } from "assets/svg/Menu.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";
import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";

const messages = {
    Home: 'Home',
    Rewards: 'Rewards',
    Funding: 'Funding',
    Cards: 'Cards',
    MyTrips: 'My Trips'
}

export enum NavColor {
    White='White',
    Black='Black',
}

enum NavigationDropdownKey {
    Rewards,
    CardAccount,
    Settings
}

type NavigationProps = {
    color: NavColor
}

const Navigation = (props: NavigationProps) => {
    // Redux state
    const dispatch = useDispatch();
    const isLoadingCardAccount = useSelector((state: any) => state.banking.isLoading)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const isUserLoading = useSelector((state: any) => state.currentUser.isFetching)
    const selectedAccount = useSelector((state: any) => state.banking.account)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    
    // Component state
    const [isOpen, setIsOpen] = useState(false)
    const [currentDropdownKey, setCurrentDropdownKey] = useState<NavigationDropdownKey | undefined>(undefined)
    const [shouldInitializeName, setShouldInitializeName] = useState<boolean | undefined>(undefined)

    const ref = useRef(null);

    const formatter = Intl.NumberFormat('en', { notation: 'compact' });

    const hasMultipleCardAccounts = currentUser?.cardAccounts?.length > 1

    const currentNickname = currentUser?.cardAccounts?.find((account: any) => account.uuid === selectedAccount.uuid)?.nickname
    const currentName = currentUser?.cardAccounts?.find((account: any) => account.uuid === selectedAccount.uuid)?.name
    const mainDisplayName = currentNickname ? `${currentNickname} (${currentName})` : currentName

    const getName = () => {
        /*
         *  If user's name is over 14 characters, display their initials instead
         *
         *  CSS styles are applied so that if the name is less than 14 characters but
         *  the characters are wide (ex: 'W'), the name will be ellipsis'd
         * 
         *  todo: update this to use hooks to detect if the content is overflowing
         */
        if (shouldInitializeName) {
            return `${currentUser.firstName[0].toUpperCase()}.${currentUser.lastName[0].toUpperCase()}.`
        }
        return currentUser.firstName
    }

    const toggleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    const logout = (ev: React.MouseEvent) => {
        ev.preventDefault();
        Session.logout()
        dispatch(UserActions.clearCurrentUser())
        window.location.replace('/login/')
    }

    const handleMobileAccountChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(BankingActions.fetchCardAccount({ cardAccountUuid: ev.target.value }))
    }
    
    const createDropdownOpener = (key: NavigationDropdownKey) => {
        return () => {
            setCurrentDropdownKey(key)
        }
    }

    const closeDropdowns = () => {
        setCurrentDropdownKey(undefined)
    }

    // replace any typing
    const mapCardAccountToDropdownItem = (account: any, index: number) => {
        let displayName = account.nickname ? `${account.nickname} (${account.name})` : account.name

        const handleClick = () => {
            if (selectedAccount.uuid !== account.uuid) {
                dispatch(BankingActions.fetchCardAccount({ cardAccountUuid: account.uuid }))
            }
        }

        return <CardAccountDropdownItem key={`dropdownItem:${index}`} onClick={handleClick}>
            <ActiveIndicatorWrapper>
                {account.uuid === selectedAccount.uuid && (isLoadingCardAccount ? <Spinner size={11}/> : <ActiveAccountIndicator />)}
            </ActiveIndicatorWrapper>
            <CenteredColumn>
                <DropdownItemTitle>
                    <AccountName>{displayName}</AccountName>
                </DropdownItemTitle>
                <div className="d-flex justify-content-between">
                    <CardAccountDetail>{account.availableBalance?.toLocaleString(Locale.English, USDCurrencyOptions)}</CardAccountDetail>
                    <CardAccountDetail>...{account.accountLast4?.slice(-4)}</CardAccountDetail>
                </div>
            </CenteredColumn>
        </CardAccountDropdownItem>
    }

    useEffect(() => {
        setShouldInitializeName((ref.current as any)?.scrollWidth > (ref.current as any)?.clientWidth)
    }, [currentUser])

    return <NavbarContainer>
        {!isMobile ? <>
            <LogoContainer>
                <NavLink exact to={AppPath.Home}>
                    <DesktopLogo fill={styles.Color[props.color]}/>
                </NavLink>
            </LogoContainer>
            <LinksContainer>
                <NavItem color={props.color} exact to={AppPath.Home}>{messages.Home}</NavItem>
                <DropdownWrapper
                    onMouseEnter={createDropdownOpener(NavigationDropdownKey.Rewards)}
                    onMouseLeave={closeDropdowns}
                >
                    <NavItem color={props.color} exact to={AppPath.Rewards}>{messages.Rewards}</NavItem>
                    {currentDropdownKey === NavigationDropdownKey.Rewards && <Dropdown {...fadeUpwardMotionProps}>
                        <DropdownCaretContainer>
                            <DropdownCaret/>
                        </DropdownCaretContainer>
                        <LinkDropdownItem>
                            <CustomLink to={AppPath.Rewards}>Redeem</CustomLink>
                        </LinkDropdownItem>
                        <LinkDropdownItem>
                            <CustomLink to={AppPath.RewardsActivity}>Rewards Activity</CustomLink>
                        </LinkDropdownItem>
                        <LinkDropdownItem>
                            <CustomLink to={AppPath.MyTrips}>My Trips</CustomLink>
                        </LinkDropdownItem>
                    </Dropdown>}
                </DropdownWrapper>
                <NavItem color={props.color} exact to={AppPath.Funding}>{messages.Funding}</NavItem>
                <NavItem color={props.color} exact to='/cards'>{messages.Cards}</NavItem>
                <CardAccountContainer
                    $hasMultipleCardAccounts={hasMultipleCardAccounts}
                    color={props.color}
                    onMouseEnter={hasMultipleCardAccounts ? createDropdownOpener(NavigationDropdownKey.CardAccount) : noop}
                    onMouseLeave={closeDropdowns}
                >
                    
                    {isUserLoading || isLoadingCardAccount ? <SpinnerContainer>
                        <Spinner size={11}/>
                    </SpinnerContainer> : <>
                        <ActiveAccountIndicator />
                        <CardAccountName color={props.color}>{mainDisplayName}</CardAccountName>
                        {hasMultipleCardAccounts && <StyledCaretDown fill={styles.Color[props.color]} />}
                    </>}
                    <AnimatePresence>
                        {currentDropdownKey === NavigationDropdownKey.CardAccount && <Dropdown {...fadeUpwardMotionProps}>
                            {currentUser?.cardAccounts?.map(mapCardAccountToDropdownItem)}
                        </Dropdown>}
                    </AnimatePresence>
                </CardAccountContainer>
                <PointsContainer
                    color={props.color}
                    onMouseEnter={createDropdownOpener(NavigationDropdownKey.Settings)}
                    onMouseLeave={closeDropdowns}
                >
                    {isUserLoading ? <SpinnerWrapper>
                        <SpinnerContainer>
                            <Spinner size={11}/>
                        </SpinnerContainer>
                    </SpinnerWrapper> : <Points color={props.color}>
                        <AccountName ref={ref}>{getName()}</AccountName>
                        <span>| {formatter.format(currentUser.numPoints)} pts</span>
                    </Points>}
                    <AnimatePresence>
                        {currentDropdownKey === NavigationDropdownKey.Settings && <Dropdown {...fadeUpwardMotionProps}>
                            <DropdownItem>
                                <CustomLink to={AppPath.Settings}>Settings</CustomLink>
                            </DropdownItem>
                            <DropdownItem onClick={logout}>Log out</DropdownItem>
                        </Dropdown>}
                    </AnimatePresence>
                </PointsContainer>
            </LinksContainer> 
        </> : <div className="w-100 h-100">
            <MobileNavbarContainer>
                <IconContainer as={NavLink} exact to={AppPath.Home}>
                    <TaekusIcon fill={styles.Color[props.color]}/>
                </IconContainer>
                <IconContainer onClick={toggleIsOpen}>
                    <Menu fill={styles.Color[props.color]}/>
                </IconContainer>            
            </MobileNavbarContainer>
            <AnimatePresence mode='wait'>
                {(isMobile && isOpen) && <motion.div {...fadeInOutMotionProps}>
                    <CustomModal fullscreen="true" show>
                        <ModalContent>
                            <MobileNavbarContainer>
                                <IconContainer as={NavLink} exact to={AppPath.Home}>
                                    <TaekusIcon/>
                                </IconContainer>
                                <IconContainer onClick={() => setIsOpen(!isOpen)}>
                                    <CustomClose/>
                                </IconContainer>            
                            </MobileNavbarContainer>
                            <MobileMenuHeader>
                                <div className="d-flex justify-content-between">
                                    <div>{currentUser.firstName}</div>
                                    <div>{currentUser.numPoints?.toLocaleString()} Points</div>
                                </div>
                                <MobileAccountSelectContainer>
                                    {isLoadingCardAccount ? <SpinnerContainer>
                                        <Spinner size={11}/>
                                    </SpinnerContainer> : (hasMultipleCardAccounts ? <div className="w-100 d-flex justify-content-between align-items-center">
                                        <MobileAccountSelect value={selectedAccount.uuid} onChange={handleMobileAccountChange}>
                                            {currentUser?.cardAccounts.map((account: any) => <MobileAccountOption key={`accountOption-${account.uuid}`} value={account.uuid} >{`${account.nickname ? `${account.nickname} (${account.name})` : account.name} ...${account.accountLast4}`}</MobileAccountOption>)}
                                        </MobileAccountSelect>
                                        <DropdownArrow/>
                                    </div> : <MobileSingleAccount>{mainDisplayName}</MobileSingleAccount>)}
                                </MobileAccountSelectContainer>
                            </MobileMenuHeader>
                            <MobileNavItemContainer>
                                <Column>
                                    <MobileNavItem exact to={AppPath.Home} onClick={toggleIsOpen}>Home</MobileNavItem>
                                    <MobileNavItem exact to={AppPath.Rewards} onClick={toggleIsOpen}>Rewards</MobileNavItem>
                                    <MobileSubNavItem exact to={AppPath.RewardsActivity} onClick={toggleIsOpen}>Rewards Activity</MobileSubNavItem>
                                    <MobileSubNavItem exact to={AppPath.MyTrips} onClick={toggleIsOpen}>My Trips</MobileSubNavItem>
                                    <MobileNavItem exact to={AppPath.Funding} onClick={toggleIsOpen}>Funding</MobileNavItem>
                                    <MobileNavItem exact to='/cards' onClick={toggleIsOpen}>Cards</MobileNavItem>
                                </Column>
                                <Column>
                                    <MobileNavItem exact to={AppPath.Settings} onClick={toggleIsOpen}>Settings</MobileNavItem>
                                    <MobileLogout onClick={logout}>Logout</MobileLogout>
                                </Column>
                            </MobileNavItemContainer>
                        </ModalContent>
                    </CustomModal>
                </motion.div>}
            </AnimatePresence>
        </div>}
    </NavbarContainer>
}

const StyledCaretDown = styled(CaretDown)`
    min-width: 10px;
    width: 10px;
    height: auto;
`

const CustomClose = styled(Close)`
    height: 24px;
    width: 24px;
`

const DesktopLogo = styled(TaekusLogoSVG)`
    height: 30px;
`

const MobileAccountOption = styled.option`
    color: black;
`

const MobileSingleAccount = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const MobileAccountSelectContainer = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 24px;
    display: flex;
    margin-top: 4px;
    border-bottom: 1px solid black;
    align-items: center;
    * {
        color: black;
    }
`

const MobileAccountSelect = styled.select`
    width: 100%;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: 0;
    border: 0;
    padding: 0;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`

const SpinnerWrapper = styled.div`
    width: 200px;
    display: flex;
    justify-content: center;
`

const AccountName = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
`

const MobileSubNavItem = styled(NavLink)`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    margin: 0 0 ${styles.Spacing.XS} 14px;
    font-weight: ${styles.Font.Weight[400]};
    line-height: 124%;
    letter-spacing: 0.42px;
    color: ${styles.Color.Black};
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: ${styles.Color.Black};
    }
`

const MobileNavItem = styled(NavLink)`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Medium};
    font-weight: ${styles.Font.Weight[400]};
    line-height: 124%;
    height: 52px;
    letter-spacing: 0.42px;
    color: ${styles.Color.Black};
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: ${styles.Color.Black};
    }
`

const MobileNavItemContainer = styled.div`
    padding: ${styles.Spacing.S};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`

const MobileLogout = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Medium};
    font-weight: ${styles.Font.Weight[400]}; 
    line-height: 124%;
    height: 52px;
    letter-spacing: 0.42px;
    color: black;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: black;
    }
`

const CardAccountDetail = styled.div`
    max-width: 50%;
`

const DropdownWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`

const ModalContent = styled.div`
    width: 100vw;
    max-width: -webkit-fill-available;
    height: 100vh;
    max-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
`

const CustomModal = styled(Modal)`
    .modal-dialog {
        margin: 0;
        max-width: none; 
    }

    .modal-content {
        border-radius: 0;
        background-color: ${styles.Color.TaekusSeaFoam};
        border: 0;
        width: 100%;
    }

    .modal-body {
        padding: 0;
    }
`

const IconContainer = styled.div`
    width: 24px;
    height: 24px;
`

const Dropdown = styled(motion.div)`
    top: ${styles.Spacing.S};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9px;
    background-color: ${styles.Color.White};
    width: 100%;
    overflow: hidden;
    min-width: 180px;
    position: absolute;
    border: 1px solid ${styles.Color.Grey};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const CustomLink = styled(Link)`
    color: ${styles.Color.Black};
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
        color: ${styles.Color.Black};
        text-decoration: none;
    }
`

const SpinnerContainer = styled.div`
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const labelStyles = `
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    /* identical to box height, or 20px */
    text-align: right;
    letter-spacing: 0.02em;
`

const CenteredColumn = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    padding-right: ${styles.Spacing.S};
    justify-content: center;
`

const ActiveIndicatorWrapper = styled.div`
    display: flex;
    min-width: 30px;
    align-items: center;
    justify-content: center;
`

const ActiveAccountIndicator = styled.div`
    background-color: ${styles.Color.TaekusPurple};
    min-width: 11px;
    min-height: 11px;
    border-radius: ${styles.BorderRadius.Half};
`

type ColorProps = {
    color: NavColor
}

const CardAccountName = styled.div<ColorProps>`
    ${labelStyles}
    color: ${props => styles.Color[props.color]};
    padding: 0 ${styles.Spacing.XS};
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const NavItem = styled(NavLink)<ColorProps>`
    ${labelStyles}
    height: 30px;
    padding: 4px ${styles.Spacing.S};
    color: ${props => styles.Color[props.color]};
    display: flex;
    cursor: pointer;
    text-decoration: inherit;
    &:hover {
        color: ${props => styles.Color[props.color]};
    }
    ${styles.Animation.transitionStyles}
`

const PointsContainer = styled.div<ColorProps>`
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 ${styles.Spacing.XS};
    height: 30px;
    width: 200px;
    padding: 4px 8px;
    cursor: pointer;
    ${styles.Animation.transitionStyles}
    border: 1px solid transparent;
    &:hover {
        border: 1px solid ${props => styles.Color[props.color]};;
    }
`

const Points = styled.div<ColorProps>`
    ${labelStyles}
    justify-content: center;
    width: 100%;
    display: flex;
    overflow: hidden;
    color: ${props => styles.Color[props.color]};
`

type CardAccountContainerProps = {
    color: NavColor;
    $hasMultipleCardAccounts: boolean;
}

const CardAccountContainer = styled.div<CardAccountContainerProps>`
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: center;
    width: 260px;
    padding: 4px 8px;
    ${styles.Animation.transitionStyles}
    border: 1px solid transparent;
    ${props => props.$hasMultipleCardAccounts && `
        cursor: pointer;
        &:hover {
            border: 1px solid ${props.color};;
        }
    `}
`

const DropdownItemTitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const DropdownCaretContainer = styled.div`
    position: absolute;
    z-index: 1;
`

const DropdownCaret = styled.div`
    background-color: white;
    rotate: 135deg;
    position: absolute;
    top: -8px;
    left: -${21.21/4}px;
    width: 15px;
    height: 15px;
    z-index: -1;
    border: 1px solid ${styles.Color.Grey};
`

const LinkDropdownItem = styled.div`
    ${styles.Text.BodySmall}
    color: ${styles.Color.Black};
    height: ${styles.Spacing.L};
    width: 100%;
    display: flex;
    z-index: 2;
    padding: 0 20px;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    background-color: white;
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: ${styles.Color.Grey};
    }
    &:not(&:last-child) {
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const CardAccountDropdownItem = styled.div`
    ${styles.Text.BodySmall}
    color: ${styles.Color.Black};
    height: ${styles.Spacing.L};
    width: 100%;
    display: flex;
    z-index: 2;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    background-color: white;
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: ${styles.Color.Grey};
    }
    &:not(&:last-child) {
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const DropdownItem = styled.div`
    ${styles.Text.BodySmall}
    color: ${styles.Color.Black};
    height: ${styles.Spacing.L};
    width: 100%;
    display: flex;
    z-index: 2;
    align-items: center;
    padding: 0 20px;
    overflow: hidden;
    cursor: pointer;
    background-color: white;
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: ${styles.Color.Grey};
    }
    &:not(&:last-child) {
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const LogoContainer = styled.div`
    margin-left: ${styles.Spacing.M};
    cursor: pointer;
`

const LinksContainer = styled.div`
    margin-right ${styles.Spacing.S};
    align-items: center;
    display: flex;
`

const MobileMenuHeader = styled.div`
    padding: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    border-bottom: 1px solid rgba(0,0,0,0.2);
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const MobileNavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${styles.Spacing.S};
    width: 100%;
    min-height: ${styles.Spacing.L};
`

const NavbarContainer = styled.div`
    height: ${styles.Spacing.L};
    min-height: ${styles.Spacing.L};
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    z-index: 10;
    white-space: nowrap;
`

export default Navigation