import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { motion } from "framer-motion";
import styled from "styled-components";

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import { categoryMccCodes } from "utils/categoryMccCodes";
import { Locale, USDCurrencyOptions } from "utils/constants";
import { simplifyCurrencyString } from "utils/utils";
import { isFeatureEnabled, ToggleFeature } from 'utils/toggles';

import DatePicker from "components/common/DatePicker/DatePicker";
import { filterButtonMessages } from "components/common/FilterButton";
import { TransactionFilterOptions, AdvancedTransactionFilter, defaultTransactionFilterOptions } from "components/common/Transactions/constants";
import Select from "components/common/Select/Select";
import selectStyles from "components/common/Select/selectStyles";
import Spinner from "components/common/Spinner";

import { TransactionType } from "components/pages/Home/constants";

import { fadeInOutMotionProps, slideFromRightMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

import { ReactComponent as CalendarSVG } from "assets/svg/Calendar.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";
import { ReactComponent as SearchIcon } from "assets/svg/Search.svg";

type TransactionFilterSidebarProps = {
    onClose: () => void;
    onOptionsChange: (options: TransactionFilterOptions) => void;
    options: TransactionFilterOptions;
    blacklist?: AdvancedTransactionFilter[]
}

const TransactionFilterSidebar = (props: TransactionFilterSidebarProps) => {
    const dispatch = useDispatch()

    // Redux state
    const banking = useSelector((state: any) => state.banking)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const cards = useSelector((state: any) => state.paymentCards.paymentCards.paymentCards).slice(1)
    const isLoadingCards = useSelector((state: any) => state.paymentCards.paymentCards.isLoading)
    const lastFetchedAccountUuid = useSelector((state: any) => state.paymentCards.paymentCards.lastFetchedAccountUuid)

    // toggle statuses
    const isRefundFilteringEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.RefundTransactionFiltering)

    // Component state
    const [filterOptions, setFilterOptions] = useState<TransactionFilterOptions>(props.options)
    const [cardSearch, setCardSearch] = useState('')
    const [categorySearch, setCategorySearch] = useState('')
    const [isMinimumFocused, setIsMinimumFocused] = useState(false)
    const [isMaximumFocused, setIsMaximumFocused] = useState(false)
    const [scrolledValue, setScrolledValue] = useState(0)

    const scrollableRef = useRef(null)

    
    let transactionTypes = [
        TransactionType.All,
        TransactionType.Purchase,
        TransactionType.Payment,
        TransactionType.Decline,
    ]
    if (isRefundFilteringEnabled) {
        transactionTypes = [
            TransactionType.All,
            TransactionType.Purchase,
            TransactionType.Refund,
            TransactionType.Payment,
            TransactionType.Decline,
        ]
    }
    const maxTransactionDate = moment()

    // filter only the first six cards that match the search string and aren't yet selected, then map to options
    const cardOptions = cards
        .filter((card: any) => !filterOptions.cardUuids.includes(card.uuid) && (cardSearch.length === 0 || card.nickname.toLowerCase().includes(cardSearch.toLowerCase()) || card.lastFour.includes(cardSearch)))
        .slice(0, 6)
        .map((card: any) => ({ value: card.uuid, label: `${card.nickname} ...${card.lastFour}` }))
    // filter categories that aren't selected, then map to options
    const categoryOptions = Object.keys(categoryMccCodes).filter((category: string) => !filterOptions.categories.includes(category)).map((category: string) => ({ value: category, label: category }))
    // format minimum and maximum input values when they're blurred
    const minimumAmountInputValue = filterOptions.minimumAmount ? (isMinimumFocused ? filterOptions.minimumAmount : Number(filterOptions.minimumAmount)?.toLocaleString(Locale.English, USDCurrencyOptions)) : ''
    const maximumAmountInputValue = filterOptions.maximumAmount ? (isMaximumFocused ? filterOptions.maximumAmount : Number(filterOptions.maximumAmount)?.toLocaleString(Locale.English, USDCurrencyOptions)) : ''

    const findCardByUuid = (cardUuid: string) => cards.find((card: any) => card.uuid === cardUuid)

    const updateScrolledValue = () => {
        const scrollAmount = (scrollableRef.current as any)?.scrollTop
        const totalHeight = (scrollableRef.current as any)?.scrollHeight - (scrollableRef.current as any)?.clientHeight
        setScrolledValue(scrollAmount / totalHeight)
    }

    (scrollableRef.current as any)?.addEventListener("scroll", updateScrolledValue);

    const clearFilterOptions = () => {
        setFilterOptions(defaultTransactionFilterOptions)
    }

    const handleCardSelection = (cardOption: { label: string, value: string }) => {
        setFilterOptions({
            ...filterOptions,
            cardUuids: [...filterOptions.cardUuids, cardOption.value]
        })
    }

    const deselectCard = (cardUuid: string) => {
        setFilterOptions({
            ...filterOptions,
            cardUuids: filterOptions.cardUuids.filter((uuid: string) => uuid !== cardUuid)
        })
    }

    const handleCategorySelection = (categoryOption: { label: string, value: string }) => {
        setFilterOptions({
            ...filterOptions,
            categories: [...filterOptions.categories, categoryOption.value]
        })
    }

    const deselectCategory = (category: string) => {
        setFilterOptions({
            ...filterOptions,
            categories: filterOptions.categories.filter((selectedCategory: string) => category !== selectedCategory)
        })
    }

    const handleTransactionDateRangeChange = (dates: [Date | undefined, Date | undefined]) => { 
        const [start, end] = dates;
        setFilterOptions({
            ...filterOptions,
            startDate: start ? moment(start) : filterOptions.startDate,
            endDate: end ? moment(end) : undefined
        })
    }

    const updateMinMaxAmount = (ev: React.ChangeEvent<HTMLInputElement>) => {
        // validate string as valid USD amount or empty string
        if (simplifyCurrencyString(ev.target.value) || ev.target.value === '') {
            setFilterOptions({
                ...filterOptions,
                [ev.target.id]: ev.target.value === '' ? undefined : ev.target.value,
            })
        }
    }

    const updateTransactionType = (ev: React.MouseEvent<HTMLButtonElement>) => { 
        setFilterOptions({ 
            ...filterOptions,
            type: (ev.target as any).id,
        })
    }

    const updateSearchString = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setFilterOptions({
            ...filterOptions,
            searchString: ev.target.value,
        })
    }

    const saveFilterOptions = () => {
        props.onOptionsChange(filterOptions);
        props.onClose();
    }

    const mapCardUuidToSelectedItem = (cardUuid: string) => {
        const card = findCardByUuid(cardUuid)
        const removeCard = () => { deselectCard(card.uuid) }

        return <SelectedItem key={cardUuid}>
            <ItemTitle>{card.nickname} ...{card.lastFour}</ItemTitle>
            {!blacklist?.includes(AdvancedTransactionFilter.CardUuids) && <ItemClose onClick={removeCard}/>}
        </SelectedItem>
    }

    const mapCategoryToSelectedItem = (category: string) => {
        const removeCategory = () => { deselectCategory(category) }

        return <SelectedItem key={category}>
            <ItemTitle>{category}</ItemTitle>
            <ItemClose onClick={removeCategory}/>
        </SelectedItem>
    }

    // Fetch card account cards if they haven't already been when the sidebar opens (and if cards isn't a blacklisted filter)
    useEffect(() => {
        const isCardsAlreadyLoaded = lastFetchedAccountUuid === banking.account.uuid

        if (!isCardsAlreadyLoaded && !blacklist?.includes(AdvancedTransactionFilter.CardUuids)) {
            dispatch(PaymentCardActions.fetchPaymentCards({ cardAccountUuid: banking.account.uuid }, false))
        }
    }, [dispatch]) // eslint-disable-line

    const { blacklist } = props;
    const { type, startDate, endDate, searchString } = filterOptions;

    return <Container {...fadeInOutMotionProps} onClick={props.onClose}>
        <Sidebar {...slideFromRightMotionProps} onClick={(ev: any) => { ev.stopPropagation() }}>
            <Header scrollAmount={scrolledValue}>
                <Title>Advanced Filters</Title>
                <StyledClose onClick={props.onClose} />
            </Header>
            <Scrollable ref={scrollableRef}>
                <FilterContainer>
                    <FilterTitle>Transaction Type</FilterTitle>
                    <FilterButtonContainer>
                        {transactionTypes.map((transactionType => <FilterButton
                            key={transactionType}
                            id={transactionType}
                            selected={type === transactionType}
                            onClick={updateTransactionType}
                        >
                            {filterButtonMessages[transactionType]}
                        </FilterButton>))}
                    </FilterButtonContainer>
                </FilterContainer>
                <FilterContainer>
                    <FilterTitle>Date Range</FilterTitle>
                    <FilterValueContainer>
                        <DatePicker 
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleTransactionDateRangeChange}
                            customInput={<DatePickerInput id='calendarInput'>
                                <CalendarIconWrapper>
                                    <CalendarSVG/>
                                </CalendarIconWrapper>
                                {`${filterOptions.startDate?.format("MMMM D, YYYY")} - ${filterOptions.endDate ? filterOptions.endDate.format("MMMM D, YYYY") : '...'}`}
                            </DatePickerInput>}
                            maxDate={maxTransactionDate}
                            selectsRange
                        />
                    </FilterValueContainer>
                </FilterContainer>
                <FilterContainer>
                    <FilterTitle>Merchant Name or Description</FilterTitle>
                    <SearchContainer>
                        <StyledSearchIcon />
                        <Search value={searchString} onChange={updateSearchString} placeholder='Search Transactions' />
                    </SearchContainer>
                </FilterContainer>
                <FilterContainer>
                    <FilterTitle>Cards</FilterTitle>
                    {isLoadingCards ? <div className="d-flex justify-content-center align-items-center">
                        <Spinner size={20}/>
                    </div> : <FilterValueContainer>
                        {!blacklist?.includes(AdvancedTransactionFilter.CardUuids) && <CardSelectContainer>
                            <Select
                                key={filterOptions.cardUuids.length}
                                options={cardOptions}
                                onChange={handleCardSelection}
                                isSearchable
                                styleType={selectStyles.TransactionFilter}
                                inputValue={cardSearch}
                                onInputChange={setCardSearch}
                                dropdownIndicator={<></>}
                                placeholder='Search card name or last four'
                            />
                        </CardSelectContainer>}
                        <div>
                            {filterOptions.cardUuids
                                .sort((a, b) => findCardByUuid(a).nickname.localeCompare(findCardByUuid(b).nickname))
                                .map(mapCardUuidToSelectedItem)}
                        </div>
                    </FilterValueContainer>}
                </FilterContainer>
                {!blacklist?.includes(AdvancedTransactionFilter.Categories) && <FilterContainer>
                    <FilterTitle>Category</FilterTitle>
                    <FilterValueContainer>
                        <CardSelectContainer>
                            <Select
                                key={filterOptions.categories.length}
                                options={categoryOptions}
                                onChange={handleCategorySelection}
                                isSearchable
                                styleType={selectStyles.TransactionFilter}
                                inputValue={categorySearch}
                                onInputChange={setCategorySearch}
                                dropdownIndicator={<></>}
                                placeholder='Search category'
                            />
                        </CardSelectContainer>
                        <div>
                            {filterOptions.categories
                                .sort((a, b) => a.localeCompare(b))
                                .map(mapCategoryToSelectedItem)}
                        </div>
                    </FilterValueContainer>
                </FilterContainer>}
                {!blacklist?.includes(AdvancedTransactionFilter.AmountRange) && <FilterContainer>
                    <FilterTitle>Transaction Amount</FilterTitle>
                    <TransactionAmountWrapper>
                        <AmountInputContainer>
                            <AmountInput
                                value={minimumAmountInputValue}
                                onFocus={() => setIsMinimumFocused(true)}
                                onBlur={() => setIsMinimumFocused(false)}
                                onChange={updateMinMaxAmount}
                                id='minimumAmount'
                                placeholder="no minimum"
                            />
                        </AmountInputContainer>
                        <AmountInputSeparator>-</AmountInputSeparator>
                        <AmountInputContainer>
                            <AmountInput
                                value={maximumAmountInputValue}
                                onFocus={() => setIsMaximumFocused(true)}
                                onBlur={() => setIsMaximumFocused(false)}
                                onChange={updateMinMaxAmount}
                                id='maximumAmount'
                                placeholder="no maximum"
                            />
                        </AmountInputContainer>
                    </TransactionAmountWrapper>
                </FilterContainer>}
            </Scrollable>
            <Footer scrollAmount={scrolledValue} >
                <ClearButtonContainer onClick={clearFilterOptions}>
                    <StyledClose/>
                    <div>Clear all options</div>
                </ClearButtonContainer>
                <SaveButton onClick={saveFilterOptions}>Save</SaveButton>
            </Footer>
        </Sidebar>
    </Container>
}

const SaveButton = styled.button`
    background-color: ${styles.Color.TaekusPurple};
    border: 0;
    outline: 0;
    color: white;
    margin: ${styles.Spacing.XS} 0;
    width: 100%;
    height: ${styles.Spacing.M};
`

const StyledSearchIcon = styled(SearchIcon)`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    fill: ${styles.Color.TaekusPurple};
    top: 3px;
    position: absolute;
`

type BookendProps = {
    scrollAmount?: number
}

const Header = styled.div<BookendProps>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,${props => Math.min((props.scrollAmount || 0) * 25, 0.4)});
    align-items: center;
    padding: ${styles.Spacing.S};
    ${styles.Animation.transitionStyles}
`

const Footer = styled.div<BookendProps>`
    width: 100%;
    border-top: 1px solid rgba(0,0,0,${props => Math.min((1 - (props.scrollAmount || 0)) * 25, 0.4)});
    align-items: center;
    padding: ${styles.Spacing.S};
    ${styles.Animation.transitionStyles}
`

const ItemClose = styled(Close)`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    cursor: pointer;
`

const ItemTitle = styled.div`
    flex: 1;
`

const SelectedItem = styled.div`
    width: 100%;
    display: flex;
    height: ${styles.Spacing.M};
    align-items: center;
    padding: 0 ${styles.Spacing.XS};
    &:not(&:last-child) {
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const Scrollable = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
    padding: 20px 0;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const FilterContainer = styled.div`
    &:not(&:first-child) {
        margin-top: ${styles.Spacing.S};
    }
`

const CardSelectContainer = styled.div`
    padding: 0 2px;
    width: 100%;
`

const AmountInputSeparator = styled.div`
    min-width: ${styles.Spacing.S};
    display: flex;
    align-items: center;
    justify-content: center;
`

const TransactionAmountWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 ${styles.Spacing.S};
`

const AmountInputContainer = styled.div`
    flex: 1;
`

const AmountInput = styled.input`
    border: 1px solid ${styles.Color.GreyText};
    border-radius: 2px;
    width: 100%;
    padding: 16px;
    outline: 0;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border: 1px solid ${styles.Color.TaekusPurple};
    }
`

const FilterTitle = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    margin-bottom: ${styles.Spacing.XS};
    padding: 0 ${styles.Spacing.S};
`

const FilterButtonContainer = styled.div`
    display: flex;
    overflow-x: auto;
    mask-image: linear-gradient(to right, transparent, black ${styles.Spacing.S}, black calc(100% - ${styles.Spacing.S}), transparent 100%);
    padding: 0 ${styles.Spacing.S};
    ${styles.Scrollbar.transparent}
`

const ClearButtonContainer = styled.div`
    display: flex;
    width: 145px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        text-decoration: underline;
    }
`

const SearchContainer = styled.div`
    position: relative;
    margin: 0 ${styles.Spacing.S};
`

const Search = styled.input`
    ${styles.Text.BodySmall}
    border: 0;
    box-sizing:border-box;
    border-bottom: 1px solid ${styles.Color.GreyText};
    background-color: ${styles.Color.Transparent};
    outline: none;
    color: ${styles.Color.Black};
    width: 100%;
    height: 30px;
    padding-bottom: 7px;
    padding-left: 28px;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const CalendarIconWrapper = styled.div`
    margin-left: ${styles.Spacing.XXXS};
    margin-right: 6px;
`

type FilterButtonProps = {
    selected?: boolean;
}

const FilterButton = styled.button<FilterButtonProps>`
    ${styles.Text.BodySmall}
    padding: ${styles.Spacing.XS} 25px;
    background-color: ${props => props.selected ? styles.Color.Black : styles.Color.Transparent};
    color: ${props => props.selected ? styles.Color.TaekusCream : styles.Color.GreyText};
    border: ${props => props.selected ? `1px solid transparent` : `1px solid ${styles.Color.Grey}`};
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border-radius: 30px;
    user-select: none;
    ${styles.Animation.transitionStyles}
    &:not(&:last-child) {
        margin-right: ${styles.Spacing.XS};
    }
`

const FilterValueContainer = styled.div`
    margin: 0 ${styles.Spacing.S};
`

const DatePickerInput = styled.div`
    ${styles.Text.BodySmall}
    flex: 1;
    border-bottom: 1px solid ${styles.Color.GreyText};
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
    padding-bottom: 7px;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const Title = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.24px;
`

const StyledClose = styled(Close)`
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const Sidebar = styled(motion.div)`
    background-color: white;
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Mobile} {
        max-width: 100%;
    }
`

const Container = styled(motion.div)`
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: end;
    z-index: 999;
`

export default TransactionFilterSidebar