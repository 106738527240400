import React from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import styled from "styled-components";

import { Bold } from "components/pages/Settings/constants";

import styles from "styles/styles";

const Personal = () => {
    // Redux state
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const parentUser = currentUser.parentUser

    const userFullName = `${currentUser.firstName} ${currentUser.middleName} ${currentUser.lastName}`
    const userBirthday = moment(currentUser.dateOfBirth).format('MMM D, YYYY')

    return <div>
        <Title>Personal</Title>
        <div>
            {parentUser ? <HelpText><b>Acting as an authorized user for {parentUser.firstName} {parentUser.lastName}</b></HelpText>:<></>}
            <HelpText>For assistance changing these details, please reach out to <PurpleText>support@taekus.com</PurpleText>.</HelpText>
            <SettingItem>
                <div>Username</div>
                <CustomBoldText>{currentUser.username}</CustomBoldText>
            </SettingItem>
            <SettingItem>
                <div>Name</div>
                <CustomBoldText>{userFullName}</CustomBoldText>
            </SettingItem>
            <SettingItem>
                <div>Date of Birth</div>
                <CustomBoldText>{userBirthday}</CustomBoldText>
            </SettingItem>
            <SettingItem>
                <div>Mailing Address</div>
                <CustomBoldText>
                    <div>{`${currentUser.mailingAddress.street} ${currentUser.mailingAddress.unitNum}`}</div>
                    <div>{`${currentUser.mailingAddress.city}, ${currentUser.mailingAddress.state} ${currentUser.mailingAddress.zip}`}</div>
                </CustomBoldText>
            </SettingItem>
            <SettingItem>
                <div>Phone Number</div>
                <CustomBoldText>{currentUser.phone}</CustomBoldText>
            </SettingItem>
            <SettingItem>
                <div>E-mail</div>
                <CustomBoldText>{currentUser.email}</CustomBoldText>
            </SettingItem>
        </div>
    </div>
}

const PurpleText = styled.span`
    color: ${styles.Color.TaekusPurple};
`

const HelpText = styled.div`
     ${styles.Text.BodySmall}
    font-size: ${styles.Font.Size.Small};
    padding-top: ${styles.Spacing.S};
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
`

const CustomBoldText = styled(Bold)`
    text-align: right;
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const SettingItem = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk}
    display: flex;
    height: 100px;
    display: flex;
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        justify-content: space-between;
        padding: ${styles.Spacing.XS} 0;
    }
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        justify-content: space-between;
    }
`

export default Personal;