import React from 'react'
import { FormattedMessage } from 'react-intl'

import 'assets/global/css/notificationBanner.scss'

const ArchivedAccountNotification = ({ account }) => {
  if (account.isArchived) {
    return (
      <div className="alert alert-danger text-center w-100 mb-0">
        <FormattedMessage
          id="statements.generation-error"
          defaultMessage={
            'This account is archived. Account and transaction data might not be complete. Taekus rewards remain valid and redeemable. For a complete account history, please refer to your statements.  For more information, click here:'
          }
        />
        &nbsp;
        <a href="https://taekus.com/migration" target="_blank" rel="noopener noreferrer">
          https://taekus.com/migration
        </a>
      </div>
    )
  }

  return null
}

export default ArchivedAccountNotification
