import React from 'react'

import moment from 'moment'
import classNames from 'classnames'

import DatePicker from 'react-datepicker'

import 'assets/global/css/dateInput.scss'

const DateInput = ({
  placeholderText,
  handleDateChange,
  selected,
  selectedVariableName,
  startDate = null,
  endDate = null,
  maxDate = null,
  minDate = null,
  dateFormat = 'MMMM d, yyyy',
  isClearable = false,
  datePickerClassName = '',
  startOrEnd = null, // Can be start, end, or null. useful for setting up ranges with two datepickers
  customInput = null,
  disabled = false,
}) => {
  const DatepickerHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="Common-datepickerHeader d-flex justify-content-between align-items-center mx-2">
      <div className="datepickerTitle ml-2">{moment(date).format('MMMM YYYY').toUpperCase()}</div>

      <div>
        <button
          className={classNames('StandardButtonNoStyles datepickerButton', {
            'datepickerButton-disabled': prevMonthButtonDisabled,
          })}
          onClick={decreaseMonth}
          type="button"
          disabled={prevMonthButtonDisabled}
        >
          {'<'}
        </button>
        <button
          className={classNames('StandardButtonNoStyles datepickerButton', {
            'datepickerButton-disabled': nextMonthButtonDisabled,
          })}
          onClick={increaseMonth}
          type="button"
          disabled={nextMonthButtonDisabled}
        >
          {'>'}
        </button>
      </div>
    </div>
  )

  let selectsStart = false
  let selectsEnd = false

  if (startOrEnd === 'start') {
    selectsStart = true
  } else if (startOrEnd === 'end') {
    selectsEnd = true
  }

  return (
    <span className="Common-datePicker">
      <DatePicker
        className={datePickerClassName}
        dateFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        isClearable={isClearable}
        selected={selected}
        useWeekdaysShort={true}
        placeholderText={placeholderText}
        onChange={(date) => handleDateChange(date, selectedVariableName)}
        startDate={startDate}
        endDate={endDate}
        calendarClassName="Common-datePickerCalendar"
        renderCustomHeader={(props) => <DatepickerHeader {...props} />}
        customInput={customInput}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        disabled={disabled}
      />
    </span>
  )
}

export default DateInput
