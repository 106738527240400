export const getNAICSCodes = () => {
  return [
    { value: 111110, label: 'Soybean Farming (111110)' },
    { value: 111120, label: 'Oilseed (except Soybean) Farming (111120)' },
    { value: 111130, label: 'Dry Pea and Bean Farming (111130)' },
    { value: 111140, label: 'Wheat Farming(111140)' },
    { value: 111150, label: 'Corn Farming (111150)' },
    { value: 111160, label: 'Rice Farming' },
    { value: 111191, label: 'Oilseed and Grain Combination Farming (111191)' },
    { value: 111199, label: 'All Other Grain Farming (111199)' },
    { value: 111211, label: 'Potato Farming (111211)' },
    { value: 111219, label: 'Other Vegetable (except Potato) and Melon Farming (111219)' },
    { value: 111310, label: 'Orange Groves(111310)' },
    { value: 111320, label: 'Citrus (except Orange) Groves (111320)' },
    { value: 111331, label: 'Apple Orchards (111331)' },
    { value: 111332, label: 'Grape Vineyards (111332)' },
    { value: 111333, label: 'Strawberry Farming (111333)' },
    { value: 111334, label: 'Berry (except Strawberry) Farming (111334)' },
    { value: 111335, label: 'Tree Nut Farming (111335)' },
    { value: 111336, label: 'Fruit and Tree Nut Combination Farming (111336)' },
    { value: 111339, label: 'Other Noncitrus Fruit Farming (111339)' },
    { value: 111411, label: 'Mushroom Production (111411)' },
    { value: 111419, label: 'Other Food Crops Grown Under Cover (111419)' },
    { value: 111421, label: 'Nursery and Tree Production (111421)' },
    { value: 111422, label: 'Floriculture Production (111422)' },
    { value: 111910, label: 'Tobacco Farming(111910)' },
    { value: 111920, label: 'Cotton Farming(111920)' },
    { value: 111930, label: 'Sugarcane Farming(111930)' },
    { value: 111940, label: 'Hay Farming (111940)' },
    { value: 111991, label: 'Sugar Beet Farming (111991)' },
    { value: 111992, label: 'Peanut Farming (111992)' },
    { value: 111998, label: 'All Other Miscellaneous Crop Farming (111998)' },
    { value: 112111, label: 'Beef Cattle Ranching and Farming (112111)' },
    { value: 112112, label: 'Cattle Feedlots (112112)' },
    { value: 112120, label: 'Dairy Cattle and Milk Production(112120)' },
    { value: 112130, label: 'Dual-Purpose Cattle Ranching and Farming (112130)' },
    { value: 112210, label: 'Hog and Pig Farming (112210)' },
    { value: 112310, label: 'Chicken Egg Production (112310)' },
    { value: 112320, label: 'Broilers and Other Meat Type Chicken Production (112320)' },
    { value: 112330, label: 'Turkey Production(112330)' },
    { value: 112340, label: 'Poultry Hatcheries(112340)' },
    { value: 112390, label: 'Other Poultry Production (112390)' },
    { value: 112410, label: 'Sheep Farming(112410)' },
    { value: 112420, label: 'Goat Farming(112420)' },
    { value: 112511, label: 'Finfish Farming and Fish Hatcheries (112511)' },
    { value: 112512, label: 'Shellfish Farming (112512)' },
    { value: 112519, label: 'Other Aquaculture (112519)' },
    { value: 112910, label: 'Apiculture(112910)' },
    { value: 112920, label: 'Horses and Other Equine Production(112920)' },
    { value: 112930, label: 'Fur-Bearing Animal and Rabbit Production(112930)' },
    { value: 112990, label: 'All Other Animal Production (112990)' },
    { value: 113110, label: 'Timber Tract Operations(113110)' },
    { value: 113210, label: 'Forest Nurseries and Gathering of Forest Products (113210)' },
    { value: 113310, label: 'Logging (113310)' },
    { value: 114111, label: 'Finfish Fishing (114111)' },
    { value: 114112, label: 'Shellfish Fishing (114112)' },
    { value: 114119, label: 'Other Marine Fishing (114119)' },
    { value: 114210, label: 'Hunting and Trapping(114210)' },
    { value: 115111, label: 'Cotton Ginning (115111)' },
    { value: 115112, label: 'Soil Preparation, Planting, and Cultivating (115112)' },
    { value: 115113, label: 'Crop Harvesting, Primarily by Machine (115113)' },
    { value: 115114, label: 'Postharvest Crop Activities (except Cotton Ginning) (115114)' },
    { value: 115115, label: 'Farm Labor Contractors and Crew Leaders (115115)' },
    { value: 115116, label: 'Farm Management Services (115116)' },
    { value: 115210, label: 'Support Activities for Animal Production(115210)' },
    { value: 115310, label: 'Support Activities for Forestry(115310)' },
    { value: 211120, label: 'Crude Petroleum Extraction (211120)' },
    { value: 211130, label: 'Natural Gas Extraction (211130)' },
    { value: 212114, label: 'Surface Coal Mining (212114)' },
    { value: 212115, label: 'Underground Coal Mining (212115)' },
    { value: 212210, label: 'Iron Ore Mining(212210)' },
    { value: 212220, label: 'Gold Ore and Silver Ore Mining (212220)' },
    { value: 212230, label: 'Copper, Nickel, Lead, and Zinc Mining (212230)' },
    { value: 212290, label: 'Other Metal Ore Mining (212290)' },
    { value: 212311, label: 'Dimension Stone Mining and Quarrying (212311)' },
    { value: 212312, label: 'Crushed and Broken Limestone Mining and Quarrying (212312)' },
    { value: 212313, label: 'Crushed and Broken Granite Mining and Quarrying (212313)' },
    { value: 212319, label: 'Other Crushed and Broken Stone Mining and Quarrying (212319)' },
    { value: 212321, label: 'Construction Sand and Gravel Mining (212321)' },
    { value: 212322, label: 'Industrial Sand Mining (212322)' },
    { value: 212323, label: 'Kaolin, Clay, and Ceramic and Refractory Minerals Mining (212323)' },
    { value: 212390, label: 'Other Nonmetallic Mineral Mining and Quarrying (212390)' },
    { value: 213111, label: 'Drilling Oil and Gas Wells(213111)' },
    { value: 213112, label: 'Support Activities for Oil and Gas Operations (213112)' },
    { value: 213113, label: 'Support Activities for Coal Mining (213113)' },
    { value: 213114, label: 'Support Activities for Metal Mining (213114)' },
    {
      value: 213115,
      label: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining (213115)',
    },
    { value: 221111, label: 'Hydroelectric Power Generation (221111)' },
    { value: 221112, label: 'Fossil Fuel Electric Power Generation (221112)' },
    { value: 221113, label: 'Nuclear Electric Power Generation (221113)' },
    { value: 221114, label: 'Solar Electric Power Generation (221114)' },
    { value: 221115, label: 'Wind Electric Power Generation (221115)' },
    { value: 221116, label: 'Geothermal Electric Power Generation (221116)' },
    { value: 221117, label: 'Biomass Electric Power Generation (221117)' },
    { value: 221118, label: 'Other Electric Power Generation (221118)' },
    { value: 221121, label: 'Electric Bulk Power Transmission and Control (221121)' },
    { value: 221122, label: 'Electric Power Distribution (221122)' },
    { value: 221210, label: 'Natural Gas Distribution (221210)' },
    { value: 221310, label: 'Water Supply and Irrigation Systems (221310)' },
    { value: 221320, label: 'Sewage Treatment Facilities (221320)' },
    { value: 221330, label: 'Steam and Air-Conditioning Supply (221330)' },
    {
      value: 236115,
      label: 'New Single-Family Housing Construction (except For-Sale Builders) (236115)',
    },
    {
      value: 236116,
      label: 'New Multifamily Housing Construction (except For-Sale Builders) (236116)',
    },
    { value: 236117, label: 'New Housing For-Sale Builders (236117)' },
    { value: 236118, label: 'Residential Remodelers (236118)' },
    { value: 236210, label: 'Industrial Building Construction (236210)' },
    { value: 236220, label: 'Commercial and Institutional Building Construction (236220)' },
    { value: 237110, label: 'Water and Sewer Line and Related Structures Construction (237110)' },
    { value: 237120, label: 'Oil and Gas Pipeline and Related Structures Construction (237120)' },
    {
      value: 237130,
      label: 'Power and Communication Line and Related Structures Construction (237130)',
    },
    { value: 237210, label: 'Land Subdivision (237210)' },
    { value: 237310, label: 'Highway, Street, and Bridge Construction (237310)' },
    { value: 237990, label: 'Other Heavy and Civil Engineering Construction (237990)' },
    { value: 238110, label: 'Poured Concrete Foundation and Structure Contractors (238110)' },
    { value: 238120, label: 'Structural Steel and Precast Concrete Contractors (238120)' },
    { value: 238130, label: 'Framing Contractors (238130)' },
    { value: 238140, label: 'Masonry Contractors (238140)' },
    { value: 238150, label: 'Glass and Glazing Contractors (238150)' },
    { value: 238160, label: 'Roofing Contractors (238160)' },
    { value: 238170, label: 'Siding Contractors (238170)' },
    {
      value: 238190,
      label: 'Other Foundation, Structure, and Building Exterior Contractors (238190)',
    },
    {
      value: 238210,
      label: 'Electrical Contractors and Other Wiring Installation Contractors(238210)',
    },
    { value: 238220, label: 'Plumbing, Heating, and Air-Conditioning Contractors (238220)' },
    { value: 238290, label: 'Other Building Equipment Contractors (238290)' },
    { value: 238310, label: 'Drywall and Insulation Contractors (238310)' },
    { value: 238320, label: 'Painting and Wall Covering Contractors(238320)' },
    { value: 238330, label: 'Flooring Contractors(238330)' },
    { value: 238340, label: 'Tile and Terrazzo Contractors(238340)' },
    { value: 238350, label: 'Finish Carpentry Contractors(238350)' },
    { value: 238390, label: 'Other Building Finishing Contractors(238390)' },
    { value: 238910, label: 'Site Preparation Contractors(238910)' },
    { value: 238990, label: 'All Other Specialty Trade Contractors(238990)' },
    { value: 311111, label: 'Dog and Cat Food Manufacturing (311111)' },
    { value: 311119, label: 'Other Animal Food Manufacturing (311119)' },
    { value: 311211, label: 'Flour Milling (311211)' },
    { value: 311212, label: 'Rice Milling (311212)' },
    { value: 311213, label: 'Malt Manufacturing (311213)' },
    { value: 311221, label: 'Wet Corn Milling and Starch Manufacturing (311221)' },
    { value: 311224, label: 'Soybean and Other Oilseed Processing (311224)' },
    { value: 311225, label: 'Fats and Oils Refining and Blending (311225)' },
    { value: 311230, label: 'Breakfast Cereal Manufacturing(311230)' },
    { value: 311313, label: 'Beet Sugar Manufacturing (311313)' },
    { value: 311314, label: 'Cane Sugar Manufacturing (311314)' },
    { value: 311340, label: 'Nonchocolate Confectionery Manufacturing(311340)' },
    { value: 311351, label: 'Chocolate and Confectionery Manufacturing from Cacao Beans (311351)' },
    { value: 311352, label: 'Confectionery Manufacturing from Purchased Chocolate (311352)' },
    { value: 311411, label: 'Frozen Fruit, Juice, and Vegetable Manufacturing (311411)' },
    { value: 311412, label: 'Frozen Specialty Food Manufacturing (311412)' },
    { value: 311421, label: 'Fruit and Vegetable Canning (311421)' },
    { value: 311422, label: 'Specialty Canning (311422)' },
    { value: 311423, label: 'Dried and Dehydrated Food Manufacturing (311423)' },
    { value: 311511, label: 'Fluid Milk Manufacturing (311511)' },
    { value: 311512, label: 'Creamery Butter Manufacturing (311512)' },
    { value: 311513, label: 'Cheese Manufacturing (311513)' },
    { value: 311514, label: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing (311514)' },
    { value: 311520, label: 'Ice Cream and Frozen Dessert Manufacturing(311520)' },
    { value: 311611, label: 'Animal (except Poultry) Slaughtering (311611)' },
    { value: 311612, label: 'Meat Processed from Carcasses (311612)' },
    { value: 311613, label: 'Rendering and Meat Byproduct Processing (311613)' },
    { value: 311615, label: 'Poultry Processing (311615)' },
    { value: 311710, label: 'Seafood Product Preparation and Packaging(311710)' },
    { value: 311811, label: 'Retail Bakeries (311811)' },
    { value: 311812, label: 'Commercial Bakeries (311812)' },
    { value: 311813, label: 'Frozen Cakes, Pies, and Other Pastries Manufacturing (311813)' },
    { value: 311821, label: 'Cookie and Cracker Manufacturing (311821)' },
    {
      value: 311824,
      label: 'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour (311824)',
    },
    { value: 311830, label: 'Tortilla Manufacturing(311830)' },
    { value: 311911, label: 'Roasted Nuts and Peanut Butter Manufacturing (311911)' },
    { value: 311919, label: 'Other Snack Food Manufacturing (311919)' },
    { value: 311920, label: 'Coffee and Tea Manufacturing (311920)' },
    { value: 311930, label: 'Flavoring Syrup and Concentrate Manufacturing(311930)' },
    {
      value: 311941,
      label: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing (311941)',
    },
    { value: 311942, label: 'Spice and Extract Manufacturing (311942)' },
    { value: 311991, label: 'Perishable Prepared Food Manufacturing (311991)' },
    { value: 311999, label: 'All Other Miscellaneous Food Manufacturing (311999)' },
    { value: 312111, label: 'Soft Drink Manufacturing (312111)' },
    { value: 312112, label: 'Bottled Water Manufacturing (312112)' },
    { value: 312113, label: 'Ice Manufacturing (312113)' },
    { value: 312120, label: 'Breweries(312120)' },
    { value: 312130, label: 'Wineries (312130)' },
    { value: 312140, label: 'Distilleries (312140)' },
    { value: 312230, label: 'Tobacco Manufacturing (312230)' },
    { value: 313110, label: 'Fiber, Yarn, and Thread Mills (313110)' },
    { value: 313210, label: 'Broadwoven Fabric Mills(313210)' },
    { value: 313220, label: 'Narrow Fabric Mills and Schiffli Machine Embroidery(313220)' },
    { value: 313230, label: 'Nonwoven Fabric Mills(313230)' },
    { value: 313240, label: 'Knit Fabric Mills(313240)' },
    { value: 313310, label: 'Textile and Fabric Finishing Mills (313310)' },
    { value: 313320, label: 'Fabric Coating Mills(313320)' },
    { value: 314110, label: 'Carpet and Rug Mills(314110)' },
    { value: 314120, label: 'Curtain and Linen Mills(314120)' },
    { value: 314910, label: 'Textile Bag and Canvas Mills (314910)' },
    { value: 314994, label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills (314994)' },
    { value: 314999, label: 'All Other Miscellaneous Textile Product Mills (314999)' },
    { value: 315120, label: 'Apparel Knitting Mills(315120)' },
    { value: 315210, label: 'Cut and Sew Apparel Contractors (315210)' },
    { value: 315250, label: 'Cut and Sew Apparel Manufacturing (except Contractors) (315250)' },
    { value: 315990, label: 'Apparel Accessories and Other Apparel Manufacturing (315990)' },
    { value: 316110, label: 'Leather and Hide Tanning and Finishing(316110)' },
    { value: 316210, label: 'Footwear Manufacturing (316210)' },
    { value: 316990, label: 'Other Leather and Allied Product Manufacturing (316990)' },
    { value: 321113, label: 'Sawmills (321113)' },
    { value: 321114, label: 'Wood Preservation (321114)' },
    { value: 321211, label: 'Hardwood Veneer and Plywood Manufacturing (321211)' },
    { value: 321212, label: 'Softwood Veneer and Plywood Manufacturing (321212)' },
    { value: 321215, label: 'Engineered Wood Member Manufacturing (321215)' },
    { value: 321219, label: 'Reconstituted Wood Product Manufacturing (321219)' },
    { value: 321911, label: 'Wood Window and Door Manufacturing (321911)' },
    { value: 321912, label: 'Cut Stock, Resawing Lumber, and Planing (321912)' },
    { value: 321918, label: 'Other Millwork (including Flooring) (321918)' },
    { value: 321920, label: 'Wood Container and Pallet Manufacturing(321920)' },
    { value: 321991, label: 'Manufactured Home (Mobile Home) Manufacturing (321991)' },
    { value: 321992, label: 'Prefabricated Wood Building Manufacturing (321992)' },
    { value: 321999, label: 'All Other Miscellaneous Wood Product Manufacturing (321999)' },
    { value: 322110, label: 'Pulp Mills (322110)' },
    { value: 322120, label: 'Paper Mills (322120)' },
    { value: 322130, label: 'Paperboard Mills (322130)' },
    { value: 322211, label: 'Corrugated and Solid Fiber Box Manufacturing (322211)' },
    { value: 322212, label: 'Folding Paperboard Box Manufacturing (322212)' },
    { value: 322219, label: 'Other Paperboard Container Manufacturing (322219)' },
    { value: 322220, label: 'Paper Bag and Coated and Treated Paper Manufacturing(322220)' },
    { value: 322230, label: 'Stationery Product Manufacturing(322230)' },
    { value: 322291, label: 'Sanitary Paper Product Manufacturing (322291)' },
    { value: 322299, label: 'All Other Converted Paper Product Manufacturing (322299)' },
    { value: 323111, label: 'Commercial Printing (except Screen and Books) (323111)' },
    { value: 323113, label: 'Commercial Screen Printing (323113)' },
    { value: 323117, label: 'Books Printing (323117)' },
    { value: 323120, label: 'Support Activities for Printing(323120)' },
    { value: 324110, label: 'Petroleum Refineries(324110)' },
    { value: 324121, label: 'Asphalt Paving Mixture and Block Manufacturing (324121)' },
    { value: 324122, label: 'Asphalt Shingle and Coating Materials Manufacturing (324122)' },
    { value: 324191, label: 'Petroleum Lubricating Oil and Grease Manufacturing (324191)' },
    { value: 324199, label: 'All Other Petroleum and Coal Products Manufacturing (324199)' },
    { value: 325110, label: 'Petrochemical Manufacturing(325110)' },
    { value: 325120, label: 'Industrial Gas Manufacturing(325120)' },
    { value: 325130, label: 'Synthetic Dye and Pigment Manufacturing(325130)' },
    { value: 325180, label: 'Other Basic Inorganic Chemical Manufacturing (325180)' },
    { value: 325193, label: 'Ethyl Alcohol Manufacturing (325193)' },
    {
      value: 325194,
      label: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing (325194)',
    },
    { value: 325199, label: 'All Other Basic Organic Chemical Manufacturing (325199)' },
    { value: 325211, label: 'Plastics Material and Resin Manufacturing (325211)' },
    { value: 325212, label: 'Synthetic Rubber Manufacturing (325212)' },
    { value: 325220, label: 'Artificial and Synthetic Fibers and Filaments Manufacturing(325220)' },
    { value: 325311, label: 'Nitrogenous Fertilizer Manufacturing (325311)' },
    { value: 325312, label: 'Phosphatic Fertilizer Manufacturing (325312)' },
    { value: 325314, label: 'Fertilizer (Mixing Only) Manufacturing (325314)' },
    { value: 325315, label: 'Compost Manufacturing(325315)' },
    { value: 325320, label: 'Pesticide and Other Agricultural Chemical Manufacturing(325320)' },
    { value: 325411, label: 'Medicinal and Botanical Manufacturing (325411)' },
    { value: 325412, label: 'Pharmaceutical Preparation Manufacturing (325412)' },
    { value: 325413, label: 'In-Vitro Diagnostic Substance Manufacturing (325413)' },
    { value: 325414, label: 'Biological Product (except Diagnostic) Manufacturing (325414)' },
    { value: 325510, label: 'Paint and Coating Manufacturing(325510)' },
    { value: 325520, label: 'Adhesive Manufacturing(325520)' },
    { value: 325611, label: 'Soap and Other Detergent Manufacturing (325611)' },
    { value: 325612, label: 'Polish and Other Sanitation Good Manufacturing (325612)' },
    { value: 325613, label: 'Surface Active Agent Manufacturing (325613)' },
    { value: 325620, label: 'Toilet Preparation Manufacturing(325620)' },
    { value: 325910, label: 'Printing Ink Manufacturing(325910)' },
    { value: 325920, label: 'Explosives Manufacturing(325920)' },
    { value: 325991, label: 'Custom Compounding of Purchased Resins (325991)' },
    {
      value: 325992,
      label: 'Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing (325992)',
    },
    {
      value: 325998,
      label: 'All Other Miscellaneous Chemical Product and Preparation Manufacturing (325998)',
    },
    { value: 326111, label: 'Plastics Bag and Pouch Manufacturing (326111)' },
    {
      value: 326112,
      label: 'Plastics Packaging Film and Sheet (including Laminated) Manufacturing (326112)',
    },
    {
      value: 326113,
      label: 'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing (326113)',
    },
    { value: 326121, label: 'Unlaminated Plastics Profile Shape Manufacturing (326121)' },
    { value: 326122, label: 'Plastics Pipe and Pipe Fitting Manufacturing (326122)' },
    {
      value: 326130,
      label: 'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing(326130)',
    },
    { value: 326140, label: 'Polystyrene Foam Product Manufacturing(326140)' },
    {
      value: 326150,
      label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing(326150)',
    },
    { value: 326160, label: 'Plastics Bottle Manufacturing(326160)' },
    { value: 326191, label: 'Plastics Plumbing Fixture Manufacturing (326191)' },
    { value: 326199, label: 'All Other Plastics Product Manufacturing (326199)' },
    { value: 326211, label: 'Tire Manufacturing (except Retreading) (326211)' },
    { value: 326212, label: 'Tire Retreading (326212)' },
    { value: 326220, label: 'Rubber and Plastics Hoses and Belting Manufacturing(326220)' },
    { value: 326291, label: 'Rubber Product Manufacturing for Mechanical Use (326291)' },
    { value: 326299, label: 'All Other Rubber Product Manufacturing (326299)' },
    { value: 327110, label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing (327110)' },
    { value: 327120, label: 'Clay Building Material and Refractories Manufacturing (327120)' },
    { value: 327211, label: 'Flat Glass Manufacturing (327211)' },
    { value: 327212, label: 'Other Pressed and Blown Glass and Glassware Manufacturing (327212)' },
    { value: 327213, label: 'Glass Container Manufacturing (327213)' },
    { value: 327215, label: 'Glass Product Manufacturing Made of Purchased Glass (327215)' },
    { value: 327310, label: 'Cement Manufacturing(327310)' },
    { value: 327320, label: 'Ready-Mix Concrete Manufacturing(327320)' },
    { value: 327331, label: 'Concrete Block and Brick Manufacturing (327331)' },
    { value: 327332, label: 'Concrete Pipe Manufacturing (327332)' },
    { value: 327390, label: 'Other Concrete Product Manufacturing (327390)' },
    { value: 327410, label: 'Lime Manufacturing(327410)' },
    { value: 327420, label: 'Gypsum Product Manufacturing(327420)' },
    { value: 327910, label: 'Abrasive Product Manufacturing(327910)' },
    { value: 327991, label: 'Cut Stone and Stone Product Manufacturing (327991)' },
    { value: 327992, label: 'Ground or Treated Mineral and Earth Manufacturing (327992)' },
    { value: 327993, label: 'Mineral Wool Manufacturing (327993)' },
    {
      value: 327999,
      label: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing (327999)',
    },
    { value: 331110, label: 'Iron and Steel Mills and Ferroalloy Manufacturing (331110)' },
    {
      value: 331210,
      label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel(331210)',
    },
    { value: 331221, label: 'Rolled Steel Shape Manufacturing (331221)' },
    { value: 331222, label: 'Steel Wire Drawing (331222)' },
    { value: 331313, label: 'Alumina Refining and Primary Aluminum Production (331313)' },
    { value: 331314, label: 'Secondary Smelting and Alloying of Aluminum (331314)' },
    { value: 331315, label: 'Aluminum Sheet, Plate, and Foil Manufacturing (331315)' },
    { value: 331318, label: 'Other Aluminum Rolling, Drawing, and Extruding (331318)' },
    { value: 331410, label: 'Nonferrous Metal (except Aluminum) Smelting and Refining (331410)' },
    { value: 331420, label: 'Copper Rolling, Drawing, Extruding, and Alloying(331420)' },
    {
      value: 331491,
      label:
        'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding (331491)',
    },
    {
      value: 331492,
      label:
        'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum) (331492)',
    },
    { value: 331511, label: 'Iron Foundries (331511)' },
    { value: 331512, label: 'Steel Investment Foundries (331512)' },
    { value: 331513, label: 'Steel Foundries (except Investment) (331513)' },
    { value: 331523, label: 'Nonferrous Metal Die-Casting Foundries (331523)' },
    { value: 331524, label: 'Aluminum Foundries (except Die-Casting) (331524)' },
    { value: 331529, label: 'Other Nonferrous Metal Foundries (except Die-Casting) (331529)' },
    { value: 332111, label: 'Iron and Steel Forging (332111)' },
    { value: 332112, label: 'Nonferrous Forging (332112)' },
    { value: 332114, label: 'Custom Roll Forming (332114)' },
    { value: 332117, label: 'Powder Metallurgy Part Manufacturing (332117)' },
    {
      value: 332119,
      label: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive) (332119)',
    },
    {
      value: 332215,
      label:
        'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing (332215)',
    },
    { value: 332216, label: 'Saw Blade and Handtool Manufacturing (332216)' },
    { value: 332311, label: 'Prefabricated Metal Building and Component Manufacturing (332311)' },
    { value: 332312, label: 'Fabricated Structural Metal Manufacturing (332312)' },
    { value: 332313, label: 'Plate Work Manufacturing (332313)' },
    { value: 332321, label: 'Metal Window and Door Manufacturing (332321)' },
    { value: 332322, label: 'Sheet Metal Work Manufacturing (332322)' },
    { value: 332323, label: 'Ornamental and Architectural Metal Work Manufacturing (332323)' },
    { value: 332410, label: 'Power Boiler and Heat Exchanger Manufacturing(332410)' },
    { value: 332420, label: 'Metal Tank (Heavy Gauge) Manufacturing(332420)' },
    { value: 332431, label: 'Metal Can Manufacturing (332431)' },
    { value: 332439, label: 'Other Metal Container Manufacturing (332439)' },
    { value: 332510, label: 'Hardware Manufacturing(332510)' },
    { value: 332613, label: 'Spring Manufacturing (332613)' },
    { value: 332618, label: 'Other Fabricated Wire Product Manufacturing (332618)' },
    { value: 332710, label: 'Machine Shops(332710)' },
    { value: 332721, label: 'Precision Turned Product Manufacturing (332721)' },
    { value: 332722, label: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing (332722)' },
    { value: 332811, label: 'Metal Heat Treating (332811)' },
    {
      value: 332812,
      label:
        'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers (332812)',
    },
    {
      value: 332813,
      label: 'Electroplating, Plating, Polishing, Anodizing, and Coloring (332813)',
    },
    { value: 332911, label: 'Industrial Valve Manufacturing (332911)' },
    { value: 332912, label: 'Fluid Power Valve and Hose Fitting Manufacturing (332912)' },
    { value: 332913, label: 'Plumbing Fixture Fitting and Trim Manufacturing (332913)' },
    { value: 332919, label: 'Other Metal Valve and Pipe Fitting Manufacturing (332919)' },
    { value: 332991, label: 'Ball and Roller Bearing Manufacturing(332991)' },
    { value: 332992, label: 'Small Arms Ammunition Manufacturing (332992)' },
    { value: 332993, label: 'Ammunition (except Small Arms) Manufacturing (332993)' },
    {
      value: 332994,
      label: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing (332994)',
    },
    { value: 332996, label: 'Fabricated Pipe and Pipe Fitting Manufacturing (332996)' },
    {
      value: 332999,
      label: 'All Other Miscellaneous Fabricated Metal Product Manufacturing (332999)',
    },
    { value: 333111, label: 'Farm Machinery and Equipment Manufacturing (333111)' },
    {
      value: 333112,
      label: 'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing (333112)',
    },
    { value: 333120, label: 'Construction Machinery Manufacturing(333120)' },
    { value: 333131, label: 'Mining Machinery and Equipment Manufacturing (333131)' },
    { value: 333132, label: 'Oil and Gas Field Machinery and Equipment Manufacturing (333132)' },
    { value: 333241, label: 'Food Product Machinery Manufacturing (333241)' },
    { value: 333242, label: 'Semiconductor Machinery Manufacturing (333242)' },
    { value: 333243, label: 'Sawmill, Woodworking, and Paper Machinery Manufacturing (333243)' },
    { value: 333248, label: 'All Other Industrial Machinery Manufacturing (333248)' },
    { value: 333310, label: 'Commercial and Service Industry Machinery Manufacturing (333310)' },
    {
      value: 333413,
      label:
        'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing (333413)',
    },
    { value: 333414, label: 'Heating Equipment (except Warm Air Furnaces) Manufacturing (333414)' },
    {
      value: 333415,
      label:
        'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing (333415)',
    },
    { value: 333511, label: 'Industrial Mold Manufacturing (333511)' },
    {
      value: 333514,
      label: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing (333514)',
    },
    { value: 333515, label: 'Cutting Tool and Machine Tool Accessory Manufacturing (333515)' },
    { value: 333517, label: 'Machine Tool Manufacturing (333517)' },
    {
      value: 333519,
      label: 'Rolling Mill and Other Metalworking Machinery Manufacturing (333519)',
    },
    { value: 333611, label: 'Turbine and Turbine Generator Set Units Manufacturing (333611)' },
    {
      value: 333612,
      label: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing (333612)',
    },
    { value: 333613, label: 'Mechanical Power Transmission Equipment Manufacturing (333613)' },
    { value: 333618, label: 'Other Engine Equipment Manufacturing (333618)' },
    { value: 333912, label: 'Air and Gas Compressor Manufacturing (333912)' },
    {
      value: 333914,
      label: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing (333914)',
    },
    { value: 333921, label: 'Elevator and Moving Stairway Manufacturing (333921)' },
    { value: 333922, label: 'Conveyor and Conveying Equipment Manufacturing (333922)' },
    {
      value: 333923,
      label: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing (333923)',
    },
    {
      value: 333924,
      label: 'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing (333924)',
    },
    { value: 333991, label: 'Power-Driven Handtool Manufacturing (333991)' },
    { value: 333992, label: 'Welding and Soldering Equipment Manufacturing (333992)' },
    { value: 333993, label: 'Packaging Machinery Manufacturing (333993)' },
    { value: 333994, label: 'Industrial Process Furnace and Oven Manufacturing (333994)' },
    { value: 333995, label: 'Fluid Power Cylinder and Actuator Manufacturing (333995)' },
    { value: 333996, label: 'Fluid Power Pump and Motor Manufacturing (333996)' },
    {
      value: 333998,
      label: 'All Other Miscellaneous General Purpose Machinery Manufacturing (333998)',
    },
    { value: 334111, label: 'Electronic Computer Manufacturing (334111)' },
    { value: 334112, label: 'Computer Storage Device Manufacturing (334112)' },
    {
      value: 334118,
      label: 'Computer Terminal and Other Computer Peripheral Equipment Manufacturing (334118)',
    },
    { value: 334210, label: 'Telephone Apparatus Manufacturing(334210)' },
    {
      value: 334220,
      label:
        'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing(334220)',
    },
    { value: 334290, label: 'Other Communications Equipment Manufacturing(334290)' },
    { value: 334310, label: 'Audio and Video Equipment Manufacturing(334310)' },
    { value: 334412, label: 'Bare Printed Circuit Board Manufacturing  (334412)' },
    { value: 334413, label: 'Semiconductor and Related Device Manufacturing (334413)' },
    {
      value: 334416,
      label: 'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing (334416)',
    },
    { value: 334417, label: 'Electronic Connector Manufacturing (334417)' },
    {
      value: 334418,
      label: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing (334418)',
    },
    { value: 334419, label: 'Other Electronic Component Manufacturing (334419)' },
    {
      value: 334510,
      label: 'Electromedical and Electrotherapeutic Apparatus Manufacturing (334510)',
    },
    {
      value: 334511,
      label:
        'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing (334511)',
    },
    {
      value: 334512,
      label:
        'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use (334512)',
    },
    {
      value: 334513,
      label:
        'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables (334513)',
    },
    { value: 334514, label: 'Totalizing Fluid Meter and Counting Device Manufacturing (334514)' },
    {
      value: 334515,
      label:
        'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals (334515)',
    },
    { value: 334516, label: 'Analytical Laboratory Instrument Manufacturing (334516)' },
    { value: 334517, label: 'Irradiation Apparatus Manufacturing (334517)' },
    { value: 334519, label: 'Other Measuring and Controlling Device Manufacturing (334519)' },
    { value: 334610, label: 'Manufacturing and Reproducing Magnetic and Optical Media (334610)' },
    { value: 335131, label: 'Residential Electric Lighting Fixture Manufacturing (335131)' },
    {
      value: 335132,
      label:
        'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing (335132)',
    },
    {
      value: 335139,
      label: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing (335139)',
    },
    { value: 335210, label: 'Small Electrical Appliance Manufacturing(335210)' },
    { value: 335220, label: 'Major Household Appliance Manufacturing (335220)' },
    {
      value: 335311,
      label: 'Power, Distribution, and Specialty Transformer Manufacturing (335311)',
    },
    { value: 335312, label: 'Motor and Generator Manufacturing (335312)' },
    { value: 335313, label: 'Switchgear and Switchboard Apparatus Manufacturing (335313)' },
    { value: 335314, label: 'Relay and Industrial Control Manufacturing (335314)' },
    { value: 335910, label: 'Battery Manufacturing (335910)' },
    { value: 335921, label: 'Fiber Optic Cable Manufacturing (335921)' },
    { value: 335929, label: 'Other Communication and Energy Wire Manufacturing (335929)' },
    { value: 335931, label: 'Current-Carrying Wiring Device Manufacturing (335931)' },
    { value: 335932, label: 'Noncurrent-Carrying Wiring Device Manufacturing (335932)' },
    { value: 335991, label: 'Carbon and Graphite Product Manufacturing (335991)' },
    {
      value: 335999,
      label: 'All Other Miscellaneous Electrical Equipment and Component Manufacturing (335999)',
    },
    { value: 336110, label: 'Automobile and Light Duty Motor Vehicle Manufacturing (336110)' },
    { value: 336120, label: 'Heavy Duty Truck Manufacturing(336120)' },
    { value: 336211, label: 'Motor Vehicle Body Manufacturing (336211)' },
    { value: 336212, label: 'Truck Trailer Manufacturing (336212)' },
    { value: 336213, label: 'Motor Home Manufacturing (336213)' },
    { value: 336214, label: 'Travel Trailer and Camper Manufacturing (336214)' },
    {
      value: 336310,
      label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing(336310)',
    },
    {
      value: 336320,
      label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing(336320)',
    },
    {
      value: 336330,
      label:
        'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing(336330)',
    },
    { value: 336340, label: 'Motor Vehicle Brake System Manufacturing(336340)' },
    {
      value: 336350,
      label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing(336350)',
    },
    { value: 336360, label: 'Motor Vehicle Seating and Interior Trim Manufacturing(336360)' },
    { value: 336370, label: 'Motor Vehicle Metal Stamping(336370)' },
    { value: 336390, label: 'Other Motor Vehicle Parts Manufacturing(336390)' },
    { value: 336411, label: 'Aircraft Manufacturing (336411)' },
    { value: 336412, label: 'Aircraft Engine and Engine Parts Manufacturing (336412)' },
    { value: 336413, label: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing (336413)' },
    { value: 336414, label: 'Guided Missile and Space Vehicle Manufacturing (336414)' },
    {
      value: 336415,
      label:
        'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing (336415)',
    },
    {
      value: 336419,
      label:
        'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing (336419)',
    },
    { value: 336510, label: 'Railroad Rolling Stock Manufacturing(336510)' },
    { value: 336611, label: 'Ship Building and Repairing (336611)' },
    { value: 336612, label: 'Boat Building (336612)' },
    { value: 336991, label: 'Motorcycle, Bicycle, and Parts Manufacturing (336991)' },
    {
      value: 336992,
      label: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing (336992)',
    },
    { value: 336999, label: 'All Other Transportation Equipment Manufacturing (336999)' },
    { value: 337110, label: 'Wood Kitchen Cabinet and Countertop Manufacturing(337110)' },
    { value: 337121, label: 'Upholstered Household Furniture Manufacturing (337121)' },
    { value: 337122, label: 'Nonupholstered Wood Household Furniture Manufacturing (337122)' },
    {
      value: 337126,
      label: 'Household Furniture (except Wood and Upholstered) Manufacturing (337126)',
    },
    { value: 337127, label: 'Institutional Furniture Manufacturing (337127)' },
    { value: 337211, label: 'Wood Office Furniture Manufacturing (337211)' },
    { value: 337212, label: 'Custom Architectural Woodwork and Millwork Manufacturing (337212)' },
    { value: 337214, label: 'Office Furniture (except Wood) Manufacturing (337214)' },
    { value: 337215, label: 'Showcase, Partition, Shelving, and Locker Manufacturing (337215)' },
    { value: 337910, label: 'Mattress Manufacturing(337910)' },
    { value: 337920, label: 'Blind and Shade Manufacturing(337920)' },
    { value: 339112, label: 'Surgical and Medical Instrument Manufacturing (339112)' },
    { value: 339113, label: 'Surgical Appliance and Supplies Manufacturing (339113)' },
    { value: 339114, label: 'Dental Equipment and Supplies Manufacturing (339114)' },
    { value: 339115, label: 'Ophthalmic Goods Manufacturing (339115)' },
    { value: 339116, label: 'Dental Laboratories (339116)' },
    { value: 339910, label: 'Jewelry and Silverware Manufacturing (339910)' },
    { value: 339920, label: 'Sporting and Athletic Goods Manufacturing(339920)' },
    { value: 339930, label: 'Doll, Toy, and Game Manufacturing(339930)' },
    { value: 339940, label: 'Office Supplies (except Paper) Manufacturing(339940)' },
    { value: 339950, label: 'Sign Manufacturing(339950)' },
    { value: 339991, label: 'Gasket, Packing, and Sealing Device Manufacturing (339991)' },
    { value: 339992, label: 'Musical Instrument Manufacturing (339992)' },
    { value: 339993, label: 'Fastener, Button, Needle, and Pin Manufacturing (339993)' },
    { value: 339994, label: 'Broom, Brush, and Mop Manufacturing (339994)' },
    { value: 339995, label: 'Burial Casket Manufacturing (339995)' },
    { value: 339999, label: 'All Other Miscellaneous Manufacturing (339999)' },
    { value: 423110, label: 'Automobile and Other Motor Vehicle Merchant Wholesalers (423110)' },
    { value: 423120, label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers (423120)' },
    { value: 423130, label: 'Tire and Tube Merchant Wholesalers (423130)' },
    { value: 423140, label: 'Motor Vehicle Parts (Used) Merchant Wholesalers (423140)' },
    { value: 423210, label: 'Furniture Merchant Wholesalers (423210)' },
    { value: 423220, label: 'Home Furnishing Merchant Wholesalers (423220)' },
    {
      value: 423310,
      label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers (423310)',
    },
    {
      value: 423320,
      label: 'Brick, Stone, and Related Construction Material Merchant Wholesalers (423320)',
    },
    {
      value: 423330,
      label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers (423330)',
    },
    { value: 423390, label: 'Other Construction Material Merchant Wholesalers (423390)' },
    { value: 423410, label: 'Photographic Equipment and Supplies Merchant Wholesalers (423410)' },
    { value: 423420, label: 'Office Equipment Merchant Wholesalers (423420)' },
    {
      value: 423430,
      label:
        'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers (423430)',
    },
    { value: 423440, label: 'Other Commercial Equipment Merchant Wholesalers (423440)' },
    {
      value: 423450,
      label: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers (423450)',
    },
    { value: 423460, label: 'Ophthalmic Goods Merchant Wholesalers (423460)' },
    {
      value: 423490,
      label: 'Other Professional Equipment and Supplies Merchant Wholesalers (423490)',
    },
    { value: 423510, label: 'Metal Service Centers and Other Metal Merchant Wholesalers (423510)' },
    { value: 423520, label: 'Coal and Other Mineral and Ore Merchant Wholesalers (423520)' },
    {
      value: 423610,
      label:
        'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers (423610)',
    },
    {
      value: 423620,
      label:
        'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers (423620)',
    },
    { value: 423690, label: 'Other Electronic Parts and Equipment Merchant Wholesalers (423690)' },
    { value: 423710, label: 'Hardware Merchant Wholesalers (423710)' },
    {
      value: 423720,
      label:
        'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers (423720)',
    },
    {
      value: 423730,
      label:
        'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers (423730)',
    },
    { value: 423740, label: 'Refrigeration Equipment and Supplies Merchant Wholesalers (423740)' },
    {
      value: 423810,
      label:
        'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers (423810)',
    },
    {
      value: 423820,
      label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers (423820)',
    },
    { value: 423830, label: 'Industrial Machinery and Equipment Merchant Wholesalers (423830)' },
    { value: 423840, label: 'Industrial Supplies Merchant Wholesalers(423840)' },
    {
      value: 423850,
      label: 'Service Establishment Equipment and Supplies Merchant Wholesalers (423850)',
    },
    {
      value: 423860,
      label:
        'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers (423860)',
    },
    {
      value: 423910,
      label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers (423910)',
    },
    { value: 423920, label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers (423920)' },
    { value: 423930, label: 'Recyclable Material Merchant Wholesalers (423930)' },
    {
      value: 423940,
      label: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers (423940)',
    },
    { value: 423990, label: 'Other Miscellaneous Durable Goods Merchant Wholesalers (423990)' },
    { value: 424110, label: 'Printing and Writing Paper Merchant Wholesalers (424110)' },
    { value: 424120, label: 'Stationery and Office Supplies Merchant Wholesalers (424120)' },
    { value: 424130, label: 'Industrial and Personal Service Paper Merchant Wholesalers (424130)' },
    { value: 424210, label: "Drugs and Druggists' Sundries Merchant Wholesalers (424210)" },
    {
      value: 424310,
      label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers (424310)',
    },
    { value: 424340, label: 'Footwear Merchant Wholesalers (424340)' },
    { value: 424350, label: 'Clothing and Clothing Accessories Merchant Wholesalers(424350)' },
    { value: 424410, label: 'General Line Grocery Merchant Wholesalers (424410)' },
    { value: 424420, label: 'Packaged Frozen Food Merchant Wholesalers (424420)' },
    {
      value: 424430,
      label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers (424430)',
    },
    { value: 424440, label: 'Poultry and Poultry Product Merchant Wholesalers (424440)' },
    { value: 424450, label: 'Confectionery Merchant Wholesalers (424450)' },
    { value: 424460, label: 'Fish and Seafood Merchant Wholesalers (424460)' },
    { value: 424470, label: 'Meat and Meat Product Merchant Wholesalers (424470)' },
    { value: 424480, label: 'Fresh Fruit and Vegetable Merchant Wholesalers (424480)' },
    { value: 424490, label: 'Other Grocery and Related Products Merchant Wholesalers (424490)' },
    { value: 424510, label: 'Grain and Field Bean Merchant Wholesalers (424510)' },
    { value: 424520, label: 'Livestock Merchant Wholesalers (424520)' },
    { value: 424590, label: 'Other Farm Product Raw Material Merchant Wholesalers (424590)' },
    {
      value: 424610,
      label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers (424610)',
    },
    { value: 424690, label: 'Other Chemical and Allied Products Merchant Wholesalers (424690)' },
    { value: 424710, label: 'Petroleum Bulk Stations and Terminals (424710)' },
    {
      value: 424720,
      label:
        'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) (424720)',
    },
    { value: 424810, label: 'Beer and Ale Merchant Wholesalers (424810)' },
    { value: 424820, label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers (424820)' },
    { value: 424910, label: 'Farm Supplies Merchant Wholesalers (424910)' },
    { value: 424920, label: 'Book, Periodical, and Newspaper Merchant Wholesalers (424920)' },
    {
      value: 424930,
      label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers (424930)",
    },
    {
      value: 424940,
      label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers (424940)',
    },
    { value: 424950, label: 'Paint, Varnish, and Supplies Merchant Wholesalers (424950)' },
    { value: 424990, label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers (424990)' },
    { value: 425120, label: 'Wholesale Trade Agents and Brokers (425120)' },
    { value: 441110, label: 'New Car Dealers (441110)' },
    { value: 441120, label: 'Used Car Dealers (441120)' },
    { value: 441210, label: 'Recreational Vehicle Dealers (441210)' },
    { value: 441222, label: 'Boat Dealers (441222)' },
    { value: 441227, label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers (441227)' },
    { value: 441330, label: 'Automotive Parts and Accessories Retailers (441330)' },
    { value: 441340, label: 'Tire Dealers (441340)' },
    { value: 444110, label: 'Home Centers (444110)' },
    { value: 444120, label: 'Paint and Wallpaper Retailers (444120)' },
    { value: 444140, label: 'Hardware Retailers (444140)' },
    { value: 444180, label: 'Other Building Material Dealers (444180)' },
    { value: 444230, label: 'Outdoor Power Equipment Retailers (444230)' },
    { value: 444240, label: 'Nursery, Garden Center, and Farm Supply Retailers (444240)' },
    {
      value: 445110,
      label: 'Supermarkets and Other Grocery Retailers (except Convenience Retailers) (445110)',
    },
    { value: 445131, label: 'Convenience Retailers (445131)' },
    { value: 445132, label: 'Vending Machine Operators (445132)' },
    { value: 445230, label: 'Fruit and Vegetable Retailers (445230)' },
    { value: 445240, label: 'Meat Retailers (445240)' },
    { value: 445250, label: 'Fish and Seafood Retailers (445250)' },
    { value: 445291, label: 'Baked Goods Retailers (445291)' },
    { value: 445292, label: 'Confectionery and Nut Retailers (445292)' },
    { value: 445298, label: 'All Other Specialty Food Retailers (445298)' },
    { value: 445320, label: 'Beer, Wine, and Liquor Retailers (445320)' },
    { value: 449110, label: 'Furniture Retailers (449110)' },
    { value: 449121, label: 'Floor Covering Retailers (449121)' },
    { value: 449122, label: 'Window Treatment Retailers (449122)' },
    { value: 449129, label: 'All Other Home Furnishings Retailers (449129)' },
    { value: 449210, label: 'Electronics and Appliance Retailers(449210)' },
    { value: 455110, label: 'Department Stores (455110)' },
    { value: 455211, label: 'Warehouse Clubs and Supercenters (455211)' },
    { value: 455219, label: 'All Other General Merchandise Retailers (455219)' },
    { value: 456110, label: 'Pharmacies and Drug Retailers (456110)' },
    { value: 456120, label: 'Cosmetics, Beauty Supplies, and Perfume Retailers (456120)' },
    { value: 456130, label: 'Optical Goods Retailers (456130)' },
    { value: 456191, label: 'Food (Health) Supplement Retailers (456191)' },
    { value: 456199, label: 'All Other Health and Personal Care Retailers (456199)' },
    { value: 457110, label: 'Gasoline Stations with Convenience Stores (457110)' },
    { value: 457120, label: 'Other Gasoline Stations (457120)' },
    { value: 457210, label: 'Fuel Dealers (457210)' },
    { value: 458110, label: 'Clothing and Clothing Accessories Retailers (458110)' },
    { value: 458210, label: 'Shoe Retailers (458210)' },
    { value: 458310, label: 'Jewelry Retailers (458310)' },
    { value: 458320, label: 'Luggage and Leather Goods Retailers (458320)' },
    { value: 459110, label: 'Sporting Goods Retailers (459110)' },
    { value: 459120, label: 'Hobby, Toy, and Game Retailers (459120)' },
    { value: 459130, label: 'Sewing, Needlework, and Piece Goods Retailers (459130)' },
    { value: 459140, label: 'Musical Instrument and Supplies Retailers (459140)' },
    { value: 459210, label: 'Book Retailers and News Dealers (459210)' },
    { value: 459310, label: 'Florists (459310)' },
    { value: 459410, label: 'Office Supplies and Stationery Retailers (459410)' },
    { value: 459420, label: 'Gift, Novelty, and Souvenir Retailers (459420)' },
    { value: 459510, label: 'Used Merchandise Retailers (459510)' },
    { value: 459910, label: 'Pet and Pet Supplies Retailers (459910)' },
    { value: 459920, label: 'Art Dealers (459920)' },
    { value: 459930, label: 'Manufactured (Mobile) Home Dealers (459930)' },
    {
      value: 459991,
      label: 'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers (459991)',
    },
    { value: 459999, label: 'All Other Miscellaneous Retailers (459999)' },
    { value: 481111, label: 'Scheduled Passenger Air Transportation (481111)' },
    { value: 481112, label: 'Scheduled Freight Air Transportation (481112)' },
    { value: 481211, label: 'Nonscheduled Chartered Passenger Air Transportation (481211)' },
    { value: 481212, label: 'Nonscheduled Chartered Freight Air Transportation (481212)' },
    { value: 481219, label: 'Other Nonscheduled Air Transportation (481219)' },
    { value: 482111, label: 'Line-Haul Railroads (482111)' },
    { value: 482112, label: 'Short Line Railroads (482112)' },
    { value: 483111, label: 'Deep Sea Freight Transportation (483111)' },
    { value: 483112, label: 'Deep Sea Passenger Transportation (483112)' },
    { value: 483113, label: 'Coastal and Great Lakes Freight Transportation (483113)' },
    { value: 483114, label: 'Coastal and Great Lakes Passenger Transportation (483114)' },
    { value: 483211, label: 'Inland Water Freight Transportation (483211)' },
    { value: 483212, label: 'Inland Water Passenger Transportation (483212)' },
    { value: 484110, label: 'General Freight Trucking, Local (484110)' },
    { value: 484121, label: 'General Freight Trucking, Long-Distance, Truckload (484121)' },
    {
      value: 484122,
      label: 'General Freight Trucking, Long-Distance, Less Than Truckload (484122)',
    },
    { value: 484210, label: 'Used Household and Office Goods Moving(484210)' },
    { value: 484220, label: 'Specialized Freight (except Used Goods) Trucking, Local (484220)' },
    {
      value: 484230,
      label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance (484230)',
    },
    { value: 485111, label: 'Mixed Mode Transit Systems (485111)' },
    { value: 485112, label: 'Commuter Rail Systems (485112)' },
    { value: 485113, label: 'Bus and Other Motor Vehicle Transit Systems (485113)' },
    { value: 485119, label: 'Other Urban Transit Systems (485119)' },
    { value: 485210, label: 'Interurban and Rural Bus Transportation(485210)' },
    { value: 485310, label: 'Taxi and Ridesharing Services (485310)' },
    { value: 485320, label: 'Limousine Service(485320)' },
    { value: 485410, label: 'School and Employee Bus Transportation(485410)' },
    { value: 485510, label: 'Charter Bus Industry(485510)' },
    { value: 485991, label: 'Special Needs Transportation (485991)' },
    { value: 485999, label: 'All Other Transit and Ground Passenger Transportation (485999)' },
    { value: 486110, label: 'Pipeline Transportation of Crude Oil(486110)' },
    { value: 486210, label: 'Pipeline Transportation of Natural Gas(486210)' },
    { value: 486910, label: 'Pipeline Transportation of Refined Petroleum Products(486910)' },
    { value: 486990, label: 'All Other Pipeline Transportation(486990)' },
    { value: 487110, label: 'Scenic and Sightseeing Transportation, Land(487110)' },
    { value: 487210, label: 'Scenic and Sightseeing Transportation, Water(487210)' },
    { value: 487990, label: 'Scenic and Sightseeing Transportation, Other(487990)' },
    { value: 488111, label: 'Air Traffic Control(488111)' },
    { value: 488119, label: 'Other Airport Operations (488119)' },
    { value: 488190, label: 'Other Support Activities for Air Transportation(488190)' },
    { value: 488210, label: 'Support Activities for Rail Transportation(488210)' },
    { value: 488310, label: 'Port and Harbor Operations(488310)' },
    { value: 488320, label: 'Marine Cargo Handling(488320)' },
    { value: 488330, label: 'Navigational Services to Shipping (488330)' },
    { value: 488390, label: 'Other Support Activities for Water Transportation(488390)' },
    { value: 488410, label: 'Motor Vehicle Towing(488410)' },
    { value: 488490, label: 'Other Support Activities for Road Transportation (488490)' },
    { value: 488510, label: 'Freight Transportation Arrangement (488510)' },
    { value: 488991, label: 'Packing and Crating (488991)' },
    { value: 488999, label: 'All Other Support Activities for Transportation (488999)' },
    { value: 491110, label: 'Postal Service(491110)' },
    { value: 492110, label: 'Couriers and Express Delivery Services(492110)' },
    { value: 492210, label: 'Local Messengers and Local Delivery(492210)' },
    { value: 493110, label: 'General Warehousing and Storage (493110)' },
    { value: 493120, label: 'Refrigerated Warehousing and Storage(493120)' },
    { value: 493130, label: 'Farm Product Warehousing and Storage(493130)' },
    { value: 493190, label: 'Other Warehousing and Storage(493190)' },
    { value: 512110, label: 'Motion Picture and Video Production (512110)' },
    { value: 512120, label: 'Motion Picture and Video Distribution(512120)' },
    { value: 512131, label: 'Motion Picture Theaters (except Drive-Ins) (512131)' },
    { value: 512132, label: 'Drive-In Motion Picture Theaters (512132)' },
    { value: 512191, label: 'Teleproduction and Other Postproduction Services (512191)' },
    { value: 512199, label: 'Other Motion Picture and Video Industries (512199)' },
    { value: 512230, label: 'Music Publishers(512230)' },
    { value: 512240, label: 'Sound Recording Studios(512240)' },
    { value: 512250, label: 'Record Production and Distribution(512250)' },
    { value: 512290, label: 'Other Sound Recording Industries(512290)' },
    { value: 513110, label: 'Newspaper Publishers (513110)' },
    { value: 513120, label: 'Periodical Publishers (513120)' },
    { value: 513130, label: 'Book Publishers (513130)' },
    { value: 513140, label: 'Directory and Mailing List Publishers (513140)' },
    { value: 513191, label: 'Greeting Card Publishers (513191)' },
    { value: 513199, label: 'All Other Publishers (513199)' },
    { value: 513210, label: 'Software Publishers(513210)' },
    { value: 516110, label: 'Radio Broadcasting Stations (516110)' },
    { value: 516120, label: 'Television Broadcasting Stations (516120)' },
    {
      value: 516210,
      label:
        'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers(516210)',
    },
    { value: 517111, label: 'Wired Telecommunications Carriers (517111)' },
    { value: 517112, label: 'Wireless Telecommunications Carriers (except Satellite)(517112)' },
    { value: 517121, label: 'Telecommunications Resellers(517121)' },
    { value: 517122, label: 'Agents for Wireless Telecommunications Services(517122)' },
    { value: 517410, label: 'Satellite Telecommunications(517410)' },
    { value: 517810, label: 'All Other Telecommunications (517810)' },
    {
      value: 518210,
      label:
        'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services(518210)',
    },
    { value: 519210, label: 'Libraries and Archives (519210)' },
    { value: 519290, label: 'Web Search Portals and All Other Information Services(519290)' },
    { value: 521110, label: 'Monetary Authorities-Central Bank(521110)' },
    { value: 522110, label: 'Commercial Banking (522110)' },
    { value: 522130, label: 'Credit Unions (522130)' },
    {
      value: 522180,
      label: 'Savings Institutions and Other Depository Credit Intermediation (522180)',
    },
    { value: 522210, label: 'Credit Card Issuing (522210)' },
    { value: 522220, label: 'Sales Financing (522220)' },
    { value: 522291, label: 'Consumer Lending (522291)' },
    { value: 522292, label: 'Real Estate Credit (522292)' },
    {
      value: 522299,
      label:
        'International, Secondary Market, and All Other Nondepository Credit Intermediation (522299)',
    },
    { value: 522310, label: 'Mortgage and Nonmortgage Loan Brokers (522310)' },
    {
      value: 522320,
      label: 'Financial Transactions Processing, Reserve, and Clearinghouse Activities (522320)',
    },
    { value: 522390, label: 'Other Activities Related to Credit Intermediation (522390)' },
    { value: 523150, label: 'Investment Banking and Securities Intermediation (523150)' },
    { value: 523160, label: 'Commodity Contracts Intermediation (523160)' },
    { value: 523210, label: 'Securities and Commodity Exchanges(523210)' },
    { value: 523910, label: 'Miscellaneous Intermediation (523910)' },
    { value: 523940, label: 'Portfolio Management and Investment Advice (523940)' },
    { value: 523991, label: 'Trust, Fiduciary, and Custody Activities (523991)' },
    { value: 523999, label: 'Miscellaneous Financial Investment Activities (523999)' },
    { value: 524113, label: 'Direct Life Insurance Carriers (524113)' },
    { value: 524114, label: 'Direct Health and Medical Insurance Carriers (524114)' },
    { value: 524126, label: 'Direct Property and Casualty Insurance Carriers (524126)' },
    { value: 524127, label: 'Direct Title Insurance Carriers (524127)' },
    {
      value: 524128,
      label: 'Other Direct Insurance (except Life, Health, and Medical) Carriers (524128)',
    },
    { value: 524130, label: 'Reinsurance Carriers (524130)' },
    { value: 524210, label: 'Insurance Agencies and Brokerages (524210)' },
    { value: 524291, label: 'Claims Adjusting (524291)' },
    {
      value: 524292,
      label:
        'Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds (524292)',
    },
    { value: 524298, label: 'All Other Insurance Related Activities (524298)' },
    { value: 525110, label: 'Pension Funds (525110)' },
    { value: 525120, label: 'Health and Welfare Funds (525120)' },
    { value: 525190, label: 'Other Insurance Funds (525190)' },
    { value: 525910, label: 'Open-End Investment Funds (525910)' },
    { value: 525920, label: 'Trusts, Estates, and Agency Accounts (525920)' },
    { value: 525990, label: 'Other Financial Vehicles (525990)' },
    { value: 531110, label: 'Lessors of Residential Buildings and Dwellings (531110)' },
    {
      value: 531120,
      label: 'Lessors of Nonresidential Buildings (except Miniwarehouses) (531120)',
    },
    { value: 531130, label: 'Lessors of Miniwarehouses and Self-Storage Units (531130)' },
    { value: 531190, label: 'Lessors of Other Real Estate Property (531190)' },
    { value: 531210, label: 'Offices of Real Estate Agents and Brokers(531210)' },
    { value: 531311, label: 'Residential Property Managers (531311)' },
    { value: 531312, label: 'Nonresidential Property Managers (531312)' },
    { value: 531320, label: 'Offices of Real Estate Appraisers (531320)' },
    { value: 531390, label: 'Other Activities Related to Real Estate (531390)' },
    { value: 532111, label: 'Passenger Car Rental (532111)' },
    { value: 532112, label: 'Passenger Car Leasing (532112)' },
    {
      value: 532120,
      label: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing (532120)',
    },
    { value: 532210, label: 'Consumer Electronics and Appliances Rental(532210)' },
    { value: 532281, label: 'Formal Wear and Costume Rental(532281)' },
    { value: 532282, label: 'Video Tape and Disc Rental(532282)' },
    { value: 532283, label: 'Home Health Equipment Rental (532283)' },
    { value: 532284, label: 'Recreational Goods Rental (532284)' },
    { value: 532289, label: 'All Other Consumer Goods Rental (532289)' },
    { value: 532310, label: 'General Rental Centers(532310)' },
    {
      value: 532411,
      label: 'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing (532411)',
    },
    {
      value: 532412,
      label:
        'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing (532412)',
    },
    { value: 532420, label: 'Office Machinery and Equipment Rental and Leasing(532420)' },
    {
      value: 532490,
      label: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing (532490)',
    },
    {
      value: 533110,
      label: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)(533110)',
    },
    { value: 541110, label: 'Offices of Lawyers(541110)' },
    { value: 541120, label: 'Offices of Notaries(541120)' },
    { value: 541191, label: 'Title Abstract and Settlement Offices (541191)' },
    { value: 541199, label: 'All Other Legal Services (541199)' },
    { value: 541211, label: 'Offices of Certified Public Accountants (541211)' },
    { value: 541213, label: 'Tax Preparation Services (541213)' },
    { value: 541214, label: 'Payroll Services (541214)' },
    { value: 541219, label: 'Other Accounting Services (541219)' },
    { value: 541310, label: 'Architectural Services(541310)' },
    { value: 541320, label: 'Landscape Architectural Services(541320)' },
    { value: 541330, label: 'Engineering Services(541330)' },
    { value: 541340, label: 'Drafting Services(541340)' },
    { value: 541350, label: 'Building Inspection Services(541350)' },
    { value: 541360, label: 'Geophysical Surveying and Mapping Services(541360)' },
    { value: 541370, label: 'Surveying and Mapping (except Geophysical) Services(541370)' },
    { value: 541380, label: 'Testing Laboratories and Services(541380)' },
    { value: 541410, label: 'Interior Design Services(541410)' },
    { value: 541420, label: 'Industrial Design Services(541420)' },
    { value: 541430, label: 'Graphic Design Services(541430)' },
    { value: 541490, label: 'Other Specialized Design Services(541490)' },
    { value: 541511, label: 'Custom Computer Programming Services (541511)' },
    { value: 541512, label: 'Computer Systems Design Services (541512)' },
    { value: 541513, label: 'Computer Facilities Management Services (541513)' },
    { value: 541519, label: 'Other Computer Related Services(541519)' },
    {
      value: 541611,
      label: 'Administrative Management and General Management Consulting Services (541611)',
    },
    { value: 541612, label: 'Human Resources Consulting Services (541612)' },
    { value: 541613, label: 'Marketing Consulting Services (541613)' },
    {
      value: 541614,
      label: 'Process, Physical Distribution, and Logistics Consulting Services (541614)',
    },
    { value: 541618, label: 'Other Management Consulting Services (541618)' },
    { value: 541620, label: 'Environmental Consulting Services(541620)' },
    { value: 541690, label: 'Other Scientific and Technical Consulting Services(541690)' },
    { value: 541713, label: 'Research and Development in Nanotechnology (541713)' },
    {
      value: 541714,
      label: 'Research and Development in Biotechnology (except Nanobiotechnology)(541714)',
    },
    {
      value: 541715,
      label:
        'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) (541715)',
    },
    {
      value: 541720,
      label: 'Research and Development in the Social Sciences and Humanities (541720)',
    },
    { value: 541810, label: 'Advertising Agencies(541810)' },
    { value: 541820, label: 'Public Relations Agencies(541820)' },
    { value: 541830, label: 'Media Buying Agencies(541830)' },
    { value: 541840, label: 'Media Representatives(541840)' },
    { value: 541850, label: 'Indoor and Outdoor Display Advertising(541850)' },
    { value: 541860, label: 'Direct Mail Advertising(541860)' },
    { value: 541870, label: 'Advertising Material Distribution Services(541870)' },
    { value: 541890, label: 'Other Services Related to Advertising (541890)' },
    { value: 541910, label: 'Marketing Research and Public Opinion Polling(541910)' },
    { value: 541921, label: 'Photography Studios, Portrait (541921)' },
    { value: 541922, label: 'Commercial Photography (541922)' },
    { value: 541930, label: 'Translation and Interpretation Services(541930)' },
    { value: 541940, label: 'Veterinary Services (541940)' },
    { value: 541990, label: 'All Other Professional, Scientific, and Technical Services(541990)' },
    { value: 551111, label: 'Offices of Bank Holding Companies (551111)' },
    { value: 551112, label: 'Offices of Other Holding Companies (551112)' },
    { value: 551114, label: 'Corporate, Subsidiary, and Regional Managing Offices (551114)' },
    { value: 561110, label: 'Office Administrative Services(561110)' },
    { value: 561210, label: 'Facilities Support Services(561210)' },
    { value: 561311, label: 'Employment Placement Agencies (561311)' },
    { value: 561312, label: 'Executive Search Services (561312)' },
    { value: 561320, label: 'Temporary Help Services(561320)' },
    { value: 561330, label: 'Professional Employer Organizations(561330)' },
    { value: 561410, label: 'Document Preparation Services(561410)' },
    { value: 561421, label: 'Telephone Answering Services (561421)' },
    { value: 561422, label: 'Telemarketing Bureaus and Other Contact Centers (561422)' },
    { value: 561431, label: 'Private Mail Centers (561431)' },
    { value: 561439, label: 'Other Business Service Centers (including Copy Shops) (561439)' },
    { value: 561440, label: 'Collection Agencies(561440)' },
    { value: 561450, label: 'Credit Bureaus(561450)' },
    { value: 561491, label: 'Repossession Services (561491)' },
    { value: 561492, label: 'Court Reporting and Stenotype Services (561492)' },
    { value: 561499, label: 'All Other Business Support Services (561499)' },
    { value: 561510, label: 'Travel Agencies(561510)' },
    { value: 561520, label: 'Tour Operators(561520)' },
    { value: 561591, label: 'Convention and Visitors Bureaus (561591)' },
    { value: 561599, label: 'All Other Travel Arrangement and Reservation Services (561599)' },
    { value: 561611, label: 'Investigation and Personal Background Check Services (561611)' },
    { value: 561612, label: 'Security Guards and Patrol Services (561612)' },
    { value: 561613, label: 'Armored Car Services (561613)' },
    { value: 561621, label: 'Security Systems Services (except Locksmiths) (561621)' },
    { value: 561622, label: 'Locksmiths (561622)' },
    { value: 561710, label: 'Exterminating and Pest Control Services(561710)' },
    { value: 561720, label: 'Janitorial Services (561720)' },
    { value: 561730, label: 'Landscaping Services(561730)' },
    { value: 561740, label: 'Carpet and Upholstery Cleaning Services(561740)' },
    { value: 561790, label: 'Other Services to Buildings and Dwellings (561790)' },
    { value: 561910, label: 'Packaging and Labeling Services(561910)' },
    { value: 561920, label: 'Convention and Trade Show Organizers(561920)' },
    { value: 561990, label: 'All Other Support Services(561990)' },
    { value: 562111, label: 'Solid Waste Collection (562111)' },
    { value: 562112, label: 'Hazardous Waste Collection (562112)' },
    { value: 562119, label: 'Other Waste Collection (562119)' },
    { value: 562211, label: 'Hazardous Waste Treatment and Disposal (562211)' },
    { value: 562212, label: 'Solid Waste Landfill (562212)' },
    { value: 562213, label: 'Solid Waste Combustors and Incinerators (562213)' },
    { value: 562219, label: 'Other Nonhazardous Waste Treatment and Disposal (562219)' },
    { value: 562910, label: 'Remediation Services (562910)' },
    { value: 562920, label: 'Materials Recovery Facilities (562920)' },
    { value: 562991, label: 'Septic Tank and Related Services (562991)' },
    { value: 562998, label: 'All Other Miscellaneous Waste Management Services (562998)' },
    { value: 611110, label: 'Elementary and Secondary Schools (611110)' },
    { value: 611210, label: 'Junior Colleges (611210)' },
    { value: 611310, label: 'Colleges, Universities, and Professional Schools(611310)' },
    { value: 611410, label: 'Business and Secretarial Schools (611410)' },
    { value: 611420, label: 'Computer Training (611420)' },
    { value: 611430, label: 'Professional and Management Development Training (611430)' },
    { value: 611511, label: 'Cosmetology and Barber Schools (611511)' },
    { value: 611512, label: 'Flight Training (611512)' },
    { value: 611513, label: 'Apprenticeship Training (611513)' },
    { value: 611519, label: 'Other Technical and Trade Schools (611519)' },
    { value: 611610, label: 'Fine Arts Schools (611610)' },
    { value: 611620, label: 'Sports and Recreation Instruction (611620)' },
    { value: 611630, label: 'Language Schools (611630)' },
    { value: 611691, label: 'Exam Preparation and Tutoring (611691)' },
    { value: 611692, label: 'Automobile Driving Schools (611692)' },
    { value: 611699, label: 'All Other Miscellaneous Schools and Instruction (611699)' },
    { value: 611710, label: 'Educational Support Services(611710)' },
    { value: 621111, label: 'Offices of Physicians (except Mental Health Specialists) (621111)' },
    { value: 621112, label: 'Offices of Physicians, Mental Health Specialists(621112)' },
    { value: 621210, label: 'Offices of Dentists (621210)' },
    { value: 621310, label: 'Offices of Chiropractors (621310)' },
    { value: 621320, label: 'Offices of Optometrists(621320)' },
    { value: 621330, label: 'Offices of Mental Health Practitioners (except Physicians) (621330)' },
    {
      value: 621340,
      label: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists(621340)',
    },
    { value: 621391, label: 'Offices of Podiatrists (621391)' },
    { value: 621399, label: 'Offices of All Other Miscellaneous Health Practitioners (621399)' },
    { value: 621410, label: 'Family Planning Centers (621410)' },
    { value: 621420, label: 'Outpatient Mental Health and Substance Abuse Centers (621420)' },
    { value: 621491, label: 'HMO Medical Centers (621491)' },
    { value: 621492, label: 'Kidney Dialysis Centers (621492)' },
    { value: 621493, label: 'Freestanding Ambulatory Surgical and Emergency Centers (621493)' },
    { value: 621498, label: 'All Other Outpatient Care Centers (621498)' },
    { value: 621511, label: 'Medical Laboratories (621511)' },
    { value: 621512, label: 'Diagnostic Imaging Centers (621512)' },
    { value: 621610, label: 'Home Health Care Services(621610)' },
    { value: 621910, label: 'Ambulance Services (621910)' },
    { value: 621991, label: 'Blood and Organ Banks (621991)' },
    { value: 621999, label: 'All Other Miscellaneous Ambulatory Health Care Services (621999)' },
    { value: 622110, label: 'General Medical and Surgical Hospitals (622110)' },
    { value: 622210, label: 'Psychiatric and Substance Abuse Hospitals (622210)' },
    {
      value: 622310,
      label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals (622310)',
    },
    { value: 623110, label: 'Nursing Care Facilities (Skilled Nursing Facilities) (623110)' },
    {
      value: 623210,
      label: 'Residential Intellectual and Developmental Disability Facilities (623210)',
    },
    { value: 623220, label: 'Residential Mental Health and Substance Abuse Facilities (623220)' },
    { value: 623311, label: 'Continuing Care Retirement Communities (623311)' },
    { value: 623312, label: 'Assisted Living Facilities for the Elderly (623312)' },
    { value: 623990, label: 'Other Residential Care Facilities (623990)' },
    { value: 624110, label: 'Child and Youth Services (624110)' },
    { value: 624120, label: 'Services for the Elderly and Persons with Disabilities (624120)' },
    { value: 624190, label: 'Other Individual and Family Services (624190)' },
    { value: 624210, label: 'Community Food Services (624210)' },
    { value: 624221, label: 'Temporary Shelters (624221)' },
    { value: 624229, label: 'Other Community Housing Services (624229)' },
    { value: 624230, label: 'Emergency and Other Relief Services (624230)' },
    { value: 624310, label: 'Vocational Rehabilitation Services (624310)' },
    { value: 624410, label: 'Child Care Services (624410)' },
    { value: 711110, label: 'Theater Companies and Dinner Theaters (711110)' },
    { value: 711120, label: 'Dance Companies (711120)' },
    { value: 711130, label: 'Musical Groups and Artists (711130)' },
    { value: 711190, label: 'Other Performing Arts Companies (711190)' },
    { value: 711211, label: 'Sports Teams and Clubs (711211)' },
    { value: 711212, label: 'Racetracks (711212)' },
    { value: 711219, label: 'Other Spectator Sports (711219)' },
    {
      value: 711310,
      label: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities(711310)',
    },
    {
      value: 711320,
      label: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities(711320)',
    },
    {
      value: 711410,
      label:
        'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures(711410)',
    },
    { value: 711510, label: 'Independent Artists, Writers, and Performers(711510)' },
    { value: 712110, label: 'Museums (712110)' },
    { value: 712120, label: 'Historical Sites(712120)' },
    { value: 712130, label: 'Zoos and Botanical Gardens (712130)' },
    { value: 712190, label: 'Nature Parks and Other Similar Institutions(712190)' },
    { value: 713110, label: 'Amusement and Theme Parks (713110)' },
    { value: 713120, label: 'Amusement Arcades(713120)' },
    { value: 713210, label: 'Casinos (except Casino Hotels)(713210)' },
    { value: 713290, label: 'Other Gambling Industries (713290)' },
    { value: 713910, label: 'Golf Courses and Country Clubs(713910)' },
    { value: 713920, label: 'Skiing Facilities(713920)' },
    { value: 713930, label: 'Marinas(713930)' },
    { value: 713940, label: 'Fitness and Recreational Sports Centers (713940)' },
    { value: 713950, label: 'Bowling Centers(713950)' },
    { value: 713990, label: 'All Other Amusement and Recreation Industries (713990)' },
    { value: 721110, label: 'Hotels (except Casino Hotels) and Motels (721110)' },
    { value: 721120, label: 'Casino Hotels(721120)' },
    { value: 721191, label: 'Bed-and-Breakfast Inns (721191)' },
    { value: 721199, label: 'All Other Traveler Accommodation (721199)' },
    { value: 721211, label: 'RV (Recreational Vehicle) Parks and Campgrounds (721211)' },
    { value: 721214, label: 'Recreational and Vacation Camps (except Campgrounds) (721214)' },
    {
      value: 721310,
      label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps(721310)",
    },
    { value: 722310, label: 'Food Service Contractors(722310)' },
    { value: 722320, label: 'Caterers(722320)' },
    { value: 722330, label: 'Mobile Food Services(722330)' },
    { value: 722410, label: 'Drinking Places (Alcoholic Beverages) (722410)' },
    { value: 722511, label: 'Full-Service Restaurants (722511)' },
    { value: 722513, label: 'Limited-Service Restaurants (722513)' },
    { value: 722514, label: 'Cafeterias, Grill Buffets, and Buffets(722514)' },
    { value: 722515, label: 'Snack and Nonalcoholic Beverage Bars (722515)' },
    { value: 811111, label: 'General Automotive Repair (811111)' },
    { value: 811114, label: 'Specialized Automotive Repair (811114)' },
    { value: 811121, label: 'Automotive Body, Paint, and Interior Repair and Maintenance(811121)' },
    { value: 811122, label: 'Automotive Glass Replacement Shops (811122)' },
    { value: 811191, label: 'Automotive Oil Change and Lubrication Shops (811191)' },
    { value: 811192, label: 'Car Washes (811192)' },
    { value: 811198, label: 'All Other Automotive Repair and Maintenance (811198)' },
    { value: 811210, label: 'Electronic and Precision Equipment Repair and Maintenance (811210)' },
    {
      value: 811310,
      label:
        'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance (811310)',
    },
    { value: 811411, label: 'Home and Garden Equipment Repair and Maintenance (811411)' },
    { value: 811412, label: 'Appliance Repair and Maintenance (811412)' },
    { value: 811420, label: 'Reupholstery and Furniture Repair(811420)' },
    { value: 811430, label: 'Footwear and Leather Goods Repair(811430)' },
    { value: 811490, label: 'Other Personal and Household Goods Repair and Maintenance (811490)' },
    { value: 812111, label: 'Barber Shops (812111)' },
    { value: 812112, label: 'Beauty Salons (812112)' },
    { value: 812113, label: 'Nail Salons (812113)' },
    { value: 812191, label: 'Diet and Weight Reducing Centers (812191)' },
    { value: 812199, label: 'Other Personal Care Services (812199)' },
    { value: 812210, label: 'Funeral Homes and Funeral Services (812210)' },
    { value: 812220, label: 'Cemeteries and Crematories (812220)' },
    { value: 812310, label: 'Coin-Operated Laundries and Drycleaners (812310)' },
    { value: 812320, label: 'Drycleaning and Laundry Services (except Coin-Operated) (812320)' },
    { value: 812331, label: 'Linen Supply (812331)' },
    { value: 812332, label: 'Industrial Launderers (812332)' },
    { value: 812910, label: 'Pet Care (except Veterinary) Services (812910)' },
    { value: 812921, label: 'Photofinishing Laboratories (except One-Hour) (812921)' },
    { value: 812922, label: 'One-Hour Photofinishing (812922)' },
    { value: 812930, label: 'Parking Lots and Garages (812930)' },
    { value: 812990, label: 'All Other Personal Services (812990)' },
    { value: 813110, label: 'Religious Organizations (813110)' },
    { value: 813211, label: 'Grantmaking Foundations (813211)' },
    { value: 813212, label: 'Voluntary Health Organizations (813212)' },
    { value: 813219, label: 'Other Grantmaking and Giving Services (813219)' },
    { value: 813311, label: 'Human Rights Organizations (813311)' },
    { value: 813312, label: 'Environment, Conservation and Wildlife Organizations (813312)' },
    { value: 813319, label: 'Other Social Advocacy Organizations (813319)' },
    { value: 813410, label: 'Civic and Social Organizations (813410)' },
    { value: 813910, label: 'Business Associations (813910)' },
    { value: 813920, label: 'Professional Organizations (813920)' },
    { value: 813930, label: 'Labor Unions and Similar Labor Organizations (813930)' },
    { value: 813940, label: 'Political Organizations (813940)' },
    {
      value: 813990,
      label:
        'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations) (813990)',
    },
    { value: 814110, label: 'Private Households(814110)' },
    { value: 921110, label: 'Executive Offices (921110)' },
    { value: 921120, label: 'Legislative Bodies (921120)' },
    { value: 921130, label: 'Public Finance Activities (921130)' },
    { value: 921140, label: 'Executive and Legislative Offices, Combined (921140)' },
    { value: 921150, label: 'American Indian and Alaska Native Tribal Governments (921150)' },
    { value: 921190, label: 'Other General Government Support (921190)' },
    { value: 922110, label: 'Courts (922110)' },
    { value: 922120, label: 'Police Protection (922120)' },
    { value: 922130, label: 'Legal Counsel and Prosecution (922130)' },
    { value: 922140, label: 'Correctional Institutions (922140)' },
    { value: 922150, label: 'Parole Offices and Probation Offices (922150)' },
    { value: 922160, label: 'Fire Protection (922160)' },
    { value: 922190, label: 'Other Justice, Public Order, and Safety Activities (922190)' },
    { value: 923110, label: 'Administration of Education Programs (923110)' },
    { value: 923120, label: 'Administration of Public Health Programs (923120)' },
    {
      value: 923130,
      label:
        "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) (923130)",
    },
    { value: 923140, label: "Administration of Veterans' Affairs (923140)" },
    {
      value: 924110,
      label:
        'Administration of Air and Water Resource and Solid Waste Management Programs (924110)',
    },
    { value: 924120, label: 'Administration of Conservation Programs (924120)' },
    { value: 925110, label: 'Administration of Housing Programs (925110)' },
    {
      value: 925120,
      label: 'Administration of Urban Planning and Community and Rural Development (925120)',
    },
    { value: 926110, label: 'Administration of General Economic Programs (926110)' },
    { value: 926120, label: 'Regulation and Administration of Transportation Programs (926120)' },
    {
      value: 926130,
      label:
        'Regulation and Administration of Communications, Electric, Gas, and Other Utilities (926130)',
    },
    { value: 926140, label: 'Regulation of Agricultural Marketing and Commodities (926140)' },
    {
      value: 926150,
      label: 'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors (926150)',
    },
    { value: 927110, label: 'Space Research and Technology (927110)' },
    { value: 928110, label: 'National Security (928110)' },
    { value: 928120, label: 'International Affairs (928120)' },
  ]
}
