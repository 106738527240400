import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import moment from "moment"

import styled from "styled-components";

import { Actions } from "redux/currentUser";
import DatePicker from "components/common/DatePicker/DatePicker"

import messages from "components/pages/Settings/messages"
import ApiKeyItem from 'components/pages/Settings/developer/ApiKeyItem';

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";

import styles from "styles/styles";

/*
Flow:
    if (key does not exist) {
        Generate button
    } else{
        - Show existing key info
        - button for revoke
        - button for regenerate
    }
*/

enum ModalMode {
    Regenerate,
    Delete,
    Create
}

const Developers = () => {
    // Redux state
    const dispatch = useDispatch()
    const hasApiKeyAccess = useSelector((state: any) => state.currentUser.currentUser.hasApiKeyAccess)
    const apiKeys = useSelector((state: any) => state.currentUser.apiKeys)
    const secretApiKey = useSelector((state: any) => state.currentUser.secretApiKey)
    const isFetchingApiKeys = useSelector((state: any) => state.currentUser.isFetchingApiKeys)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    // Component state
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isApiSecretModalOpen, setIsApiSecretModalOpen] = useState(false)
    const [modalMode, setModalMode] = useState<ModalMode | undefined>(undefined)
    const [selectedKey, setSelectedKey] = useState<any>(undefined)
    const [expiryDate, setExpiryDate] = useState(moment().startOf('date'))

    // Actions on modals
    const toggleIsModalOpen = () => {
        setIsModalOpen(!isModalOpen)
    }

    const regenerateApiKeyModal = (apiKey: any) => {
        setSelectedKey(apiKey)
        setModalMode(ModalMode.Regenerate)
        setIsModalOpen(true)
    }

    const deleteApiKeyModal = (apiKey: any) => {
        setSelectedKey(apiKey)
        setModalMode(ModalMode.Delete)
        setIsModalOpen(true)
    }

    const createApiKeyModal = (apiKey: any) => {
        setModalMode(ModalMode.Create)
        setIsModalOpen(true)
    }

    // Helper functions
    const handleExpiryDateChange = (value: any) => {
        setExpiryDate(moment(value))
    }

    const clearSecretApiKey = () => {
        dispatch(Actions.clearSecretApiKey())
        setIsApiSecretModalOpen(false)
    }

    // // todo - get this working
    // const copySecretToClipboard = () => {
    //     if ("clipboard" in navigator) {
    //         navigator.clipboard.writeText(secretApiKey);
    //     }
    //   };

    // Actions on api keys
    const removeKey = () => {
        dispatch(Actions.revokeApiKeys({"prefix": selectedKey.prefix}))
        setIsModalOpen(false)
    }    

    const regenerateKey = () => {
        dispatch(Actions.regenerateApiKeys({
            "delete_params": {"prefix": selectedKey.prefix},
            "create_params": {
                "expiry_date" : expiryDate.format("YYYY-MM-DDThh:mm")
            }
        }))
        setIsModalOpen(false)
        setIsApiSecretModalOpen(true)
    }

    const createKey = () => {
        const createParams = {"expiry_date": expiryDate.format("YYYY-MM-DDThh:mm")}
        dispatch(Actions.createApiKeys(createParams))
        setIsModalOpen(false)
        setIsApiSecretModalOpen(true)
    }

    // UseEffects
    useEffect(() => {
        if (!isFetchingApiKeys) {
            if (hasApiKeyAccess) {
                dispatch(Actions.getApiKeys())
            }
        }
    }, [hasApiKeyAccess, dispatch]) // eslint-disable-line

    // Component rendering helper functions
    const mapApiKeyToRow = (apiKey: any, index: number) => {
        return <ApiKeyItem
            key={`apikey-${index}`} 
            apiKey={apiKey} 
            index={index}  
            regenerateCallback={regenerateApiKeyModal}
            deleteCallback={deleteApiKeyModal} 
        />
    }

    const renderApiKey = (loadedApiKeys: any) => {
        // Currently restricting to 1 API key. 
        return (
            <div>
                <Table>
                    <TableContent>
                        {!isMobile && <HeaderRow>
                            <HeaderItem>{messages.Developers.Labels.ApiKeyPrefix}</HeaderItem>
                            <HeaderItem>{messages.Developers.Labels.ApiKeyCreatedDate}</HeaderItem>
                            <HeaderItem>{messages.Developers.Labels.ApiKeyExpiryDate}</HeaderItem>
                            <HeaderItem>{messages.Developers.Labels.ApiKeyActions}</HeaderItem>
                        </HeaderRow>}                        
                        {loadedApiKeys?.map(mapApiKeyToRow)}
                    </TableContent>
                </Table>
            </div>
        )
    }

    const createApiKeyComponent = () => {
        return (
            <ManageButton onClick={createApiKeyModal}>Create API key</ManageButton>
        )
    }

    const getKeyCalendarComponent = () => {
        return <DatePickerDropdownContainer>
            <CalendarDiv id='dateInput'>
                <DatePicker 
                    selected={moment(expiryDate)}
                    startDate={moment(expiryDate)}
                    onChange={handleExpiryDateChange}
                    customInput={<DatePickerInput id='departureDate' onClick={() => {}} >
                        <div>{expiryDate && moment(expiryDate).format('MMMM D, YYYY')}</div>
                        <CustomCaretDown/>
                    </DatePickerInput>}
                    minDate={moment().startOf('date')}
                    maxDate={moment().add(365, 'days')}
                />
            </CalendarDiv>
        </DatePickerDropdownContainer>
    }

    const getModalTitle = (mode: ModalMode | undefined) => {
        if (mode === ModalMode.Delete) {
            return 'Delete API Key'
        } else if (mode === ModalMode.Regenerate) {
            return 'Regenerate API Key'
        } else if (mode === ModalMode.Create) {
            return 'Create API Key'
        } else {
            return ''
        }
    }

    const getModalBody = (mode: ModalMode | undefined) => {
        if (mode === ModalMode.Delete) {
            return (
                <ModalKeyDetail>
                    <ModalText>{messages.Developers.Description.ApiKeyModal.DeleteConfirmation}</ModalText> 
                </ModalKeyDetail>
            )
        } else if (mode === ModalMode.Regenerate) {
            return (
                <ModalKeyDetail>
                    <ModalText>{messages.Developers.Description.ApiKeyExpiryDate}</ModalText>
                    {getKeyCalendarComponent()}
                    <ModalText>{messages.Developers.Description.ApiKeyModal.RegenerateConfirmation}</ModalText>
                </ModalKeyDetail>
            )
        } else if (mode === ModalMode.Create) {
            // todo warning message revisit
            return (
                <ModalKeyDetail>
                    <ModalText>{messages.Developers.Description.ApiKeyExpiryDate}</ModalText>
                    {getKeyCalendarComponent()}
                    <ModalText>{messages.Developers.Description.ApiKeyModal.CreateConfirmation}</ModalText>
                </ModalKeyDetail>
            )
        } else {
            return
        }
    }

    const getModalButtons = (mode: ModalMode | undefined) => {
        if (mode === ModalMode.Delete) {
            return (
                <ModalButtonsContainer>
                    <CancelButton onClick={toggleIsModalOpen}>Cancel</CancelButton>
                    <ConfirmButton onClick={removeKey}>Delete</ConfirmButton>
                </ModalButtonsContainer>
            )
        } else if (mode === ModalMode.Regenerate) {
            return (
                <ModalButtonsContainer>
                    <CancelButton onClick={toggleIsModalOpen}>Cancel</CancelButton>
                    <ConfirmButton onClick={regenerateKey}>Confirm</ConfirmButton>
                </ModalButtonsContainer>
            )
        } else if (mode === ModalMode.Create) {
            return (
                <ModalButtonsContainer>
                    <CancelButton onClick={toggleIsModalOpen}>Cancel</CancelButton>
                    <ConfirmButton onClick={createKey}>Confirm</ConfirmButton>
                </ModalButtonsContainer>
            )
        } else {
            return
        }
    }

    return <div>
        <Modal show={isModalOpen} centered>
            <ModalTitle>{getModalTitle(modalMode)}</ModalTitle>
            <ModalKeyDetails>
                {getModalBody(modalMode)}
            </ModalKeyDetails>
            {getModalButtons(modalMode)}
        </Modal>
        <Modal show={isApiSecretModalOpen} centered>
            <ModalTitle>API Key</ModalTitle>
            <ModalKeyDetails>
                <ModalText>{messages.Developers.Description.ApiKeyModal.CreationSuccess}</ModalText>
            </ModalKeyDetails>
            {/* todo - copy on click */}
            <ModalKeyDetails>
                    <SecretText>{secretApiKey !== null && secretApiKey}</SecretText>
            </ModalKeyDetails>            
            <ModalButtonsContainer><ConfirmButton onClick={clearSecretApiKey}>Close</ConfirmButton></ModalButtonsContainer>
        </Modal>
        <Title>Developers</Title>
        {hasApiKeyAccess && <DeveloperItem>
                <Subtitle>API Keys</Subtitle>
                <Description>
                    <Container>
                        <Text>{messages.Developers.Description.ApiKeyMainDescription}</Text>
                        <div>{messages.Developers.Description.ApiKeyApiDocPretext} <StyledLink href="https://app.taekus.com/api/docs/">API docs.</StyledLink></div>
                        <br></br>
                        <BoldText>{messages.Developers.Description.ApiKeyMainWarningTitle}</BoldText>
                        <ul>
                        {messages.Developers.Description.ApiKeyMainWarningTitleList.map(
                            (message: string) => {
                                return <li>{message}</li>
                            }
                        )}
                        </ul>
                    </Container>
                </Description>
                {(apiKeys?.length > 0) && renderApiKey(apiKeys)}
                {(!apiKeys?.length) && createApiKeyComponent()}
        </DeveloperItem>}
        {!hasApiKeyAccess && <DeveloperItem>
                <Text>{messages.Developers.Description.ApiKeyNoAccess} <StyledLink href="mailto:support@taekus.com">support@taekus.com</StyledLink></Text>
        </DeveloperItem>}        
    </div>
}

// Text Styles
const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const Subtitle = styled.div`
    ${styles.Text.DisplaySmall}
    font-size: ${styles.Spacing.S};
    padding-top: ${styles.Spacing.S};
`

const BoldText = styled.div`
    font-weight: bold;
`

const Text = styled.div`
    padding-top: ${styles.Spacing.XS};
    padding-bottom: ${styles.Spacing.S};
    max-width: 1000px;
    white-space:pre-wrap;
`

const StyledLink = styled.a`
    color: ${styles.Color.TaekusPurple};
`

const Description = styled.div`
    display: flex;
    flex: 0;
    justify-content: left;
    height: min-content;
    text-align: left;    
    font-size: ${styles.Font.Size.Small};
    border-radius: 8px;
    padding: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.M};
`

const Container = styled.div`
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: ${styles.Color.Black};
    overflow-y: auto;
`

// Calendar Styles
const DatePickerDropdownContainer = styled.div`
    position: relative;
`

const DatePickerInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: ${styles.Spacing.XS};
    width: 170px;
    cursor: pointer;
`

const CustomCaretDown = styled(CaretDown)`
    width: 10px;
    height: 5px;
    margin-left: ${styles.Spacing.XS};
`

const CalendarDiv = styled.div`
    color: ${styles.Color.TaekusPurple};
    margin: ${styles.Spacing.S};
`

// Table Styles
const HeaderItem = styled.th`
    width='25%';
    font-weight: ${styles.Font.Weight[500]};
    color: ${styles.Color.NearBlack};
    border-bottom: 2px solid ${styles.Color.Black};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    &:first-child {
        padding-left: ${styles.Spacing.XS};
    }
`

const HeaderRow = styled.tr``

const Table = styled.table`
    gap: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.M};
    width: 100%;
    table-layout: fixed;
`
const DeveloperItem = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk}
    display: flex;
    height: fit-content;
    min-height: 100px;
    padding: ${styles.Spacing.S} 0;
    
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        justify-content: space-between;
    }
`

const TableContent = styled.tbody`
    ${styles.Animation.transitionStyles}
`

// Modal Styles
const ModalTitle = styled.div`
    padding-top: ${styles.Spacing.S};
    padding-left: ${styles.Spacing.S};
    padding-right: ${styles.Spacing.S};
    font-size: ${styles.Spacing.S};
`

const ModalButtonsContainer = styled.div`
    display: flex;
    flex: 1;
`

const ModalKeyDetails = styled.div`
    display: flex;
    justify-content: center;
    padding: ${styles.Spacing.XS};
`

const ModalKeyDetail = styled.div`
    flex: 1;
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
`

const ModalText = styled.div`
    flex: 1;
    padding: ${styles.Spacing.XS};
`

const ConfirmButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${styles.Color.TaekusPurple};
    color ${styles.Color.White};
    border: 0;
    padding: 8px;
    flex: 1;
    margin: ${styles.Spacing.XS};
`

const CancelButton = styled.button`
    background-color: ${styles.Color.Grey};
    color ${styles.Color.Black};
    border: 0;
    padding: 8px;
    flex: 1;
    margin: ${styles.Spacing.XS};
`

const ManageButton = styled.button`
    border: 0;
    background-color: ${styles.Color.TaekusPurple};
    width: fit-content;
    height: fit-content;
    color: ${styles.Color.TaekusCream};
    ${styles.MediaQueries.Mobile} {
        padding: 0;
    }
    padding: ${styles.Spacing.XS} ${styles.Spacing.L} ${styles.Spacing.XS}  ${styles.Spacing.L};
    margin-left: ${styles.Spacing.XS}
`
const SecretText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${styles.Color.Grey};
    color ${styles.Color.Black};
    padding: 8px;
    flex: 1;    
`

export default Developers;