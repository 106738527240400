import React from 'react'

import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Row, Col } from 'react-bootstrap'
import { Button } from 'reactstrap'

import { ReactComponent as TaekusIcon } from 'assets/svg/TaekusIcon.svg'

import 'assets/components/login/css/login.scss'

class AccountRecovery extends React.Component {
  render() {
    return (
      <Row className="Login-container m-0">
        <Col md="6" lg="5">
          <Col
            xs="12"
            md={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 2 }}
            className="Login-formBlock"
          >
            <h3 className="Login-subtitle my-5 pt-5 text-center">
              <FormattedMessage id="account-recovery.title" defaultMessage={`Account Recovery`} />
            </h3>
            <div className="StandardBodyDMSans mb-5 text-center">
              <FormattedMessage
                id="account-recovery.body"
                defaultMessage={`Having trouble accessing your Taekus account? Please contact us at the phone number on the back of your card or email us at `}
              />
              <a href="mailto:support@taekus.com">support@taekus.com</a>
              <FormattedMessage
                id="account-recovery.body"
                defaultMessage={`. A representative can help you with locating your account information.`}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <NavLink to="/login/" className="text-decoration-none">
                <Button className="StandardButtonPurple">
                  <FormattedMessage id="acount-recovery.back" defaultMessage={`Back to login`} />
                </Button>
              </NavLink>
            </div>
          </Col>{' '}
        </Col>
        <Col
          md="6"
          lg="7"
          className="Login-decorativeBackground d-none d-md-flex justify-content-center"
        >
          <div className="d-flex w-100 h-100 justify-content-center align-items-center">
            <div className="Taekus-Icon-Large">
              <TaekusIcon fill="white" style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default AccountRecovery
