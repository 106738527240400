import React from "react"

import styled from "styled-components"

import styles from "styles/styles"

type TabProps = {
    label: string,
    selected: boolean,
    onClick: () => void,
}

const Tab = (props: TabProps) => {
    return <Container selected={props.selected} onClick={props.onClick}>
        {props.label}
    </Container>
}

type ContainerProps = {
    selected: boolean
}

const Container = styled.button<ContainerProps>`
    border-radius: 0;
    border-top: transparent;
    border-bottom: ${props => props.selected ? `2px solid ${styles.Color.Black} !important` : '1px solid transparent'};
    border-left: transparent;
    border-right: transparent;
    background-color: transparent;
    color: ${styles.Color.Black};
    display: flex;
    padding: 8px ${styles.Spacing.XS};
    justify-content: center;
    align-items: center;
    gap: ${styles.Spacing.XS};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: ${styles.Font.Weight};
    line-height: 138%; /* 24.84px */
    letter-spacing: 0.36px;
    margin-right: ${styles.Spacing.S};
    &:hover {
        border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    ${styles.MediaQueries.Desktop} {
        font-size: 18px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 12px;
    }
    ${styles.Animation.transitionStyles}
`


export default Tab