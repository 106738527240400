import { createSlice } from '@reduxjs/toolkit'
import { getWindowScreenSize } from './utils';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    dropdownKey: '',
    toast: {
      isError: false,
      message: '',
      isOpen: false,
    },
    isMobile: window.matchMedia(`(max-width: 768px)`).matches,
    screenSize: getWindowScreenSize(window),
  },
  reducers: {
    updateDropdownKey: (state, action) => {
      state.dropdownKey = action.payload;
    },
    updateIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    updateScreenSize: (state, action) => {
      state.screenSize = action.payload;
    },
    updateToast: (state, action) => {
      state.toast = action.payload;
    }    
  }
})

export const { updateDropdownKey, updateIsMobile, updateScreenSize, updateToast } = globalSlice.actions
  

export default globalSlice.reducer