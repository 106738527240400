import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { Actions } from "redux/features/banking/banking";

import Modal from "components/common/Modal";
import Spinner from "components/common/Spinner";
import Navigation, { NavColor } from "components/navbar/Navigation";

import messages from "components/pages/Settings/messages";
import { User, UserAccessLevel } from "components/pages/Settings/types";

import { validateEmail } from "utils/utils";

import { ReactComponent as ArrowLeft } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as Accountant } from "assets/svg/Accountant.svg";
import { ReactComponent as AccountManager } from "assets/svg/AccountManager.svg";
import { ReactComponent as AuthorizedUser } from "assets/svg/AuthorizedUser.svg";
import { ReactComponent as FullAccess } from "assets/svg/FullAccess.svg";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

const getAccessLevelIcon = (level: UserAccessLevel) => {
    switch (level) {
        case UserAccessLevel.AccountManager:
            return <AccountManager/>;
        case UserAccessLevel.Accountant:
            return <Accountant/>;
        case UserAccessLevel.FullAccess:
            return <FullAccess/>;
        case UserAccessLevel.AuthorizedUser:
        default:
            return <AuthorizedUser/>;
    }
}

type UserDetails = {
    accessLevel: UserAccessLevel | undefined,
    active: boolean,
    auFirstName: string,
    auLastName: string,
    inviteEmailAddress: string,
    inviteIsDisabled: boolean,
    user: null,
}

const defaultUserDetails = {
    accessLevel: undefined,
    active: true,
    auFirstName: '',
    auLastName: '',
    cardAccount: null,
    inviteEmailAddress: '',
    inviteIsDisabled: false,
    user: null,
} as UserDetails

const UserAccessInspector = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { cardAccountID, userID } = useParams<{ cardAccountID: string, userID: string }>();

    // Redux state
    const isLoadingAccount = useSelector((state: any) => state.banking.isLoading)
    const banking = useSelector((state: any) => state.banking)
    const cardAccount = useSelector((state: any) => state.currentUser.currentUser.cardAccounts.find((account: any) => account.uuid === cardAccountID))
    const user = useSelector((state: any) => state.banking.account.authorizedUsers)?.find((user: User) => user.uuid === userID)
    const isLoadingAuthorizedUser = useSelector((state: any) => state.banking.account.isLoadingAuthorizedUser)
    const didAuthorizedUserFail = useSelector((state: any) => state.banking.account.didAuthorizedUserFail)
    const authorizedUserError = useSelector((state: any) => state.banking.account.authorizedUserError)

    // Component state
    const [selectedAccessLevel, setSelectedAccessLevel] = useState<UserAccessLevel | undefined>(undefined)
    const [newUserDetails, setNewUserDetails] = useState({ ...defaultUserDetails })
    const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    const isEditing = userID !== 'new';
    const userFirstName = isEditing ? user?.auFirstName : newUserDetails.auFirstName
    const userLastName = isEditing ? user?.auLastName : newUserDetails.auLastName
    const isSaveButtonDisabled = isEditing ? selectedAccessLevel === user?.accessLevel :
        (newUserDetails.auFirstName === '' ||
        newUserDetails.auLastName === '' ||
        !validateEmail(newUserDetails.inviteEmailAddress) ||
        newUserDetails.accessLevel === undefined || didAuthorizedUserFail === true)

    const title = isEditing ? 'Manage Authorized User' : 'Add Authorized User'

    // Only full access is supported for now
    const accessLevels = [
        UserAccessLevel.FullAccess,
        // UserAccessLevel.AuthorizedUser,
        // UserAccessLevel.AccountManager,
        // UserAccessLevel.Accountant,
    ]

    const goToUserAccess = () => {
        history.push('/settings/useraccess')
    }

    const toggleRevokeModal = () => {
        setIsRevokeModalOpen(!isRevokeModalOpen)
    }

    const updateUserDetail = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setNewUserDetails({
            ...newUserDetails,
            [ev.target.id]: ev.target.value,
        })
    }

    const createAuthorizedUser = () => {
        setIsUpdating(true)
        dispatch(Actions.addAuthorizedUser({
            ...newUserDetails,
            cardAccountUuid: cardAccountID,
        }))
    }

    const updateAuthorizedUser = () => {
        setIsUpdating(true)
        dispatch(Actions.updateAuthorizedUser({
            auFirstName: user.auFirstName,
            auLastName: user.auLastName,
            inviteEmailAddress: user.inviteEmailAddress,
            active: user.active,
            cardAccountUuid: cardAccountID,
            uuid: userID,
            accessLevel: selectedAccessLevel,
        }))
    }

    const disableAuthorizedUser = () => {
        dispatch(Actions.updateAuthorizedUser({
            auFirstName: user.auFirstName,
            auLastName: user.auLastName,
            inviteEmailAddress: user.inviteEmailAddress,
            active: false,
            cardAccountUuid: cardAccountID,
            uuid: userID,
            accessLevel: user.accessLevel,
        }))
        goToUserAccess()
    }

    // Move to the cards list view if user chooses a Taekus account that doesn't match the inspected card
    useEffect(() => {
        if (banking.account.uuid && banking.account.uuid !== cardAccountID) {
            goToUserAccess()
        }
    }, [banking.account.uuid]) // eslint-disable-line

    useEffect(() => {
        setSelectedAccessLevel(user?.accessLevel)
    }, [ user ])

    useEffect(() => {
        // use isUpdating to avoid immediately back-navigating if redux store is dirty from a previous edit
        if (isUpdating && didAuthorizedUserFail === false) {
            goToUserAccess()
        }
    }, [ isLoadingAuthorizedUser, goToUserAccess ]) // eslint-disable-line

    return <Container>
        <Navigation color={NavColor.Black}/>
        <Main>
            <Sidebar>
                <BackLink onClick={goToUserAccess}>
                    <StyledArrowLeft/>
                    Back to all
                </BackLink>
            </Sidebar>
            <Content>
                <Title>{title}</Title>
                <AnimatePresence mode='wait'>
                    {isLoadingAccount ? <motion.div key='spinner' {...fadeInOutMotionProps} className="d-flex flex-column align-items-center">
                        <Spinner size={40} />
                        <SpinnerLabel>Fetching Account</SpinnerLabel>
                    </motion.div> : <Details key='content' {...fadeInOutMotionProps}>
                        {!isEditing && <Information>Enter your authorized user’s details. We will send them an email invite along with more questions/details to fill out so that they can be identity verification and onboarded.</Information>}
                        <Section>
                            <Subtitle>Personal Information</Subtitle>
                            {authorizedUserError !== '' && 
                            <ErrorSubtitle>{authorizedUserError}</ErrorSubtitle>}
                            <DetailRow>
                                <UserDetail>
                                    <InputLabel>First Name</InputLabel>
                                    <StyledInput 
                                        id='auFirstName'
                                        disabled={isEditing}
                                        value={userFirstName}
                                        onChange={updateUserDetail}
                                    />
                                </UserDetail>
                                <UserDetail>
                                    <InputLabel>Last Name</InputLabel>
                                    <StyledInput
                                        id='auLastName'
                                        disabled={isEditing}
                                        value={userLastName}
                                        onChange={updateUserDetail}
                                    />
                                </UserDetail>
                            </DetailRow>
                            <DetailRow>
                                {/* <UserDetail>
                                    <InputLabel>Phone Number</InputLabel>
                                    <StyledInput/>
                                </UserDetail> */}
                                {(user?.inviteEmailAddress || !isEditing) && <UserDetail>
                                    <InputLabel>Email</InputLabel>
                                    <StyledInput
                                        id='inviteEmailAddress'
                                        disabled={isEditing}
                                        value={isEditing ? user?.inviteEmailAddress : newUserDetails.inviteEmailAddress}
                                        onChange={updateUserDetail}
                                    />
                                </UserDetail>}
                            </DetailRow>
                        </Section>
                        <Section>
                            <Subtitle>Selected Account</Subtitle>
                            <div className="d-flex align-items-center">
                                <MiniCard>
                                    <MiniCardStripe/>
                                </MiniCard>
                                <StyledInput disabled value={`${cardAccount?.name} ...${cardAccount?.accountLast4}`}/>
                            </div>
                        </Section>
                        <Section>
                            <Subtitle>Access Level</Subtitle>
                            {accessLevels.map(accessLevel => {
                                const onClick = () => { 
                                    setSelectedAccessLevel(accessLevel)
                                    if (!isEditing) {
                                        setNewUserDetails({
                                            ...newUserDetails,
                                            accessLevel,
                                        })
                                    }
                                }

                                return <AccessLevelOptions isSelected={accessLevel === selectedAccessLevel} onClick={onClick}>
                                    <AccessLevelHeader>
                                        <AccessLevelIconContainer>{getAccessLevelIcon(accessLevel)}</AccessLevelIconContainer>
                                        <AccessLevelLabel>{messages.UserAccess.Level[accessLevel].label}</AccessLevelLabel>
                                    </AccessLevelHeader>
                                    <AccessLevelDescription>{messages.UserAccess.Level[accessLevel].description}</AccessLevelDescription>
                                </AccessLevelOptions>
                            })}
                        </Section>
                        {isEditing && <Section>
                            <Subtitle>Revoke User Access</Subtitle>
                            <RevokeButton onClick={toggleRevokeModal}>Revoke Access</RevokeButton>
                        </Section>}
                        <div className="d-flex">
                            {!isEditing && <>
                                {isLoadingAuthorizedUser ? <SaveButtonSpinnerContainer>
                                    <Spinner size={20}/>
                                </SaveButtonSpinnerContainer> : <SaveButton 
                                    onClick={isEditing ? updateAuthorizedUser : createAuthorizedUser}
                                    disabled={isSaveButtonDisabled}
                                >
                                    {isEditing ? 'Save' : 'Send Email Invite'}
                                </SaveButton>}
                                <CancelButton>Cancel</CancelButton>
                            </>}
                        </div>
                    </Details>}
                </AnimatePresence>
            </Content>
        </Main>
        {isRevokeModalOpen && <Modal title={'Revoke User Access?'} onClose={toggleRevokeModal}>
            <ModalContent>
                <div>{userFirstName} {userLastName} will no longer be able to see or manage your {cardAccount?.name} account ending in {cardAccount?.accountLast4} when you revoke user access.</div>
                <div className='d-flex justify-content-end'>
                    <ModalCancelButton onClick={toggleRevokeModal}>Cancel</ModalCancelButton>
                    <ModalRevokeButton onClick={disableAuthorizedUser}>Revoke Access</ModalRevokeButton>
                </div>
            </ModalContent>
        </Modal>}
    </Container>
}

const SaveButtonSpinnerContainer = styled.div`
    width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalContent = styled.div`
    max-width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`

const MiniCardStripe = styled.div`
    background-color: ${styles.Color.TaekusBlue};
    width: 14px;
    height: 2px;
    margin: 1px;
    border-radius: 1px;
`

const MiniCard = styled.div`
    width: 17px;
    height: 12px;
    border: 1px solid ${styles.Color.TaekusBlue};
    margin-right: 6px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: end;
`

const AccessLevelIconContainer = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;
    stroke: inherit;
    fill: inherit;
`

const SpinnerLabel = styled.div`
    opacity: 0.5;
    padding: ${styles.Spacing.XS};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const StyledArrowLeft = styled(ArrowLeft)`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.XS};
    margin-right: ${styles.Spacing.XS};
    fill: ${styles.Color.TaekusPurple};
`

const ModalCancelButton = styled.button`
    height: ${styles.Spacing.M};
    border: 1px solid ${styles.Color.TaekusPurple};
    background-color: transparent;
    color: ${styles.Color.TaekusPurple};
    padding: 0 30px;
    min-width: 100px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    border-radius: 2px;
    margin-right: ${styles.Spacing.XS};
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: ${styles.Color.TaekusPurple};
        color: white;
    }
`

const ModalRevokeButton = styled.button`
    height: ${styles.Spacing.M};
    border: 1px solid #EF4135;
    background-color: #EF4135;
    color: white;
    padding: 0 30px;
    min-width: 100px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    border-radius: 2px;
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: transparent;
        color: #EF4135;    
    }
`

const RevokeButton = styled.button`
    font-size: 16px;
    padding: ${styles.Spacing.XS} 25px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    border-radius: 2px;
    border: 1px solid #EF4135;
    color: #EF4135;
    background-color: transparent;
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: #EF4135;
        color: white;
    }
`

const CancelButton = styled.button`
    margin-left: ${styles.Spacing.XS};
    width: 168px;
    border: 1px solid ${styles.Color.TaekusPurple};
    color: ${styles.Color.TaekusPurple};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${styles.Spacing.XS} 25px;
    cursor: pointer;
    border-radius: 2px;
`

const SaveButton = styled.button`
    border: none;
    background-color: ${styles.Color.TaekusPurple};
    width: 360px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${styles.Spacing.XS} 25px;
    cursor: pointer;
    border-radius: 2px;
    &:disabled {
        cursor: auto;
        background-color: ${styles.Color.Grey};
    }
`

const DetailRow = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Section = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-bottom: ${styles.Spacing.M};
    } 
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    } 
    
`

const UserDetail = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.XS};
    }
    &:first-child {
        margin-right: ${styles.Spacing.M};
    }
`

const Content = styled.div`
    width: 100%;
    ${styles.MediaQueries.Desktop} {
        padding-right: 50px;
    }
    ${styles.MediaQueries.Mobile} {
        padding: ${styles.Spacing.S};
    }
`

const Main = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        margin-top: ${styles.Spacing.L};
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const InputLabel = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 500;
    line-height: 127%; /* 17.78px */
    letter-spacing: 0.14px;
`

const StyledInput = styled.input`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 360px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    background-color: transparent;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    outline: none;
    color: black !important;
    ${styles.Animation.transitionStyles}
    :disabled {
        opacity: 1;
    }
    :not(:disabled) {
        border-bottom: 1px solid #BFBFBF;
        &:hover, &:focus {
           border-bottom: 1px solid ${styles.Color.TaekusPurple};
        }
    }
`

const Subtitle = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 27.6px */
    letter-spacing: 0.4px;
    margin-bottom: ${styles.Spacing.XS};
`

const ErrorSubtitle = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 138%; /* 27.6px */
    letter-spacing: 0.4px;
    color: red;
    margin-bottom: ${styles.Spacing.XS};
`

const Information = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    margin-bottom: ${styles.Spacing.S};
`

const AccessLevelHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${styles.Spacing.XS};
`

const AccessLevelDescription = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
`

const AccessLevelLabel = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

type AccessLevelOptionsProps = {
    isSelected: boolean;
    onClick?: any;
}

const AccessLevelOptions = styled.div<AccessLevelOptionsProps>`
    width: 100%;
    border-radius: 2px;
    padding: ${styles.Spacing.S};
    min-height: 94px;
    ${props => props.onClick && `cursor: pointer;`}
    ${styles.Animation.transitionStyles}
    margin-bottom: 16px;
    fill: ${props => props.isSelected ? styles.Color.TaekusPurple : styles.Color.Black};
    stroke: ${props => props.isSelected ? styles.Color.TaekusPurple : styles.Color.Black};
    color: ${props => props.isSelected ? styles.Color.TaekusPurple : styles.Color.Black};
    border: 2px solid ${props => props.isSelected ? styles.Color.TaekusPurple : styles.Color.Grey};
    &:hover {
        fill: ${styles.Color.TaekusPurple};
        stroke: ${styles.Color.TaekusPurple};
        color: ${styles.Color.TaekusPurple};
        border: 2px solid ${styles.Color.TaekusPurple};
    }
`

const Details = styled(motion.div)`
    margin-bottom: ${styles.Spacing.L};
    max-width: 766px;
`

const BackLink = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.28px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        text-decoration: underline;
    }
`

const Title = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 30.48px */
    letter-spacing: 0.24px;
    border-bottom: 1px solid black;
    padding-bottom: 28px;
    margin-bottom: ${styles.Spacing.S};
    width: 100%;
`

const Container = styled.div`
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: ${styles.Color.Black};
    overflow-y: scroll;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Sidebar = styled.div`
    display: flex; 
    flex-direction: column;    
    ${styles.MediaQueries.Desktop} {
        padding-left: ${styles.Spacing.M};
        padding-right: ${styles.Spacing.M};
        padding-top: 30px;
        min-width: 240px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        padding: ${styles.Spacing.XS} ${styles.Spacing.S};
    }
`

export default UserAccessInspector;