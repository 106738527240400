import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import moment from 'moment';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { getCurrencyOptions } from 'utils/constants';
import { debounce } from 'utils/debounce';

import { Transaction } from 'components/pages/Home/constants';
import { updateTransactionMemo } from 'components/pages/Home/homeSlice';

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles';
import TransactionMetadataDetails from 'components/pages/Home/TransactionMetadataDetails';

type TransactionItemProps = {
    transaction: Transaction,
    disputeTransaction: (transaction: any) => void;
}

const detailVariants = {
    open: { height: 'auto', opacity: 1 },
    closed: { height: 0, opacity: 0 },
}

const TransactionItem = (props: TransactionItemProps) => {
    const history = useHistory()

    // Redux state
    const dispatch = useDispatch();
    const banking = useSelector((state: any) => state.banking)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    // Local state
    const [isExpanded, setIsExpanded] = useState(false)
    const [isTransactionMetadataExpanded, setIsTransactionMetadataExpanded] = useState(false)
    const [memo, setMemo] = useState(props.transaction.memo)

    const date = new Date(props.transaction.date)

    const isNotPaymentOrWithdrawal = (transactionType: string) => {
        return transactionType !== "PAYMENT" && transactionType !== "WITHDRAWAL";
    }

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    const stopPropogation = (ev: React.MouseEvent<HTMLElement>) => {
        ev.stopPropagation();
    }

    const updateMemo = (memo: string) => {
        dispatch(updateTransactionMemo(
            banking.account.uuid, 
            {
                ...props.transaction,
                memo 
            }
        ))
    }

    const debouncedUpdateMemo = useCallback(debounce(updateMemo, 2000), []) // eslint-disable-line

    const handleMemoOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMemo(ev.target.value)
        debouncedUpdateMemo(ev.target.value)
    }

    const handleDispute = (ev: any) => { 
        ev.stopPropagation(); 
        props.disputeTransaction(props.transaction)
    }

    const toggleExpandTransactionMetadata = (ev: any) => { 
        ev.stopPropagation(); 
        setIsTransactionMetadataExpanded(!isTransactionMetadataExpanded)
    }

    const goToCardInspector = () => { 
        history.push(`/cards/${banking.account.uuid}/${props.transaction.cardUuid}`)
    }

    const isPending = props.transaction.state === 'PENDING'
    const isDecline = props.transaction.type === 'DECLINE'

    const { name, description } = props.transaction.merchant;
    const transactionDescription = ((name !== '' && name !== null) ? name : ((description !== '' && description !== null) ? description : props.transaction.merchant.originalDescription ))

    const { amountOrigin, currencyOrigin, amountUsd } = props.transaction.amount;
    const isForeignTransaction = (currencyOrigin !== "840") && (currencyOrigin !== "USD")

    const pointsIncludedTransactionTypes = ['PURCHASE', 'REFUND']
    const pointsLabel = props.transaction?.pointsPosted ? (props.transaction?.pointsEarned !== undefined ? `${props.transaction.pointsEarned} PTS` : '') : (pointsIncludedTransactionTypes.includes(props.transaction.type) ? 'Pending PTS' : '')

    return <>
    <Row {...fadeInOutMotionProps} onClick={toggleIsExpanded} $expanded={isExpanded}>
        {isMobile ? <>
            <Item isDecline={isDecline} isPending={isPending}>
                <div className='d-flex justify-content-between'>
                    <div className='w-50'>
                        <Value>{transactionDescription}</Value>
                        <Value>{props.transaction.categoryLabel}</Value>
                        <Value>{moment(date).format('MMM D, YYYY')}</Value>
                    </div>
                    <div className='w-50 text-right'>
                        <Value>{`${amountUsd.toLocaleString(undefined, getCurrencyOptions("USD"))}`}</Value>
                        <Value>{pointsLabel}</Value>
                    </div>
                </div>
                <AnimatePresence mode='wait'>
                    {isExpanded && <ExpandedDetailsContainer variants={detailVariants} initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0, marginTop: 0}} animate={{opacity: 1, height: 'auto', marginTop: '20px'}}>
                        {isForeignTransaction && (
                            <>
                                <ExpandedDetailLabel>Amount in Original Currency</ExpandedDetailLabel>
                                <ExpandedDetailValue>{`${amountOrigin.toLocaleString(undefined, getCurrencyOptions(props.transaction.amount.currencyOrigin))}`}</ExpandedDetailValue>
                                <br/>
                            </>
                        )}
                        <ExpandedDetailLabel>Transaction Date</ExpandedDetailLabel>
                        <ExpandedDetailValue>{moment(date).format('MMMM D, YYYY hh:mm A') + ' ' + new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]}</ExpandedDetailValue>
                        <br/>
                        <ExpandedDetailLabel>Merchant Info</ExpandedDetailLabel>
                        <ExpandedDetailValue>{props.transaction.merchant.originalDescription}</ExpandedDetailValue>
                        <ExpandedDetailValue>{props.transaction.merchant.webAddress}</ExpandedDetailValue>
                        <br/>
                        <ExpandedDetailLabel>Memo</ExpandedDetailLabel>
                        <Memo onClick={stopPropogation} placeholder='Add a short note to your transaction' value={memo || ''} onChange={handleMemoOnChange} />
                        <br/>
                        {props.transaction.mccCode && <>
                            <ExpandedDetailLabel>MCC Code</ExpandedDetailLabel>
                            <ExpandedDetailLabel>{props.transaction.mccCode}</ExpandedDetailLabel>
                        </>
                        }
                    </ExpandedDetailsContainer>}
                </AnimatePresence>
            </Item>
        </> : <>
            <Item isPending={isPending} isTransactionMetadataExpanded={isTransactionMetadataExpanded}>
                <Value>
                    {moment(date).format('MMMM D')}
                    <br/>
                    {moment(date).format('YYYY')}
                </Value>
                <AnimatePresence>
                {isExpanded && <ExpandedDetailsContainer variants={detailVariants} initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0, marginTop: 0}} animate={{opacity: 1, height: 'auto', marginTop: '30px'}}>
                    <ExpandedDetailLabel>{'Transacted'}</ExpandedDetailLabel>
                    <ExpandedDetailValue>{moment(date).format('MMMM D, YYYY hh:mm A') + ' ' + new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]}
                    {(!props.transaction.dispute && props.transaction?.uuid && props.transaction.settled && (props.transaction.type === "PURCHASE")) && <><br/><br/><DisputeLink onClick={handleDispute}>Dispute</DisputeLink></>}
                    {props.transaction.dispute  && <><br/><br/>
                        <ExpandedDetailLabel>{'Dispute'}</ExpandedDetailLabel>
                        <ExpandedDetailValue>{props.transaction.dispute.referenceNumber} - {props.transaction.dispute.status}</ExpandedDetailValue>
                    </>}
                    {!!props.transaction.transactionMetadata?.length && 
                    <><br/><br/><TransactionMetadataLink onClick={toggleExpandTransactionMetadata}>Expand Transaction Details</TransactionMetadataLink></>}
                </ExpandedDetailValue>

                </ExpandedDetailsContainer>}
                </AnimatePresence>
            </Item>
            <Item isPending={isPending} isTransactionMetadataExpanded={isTransactionMetadataExpanded}>
                <Value>
                    {transactionDescription}
                </Value>
                <AnimatePresence>
                {isExpanded && <ExpandedDetailsContainer variants={detailVariants} initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0, marginTop: 0}} animate={{opacity: 1, height: 'auto', marginTop: '30px'}}>
                    <ExpandedDetailLabel>Also known as</ExpandedDetailLabel>
                    <ExpandedDetailValueDescription>{props.transaction.merchant.originalDescription}</ExpandedDetailValueDescription>
                    <br/>
                    {props.transaction.cardNickname && <>
                        <ExpandedDetailLabel>Card Used</ExpandedDetailLabel>
                        <CardLink onClick={goToCardInspector}>{props.transaction.cardNickname} ...{props.transaction.cardLast4}</CardLink>
                    </>}
                    {props.transaction.merchant.webAddress && <>
                        <br/>
                        <ExpandedDetailLabel>{'Merchant Contact'}</ExpandedDetailLabel>
                        <ExpandedDetailValue>{props.transaction.merchant.webAddress}</ExpandedDetailValue>
                    </>}
                </ExpandedDetailsContainer>}
                </AnimatePresence>
            </Item>
            <Item isPending={isPending} isTransactionMetadataExpanded={isTransactionMetadataExpanded}>
                <Value>
                    {props.transaction.categoryLabel} {isDecline && props.transaction.reason && `- ${props.transaction.reason}`}
                </Value>
                <AnimatePresence>
                {isExpanded && <ExpandedDetailsContainer variants={detailVariants} initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0, marginTop: 0}} animate={{opacity: 1, height: 'auto', marginTop: '30px'}}>
                    {isNotPaymentOrWithdrawal(props.transaction.type) &&
                        <>
                            {isDecline && props.transaction.reason && <>
                                <ExpandedDetailLabel>{'Memo'}</ExpandedDetailLabel>
                                <ExpandedDetailValue>{props.transaction.reason}</ExpandedDetailValue>
                            </>}
                        </>
                    }
                    <ExpandedDetailLabel>{'Memo'}</ExpandedDetailLabel>
                    <Memo onClick={stopPropogation} placeholder='Add a short note to your transaction' value={memo || ''} onChange={handleMemoOnChange} />
                    {props.transaction.mccCode && <>
                        <br/>
                        <ExpandedDetailLabel>{'MCC Code'}</ExpandedDetailLabel>
                        <ExpandedDetailValue>{'The transaction classification code'}</ExpandedDetailValue>
                        <ExpandedDetailLabel>{props.transaction.mccCode}</ExpandedDetailLabel>
                        </>
                    }
                    {!!props.transaction.interchangeEarned && <>
                        <br/>
                        <ExpandedDetailLabel>{'Interchange Earned'}</ExpandedDetailLabel>
                        <ExpandedDetailValue>{props.transaction.interchangeEarned}</ExpandedDetailValue>
                    </>}
                </ExpandedDetailsContainer>}
                </AnimatePresence>
            </Item>
            <Item isPending={isPending} isTransactionMetadataExpanded={isTransactionMetadataExpanded}>
                <Value>
                    {`${amountUsd.toLocaleString(undefined, getCurrencyOptions('USD'))}`} USD
                    <br/>
                    {pointsLabel}
                </Value>
                {isForeignTransaction &&(
                    <AnimatePresence>
                    {isExpanded && <ExpandedDetailsContainer variants={detailVariants} initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0, marginTop: 0}} animate={{opacity: 1, height: 'auto', marginTop: '30px'}}>
                        <ExpandedDetailLabel>{'Amount in Original Currency'}</ExpandedDetailLabel>
                        <ExpandedDetailValueDescription>{`${amountOrigin.toLocaleString(undefined, getCurrencyOptions(currencyOrigin))}`}</ExpandedDetailValueDescription>
                    </ExpandedDetailsContainer>}
                    </AnimatePresence>
                )}
            </Item>
            <Item isTransactionMetadataExpanded={isTransactionMetadataExpanded}/>
        </>}
        
    </Row>
        {
            isExpanded
            && isTransactionMetadataExpanded
            && <Row $expanded={isExpanded}>
                <DetailRow colSpan={5}><TransactionMetadataDetails transactionMetadata={props.transaction.transactionMetadata!}></TransactionMetadataDetails></DetailRow>
            </Row>
        }
    </>
}

const DisputeLink = styled.div`
    color: red;
    cursor: pointer;
    margin-top: ${styles.Spacing.M};
    user-select: none;
    &:hover {
        text-decoration: underline;
    }
`

const TransactionMetadataLink = styled.div`
    cursor: pointer;
    margin-top: ${styles.Spacing.M};
    user-select: none;
    &:hover {
        text-decoration: underline;
    }
`

const Memo = styled.textarea`
    outline: none;
    width: 100%;
    height: 100px;
    border: 1px solid #BFBFBF;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    text-align: top;
    display: flex;
    align-items: start;
    text-wrap: wrap;
    padding: 10px;
`

const ExpandedDetailValue = styled.div`
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    white-space: normal;
`

const CardLink = styled.div`
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    width: fit-content;
    white-space: normal;
    opacity: 1 !important;
    color: ${styles.Color.TaekusPurple};
    &:hover {
        text-decoration: underline;
    }
`

const ExpandedDetailValueDescription = styled.div`
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    white-space: normal;
`
const ExpandedDetailLabel = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
`

const ExpandedDetailsContainer = styled(motion.div)`
`

const Value = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.M};
    }
`

type ItemProps = {
    isPending?: boolean,
    isDecline?: boolean,
    maxWidth?: string,
    isTransactionMetadataExpanded?: boolean
}

const Item = styled.td<ItemProps>`
    font-style: normal;
    font-weight: 400;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
    color: ${props => props.isDecline ? `darkred` :  `rgba(0,0,0,${props.isPending ? '0.5' : '1'})`};
    ${props => !props.isTransactionMetadataExpanded && ` border-bottom: 1px solid rgba(0,0,0,0.5);`};
    vertical-align: top;
    padding-top: ${styles.Spacing.XS};
    padding-bottom: ${styles.Spacing.XS};
    padding-right: ${styles.Spacing.XS};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:nth-child(4) {
        text-align: right;
        padding-right: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        &:nth-child(1) {
            padding-left: ${styles.Spacing.XS};
        }
    }
`

type RowProps = {
    $expanded: boolean;
}

const Row = styled(motion.tr)<RowProps>`
    ${styles.Animation.transitionStyles}
    cursor: pointer;
    ${props => props.$expanded && `background-color: rgba(124, 61, 118, 0.1);`}
    &:hover {
        background-color: rgba(124, 61, 118, 0.15);
    }
`

const DetailRow = styled.td`
    border-bottom: 1px solid rgba(0,0,0,0.5);
`

export default TransactionItem;