import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api';

import { categoryMccCodes } from 'utils/categoryMccCodes';

import { TransactionFilterOptions } from 'components/common/Transactions/constants';

export const cardsSlice = createSlice({
  name: 'cards',
  initialState: {
    cards: [],
    cardTransactionsFetchController: null,
    didTransactionFailParsing: false,
    isCreatingVirtualCard: false,
    isLoadingCardDetails: false,
    isLoadingCards: false,
    isLoadingSelectedCardTransactions: false,
    isMoreTransactions: undefined,
    defaultLoadedTransactionsCardUuid: undefined,
    previewCardDetails: null,
    selectedCardTransactions: [],
    selectedPreviewCard: null,
  },
  reducers: {
    setPreviewCard: (state, action) => {
      state.selectedPreviewCard = action.payload;
    },
    cardPreviewLoading: state => {
      state.isLoadingCardDetails = true
    },
    cardPreviewComplete: (state, action) => {
      state.isLoadingCardDetails = false
      state.previewCardDetails = action.payload 
    },
    cardsLoading: state => {
      state.isLoadingCards = true;
    },
    cardsRecieved: (state, action) => {
      state.cards = action.payload.cards;
      state.isLoadingCards = false;
    },
    selectedCardTransactionsLoading: (state, action) => {
      if (state.cardTransactionsFetchController) {
        (state.cardTransactionsFetchController as any).abort();
      }
      state.isLoadingSelectedCardTransactions = true;
      state.cardTransactionsFetchController = action.payload
    },
    selectedCardTransactionsRecieved: (state, action) => {
      state.selectedCardTransactions = action.payload.transactions;
      state.isMoreTransactions = action.payload.isMore;
      state.didTransactionFailParsing = action.payload.didTransactionFailParsing;
      state.isLoadingSelectedCardTransactions = false;
      state.cardTransactionsFetchController = null;
      state.defaultLoadedTransactionsCardUuid = action.payload.defaultLoadedTransactionsCardUuid;
    },
    createVirtualCardsLoading: state => {
      state.isCreatingVirtualCard = true;
    },
    createVirtualCardsComplete: (state) => {
      state.isCreatingVirtualCard = false;
    }
  }
})
  
export const { 
  setPreviewCard,
  cardsLoading,
  cardsRecieved,
  cardPreviewLoading,
  cardPreviewComplete,
  selectedCardTransactionsLoading,
  selectedCardTransactionsRecieved,
  createVirtualCardsLoading,
  createVirtualCardsComplete
} = cardsSlice.actions

export const fetchCards = (
  uuid: string,
) => async (dispatch: any) => {
  dispatch(cardsLoading());
  const response = await API.banking.paymentCards.get({
    cardAccountUuid: uuid
  })
  dispatch(cardsRecieved({
    cards: response.data,
  }));
}

export const fetchCardDetails = (
  cardAccountUuid: string,
  cardUuid: string,
) => async (dispatch: any) => {
  dispatch(cardPreviewLoading())

  const response = await API.banking.paymentCards
    .getCardDetails({
      cardAccountUuid,
      uuid: cardUuid,
    }).catch((e) => {
      // in the 400 case, we want to do nothing
      return e
    }) 

  dispatch(cardPreviewComplete(response.data))
}

export const fetchSelectedCardTransactions = (
  cardAccountUuid: string,
  startIndex:number=0,
  filterOptions: TransactionFilterOptions,
  isDefaultFetch: boolean = false
) => async (dispatch: any) => {
  const controller = new AbortController();
  dispatch(selectedCardTransactionsLoading(controller));
  
  const transactionParams = {
    cardAccountUuid,
    startIndex,
    startDate: filterOptions.startDate?.toDate(),
    endDate: filterOptions.endDate?.toDate(),
    filterType: filterOptions.type,
    searchString: filterOptions.searchString,
    minimumAmount: filterOptions.minimumAmount,
    maximumAmount: filterOptions.maximumAmount,
    mccs: filterOptions.categories.map((category: string) => (categoryMccCodes as any)[category]).flat(),
    cardUuids: filterOptions.cardUuids,
  }

  return API.banking.fetchTransactions(transactionParams, controller)
    .then((response) => {
      dispatch(selectedCardTransactionsRecieved({
        transactions: (response.data as any).transactions,
        isMore: (response.data as any).isMore,
        didTransactionFailParsing: (response.data as any).didTransactionFailParsing,
        defaultLoadedTransactionsCardUuid: isDefaultFetch ? filterOptions.cardUuids[0] : undefined,
      }));
    })
    .catch((e) => {
      // handle error
    }) 
}

export const createVirtualCard = (
  cardAccountUuid: string,
  addressUuid?: string
) => async (dispatch: any) => {
  dispatch(createVirtualCardsLoading());
  

  await API.banking.paymentCards.create({ 
    cardAccountUuid,
    addressUuid
  })
  
  dispatch(createVirtualCardsComplete());
}

export default cardsSlice.reducer