import React from "react"
import moment from "moment"
import { SearchOptions, TripType } from "./constants"

export const mapEnumeratedSearchOptions = (option: any) => <option value={option.value}>{option.label}</option>

export const isMomentInFlightRange = (date?: moment.Moment) => {
    return date?.isSameOrAfter(moment(), 'day') && date?.isSameOrBefore(moment().add(335, 'days'), 'day')
}

export const isRewardsSearchOptionsValid = (searchOptions: SearchOptions) => {
    return !!searchOptions.departureAirport && !!searchOptions.arrivalAirport && isMomentInFlightRange(searchOptions.departureDate) && (searchOptions.tripType === TripType.OneWay || isMomentInFlightRange(searchOptions.returnDate))
}

export const isFlightSearchOptionsValid = (searchOptions: SearchOptions) => {
    if (!searchOptions.departureAirport || !searchOptions.arrivalAirport) {
        return false;
    }

    if (!isMomentInFlightRange(searchOptions.departureDate) || (searchOptions.tripType === TripType.RoundTrip && !isMomentInFlightRange(searchOptions.arrivalAirport))) {
        return false;
    }
    
    return true
}

export const getSearchOptionsFromLocalStorage = () => {
    const optionsCopy = JSON.parse(localStorage.getItem('searchOptions')!)
    if (optionsCopy) {
        const departureDate = moment(optionsCopy.departureDate)
        const returnDate = moment(optionsCopy.returnDate)
        
        /*
         *  If either of the currently saved dates aren't valid, 
         *  set the departure date for 2 weeks out, 
         *  and the return date for a week after the departure
         */
        if (!isMomentInFlightRange(departureDate) || !isMomentInFlightRange(returnDate)) {
            optionsCopy.departureDate = moment().add(2, 'weeks')
            optionsCopy.returnDate = moment().add(3, 'weeks')
        } else {
            // Dates are not stored in localStorage as moment objects, so convert them if we aren't creating new ones above
            optionsCopy.departureDate = departureDate
            optionsCopy.returnDate = returnDate
        }

        /*
         *  If the currently saved return date is before the departure date, 
         *  set the return date for a week after the departure
         */
        if (returnDate.isSameOrBefore(departureDate, 'day')) {
            optionsCopy.returnDate = moment(departureDate).add(1, 'week')
        }
    }
    if (optionsCopy?.airlineOption) delete optionsCopy.airlineOption
    if (optionsCopy?.connectingAirport) delete optionsCopy.connectingAirport
    return optionsCopy;
}

export const updateSearchOptions = (option: keyof SearchOptions, value: any, searchOptions: SearchOptions) => {
    const updatedOptions = { ...searchOptions, departureDate: searchOptions.departureDate, returnDate: searchOptions.returnDate }

    if (option === 'departureDate' && searchOptions.returnDate) {
        /*
         * If updating the departure date, update the return date
         * to a day the same distance between the current search options.
         */
        const distanceInDays = Math.abs(searchOptions.departureDate?.diff(searchOptions.returnDate, 'days') || 0);
        updatedOptions.returnDate = value.clone().add(distanceInDays, 'days')
    } else if (option === 'tripType') {
        // If updating the trip type, unset the round trip
        updatedOptions.returnDate = undefined
    }

    updatedOptions[option] = value;
    return updatedOptions
}