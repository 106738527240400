import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalDialog } from "react-bootstrap";

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components"

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'
import { Actions as BankingActions } from 'redux/features/banking/banking'

import Button, { ButtonSize, ButtonType } from "components/common/Button";
import Select from "components/common/Select/Select";
import selectStyles from "components/common/Select/selectStyles";

import { ReactComponent as Close } from "assets/svg/Close.svg";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles"

const NewTagLabel = '+ Create Tag'

type TagProps = {
    card: any
    label?: string,
    ref?: any,
    selectKey?: string,
}

const Tag = (props: TagProps) => {
    const dispatch = useDispatch()

    // Redux state
    const banking = useSelector((state: any) => state.banking)

    // Component state
    const [searchInput, setSearchInput] = useState('')
    const [newTagInput, setNewTagInput] = useState('')
    const [tempKey, setTempKey] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const containerRef = useRef(null);
    
    const { card } = props;

    const preventDefault = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();
    }

    const addTagToCard = (tag: string) => {
        tag = tag.toLowerCase()
        const updatedTags = [...card.tags, tag]
        const updatedPaymentCard = {
            ...card,
            cardAccountUuid: banking.account.uuid,
            tags: updatedTags
        }
        dispatch(PaymentCardActions.updatePaymentCard(updatedPaymentCard))
        dispatch(BankingActions.updateAccountTags(updatedTags))
        setTempKey('')
        setIsModalOpen(false)
    }

    const handleOnChange = (option: any) => {
        // reset select key so the placeholder doesn't disappear
        setTempKey('temp')
        if (option.value === NewTagLabel) {
            setIsModalOpen(true)
        } else {
            addTagToCard(option.value)
        }
        setSearchInput('')
    }

    const handleNewTagInput = (ev: any) => {
        setNewTagInput(ev.target.value)
    }

    const deleteTag = () => {
        const updatedPaymentCard = {
            ...card,
            cardAccountUuid: banking.account.uuid,
            tags: card.tags?.filter((tag: string) => tag !== props.label),
        }
        dispatch(PaymentCardActions.updatePaymentCard(updatedPaymentCard))
    }

    const closeModal = () => {
        setIsModalOpen(!isModalOpen);
        setNewTagInput('')
        setTempKey('');
    }

    const addNewTag = () => {
        addTagToCard(newTagInput);
        setNewTagInput('');
    }

    let options = [{ value: NewTagLabel, label: NewTagLabel }]
    if (banking.account.tags?.length) {
        options = options.concat(banking?.account.tags
            .filter((tag: any) => !props.card.tags?.includes(tag))
            .map((tag: any) => ({ value: tag, label: tag }))
        )
    }

    return <Container title={props.label} ref={containerRef} newTag={!props.label} onClick={preventDefault} onDoubleClick={preventDefault}>
        {props.label ? <Label>
            <Text>{props.label}</Text>
            <CustomCross onClick={deleteTag}/>
        </Label> : <Select
            key={(tempKey !== '' ? tempKey : props.selectKey) || ''}
            dropdownIndicator={<></>}
            styleType={selectStyles.tags}
            inputValue={searchInput}
            placeholder="+ Add tag"
            options={options}
            onChange={handleOnChange}
            // menuPortalTarget and menuPosition allows the dropdown menu to position on top of other components
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
        />}
        <Modal dialogAs={Dialog} centered show={isModalOpen}>
            <ModalBody>
                <div className='w-100 d-flex justify-content-between align-items-center'>
                    <ModalTitle>Add New Tag</ModalTitle>
                    <CloseButton onClick={closeModal}>
                        <StyledClose/>
                    </CloseButton>
                </div>
                <TagInput autoFocus value={newTagInput} onChange={handleNewTagInput} placeholder='Add a tag name'/>
                <WarningContainer>
                    <AnimatePresence>
                        {banking.account.tags?.includes(newTagInput) && <Warning {...fadeInOutMotionProps}>This tag already exists, add it anyways?</Warning>}
                    </AnimatePresence>
                </WarningContainer>
                <ButtonContainer>
                    <Button disabled={newTagInput === ''} size={ButtonSize.Wide} label="Add tag" buttonType={ButtonType.Purple} onClick={addNewTag}/>
                </ButtonContainer>
            </ModalBody>
        </Modal>
    </Container>
}

const Dialog = styled(ModalDialog)`
    max-width: 400px;
`

const StyledClose = styled(Close)`
    height: 24px;
    width: 24px;
`

const WarningContainer = styled.div`
    height: ${styles.Spacing.M};
`

const Text = styled.div`
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    width: fit-content;
`

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${styles.MediaQueries.Mobile} {
        padding: 10px;
    }
`

const Warning = styled(motion.div)`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    margin: ${styles.Spacing.XS} ${styles.Spacing.M};
`

const TagInput = styled.input`
    margin: ${styles.Spacing.XS} ${styles.Spacing.M};
    outline: 0;
    border: 0;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
`

const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
    cursor: pointer;
    margin: ${styles.Spacing.XS};
`

const CustomCross = styled(Close)`
    width: 16px;
    height: 16px;
    fill: rgba(0, 0, 0, 0.5);
    &:hover {
        fill: rgba(0, 0, 0, 1);
    }
    cursor: pointer;
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    margin-bottom: ${styles.Spacing.S};
`

const ModalTitle = styled.div`
    padding: 0 ${styles.Spacing.S};
    font-size: 20px;
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Label = styled.div`
    display: flex;
    max-width: 100%;
    padding: 4px 6px;
    user-select: none;
`

type ContainerProps = {
    newTag: boolean
}

const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    border: 1px solid ${props => props.newTag ? styles.Color.TaekusPurple : styles.Color.Grey};
    border-radius: ${styles.BorderRadius.S};
    height: 25px;
    font-style: normal;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.5);
    margin-right: ${styles.Spacing.XS};
    margin-bottom: ${styles.Spacing.XS};
    ${styles.Animation.transitionStyles}
`

export default Tag