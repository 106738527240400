import React from 'react'

import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Row, Col } from 'react-bootstrap'
import { Button } from 'reactstrap'

import { ReactComponent as TaekusIcon } from 'assets/svg/TaekusIcon.svg'

import 'assets/components/login/css/login.scss'

// TODO: This is basically a throwaway module and should be refactored eventually

class SignupSuccess extends React.Component {
  render() {
    return (
      <Row className="Login-container m-0">
        <Col md="6" lg="5">
          <Col
            xs="12"
            md={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 2 }}
            className="Login-formBlock"
          >
            <h3 className="Login-subtitle my-5 pt-5 text-center">
              <FormattedMessage id="signup-success.welcome" defaultMessage={`Welcome to Taekus`} />
            </h3>
            <div className="StandardBodyDMSans mb-5 text-center">
              <FormattedMessage
                id="signup-success.description"
                defaultMessage={`Thanks for opening an account with Taekus! We're happy to have you here. Click below to go to your new account overview.`}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <NavLink to="/" className="text-decoration-none">
                <Button className="StandardButtonPurple">
                  <FormattedMessage id="signup.submit" defaultMessage={`Go to Account`} />
                </Button>
              </NavLink>
            </div>
          </Col>
        </Col>
        <Col
          md="6"
          lg="7"
          className="Login-decorativeBackground d-none d-md-flex justify-content-center"
        >
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="Taekus-Icon-Large">
              <TaekusIcon fill="white" style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default SignupSuccess
