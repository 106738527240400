import values from 'lodash/values'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'

import API from 'services/api'
import { TicketStatus } from 'components/pages/Trips/constants'

const featureName = 'Trips'

const ACTION_FETCH_TRIPS_START = `${featureName}/FETCH_TRIPS_START`
const ACTION_FETCH_TRIPS_COMPLETE = `${featureName}/FETCH_TRIPS_COMPLETE`
const ACTION_CLEAR_TRIPS = `${featureName}/CLEAR_TRIPS`
const ACTION_FETCH_TRIPS_ERROR = `${featureName}/FETCH_TRIPS_ERROR`

const ACTION_FETCH_TRIP_FULL_START = `${featureName}/FETCH_TRIP_FULL_START`
const ACTION_FETCH_TRIP_PARTIAL_START = `${featureName}/FETCH_TRIP_PARTIAL_START`
const ACTION_FETCH_TRIP_COMPLETE = `${featureName}/FETCH_TRIP_COMPLETE`
const ACTION_FETCH_TRIP_ERROR = `${featureName}/FETCH_TRIP_ERROR`

const ACTION_UPDATE_TRIP_START = `${featureName}/UPDATE_START`
const ACTION_UPDATE_TRIP_COMPLETE = `${featureName}/UPDATE_COMPLETE`
const ACTION_UPDATE_TRIP_ERROR = `${featureName}/UPDATE_ERROR`

const ACTION_CANCEL_TRIP_START = `${featureName}/CANCEL_START`
const ACTION_CANCEL_TRIP_COMPLETE = `${featureName}/CANCEL_COMPLETE`
const ACTION_CANCEL_TRIP_ERROR = `${featureName}/CANCEL_ERROR`

const ACTION_FETCH_CANCEL_COST_START = `${featureName}/FETCH_CANCEL_COST_START`
const ACTION_FETCH_CANCEL_COST_COMPLETE = `${featureName}/FETCH_CANCEL_COST_COMPLETE`
const ACTION_FETCH_CANCEL_COST_ERROR = `${featureName}/FETCH_CANCEL_COST_ERROR`

export const Actions = {
  clearTrips: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_TRIPS })
  },

  fetchTrip: (uuid, partial = false) => (dispatch) => {
    // Full update is triggered when we don't have any information about the trip
    // in the redux store, and blocks the frontend from displaying anything. Partial
    // update kicks off a GDS API call, but instantly displays whatever's in the database to the user.

    if (partial) {
      dispatch({ type: ACTION_FETCH_TRIP_PARTIAL_START })
    } else {
      dispatch({ type: ACTION_FETCH_TRIP_FULL_START })
    }

    return API.trips
      .fetch(uuid)
      .then((response) => {
        dispatch({
          type: ACTION_FETCH_TRIP_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_TRIP_ERROR,
          payload: e,
        })
      })
  },
  fetchTrips: (searchParams) => (dispatch) => {
    dispatch({ type: ACTION_FETCH_TRIPS_START })

    return API.trips
      .fetchAll(searchParams)
      .then((response) => {
        dispatch({
          type: ACTION_FETCH_TRIPS_COMPLETE,
          payload: response.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_TRIPS_ERROR,
          payload: e,
        })
      })
  },
  fetchRefundCost: (params) => (dispatch) => {
    dispatch({
      type: ACTION_FETCH_CANCEL_COST_START,
      payload: params,
    })

    return API.trips
      .fetchRefundCost(params)
      .then((response) => {
        dispatch({
          type: ACTION_FETCH_CANCEL_COST_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_FETCH_CANCEL_COST_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  cancelTrip: (params) => (dispatch) => {
    dispatch({
      type: ACTION_CANCEL_TRIP_START,
      payload: params,
    })

    return API.trips
      .cancel(params)
      .then((response) => {
        dispatch({
          type: ACTION_CANCEL_TRIP_COMPLETE,
          payload: params.uuid,
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CANCEL_TRIP_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
  updateTrip: (tripParams) => (dispatch) => {
    // Here we preemptively update the frontend object on the assumption
    // that the backend server call will succeed, in order to provide the illusion
    // of faster/better performance.
    dispatch({
      type: ACTION_UPDATE_TRIP_START,
      payload: tripParams,
    })

    return API.trips
      .update(tripParams)
      .then((response) => {
        dispatch({
          type: ACTION_UPDATE_TRIP_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_UPDATE_TRIP_ERROR,
          payload: { ...e.response.data },
        })
      })
  },
}

// REDUCER

const defaultState = {
  trips: [],
  cancel: {
    success: null,
    isError: null,
    isCostError: null,
    isCostLoading: false,
    isLoading: false,
    data: null,
  },
  isLoading: false,
  isUpdating: false,
  error: null,
  errors: [],
}

export const TripsReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_FETCH_TRIPS_COMPLETE:
      return {
        ...state,
        trips: action.payload,
        isLoading: false,
      }

    case ACTION_FETCH_TRIP_COMPLETE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        trips: values(merge(keyBy(state.trips, 'uuid'), keyBy([action.payload], 'uuid'))),
      }

    case ACTION_CLEAR_TRIPS:
      return {
        ...defaultState,
      }

    case ACTION_UPDATE_TRIP_START:
      return {
        ...state,
        trips: values(merge(keyBy(state.trips, 'uuid'), keyBy([action.payload], 'uuid'))),
        error: false,
      }

    case ACTION_FETCH_TRIPS_START:
    case ACTION_FETCH_TRIP_FULL_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      }

    case ACTION_FETCH_TRIP_PARTIAL_START:
      return {
        ...state,
        isUpdating: true,
        error: false,
      }

    case ACTION_UPDATE_TRIP_COMPLETE:
      return {
        ...state,
        error: false,
        isLoading: false,
        trips: values(merge(keyBy(state.trips, 'uuid'), keyBy([action.payload], 'uuid'))),
      }

    case ACTION_UPDATE_TRIP_ERROR:
      return {
        ...state,
        errors: {
          ...state,
          ...action.payload,
        },
      }

    case ACTION_FETCH_TRIP_ERROR:
    case ACTION_FETCH_TRIPS_ERROR:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        error: true,
      }

    case ACTION_FETCH_CANCEL_COST_START:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isCostError: null,
          isCostLoading: true,
          data: null,
          success: null,
        }
      }

    case ACTION_FETCH_CANCEL_COST_COMPLETE:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isCostError: false,
          isCostLoading: false,
          data: action.payload
        }
      }

    case ACTION_FETCH_CANCEL_COST_ERROR:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isCostError: true,
          isCostLoading: false,
        }
      }

    case ACTION_CANCEL_TRIP_START:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isError: null,
          isLoading: true,
          success: null,
        }
      }

    case ACTION_CANCEL_TRIP_COMPLETE:
      let cancelledTrip = state.trips.find((trip) => trip.uuid === action.payload);
      cancelledTrip.ticketStatus = TicketStatus.CANCEL_STARTED;

      return {
        ...state,
        cancel: {
          ...state.cancel,
          isError: false,
          isLoading: false,
          success: true,
        }
      }

    case ACTION_CANCEL_TRIP_ERROR:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isError: true,
          isLoading: false,
          success: false,
        }
      }

    default:
      return state
  }
}
