import {
    backgroundBlue,
    backgroundBlue15PctOpacity,
    backgroundBlue8PctOpacity,
} from 'components/common/styleConstants'
import styles from 'styles/styles'

export enum FlightLocationDropdownStyleTypes {
  MobileFlights,  
  MobileRewards,
  Rewards,
  Settings
}

export const flightLocationDropdownStyles = {
  [FlightLocationDropdownStyleTypes.MobileFlights]: {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor:
        state.isDisabled || state.isFocused || state.isSelected
          ? backgroundBlue8PctOpacity()
          : styles.Color.White,
      color: backgroundBlue(),
      '&:active': {
        backgroundColor: backgroundBlue15PctOpacity(),
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: 0,
      padding: 0,
    }),
    placeholder: (provided: any) => ({
      color: styles.Color.White,
      gridArea: '1/1/2/3',
      fontSize: styles.Font.Size.Small,
      opacity: 0.5,
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: styles.Color.White,
      fontSize: styles.Font.Size.Small,
      padding: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
      border: `1px solid ${styles.Color.GreyText}`,
      borderRadius: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      width: '100%',
      '@media only screen and (max-width: 576px)': {
        width: '100%',
      },
    }),
    input: (provided: any) => ({
      ...provided,
      color: styles.Color.White,
      padding: 0,
      height: styles.Spacing.S,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      caretColor: 'transparent',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      outline: 'none',
      padding: 0,
      boxShadow: 'none',
      minWidth: '100%',
      backgroundColor: styles.Color.Transparent,
      border: 'none',
      fontFamily: styles.Font.Family.MonumentGrotesk,
    }),
  },
  [FlightLocationDropdownStyleTypes.MobileRewards]: {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor:
        state.isDisabled || state.isFocused || state.isSelected
          ? backgroundBlue8PctOpacity()
          : styles.Color.White,
      color: backgroundBlue(),
      '&:active': {
        backgroundColor: backgroundBlue15PctOpacity(),
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: 0,
      padding: 0,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: '8px',
      paddingRight: '0',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    placeholder: (provided: any) => ({
      color: styles.Color.White,
      gridArea: '1/1/2/3',
      fontSize: styles.Font.Size.Medium,
      whiteSpace: 'nowrap',
      padding: 0,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: styles.Color.White,
      fontSize: styles.Font.Size.Small,
      padding: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
      border: `1px solid ${styles.Color.GreyText}`,
      borderRadius: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      width: '100%',
      '@media only screen and (max-width: 576px)': {
        width: '100%',
      },
    }),
    input: (provided: any) => ({
      ...provided,
      color: styles.Color.White,
      fontSize: styles.Font.Size.Small,
      padding: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      border: 0,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      outline: 'none',
      padding: 0,
      boxShadow: 'none',
      width: '100%',
      height: styles.Spacing.L,
      backgroundColor: 'transparent',
      borderRadius: 0,
      border: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      margin: 0,
      '&:hover': {
        borderColor: styles.Color.White,
        opacity: 1,
      },
    }),
    container: (provided: any) => ({
      ...provided,
      minWidth: '20%',
      padding: 0,
      flex: 1,
      textAlign: 'center',
    }),
  },
  [FlightLocationDropdownStyleTypes.Settings]: {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor:
        state.isDisabled || state.isFocused || state.isSelected
          ? backgroundBlue8PctOpacity()
          : styles.Color.White,
      color: backgroundBlue(),
      '&:active': {
        backgroundColor: backgroundBlue15PctOpacity(),
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: 0,
      padding: 0,
    }),
    placeholder: (provided: any) => ({
      color: styles.Color.Black,
      gridArea: '1/1/2/3',
      fontSize: styles.Font.Size.Small,
      opacity: 0.5,
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: styles.Color.Black,
      fontSize: styles.Font.Size.Small,
      padding: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
      border: `1px solid ${styles.Color.GreyText}`,
      borderRadius: 0,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      width: '100%',
      '@media only screen and (max-width: 576px)': {
        width: '100%',
      },
    }),
    input: (provided: any) => ({
      ...provided,
      color: styles.Color.Black,
      padding: 0,
      height: styles.Spacing.S,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      outline: 'none',
      padding: 0,
      boxShadow: 'none',
      backgroundColor: styles.Color.Transparent,
      borderRadius: 0,
      minWidth: '160px',
      borderTop: '1px solid transparent',
      borderLeft: '1px solid transparent',
      borderRight: '1px solid transparent',
      borderBottom: `1px solid ${styles.Color.TaekusPurple}`,
      fontFamily: styles.Font.Family.MonumentGrotesk,
      ':hover': {
        border: `1px solid ${styles.Color.TaekusPurple}`,
      }
    }),
  }
}