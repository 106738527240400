import { ReadyState, SendMessage } from 'react-use-websocket';
import API from 'services/api';
import camelCaseKeys from 'utils/camelCaseKeys';
import { responseExtractor } from 'components/common/Websocket/WsResponseExtractors';
import snakeCaseKeys from "utils/snakeCaseKeys";

export const getConnectionState = (readyState: number) => {
	return {
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Open',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
	}[readyState];
}

export const refreshTokenGetWebsocketEndpoint = (pathname: string) => {
	const accessToken = localStorage.getItem('accessToken')
	if (accessToken && accessToken !== 'undefined') {
		const tokenExpTime = JSON.parse(window.atob(accessToken.split('.')[1]))['exp'] * 1000
		const refreshToken = localStorage.getItem('refreshToken')
		if (tokenExpTime < Date.now() && refreshToken) {
		  API.auth.refreshAccessToken(refreshToken).then((response: any) => {
			const { access } = response.data
			localStorage.setItem('accessToken', access)
		  })
		}
	}
	const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
	const hostname = publicUrl.hostname
	if (hostname === "localhost") {
		return `ws://${hostname}:8000/ws/${pathname}?token=${accessToken}`
	} else {
		return `wss://${hostname}/ws/${pathname}?token=${accessToken}`
	}
}

export const wsRequest = (sendMessage: SendMessage, messageType: string, body: any, requestId: string="") => {
	const params = {
		type: messageType,
		request_id: requestId,
		message: snakeCaseKeys(body),
	}
	sendMessage(JSON.stringify(params))
	return
	
}

export const parseResponse = (lastMessage: any) => {
	// Extract and flatten keys from WS response.
	const formattedMessage =  camelCaseKeys(JSON.parse(lastMessage.data))
	const data = responseExtractor(formattedMessage?.message?.jobName || formattedMessage?.type, formattedMessage)

	return {
		jobType: formattedMessage?.type,
		jobId: formattedMessage?.jobId,
		requestId: formattedMessage?.requestId,
		status: formattedMessage?.status,
		name: formattedMessage?.message?.jobName, // only used for status message. Foreign keys to the jobType
		data: data,
	}
}