import React from "react";

import { styled } from "styled-components";

import Modal from "components/common/Modal"

import styles from "styles/styles";

type ChangeFlightModalProps = {
    onClose: () => void,
}

const ChangeFlightModal = (props: ChangeFlightModalProps) => {
    const { onClose } = props;

    const emailValue = 'support@taekus.com'

    const copyEmail = () => {
        navigator.clipboard.writeText(emailValue);
    }

    return <Modal title='Change Flight' onClose={onClose}>
        <Container>
            <Text>If you need to change your flight for any reason, please contact <CopyableEmail onClick={copyEmail}>{emailValue}</CopyableEmail>.</Text>
            <Text>Please include your <Bold>Taekus Booking Code</Bold> in your email so we can find your booking.</Text>
        </Container>
    </Modal>
}

const CopyableEmail = styled.span`
    color: ${styles.Color.TaekusPurple};
    cursor: pointer;
`

const Bold = styled.span`
    font-weight: 500;
`

const Text = styled.div`
    &:first-child {
        margin-bottom: 24px;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    text-wrap: wrap;
    margin-top: 15px;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
`

export default ChangeFlightModal