const messages = {
    AccountInfo: {
        Labels: {
            BankName: 'Bank Name',
            BankAddress: 'Bank Address',
            ABARoutingNumber: 'ABA/Routing Number',
            SwiftCode: 'SWIFT Code',
            AccountNumber: 'Account Number',
            NameOnAccount: 'Name on Account',
            PeerTransferCode: 'TaekusPay Code'
        },
        BankAddresses: {
            Stearns: '4191 2nd St. S, St. Cloud, MN 56301, (320) 253-6607'
        },
        BankNames: {
            Stearns: 'Stearns Bank'
        }
    }
}

export default messages;