import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from "react-bootstrap";

import { FaRegTimesCircle } from 'react-icons/fa'

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import { Locale, USDCurrencyOptions } from 'utils/constants'

import { Actions as AutoPayActions } from 'redux/features/banking/autopay'
import { DropdownKeys } from 'redux/features/global/constants'
import { updateDropdownKey } from 'redux/features/global/globalSlice'

import Spinner from 'components/common/Spinner'
import PlaidModal from 'components/pages/Funding/PlaidModal'

import { ReactComponent as Checkmark } from "assets/svg/Checkmark.svg"
import { ReactComponent as DropdownArrow } from "assets/svg/DropdownArrow.svg";

import { fadeInOutMotionProps, fadeUpwardMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles'

const AutoFund = () => {
    // Redux state
    const dispatch = useDispatch()
    const banking = useSelector((state: any) => state.banking)
    const autopay = useSelector((state: any) => state.autopay)
    const isAccountDropdownOpen = useSelector((state: any) => state.global.dropdownKey) === DropdownKeys.Funding.Account;
    const externalAccounts = useSelector((state: any) => state.linkedAccounts.linkedAccounts)

    // Component State
    const [selectedExternalAccount, setSelectedExternalAccount] = useState<any | undefined>(undefined);
    const [minimumThresholdAmount, setMinimumThresholdAmount] = useState(autopay.workingCopy.active ? autopay.workingCopy.debitReloadBalanceTrigger : '');
    const [reloadAmount, setReloadAmount] = useState(autopay.workingCopy.active ? autopay.workingCopy.amount : '');
    const [isEditing, setIsEditing] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [termsIsAgreed, setTermsIsAgreed] = useState(false)
    
    useEffect(() => {
        if (autopay.workingCopy.active) {
            setMinimumThresholdAmount(autopay.workingCopy.debitReloadBalanceTrigger)
            setReloadAmount(autopay.workingCopy.amount)
            setSelectedExternalAccount(externalAccounts.find((account: any) => account.uuid === autopay.workingCopy.externalAccount))
        } else {
            setMinimumThresholdAmount('')
            setReloadAmount('')
            setSelectedExternalAccount(undefined)
        }
    }, [autopay]) // eslint-disable-line

    const stopPropagation = (ev: React.MouseEvent) => {
        ev.stopPropagation()
    }

    const toggleAccountDropdown = (ev: React.MouseEvent) => {
        dispatch(updateDropdownKey(isAccountDropdownOpen ? '' : DropdownKeys.Funding.Account))
        ev.stopPropagation()
    }

    const toggleIsEditing = (ev: React.MouseEvent) => {
        setIsEditing(!isEditing)
    }

    const mapExternalAccountToDropdownItem = (externalAccount: any) => {
        const isAvailable = externalAccount.verified && externalAccount.isTransferReady;
        const isVerifiable = !externalAccount.verified;

        const selectExternalAccount = () => {
            setSelectedExternalAccount(externalAccount)
        }

        return <ExternalAccount isAvailable={isAvailable} onClick={isAvailable ? selectExternalAccount : stopPropagation}>
            <div>
                <ExternalAccountTitle isAvailable={isAvailable}>{externalAccount.name} — {externalAccount.accountLast4}</ExternalAccountTitle>
                <ExternalAccountType>{externalAccount.type === 'checking' ? 'Checking' : 'Savings'}</ExternalAccountType>
            </div>
            {isVerifiable && <PlaidModal linkedAccount={externalAccount}>
                <VerifyLink>Verify</VerifyLink>
            </PlaidModal>}
            {!isAvailable && !isVerifiable && <FadedText>Unavailable</FadedText>}
        </ExternalAccount>
    }

    const onMinimumThresholdAmountBlur = () => {
        // Add commas back on blur
        setMinimumThresholdAmount(Number(minimumThresholdAmount?.replaceAll(',','')).toLocaleString(Locale.English, USDCurrencyOptions).slice(1)) 
    }

    const onMinimumThresholdAmountFocus = () => {
        // Add commas back on blur
        setMinimumThresholdAmount(minimumThresholdAmount?.replaceAll(',','')) 
    }

    const updateMinimumThresholdAmount = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(Number(ev.target.value))) {
            const newValue = ev.target.value?.replaceAll(',', '')

            const valueIsANumber = !isNaN(Number(newValue))
            const valueIsInRange = Number(newValue) >= 0 && Number(newValue) < 10000000
            const valueHasUpTo2DecimalPlaces = (!newValue.includes('.') || newValue.split('.')[1]?.length <= 2)

            if (valueIsANumber && valueIsInRange && valueHasUpTo2DecimalPlaces){
                setMinimumThresholdAmount(newValue)
            }
        }
    }

    const onReloadAmountBlur = () => {
        // Add commas back on blur
        setReloadAmount(Number(reloadAmount?.replaceAll(',','')).toLocaleString(Locale.English, USDCurrencyOptions).slice(1)) 
    }

    const onReloadAmountFocus = () => {
        // Add commas back on blur
        setReloadAmount(reloadAmount?.replaceAll(',','')) 
    }

    const updateReloadAmount = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(Number(ev.target.value))) {
            const newValue = ev.target.value?.replaceAll(',', '')

            const valueIsANumber = !isNaN(Number(newValue))
            const valueIsInRange = Number(newValue) >= 0 && Number(newValue) < 10000000
            const valueHasUpTo2DecimalPlaces = (!newValue.includes('.') || newValue.split('.')[1]?.length <= 2)

            if (valueIsANumber && valueIsInRange && valueHasUpTo2DecimalPlaces){
                setReloadAmount(newValue)
            }
        }
    }

    const updateAutopay = () => {
        const isValidAmounts = !isNaN(Number(minimumThresholdAmount?.replaceAll(',', ''))) && !isNaN(Number(reloadAmount.replaceAll(',', '')))

        if (isValidAmounts && selectedExternalAccount !== undefined && termsIsAgreed) {
            dispatch(AutoPayActions.updateAutopay({
                cardAccountUuid: banking.account.uuid,
                cardAccount: banking.account.uuid,
                amount: reloadAmount.replaceAll(',', ''),
                debitReloadBalanceTrigger: minimumThresholdAmount?.replaceAll(',', ''),
                externalAccount: selectedExternalAccount.uuid,
                active: true,
            }))
            setIsEditing(false)
            toggleIsModalOpen()
        }
    }

    const turnOffAutoPay = () => {
        dispatch(AutoPayActions.updateAutopay({
            cardAccountUuid: banking.account.uuid,
            active: false,
            amount: autopay.workingCopy.amount,
            debitReloadBalanceTrigger: autopay.workingCopy.debitReloadBalanceTrigger,
        }))
        setIsEditing(false)
    }

    const toggleIsModalOpen = () => {
        setIsModalOpen(!isModalOpen)
        setTermsIsAgreed(false)
    }

    const toggleTermsAgreed = () => {
        setTermsIsAgreed(!termsIsAgreed)
    }

    return <Container>
        {<Modal centered show={isModalOpen}>
            <ModalContent>
                <ModalTitle>Enable Auto-Reload</ModalTitle>
                <ModalSubtitle>
                    I authorize Taekus Inc (Taekus) to initiate an ACH transfer from the account above. I certify that I am authorized to initiate this transfer and that it complies with applicable law. This authorization is to remain in full force and effect until I notify Taekus at support@taekus.com that I wish to revoke it. I understand that this authorization will remain in full force and effect until I notify Taekus by clicking the turn-off link on the Edit AutoDeposit screen or emailing at support@taekus.com stating that I wish to revoke this authorization. I understand that Taekus must be provided sufficient time to have a reasonable opportunity to act upon your notice to cancel this authorization language.
                </ModalSubtitle>
                <div>
                    <input checked={termsIsAgreed} onChange={toggleTermsAgreed} type="checkbox" id="terms"/>
                    <TermsLabel htmlFor="terms"> I agree</TermsLabel>
                </div>
                <div className='d-flex w-100'>
                    <CancelButton onClick={toggleIsModalOpen}>Cancel</CancelButton>
                    <ConfirmButton onClick={updateAutopay}>Enable</ConfirmButton>
                </div>
            </ModalContent>
        </Modal>}
        <Description>
            Auto reload automatically transfers a set amount of money from your bank account to your Taekus account when your account balance drops below your minimum balance amount.
        </Description>
        <AutoFundContainer>
            {autopay.isLoading ? <SpinnerContainer {...fadeInOutMotionProps}>
                <Spinner/>
            </SpinnerContainer> : <Status {...fadeInOutMotionProps}>
                <div className='d-flex align-items-center'>
                    <AutoFundIconContainer>
                        {autopay.workingCopy.active ?  <Checkmark className='w-100 h-auto' fill='#00bf0c'/> : <FaRegTimesCircle className='w-100 h-auto' fill='red'/>}
                    </AutoFundIconContainer>
                    <div>{autopay.workingCopy.active ?  'Auto Reload is currently enabled.' : 'Auto Reload is currently disabled.'}</div>
                </div>
                {!isEditing && <ClickableText onClick={toggleIsEditing}>{autopay.workingCopy.active ? 'Edit auto reload settings' : 'Set up Auto Reload'}</ClickableText>}
            </Status>}
        </AutoFundContainer>
        <AnimatePresence mode='wait'>
            {(isEditing || autopay.workingCopy.active) && <motion.div key='edit' {...fadeInOutMotionProps}>
                <Row>
                    <Block>
                        <InputLabel>Minimum Balance Amount:</InputLabel>
                        <Amount>
                            $
                            {isEditing ? <AmountInput
                                onChange={updateMinimumThresholdAmount}
                                onBlur={onMinimumThresholdAmountBlur}
                                onFocus={onMinimumThresholdAmountFocus}
                                value={minimumThresholdAmount} 
                            /> : minimumThresholdAmount?.toLocaleString(Locale.English, USDCurrencyOptions)}
                        </Amount>
                    </Block>
                    <Block>
                        <InputLabel>Reload amount:</InputLabel>
                        <Amount>
                            $
                            {isEditing ? <AmountInput
                                onChange={updateReloadAmount}
                                onBlur={onReloadAmountBlur}
                                onFocus={onReloadAmountFocus}
                                value={reloadAmount} 
                            /> : reloadAmount?.toLocaleString(Locale.English, USDCurrencyOptions)}
                        </Amount>
                    </Block>
                </Row>
                <Row>
                    <Block>
                            <InputLabel>Transfer from:</InputLabel>
                            {isEditing ? <div className='w-100'>
                                <Dropdown onClick={toggleAccountDropdown}>
                                    {!!selectedExternalAccount ? <FundingAccountInput>
                                        <FundingAcccountName>{selectedExternalAccount?.name}</FundingAcccountName> — {selectedExternalAccount?.accountLast4}
                                    </FundingAccountInput> : <FadedText>Select a funding account</FadedText>}
                                    <DropdownArrowContainer>
                                        <DropdownArrow/>
                                    </DropdownArrowContainer>
                                </Dropdown>
                                <AnimatePresence>
                                    {isAccountDropdownOpen && <ExternalAccountsDropdownContainer
                                        {...fadeUpwardMotionProps}
                                    >
                                        <ExternalAccountsDropdown>
                                            {externalAccounts.map(mapExternalAccountToDropdownItem)}
                                            <ExternalAccount onClick={stopPropagation}>
                                                <PlaidModal>
                                                    <AddAccountWrapper>+ Add new account</AddAccountWrapper>
                                                </PlaidModal>
                                            </ExternalAccount>
                                        </ExternalAccountsDropdown>
                                    </ExternalAccountsDropdownContainer>}
                                </AnimatePresence>
                            </div> : <AccountName>{`${selectedExternalAccount?.name} — ${selectedExternalAccount?.accountLast4}`}</AccountName>}
                    </Block>
                    <Block>
                        <InputLabel>To Taekus Account:</InputLabel>
                        <AccountName>Taekus Debit — {banking.account.accountNumber?.slice(-4)}</AccountName>
                    </Block>
                </Row>
                {isEditing && <ButtonsContainer>
                    <ContinueButton onClick={toggleIsModalOpen}>
                        {autopay.workingCopy.active ? 'Update Auto Reload' : 'Enable Auto Reload'}
                    </ContinueButton>
                    {autopay.workingCopy.active && <DisableButton {...fadeInOutMotionProps} onClick={turnOffAutoPay}>
                        Turn off Auto Reload
                    </DisableButton>}
                </ButtonsContainer>}
            </motion.div>}
        </AnimatePresence>
    </Container>
}

const FundingAcccountName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8px;
`

const FundingAccountInput = styled.div`
    width: 1%;
    flex: 1;
    display: flex;
`

const TermsLabel = styled.label`
    margin: ${styles.Spacing.XS} ${styles.Spacing.S};
`

const ConfirmButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${styles.Color.TaekusPurple};
    color ${styles.Color.White};
    border: 0;
    padding: 8px;
    flex: 1;
    margin-left: 4px;
    margin-top: ${styles.Spacing.XS};
    height: ${styles.Spacing.M};
`

const CancelButton = styled.button`
    background-color: ${styles.Color.Grey};
    color ${styles.Color.Black};
    border: 0;
    padding: 8px;
    flex: 1;
    margin-right: 4px;
    margin-top: ${styles.Spacing.XS};
    height: ${styles.Spacing.M};
`

const Status = styled(motion.div)`
    font-size: 20px;
    ${styles.MediaQueries.Desktop} {
        display: flex;
        align-items: center;
    }
`

const ModalTitle = styled.div`
    font-size: 24px;
`

const ModalSubtitle = styled.div`
    font-size: ${styles.Font.Size.Small};
    margin-top: ${styles.Spacing.XS};
`

const ModalContent = styled.div`
    padding: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const ClickableText = styled.button`
    border: 0;
    background-color: ${styles.Color.Transparent};
    ${styles.MediaQueries.Mobile} {
        padding: ${styles.Spacing.XS} 4px;
    }
    color: blue;
    &:hover {
        text-decoration: underline;
    }
`

const AutoFundContainer = styled.div`
    padding: ${styles.Spacing.S} 0;
    min-height: 70px;
`

const AutoFundIconContainer = styled.div`
    width: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.XS};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
`

const Description = styled.div`
    flex: 0;
    height: min-content;
    align-self: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0 ${styles.Spacing.XS};
    font-size: ${styles.Font.Size.Small};
    background-color: #DFDFDF;
    border-radius: 8px;
    padding: ${styles.Spacing.XS};
    margin: ${styles.Spacing.S} 0;
`

const Row = styled.div`
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        display: flex;
        margin-bottom: ${styles.Spacing.M};
    }
`

const Block = styled.div`
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        min-width: 425px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin-bottom: ${styles.Spacing.S};
    }
`

const ButtonsContainer = styled.div`
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

const Amount = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    align-items: center;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
`

const AmountInput = styled.input`
    color: ${styles.Color.Black};
    font-weight: ${styles.Font.Weight[400]};
    background-color: ${styles.Color.Transparent};
    border: 0;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
    outline: none;
    margin-left: 4px;
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    border-radius: 0;
    height: ${styles.Spacing.L};
    padding: ${styles.Spacing.XS};
    ${styles.MediaQueries.Desktop} {
        width: 260px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const VerifyLink = styled.div`
    color: blue;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const AccountName = styled.div`
    font-size: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const AddAccountWrapper = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    height: ${styles.Spacing.L};
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: ${styles.Spacing.S};
    line-height: 140%; 
`

const FadedText = styled.div`
    opacity: 0.5;
`

const ExternalAccountsDropdownContainer = styled(motion.div)`
    position: relative;
    margin-right: 70px;
    ${styles.MediaQueries.Mobile}{
        width: 100%;
    }
`

const ExternalAccountsDropdown = styled.div`
    max-height: 400px;
    width: 100%;
    margin: 0;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: white;
    border-radius: ${styles.BorderRadius.L};
    bottom: ${styles.Spacing.L};
    position: absolute;
    border: 1px solid ${styles.Color.GreyText};
`

const DropdownArrowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0;
`

type ExternalAccountProps = {
    isAvailable?: boolean,
}

const ExternalAccount = styled.div<ExternalAccountProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${styles.Spacing.L};
    padding: ${styles.Spacing.XS};
    user-select: none;
    &:not(&:last-child) { 
        border-bottom: 1px solid ${styles.Color.GreyText};
    }
    &:hover {
        ${props => props.isAvailable !== false && `
            cursor: pointer;
            background-color: lightgrey;
        `}
    }
`

const ExternalAccountTitle = styled.div<ExternalAccountProps>`
    ${props => props.isAvailable === false && 'opacity: 0.5;'}
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: ${styles.Spacing.S};
    line-height: 140%; 
`

const ExternalAccountType = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    color: grey;
    font-size: 16px;
    line-height: 140%;
`

type ContinueButtonProps = {
    disabled?: boolean;
}

const ContinueButton = styled.div<ContinueButtonProps>`
    cursor: pointer;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.disabled ? '#D7D7D7' : styles.Color.TaekusPurple};
    border-radius: ${styles.BorderRadius.S};
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 16px;
    line-height: 138%;
    text-align: right;
    letter-spacing: 0.02em;
    color: ${props => props.disabled ? '#FFFFFF' : '#FAF8EE'};
    margin-right: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.S};
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const DisableButton = styled(motion.button)`
    cursor: pointer;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(230 59 59);
    color: ${styles.Color.White};
    border-radius: ${styles.BorderRadius.S};
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 16px;
    line-height: 138%;
    text-align: right;
    letter-spacing: 0.02em;
    margin-bottom: ${styles.Spacing.S};
    border: 0;
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Dropdown = styled.div`
    white-space: nowrap;
    display: flex;
    overflow: hidden;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    color: ${styles.Color.Black};
    cursor: pointer;
    padding-bottom: ${styles.Spacing.XS};
    border-bottom: ${styles.Spacing.XXXS} solid ${styles.Color.Black};
    ${styles.MediaQueries.Desktop} {
        margin-right: 70px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.XS};
    }
`

const InputLabel = styled.div`
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
    color: ${styles.Color.Black};
    margin-bottom: ${styles.Spacing.XS};
`

const SpinnerContainer = styled(motion.div)`
    width: 24px;
    height: 24px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: ${styles.Spacing.M};
`

export default AutoFund;