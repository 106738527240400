import React from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import moment from "moment";

import { motion } from "framer-motion";
import styled from "styled-components"

import { getUserAccessStatusColor } from "components/pages/Settings/constants";
import messages from "components/pages/Settings/messages";
import { User, UserAccessLevel, UserAccessStatus } from "components/pages/Settings/types";

import Spinner from "components/common/Spinner";

import { ReactComponent as Accountant } from "assets/svg/Accountant.svg";
import { ReactComponent as AccountManager } from "assets/svg/AccountManager.svg";
import { ReactComponent as ArrowRight } from "assets/svg/ArrowRight.svg";
import { ReactComponent as AuthorizedUser } from "assets/svg/AuthorizedUser.svg";
import { ReactComponent as FullAccess } from "assets/svg/FullAccess.svg";

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles"

const getAccessLevelIcon = (level: UserAccessLevel) => {
    switch (level) {
        case UserAccessLevel.AccountManager:
            return <AccountManager/>;
        case UserAccessLevel.Accountant:
            return <Accountant/>;
        case UserAccessLevel.FullAccess:
            return <FullAccess/>;
        case UserAccessLevel.AuthorizedUser:
        default:
            return <AuthorizedUser/>;
    }
}

const getUserStatus = (user: any) => {
    if (user.active) {
        return user.user === null ? UserAccessStatus.Pending : UserAccessStatus.Active
    }
    return user.user === null ? UserAccessStatus.InviteExpired : UserAccessStatus.Removed
}

const getUpdateLabel = (user: any) => {
    return getUserStatus(user) === UserAccessStatus.Active ? 'Last Updated' : 'Invite Sent'
}

const UserAccess = () => {
    const history = useHistory()

    // Redux State
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const isLoadingAccount = useSelector((state: any) => state.banking.isLoading)
    const cardAccount = useSelector((state: any) => state.banking.account)
    const authorizedUsers = useSelector((state: any) => state.banking.account.authorizedUsers)

    const sortUsers = (a: User, b: User) => {
        // Sort by active first, then by last name
        if ((getUserStatus(a) === UserAccessStatus.Active ||  getUserStatus(b) === UserAccessStatus.Active) && getUserStatus(a) !== getUserStatus(b)) {
            return getUserStatus(a) === UserAccessStatus.Active ? -1 : 1
        }
        return a.auLastName.localeCompare(b.auLastName)
    }
    const activeUsers = authorizedUsers?.filter((user: User) => [UserAccessStatus.Active, UserAccessStatus.Pending].includes(getUserStatus(user))).sort(sortUsers)
    const inactiveUsers = authorizedUsers?.filter((user: User) => [UserAccessStatus.InviteExpired, UserAccessStatus.Removed].includes(getUserStatus(user))).sort(sortUsers)

    const goToNewUserPage = () => {
        history.push(`/settings/useraccess/${cardAccount.uuid}/new`)
    }

    const mapUserToRow = (user: User) => {
        const isActiveUser = [UserAccessStatus.Active, UserAccessStatus.Pending].includes(getUserStatus(user))
        const onClick = isActiveUser ? () => { history.push(`/settings/useraccess/${cardAccount.uuid}/${user.uuid}`) } : undefined

        return <UserRow onClick={onClick}>
            {isMobile ? <UserCell>
                <div className="w-100 d-flex justify-content-between">
                    <div>
                        <div>{user.auFirstName} {user.auLastName}</div>
                        <div className="d-flex align-items-center">
                            <StyledUserAccessIcon>
                                {getAccessLevelIcon(user.accessLevel)}
                            </StyledUserAccessIcon>
                            {messages.UserAccess.Level[user.accessLevel]?.label || ''}
                        </div>
                        <div className="d-flex align-items-center">
                            <UserAccessStatusIndicator user={user}/>
                            {messages.UserAccess.Status[getUserStatus(user)]}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <LastUpdate>
                            {isActiveUser && <div>{getUpdateLabel(user)}</div>}
                            <div>{moment(user.updated).format('MM/DD/YYYY')}</div>
                        </LastUpdate>
                    </div>
                </div>
            </UserCell> : <>
                <UserCell>{user.auFirstName} {user.auLastName}</UserCell>
                <UserCell>
                    <div className="d-flex align-items-center">
                        <StyledUserAccessIcon>
                            {getAccessLevelIcon(user.accessLevel)}
                        </StyledUserAccessIcon>
                        <div>{messages.UserAccess.Level[user.accessLevel].label}</div>
                    </div>
                </UserCell>
                <UserCell>
                    <div className="d-flex align-items-center">
                        <UserAccessStatusIndicator user={user}/>
                        {messages.UserAccess.Status[getUserStatus(user)]}
                    </div>
                </UserCell>
                <UserCell>
                    <div className="d-flex justify-content-between">
                        <LastUpdate>
                            {isActiveUser && <div>{getUpdateLabel(user)}</div>}
                            <div>{moment(user.updated).format('MM/DD/YYYY')}</div>
                        </LastUpdate>
                        {isActiveUser && <div>
                            <ActiveAccountArrowIcon/>
                        </div>}
                    </div>
                </UserCell>
            </>}
        </UserRow>
    }

    return <div>
        <Title>User Access and Management</Title>
        {isLoadingAccount ? <SpinnerContainer key='spinner' {...fadeInOutMotionProps}>
            <Spinner size={40} />
            <SpinnerLabel>Fetching Account</SpinnerLabel>
        </SpinnerContainer> : (authorizedUsers?.length ? <div>
            {activeUsers?.length > 0 && <Table>
                <HeaderRow>
                    {isMobile ? <TableHeader>Active Users</TableHeader> : <>
                        <TableHeader>User</TableHeader>
                        <TableHeader>Access Level</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Last Update</TableHeader>
                    </>}
                </HeaderRow>
                {activeUsers?.map(mapUserToRow)}
            </Table>}
            <AddUserButton onClick={goToNewUserPage}>
                + Add new user
            </AddUserButton>
            {inactiveUsers?.length > 0 && <Table>
                <HeaderRow>
                    {isMobile ? <TableHeader>Inactive Users</TableHeader> : <>
                        <TableHeader>Removed & Expired Users</TableHeader>
                        <TableHeader>Previous Access Level</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Date Removed</TableHeader>
                    </>}
                </HeaderRow>
                {inactiveUsers.map(mapUserToRow)}
            </Table>}
        </div> : <EmptyUserList>
            Users you add or manage will appear here.
            <AddUserButton onClick={goToNewUserPage}>
                + Add new user
            </AddUserButton>
        </EmptyUserList>)}
    </div>
}

const EmptyUserList = styled.div`
    margin-top: 24px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const AddUserButton = styled.button`
    border: 0;
    background-color: black;
    margin-top: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: white;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const HeaderRow = styled.tr`
    border-bottom: 1px solid black;
`

const SpinnerLabel = styled.div`
    opacity: 0.5;
    padding: ${styles.Spacing.XS};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const SpinnerContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${styles.Spacing.S};
`

const ActiveAccountArrowIcon = styled(ArrowRight)`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.XS};
    margin-right: ${styles.Spacing.S};
    fill: ${styles.Color.TaekusPurple};
`

type UserAccessStatusIndicatorProps = {
    user: User,
}

const UserAccessStatusIndicator = styled.div<UserAccessStatusIndicatorProps>`
    width: 11px;
    height: 11px;
    background-color: ${props => getUserAccessStatusColor(getUserStatus(props.user))};
    border-radius: 50%;
    margin-right: 8px;
`

const StyledUserAccessIcon = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;
    fill: black;
    stroke: black;
`

const LastUpdate = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Mobile} {
        align-items: end;    
    }
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const UserCell = styled.td`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 4px;
`

type UserRowProps = {
    onClick?: any;
}

const UserRow = styled.tr<UserRowProps>`
    height: 80px;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    ${styles.Animation.transitionStyles}
    ${props => props.onClick && `
        cursor: pointer;
        &:hover {
            background-color: rgba(124, 61, 118, 0.10);
        }
    `}
`

const TableHeader = styled.th`
    font-size: 16px;
    font-weight: 500;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${styles.MediaQueries.Desktop} {
        width: 25%;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Table = styled.table`
    // gap: ${styles.Spacing.S};
    margin-top: 24px;
    width: 100%;
    table-layout: fixed;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export default UserAccess