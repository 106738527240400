import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import AppRouter from 'components/appRouter/appRouter'
import AppContainer from 'components/AppContainer'
import Login from 'components/authentication/Login'
import Signup from 'components/authentication/Signup'
import SignupAuthorizedUser from 'components/authentication/SignupAuthorizedUser'
import SignupSuccess from 'components/authentication/SignupSuccess'
import SignupUnsuccessful from 'components/authentication/SignupUnsuccessful'
import SignupError from 'components/authentication/SignupError' // SignupNoAUInvite
import SignupNoAUInvite from 'components/authentication/SignupNoAUInvite' // SignupNoAUInvite
import SignupAlreadyLoggedIn from './authentication/SignupAlreadyLoggedIn'

import VerifyPhone from 'components/authentication/VerifyPhone'
import VerifyId from 'components/authentication/VerifyId'
import AccountRecovery from 'components/authentication/AccountRecovery'

class App extends Component {
  componentDidMount() {
    if (window.env.REACT_APP_DEBUG !== 'true') {
      ReactGA.initialize(`${window.env.REACT_APP_GA_ID}`)
      ReactGA.send({
        hitType: 'pageview',
        page: `${window.location.pathname + window.location.search}`,
      })
    }
  }

  render() {
    const debug = window.env.REACT_APP_DEBUG === 'true'

    return (
      <div className="App">
        <Helmet>
          {debug && <meta name="robots" content="noindex"></meta>}
          {debug ? (
            <script type="text/javascript" src="https://ci-mpsnare.iovation.com/snare.js"></script>
          ) : (
            <script type="text/javascript" src="https://mpsnare.iesnare.com/snare.js"></script>
          )}
        </Helmet>
        <BrowserRouter>
          <AppContainer>
            <Switch>
              <Route exact path="/login/" render={(props) => <Login {...props} />} />
              <Route
                exact
                path="/signup-authorized-user/"
                render={(props) => <SignupAuthorizedUser {...props} />}
              />
              <Route exact path="/signup/" render={(props) => <Signup {...props} />} />
              <Route exact path="/signup/verify/" render={(props) => <VerifyPhone {...props} />} />
              <Route
                exact
                path="/signup/id-verify/:applicationUuid"
                render={(props) => <VerifyId {...props} />}
              />
              <Route
                exact
                path="/signup/success/"
                render={(props) => <SignupSuccess {...props} />}
              />
              <Route
                exact
                path="/signup/unsuccessful/"
                render={(props) => <SignupUnsuccessful {...props} />}
              />
              <Route exact path="/signup/error/" render={(props) => <SignupError {...props} />} />
              <Route
                exact
                path="/signup-authorized-user/no-invite/"
                render={(props) => <SignupNoAUInvite {...props} />}
              />
              <Route
                exact
                path="/signup-authorized-user/already-logged-in/"
                render={(props) => <SignupAlreadyLoggedIn {...props} />}
              />
              <Route
                exact
                path="/account/recovery/"
                render={(props) => <AccountRecovery {...props} />}
              />
              <Route exact path="/signup/:cardType" render={(props) => <Signup {...props} />} />
              <Route path="/" render={(props) => <AppRouter {...props} />} />
            </Switch>
          </AppContainer>
        </BrowserRouter>
      </div>
    )
  }
}

export default App
