import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";

import { motion } from "framer-motion";
import styled from "styled-components";

import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'

import PlaidModal from "components/pages/Funding/PlaidModal";

import TextInput from "components/common/TextInput";
import Spinner from "components/common/Spinner";
import { fadeInOutMotionProps } from "styles/motionConstants";

import { ReactComponent as SearchIcon } from "assets/svg/Search.svg";
import styles from "styles/styles";

enum ModalMode {
    Edit,
    Delete
}

const ExternalAccounts = () => {
    // Redux state
    const dispatch = useDispatch()
    const isLinkedAccountsLoading = useSelector((state: any) => state.linkedAccounts.isLoading)
    const linkedAccounts = useSelector((state: any) => state.linkedAccounts.linkedAccounts)
    const parentUser = useSelector((state: any) => state.currentUser.currentUser.parentUser)

    // Component state
    const [searchValue, setSearchValue] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalMode, setModalMode] = useState<ModalMode | undefined>(undefined)
    const [selectedAccount, setSelectedAccount] = useState<any>(undefined)
    const [accountNicknameState, setAccountNicknameState] = useState('')

    const displayAccounts = searchValue.length ? linkedAccounts.filter((acc: any) => `${acc?.name} ${acc?.accountLast4}`.toLowerCase().includes(searchValue.toLowerCase())) : linkedAccounts

    const toggleIsModalOpen = () => {
        setIsModalOpen(!isModalOpen)
    }

    const handleAccountNicknameStateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setAccountNicknameState(ev.target.value)
    }

    const updateLinkedAccountNickname = () => {
        dispatch(LinkedAccountActions.updateLinkedAccount({
            uuid: selectedAccount?.uuid,
            name: accountNicknameState,
        }))
        setIsModalOpen(false)
    }

    const removeLinkedAccount = () => {
        dispatch(LinkedAccountActions.deleteLinkedAccount({
              accountUuid: selectedAccount.uuid,
        }))
        setIsModalOpen(false)
    }

    const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(ev.target.value);
    }

    const mapPaymentAccountToItem = (linkedAccount: any, index: number) => {
        const editAccountName = () => {
            setSelectedAccount(linkedAccount)
            setModalMode(ModalMode.Edit)
            setAccountNicknameState(linkedAccount.name)
            setIsModalOpen(true)
        }

        const deleteAccountName = () => {
            setSelectedAccount(linkedAccount)
            setModalMode(ModalMode.Delete)
            setIsModalOpen(true)
        }

        const isAvailable = linkedAccount.verified && linkedAccount.isTransferReady;
        const isVerifiable = !linkedAccount.verified;

        return <Address key={`address-${index}`}>
            <AccountDetails>
                <AccountName>{linkedAccount?.name}</AccountName>
                <AccountDescription>
                        {`${linkedAccount?.type === 'checking' ? 'Checking' : 'Savings'} - ${linkedAccount?.accountLast4}`}
                </AccountDescription>
            </AccountDetails>
            <AccountActions>
                {isVerifiable ? <PlaidModal linkedAccount={linkedAccount}>
                    <ManageButton>Verify</ManageButton>
                </PlaidModal> : <AccountStatus>{isAvailable ? 'Available' : 'Unavailable'}</AccountStatus>}
                <ManageButton onClick={editAccountName}>Edit</ManageButton>
                <ManageButton onClick={deleteAccountName}>Delete</ManageButton>
            </AccountActions>
        </Address>
    }

    return <div>
        <Modal show={isModalOpen} centered>
            <ModalTitle>{modalMode === ModalMode.Delete ? 'Delete Account' : 'Edit Account Nickname'}</ModalTitle>
            {modalMode === ModalMode.Edit && <AccountNameInputContainer>
                <AccountNameInput onChange={handleAccountNicknameStateChange} value={accountNicknameState} />
            </AccountNameInputContainer>}
            <ModalAccountDetails>
                <ModalAccountDetail>
                    <ModalAccountDetailTitle>Account Last 4</ModalAccountDetailTitle>
                    <div>{selectedAccount?.accountLast4}</div>
                </ModalAccountDetail>
                <ModalAccountDetail>
                    <ModalAccountDetailTitle>Account Type</ModalAccountDetailTitle>
                    <div>{selectedAccount?.type === 'checking' ? 'Checking' : 'Savings'}</div>
                </ModalAccountDetail>
            </ModalAccountDetails>
            <ModalButtonsContainer>
                <CancelButton onClick={toggleIsModalOpen}>Cancel</CancelButton>
                {modalMode === ModalMode.Delete ? <ConfirmButton onClick={removeLinkedAccount}>Delete</ConfirmButton> : 
                <ConfirmButton onClick={updateLinkedAccountNickname}>Confirm</ConfirmButton>}
            </ModalButtonsContainer>
        </Modal>
        <Title>External Accounts</Title>
        {parentUser ? <HelpText><b>Acting as an authorized user for {parentUser.firstName} {parentUser.lastName}</b></HelpText>:<></>}
        <div>
            <ContentHeader>
                <SearchInputContainer>
                    <TextInput 
                        onChange={handleSearchInputChange}
                        icon={<SearchIcon/>} 
                        placeholder={'Search'} 
                    />
                </SearchInputContainer>
                <PlaidModal>
                    <AddAccountButton>+ Add External Account</AddAccountButton>
                </PlaidModal>
            </ContentHeader>
            {isLinkedAccountsLoading ? <motion.div {...fadeInOutMotionProps} className='d-flex justify-content-center'><div style={{width: '20px', height: '20px', marginTop: '20px'}}><Spinner/></div></motion.div> : (displayAccounts.length ? displayAccounts.sort((a: any, b: any) => (a.name+a.accountLast4).localeCompare(b.name+b.accountLast4)).map(mapPaymentAccountToItem) : <EmptyAccountsText {...fadeInOutMotionProps}>{!!linkedAccounts.length ? 'Your accounts are hidden because none of them match the search parameters.' : 'You have no accounts currently linked to your Taekus profile.'}</EmptyAccountsText>)}
        </div>
    </div>
}

const HelpText = styled.div`
     ${styles.Text.BodySmall}
    font-size: ${styles.Font.Size.Small};
    padding-top: ${styles.Spacing.S};
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
`

const EmptyAccountsText = styled(motion.div)`
    display: flex;
    justify-content: center;
    text-align: center;
    color: rgba(0,0,0, 0.5);
    font-family: ${styles.Font.Family.MonumentGrotesk};
    margin-top: ${styles.Spacing.S};
`

const AddAccountButton = styled.button`
    background-color: ${styles.Color.Black};
    color: ${styles.Color.White};
    border: 0;
    padding: ${styles.Spacing.XS};
    height: fit-content;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const SearchInputContainer = styled.div`
    margin: ${styles.Spacing.S} 0;
    border: 0;
    outline: 0;
    backgroundColor: ${styles.Color.Transparent};
    ${styles.MediaQueries.Desktop} {
        width: 50%;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const ContentHeader = styled.div`
    ${styles.MediaQueries.Desktop} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const AccountNameInputContainer = styled.div`
    display: flex;    
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
    flex: 1;
`

const AccountNameInput = styled.input`
    flex: 1;
    padding: ${styles.Spacing.XS};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const ModalTitle = styled.div`
    padding: ${styles.Spacing.S};
    font-size: ${styles.Spacing.S};
`

const ModalButtonsContainer = styled.div`
    display: flex;
    flex: 1;
`

const ModalAccountDetails = styled.div`
    display: flex;
    justify-content: center;
`

const ModalAccountDetail = styled.div`
    flex: 1;
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
`

const ModalAccountDetailTitle = styled.div`
    font-size: ${styles.Font.Size.Small};
`

const ConfirmButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${styles.Color.TaekusPurple};
    color ${styles.Color.White};
    border: 0;
    padding: 8px;
    flex: 1;
    margin: ${styles.Spacing.XS};
`

const CancelButton = styled.button`
    background-color: ${styles.Color.Grey};
    color ${styles.Color.Black};
    border: 0;
    padding: 8px;
    flex: 1;
    margin: ${styles.Spacing.XS};
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    min-width: 300px;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const AccountName = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const AccountDetails = styled.div`
    ${styles.MediaQueries.Mobile}{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const AccountDescription = styled.div`
    display: flex;
    align-items: center;
    opacity: 0.6;
`

const AccountActions = styled.div`
    display: flex;
    min-width: 120px;
    justify-content: space-between;
    align-items: center;
    ${styles.MediaQueries.Desktop} {
        
    }
`

const AccountStatus = styled.div`
    opacity: 0.5;
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.XS};
    }
`

const Address = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    height: 100px;
    width: 100%;
    padding: 0 ${styles.Spacing.S};
    display: flex;
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0;
    }
    ${styles.MediaQueries.Desktop} {
        justify-content: space-between;
        align-items: center;
    }
`

const ManageButton = styled.button`
    border: 0;
    background-color: ${styles.Color.Transparent};
    width: fit-content;
    height: fit-content;
    color: ${styles.Color.TaekusPurple};
    &:hover {
        text-decoration: underline;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0;
    }
`

export default ExternalAccounts;