/**
 * Get the value for a given key in address_components
 * https://stackoverflow.com/a/40144130/2698266
 *
 * @param {Array} components address_components returned from Google maps autocomplete
 * @param type key for desired address component
 * @returns {String} value, if found, for given type (key)
 */
const extractFromAddress = (components, type, use_short = false) => {
  return (
    components
      .filter((component) => component.types.indexOf(type) !== -1)
      .map((item) => (use_short ? item.short_name : item.long_name))
      .pop() || null
  )
}

export default extractFromAddress
